import Button from 'core-system/Button'
import { ColumnCard } from 'core-system/ColumnView'
import SummaryOverview from 'core-system/Program/Summary/SummaryOverview'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import OTPSummaryBudget from 'features/OTP/Activation/components/OTPSummaryBudget'
import OTPSummaryOptions from 'features/OTP/Activation/components/OTPSummaryOptions'
import moment from 'moment'
import React from 'react'
import { Segment } from 'redux/employer/employerTypes'
import { OtpActiveOptions, OtpManageOptionState } from 'redux/otp/otpTypes'
import styled from 'styled-components'

const Container = styled(ColumnCard)`
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
`

interface OTPManageSummaryProps {
  toggleEdit: () => void
  canEdit: boolean
  program: OtpManageOptionState
  currentSegment: Segment
  activeOptions: OtpActiveOptions
  handleOpenModal: () => void
}

const OTPManageSummary = React.memo((props: OTPManageSummaryProps) => {
  const {
    toggleEdit,
    canEdit,
    program,
    currentSegment,
    activeOptions,
    handleOpenModal,
  } = props

  return (
    <Container>
      <Text variant='h4' marginBottom='1.5rem'>
        {canEdit ? "Next Month's Program" : 'Current Program'}
      </Text>
      <SummaryOverview
        startDate={moment(program.startDate, 'YYYY-MM-DD').format(
          'MMMM Do, YYYY'
        )}
        currentSegment={currentSegment}
        marginBottom='1rem'
      />
      <OTPSummaryOptions
        activeOptions={activeOptions}
        activeMerchantIds={program.micromobility.activeMerchantIds}
        availableMerchantIds={program.micromobility.availableMerchantIds}
        handleOpenModal={handleOpenModal}
        canEdit={canEdit}
      />
      <OTPSummaryBudget
        totalEmployees={currentSegment.commuters}
        transactionLimit={program.budget}
      />
      <Text
        variant='body2'
        textColor={palette.text.placeholder}
        marginBottom={!canEdit && '1.5rem'}
      >
        All budget amounts are estimated based on max commuter spend.
      </Text>
      {!canEdit && (
        <Button width='100%' onClick={toggleEdit}>
          Edit Next Year&apos;s Program
        </Button>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OTPManageSummary.displayName = 'OTPManageSummary'
}

export default OTPManageSummary
