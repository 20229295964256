import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 12C22 17.5229 17.5229 22 12 22C6.47714 22 2 17.5229 2 12C2 6.47714 6.47714 2 12 2C17.5229 2 22 6.47714 22 12ZM10.4412 13.881L16.223 8.09924L17.5699 9.44611L10.4412 16.5748L6.08875 12.2223L7.43562 10.8754L10.4412 13.881Z'
    />
  </>,
  'Success'
)
