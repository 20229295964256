import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.2143 7.5L16.5 8.78571L13.2857 12L16.5 15.2143L15.2143 16.5L12 13.2857L8.78571 16.5L7.5 15.2143L10.7143 12L7.50001 8.78572L8.78572 7.5L12 10.7143L15.2143 7.5Z'
    />
  </>,
  'Clear'
)
