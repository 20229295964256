import AlertBanner from 'core-system/AlertBanner'
import Banner from 'core-system/Banner'
import Loading from 'core-system/Loading'
import { ProgramMissingPaymentMethod } from 'core-system/Program'
import ProgramHeader from 'core-system/Program/ProgramHeader'
import moment from 'moment'
import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getClientEnvironment } from 'redux/config/services/Constants'
import { AppState } from 'redux/config/store'
import { QtfProgram, QtfProgramGroup } from 'redux/qtf/qtfTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import QTFOverviewView from './QTFOverviewView'
import NoPaymentModal from 'shared/components/NoPaymentModal'

const QTFView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const navigate = useNavigate()

  const qtfPrograms = useSelector((state: AppState) => state.qtf.qtfPrograms)
  const {
    segmentsMap,
    hasValidPaymentMethod,
    hasValidDistributionType,
    profile,
  } = useSelector((state: AppState) => state.employer)
  const { myCommuters } = useSelector((state: AppState) => state.employee)

  const segmentContractors = useMemo(
    () =>
      myCommuters?.[segmentId]?.filter(
        (commuter) => commuter['finchEmploymentType'] === 'contractor'
      ),
    [myCommuters, segmentId]
  )

  const [programGroup, setProgramGroup] = useState<QtfProgramGroup>(null)
  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)
  const [showAlertBanner, setShowAlertBanner] = useState(
    segmentContractors?.length > 0
  )

  const onGetStartedClick = () => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      navigate(`/tax-savings/${segmentId}/activation`)
    } else {
      setIsNoPaymentModalOpen(true)
    }
  }

  useEffect(() => {
    setProgramGroup({
      active: qtfPrograms[segmentId] && qtfPrograms[segmentId].active,
      nextMonth: qtfPrograms[segmentId] && qtfPrograms[segmentId].nextMonth,
    })
  }, [qtfPrograms, segmentId])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setShowAlertBanner(segmentContractors?.length > 0)
  }, [segmentContractors])

  if (!qtfPrograms || !programGroup || !myCommuters) {
    return <Loading fullPage />
  }

  if (qtfPrograms && !programGroup.active && !programGroup.nextMonth) {
    return (
      <>
        {showAlertBanner && (
          <AlertBanner
            variant='yellowAlert'
            message={`${segmentContractors?.length || 0} commuter${
              segmentContractors?.length === 1 ? '' : 's'
            } at this worksite are contractors. They are not eligible for pre-tax benefits`}
            closeFn={() => setShowAlertBanner(false)}
            marginBottom='1rem'
          />
        )}
        <ProgramHeader segmentId={segmentId} isPreTax={true} />
        <Banner
          variant='cta'
          title='Start your Pre-Tax Commuter Program today!'
          description={`Give ${
            segmentsMap[segmentId].name === 'All Employees'
              ? 'All Commuters'
              : segmentsMap[segmentId].name
          } tax saving benefits today!`}
          cta='Get Started'
          ctaCallback={() => onGetStartedClick()}
          background='bannerGeneral3'
        />
        <NoPaymentModal
          open={isNoPaymentModalOpen}
          closeModal={() => setIsNoPaymentModalOpen(false)}
          product='qtf'
        />
      </>
    )
  }
  const isValid = hasValidDistributionType && hasValidPaymentMethod
  const isNextMonth = !programGroup.active && programGroup.nextMonth
  const isCancelling =
    !programGroup.active &&
    programGroup.nextMonth &&
    programGroup.nextMonth.typeMap?.COMMUTER_TRANSIT?.status === 'CANCELLING' &&
    programGroup.nextMonth.typeMap?.COMMUTER_PARKING?.status === 'CANCELLING'
  const isPending = isNextMonth && !isCancelling
  const isDevCheck =
    getClientEnvironment() === 'development' && !isPending && !isCancelling
  const isOverviewCheck = programGroup.active && isValid

  return (
    <>
      {showAlertBanner && (
        <AlertBanner
          variant='yellowAlert'
          message={`${
            segmentContractors?.length || 0
          } commuters at this worksite are contractors. They are not eligible for pre-tax benefits`}
          closeFn={() => setShowAlertBanner(false)}
          marginBottom='1rem'
        />
      )}
      <ProgramHeader
        segmentId={segmentId}
        manageRoute={
          isDevCheck || isOverviewCheck
            ? `/tax-savings/${segmentId}/manage`
            : null
        }
        isPreTax={true}
      />
      {isPending && (
        <Banner
          variant='cta'
          background='bannerGeneral3'
          title='Starting Soon!'
          description={`Commuters will be able to start allocating funds on ${moment(
            programGroup.nextMonth.startDate
          ).format(
            'MMMM Do, YYYY'
          )} and they will be able to spend these funds starting on ${moment(
            programGroup.nextMonth.hrInitialDisplayStartDate
          ).format('MMMM Do, YYYY')}. `}
          cta='Manage Program'
          ctaCallback={() => navigate(`/tax-savings/${segmentId}/manage`)}
        />
      )}
      {isCancelling && (
        <Banner
          variant='cta'
          background='bannerGeneral3'
          title='Program Cancelling'
          description='Your program cancellation request has been submitted. Our team will reach out soon.'
          cta='Manage Program'
          ctaCallback={() => navigate(`/tax-savings/${segmentId}/manage`)}
        />
      )}
      {isDevCheck ? (
        <QTFOverviewView activeProgram={programGroup.active} />
      ) : (
        <>
          {!isValid && (
            <ProgramMissingPaymentMethod from={`/tax-savings/${segmentId}`} />
          )}
          {isOverviewCheck && (
            <QTFOverviewView
              activeProgram={programGroup.active as QtfProgram}
            />
          )}
        </>
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFView.displayName = 'QTFView'
}

export default QTFView
