import Chip from 'core-system/Chip'
import FlexContainer from 'core-system/FlexContainer'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import TransitLogo from 'core-system/TransitLogo'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import {
  DetailedServiceProvider,
  Merchant,
  ServiceProvider,
} from 'redux/programs/programsTypes'
import RandomUtils from 'shared/RandomUtils'
import styled, { css } from 'styled-components'

type ModeTypesSectionVariants = 'row' | 'column'

const Container = styled.div<{ variant: ModeTypesSectionVariants }>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding: 1.5rem 3.25rem 3.25rem 3.25rem;

  ${(props) =>
    props.variant === 'column' &&
    css`
      flex-direction: column;
      padding: 0;
    `}
`

const LocationsFilterContainer = styled.div<{
  variant: ModeTypesSectionVariants
}>`
  gap: 1rem;
  min-width: 33%;
  min-height: ${(props) => props.variant === 'row' && '100%'};
`

const AvailableModeTypesContainer = styled.div<{
  variant: ModeTypesSectionVariants
}>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  ${(props) =>
    props.variant === 'row' &&
    css`
      border-left: 0.0625rem solid ${palette.grey.grey3};
      padding-left: 2rem;
    `}
`

const ServiceContainer = styled.div<{ variant: ModeTypesSectionVariants }>`
  ${(props) => props.theme.baseCard}

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;

  ${(props) =>
    props.variant === 'column' &&
    css`
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    `}
`

const ServiceContainerTitle = styled.div<{
  variant: ModeTypesSectionVariants
}>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${(props) =>
    props.variant === 'column' &&
    css`
      width: 100%;
    `}
`

const LearnMoreWrapper = styled.div<{ variant: ModeTypesSectionVariants }>`
  ${(props) =>
    props.variant === 'column' &&
    css`
      width: 100%;
      border-top: 0.0625rem solid ${palette.grey.grey3};
      padding-top: 0.5rem;
    `}
`

const StyledLink = styled.a`
  cursor: pointer;
  font-weight: normal;
  color: ${palette.primary.primaryPurple};

  &:hover {
    color: ${palette.text.primary};
  }
`

interface ModeOfferProps {
  service: DetailedServiceProvider
  variant: ModeTypesSectionVariants
}
const AvailableService = React.memo((props: ModeOfferProps) => {
  const { service, variant } = props
  return (
    <ServiceContainer variant={variant}>
      <ServiceContainerTitle variant={variant}>
        <TransitLogo
          modeType={service.modeType}
          size={variant === 'row' ? 'large' : 'small'}
          src={service.iconUrl || service.modeType}
          textColor={palette.text.primary}
          backgroundColor={palette.transparent}
          border='none'
        />
        <Text variant='action3'>
          {ProgramUtils.formatElectricModeTypes(
            RandomUtils.title(service.modeType)
          )}
        </Text>
      </ServiceContainerTitle>
      {service.websiteUrl && (
        <LearnMoreWrapper variant={variant}>
          <Text variant='hyperlink'>
            <StyledLink
              href={service.websiteUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn More
            </StyledLink>
          </Text>
        </LearnMoreWrapper>
      )}
    </ServiceContainer>
  )
})
AvailableService.displayName = 'AvailableService'

interface LocationFilter {
  text: string
  func: (mode: ServiceProvider) => boolean
}

interface ModeTypesSectionProps {
  merchant: Merchant
  variant?: ModeTypesSectionVariants
}
const ModeTypesSection = (props: ModeTypesSectionProps) => {
  const { merchant, variant = 'row' } = props

  const serviceProviderMap = useSelector(
    (state: AppState) => state.programs.serviceProviderMap
  )

  const getAllLocationFilters = (): LocationFilter[] => {
    const metroAreaFilters = merchant.metroAreas.map((metroArea) => {
      return {
        text: ProgramUtils.metroAreaStrings[metroArea],
        func: (mode) => mode.metroArea === metroArea,
      } as LocationFilter
    })

    const countryFilters = merchant.countries.map((country) => {
      return {
        text: `Nationwide ${ProgramUtils.countryNumberToName[country]}`,
        func: (mode) => mode.country === country,
      } as LocationFilter
    })

    return [...metroAreaFilters, ...countryFilters]
  }

  const [currentLocationFilters, setCurrentLocationFilters] = useState<
    LocationFilter[]
  >([])

  const toggleLocationFilter = (locationFilter) => {
    const idx = currentLocationFilters.findIndex(
      (f) => f.text === locationFilter.text
    )
    if (idx === -1) {
      setCurrentLocationFilters([...currentLocationFilters, locationFilter])
    } else {
      setCurrentLocationFilters([
        ...currentLocationFilters.slice(0, idx),
        ...currentLocationFilters.slice(idx + 1),
      ])
    }
  }

  const getFilteredProviders = (): DetailedServiceProvider[] => {
    const relevantModes = merchant.modeIds.map((id) => serviceProviderMap[id])
    const filteredProviders = !currentLocationFilters.length
      ? relevantModes
      : relevantModes.filter((prov) =>
          currentLocationFilters.some((f) => f.func(prov))
        )

    // remove duplicate modeType services
    const offeredModeTypes = new Set()
    const uniqueModeFilteredProviders = []

    filteredProviders.forEach((prov) => {
      if (offeredModeTypes.has(prov.modeType)) return
      offeredModeTypes.add(prov.modeType)
      uniqueModeFilteredProviders.push(prov)
    })

    return uniqueModeFilteredProviders
  }

  return (
    <Container variant={variant}>
      <LocationsFilterContainer variant={variant}>
        <Text variant='h5' paddingBottom='1rem'>
          Locations
        </Text>
        <FlexContainer
          flexWrap='wrap'
          style={{ gap: '0.5rem' }}
          data-cy='merchant-catalog-details-location-filters-container'
        >
          {getAllLocationFilters().map((locationFilter, idx) => (
            <Chip
              variant='filter'
              onClick={() => toggleLocationFilter(locationFilter)}
              selected={
                currentLocationFilters.findIndex(
                  (f) => f.text === locationFilter.text
                ) !== -1
              }
              key={idx}
            >
              {locationFilter.text}
            </Chip>
          ))}
        </FlexContainer>
      </LocationsFilterContainer>
      <AvailableModeTypesContainer variant={variant}>
        <Text variant='h5'>Mode Types Available</Text>
        <FlexContainer
          flexDirection='column'
          width='100%'
          style={{ gap: '0.5rem' }}
          data-cy='merchant-catalog-details-mode-types-container'
        >
          {getFilteredProviders().map((prov, idx) => (
            <AvailableService service={prov} variant={variant} key={idx} />
          ))}
        </FlexContainer>
      </AvailableModeTypesContainer>
    </Container>
  )
}

export default ModeTypesSection
