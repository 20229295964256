import { QtfManageState } from 'redux/qtf/qtfTypes'

const monthlyPreTaxParking = 31500
const monthlyPreTaxTransit = 31500

//this is used to see if the upcoming program has changes
const programHasChanges = (
  programState: QtfManageState,
  activeOptions: Dictionary
) => {
  const optionChanges = Object.keys(activeOptions.active).flatMap((option) => {
    if (activeOptions.active[option] !== activeOptions.nextMonth[option]) {
      return option
    } else {
      return []
    }
  })

  const isCancelling =
    programState.nextMonth.typeMap?.COMMUTER_PARKING?.status === 'CANCELLING' &&
    programState.nextMonth.typeMap?.COMMUTER_TRANSIT?.status === 'CANCELLING'

  return (
    programState.active.contributionDeadlineDay !==
      programState.nextMonth.contributionDeadlineDay ||
    optionChanges.length > 0 ||
    isCancelling
  )
}

const payrollScheduleOptions = {
  BI_WEEKLY: 'Bi-Weekly',
  BI_MONTHLY: 'Semi Monthly',
  MONTHLY: 'Monthly',
}

export default {
  monthlyPreTaxParking,
  monthlyPreTaxTransit,
  programHasChanges,
  payrollScheduleOptions,
}
