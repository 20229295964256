import FlexContainer from 'core-system/FlexContainer'
import Radio from 'core-system/Radio'
import Text from 'core-system/Text'
import React from 'react'
import styled, { css } from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div<{
  active?: boolean
  canSelect?: boolean
}>`
  padding: 1.5rem;
  border-radius: 0.9375rem;
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.palette.primary.primaryPurple
        : props.theme.palette.grey.grey3};
  position: relative;
  cursor: ${(props) => (props.canSelect ? 'pointer' : 'default')};
  user-select: none;
  position: relative;

  ${(props) =>
    props.canSelect &&
    !props.active &&
    css`
      &:hover {
        border-color: ${(props) => props.theme.palette.primary.primaryPurple};
        #radioContainer {
          border-color: ${(props) => props.theme.palette.primary.primaryPurple};
        }
        #radioIndicator {
          display: block;
          opacity: 0.3;
        }
      }
    `};

  ${flexbox}
  ${space}
  ${layout}
`

const DisabledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.9375rem;
  background-color: ${(props) => props.theme.palette.grey.grey3};
  opacity: 0.3;
`

interface OptionCardProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  title: string
  active?: boolean
  canSelect?: boolean
}

const OptionCard = React.memo((props: OptionCardProps) => {
  const {
    children,
    title,
    canSelect = true,
    active = false,
    onClick,
    ...rest
  } = props

  return (
    <Container
      active={active}
      canSelect={canSelect}
      onClick={canSelect ? onClick : undefined}
      {...rest}
    >
      {!canSelect && !active && <DisabledContainer />}
      <FlexContainer alignItems='start' justifyContent='space-between'>
        <Text variant='action2'>{title}</Text>
        <Radio id='radioContainer' active={active} onClick={onClick} />
      </FlexContainer>
      {children}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OptionCard.displayName = 'OptionCard'
}

export default OptionCard
