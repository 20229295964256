import React from 'react'
import * as ModeTypeIcons from 'core-system/Icons/modeTypes'

export const tripModeTypes = {
  BIKE: {
    baseId: 'BIKE',
    name: 'Bike',
    icon: <ModeTypeIcons.Bike />,
    hasProviders: false,
  },
  BIKESHARE: {
    baseId: 'BIKESHARE',
    name: 'Bikeshare',
    icon: <ModeTypeIcons.Bike />,
    hasProviders: true,
  },
  BUS: {
    baseId: 'BUS',
    name: 'Bus',
    icon: <ModeTypeIcons.Bus />,
    hasProviders: true,
  },
  CAR: {
    baseId: 'CAR',
    name: 'Car',
    icon: <ModeTypeIcons.Car />,
    hasProviders: false,
  },
  CARPOOL: {
    baseId: 'CARPOOL',
    name: 'Carpool',
    icon: <ModeTypeIcons.Carpool />,
    hasProviders: true,
  },
  CARSHARE: {
    baseId: 'CARSHARE',
    name: 'Carshare',
    icon: <ModeTypeIcons.Car />,
    hasProviders: true,
  },
  SCOOTER: {
    baseId: 'SCOOTER',
    name: 'Scooter',
    icon: <ModeTypeIcons.Scooter />,
    hasProviders: true,
  },
  SCOOTERSHARE: {
    baseId: 'SCOOTERSHARE',
    name: 'Scootershare',
    icon: <ModeTypeIcons.ElectricScooter />,
    hasProviders: true,
  },
  MOPED: {
    baseId: 'MOPED',
    name: 'Moped',
    icon: <ModeTypeIcons.Moped />,
    hasProviders: true,
  },
  MOPEDSHARE: {
    baseId: 'MOPEDSHARE',
    name: 'Mopedshare',
    icon: <ModeTypeIcons.ElectricMoped />,
    hasProviders: true,
  },
  RIDESHARE: {
    baseId: 'RIDESHARE',
    name: 'Rideshare',
    icon: <ModeTypeIcons.Rideshare />,
    hasProviders: true,
  },
  TRANSIT: {
    baseId: 'TRANSIT',
    name: 'Transit',
    icon: <ModeTypeIcons.Transit />,
    hasProviders: true,
  },
  WALK: {
    baseId: 'WALK',
    name: 'Walk',
    icon: <ModeTypeIcons.Walking />,
    hasProviders: false,
  },
  SHUTTLE: {
    baseId: 'SHUTTLE',
    name: 'Shuttle',
    icon: <ModeTypeIcons.Van />,
    hasProviders: true,
  },
  VANPOOL: {
    baseId: 'VANPOOL',
    name: 'Vanpool',
    icon: <ModeTypeIcons.Vanpool />,
    hasProviders: true,
  },
  FERRY: {
    baseId: 'FERRY',
    name: 'Ferry',
    icon: <ModeTypeIcons.Ferry />,
    hasProviders: true,
  },
  COMMRAIL: {
    baseId: 'COMMRAIL',
    name: 'Rail',
    icon: <ModeTypeIcons.Transit />,
    hasProviders: true,
  },
  LITERAIL: {
    baseId: 'LITERAIL',
    name: 'Literail',
    icon: <ModeTypeIcons.Transit />,
    hasProviders: true,
  },
}

export const NoTripReasonDropDownItems = [
  { id: 'WFH', text: 'Work From Home' },
  { id: 'VACATION', text: 'Vacation' },
  { id: 'SICK', text: 'Sick' },
  { id: 'OTHER', text: 'Other' },
]
