import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='40' height='40' rx='8' fill='#D4E9FF' />
    <path
      d='M30.6829 29.1829C30.6829 29.9099 30.0929 30.5 29.3658 30.5H27.3902C26.6632 30.5 26.0732 29.9099 26.0732 29.1829V23.9146C26.0732 23.1876 26.6632 22.5975 27.3902 22.5975H29.3658C30.0929 22.5975 30.6829 23.1876 30.6829 23.9146V29.1829ZM14.2195 29.1829C14.2195 29.9099 13.6294 30.5 12.9024 30.5H10.9268C10.1998 30.5 9.60974 29.9099 9.60974 29.1829V23.9146C9.60974 23.1876 10.1998 22.5975 10.9268 22.5975H12.9024C13.6294 22.5975 14.2195 23.1876 14.2195 23.9146V29.1829Z'
      fill='#292F33'
    />
    <path
      d='M14.2195 10.7439C16.8536 10.0854 23.439 10.0854 26.0732 10.7439C28.6289 11.3827 29.3658 17.9878 29.3658 17.9878C29.3658 17.9878 30.6829 17.9878 30.6829 20.622V25.8902C30.6829 25.8902 30.6045 27.8856 28.0488 28.5244C25.4146 29.1829 23.439 29.1829 20.1463 29.1829C16.8536 29.1829 14.878 29.1829 12.2439 28.5244C9.68877 27.8856 9.60974 25.8902 9.60974 25.8902V20.622C9.60974 20.622 9.60974 17.9878 10.9268 17.9878C10.9268 17.9878 11.6644 11.3827 14.2195 10.7439Z'
      fill='#947BFF'
    />
    <path
      d='M20.1463 17.3293C22.7179 17.3293 25.8249 17.461 28.0488 17.6988L27.3902 14.0366C26.7317 12.061 22.7805 12.061 20.1463 12.061C17.5122 12.061 13.561 12.061 12.9024 14.0366L12.2439 17.6988C14.4678 17.461 17.5748 17.3293 20.1463 17.3293Z'
      fill='#DBDBFF'
    />
    <path
      d='M11.5853 17.6586C11.5853 18.2045 11.1435 18.6464 10.5975 18.6464H9.28047C8.73454 18.6464 8.29266 18.2045 8.29266 17.6586C8.29266 17.1127 8.73454 16.6708 9.28047 16.6708H10.5975C11.1435 16.6708 11.5853 17.1127 11.5853 17.6586ZM28.7073 17.6586C28.7073 18.2045 29.1498 18.6464 29.6951 18.6464H31.0122C31.5574 18.6464 32 18.2045 32 17.6586C32 17.1127 31.5574 16.6708 31.0122 16.6708H29.6951C29.1498 16.6708 28.7073 17.1127 28.7073 17.6586Z'
      fill='#947BFF'
    />
    <path
      d='M15.5366 22.7623C15.5366 23.4897 14.6522 24.0793 13.561 24.0793H12.9024C11.8112 24.0793 10.9268 23.4897 10.9268 22.7623C10.9268 22.0348 11.8112 21.4452 12.9024 21.4452H13.561C14.6522 21.4452 15.5366 22.0348 15.5366 22.7623ZM29.3658 22.7623C29.3658 23.4897 28.4808 24.0793 27.3902 24.0793H26.7317C25.6412 24.0793 24.7561 23.4897 24.7561 22.7623C24.7561 22.0348 25.6412 21.4452 26.7317 21.4452H27.3902C28.4808 21.4452 29.3658 22.0348 29.3658 22.7623Z'
      fill='#FFE68B'
    />
    <path
      d='M20.1463 26.5488C16.417 26.5488 11.8231 26.4006 11.1198 25.6973C10.8623 25.4398 10.8623 25.0236 11.1198 24.7661C11.3463 24.5389 11.6973 24.5119 11.9541 24.6858C12.1069 24.7516 13.4589 25.2317 20.1463 25.2317C26.8338 25.2317 28.1857 24.7516 28.3385 24.6858C28.596 24.5119 28.9457 24.5389 29.1729 24.7661C29.4304 25.0236 29.4304 25.4398 29.1729 25.6973C28.4696 26.4006 23.8756 26.5488 20.1463 26.5488Z'
      fill='#BEB3FF'
    />
    <mask
      id='mask0'
      // style='mask-type:alpha'
      maskUnits='userSpaceOnUse'
      x='12'
      y='12'
      width='17'
      height='6'
    >
      <path
        d='M20.1463 17.3293C22.7179 17.3293 25.8249 17.461 28.0488 17.6988L27.3902 14.0366C26.7317 12.061 22.7805 12.061 20.1463 12.061C17.5122 12.061 13.561 12.061 12.9024 14.0366L12.2439 17.6988C14.4678 17.461 17.5748 17.3293 20.1463 17.3293Z'
        fill='#DBDBFF'
      />
    </mask>
    <g mask='url(#mask0)'>
      <rect
        x='12.639'
        y='14.8599'
        width='6.58537'
        height='7.2439'
        rx='2.63415'
        fill='#5E5EAA'
      />
      <rect
        x='12.639'
        y='14.8599'
        width='6.58537'
        height='7.2439'
        rx='2.63415'
        fill='#5E5EAA'
      />
      <rect
        x='20.5414'
        y='14.8599'
        width='6.58537'
        height='7.2439'
        rx='2.63415'
        fill='#5E5EAA'
      />
      <path
        d='M13.5291 22.1691C13.4146 22.2472 13.2806 22.1594 13.2156 22.1055C13.0316 21.9536 12.8452 21.5503 12.919 21.0659C12.9937 20.5766 13.0156 20.0054 13.2456 19.058C13.2723 18.9473 13.2122 17.7106 13.3485 16.8602C13.3854 16.6307 13.8135 16.3428 14.0742 16.3472C14.3343 16.3511 14.2018 16.8054 14.2071 16.8816C14.2654 17.698 14.0382 18.9585 14.014 19.1298C13.8795 20.0791 13.5126 20.5058 13.6373 21.1343C13.7029 21.4639 13.7121 21.7619 13.5199 21.7871C13.5223 21.9648 13.5713 22.1405 13.5291 22.1691ZM18.3322 22.1691C18.4468 22.2472 18.5807 22.1594 18.6458 22.1055C18.8297 21.9536 19.0161 21.5503 18.9423 21.0659C18.8676 20.5766 18.8457 20.0054 18.6157 19.058C18.589 18.9473 18.6492 17.7106 18.5128 16.8602C18.4759 16.6307 18.0478 16.3428 17.7872 16.3472C17.527 16.3511 17.6595 16.8054 17.6542 16.8816C17.5959 17.698 17.8231 18.9585 17.8473 19.1298C17.9818 20.0791 18.3487 20.5058 18.224 21.1343C18.1585 21.4639 18.1492 21.7619 18.3414 21.7871C18.339 21.9648 18.29 22.1405 18.3322 22.1691Z'
        fill='#D4AB88'
      />
      <path
        d='M18.0497 15.8124C17.7075 15.4003 15.9306 15.1091 15.9306 15.1091C15.9306 15.1091 14.1382 15.4003 13.796 15.8124C13.3805 16.3128 13.1641 17.1583 13.1641 17.1583L14.0295 17.3127C14.0295 17.3127 14.2474 18.0951 14.2474 18.8406C14.2474 19.5861 14.0542 21.4164 14.0542 21.4164H17.808C17.808 21.4164 17.5993 19.5526 17.5993 18.8411C17.5993 18.1295 17.8327 17.3127 17.8327 17.3127L18.6826 17.1583C18.6816 17.1583 18.4647 16.3123 18.0497 15.8124Z'
        fill='#3A05B8'
      />
      <path
        d='M16.5335 11.6179C16.2471 11.5252 15.5725 11.5504 15.404 11.7698C14.9658 11.778 14.4518 12.1746 14.3843 12.6968C14.3173 13.2142 14.4663 13.4545 14.5192 13.8432C14.5789 14.284 14.827 14.4247 15.025 14.4839C15.3099 14.8606 15.6128 14.8441 16.1214 14.8441C17.1145 14.8441 17.5877 14.1796 17.6294 13.0507C17.6547 12.3682 17.2538 11.8508 16.5335 11.6179Z'
        fill='#963B22'
      />
      <path
        d='M15.4496 14.5607V15.412C15.4496 15.6469 15.6865 15.8377 15.9792 15.8377C16.2718 15.8377 16.5087 15.6469 16.5087 15.412V14.5607H15.4496Z'
        fill='#D4AB88'
      />
      <path
        d='M15.4496 15.0887C15.6418 15.2106 16.0326 15.3591 16.5087 15.0742V14.5607H15.4496V15.0887Z'
        fill='#BC9675'
      />
      <path
        d='M17.1013 13.0992C17.0052 12.9662 16.882 12.8589 16.6126 12.8211C16.7135 12.8677 16.8106 13.0273 16.8232 13.1162C16.8358 13.2045 16.8485 13.2763 16.7684 13.188C16.4476 12.8332 16.0981 12.973 15.752 12.7565C15.5103 12.6051 15.4366 12.4376 15.4366 12.4376C15.4366 12.4376 15.4069 12.6609 15.0405 12.8885C14.9342 12.9545 14.8075 13.1016 14.7372 13.3185C14.6867 13.4744 14.7022 13.6136 14.7022 13.8515C14.7022 14.5451 15.274 15.1285 15.9792 15.1285C16.6844 15.1285 17.2562 14.5402 17.2562 13.8515C17.2562 13.4195 17.211 13.2506 17.1013 13.0992Z'
        fill='#D4AB88'
      />
      <path
        d='M21.4315 22.1691C21.317 22.2472 21.183 22.1594 21.118 22.1055C20.934 21.9536 20.7476 21.5503 20.8214 21.0659C20.8961 20.5766 20.918 20.0054 21.1481 19.058C21.1747 18.9473 21.1146 17.7106 21.2509 16.8602C21.2878 16.6307 21.7159 16.3428 21.9766 16.3472C22.2367 16.3511 22.1042 16.8054 22.1095 16.8816C22.1678 17.698 21.9406 18.9585 21.9164 19.1298C21.7819 20.0791 21.415 20.5058 21.5397 21.1343C21.6053 21.4639 21.6145 21.7619 21.4223 21.7871C21.4247 21.9648 21.4737 22.1405 21.4315 22.1691ZM26.2346 22.1691C26.3492 22.2472 26.4831 22.1594 26.5482 22.1055C26.7321 21.9536 26.9185 21.5503 26.8447 21.0659C26.77 20.5766 26.7481 20.0054 26.5181 19.058C26.4914 18.9473 26.5516 17.7106 26.4152 16.8602C26.3783 16.6307 25.9502 16.3428 25.6896 16.3472C25.4294 16.3511 25.5619 16.8054 25.5566 16.8816C25.4983 17.698 25.7255 18.9585 25.7497 19.1298C25.8842 20.0791 26.2511 20.5058 26.1264 21.1343C26.0609 21.4639 26.0516 21.7619 26.2438 21.7871C26.2414 21.9648 26.1924 22.1405 26.2346 22.1691Z'
        fill='#F0D4C4'
      />
      <path
        d='M25.9522 15.8124C25.61 15.4003 23.8331 15.1091 23.8331 15.1091C23.8331 15.1091 22.0406 15.4003 21.6985 15.8124C21.283 16.3128 21.0665 17.1583 21.0665 17.1583L21.9319 17.3127C21.9319 17.3127 22.1499 18.0951 22.1499 18.8406C22.1499 19.5861 21.9567 21.4164 21.9567 21.4164H25.7104C25.7104 21.4164 25.5017 19.5526 25.5017 18.8411C25.5017 18.1295 25.7352 17.3127 25.7352 17.3127L26.5851 17.1583C26.5841 17.1583 26.3671 16.3123 25.9522 15.8124Z'
        fill='#FF7076'
      />
      <path
        d='M24.4359 11.6179C24.1495 11.5252 23.4749 11.5504 23.3065 11.7698C22.8682 11.778 22.3542 12.1746 22.2867 12.6968C22.2197 13.2142 22.3687 13.4545 22.4216 13.8432C22.4813 14.284 22.7294 14.4247 22.9274 14.4839C23.2123 14.8606 23.5152 14.8441 24.0238 14.8441C25.0169 14.8441 25.4901 14.1796 25.5318 13.0507C25.5571 12.3682 25.1562 11.8508 24.4359 11.6179Z'
        fill='#60352A'
      />
      <path
        d='M23.3521 14.5607V15.412C23.3521 15.6469 23.5889 15.8377 23.8816 15.8377C24.1742 15.8377 24.4111 15.6469 24.4111 15.412V14.5607H23.3521Z'
        fill='#F0D4C4'
      />
      <path
        d='M23.3521 15.0887C23.5443 15.2106 23.935 15.3591 24.4111 15.0742V14.5607H23.3521V15.0887Z'
        fill='#EEC2AD'
      />
      <path
        d='M25.0037 13.0992C24.9076 12.9662 24.7844 12.8589 24.515 12.8211C24.6159 12.8677 24.713 13.0273 24.7256 13.1162C24.7383 13.2045 24.7509 13.2763 24.6708 13.188C24.35 12.8332 24.0005 12.973 23.6544 12.7565C23.4127 12.6051 23.339 12.4376 23.339 12.4376C23.339 12.4376 23.3094 12.6609 22.9429 12.8885C22.8366 12.9545 22.7099 13.1016 22.6396 13.3185C22.5891 13.4744 22.6046 13.6136 22.6046 13.8515C22.6046 14.5451 23.1764 15.1285 23.8816 15.1285C24.5868 15.1285 25.1586 14.5402 25.1586 13.8515C25.1586 13.4195 25.1134 13.2506 25.0037 13.0992Z'
        fill='#F0D4C4'
      />
    </g>
  </>,
  'Carpool',
  '0 0 40 40'
)
