import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Select, { SelectItemProps } from './Select'
import TextField from 'core-system/TextField/TextField'
import styled from 'styled-components'

const DropdownContainer = styled.div<{ width?: string }>`
  position: relative;
  width: ${(props) => props.width || '100%'};
`

interface SearchableDropdownOption {
  id: string
  text: string
}

interface SearchableDropdownProps {
  options: SelectItemProps[]
  itemCallback: (item: SelectItemProps) => void
  width?: string
}

const SearchableDropdown = React.memo((props: SearchableDropdownProps) => {
  const { options, itemCallback, width } = props

  const [searchInput, setSearchInput] = useState<string>('')
  const [filteredOptions, setFilteredOptions] =
    useState<SearchableDropdownOption[]>(options)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const dropdownContainerRef = useRef(null)

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchInput = event.target.value.toLowerCase()
    setSearchInput(searchInput)
    setDropdownOpen(true)

    const filtered = options.filter((option) =>
      String(option.text).toLowerCase().includes(searchInput)
    )
    setFilteredOptions(filtered)
  }

  const handleSearchBarClick = () => {
    if (dropdownOpen) {
      return
    }
    setDropdownOpen(true)
    if (searchInput.length === 0) {
      setFilteredOptions(options)
    } else {
      const filtered = options.filter((option) =>
        String(option.text).toLowerCase().includes(searchInput.toLowerCase())
      )
      setFilteredOptions(filtered)
    }
    itemCallback(null)
  }

  const handleOptionClick = (option: SelectItemProps) => {
    setSearchInput(option.text)
    itemCallback(option)
    setDropdownOpen(false)
  }

  // Close dropdown on mouse click outside dropdown
  const clickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        dropdownContainerRef &&
        dropdownContainerRef.current &&
        dropdownContainerRef.current.contains(e.target)
      ) {
        return
      }
      setDropdownOpen(false)
    },
    [dropdownContainerRef]
  )

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside, false)
    return () => {
      document.removeEventListener('mousedown', clickOutside, false)
    }
  }, [clickOutside])

  return (
    <DropdownContainer width={width} ref={dropdownContainerRef}>
      <TextField
        value={searchInput}
        placeholder='Select a Commuter'
        onChange={handleSearch}
        onClick={handleSearchBarClick}
        borderRadius={dropdownOpen ? '0.5rem 0.5rem 0 0' : null}
      />
      <Select
        items={filteredOptions}
        open={dropdownOpen}
        itemCallback={handleOptionClick}
        setIsOpen={setDropdownOpen}
        selectorMaxHeight='20rem'
      />
    </DropdownContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SearchableDropdown.displayName = 'SearchableDropdown'
}

export default SearchableDropdown
