import React, { useEffect, useState } from 'react'
import Portal from '../Portal'
import refExists from '../../shared/refExists'
import useGlobalScroll from '../../shared/Hooks/useGlobalScroll'
import styled from 'styled-components'
import {
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  shadow,
  ShadowProps,
  variant,
} from 'styled-system'

import { color, ColorProps } from '../@styled-system/Color'

// Available position options
type position = 'left' | 'right' | 'top' | 'bottom'

// Outer container holding the tooltip
const OuterContainer = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.tooltip};
`

// Main tooltip styling
const Container = styled.div<
  { position: position; isMultiLine: boolean } & ColorProps &
    BorderProps &
    FlexboxProps &
    SpaceProps &
    ShadowProps &
    LayoutProps
>`
  // Container styling
  position: relative;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border-radius: 0.25rem;
  padding: ${(props) => (props.isMultiLine ? '1rem' : '0.5rem 1rem')};
  max-width: ${(props) => props.theme.pxToRem(212)};
  text-align: left;

  // Contents & text
  ${(props) => props.theme.typography.action4}
  color: ${(props) => props.theme.palette.white};
  display: flex;
  align-items: center;
  justify-content: center;

  // Shared styles for the arrow, regardless of position
  &::after,
  &::before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  // Inner part of arrow
  &::after {
    border-width: 0.5rem;
  }

  // Border of arrow (if desired)
  &::before {
    ${border}
    border-width: 0.5625rem;
  }

  // Accepts styled-system props
  ${space}
  ${layout}
  ${color}
  ${border}
  ${flexbox}
  ${shadow}

  // Arrow positioning
  ${(props) =>
    variant({
      prop: 'position',
      variants: {
        // Tooltip to the right of the target
        right: {
          '&::after, &::before': {
            right: '100%',
            top: '50%',
          },
          '&::after': {
            borderRightColor: props.bg
              ? props.bg
              : props.theme.palette.text.secondary,
            marginTop: '-0.5rem',
          },
          '&::before': {
            borderLeftColor: 'transparent',
            borderTopColor: 'transparent',
            borderBottomColor: 'transparent',
            marginTop: '-0.5625rem',
          },
        },
        // Tooltip to the left of the target
        left: {
          '&::after, &::before': {
            left: '100%',
            top: '50%',
          },
          '&::after': {
            borderLeftColor: props.bg
              ? props.bg
              : props.theme.palette.text.secondary,
            marginTop: '-0.5rem',
          },
          '&::before': {
            borderRightColor: 'transparent',
            borderTopColor: 'transparent',
            borderBottomColor: 'transparent',
            marginTop: '-0.5625rem',
          },
        },
        // Tooltip in the middle, below the target
        bottom: {
          '&::after, &::before': {
            left: '50%',
            bottom: '100%',
          },
          '&::after': {
            borderBottomColor: props.bg
              ? props.bg
              : props.theme.palette.text.secondary,
            marginLeft: '-0.5rem',
          },
          '&::before': {
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
            borderTopColor: 'transparent',
            marginLeft: '-0.5625rem',
          },
        },
        // Tooltip above the target
        top: {
          '&::after, &::before': {
            left: '50%',
            top: '100%',
          },
          '&::after': {
            borderTopColor: props.bg
              ? props.bg
              : props.theme.palette.text.secondary,
            marginLeft: '-0.5rem',
          },
          '&::before': {
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent',
            marginLeft: '-0.5625rem',
          },
        },
      },
    })}
`
const POSITION_BUFFER = 8

function getLeft(
  targetRect: ClientRect | DOMRect,
  tooltipRect: ClientRect | DOMRect,
  position: position
): number {
  if (position === 'left') {
    return targetRect.left - POSITION_BUFFER - tooltipRect.width
  }

  if (position === 'right') {
    return targetRect.right + POSITION_BUFFER
  }

  const leftPosition =
    targetRect.left + targetRect.width / 2 - tooltipRect.width / 2

  if (leftPosition <= 0) {
    return 0
  }

  if (leftPosition + tooltipRect.width > window.innerWidth) {
    return window.innerWidth - tooltipRect.width
  }

  return leftPosition
}

function getTop(
  targetRect: ClientRect | DOMRect,
  tooltipRect: ClientRect | DOMRect,
  position: position
): number {
  if (position === 'bottom') {
    return targetRect.top + targetRect.height + POSITION_BUFFER
  } else if (position === 'top') {
    return targetRect.top - targetRect.height / 2 - POSITION_BUFFER
  }
  return targetRect.top + targetRect.height / 2 - tooltipRect.height / 2
}

export interface TooltipProps
  extends FlexboxProps,
    SpaceProps,
    ColorProps,
    BorderProps,
    ShadowProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  show: boolean
  target: React.RefObject<HTMLElement>
  position?: position
  isMultiLine?: boolean
}

// Tooltip begins
const Tooltip = (props: TooltipProps) => {
  const {
    target,
    show,
    position = 'bottom',
    children,
    isMultiLine = false,
    ...rest
  } = props

  const [showState, setShowState] = useState(show)
  const tooltip = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    setShowState(show)
  }, [show])

  const hideTooltip = () => {
    if (showState) {
      setShowState(false)
    }
  }

  useGlobalScroll(() => hideTooltip())

  React.useLayoutEffect(() => {
    // Handle Position Logic
    if (showState && refExists(target.current) && refExists(tooltip.current)) {
      const targetRect = target.current.getBoundingClientRect()
      const tooltipRect = tooltip.current.getBoundingClientRect()
      const left = getLeft(targetRect, tooltipRect, position)
      const top = getTop(targetRect, tooltipRect, position)

      tooltip.current.style.left = `${left}px`
      tooltip.current.style.top = `${top}px`
    }
  }, [showState, position, target])

  if (!showState) return null
  return (
    <Portal>
      <OuterContainer ref={tooltip}>
        <Container position={position} isMultiLine={isMultiLine} {...rest}>
          {children}
        </Container>
      </OuterContainer>
    </Portal>
  )
}

export default Tooltip

if (process.env.NODE_ENV !== 'production') {
  Tooltip.displayName = 'Tooltip'
}
