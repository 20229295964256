import { AxiosResponse } from 'axios'
import Request from 'redux/config/services/Request'
import { SegmentParams } from 'redux/employer/employerTypes'
import { FlexProgram, FlexProgramUpdate } from 'redux/flex/flexTypes'
import {
  SegmentAndType,
  SegmentAndTypeAndDate,
} from 'redux/programs/programsTypes'

const getFlexRecommendations = (
  payload: SegmentParams
): Promise<AxiosResponse> => {
  return Request.get(`/hr/programs/merchants/?segment=${payload.segmentId}`)
}

const createFlexProgram = (payload: FlexProgram): Promise<AxiosResponse> => {
  return Request.post('/hr/programs/card_programs/', payload)
}

const updateFlexProgram = (
  payload: FlexProgramUpdate
): Promise<AxiosResponse> => {
  const { programId, ...rest } = payload
  return Request.put(`/hr/programs/card_programs/${programId}/`, rest)
}

const cancelFlexProgram = (programId: string): Promise<AxiosResponse> => {
  return Request.del(`/hr/programs/card_programs/${programId}/`)
}

const getFlexDashboardStats = (
  segmentAndType: SegmentAndType
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/programs/card_programs/dashboard/?segment=${segmentAndType.segmentId}&type=${segmentAndType.type}`
  )
}

const getFlexDashboardServices = (
  segmentAndTypeAndDate: SegmentAndTypeAndDate
): Promise<AxiosResponse> => {
  return Request.post('/hr/programs/card_programs/services/', {
    rangeEnd: segmentAndTypeAndDate.rangeEnd,
    rangeStart: segmentAndTypeAndDate.rangeStart,
    segment: segmentAndTypeAndDate.segmentId,
    type: segmentAndTypeAndDate.type,
  })
}

export default {
  getFlexRecommendations,
  createFlexProgram,
  updateFlexProgram,
  cancelFlexProgram,
  getFlexDashboardStats,
  getFlexDashboardServices,
}
