export const GRHCopy = {
  description:
    'Guaranteed Ride Home (GRH) is a program that offers commuters a sure way to get to and from work, no matter what.',
  howToUseSteps: [
    'Click Request GRH and provide a reason.',
    'Once submitted, your Fleet card will be immediately activated for GRH',
    'Simply use your Fleet card on any available service below and your next transaction will be covered by the GRH program up to the maximum per transaction limit.',
  ],
  reminder:
    'Your next transaction needs to be GRH eligible for the program to activate.',
  noGRHProgram:
    'Your employer has not activated a GRH program that you can use. Please contact your employer to get one set up.',
}

export const GRHReasons = [
  'Family Emergency',
  'Illness/Injury',
  'Unplanned Overtime',
  'Missed Carpool/Vanpool',
  'Other',
]
