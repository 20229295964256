import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <svg
      width='114'
      height='38'
      viewBox='0 0 114 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48.6615 17.3142H43.6053V29.1016H38.4859V17.3142H34.6621V13.3009H38.4859V12.3844C38.4859 8.49738 41.3616 6.66455 44.9009 6.66455C46.3231 6.66455 47.5555 6.98065 48.4404 7.26496L47.6503 10.9308C46.9551 10.7727 46.2913 10.7095 45.8806 10.7095C44.648 10.7095 43.6053 11.2151 43.6053 12.5741V13.3009H48.6615V17.3142Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.6523 29.1018H55.7717V6.66479H50.6523V29.1018Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.5772 19.2103H72.0463C71.762 17.8831 70.3399 16.9666 67.9066 16.9666C65.4102 16.9666 64.0196 17.9463 63.5772 19.2103ZM77.2291 23.7609C76.6287 27.1423 73.3421 29.4175 68.1911 29.4175C62.2816 29.4175 58.2998 26.2575 58.2998 21.3277C58.2998 16.1449 62.5027 12.9849 67.9066 12.9849C72.552 12.9849 77.071 15.2602 77.1027 21.2011C77.1027 21.6754 77.071 22.1492 77.0078 22.6864H63.4508C63.7985 24.2665 65.4102 25.4358 68.1911 25.4358C70.4032 25.4358 71.762 24.8354 72.2045 23.7609H77.2291Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M84.6885 19.2103H93.1577C92.8733 17.8831 91.4512 16.9666 89.0177 16.9666C86.5213 16.9666 85.131 17.9463 84.6885 19.2103ZM98.3402 23.7609C97.74 27.1423 94.4534 29.4175 89.3025 29.4175C83.3927 29.4175 79.4111 26.2575 79.4111 21.3277C79.4111 16.1449 83.614 12.9849 89.0177 12.9849C93.6633 12.9849 98.1823 15.2602 98.2139 21.2011C98.2139 21.6754 98.1823 22.1492 98.1191 22.6864H84.5619C84.9096 24.2665 86.5213 25.4358 89.3025 25.4358C91.5144 25.4358 92.8733 24.8354 93.3158 23.7609H98.3402Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M103.808 24.1718V17.3143H99.8574V13.3008H101.406C103.365 13.3008 104.187 12.5424 104.187 10.8044V8.84497H108.927V13.3008H113.888V17.3143H108.927V23.2553C108.927 24.1718 109.085 25.4042 111.329 25.4042C111.834 25.4042 112.529 25.341 113.13 25.2147V29.0383C112.277 29.2596 110.981 29.4176 109.717 29.4176C105.23 29.4176 103.808 26.9527 103.808 24.1718Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.3656 0.215088L18.6842 0.227057L0 18.9113L5.86109 24.7726L24.4113 6.22255V2.1266L24.3656 0.215088Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.2743 26.8036L24.4024 21.6755L24.3655 15.8684L18.7112 15.9075L7.85352 26.7651L18.8521 37.7635L24.4114 37.7609V31.9406L19.2743 26.8036Z'
        fill='white'
      />
    </svg>
  </>,
  'FleetLogoWhite',
  '0 0 114 38'
)
