import Divider from 'core-system/Divider/Divider'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import styled from 'styled-components'

const SectionItems = styled.div`
  div:last-of-type {
    margin-bottom: 0;
  }
`

interface MyCommuterDetailsOverviewSectionProps {
  title: string
  spendHeading: string
  items: any
  additionalItems?: any
}

const MyCommuterDetailsOverviewSection = React.memo(
  (props: MyCommuterDetailsOverviewSectionProps) => {
    const { title, spendHeading, items, additionalItems } = props

    return (
      <FlexContainer width='100%' flexDirection='column'>
        <FlexContainer
          marginBottom='0.5rem'
          marginTop='0.375rem'
          alignItems='center'
          justifyContent='space-between'
        >
          <Text variant='action2'>{title}</Text>
          <Text variant='body1' textColor={palette.text.secondary}>
            {spendHeading}
          </Text>
        </FlexContainer>
        <SectionItems>{items}</SectionItems>
        {additionalItems ? (
          <SectionItems>{additionalItems}</SectionItems>
        ) : null}
        <Divider margin='1rem 0' />
      </FlexContainer>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommuterDetailsOverviewSection.displayName =
    'MyCommuterDetailsOverviewSection'
}

export default MyCommuterDetailsOverviewSection
