import React from 'react'
import Dropdown from 'core-system/Dropdown'
import Text from 'core-system/Text'
import FlexContainer from 'core-system/FlexContainer'
import { getTimeSelectorOptions } from '../utils'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'

interface TimeSelectorProps {
  value: string
  onChange: (value: string) => void
  label?: string
  timeInterval?: 5 | 10 | 15 | 30
}

const TimeSelector = React.memo((props: TimeSelectorProps) => {
  const { value, onChange, label, timeInterval } = props

  const timeOptions = timeInterval
    ? getTimeSelectorOptions(timeInterval)
    : getTimeSelectorOptions()

  return (
    <FlexContainer flexDirection='column'>
      <Text
        variant='body1'
        textColor={palette.text.secondary}
        style={{ marginBottom: '0.25rem' }}
      >
        {label}
      </Text>
      <Dropdown
        data-cy='carpool-edit-timeselector'
        active={value}
        items={timeOptions}
        itemCallback={(item) => onChange(item.id)}
        selectorMaxHeight='10rem'
        usePortal={true}
        width={pxToRem(150)}
        marginBottom={0}
        placeholder='Select a time...'
      />
    </FlexContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TimeSelector.displayName = 'TimeSelector'
}

export default TimeSelector
