import React, { useCallback } from 'react'
import employeePlatformService from 'redux/employeePlatform/employeePlatformService'
import axios from 'axios'

interface FileUploadProps {
  onUploadSuccess: () => void
  onUploadError: () => void
}

const UploadReceiptDropbox: React.FC<FileUploadProps> = ({
  onUploadSuccess,
  onUploadError,
}) => {
  const handleFileDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      const files = event.dataTransfer.files
      if (files.length > 0) {
        const file = files[0]

        // Request upload URL
        const response = await employeePlatformService.getUploadReceiptUrl()

        if (response && response.data) {
          // Use the upload URL to upload the file
          const uploadUrl = response.data.url
          try {
            // Get the file type to set the appropriate Content-Type header
            const contentType = file.type

            const response = await axios.put(uploadUrl, file, {
              headers: {
                'Content-Type': contentType, // Set Content-Type to the type of the file (e.g., "image/jpeg")
              },
            })

            if (response.status === 200) {
              onUploadSuccess()
            } else {
              onUploadError()
            }
          } catch (error) {
            onUploadError()
          }
        }
      }
    },
    [onUploadSuccess, onUploadError]
  )

  return (
    <div
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
      style={{
        border: '2px dashed #ccc',
        padding: '20px',
        textAlign: 'center',
      }}
    >
      Drop your file here
    </div>
  )
}

export default UploadReceiptDropbox
