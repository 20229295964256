import FlexContainer from 'core-system/FlexContainer'
import Loading from 'core-system/Loading'
import Modal, { ModalBody, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import TransitLogo from 'core-system/TransitLogo'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { Merchant, ServiceModeType } from 'redux/programs/programsTypes'
import styled, { css } from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import InfoTag from '../InfoTag'
import ModeTypesSection from './ModeTypesSection'
import ProviderPartnerBenefits from './ProviderPartnerBenefits'

const ProviderOrgSection = styled.div<SpaceProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1.5rem 3.375rem;
  background-color: ${palette.secondary.purple7};

  ${space}
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.5rem;
  gap: 0.5rem;
`

const StyledLink = styled.a`
  cursor: pointer;
  font-weight: normal;
  line-height: normal;
  color: ${palette.primary.primaryPurple};

  &:hover {
    color: ${palette.text.primary};
  }
`

const LinksContainer = styled.div<{ hasLeftBorder?: boolean }>`
  display: flex;
  gap: 1rem;

  ${(props) =>
    props.hasLeftBorder &&
    css`
      margin-left: 1rem;

      > div:first-child {
        border-left: 0.0625rem solid ${palette.grey.grey3};
        padding-left: 1rem;
      }
    `}
`

interface LinkProps {
  url?: string
  text?: string
}
const Link = React.memo((props: LinkProps) => {
  const { url, text } = props
  if (!url) return null
  return (
    <Text variant='hyperlink'>
      <StyledLink href={url} target='_blank' rel='noopener noreferrer'>
        {text}
      </StyledLink>
    </Text>
  )
})
Link.displayName = 'Link'

interface ProviderCatalogDetailModalProps {
  merchant: Merchant
  isModalOpen: boolean
  setIsModalOpen: any
}

const ProviderCatalogDetailModal = React.memo(
  (props: ProviderCatalogDetailModalProps) => {
    const { merchant, isModalOpen, setIsModalOpen } = props

    return (
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        width={pxToRem(944)}
      >
        <ModalHeader title='Service Provider Details' />
        {!merchant ? (
          <Loading />
        ) : (
          <ModalBody padding='0' maxHeight={pxToRem(600)}>
            <ProviderOrgSection>
              <FlexContainer>
                <TransitLogo
                  size='xl'
                  modeType={
                    merchant.iconUrl
                      ? 'custom'
                      : (merchant.modeTypes[0] as ServiceModeType)
                  }
                  src={merchant.iconUrl || merchant.modeTypes[0]}
                />
                <InfoContainer>
                  <Text variant='h5'>{merchant.name}</Text>
                  <FlexContainer>
                    {merchant.fleetPartner && <InfoTag>fleet partner</InfoTag>}
                    <LinksContainer hasLeftBorder={merchant.fleetPartner}>
                      <Link url={merchant.websiteUrl} text='Visit Website' />
                      <Link
                        url={merchant.setupInstructions}
                        text='Setup Instructions'
                      />
                      <Link
                        url={merchant.refundPolicies}
                        text='Refund Policy'
                      />
                    </LinksContainer>
                  </FlexContainer>
                </InfoContainer>
              </FlexContainer>
              {merchant.description && (
                <Text variant='body1' marginTop='1rem'>
                  {merchant.description}
                </Text>
              )}
              <ProviderPartnerBenefits merchant={merchant} marginTop='1rem' />
            </ProviderOrgSection>
            <ModeTypesSection merchant={merchant} />
          </ModalBody>
        )}
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProviderCatalogDetailModal.displayName = 'Provider Catalog Detail Modal'
}

export default ProviderCatalogDetailModal
