import React, { useMemo, useState } from 'react'
import ChartTypes, { Chart } from 'chart.js'
import palette from '../../Themes/palette'
import { LayoutProps, layout, SpaceProps, space } from 'styled-system'
import styled from 'styled-components'

interface Props
  extends ChartTypes.ChartConfiguration,
    LayoutProps,
    SpaceProps {}

const ChartContainer = styled.div<LayoutProps & SpaceProps>`
  display: flex;
  align-items: center;
  position: relative;
  ${layout}
  ${space}
`

const RadialChart: React.FC<Props> = ({
  type,
  data,
  options,
  plugins,
  ...props
}) => {
  const ref = React.useRef<HTMLCanvasElement>(null)
  const [chartInstance, setChartInstance] = useState(undefined)

  const canvas = useMemo(() => {
    return <canvas id='myChart' width='100%' height='100%' ref={ref}></canvas>
  }, [ref])

  React.useEffect(() => {
    if (ref.current !== null) {
      if (chartInstance) chartInstance.destroy()
      setChartInstance(
        new Chart(ref.current.getContext('2d'), {
          type: type,
          data: data,
          options: options,
          plugins: plugins,
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options, type, plugins])

  return <ChartContainer {...props}>{canvas}</ChartContainer>
}

RadialChart.defaultProps = {
  type: 'doughnut',
  data: {
    datasets: [
      {
        data: [10, 20, 30],
        backgroundColor: [
          palette.primary.primaryPurple,
          palette.primary.primaryDark,
          '#61DBD8',
        ],
        borderWidth: 0,
        hoverBorderWidth: 5,
        hoverBorderColor: palette.secondary.purple7,
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ['Label1', 'Label2', 'Label3'],
  },
  options: {
    legend: {
      display: true,
      position: 'right',
      align: 'center',
      labels: {
        usePointStyle: true,
        boxWidth: 10,
        fontColor: '#7E7EC2',
        fontFamily: 'Montserrat',
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return data.labels[tooltipItem.index] as string
        },
        labelTextColor: () => 'fff',
      },
      titleSpacing: 3,
      backgroundColor: palette.primary.primaryPurple,
      displayColors: false,
      caretSize: 0,
      mode: 'single',
    },
    responsive: true,
    cutoutPercentage: 55,
    animation: {
      animateRotate: false,
    },
  },
  width: '200px',
  height: '200px',
}

export default RadialChart
