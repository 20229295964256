import React, { useState, useMemo } from 'react'
import Modal, { ModalBody, ModalHeader, ModalFooter } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import Button from 'core-system/Button'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import { Mode } from 'redux/employeePlatform/employeePlatformTypes'
import Radio from 'core-system/Radio'
import Text from 'core-system/Text'
import { tripModeTypes } from '../TripsUtils'
import AddIcon from 'core-system/Icons/Actions/Add'

const ModeOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
`

const ModeOptionNameIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`

const ModeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
`

const BaseModeOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple5};
  }
`

const renderModeOptions = (
  modes: Mode[],
  selectedModeIds: string[],
  onRadioClick: (modeId: string) => void
) => {
  return modes.map((mode) => {
    if (mode.modeType === mode.name) {
      return null
    }
    return (
      <ModeOptionContainer key={mode.id}>
        <ModeOptionNameIcon>
          <ModeIcon>
            {mode.iconUrl ? (
              <img src={mode.iconUrl} height='100%' width='100%' />
            ) : (
              tripModeTypes[mode.modeType].icon
            )}
          </ModeIcon>
          <Text variant='action3'>
            {mode.name === mode.modeType ? 'Other' : mode.name}
          </Text>
        </ModeOptionNameIcon>
        <Radio
          active={selectedModeIds.includes(mode.id)}
          onClick={() => onRadioClick(mode.id)}
        />
      </ModeOptionContainer>
    )
  })
}

interface SelectServiceProviderModalProps {
  open: boolean
  closeModal: () => void
  selectedModeType: string | null
  setSelectedModeType: (modeType: string | null) => void
  selectedModes: string[]
  setSelectedModes: (modeIds: string[]) => void
}

const SelectServiceProviderModal = React.memo(
  (props: SelectServiceProviderModalProps) => {
    const {
      open,
      closeModal,
      selectedModeType,
      setSelectedModeType,
      selectedModes,
      setSelectedModes,
    } = props

    const { allModes } = useSelector(
      (state: AppState) => state.employeePlatform
    )

    const [selectedModeIds, setSelectedModeIds] =
      useState<string[]>(selectedModes)

    const filteredModes = useMemo(() => {
      if (allModes && selectedModeType) {
        return Object.values(allModes).filter(
          (mode) => mode.modeType === selectedModeType
        )
      }
      return []
    }, [allModes, selectedModeType])

    const baseModeId = useMemo(() => {
      if (filteredModes && selectedModeType) {
        return (
          filteredModes.find((mode) => mode.name === selectedModeType)?.id ||
          null
        )
      }
      return null
    }, [filteredModes, selectedModeType])

    const handleCloseModal = () => {
      setSelectedModeType(null)
      setSelectedModeIds(selectedModes)
      closeModal()
    }

    const handleSubmit = () => {
      // Ensure that we don't add duplicate modes
      setSelectedModes([...new Set([...selectedModes, ...selectedModeIds])])
      handleCloseModal()
    }

    const handleBaseModeOptionClick = () => {
      setSelectedModes([
        ...new Set(baseModeId ? [...selectedModes, baseModeId] : selectedModes),
      ])
      setSelectedModeType(null)
      handleCloseModal()
    }

    const handleModeRadioClick = (modeId: string) => {
      if (selectedModeIds.includes(modeId)) {
        setSelectedModeIds(selectedModeIds.filter((id) => id !== modeId))
      } else {
        setSelectedModeIds([...selectedModeIds, modeId])
      }
    }

    return (
      <Modal
        open={open}
        onClose={() => handleCloseModal()}
        width={pxToRem(450)}
        maxHeight={pxToRem(600)}
      >
        <ModalHeader title='Select Provider(s)' />
        <ModalBody>
          <BaseModeOption onClick={() => handleBaseModeOptionClick()}>
            <ModeOptionNameIcon>
              <ModeIcon>{tripModeTypes[selectedModeType]?.icon}</ModeIcon>
              <Text variant='action3'>Other</Text>
            </ModeOptionNameIcon>
            <AddIcon />
          </BaseModeOption>
          {renderModeOptions(
            filteredModes,
            selectedModeIds,
            handleModeRadioClick
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant='tertiary' onClick={() => handleCloseModal()}>
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()} marginLeft='1rem'>
            {`Add Mode${selectedModeIds.length > 1 ? 's' : ''}`}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SelectServiceProviderModal.displayName = 'SelectServiceProviderModal'
}

export default SelectServiceProviderModal
