import RandomUtils from './RandomUtils'
import React from 'react'

const generateArchiveMessage = (
  body: [number, string][],
  start: string,
  end: string,
  backupMsg: string
) => {
  const formattedBody = body.flatMap((count, i) => {
    if (count[0]) {
      return (
        <strong key={i}>
          {count[0]} {count[1]}
          {RandomUtils.pluralCheck(count[0] as number)}
        </strong>
      )
    } else {
      return []
    }
  })

  const length = formattedBody.length
  if (length === 0) {
    return backupMsg
  } else if (length === 1) {
    return (
      <>
        {start} {formattedBody}. {end}
      </>
    )
  } else {
    const withPunctuation = formattedBody.flatMap((text, i) => {
      if (i === length - 1) {
        return ['and ', text, '.']
      } else if (i < length) {
        return [text, ', ']
      } else {
        return []
      }
    })
    return (
      <>
        {start} {withPunctuation} {end}
      </>
    )
  }
}

export default {
  generateArchiveMessage,
}
