import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem ${(props) => props.theme.pxToRem(52)};
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 5rem);
  box-shadow: ${(props) => props.theme.dropShadows.bottom};
  z-index: ${(props) => props.theme.zIndex.max};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
  }
`

interface FooterProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

const Footer = React.memo((props: FooterProps) => {
  return <Container>{props.children}</Container>
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  Footer.displayName = 'Footer'
}

export default Footer
