import React, { useState } from 'react'
import styled from 'styled-components'
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'core-system/Modal'
import { Card, Payment } from 'redux/employeePlatform/employeePlatformTypes'
import FleetCard from './FleetCard'
import Divider from 'core-system/Divider'
import palette from 'core-system/Themes/palette'
import Text from 'core-system/Text'
import Button from 'core-system/Button'
import LockIcon from 'core-system/Icons/Misc/Lock'
import UnlockIcon from 'core-system/Icons/Misc/Unlock'
import AddIcon from 'core-system/Icons/Actions/Add'
import { useDispatch } from 'react-redux'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import pxToRem from 'core-system/utils/pxToRem'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import Radio from 'core-system/Radio'
import FormattingUtils from 'shared/FormattingUtils'
import { convertToTitleCase } from 'employee-platform/shared/utils'
import employeePlatformService from 'redux/employeePlatform/employeePlatformService'

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: ${(props) => (props.isMobile ? 0 : '0.5rem')};
`

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const CardDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const CardDetailValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PressableTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

const PressableText = styled.div`
  cursor: pointer;
`

const TopUpAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

const TopUpBreakdownItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const renderCardDetail = (
  label: string,
  value: string,
  showFull: boolean,
  setShowFull: (val: boolean) => void,
  handleShowDetails: () => void
) => {
  const handleHideUnhide = async () => {
    if (showFull) {
      setShowFull(false)
    } else {
      await handleShowDetails()
      setShowFull(true)
    }
  }
  return (
    <CardDetail>
      <Text variant='body1' textColor={palette.text.secondary}>
        {label}
      </Text>
      <CardDetailValue>
        <Text variant='action4'>{value}</Text>
        {showFull ? (
          <PressableTextContainer>
            <PressableText onClick={() => handleHideUnhide()}>
              <Text variant='action4' textColor={palette.text.secondary}>
                Hide
              </Text>
            </PressableText>
          </PressableTextContainer>
        ) : (
          <PressableText onClick={() => handleHideUnhide()}>
            <Text variant='action4' textColor={palette.text.secondary}>
              Show
            </Text>
          </PressableText>
        )}
      </CardDetailValue>
    </CardDetail>
  )
}

const renderPaymentMethods = (
  paymentMethods: Payment[],
  selectedPaymentMethod: { internalId: string; type: string } | null,
  setSelectedPaymentMethod: (
    paymentMethod: { internalId: string; type: string } | null
  ) => void
) => {
  return paymentMethods.map((paymentMethod) => {
    return (
      <PaymentMethodContainer key={paymentMethod.internalId}>
        <Radio
          active={
            paymentMethod.internalId === selectedPaymentMethod?.internalId
          }
          onClick={() =>
            setSelectedPaymentMethod({
              internalId: paymentMethod.internalId,
              type: paymentMethod.type,
            })
          }
        />
        <Text variant='body1'>
          {`${
            paymentMethod.type === 'card'
              ? convertToTitleCase(paymentMethod.brand)
              : convertToTitleCase(paymentMethod.bankName)
          } •••• ${paymentMethod.last4}`}
        </Text>
      </PaymentMethodContainer>
    )
  })
}

const formatExpiryDate = (month: number, year: number) => {
  const monthStr = month >= 10 ? month : `0${month}`
  return `${monthStr}/${year}`
}

interface FleetCardDetailsModalProps {
  open: boolean
  closeModal: () => void
  card: Card
  isMobile: boolean
}

const FleetCardDetailsModal = React.memo(
  (props: FleetCardDetailsModalProps) => {
    const { open, closeModal, card, isMobile } = props

    const { paymentMethods } = useSelector(
      (state: AppState) => state.employeePlatform.wallet
    )

    const dispatch = useDispatch()

    const [showFullDetails, setShowFullDetails] = useState(false)
    const [fullDetails, setFullDetails] = useState({
      number: null,
      cvc: null,
    })
    const [currentView, setCurrentView] = useState<
      'details' | 'lock' | 'addfunds'
    >('details')
    const [topUpAmount, setTopUpAmount] = useState(0)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{
      internalId: string
      type: string
    } | null>(null)

    const getTitle = () => {
      if (currentView === 'details') {
        return 'Fleet Card Details'
      } else if (currentView === 'lock') {
        return `${card?.status !== 'active' ? 'Unlock' : 'Lock'} Fleet Card`
      } else {
        return 'Add Funds'
      }
    }

    const getConfirmButtonText = () => {
      if (currentView === 'lock') {
        return card?.status !== 'active' ? 'Unlock' : 'Lock'
      } else {
        return 'Add Funds'
      }
    }

    const handleTopUpAmountChange = (amount: number) => {
      setTopUpAmount(amount * 100)
    }

    const handleShowDetails = async () => {
      if (!fullDetails.number || !fullDetails.cvc) {
        await employeePlatformService
          .getCardDetails(card?.internalId)
          .then((res) => {
            setFullDetails({
              number: res.data.number,
              cvc: res.data.cvc,
            })
          })
      }
    }

    const handleClose = () => {
      closeModal()
      setShowFullDetails(false)
      setCurrentView('details')
      setTopUpAmount(0)
      setSelectedPaymentMethod(null)
    }

    const handleConfirm = () => {
      if (currentView === 'lock') {
        if (card?.status !== 'active') {
          dispatch(employeePlatformActions.unlockCard(card?.internalId))
        } else {
          dispatch(employeePlatformActions.lockCard(card?.internalId))
        }
      } else {
        dispatch(
          employeePlatformActions.topUpCard({
            paymentMethodId: selectedPaymentMethod?.internalId,
            topUpAmount: topUpAmount,
          })
        )
      }
      handleClose()
    }

    return (
      <Modal open={open} onClose={() => handleClose()} width={pxToRem(400)}>
        <ModalHeader title={getTitle()} />
        <ModalBody>
          {currentView === 'details' ? (
            <Container isMobile={isMobile}>
              <CardContainer>
                <FleetCard card={card} size='large' />
              </CardContainer>
              <Divider marginTop={isMobile ? 0 : '1rem'} />
              <DetailsSection>
                {renderCardDetail(
                  'Card Number',
                  showFullDetails && fullDetails.number
                    ? fullDetails.number.match(/.{1,4}/g).join(' ')
                    : `•••• •••• •••• ${card?.last4}`,
                  showFullDetails,
                  setShowFullDetails,
                  handleShowDetails
                )}
                <Divider />
                {renderCardDetail(
                  'Expiry Date',
                  showFullDetails
                    ? formatExpiryDate(card?.expMonth || 0, card?.expYear || 0)
                    : '••/••',
                  showFullDetails,
                  setShowFullDetails,
                  handleShowDetails
                )}
                <Divider />
                {card?.type !== 'physical'
                  ? renderCardDetail(
                      'Security Code',
                      showFullDetails && fullDetails.cvc
                        ? fullDetails.cvc
                        : '•••',
                      showFullDetails,
                      setShowFullDetails,
                      handleShowDetails
                    )
                  : null}
                {card?.type === 'physical' ? (
                  <Text variant='body2'>
                    Physical card details are not available on the web. Please
                    refer to your physical card for details.
                  </Text>
                ) : null}
              </DetailsSection>
              <Divider marginBottom={isMobile ? 0 : '1rem'} />
              <Button
                iconLeft={<AddIcon />}
                onClick={() => setCurrentView('addfunds')}
              >
                Add Funds
              </Button>
              <Button
                variant='secondary'
                iconLeft={
                  card?.status !== 'active' ? <UnlockIcon /> : <LockIcon />
                }
                onClick={() => setCurrentView('lock')}
              >
                {card?.status !== 'active' ? 'Unlock' : 'Lock'}
              </Button>
            </Container>
          ) : null}
          {currentView === 'lock' ? (
            <Container isMobile={isMobile}>
              <Text variant='h4'>
                {`Are you sure you want to ${
                  card?.status !== 'active' ? 'unlock' : 'lock'
                } your card?`}
              </Text>
              <Text variant='body1' textColor={palette.text.secondary}>
                {card?.status !== 'active'
                  ? 'Unlocking your card will enable your card and allow you to make transactions with it'
                  : 'Locking your card will be able disable your card and all future purchases using this card will be declined'}
              </Text>
            </Container>
          ) : null}
          {currentView === 'addfunds' ? (
            <Container isMobile={isMobile}>
              <TopUpAmountContainer>
                <Text variant='body1'>Amount</Text>
                <NumberInput
                  value={topUpAmount / 100}
                  onValueChange={handleTopUpAmountChange}
                  placeholder='0.00'
                  prefix='$'
                  incrementVal={5}
                >
                  <NumberFormatter decimalScale={0} fontVariant='h3' />
                </NumberInput>
                <Text variant='body2' textColor={palette.text.secondary}>
                  Minimum $5
                </Text>
              </TopUpAmountContainer>
              <Divider marginY='0.5rem' />
              <Text variant='action3'>Payment Method</Text>
              {renderPaymentMethods(
                paymentMethods,
                selectedPaymentMethod,
                setSelectedPaymentMethod
              )}
              <Divider marginY='0.5rem' />
              <Text variant='action3'>Breakdown</Text>
              <TopUpBreakdownItem>
                <Text variant='body1'>Top Up Amount</Text>
                <Text variant='body1'>
                  {FormattingUtils.formatDollar(topUpAmount)}
                </Text>
              </TopUpBreakdownItem>
              {selectedPaymentMethod?.type === 'card' ? (
                <TopUpBreakdownItem>
                  <Text variant='body1'>{'Card Processing Fees (3%)'}</Text>
                  <Text variant='body1'>
                    {FormattingUtils.formatDollar(topUpAmount * 0.03)}
                  </Text>
                </TopUpBreakdownItem>
              ) : null}
              <Divider />
              <TopUpBreakdownItem>
                <Text variant='action4'>Total</Text>
                <Text variant='action4'>
                  {FormattingUtils.formatDollar(
                    selectedPaymentMethod?.type === 'card'
                      ? topUpAmount * 1.03
                      : topUpAmount
                  )}
                </Text>
              </TopUpBreakdownItem>
            </Container>
          ) : null}
        </ModalBody>
        {currentView !== 'details' ? (
          <ModalFooter>
            <Button variant='tertiary' onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              onClick={() => handleConfirm()}
              disabled={
                currentView === 'addfunds' &&
                (!selectedPaymentMethod || topUpAmount < 500)
              }
              style={{ marginLeft: '0.5rem' }}
            >
              {getConfirmButtonText()}
            </Button>
          </ModalFooter>
        ) : null}
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FleetCardDetailsModal.displayName = 'FleetCardDetailsModal'
}

export default FleetCardDetailsModal
