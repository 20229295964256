import React from 'react'
import LeaderboardUtils from 'features/Leaderboards/LeaderboardUtils'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import Loading from 'core-system/Loading'
import GridCard from 'employee-platform/shared/components/GridCard'
import styled from 'styled-components'
import Text from 'core-system/Text'
import Divider from 'core-system/Divider'
import palette from 'core-system/Themes/palette'
import FormattingUtils from 'shared/FormattingUtils'
import LeaderboardRankings from './LeaderboardRankings'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import moment from 'moment'

const HeaderContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  gap: ${(props) => (props.isMobile ? '0.5rem' : '1rem')};
  width: 100%;
  ${(props) => (!props.isMobile ? 'justify-content: space-between;' : '')}
`

const HeaderIconTextContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  gap: 1rem;
`

const HeaderTextContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
`

const BonusRewardsContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMobile ? 'center' : 'flex-end')};
  gap: 0.5rem;
`

const BonusRewards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`

const BonusRewardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

const RewardIconContainer = styled.div<{ awardColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.awardColor};
`

const renderBonusReward = (rank: number, rewardValue: number) => {
  const awardBackgroundColor =
    rank === 1
      ? palette.leaderboards.goldBackground
      : rank === 2
      ? palette.leaderboards.silverBackground
      : palette.leaderboards.bronzeBackground
  const awardTextColor =
    rank === 1
      ? palette.leaderboards.goldText
      : rank === 2
      ? palette.leaderboards.silverText
      : palette.leaderboards.bronzeText

  return (
    <BonusRewardContainer>
      <RewardIconContainer awardColor={awardBackgroundColor}>
        <Text variant='body2' textColor={awardTextColor}>
          {rank}
        </Text>
      </RewardIconContainer>
      <Text variant='action2' textColor={palette.text.primary}>
        {FormattingUtils.formatDollar(rewardValue, 0)}
      </Text>
    </BonusRewardContainer>
  )
}

interface LeaderboardDetailsProps {
  isMobile: boolean
  selectedLeaderboardId: string | null
}

const LeaderboardDetails = React.memo((props: LeaderboardDetailsProps) => {
  const { isMobile, selectedLeaderboardId } = props

  const { profileData, myLeaderboards } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const leaderboardTypeInfo =
    myLeaderboards &&
    selectedLeaderboardId &&
    myLeaderboards[selectedLeaderboardId]
      ? LeaderboardUtils.leaderboardData[
          myLeaderboards[selectedLeaderboardId].queryType
        ]
      : null

  const segmentName = myLeaderboards
    ? profileData?.segments.filter(
        (segment) =>
          segment.id === myLeaderboards[selectedLeaderboardId]?.segmentId
      )[0]?.name
    : null

  const hasBonusRewards =
    myLeaderboards &&
    selectedLeaderboardId &&
    (myLeaderboards[selectedLeaderboardId]?.firstPrize ||
      myLeaderboards[selectedLeaderboardId]?.secondPrize ||
      myLeaderboards[selectedLeaderboardId]?.thirdPrize)

  if (!profileData || !myLeaderboards) {
    return <Loading />
  }

  return (
    <GridContainer numColumns={1} fitContentHeight>
      <GridCard headerTitle='Leaderboard Details'>
        {selectedLeaderboardId ? (
          <>
            <HeaderContainer isMobile={isMobile}>
              <HeaderIconTextContainer isMobile={isMobile}>
                {leaderboardTypeInfo?.icon}
                <HeaderTextContainer isMobile={isMobile}>
                  <Text variant='action1'>{leaderboardTypeInfo?.title}</Text>
                  <Text variant='action3' textColor={palette.text.secondary}>
                    {`Group: ${segmentName}`}
                  </Text>
                  <Text variant='action3' textColor={palette.text.placeholder}>
                    {moment().format('MMMM YYYY')}
                  </Text>
                </HeaderTextContainer>
              </HeaderIconTextContainer>
              {hasBonusRewards ? (
                <Divider
                  direction={isMobile ? 'horizontal' : 'vertical'}
                  size={isMobile ? '25%' : '90%'}
                />
              ) : null}
              {hasBonusRewards ? (
                <BonusRewardsContainer isMobile={isMobile}>
                  <Text variant='action3' textColor={palette.text.secondary}>
                    Bonus Rewards
                  </Text>
                  <BonusRewards>
                    {renderBonusReward(
                      1,
                      myLeaderboards[selectedLeaderboardId].firstPrize
                    )}
                    {renderBonusReward(
                      2,
                      myLeaderboards[selectedLeaderboardId].secondPrize
                    )}
                    {renderBonusReward(
                      3,
                      myLeaderboards[selectedLeaderboardId].thirdPrize
                    )}
                  </BonusRewards>
                </BonusRewardsContainer>
              ) : null}
            </HeaderContainer>
            <Divider direction='horizontal' marginY='1rem' />
            <LeaderboardRankings
              isMobile={isMobile}
              rankings={myLeaderboards[selectedLeaderboardId]?.rankings}
              myRank={myLeaderboards[selectedLeaderboardId]?.myRank}
              leaderboardTypeInfo={leaderboardTypeInfo}
            />
          </>
        ) : (
          <NoDataText
            text={
              myLeaderboards && Object.keys(myLeaderboards).length > 0
                ? 'PLEASE SELECT A LEADERBOARD TO VIEW DETAILS'
                : 'NO ACTIVE LEADERBOARDS'
            }
          />
        )}
      </GridCard>
    </GridContainer>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardDetails.displayName = 'LeaderboardDetails'
}

export default LeaderboardDetails
