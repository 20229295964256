import { mapIntelligenceActions } from 'redux/mapIntelligence/mapIntelligenceSlice'
import { micromobilityActions } from 'redux/micromobility/micromobilitySlice'
import Loading from 'core-system/Loading'
import Map from 'core-system/Map/Map'
import MapNavigationControl from 'core-system/Map/MapNavigationControl'
import MapPopupWorksite from 'core-system/Map/Popups/MapPopupWorksite'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useEffect } from 'react'
import { Layer, Source } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import useQueryParam from 'shared/Hooks/useQueryParam'
import styled from 'styled-components'
import MicromobilityMapMetrics from './MicromobilityMapMetrics'
import MicromobilityMapStyles from './MicromobilityMapStyles'
import MicromobilityPopupEmployee from './MicromobilityPopupEmployee'

const popupMap = {
  worksites: MapPopupWorksite,
  employees: MicromobilityPopupEmployee,
}

const MapContainer = styled.div`
  ${(props) => props.theme.baseCard}
  overflow: hidden;
  position: relative;
  padding: 0;
`

const MicromobilityPreviewMap = React.memo(() => {
  const bikeIsochrones = useSelector(
    (state: AppState) => state.mapIntelligence.bikeIsochrones
  )
  const employeesGeojson = useSelector(
    (state: AppState) => state.mapIntelligence.employeesGeojson
  )
  const worksiteGeojson = useSelector(
    (state: AppState) => state.mapIntelligence.worksiteGeojson
  )

  const dispatch = useDispatch()
  const segmentId = useQueryParam('segmentId')

  useEffect(() => {
    dispatch(
      micromobilityActions.getMicromobilityIsochrones({
        segment: segmentId,
        valuesSeconds: [900],
      })
    )
    dispatch(
      mapIntelligenceActions.getBaseCommuters({
        segmentId,
      })
    )
  }, [segmentId, dispatch])

  if (!bikeIsochrones || !employeesGeojson || !worksiteGeojson) {
    return <Loading height={pxToRem(650)} />
  }

  return (
    <MapContainer>
      <Map
        style={{
          height: '650px',
          width: '100%',
        }}
        validInteractiveGeoJSONLayers={['employees']}
        popupMap={popupMap}
        worksites={worksiteGeojson}
        loadLayer={employeesGeojson}
      >
        {(mapProps) => (
          <>
            <Source type='geojson' id='employees' data={employeesGeojson}>
              <Layer {...MicromobilityMapStyles.micromobilityStyle} />
            </Source>
            <Source type='geojson' id='isochrones' data={bikeIsochrones}>
              <Layer {...MicromobilityMapStyles.isochroneStyle} />
              <Layer {...MicromobilityMapStyles.isochroneOutlineStyle} />
            </Source>
            <MapNavigationControl
              setViewport={mapProps.setViewport}
              featureCollection={employeesGeojson}
              updateBbox={mapProps.updateBbox}
            />
          </>
        )}
      </Map>
      <MicromobilityMapMetrics employeeData={employeesGeojson} />
    </MapContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityPreviewMap.displayName = 'MicromobilityPreviewMap'
}

export default MicromobilityPreviewMap
