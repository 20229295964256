import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import useQueryParam from 'shared/Hooks/useQueryParam'
import Loading from 'core-system/Loading'
import GridCard from 'employee-platform/shared/components/GridCard'
import styled from 'styled-components'
import Text from 'core-system/Text'
import Button from 'core-system/Button'
import palette from 'core-system/Themes/palette'
import { useDispatch } from 'react-redux'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import CarpoolMap from './components/CarpoolMap'
import {
  convertMetresToMiles,
  convertTimeStringToAMPM,
} from 'employee-platform/shared/utils'
import { LocationDisclaimers } from './CarpoolUtils'
import FlexContainer from 'core-system/FlexContainer'
import { useNavigate } from 'react-router'
import { Locations } from 'shared/Router/Locations'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
  }
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: ${(props) => props.theme.palette.white};
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
`

const AcceptDeclineButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

const renderDetail = (label: string, value: string) => {
  return (
    <Detail>
      <Text variant='action3' textColor={palette.text.secondary}>
        {label}
      </Text>
      <Text variant='action1' textColor={palette.primary.primaryPurple}>
        {value}
      </Text>
    </Detail>
  )
}

const renderAcceptDeclineButtons = (
  onAcceptClick: () => void,
  onDeclineClick: () => void
) => {
  return (
    <AcceptDeclineButtonContainer>
      <Button variant='green' onClick={onAcceptClick}>
        Accept Request
      </Button>
      <Button variant='red' onClick={onDeclineClick}>
        Decline Request
      </Button>
    </AcceptDeclineButtonContainer>
  )
}

const renderDisclaimerText = (text: string) => {
  return (
    <FlexContainer width='100%' justifyContent='flex-end' marginTop='1rem'>
      <Text variant='body1' textColor={palette.text.placeholder}>
        {`* ${text}`}
      </Text>
    </FlexContainer>
  )
}

const CarpoolRequestDetailsView = React.memo(() => {
  const groupId = useQueryParam('carpoolGroupId')
  const requestId = useQueryParam('carpoolRequestId')

  const { receivedCarpoolRequests, myCarpoolGroups } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const handleAccept = () => {
    navigate(`${Locations.EmployeeCarpool.Overview}/${groupId}`)
    dispatch(employeePlatformActions.acceptCarpoolRequest(requestId))
  }

  const handleDecline = () => {
    navigate(`${Locations.EmployeeCarpool.Overview}/${groupId}`)
    dispatch(employeePlatformActions.declineCarpoolRequest(requestId))
  }

  if (!receivedCarpoolRequests || !myCarpoolGroups) {
    return <Loading />
  }

  const requestData = receivedCarpoolRequests[groupId][requestId]

  return (
    <GridCard
      headerTitle='Carpool Request Details'
      clickable={true}
      headerButtons={renderAcceptDeclineButtons(handleAccept, handleDecline)}
    >
      <Container>
        <DetailsContainer>
          {renderDetail(
            'Email',
            requestData?.contactInfo
              ? requestData.contactInfo
              : 'No Email Provided'
          )}
          {renderDetail(
            'Distance from You',
            requestData?.distanceSource || requestData.distanceSource === 0
              ? `${convertMetresToMiles(requestData.distanceSource, 1)} mi`
              : 'No Distance Provided'
          )}
          {renderDetail(
            'Distance from Destination',
            requestData?.distanceDest || requestData.distanceDest === 0
              ? `${convertMetresToMiles(requestData.distanceDest, 1)} mi`
              : 'No Distance Provided'
          )}
          {renderDetail(
            'Desired Destination Arrival Time',
            requestData?.workArrivalTime
              ? convertTimeStringToAMPM(requestData.workArrivalTime)
              : 'No Time Provided'
          )}
          {renderDetail(
            'Desired Destination Departure Time',
            requestData?.workDepartureTime
              ? convertTimeStringToAMPM(requestData.workDepartureTime)
              : 'No Time Provided'
          )}
          {renderDetail('Role', requestData?.driver ? 'Driver' : 'Passenger')}
        </DetailsContainer>
        <CarpoolMap
          isMobile={isMobile}
          groupId={groupId}
          requestId={requestId}
        />
      </Container>
      {renderDisclaimerText(LocationDisclaimers.ReceivedRequest)}
    </GridCard>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolRequestDetailsView.displayName = 'CarpoolRequestDetailsView'
}

export default CarpoolRequestDetailsView
