export type ModeTypeCost =
  | 'mopedLeasing'
  | 'scooterLeasing'
  | 'electricBikeLeasing'
  | 'rideshare'
  | 'scootershare'
  | 'bikeshare'
  | 'mopedshare'
  | 'carpool'

const modeCostMap = {
  mopedLeasing: 20000,
  scooterLeasing: 5000,
  electricBikeLeasing: 7500,
  rideshare: 2000,
  scootershare: 450,
  bikeshare: 375,
  mopedshare: 600,
  carpool: 700,
}

const getTripsByCost = (modeType: ModeTypeCost, totalBudget: number) => {
  const trips = Math.floor(totalBudget / modeCostMap[modeType])
  return trips ? trips : 0
}

export default {
  modeCostMap,
  getTripsByCost,
}
