import { leaderboardsActions } from 'redux/leaderboards/leaderboardsSlice'
import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import Loading from 'core-system/Loading'
import { SegmentAndDateConfig } from 'core-system/Program'
import SegmentDropdownUtils from 'core-system/SegmentDropdown/SegmentDropdownUtils'
import ActivateIncentiveSpending from 'features/Incentives/Activation/components/ActivateIncentiveSpending'
import ManageLeaderboardBonus from 'features/Leaderboards/Management/components/ManageLeaderboardBonus'
import ManageLeaderboardSummary from 'features/Leaderboards/Management/components/ManageLeaderboardSummary'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import {
  Leaderboard,
  LeaderboardType,
} from 'redux/leaderboards/leaderboardsTypes'
import DateUtils from 'shared/DateUtils'
import styled from 'styled-components'
import LeaderboardUtils from '../LeaderboardUtils'

export interface LeaderboardDate {
  val: string
  text: string
}

const StyledColumnView = styled(ColumnView)`
  margin-bottom: 10rem;
  grid-template-columns: 64.5% 34.5%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    max-width: ${(props) => props.theme.pxToRem(700)};
    grid-gap: unset;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem ${(props) => props.theme.pxToRem(52)};
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 5rem);
  box-shadow: ${(props) => props.theme.dropShadows.bottom};
  z-index: ${(props) => props.theme.zIndex.max};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const getDate = (
  field: string,
  currentLeaderboard: Leaderboard,
  startDate: LeaderboardDate
) => {
  if (currentLeaderboard && currentLeaderboard[field]) {
    return moment(currentLeaderboard[field], 'YYYY-MM-DD').format(
      'MMMM Do YYYY'
    )
  } else if (field === 'startDate' && startDate) {
    return startDate.text
  } else {
    // return endDate ? endDate.text : null
    return null
  }
}

// interface ParamTypes {
//   leaderboardType: string
//   segmentId: string
// }

const LeaderboardActivationView = React.memo(() => {
  const { leaderboardType, segmentId } = useParams()

  const { leaderboards, successTrigger, leaderboardsMap } = useSelector(
    (state: AppState) => state.leaderboards
  )
  const { segmentsMap, allSegments } = useSelector(
    (state: AppState) => state.employer
  )

  const nextMonth: LeaderboardDate = useMemo(() => {
    return {
      val: 'next-month',
      text: DateUtils.getNextXMonths(1, 1)[0].text,
    }
  }, [])

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(nextMonth)
  // const [endDate, setEndDate] = useState(null)
  const [bonuses, setBonuses] = useState(LeaderboardUtils.defaultBonuses)
  // const [showEndDate, setShowEndDate] = useState(false)

  useEffect(() => {
    if (successTrigger) {
      dispatch(leaderboardsActions.getLeaderboards())
      navigate(`/leaderboards/${segmentId}`)
    }
  }, [successTrigger, navigate, segmentId, dispatch])

  if (!leaderboards) {
    return <Loading fullPage />
  }

  if (
    leaderboardsMap[segmentId]?.programGroups?.[leaderboardType.toUpperCase()]
  ) {
    return <Navigate to={`/leaderboards/${segmentId}`} replace />
  }

  const handleActivate = () => {
    const bonusesFormatted = bonuses.map((bonus: number) =>
      !bonus ? 0 : bonus
    )

    const startDateFormatted = moment(startDate.text, 'MMMM Do YYYY').format()
    // const endDateFormatted = endDate
    //   ? moment(endDate.text, 'MMMM Do YYYY').format()
    //   : null

    dispatch(
      leaderboardsActions.createLeaderboard({
        startDate: startDateFormatted,
        endDate: null,
        queryType: leaderboardType.toUpperCase() as LeaderboardType,
        segment: segmentId,
        firstPrize: bonusesFormatted[0].toString(),
        secondPrize: bonusesFormatted[1].toString(),
        thirdPrize: bonusesFormatted[2].toString(),
      })
    )
    SegmentService.track('leaderboard-action-click', {
      action: 'activate',
      boardType: leaderboardType,
      segmentName: segmentsMap[segmentId].name,
      startDate: moment(startDateFormatted).format('DD-MM-YYYY'),
      // endDate: endDate
      //   ? moment(endDate.text, 'MMMM Do YYYY').format('DD-MM-YYYY')
      //   : null,
      endDate: null,
      firstPrize: bonusesFormatted[0],
      secondPrize: bonusesFormatted[1],
      thirdPrize: bonusesFormatted[2],
    })
  }

  const handleSegmentChange = (newSegmentId: string) => {
    if (newSegmentId !== segmentId) {
      navigate(`/leaderboards/${newSegmentId}/${leaderboardType}/activation`)
      SegmentService.track('baseOptions-toggle-action', {
        actionType: 'segment-name',
      })
    }
  }

  const currentLeaderboard =
    leaderboardsMap[segmentId] &&
    leaderboardsMap[segmentId].programs.find(
      (leaderboard) => leaderboard.type === leaderboardType.toUpperCase()
    )
  const isActive =
    currentLeaderboard &&
    LeaderboardUtils.activeLeaderboardStatuses.includes(
      currentLeaderboard.status
    )
  const noBonuses =
    currentLeaderboard &&
    currentLeaderboard.bonuses.filter((bonus) => bonus === 0).length === 3
  const startDateFormatted = getDate('startDate', currentLeaderboard, startDate)
  const endDateFormatted = getDate('endDate', currentLeaderboard, startDate)
  const activeCurrentType = Object.keys(leaderboardsMap).reduce(
    (agg, segmentId) => {
      agg[segmentId] = leaderboardsMap[segmentId].programs.some(
        (leaderboard) => leaderboard.type === leaderboardType.toUpperCase()
      )
      return agg
    },
    {}
  )

  return (
    <>
      <StyledColumnView>
        <Column>
          <SegmentAndDateConfig
            currentSegment={segmentsMap[segmentId]}
            dropdownItems={SegmentDropdownUtils.segmentDropdownItems(
              allSegments,
              activeCurrentType
            )}
            onDateChange={(val, text, initial) =>
              setStartDate(initial ? nextMonth : { val, text })
            }
            onSegmentChange={handleSegmentChange}
          />
          <ManageLeaderboardBonus
            bonuses={bonuses}
            setBonuses={setBonuses}
            noBonuses={noBonuses}
            currentLeaderboard={currentLeaderboard}
            isActive={isActive}
          />
          <ActivateIncentiveSpending />
        </Column>
        <Column>
          <ManageLeaderboardSummary
            isActive={isActive}
            leaderboardType={leaderboardType.toUpperCase()}
            currentSegment={segmentsMap[segmentId]}
            startDate={startDateFormatted}
            endDate={endDateFormatted}
            bonuses={bonuses}
            showEndDate={false}
            canEdit={false}
          />
        </Column>
      </StyledColumnView>
      <Footer>
        <ButtonContainer>
          <Button
            variant='tertiary'
            marginRight='1rem'
            onClick={() => {
              navigate(`/leaderboards/${segmentId}`)
              SegmentService.track('leaderboard-manage-action', {
                action: 'cancel',
                boardType: leaderboardType.toLowerCase(),
                locationAt: `leaderboards-${segmentsMap[segmentId].name}`,
              })
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleActivate}>Activate</Button>
        </ButtonContainer>
      </Footer>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardActivationView.displayName = 'LeaderboardActivationView'
}

export default LeaderboardActivationView
