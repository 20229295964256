import Banner from 'core-system/Banner'
import ProgramCard from 'core-system/Program/ProgramCard'
import Text from 'core-system/Text'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { CardProgram } from 'redux/programs/programsTypes'
import { QtfProgram } from 'redux/qtf/qtfTypes'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import DashboardReporting from '../components/DashboardReporting'
import DashboardInvite from './DashboardInvite'
import DashboardOnboardingMap from './DashboardOnboardingMap'
import DashboardServices from './DashboardServices'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 0.65fr) minmax(0, 0.35fr);
  gap: 1rem;
  margin-bottom: 3rem;
`

interface DashboardOnboardingProps {
  programs: CardProgram[]
  nextMonthQtfPrograms: QtfProgram[]
}

const DashboardOnboarding = React.memo((props: DashboardOnboardingProps) => {
  const { programs, nextMonthQtfPrograms } = props

  const isReportGenerating = useSelector(
    (state: AppState) => state.dashboard.isReportGenerating
  )

  const programsData = [...programs]
    .sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    )
    .reduce(
      (obj, program) => {
        obj[program.type].push(program)
        obj.total += 1
        return obj
      },
      {
        MICROMOBILITY: [],
        FLEX: [],
        OTP: [],
        GRH: [],
        total: 0,
      }
    )

  return (
    <div>
      <Banner
        variant='info'
        title='Welcome to Fleet!'
        caption={
          programsData.total + nextMonthQtfPrograms.length > 0
            ? `You have ${
                programsData.total + nextMonthQtfPrograms.length
              } Program${RandomUtils.pluralCheck(
                programsData.total + nextMonthQtfPrograms.length
              )} pending at the moment`
            : 'You have no programs running at the moment'
        }
        background='bannerDashboard'
        description='Learn more about commuter commuting to your worksite, and programs and services available to you now.'
      />
      <GridContainer>
        <div>
          <Text
            variant='h4'
            marginBottom='1rem'
            dataCy='onboarding-dashboard-title'
          >
            Your Worksite
          </Text>
          <DashboardInvite />
          <DashboardOnboardingMap />
          <DashboardServices />
        </div>
        <div>
          <Text variant='h4' marginBottom='1rem'>
            Programs
          </Text>
          <ProgramCard
            type='FLEX'
            startDate={
              programsData.FLEX.length > 0 && programsData.FLEX[0].startDate
            }
            isPending={programsData.FLEX.length > 0}
          />
          <ProgramCard
            type='MICROMOBILITY'
            startDate={
              programsData.MICROMOBILITY.length > 0 &&
              programsData.MICROMOBILITY[0].startDate
            }
            isPending={programsData.MICROMOBILITY.length > 0}
          />
          <DashboardReporting isReportGenerating={isReportGenerating} />
        </div>
      </GridContainer>
    </div>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardOnboarding.displayName = 'DashboardOnboarding'
}

export default DashboardOnboarding
