import React from 'react'
import styled from 'styled-components'
import {
  variant,
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Medal = styled.div<
  SpaceProps & LayoutProps & FlexboxProps & { variant?: number; size: number }
>`
  ${(props) => props.theme.typography.action4};
  color: ${(props) => props.theme.palette.text.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.theme.pxToRem(props.size)};
  height: ${(props) => props.theme.pxToRem(props.size)};
  border-radius: 50%;
  padding-top: 0.0625rem;

  ${(props) =>
    props.variant &&
    variant({
      variants: {
        1: {
          backgroundColor: '#FFE58B',
          color: props.theme.palette.chips.yellow1,
        },
        2: {
          backgroundColor: props.theme.palette.grey.grey3,
          color: props.theme.palette.text.secondary,
        },
        3: {
          backgroundColor: '#F6C892',
          color: '#733E00',
        },
      },
    })}

  ${space}
  ${layout}
  ${flexbox}
`

interface LeaderboardMedalProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  position: number
  size?: number
}

const LeaderboardMedal = React.memo((props: LeaderboardMedalProps) => {
  const { position, size = 20, ...rest } = props
  return (
    <Medal variant={position < 4 && position} size={size} {...rest}>
      {props.position}
    </Medal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardMedal.displayName = 'LeaderboardMedal'
}

export default LeaderboardMedal
