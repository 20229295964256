import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.81 8.62L19.65 9.04C20.5299 9.11 20.8799 10.21 20.2099 10.79L16.54 13.97L17.6399 18.7C17.8399 19.56 16.91 20.24 16.15 19.78L12 17.27L7.84995 19.77C7.08995 20.23 6.15995 19.55 6.35995 18.69L7.45995 13.97L3.78995 10.79C3.11995 10.21 3.47995 9.11 4.35995 9.04L9.18995 8.63L11.08 4.17C11.42 3.36 12.58 3.36 12.92 4.17L14.81 8.62Z'
    />
  </>,
  'Star'
)
