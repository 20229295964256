import React, { useCallback, useState } from 'react'
import Button from 'core-system/Button'
import { useStripe } from '@stripe/react-stripe-js'
import employerService from 'redux/employer/employerService'
import styled from 'styled-components'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import moment from 'moment'
import Loading from 'core-system/Loading'
import { Success } from 'core-system/Icons/Misc'
import palette from 'core-system/Themes/palette'
import AlertIcon from 'core-system/Icons/Misc/Alert'
import { employerActions } from 'redux/employer/employerSlice'
import { useDispatch } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import Chevron from 'core-system/Icons/Actions/Chevron'

const Container = styled.div`
  margin: 1rem 0;
`
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

interface IntegrateBankAccountProps {
  billingEmail: string
  billingName: string
  CustomButton?: React.ComponentType<any>
  customButtonProps?: any
  moveToNextStep?: () => void
}

const getModalCopy = (status: string) => {
  if (status === 'requires_payment_method') {
    return {
      icon: 'error',
      title: 'Confirmation Failed',
      body: 'Please try again or attempt again with a different payment method. If this continues, please contact Support.',
    }
  }
  if (status === 'verify_with_microdeposits') {
    return {
      icon: 'success',
      title: 'Almost There!',
      message:
        'This accounts needs to be verified via microdeposits. Please check your email to learn more and contact Support for questions.',
    }
  }
  // status === 'succeeded'
  return {
    icon: 'success',
    title: 'Confirmation Succeeded',
    message: 'Your account has been connected.',
  }
}

const IntegrateStripeBankAccount: React.FC<IntegrateBankAccountProps> = (
  props
) => {
  const {
    billingEmail,
    billingName,
    CustomButton,
    customButtonProps,
    moveToNextStep,
  } = props
  const [showMandateModal, setShowMandateModal] = useState(false)
  const [bankAccount, setBankAccount] = useState() as any
  const [mandateLoading, setMandateLoading] = useState(false)
  const [mandateStatus, setMandateStatus] = useState() as any
  const dispatch = useDispatch()

  const stripe = useStripe()
  const today = moment()

  const confirmPaymentMandate = async (clientSecret?: string) => {
    setMandateLoading(true)
    const { setupIntent, error } = await stripe.confirmUsBankAccountSetup(
      clientSecret
    )
    if (error) {
      console.log('stripe bank account mandate error:', error.message)
      Bugsnag.notify(error as any)
      setMandateLoading(false)
      dispatch(
        notificationsActions.generalPageError('Unable to setup bank account')
      )
    }
    if (setupIntent) {
      dispatch(employerActions.getBillingInfo())
      const info = getModalCopy(setupIntent.status)
      setMandateStatus(info)
      setTimeout(() => {
        dispatch(employerActions.getBillingInfo())
      }, 1000)
      setTimeout(() => {
        setMandateLoading(false)
      }, 2000)
      moveToNextStep && moveToNextStep()
    }
  }

  const handleConnect = async (_e: React.MouseEvent<HTMLButtonElement>) => {
    const secret = await employerService
      .getStripeBankAccountSecret()
      .then((res) => res.data)

    const { setupIntent, error } = await stripe.collectBankAccountForSetup({
      clientSecret: secret,
      params: {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: {
            name: billingName,
            email: billingEmail,
          },
        },
      },
      expand: ['payment_method'],
    })

    if (error) {
      console.log('stripe bank account setup error:', error.message)
      Bugsnag.notify(error as any)
      dispatch(
        notificationsActions.generalPageError('Unable to setup bank account')
      )
    }
    if (setupIntent.status === 'requires_confirmation') {
      const accountInfo = {
        clientSecret: secret,
        account: setupIntent.payment_method['us_bank_account'],
      }
      setBankAccount(accountInfo)
      toggleMandateModal()
    }
  }

  const toggleMandateModal = useCallback(() => {
    setShowMandateModal((prev) => !prev)
  }, [])

  return (
    <Container>
      {CustomButton ? (
        <CustomButton
          {...customButtonProps}
          onClick={handleConnect}
          iconRight={<Chevron style={{ transform: 'rotate(-90deg)' }} />}
          style={{ width: '100%' }}
        />
      ) : (
        <Button size='small' onClick={handleConnect}>
          Connect Bank Account
        </Button>
      )}
      {/* Client Mandate */}
      <Modal
        open={showMandateModal}
        onClose={toggleMandateModal}
        disableEsc={true}
        width={pxToRem(550)}
      >
        <ModalHeader title='Terms and Conditions' />
        <ModalBody>
          <Container>
            {mandateLoading && <Loading isCard={false} />}
            {!mandateLoading && !mandateStatus && (
              <>
                <FlexContainer
                  justifyContent='space-between'
                  alignItems='center'
                  padding={'0.25rem 0'}
                >
                  <Text variant='action3'>Date</Text>
                  <Text variant='body1'>{today.format('MMMM DD, YYYY')}</Text>
                </FlexContainer>
                <FlexContainer
                  justifyContent='space-between'
                  alignItems='center'
                  padding={'0.25rem 0'}
                >
                  <Text variant='action3'>Name</Text>
                  <Text variant='body1' style={{ textTransform: 'capitalize' }}>
                    {billingName}
                  </Text>
                </FlexContainer>
                <FlexContainer
                  justifyContent='space-between'
                  alignItems='center'
                  padding={'0.25rem 0'}
                >
                  <Text variant='action3'>Financial Institution</Text>
                  <Text variant='body1'>{bankAccount?.account?.bank_name}</Text>
                </FlexContainer>
                <FlexContainer
                  justifyContent='space-between'
                  alignItems='center'
                  padding={'0.25rem 0'}
                >
                  <Text variant='action3'>Account Number</Text>
                  <Text variant='body1'>
                    &nbsp;&bull;&bull;&bull;&bull;&nbsp;
                    {bankAccount?.account?.last4}
                  </Text>
                </FlexContainer>
                <FlexContainer
                  justifyContent='space-between'
                  alignItems='center'
                  padding={'0.25rem 0'}
                >
                  <Text variant='action3'>Routing Number</Text>
                  <Text variant='body1'>
                    {bankAccount?.account?.routing_number}
                  </Text>
                </FlexContainer>
                <Text variant='action3'>
                  Please review the terms and conditions below:
                </Text>
                <Text variant='body1' marginTop={'1rem'}>
                  If you use Fleet Labs’ services or purchase additional
                  products periodically pursuant to Fleet Labs’ terms, you
                  authorize Fleet Labs to debit your bank account periodically.
                  Payments that fall outside of the regular debits authorized
                  above will only be debited after your authorization is
                  obtained.
                </Text>
              </>
            )}
            {!mandateLoading && mandateStatus && (
              <>
                <FlexContainer justifyContent={'center'}>
                  {mandateStatus.icon === 'success' && (
                    <Success
                      color={palette.secondary.green2}
                      height={50}
                      width={50}
                      style={{ marginBottom: '1rem' }}
                    />
                  )}
                  {mandateStatus.icon === 'error' && (
                    <AlertIcon
                      color={palette.secondary.red1}
                      width={50}
                      height={50}
                      style={{ marginBottom: '1rem' }}
                    />
                  )}
                </FlexContainer>
                <FlexContainer justifyContent={'center'}>
                  <Text variant='h4'>{mandateStatus.title}</Text>
                </FlexContainer>
                <FlexContainer justifyContent={'center'} paddingTop={'1rem'}>
                  <Text variant='action4'>{mandateStatus.message}</Text>
                </FlexContainer>
              </>
            )}
          </Container>
        </ModalBody>
        <ModalFooter>
          <ButtonContainer>
            {!mandateLoading && mandateStatus && (
              <Button onClick={toggleMandateModal}>Close</Button>
            )}
            {!mandateStatus && (
              <>
                <Button
                  variant='tertiary'
                  marginRight='1rem'
                  onClick={toggleMandateModal}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    confirmPaymentMandate(bankAccount.clientSecret)
                  }
                >
                  Accept
                </Button>
              </>
            )}
          </ButtonContainer>
        </ModalFooter>
      </Modal>
    </Container>
  )
}
// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  IntegrateStripeBankAccount.displayName = 'IntegrateStripeBankAccount'
}

export default IntegrateStripeBankAccount
