import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M20.4211 5.96C20.4211 6.4928 19.9852 6.92 19.4415 6.92C18.8978 6.92 18.4619 6.4928 18.4619 5.96C18.4619 5.4272 18.8978 5 19.4415 5C19.9852 5 20.4211 5.4272 20.4211 5.96Z' />
    <path d='M19.7844 9.45714L17.8286 7.93598C17.9891 7.61912 18.3231 7.4 18.7068 7.4H20.1762C20.4187 7.4 20.6414 7.48753 20.8129 7.63194V8.77143L19.7844 9.45714Z' />
    <path d='M4.3125 8.9375C4.3125 9.45526 3.89277 9.875 3.375 9.875C2.85723 9.875 2.4375 9.45526 2.4375 8.9375C2.4375 8.41973 2.85723 8 3.375 8C3.89277 8 4.3125 8.41973 4.3125 8.9375Z' />
    <path d='M9.37499 9.875C9.89276 9.875 10.3125 9.45526 10.3125 8.9375C10.3125 8.41973 9.89276 8 9.37499 8C8.85723 8 8.4375 8.41973 8.4375 8.9375C8.4375 9.45526 8.85723 9.875 9.37499 9.875Z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.2571 10.5517L14.9128 6.20741C14.58 5.87461 14.1213 5.68573 13.6445 5.68573H1.79889C0.809499 5.68573 0 6.48623 0 7.48462V13.7807C0 14.7701 0.809499 15.5796 1.79889 15.5796C1.79889 17.0727 3.00414 18.2779 4.49721 18.2779C5.99029 18.2779 7.19554 17.0727 7.19554 15.5796H12.5922C12.5922 17.0727 13.7975 18.2779 15.2905 18.2779C16.7836 18.2779 17.9889 17.0727 17.9889 15.5796C18.9782 15.5796 19.7877 14.7701 19.7877 13.7807V11.8289C19.7877 11.3522 19.5989 10.8935 19.2571 10.5517ZM1.79889 10.1829V8.38406C1.79889 7.88936 2.20364 7.48462 2.69833 7.48462H5.39666V11.0824H5.2267C5.12373 10.6084 4.69178 10.25 4.17857 10.25H2.57143C2.30025 10.25 2.05177 10.3501 1.86251 10.5144C1.82148 10.4117 1.79889 10.2998 1.79889 10.1829ZM4.49721 16.7039C3.8766 16.7039 3.37291 16.2002 3.37291 15.5796C3.37291 14.959 3.8766 14.4553 4.49721 14.4553C5.11783 14.4553 5.62152 14.959 5.62152 15.5796C5.62152 16.2002 5.11783 16.7039 4.49721 16.7039ZM10.1786 10.25C10.4068 10.25 10.619 10.3209 10.7933 10.4413V7.48462H7.19554V11.0824H7.52329C7.62626 10.6084 8.05821 10.25 8.57142 10.25H10.1786ZM15.2905 16.7039C14.6699 16.7039 14.1662 16.2002 14.1662 15.5796C14.1662 14.959 14.6699 14.4553 15.2905 14.4553C15.9111 14.4553 16.4148 14.959 16.4148 15.5796C16.4148 16.2002 15.9111 16.7039 15.2905 16.7039ZM12.5922 11.0824V7.48462H13.4916L17.0894 11.0824H12.5922Z'
    />
    <path d='M23.2653 8.70283C23.2653 9.23563 22.8294 9.66283 22.2857 9.66283C21.742 9.66283 21.3061 9.23563 21.3061 8.70283C21.3061 8.17003 21.742 7.74283 22.2857 7.74283C22.8294 7.74283 23.2653 8.17003 23.2653 8.70283Z' />
    <path d='M23.2653 13.9828V17.3428H21.3061V13.9828H20.5714V11.1028C20.5714 10.5748 21.0122 10.1428 21.551 10.1428H23.0204C23.5592 10.1428 24 10.5748 24 11.1028V13.9828H23.2653Z' />
  </>,
  'Vanpool'
)
