import { AnyAction } from 'redux'
import { all, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { employerActions } from 'redux/employer/employerSlice'
import { SegmentParams } from 'redux/employer/employerTypes'
import { incentivesActions } from 'redux/incentives/incentivesSlice'
import IncentivesService from 'redux/incentives/incentivesService'
import {
  IncentiveProgram,
  IncentiveProgramCreate,
  OneTimeRewardPayload,
  TripPayload,
} from 'redux/incentives/incentivesTypes'
import { userActions } from 'redux/user/userSlice'
import { Action } from 'shared/Types/Actions'

export function* getAllIncentives(action: AnyAction) {
  yield defaultFlow(
    action,
    IncentivesService.getAllIncentives,
    incentivesActions.getAllIncentivesSuccess
  )
}

export function* getAllIncentivesTripsSuccess(action: AnyAction) {
  yield defaultFlow(
    action,
    IncentivesService.getAllIncentivesTrips,
    incentivesActions.getAllIncentivesTripsSuccess
  )
}

export function* getIncentives(action: Action<string>) {
  yield defaultFlow(
    action,
    IncentivesService.getIncentives,
    incentivesActions.getIncentivesSuccess
  )
}

export function* createIncentives(action: Action<IncentiveProgramCreate>) {
  yield defaultFlow(
    action,
    IncentivesService.createIncentives,
    incentivesActions.createIncentivesSuccess
  )
}

export function* updateIncentives(action: Action<IncentiveProgram>) {
  yield defaultFlow(
    action,
    IncentivesService.updateIncentives,
    incentivesActions.updateIncentivesSuccess
  )
}

export function* pauseIncentives(action: Action<string>) {
  yield defaultFlow(
    action,
    IncentivesService.pauseIncentives,
    incentivesActions.pauseIncentivesSuccess
  )
}

export function* getIncentivesRewarded(action: Action<SegmentParams>) {
  yield defaultFlow(
    action,
    IncentivesService.getIncentivesRewarded,
    incentivesActions.getIncentivesRewardedSuccess
  )
}

export function* getTrips(action: Action<TripPayload>) {
  yield defaultFlow(
    action,
    IncentivesService.getTrips,
    incentivesActions.getTripsSuccess
  )
}

// #---------OTR---------#
export function* getAllOneTimeRewards(action: AnyAction) {
  yield defaultFlow(
    action,
    IncentivesService.getAllOneTimeRewards,
    incentivesActions.getAllOneTimeRewardsSuccess
  )
}

export function* createOneTimeReward(action: Action<OneTimeRewardPayload>) {
  yield defaultFlow(
    action,
    IncentivesService.createOneTimeReward,
    incentivesActions.createOneTimeRewardSuccess
  )
}

export function* bulkCreateOneTimeRewards(
  action: Action<OneTimeRewardPayload[]>
) {
  yield defaultFlow(
    action,
    IncentivesService.bulkCreateOneTimeRewards,
    incentivesActions.bulkCreateOneTimeRewardsSuccess
  )
}

/**
 * Watchers
 */
export function* watchGetAllIncentives() {
  yield takeEvery(
    [
      userActions.getUserSuccess.type,
      incentivesActions.updateIncentivesSuccess.type,
      incentivesActions.pauseIncentivesSuccess.type,
      incentivesActions.createIncentivesSuccess.type,
      employerActions.archiveWorksiteSuccess.type,
    ],
    getAllIncentives
  )
}

export function* watchGetAllIncentivesTrips() {
  yield takeEvery(
    [userActions.getUserSuccess.type],
    getAllIncentivesTripsSuccess
  )
}

export function* watchGetIncentives() {
  yield takeEvery([incentivesActions.getIncentives.type], getIncentives)
}

export function* watchCreateIncentives() {
  yield takeEvery([incentivesActions.createIncentives.type], createIncentives)
}

export function* watchUpdateIncentives() {
  yield takeEvery([incentivesActions.updateIncentives.type], updateIncentives)
}

export function* watchPauseIncentives() {
  yield takeEvery([incentivesActions.pauseIncentives.type], pauseIncentives)
}

export function* watchGetIncentivesRewarded() {
  yield takeEvery(
    [incentivesActions.getIncentivesRewarded.type],
    getIncentivesRewarded
  )
}

export function* watchGetTrips() {
  yield takeEvery([incentivesActions.getTrips.type], getTrips)
}

export function* watchGetAllOneTimeRewards() {
  yield takeEvery(
    [
      userActions.getUserSuccess.type,
      incentivesActions.createOneTimeRewardSuccess.type,
      incentivesActions.bulkCreateOneTimeRewardsSuccess.type,
    ],
    getAllOneTimeRewards
  )
}

export function* watchCreateOneTimeReward() {
  yield takeEvery(
    [incentivesActions.createOneTimeReward.type],
    createOneTimeReward
  )
}

export function* watchBulkCreateOneTimeRewards() {
  yield takeEvery(
    [incentivesActions.bulkCreateOneTimeRewards.type],
    bulkCreateOneTimeRewards
  )
}

export default function* () {
  yield all([
    watchGetAllIncentives(),
    watchGetAllIncentivesTrips(),
    watchGetIncentives(),
    watchCreateIncentives(),
    watchUpdateIncentives(),
    watchPauseIncentives(),
    watchGetIncentivesRewarded(),
    watchGetTrips(),
    watchGetAllOneTimeRewards(),
    watchCreateOneTimeReward(),
    watchBulkCreateOneTimeRewards(),
  ])
}
