import { css } from 'styled-components'

export const Name = 'Cabin, san-serif'

const font = css`
  @font-face {
    font-family: 'Cabin', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cabin', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }
  @font-face {
    font-family: 'Cabin', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
`

export default font

// 400: Regular
// 500: Medium
// 700: Bold
