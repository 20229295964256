import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import Loading from 'core-system/Loading'
import Footer from 'core-system/Program/Footer'
import ProgramModal from 'core-system/Program/ProgramModal'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { qtfActions } from 'redux/qtf/qtfSlice'
import {
  QtfManageState,
  QtfProgram,
  QtfProgramManageProgram,
} from 'redux/qtf/qtfTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import QTFActivationSetup from '../Activation/components/QTFActivationSetup'
import QTFActivationSummary from '../Activation/components/QTFActivationSummary'
import QTFProgramComms from '../Activation/components/QTFProgramComms'
import QTFManagePending from './QTFManagePending'
import QTFUtils from '../Shared/QTFUtils'
import moment from 'moment'
import AlertBanner from 'core-system/AlertBanner'

const StyledColumnView = styled(ColumnView)`
  margin-bottom: 10rem;
  grid-template-columns: 64.5% 34.5%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    max-width: ${(props) => props.theme.pxToRem(700)};
    grid-gap: unset;
  }
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const getDefaultOptions = (program: QtfProgram) => {
  return {
    parking:
      !program.typeMap.COMMUTER_PARKING ||
      program.typeMap?.COMMUTER_PARKING?.status === 'CANCELLING'
        ? false
        : true,

    transit:
      !program.typeMap?.COMMUTER_TRANSIT ||
      program.typeMap?.COMMUTER_TRANSIT?.status === 'CANCELLING'
        ? false
        : true,
  }
}

const getDefaultState = (program: QtfProgram): QtfProgramManageProgram => {
  const hasParking =
    !program?.typeMap.COMMUTER_PARKING ||
    program.typeMap?.COMMUTER_PARKING?.status === 'CANCELLING'
      ? false
      : true
  const hasTransit =
    !program?.typeMap?.COMMUTER_TRANSIT ||
    program.typeMap?.COMMUTER_TRANSIT?.status === 'CANCELLING'
      ? false
      : true

  return {
    ...program,
    parking: hasParking,
    transit: hasTransit,
  }
}

const QTFManageView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )
  const {
    qtfPrograms,
    qtfProgramsLoaded,
    qtfProgramUpdated,
    qtfProgramCommunicationUsers,
  } = useSelector((state: AppState) => state.qtf)

  // hris connect
  const hrisStatus = useSelector((state: AppState) => ({
    providerName: state.employer.profile?.payrollProviderId,
    reauth: state.employer.profile?.finchReauth,
  }))

  const [isEdit, setIsEdit] = useState(false)
  const [isEditUserComms, setIsEditUserComms] = useState(false)

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [programState, setProgramState] = useState<QtfManageState>({
    active: null,
    nextMonth: null,
  })
  const [pendingProgram, setPendingProgram] = useState({
    program: null,
    activeOptions: null,
  })
  const [activeState, setActiveState] = useState('active')
  const [activeOptions, setActiveOptions] = useState<Dictionary>(null)

  const [commUserIds, setCommUserIds] = useState(null)
  const [estimatedMonthlySpend, setEstimatedMonthlySpend] = useState(0)
  const [showAlertBanner, setShowAlertBanner] = useState(
    segmentsMap?.[segmentId]?.contractors.length > 0
  )

  useEffect(() => {
    dispatch(qtfActions.getQtfProgramCommUsers({ segmentId }))
  }, [segmentId, dispatch])

  useEffect(() => {
    if (qtfPrograms) {
      const activeProgramGroup = qtfPrograms[segmentId]

      if (!activeProgramGroup?.active && activeProgramGroup?.nextMonth) {
        const nextMonthState = getDefaultState(activeProgramGroup.nextMonth)
        const nextMonthOptions = getDefaultOptions(activeProgramGroup.nextMonth)

        setProgramState((prev) => {
          return {
            ...prev,
            nextMonth: nextMonthState,
          }
        })
        setActiveOptions({
          active: null,
          nextMonth: nextMonthOptions,
        })
        // setIsEdit(true)
        setActiveState('nextMonth')
        setPendingProgram({
          program: nextMonthState,
          activeOptions: nextMonthOptions,
        })
      } else if (activeProgramGroup?.active) {
        const activeProgramState = getDefaultState(activeProgramGroup.active)
        const defaultActiveOptions = getDefaultOptions(
          activeProgramGroup.active
        )

        setProgramState({
          active: activeProgramState,
          nextMonth: activeProgramGroup.nextMonth
            ? getDefaultState(activeProgramGroup.nextMonth)
            : {
                ...activeProgramState,
                startDate: moment()
                  .add(1, 'M')
                  .startOf('month')
                  .format('YYYY-MM-DDTHH:mm:ss'),
              },
        })

        setActiveOptions({
          active: defaultActiveOptions,
          nextMonth: activeProgramGroup.nextMonth
            ? getDefaultOptions(activeProgramGroup.nextMonth)
            : defaultActiveOptions,
        })
      }
    }
  }, [qtfPrograms, segmentId])

  useEffect(() => {
    if (qtfProgramsLoaded && qtfProgramUpdated) {
      dispatch(qtfActions.toggleQtfProgramUpdated())
      if (!qtfPrograms[segmentId].active) {
        navigate(`/tax-savings/${segmentId}`)
      } else {
        setIsEdit(false)
        setActiveState('active')
        setIsUpdateModalOpen(false)
      }
    }
  }, [
    qtfProgramsLoaded,
    dispatch,
    navigate,
    qtfProgramUpdated,
    segmentId,
    qtfPrograms,
  ])

  // set comms state
  useEffect(() => {
    if (qtfProgramCommunicationUsers && segmentId) {
      setCommUserIds(qtfProgramCommunicationUsers[segmentId]?.userIds)
    }
  }, [qtfProgramCommunicationUsers, segmentId])

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false)
  }

  const handleUpdateModalToggle = () => {
    setIsUpdateModalOpen((prevState) => !prevState)
  }

  const toggleUserCommEdit = () => {
    setCommUserIds(qtfProgramCommunicationUsers[segmentId].userIds)
    setIsEditUserComms((prevState) => !prevState)
  }

  const handleCommUserUpdate = () => {
    dispatch(
      qtfActions.updateQtfProgramCommUsers({ segmentId, userIds: commUserIds })
    )
    setIsEditUserComms((prevState) => !prevState)
  }

  //toggle edit
  const toggleEdit = () => {
    if (isEdit) {
      const activeProgramGroup = qtfPrograms[segmentId]
      setProgramState((prevState) => {
        return {
          ...prevState,
          nextMonth: activeProgramGroup.nextMonth
            ? getDefaultState(activeProgramGroup.nextMonth)
            : prevState.active,
        }
      })
    }

    setIsEdit((prevState) => !prevState)

    // We only want to change back to the active state from the next month state if there
    // is an active program (e.g. if we are editing a new program that hasn't started there wont be anything active)
    if (programState.active) {
      setActiveState((prevState) => {
        return prevState === 'active' ? 'nextMonth' : 'active'
      })
    }

    SegmentService.track('program-manage-action', {
      action: isEdit ? 'close' : 'edit',
      programType: 'qtf',
      segmentName: segmentsMap[segmentId].name,
    })
  }

  const handleToggle = (type: 'parking' | 'transit') => {
    setActiveOptions((prev) => {
      return {
        ...prev,
        nextMonth: {
          ...prev.nextMonth,
          [type]: !prev.nextMonth[type],
        },
      }
    })
  }

  //handle update
  const handleUpdate = () => {
    const nextMonthProgram = programState.nextMonth
    let types = []

    if (activeOptions.nextMonth.transit) types.push('COMMUTER_TRANSIT')
    if (activeOptions.nextMonth.parking) types.push('COMMUTER_PARKING')

    const disableIds = []
    const typeMap = {
      COMMUTER_TRANSIT: 'transit',
      COMMUTER_PARKING: 'parking',
    }

    //check if active options need to be cancelled
    if (!programState.active && programState.nextMonth) {
      //pending program
      for (const programId in programState.nextMonth.idMap) {
        const type = typeMap[programState.nextMonth.idMap[programId]]
        if (
          pendingProgram.activeOptions[type] !==
            activeOptions.nextMonth[type] &&
          !activeOptions.nextMonth[type]
        ) {
          disableIds.push(programId)
        }
      }
    } else if (programState.active && programState.nextMonth) {
      //active program
      for (const type in typeMap) {
        const typeMapped = typeMap[type]

        if (
          activeOptions.active[typeMapped] !==
            activeOptions.nextMonth[typeMapped] &&
          !activeOptions.nextMonth[typeMapped]
        ) {
          disableIds.push(
            programState.active.typeMap[type]
              ? programState.active.typeMap[type].id
              : programState.nextMonth.typeMap[type]
          )
        }
      }
    }

    //check if programs need to be activated
    if (types.length > programState.nextMonth.type.length) {
      const difference = types.filter(
        (x) => !programState.nextMonth.type.includes(x)
      )
      //remove diff from types
      if (difference.length > 0) {
        types = types.filter((t) => t !== difference[0])
      }

      dispatch(
        qtfActions.createQtfProgram({
          startDate: nextMonthProgram.startDate,
          endDate: nextMonthProgram.endDate,
          segment: nextMonthProgram.segment,
          contributionDeadlineDay: nextMonthProgram.contributionDeadlineDay,
          types: difference,
          manual: nextMonthProgram.manual,
          nextValidClose: nextMonthProgram.nextValidClose,
          payrollCycle: nextMonthProgram.payrollCycle,
          commUserIds,
          employerTransitContribution:
            nextMonthProgram.employerTransitContribution,
          employerParkingContribution:
            nextMonthProgram.employerParkingContribution,
        })
      )
    }

    if (disableIds.length > 0) dispatch(qtfActions.cancelQtfProgram(disableIds))

    dispatch(
      qtfActions.updateQtfProgram({
        startDate: nextMonthProgram.startDate,
        endDate: nextMonthProgram.endDate,
        segment: nextMonthProgram.segment,
        contributionDeadlineDay: nextMonthProgram.contributionDeadlineDay,
        typeMap: nextMonthProgram.typeMap,
        manual: nextMonthProgram.manual,
        nextValidClose: nextMonthProgram.nextValidClose,
        payrollCycle: nextMonthProgram.payrollCycle,
        commUserIds,
        types,
        employerTransitContribution:
          nextMonthProgram.employerTransitContribution,
        employerParkingContribution:
          nextMonthProgram.employerParkingContribution,
      })
    )
  }

  //handle cancel
  const handleProgramCancel = (programGroup: 'active' | 'nextMonth') => {
    const relevantProgram = programState[programGroup]
    let ids = []

    //check if only one types needs to be reactivated
    if (activeOptions.nextMonth.transit !== activeOptions.nextMonth.parking) {
      if (activeOptions.nextMonth.transit)
        ids.push(relevantProgram.typeMap.COMMUTER_TRANSIT.id)
      if (activeOptions.nextMonth.parking)
        ids.push(relevantProgram.typeMap.COMMUTER_PARKING.id)
    } else {
      ids = Object.keys(relevantProgram.idMap)
    }

    dispatch(qtfActions.cancelQtfProgram(ids))
    setIsCancelModalOpen(false)
  }

  //handle reactivate
  const handleReActivate = () => {
    handleProgramCancel('nextMonth')
  }

  const handleContributionDayChange = (newDay: number) => {
    if (newDay <= 27 && newDay >= 5) {
      setProgramState((prev) => {
        return {
          ...prev,
          nextMonth: {
            ...prev.nextMonth,
            contributionDeadlineDay: newDay,
          },
        }
      })
    }
  }

  const handleEmployerTransitContributionChange = (amount: number) => {
    setProgramState((prev) => {
      return {
        ...prev,
        nextMonth: {
          ...prev.nextMonth,
          employerTransitContribution: (amount || 0) * 100,
        },
      }
    })
  }

  const handleEmployerParkingContributionChange = (amount: number) => {
    setProgramState((prev) => {
      return {
        ...prev,
        nextMonth: {
          ...prev.nextMonth,
          employerParkingContribution: (amount || 0) * 100,
        },
      }
    })
  }

  if (
    qtfProgramsLoaded &&
    !qtfPrograms[segmentId] &&
    !qtfProgramCommunicationUsers
  ) {
    return <Navigate to={Locations.QTF.Programs} replace />
  }

  if (
    !qtfProgramsLoaded ||
    (!programState.active && !programState.nextMonth) ||
    !qtfProgramCommunicationUsers
  ) {
    return <Loading fullPage />
  }

  const noActiveOptions = Object.keys(activeOptions.nextMonth).every(
    (option) => !activeOptions.nextMonth[option]
  )

  const extraHrisConfig = programState[activeState].manual
    ? null
    : {
        payrollSchedule: programState[activeState].payrollCycle,
        closingDate: new Date(activeOptions[activeState].nextValidClose),
      }

  return (
    <>
      {showAlertBanner && (
        <AlertBanner
          variant='yellowAlert'
          message={`${
            segmentsMap?.[segmentId]?.contractors.length || 0
          } commuter${
            segmentsMap?.[segmentId]?.contractors.length === 1 ? '' : 's'
          } at this worksite are contractors. They are not eligible for pre-tax benefits.`}
          closeFn={() => setShowAlertBanner(false)}
          marginBottom='1rem'
        />
      )}
      <StyledColumnView>
        <Column>
          <QTFActivationSetup
            contribStartDate={moment(programState[activeState].startDate)}
            setupMode={programState[activeState].manual ? 'manual' : 'hris'}
            // non editable
            setSetupMode={null}
            transitActive={activeOptions[activeState].transit}
            parkingActive={activeOptions[activeState].parking}
            employerTransitContribution={
              programState[activeState].employerTransitContribution
            }
            employerParkingContribution={
              programState[activeState].employerParkingContribution
            }
            onEmployerTransitContributionChange={
              handleEmployerTransitContributionChange
            }
            onEmployerParkingContributionChange={
              handleEmployerParkingContributionChange
            }
            handleToggle={handleToggle}
            contributionDays={programState[activeState].contributionDeadlineDay}
            onContributionDayChange={handleContributionDayChange}
            canEdit={isEdit}
            // non editable
            hrisConfig={extraHrisConfig}
            setHrisConfig={null}
            hrisConnectReAuth={hrisStatus.reauth}
            hrisProviderName={hrisStatus.providerName}
            setEstimatedMonthlySpend={setEstimatedMonthlySpend}
            segmentId={segmentId}
          />
          <QTFProgramComms
            selectedUserIds={commUserIds}
            canEdit={isEditUserComms}
            onUserIdsChange={(ids) => setCommUserIds(ids)}
          />
        </Column>
        <Column>
          <QTFActivationSummary
            startDate={programState[activeState].startDate}
            currentSegment={segmentsMap[segmentId]}
            transitActive={activeOptions[activeState].transit}
            parkingActive={activeOptions[activeState].parking}
            employerTransitContribution={
              programState[activeState].employerTransitContribution
            }
            employerParkingContribution={
              programState[activeState].employerParkingContribution
            }
            contributionDays={programState[activeState].contributionDeadlineDay}
            canEdit={isEdit}
            toggleEdit={toggleEdit}
            userCommEdit={isEditUserComms}
            commUserIds={commUserIds}
            toggleUserCommEdit={toggleUserCommEdit}
            setupMode={programState[activeState].manual ? 'manual' : 'hris'}
            hrisConfig={extraHrisConfig}
            estimatedMonthlySpend={estimatedMonthlySpend}
          />
          <QTFManagePending
            isPending={!programState.active && true}
            programState={
              programState.active
                ? programState
                : { ...programState, active: pendingProgram.program }
            }
            activeOptions={
              programState.active
                ? activeOptions
                : { ...activeOptions, active: pendingProgram.activeOptions }
            }
            canEdit={isEdit}
            openUpdateModal={handleUpdateModalToggle}
          />
        </Column>
      </StyledColumnView>
      {isEdit && (
        <Footer>
          <FooterContainer>
            {(programState.nextMonth.typeMap?.COMMUTER_PARKING?.status !==
              'CANCELLING' ||
              programState.nextMonth.typeMap?.COMMUTER_TRANSIT?.status !==
                'CANCELLING') && (
              <Button
                variant='cancel'
                onClick={() => {
                  setIsCancelModalOpen(true)
                  SegmentService.track('program-manage-action', {
                    action: 'cancel',
                    programType: 'flex',
                    segmentName: segmentsMap[segmentId].name,
                  })
                }}
              >
                End Program
              </Button>
            )}
            <ButtonContainer>
              <Button
                variant='tertiary'
                marginRight='1rem'
                onClick={toggleEdit}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  noActiveOptions ||
                  QTFUtils.monthlyPreTaxParking <
                    programState.nextMonth.employerParkingContribution ||
                  programState.nextMonth.employerParkingContribution < 0 ||
                  QTFUtils.monthlyPreTaxTransit <
                    programState.nextMonth.employerTransitContribution ||
                  programState.nextMonth.employerTransitContribution < 0
                }
                onClick={() =>
                  programState.nextMonth.typeMap?.COMMUTER_PARKING?.status ===
                    'CANCELLING' &&
                  programState.nextMonth.typeMap?.COMMUTER_TRANSIT?.status ===
                    'CANCELLING'
                    ? handleUpdateModalToggle()
                    : handleUpdate()
                }
              >
                Update Program
              </Button>
            </ButtonContainer>
          </FooterContainer>
        </Footer>
      )}
      {isEditUserComms && (
        <Footer>
          <FooterContainer>
            <div></div>
            <ButtonContainer>
              <Button
                variant='tertiary'
                marginRight='1rem'
                onClick={toggleUserCommEdit}
              >
                Cancel
              </Button>
              <Button onClick={handleCommUserUpdate}>Update Emails</Button>
            </ButtonContainer>
          </FooterContainer>
        </Footer>
      )}
      <ProgramModal
        title='Cancel Program'
        description='Are you sure you want to cancel this program? Your commuters will lose
        all access to this programs benefits.'
        buttonText='Cancel Program'
        open={isCancelModalOpen}
        closeModal={handleCancelModalClose}
        buttonCallback={() =>
          handleProgramCancel(programState.active ? 'active' : 'nextMonth')
        }
      />
      <ProgramModal
        title='Reactivate Program'
        description='Do you want to reactive this program? All previous benefits will be available again next month.'
        buttonText='Reactivate Program'
        open={isUpdateModalOpen}
        closeModal={handleUpdateModalToggle}
        buttonCallback={handleReActivate}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFManageView.displayName = 'QTFManageView'
}

export default QTFManageView
