import React from 'react'
import styled, { css } from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  variant,
  typography,
  TypographyProps,
} from 'styled-system'
import { color, ColorProps } from '../@styled-system/Color'
import { buildComponent } from '../utils/buildComponent'

export type TextVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'body1'
  | 'body2'
  | 'action1'
  | 'action2'
  | 'action3'
  | 'action4'
  | 'action5'
  | 'action6'
  | 'caption'
  | 'hyperlink'

export interface TextProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    TypographyProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  variant: TextVariants
  children: React.ReactNode
  hideOverflow?: boolean
  captionTitle?: boolean
  capitalize?: boolean
  placeAbove?: boolean
  noWrap?: boolean
  preWrap?: boolean
  dataCy?: string
}

const Text = styled.div<
  SpaceProps &
    LayoutProps &
    ColorProps &
    FlexboxProps &
    TypographyProps & {
      hideOverflow: boolean
      captionTitle: boolean
      capitalize: boolean
      placeAbove: boolean
      noWrap: boolean
      preWrap: boolean
    }
>`
  margin: 0;
  color: ${(props) =>
    props.color ? props.color : props.theme.palette.text.primary};

  ${(props) =>
    variant({
      variants: {
        h1: props.theme.typography.h1,
        h2: props.theme.typography.h2,
        h3: props.theme.typography.h3,
        h4: props.theme.typography.h4,
        h5: props.theme.typography.h5,
        body1: props.theme.typography.body1,
        body2: props.theme.typography.body2,
        action1: props.theme.typography.action1,
        action2: props.theme.typography.action2,
        action3: props.theme.typography.action3,
        action4: props.theme.typography.action4,
        action5: props.theme.typography.action5,
        action6: props.theme.typography.action6,
        caption: props.theme.typography.caption,
        hyperlink: props.theme.typography.hyperlink,
      },
    })}

  ${(props) =>
    props.hideOverflow &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}

  ${(props) =>
    props.captionTitle &&
    css`
      text-transform: uppercase;
      letter-spacing: 0.7px;
    `}

  ${(props) =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `}

  ${(props) =>
    props.placeAbove &&
    css`
      z-index: 100;
    `}

  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `}

  ${(props) =>
    props.preWrap &&
    css`
      white-space: pre-wrap;
    `}

  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
`

export default buildComponent((props: TextProps) => {
  return (
    <Text
      hideOverflow={props.hideOverflow}
      captionTitle={props.captionTitle}
      capitalize={props.capitalize}
      placeAbove={props.placeAbove}
      noWrap={props.noWrap}
      data-cy={props.dataCy}
      preWrap={props.preWrap}
      {...props}
    ></Text>
  )
}, 'Text')
