import * as IncentiveIcons from 'core-system/Icons/Incentives'
import * as LeaderboardTypes from 'core-system/Icons/Leaderboards/types'
import React from 'react'
import {
  CompetitionTypeGroup,
  LeaderboardManageState,
} from 'redux/leaderboards/leaderboardsTypes'
import FormattingUtils from 'shared/FormattingUtils'

const defaultBonuses = [4000, 2000, 1000]

const leaderboardData = {
  ENV: {
    icon: <IncentiveIcons.Env width='40' height='40' />,
    iconLg: <IncentiveIcons.Env width='68' height='68' />,
    title: 'Environmental Savings',
    smallTitle: 'Env. Savings',
    description: 'Incentivize less carbon intensive modes like..',
    modeTypes: [
      'BIKE',
      'SCOOTER',
      'WALK',
      'MOPED',
      'CARPOOL',
      'VANPOOL',
      'SHUTTLE',
      'TRANSIT',
    ],
    statUnit: 'lb',
    statDesc: 'lbs of CO2 saved',
  },
  WALK: {
    icon: <IncentiveIcons.Walk width='40' height='40' />,
    iconLg: <IncentiveIcons.Walk width='64' height='64' />,
    title: 'Walk to Work',
    smallTitle: 'Walk to Work',
    description:
      'Incentivize healthy living by rewarding commuters for walking more.',
    modeTypes: ['WALK'],
    statUnit: 'mi',
    statDesc: 'Miles Walked',
  },
  BIKE: {
    icon: <IncentiveIcons.Bike width='40' height='40' />,
    iconLg: <IncentiveIcons.Bike width='64' height='64' />,
    title: 'Bike to Work',
    smallTitle: 'Bike to Work',
    description:
      'Incentivize healthy living by rewarding commuters for including biking in their commutes.',
    modeTypes: ['BIKE'],
    statUnit: 'mi',
    statDesc: 'Miles Biked',
  },
  ALTERNATIVE: {
    icon: <LeaderboardTypes.Alternative width='40' height='40' />,
    iconLg: <LeaderboardTypes.Alternative width='64' height='64' />,
    title: 'Alternative Commute Mode Trips',
    smallTitle: 'Alternative Modes',
    description: 'Reward commuters for using alternatives to solo car trips.',
    modeTypes: [
      'BIKE',
      'SCOOTER',
      'WALK',
      'MOPED',
      'CARPOOL',
      'VANPOOL',
      'SHUTTLE',
      'TRANSIT',
      'RIDESHARE',
    ],
    statUnit: 'trips',
    statDesc: 'Trips Taken',
  },
  MICROMOBILITY: {
    icon: <LeaderboardTypes.Micromobility width='40' height='40' />,
    iconLg: <LeaderboardTypes.Micromobility width='64' height='64' />,
    title: 'Micromobility Trips',
    smallTitle: 'Micromobility',
    description:
      'Reward commuters for using bikes, scooters, or other micromobility vehicles in their commutes.',
    modeTypes: ['BIKE', 'SCOOTER', 'MOPED'],
    statUnit: 'trips',
    statDesc: 'Trips Taken',
  },
  TRANSIT: {
    icon: <LeaderboardTypes.Transit width='40' height='40' />,
    iconLg: <LeaderboardTypes.Transit width='64' height='64' />,
    title: 'Public Transportation Trips',
    smallTitle: 'Transportation',
    description:
      'Reward commuters for using public transportation in their commutes.',
    modeTypes: ['TRANSIT'],
    statUnit: 'trips',
    statDesc: 'Trips Taken',
  },
  CARPOOL: {
    icon: <LeaderboardTypes.Carpool width='40' height='40' />,
    iconLg: <LeaderboardTypes.Carpool width='64' height='64' />,
    title: 'Carpool Trips',
    smallTitle: 'Carpool',
    description: 'Reward commuters for carpooling.',
    modeTypes: ['CARPOOL'],
    statUnit: 'trips',
    statDesc: 'Trips Taken',
  },
}

const validLeaderboardTypes = [
  'ENV',
  'WALK',
  'BIKE',
  'ALTERNATIVE',
  'MICROMOBILITY',
  'TRANSIT',
  'CARPOOL',
]
const activeLeaderboardStatuses = ['ACTIVE', 'CANCELLING', 'PENDING']

//this is used to see if the upcoming competition has changes
const leaderboardHasChanges = (leaderboardState: LeaderboardManageState) => {
  const currentBudget = FormattingUtils.formatDollar(
    leaderboardState.active.bonuses.reduce((sum, bonus) => sum + bonus, 0),
    0
  )
  const nextMonthBudget = FormattingUtils.formatDollar(
    leaderboardState.nextMonth.bonuses.reduce((sum, bonus) => sum + bonus, 0),
    0
  )

  // const activeEndDate =
  //   leaderboardState.active.endDate &&
  //   moment(leaderboardState.active.endDate).format('MMMM Do, YYYY')
  // const nextMonthEndDate =
  //   leaderboardState.nextMonth.endDate &&
  //   moment(leaderboardState.nextMonth.endDate).format('MMMM Do, YYYY')

  return (
    currentBudget !== nextMonthBudget
  ) /* activeEndDate !== nextMonthEndDate */
}

const getActiveLeaderboards = (programGroups: CompetitionTypeGroup) => {
  return Object.values(programGroups).map((group) =>
    group.active ? group.active : group.nextMonth
  )
}

export default {
  defaultBonuses,
  leaderboardData,
  validLeaderboardTypes,
  activeLeaderboardStatuses,
  leaderboardHasChanges,
  getActiveLeaderboards,
}
