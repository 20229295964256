import Button from 'core-system/Button'
import ChipDifference from 'core-system/Chip/ChipDifference'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Text from 'core-system/Text'
import React from 'react'
import { DashboardMetric } from 'redux/dashboard/dashboardTypes'
import FormattingUtils from 'shared/FormattingUtils'
import NumberUtils from 'shared/NumberUtils'
import styled, { css } from 'styled-components'

type FormatType = 'money' | 'environment' | 'percent' | 'hours' | null
type SubTextType = 'month' | 'employees' | null

const metricMap = {
  preTaxSavings: {
    icon: (
      <PlatformSvg folder='metrics' variant='money2' width={32} height={32} />
    ),
    title: 'pre-tax savings',
    format: 'money',
    sub: null,
    error: '-',
    location: null,
    reverse: false,
  },
  offsetContribution: {
    icon: (
      <PlatformSvg folder='metrics' variant='globe' width={32} height={32} />
    ),
    title: 'offset contribution',
    format: 'environment',
    sub: null,
    error: 'Activate Carbon Offsets',
    location: null,
    reverse: false,
  },
  carbonFootprint: {
    icon: (
      <PlatformSvg folder='metrics' variant='leaf2' width={32} height={32} />
    ),
    title: 'carbon footprint',
    format: 'environment',
    sub: null,
    error: '-',
    location: null,
    reverse: true,
  },
  activeTrips: {
    icon: (
      <PlatformSvg folder='metrics' variant='bike' width={32} height={32} />
    ),
    title: 'active trips taken',
    format: null,
    sub: null,
    error: '-',
    location: null,
    reverse: false,
  },
  commuterAgony: {
    icon: (
      <PlatformSvg folder='metrics' variant='agony' width={32} height={32} />
    ),
    title: 'commuter agony',
    format: null,
    sub: 'employees',
    error: '-',
    location: null,
    reverse: true,
  },
  timeSaved: {
    icon: (
      <PlatformSvg
        folder='metrics'
        variant='productiveTimeGains'
        width={32}
        height={32}
      />
    ),
    title: 'total time saved',
    format: 'hours',
    sub: null,
    error: '-',
    location: null,
    reverse: false,
  },
  riskEmployees: {
    icon: (
      <PlatformSvg
        folder='metrics'
        variant='covidPink'
        width={32}
        height={32}
      />
    ),
    title: 'high risk commuters',
    format: null,
    sub: 'employees',
    error: '-',
    location: null,
    reverse: true,
  },
  productiveGains: {
    icon: (
      <PlatformSvg
        folder='metrics'
        variant='productiveGains'
        width={32}
        height={32}
      />
    ),
    title: 'productivity gains',
    format: 'money',
    sub: null,
    error: '-',
    location: null,
    reverse: false,
  },
  discountSavings: {
    icon: (
      <PlatformSvg folder='metrics' variant='money2' width={32} height={32} />
    ),
    title: 'discount savings',
    format: 'money',
    sub: null,
    error: '-',
    location: null,
    reverse: false,
  },
}

const Container = styled.div`
  ${(props) => props.theme.baseCard}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`

const Metrics = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Metric = styled.div<{ isDouble: boolean; isTriple: boolean }>`
  display: flex;
  width: 100%;

  ${(props) =>
    props.isDouble &&
    css`
      width: 50%;

      &:nth-child(1) {
        border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
        border-right: 1px solid ${(props) => props.theme.palette.grey.grey3};
        padding: 0 1rem 1rem 0;
      }

      &:nth-child(2) {
        border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
        padding: 0 0 1rem 1rem;
      }

      &:nth-child(3) {
        border-right: 1px solid ${(props) => props.theme.palette.grey.grey3};
        padding: 1rem 1rem 0 0;
      }

      &:nth-child(4) {
        padding: 1rem 0 0 1rem;
      }
    `}

  ${(props) =>
    props.isTriple &&
    css`
      width: 33.3%;

      &:nth-child(-n + 3) {
        border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
        padding-bottom: 1rem;
      }

      &:nth-child(n + 4) {
        padding-top: 1rem;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        border-right: 1px solid ${(props) => props.theme.palette.grey.grey3};
        padding-right: 1rem;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6) {
        padding-left: 1rem;
      }
    `}

  ${(props) =>
    !props.isDouble &&
    !props.isTriple &&
    css`
      &:nth-child(odd) {
        border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }
    `}
`

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MetricContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
`

const Value = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

const MetricIconContainer = styled.div`
  min-width: 2rem;
  height: 2rem;
`

const OverflowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0.125rem 0 0 0.75rem;
  overflow: hidden;
`

const MetricTitle = styled(Text)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.secondary};
  letter-spacing: 0.7px;
`

const MetricText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SubText = styled(Text)`
  margin: 0 0 0.1875rem 0.125rem;
  color: ${(props) => props.theme.palette.text.placeholder};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.125rem;

  a {
    text-transform: capitalize;
    color: ${(props) => props.theme.palette.white};
    text-decoration: none;
  }
`

const formatDollar = (value: number) => {
  if (value / 100 < 1000 && value / 100 > -1000) {
    return `${FormattingUtils.formatDollar(value, 0)}`
  } else {
    return `${FormattingUtils.formatDollar(value, 2, true)}`
  }
}

const formatValue = (value: number, format: FormatType) => {
  const isTooLarge = (value >= 1000 || value <= -1000) && format !== 'percent'

  const formattedVal = NumberUtils.formatNumber(
    isTooLarge ? value / 1000 : value,
    0
  )

  if (format === 'money') {
    return formatDollar(value)
  } else if (format === 'environment') {
    return `${formattedVal}${isTooLarge ? 'k ' : ''}lbs`
  } else if (format === 'percent') {
    return `${formattedVal}%`
  } else if (format === 'hours') {
    return `${formattedVal}${isTooLarge ? 'k ' : ''}hrs`
  } else {
    return formattedVal
  }
}

const getSubText = (sub: SubTextType, totalEmployees: number) => {
  if (sub === 'month') {
    return '/month'
  } else if (sub === 'employees') {
    return `/ ${totalEmployees}`
  } else {
    return null
  }
}

const getDifference = (metric: DashboardMetric, reverse: boolean) => {
  const mathSign = Math.sign(metric.percentDiff)

  if (
    mathSign === 0 ||
    !metric.lastMonth ||
    !metric.percentDiff ||
    !metric.currentMonth
  ) {
    return null
  } else {
    return (
      <ChipDifference
        percentDifference={metric.percentDiff * 100}
        marginLeft='auto'
        reverse={reverse}
      />
    )
  }
}

const getMetrics = (
  metrics: DashboardMetric[],
  isDouble: boolean,
  isTriple: boolean,
  totalEmployees: number
) => {
  return metrics.map((metric, idx) => {
    const { icon, format, sub, title, error, reverse } = metricMap[metric.type]
    return (
      <Metric key={idx} isDouble={isDouble} isTriple={isTriple}>
        {metric.currentMonth || error === '-' ? (
          <ValueContainer>
            <MetricContainer>
              <Value>
                <MetricIconContainer>{icon}</MetricIconContainer>
                <OverflowContainer>
                  {metric.currentMonth ? (
                    <>
                      <MetricText variant='h3'>
                        {formatValue(metric.currentMonth, format)}
                      </MetricText>
                      <SubText variant='body2'>
                        {getSubText(sub, totalEmployees)}
                      </SubText>
                    </>
                  ) : (
                    <Text variant='h3'>-</Text>
                  )}
                </OverflowContainer>
              </Value>
              {getDifference(metric, reverse)}
            </MetricContainer>
            <MetricTitle variant='caption'>{title}</MetricTitle>
          </ValueContainer>
        ) : (
          <ErrorContainer>
            <Button size='small' disabled>
              {error}
            </Button>
          </ErrorContainer>
        )}
      </Metric>
    )
  })
}

interface DashboardMetricsProps {
  metrics: DashboardMetric[]
  title: string
  totalEmployees: number
  isDouble?: boolean
  isTriple?: boolean
}

const DashboardMetrics = React.memo((props: DashboardMetricsProps) => {
  const {
    metrics,
    title,
    totalEmployees,
    isDouble = false,
    isTriple = false,
  } = props

  return (
    <Container>
      <Content>
        <Header>
          <Text variant='h5' dataCy='metrics-title'>
            {title}
          </Text>
        </Header>
        <Metrics>
          {getMetrics(metrics, isDouble, isTriple, totalEmployees)}
        </Metrics>
      </Content>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardMetrics.displayName = 'DashboardMetrics'
}

export default DashboardMetrics
