import React from 'react'
import styled, { css } from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps & { center: boolean; centerApart: boolean }
>`
  display: flex;

  ${(props) =>
    props.center &&
    css`
      align-items: center;
      justify-content: center;
    `}

  ${(props) =>
    props.centerApart &&
    css`
      align-items: center;
      justify-content: space-between;
    `}

  ${flexbox}
  ${space}
  ${layout}
`

interface FlexContainerProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  center?: boolean
  centerApart?: boolean
}

const FlexContainer = React.memo((props: FlexContainerProps) => {
  const { children, center = false, centerApart = false, ...rest } = props
  return (
    <Container center={center} centerApart={centerApart} {...rest}>
      {children}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FlexContainer.displayName = 'FlexContainer'
}

export default FlexContainer
