import React from 'react'
import styled from 'styled-components'
import { color, ColorProps } from '../@styled-system/Color'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div`
  padding: 1rem ${(props) => props.theme.pxToRem(52)};
  overflow: auto;
  max-height: calc(100vh - ${(props) => props.theme.pxToRem(200)});
  scrollbar-width: thin;

  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 1rem;
    opacity: 0.8;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    opacity: 1;
  }

  ${flexbox}
  ${space}
  ${layout}
  ${color}
`

interface ModalBodyProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {}

const ModalBody = React.memo((props: ModalBodyProps) => {
  const { children } = props
  return (
    <Container id={'modal-body'} {...props}>
      {children}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ModalBody.displayName = 'ModalBody'
}

export default ModalBody
