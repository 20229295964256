import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.3665 7.26904L9.35484 10.2791L6.34323 7.26826C5.74793 6.67268 5.34258 5.91396 5.17845 5.08804C5.01431 4.26211 5.09875 3.40606 5.42109 2.62811C5.74343 1.85017 6.2892 1.18527 6.98939 0.717474C7.68959 0.249681 8.51276 0 9.35484 0C10.1969 0 11.0201 0.249681 11.7203 0.717474C12.4205 1.18527 12.9662 1.85017 13.2886 2.62811C13.6109 3.40606 13.6954 4.26211 13.5312 5.08804C13.3671 5.91396 12.9618 6.67346 12.3665 7.26904ZM9.35484 5.80645C10.21 5.80645 10.9032 5.11321 10.9032 4.25806C10.9032 3.40291 10.21 2.70968 9.35484 2.70968C8.49969 2.70968 7.80645 3.40291 7.80645 4.25806C7.80645 5.11321 8.49969 5.80645 9.35484 5.80645Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.3252 12.3948C14.1703 11.9381 13.7368 11.6129 13.2258 11.6129H4.70968C4.19871 11.6129 3.7729 11.9381 3.61032 12.3948L2 17.0323V23.2258C2 23.6516 2.34839 24 2.77419 24H3.54839C3.97419 24 4.32258 23.6516 4.32258 23.2258V22.4516H13.6129V23.2258C13.6129 23.6516 13.9613 24 14.3871 24H15.1613C15.5871 24 15.9355 23.6516 15.9355 23.2258V17.0323L14.3252 12.3948ZM4.70968 20.129C4.0671 20.129 3.54839 19.6103 3.54839 18.9677C3.54839 18.3252 4.0671 17.8065 4.70968 17.8065C5.35226 17.8065 5.87097 18.3252 5.87097 18.9677C5.87097 19.6103 5.35226 20.129 4.70968 20.129ZM13.2258 20.129C12.5832 20.129 12.0645 19.6103 12.0645 18.9677C12.0645 18.3252 12.5832 17.8065 13.2258 17.8065C13.8684 17.8065 14.3871 18.3252 14.3871 18.9677C14.3871 19.6103 13.8684 20.129 13.2258 20.129ZM3.54839 16.2581L4.70968 12.7742H13.2258L14.3871 16.2581H3.54839Z'
    />
    <path d='M20.1936 11.6129C21.0529 11.6129 21.7419 10.9239 21.7419 10.0645C21.7419 9.20516 21.0529 8.51613 20.1936 8.51613C19.3342 8.51613 18.6452 9.20516 18.6452 10.0645C18.6452 10.9239 19.3342 11.6129 20.1936 11.6129Z' />
    <path d='M21.7419 24V18.5806H22.9032V13.9355C22.9032 13.0839 22.2065 12.3871 21.3548 12.3871H19.0323C18.1806 12.3871 17.4839 13.0839 17.4839 13.9355V18.5806H18.6452V24H21.7419Z' />
  </>,
  'Rideshare'
)
