import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import { SegmentParams } from 'redux/employer/employerTypes'
import {
  CardProgram,
  Merchant,
  ProgramDashboardStats,
  ProgramGroup,
  ProgramServiceUsage,
  SegmentAndType,
  SegmentAndTypeAndDate,
} from 'redux/programs/programsTypes'
import {
  MicromobilityIsochroneParams,
  MicromobilityProgramUpdate,
} from './micromobilityTypes'
import { programsActions } from 'redux/programs/programsSlice'

export interface MicromobilityState {
  micromobilityPrograms: Dictionary<ProgramGroup>
  micromobilityProgramUpdated: boolean
  micromobilityProgramsLoaded: boolean

  dashboardStats: ProgramDashboardStats
  serviceUsage: ProgramServiceUsage[]
  availableMerchants: {
    leasing: Merchant[]
    shared: Merchant[]
    oneTime: Merchant[]
  }
}

export const initialState: MicromobilityState = {
  micromobilityPrograms: null,
  micromobilityProgramUpdated: false,
  micromobilityProgramsLoaded: false,

  dashboardStats: null,
  serviceUsage: null,
  availableMerchants: null,
}

export const oneTimeProviders = [
  'Unagi',
  'Ridepanda',
  'Bay Wheels',
  'Beyond',
  'Zoomo',
]
export const leasingProviders = [
  'Unagi',
  'Ridy',
  'Ridepanda',
  'Beyond',
  'Zoomo',
  'Wheels',
  'Riide',
]

const handleToggleProgramUpdate = (state: MicromobilityState) => {
  if (!state.micromobilityProgramUpdated) {
    return {
      ...state,
      micromobilityProgramUpdated: !state.micromobilityProgramUpdated,
      micromobilityProgramsLoaded: false,
    }
  }

  return {
    ...state,
    micromobilityProgramUpdated: !state.micromobilityProgramUpdated,
  }
}

const getMicromobilityRecommendations = createAction<SegmentParams>(
  'micromobility/getMicromobilityRecommendations'
)

const createMicromobilityProgram = createAction<CardProgram>(
  'micromobility/createMicromobilityProgram'
)
const createMicromobilityProgramSuccess = createAction(
  'micromobility/createMicromobilityProgramSuccess'
)

const updateMicromobilityProgram = createAction<MicromobilityProgramUpdate>(
  'micromobility/updateMicromobilityProgram'
)

const cancelMicromobilityProgram = createAction<string>(
  'micromobility/cancelMicromobilityProgram'
)

const getMicromobilityIsochrones = createAction<MicromobilityIsochroneParams>(
  'micromobility/getMicromobilityIsochrones'
)
const getMicromobilityIsochronesSuccess = createAction(
  'micromobility/getMicromobilityIsochronesSuccess'
)

const micromobilitySlice = createSlice({
  name: 'micromobility',
  initialState,
  reducers: {
    updateMicromobilityProgramSuccess(state) {
      return handleToggleProgramUpdate(state)
    },
    cancelMicromobilityProgramSuccess(state) {
      return handleToggleProgramUpdate(state)
    },
    toggleMicromobilityProgramUpdated(state) {
      return handleToggleProgramUpdate(state)
    },
    getMicromobilityDashboardStats(
      state,
      _action: PayloadAction<SegmentAndType>
    ) {
      state.dashboardStats = null
    },
    getMicromobilityDashboardStatsSuccess(
      state,
      action: PayloadAction<ProgramDashboardStats>
    ) {
      state.dashboardStats = action.payload
    },
    getMicromobilityDashboardServices(
      state,
      _action: PayloadAction<SegmentAndTypeAndDate>
    ) {
      state.serviceUsage = null
    },
    getMicromobilityDashboardServicesSuccess(
      state,
      action: PayloadAction<ProgramServiceUsage[]>
    ) {
      state.serviceUsage = action.payload
    },
    getMicromobilityRecommendationsSuccess(
      state,
      action: PayloadAction<Merchant[]>
    ) {
      //hardcoded for now cause no way to detect in backend
      const groupings = action.payload.reduce(
        (
          modeGroupings: MicromobilityState['availableMerchants'],
          merchant: Merchant
        ) => {
          if (!ProgramUtils.modeTypes.includes(merchant.name)) {
            if (
              merchant.modeTypes.some((modeType) => modeType.includes('SHARE'))
            ) {
              modeGroupings.shared.push(merchant)
            }
            if (
              leasingProviders.includes(merchant.name) ||
              merchant.pricingTypes.includes('LEASING')
            ) {
              modeGroupings.leasing.push(merchant)
            }
            if (oneTimeProviders.includes(merchant.name)) {
              modeGroupings.oneTime.push(merchant)
            }
          }

          return modeGroupings
        },
        {
          leasing: [],
          shared: [],
          oneTime: [],
        }
      )

      state.availableMerchants = groupings
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      programsActions.getAllProgramsSuccess,
      (state, action: PayloadAction<CardProgram[]>) => {
        const activeStatuses = ['ACTIVE', 'UPDATING']
        const groupedProgramsById = action.payload
          .filter((p) => p.type === 'MICROMOBILITY')
          .reduce((agg, program) => {
            if (program.status === 'CANCELLED') return agg

            const defaultProgramGroup = {} as any
            const formattedProgram = {
              ...program,
              budget: parseInt(program.budget),
            }

            if (activeStatuses.includes(program.status)) {
              defaultProgramGroup.active = formattedProgram
            } else {
              defaultProgramGroup.nextMonth = formattedProgram
            }

            agg[program.segment] = {
              ...agg[program.segment],
              ...defaultProgramGroup,
            }

            return agg
          }, {})

        state.micromobilityPrograms = groupedProgramsById
        state.micromobilityProgramsLoaded = true
      }
    )
  },
})

export const micromobilityReducer = micromobilitySlice.reducer
export const micromobilityActions = {
  ...micromobilitySlice.actions,
  getMicromobilityRecommendations,
  createMicromobilityProgram,
  createMicromobilityProgramSuccess,
  updateMicromobilityProgram,
  cancelMicromobilityProgram,
  getMicromobilityIsochrones,
  getMicromobilityIsochronesSuccess,
}
