import Button from 'core-system/Button'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const Playground = () => {
  const dispatch = useDispatch()
  // const { data, error, isLoading } = useGetProgramsQuery()

  useEffect(() => {
    dispatch({ type: 'micromobility/getWorksites' })
  }, [dispatch])
  return (
    <div style={{ height: '100vh' }}>
      <Button onClick={() => null}>asd</Button>
    </div>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  Playground.displayName = 'Playground'
}

export default Playground
