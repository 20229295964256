import Divider from 'core-system/Divider'
import HorizontalScroller from 'core-system/HorizontalScroller'
import HorizontalScrollerUtils from 'core-system/HorizontalScroller/HorizontalScrollerUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`

const BudgetContainer = styled.div`
  display: flex;
  margin-left: auto;
  min-width: 2.5rem;
`

const StyledText = styled(Text)`
  white-space: nowrap;
`

interface ProgramChangeProps {
  metric?: string
  icon?: React.ReactElement
  value: string
  title?: string
  merchantIds?: string[]
}

const ProgramChange = React.memo((props: ProgramChangeProps) => {
  const { title, metric, icon, value, merchantIds } = props
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )
  return (
    <>
      {title && (
        <>
          <Divider margin='1.5rem 0' />
          <Text
            variant='caption'
            captionTitle
            textColor={palette.text.secondary}
          >
            {title}
          </Text>
        </>
      )}
      <FlexContainer>
        {merchantIds ? (
          <HorizontalScroller
            translationPerClick={115}
            opacityWidth={pxToRem(100)}
            resetOnChildrenUpdate
          >
            {HorizontalScrollerUtils.getProviders(merchantIds, merchantsMap)}
          </HorizontalScroller>
        ) : (
          <>
            {icon && icon}
            <Text variant='action4' marginLeft={icon && '1rem'} hideOverflow>
              {metric}
            </Text>
          </>
        )}
        <BudgetContainer>
          <StyledText variant='action4' marginLeft='1rem'>
            {value}
          </StyledText>
        </BudgetContainer>
      </FlexContainer>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramChange.displayName = 'ProgramChange'
}

export default ProgramChange
