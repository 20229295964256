import React from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import Text from 'core-system/Text'
import FormattingUtils from 'shared/FormattingUtils'
import palette from 'core-system/Themes/palette'
import PlatformSvg from 'core-system/Icons/PlatformSvg'

const PurpleContainer = styled.div<SpaceProps>`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.secondary.purple6};

  ${space}
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`

const BudgetContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

interface SummaryBudgetProps extends SpaceProps {
  budget: number
  numEmps: number
  title: string
  metric: string
}

const SummaryBudget = React.memo((props: SummaryBudgetProps) => {
  const { budget, numEmps, title, metric, ...rest } = props
  return (
    <PurpleContainer {...rest}>
      <HeaderContainer>
        <PlatformSvg folder='metrics' variant='money' />
        <Text variant='action3' marginLeft='0.5rem'>
          {title}
        </Text>
      </HeaderContainer>
      <BudgetContainer>
        <Text variant='h2'>{FormattingUtils.formatDollar(budget, 0)}</Text>
        <Text variant='action3' margin='0.625rem 0 0 0.375rem'>
          {metric}
        </Text>
      </BudgetContainer>
      <Text
        variant='caption'
        marginBottom='0.25rem'
        textColor={palette.text.secondary}
        captionTitle
      >
        Maximum Spend:
      </Text>
      <Text variant='body2' textColor={palette.text.secondary}>
        {FormattingUtils.formatDollar(budget * numEmps, 0)} for {numEmps}{' '}
        Commuters
      </Text>
    </PurpleContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SummaryBudget.displayName = 'SummaryBudget'
}

export default SummaryBudget
