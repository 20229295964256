import { AxiosResponse } from 'axios'
import { SegmentAndDateParams } from 'redux/employer/employerTypes'
import {
  IncentiveProgram,
  IncentiveProgramCreate,
  IncentivesPauseArgs,
  TripPayload,
  OneTimeRewardPayload,
} from 'redux/incentives/incentivesTypes'
import Request from '../config/services/Request'

const getAllIncentives = () => {
  return Request.get('/hr/engagement/incentives/')
}

const getAllIncentivesTrips = () => {
  return Request.get('/hr/engagement/incentives/num_trips/')
}

const getIncentives = (segmentId: string): Promise<AxiosResponse> => {
  return Request.get(`/hr/engagement/incentives/?segment=${segmentId}`)
}

const createIncentives = (
  payload: IncentiveProgramCreate
): Promise<AxiosResponse> => {
  return Request.post(`/hr/engagement/incentives/`, payload)
}

const updateIncentives = (
  payload: IncentiveProgram
): Promise<AxiosResponse> => {
  return Request.put(`/hr/engagement/incentives/${payload.id}/`, payload)
}

const pauseIncentives = (args: IncentivesPauseArgs): Promise<AxiosResponse> => {
  return Request.del(`/hr/engagement/incentives/${args.id}/`)
}

const getIncentivesRewarded = (
  payload: SegmentAndDateParams
): Promise<AxiosResponse> => {
  return Request.post('/hr/engagement/incentives/rewarded/', {
    segment: payload.segmentId,
    rangeStart: payload.rangeStart,
    rangeEnd: null,
    modeTypes: [
      'WALK',
      'BIKE',
      'BIKESHARE',
      'CAR',
      'CARPOOL',
      'MOPED',
      'MOPEDSHARE',
      'RIDESHARE',
      'SCOOTER',
      'SCOOTERSHARE',
      'SHUTTLE',
      'TRANSIT',
      'VANPOOL',
    ],
  })
}

const getTrips = (payload: TripPayload): Promise<AxiosResponse> => {
  return Request.post('/hr/engagement/calendar/', {
    segment: payload.segmentId,
    date: payload.date,
  })
}

const getAllOneTimeRewards = () => {
  return Request.get('/hr/engagement/one_time_rewards/')
}

// TODO: Might be able to merge these into one
// (just make the payload OneTimeRewardPayload | OneTimeRewardPayload[])
const createOneTimeReward = (
  payload: OneTimeRewardPayload
): Promise<AxiosResponse> => {
  return Request.post('/hr/engagement/one_time_rewards/', {
    employeeId: payload.employeeId,
    amount: payload.amount,
    memo: payload.memo,
  })
}

const bulkCreateOneTimeRewards = (
  payload: OneTimeRewardPayload[]
): Promise<AxiosResponse> => {
  return Request.post('/hr/engagement/one_time_rewards/', payload)
}

export default {
  getAllIncentives,
  getAllIncentivesTrips,
  getIncentives,
  createIncentives,
  updateIncentives,
  pauseIncentives,
  getIncentivesRewarded,
  getTrips,
  getAllOneTimeRewards,
  createOneTimeReward,
  bulkCreateOneTimeRewards,
}
