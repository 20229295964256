import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M12.144 9.82802V14.184H18.204C17.256 17.016 15.804 18.552 12.144 18.552C10.4095 18.552 8.74602 17.863 7.51953 16.6365C6.29304 15.41 5.60401 13.7465 5.60401 12.012C5.60401 10.2775 6.29304 8.61403 7.51953 7.38755C8.74602 6.16106 10.4095 5.47202 12.144 5.47202C13.746 5.44461 15.3001 6.0183 16.5 7.08002C17.424 6.16802 17.34 6.03602 19.692 3.86402C17.6203 2.00881 14.9369 0.983309 12.156 0.984025C9.21096 0.961652 6.37748 2.10934 4.27815 4.17492C2.17882 6.2405 0.985366 9.055 0.960007 12C0.985366 14.945 2.17882 17.7595 4.27815 19.8251C6.37748 21.8907 9.21096 23.0384 12.156 23.016C21.396 23.016 23.664 15.096 22.956 9.81602L12.144 9.82802Z'
  />,
  'Google'
)
