class SegmentService {
  active: boolean

  /*
   * Due to race conditions we cannot set a class variable referencing window.analytics
   * Segment ready function was attempted as well but it does not call if any script is blocked
   * by an ad blocker.
   */

  constructor() {
    this.active = process.env.NODE_ENV === 'production'
  }

  disable() {
    this.active = false
  }

  _sendSegmentRequest(func: () => void) {
    if (this.active && window.analytics) {
      func()
    }
  }

  /**
   * Identify a user with a unique Id
   *
   * @param id User unique identifer
   * @param traits dictionary of traits for the group
   **/
  identify(id: string, traits: Dictionary) {
    this._sendSegmentRequest(() => window.analytics.identify(id, traits))
    if (process.env.NODE_ENV === 'development' && !this.active) {
      console.warn('[SegmentService Identify]: ', { id, traits })
    }
  }

  /**
   * Track Segment event
   *
   * @param eventName Name of the event
   * @param properties Set of properties to include with the event.
   *    These describe the user who did the event or details about the event itself
   */
  track(eventName: string, properties?: Dictionary) {
    if (process.env.NODE_ENV === 'development' && !this.active) {
      console.warn('[SegmentService Track]: ', { eventName, properties })
    }

    this._sendSegmentRequest(() =>
      window.analytics.track(eventName, properties)
    )
  }
  /**
   * Record page views, along with optional extra information about the page being viewed.
   * @param name
   * @param properties Set of properties to include with the page view.
   *
   */
  page(name: string, properties?: Dictionary) {
    if (process.env.NODE_ENV === 'development' && !this.active) {
      console.log('[SegmentService Page]: ', name)
    }
    this._sendSegmentRequest(() => window.analytics.page(name, properties))
  }

  /**
   * Associate an individual user with a group
   *
   * @param id group unique identifer
   * @param traits dictionary of traits for the group
   **/
  group(id: string, traits: Dictionary) {
    this._sendSegmentRequest(() => window.analytics.group(id, traits))
    if (process.env.NODE_ENV === 'development' && !this.active) {
      console.warn('[SegmentService Group]: ', { id, traits })
    }
  }

  /**
   * Alias segement event
   *
   * @param id The new user ID you want to associate with the user
   */
  alias(id: string) {
    this._sendSegmentRequest(() => window.analytics.alias(id))
  }

  /**
   * Reset the id, including anonymousId, and clear traits for the currently identified user and group.
   *
   **/
  reset() {
    this._sendSegmentRequest(() => window.analytics.reset())
  }
}

export default new SegmentService()
