import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { conciergeActions } from 'redux/concierge/conciergeSlice'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Worksite } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import AnnouncementsTable from './Announcements/AnnouncementsTable'
import ConciergeHeader from './ConciergeHeader'
import FaqTable from './Faq/FaqTable'
import SurveysView from './Surveys/SurveysView'

const locationMap = {
  announcement: 1,
  survey: 2,
  faq: 3,
  '/concierge/faq': 3,
  '/concierge/announcement': 1,
}

const metricsList = ['announcement', 'survey', 'faq']

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 3rem;
  margin: 0 auto;
  margin-bottom: 3.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: 2.5rem 1.5rem;
  }
`

const ConciergeContent = styled.div`
  max-width: 75rem;
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
`

const renderContent = (
  activeId: number,
  allWorksites: Worksite[],
  allFaqs: any,
  userInfo: {
    email: string
    employerName: string
  }
) => {
  switch (activeId) {
    case 1:
      return <AnnouncementsTable />
    case 2:
      return <SurveysView allSurveys={[]} userInfo={userInfo} />
    case 3:
      return <FaqTable allFaqs={allFaqs} allWorksites={allWorksites} />
    default:
      return null
  }
}

type LocationState = {
  location: string
  from: string
}

const ConciergeView = React.memo(() => {
  const dispatch = useDispatch()
  const location = useLocation()
  const state = location.state as LocationState

  const allWorksites = useSelector(
    (state: AppState) => state.employer.worksites
  )
  const allAnnouncements = useSelector(
    (state: AppState) => state.concierge.announcements
  )
  const allFaqs = useSelector((state: AppState) => state.concierge.faqs)
  const userInfo = useSelector((state: AppState) => {
    return {
      email: state.user.profile.email,
      employerName: state.employer.profile?.name,
    }
  })

  const [activeId, setActiveId] = useState(
    state && state.from ? locationMap[state.from] : 1
  )

  useEffect(() => {
    if (!allAnnouncements) {
      dispatch(conciergeActions.getAnnouncements())
    }
  }, [allAnnouncements, dispatch])

  useEffect(() => {
    if (!allFaqs) {
      dispatch(conciergeActions.getFaqs())
    }
  }, [allFaqs, dispatch])

  const handleHeaderButtonClick = useCallback(
    (id: number) => {
      if (activeId !== id) {
        setActiveId(id)
      }

      SegmentService.track('concierge-view-select', {
        view: metricsList[id - 1],
      })
    },
    [activeId]
  )

  return (
    <div>
      <ConciergeHeader
        activeId={activeId}
        handleClick={handleHeaderButtonClick}
        allFaqs={allFaqs}
      />
      <ContentWrapper>
        <ConciergeContent>
          {renderContent(activeId, allWorksites, allFaqs, userInfo)}
        </ConciergeContent>
      </ContentWrapper>
    </div>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ConciergeView.displayName = 'ConciergeView'
}

export default ConciergeView
