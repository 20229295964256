import { StaticAssetsUrl } from 'redux/config/services/Constants'
import palette from 'core-system/Themes/palette'

export const CommutePreferenceDropdownItems = [
  { id: 'CAR', text: 'Car' },
  { id: 'TRANSIT', text: 'Transit' },
  { id: 'BIKE', text: 'Bike' },
  { id: 'SCOOTER', text: 'Scooter' },
  { id: 'MOPED', text: 'Moped' },
  { id: 'CARPOOL', text: 'Carpool' },
  { id: 'MULTIMODAL', text: 'Multimodal' },
  { id: 'OTHER', text: 'Other' },
]

export const EmployeeOnboardingContent = {
  extraInfo: {
    leftHeader: 'We need some more info to finish setting up your account.',
    rightHeader: 'Welcome to your supercharged commute benefits',
    imageUri: `${StaticAssetsUrl}/onboarding/onboarding1.png`,
    backgroundColor: palette.chips.magenta2,
  },
  acceptTerms: {
    leftHeader:
      'Please Accept the Terms and Conditions for the Fleet Mobility Card',
    rightHeader: 'Welcome to your supercharged commute benefits',
    imageUri: `${StaticAssetsUrl}/onboarding/onboarding1.png`,
    backgroundColor: palette.chips.magenta2,
  },
}
