import { AnyAction } from 'redux'
import { all, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { dashboardActions } from 'redux/dashboard/dashboardSlice'
import DashboardService from 'redux/dashboard/dashboardService'
import { Action } from 'shared/Types/Actions'

export function* getDashboardBulkData(action: AnyAction) {
  yield defaultFlow(
    action,
    DashboardService.getDashboardBulkData,
    dashboardActions.getDashboardBulkDataSuccess
  )
}

export function* getDashboardMapData(action: AnyAction) {
  yield defaultFlow(
    action,
    DashboardService.getDashboardMapData,
    dashboardActions.getDashboardMapDataSuccess
  )
}

export function* getDashboardTrackers(action: AnyAction) {
  yield defaultFlow(
    action,
    DashboardService.getDashboardTrackers,
    dashboardActions.getDashboardTrackersSuccess
  )
}

export function* getDashboardReport(action: Action<string>) {
  yield defaultFlow(
    action,
    DashboardService.getDashboardReport,
    dashboardActions.getDashboardReportSuccess
  )
}

export function* getDashboardCsvReport(action: Action<string>) {
  yield defaultFlow(
    action,
    DashboardService.getDashboardCsvReport,
    dashboardActions.getDashboardCsvReportSuccess
  )
}

/**************************************
 * Watchers
 **************************************/

export function* watchGetDashboardBulkData() {
  yield takeEvery(
    [dashboardActions.getDashboardBulkData.type],
    getDashboardBulkData
  )
}

export function* watchGetDashboardMapData() {
  yield takeEvery(
    [dashboardActions.getDashboardMapData.type],
    getDashboardMapData
  )
}

export function* watchGetDashboardTrackers() {
  yield takeEvery(
    [dashboardActions.getDashboardTrackers.type],
    getDashboardTrackers
  )
}

export function* watchGetDashboardReport() {
  yield takeEvery(
    [dashboardActions.getDashboardReport.type],
    getDashboardReport
  )
}

export function* watchGetDashboardCsvReport() {
  yield takeEvery(
    [dashboardActions.getDashboardCsvReport.type],
    getDashboardCsvReport
  )
}

export default function* () {
  yield all([
    watchGetDashboardBulkData(),
    watchGetDashboardMapData(),
    watchGetDashboardTrackers(),
    watchGetDashboardReport(),
    watchGetDashboardCsvReport(),
  ])
}
