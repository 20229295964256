import { ServiceModeType, ServiceProvider } from 'redux/programs/programsTypes'
import { v4 as uuidv4 } from 'uuid'

// used to add self owned modes into our modes data since it doesn't return with
// rest of mode data from backend by default
const selfOwnedModes: ServiceProvider[] = [
  {
    id: uuidv4(),
    iconUrl: null,
    leadTime: null,
    metroArea: null,
    modeType: 'WALK' as ServiceModeType,
    name: 'WALK',
    precautions: null,
    pricingType: null,
    websiteUrl: null,
    description: 'self owned walk mode',
    tags: ['GREEN', 'ACTIVE'],
  },
  {
    id: uuidv4(),
    iconUrl: null,
    leadTime: null,
    metroArea: null,
    modeType: 'BIKE' as ServiceModeType,
    name: 'Bike',
    precautions: null,
    pricingType: null,
    websiteUrl: null,
    description: 'self owned bike mode',
    tags: ['GREEN', 'ACTIVE'],
  },
  {
    id: uuidv4(),
    iconUrl: null,
    leadTime: null,
    metroArea: null,
    modeType: 'SCOOTER' as ServiceModeType,
    name: 'Scooter',
    precautions: null,
    pricingType: null,
    websiteUrl: null,
    description: 'self owned scooter mode',
    tags: ['GREEN', 'ACTIVE'],
  },
  {
    id: uuidv4(),
    iconUrl: null,
    leadTime: null,
    metroArea: null,
    modeType: 'MOPED' as ServiceModeType,
    name: 'Moped',
    precautions: null,
    pricingType: null,
    websiteUrl: null,
    description: 'self owned moped mode',
    tags: ['GREEN', 'ACTIVE'],
  },
  {
    id: uuidv4(),
    iconUrl: null,
    leadTime: null,
    metroArea: null,
    modeType: 'CAR' as ServiceModeType,
    name: 'Car',
    precautions: null,
    pricingType: null,
    websiteUrl: null,
    description: 'self owned car mode',
    tags: [],
  },
]

// used to get specific mode data for mapIntelligenceActions.getRecentTripOrigins
const relevantMicromobilityModeTypes = [
  'MOPEDSHARE',
  'SCOOTER',
  'MOPED',
  'BIKESHARE',
  'BIKE',
  'SCOOTERSHARE',
  'EBIKE',
  'ESCOOTER',
  'EMOPED',
]
const relevantFlexModeTypes = [
  'MOPEDSHARE',
  'SCOOTER',
  'MOPED',
  'BIKESHARE',
  'BIKE',
  'SCOOTERSHARE',
  'SHUTTLE',
  'CARPOOL',
  'VANPOOL',
  'CAR',
  'TRANSIT',
  'WALK',
  'RIDESHARE',
]

export default {
  selfOwnedModes,
  relevantMicromobilityModeTypes,
  relevantFlexModeTypes,
}
