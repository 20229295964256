import React from 'react'
import { buildForwardingComponent } from '../utils/buildComponent'
import styled from 'styled-components'
import { space, SpaceProps, flex, variant, FlexProps } from 'styled-system'
import Search from '../Icons/Actions/Search'
import Clear from '../Icons/Actions/Clear'
import useFocus from '../hooks/useFocus'

const Container = styled.div<{
  variant?: 'xsmall' | 'small' | 'large'
  barBg?: 'white' | 'colored'
  focused?: boolean
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  border-radius: 0.5rem;
  outline: none;
  color: ${(props) =>
    props.focused
      ? `${props.theme.palette.text.primary} !important`
      : props.theme.palette.text.placeholder};

  :hover {
    color: ${(props) => props.theme.palette.text.secondary};
    border-color: ${(props) => props.theme.palette.text.secondary};
  }

  ${(props) =>
    variant({
      prop: 'barBg',
      variants: {
        white: {
          backgroundColor: props.theme.palette.white,
        },
        colored: {
          backgroundColor: props.focused
            ? props.theme.palette.white
            : props.theme.palette.secondary.purple7,
        },
      },
    })}

  ${space}
  ${flex}
`

const StyledInput = styled.input`
  ${(props) => props.theme.typography.action2};
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
  cursor: text;
  ::placeholder {
    color: 'inherit';
  }

  ${(props) =>
    variant({
      variants: {
        xsmall: {
          ...props.theme.typography.action4,
          padding: `${props.theme.pxToRem(6)} 1rem`,
        },
        small: {
          ...props.theme.typography.action4,
          padding: `${props.theme.pxToRem(10)} 1rem`,
        },
        large: {
          ...props.theme.typography.action2,
          padding: `${props.theme.pxToRem(18)} 1.5rem`,
        },
      },
    })}
`

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;

  svg {
    cursor: pointer;
  }

  ${variant({
    variants: {
      xsmall: {
        padding: '0 1rem',
        div: {
          height: '1.5rem',
        },
      },
      small: {
        padding: '0.5rem 1rem',
        div: {
          height: '1.5rem',
        },
      },
      large: {
        padding: '0.5625rem 1.5rem',
      },
    },
  })}
`

const Spacer = styled.div`
  margin: 0 1.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey2};
  height: 2.5rem;
`

const SearchContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const ClearContainer = styled(SearchContainer)`
  color: ${(props) => props.theme.palette.text.placeholder};
`

export interface SearchInputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    SpaceProps,
    FlexProps {
  onsearch: (_?: any) => void
  onclearSearch: (_?: any) => void
  onChange: (_?: any) => void
  value?: string
  barBg?: 'white' | 'colored'
  variant?: 'xsmall' | 'small' | 'large'
  onenter?(evt: React.KeyboardEvent<HTMLInputElement>): void
}

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (props: SearchInputProps, parentRef: React.Ref<HTMLInputElement>) => {
    const {
      onclearSearch,
      onenter,
      onKeyDown,
      barBg = 'colored',
      variant = 'large',
      onBlur,
      onFocus,
      value,
      ...rest
    } = props
    const ref = parentRef
    const fallbackRef = React.useRef(null)

    const {
      focused,
      onFocus: _onFocus,
      onBlur: _onBlur,
    } = useFocus({
      onFocus,
      onBlur,
    })

    const _onKeyDown = React.useCallback(
      (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.keyCode === 13) {
          onenter && onenter(evt)
          return
        }
        onKeyDown && onKeyDown(evt)
      },
      [onKeyDown, onenter]
    )

    // const _onSearch = React.useCallback(
    //   (_evt: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    //     onsearch && onsearch()
    //   },
    //   [onsearch]
    // )

    const _onClearInput = React.useCallback(
      (_evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
        onclearSearch && onclearSearch()
      },
      [onclearSearch]
    )

    return (
      <Container variant={variant} barBg={barBg} focused={focused} {...rest}>
        <StyledInput
          type='text'
          ref={ref || fallbackRef}
          variant={variant}
          barBg={barBg}
          onKeyDown={_onKeyDown}
          onFocus={_onFocus}
          onBlur={_onBlur}
          {...props}
        />
        <IconsContainer variant={variant} barBg={barBg} {...props}>
          {value !== '' && (
            <>
              <ClearContainer onClick={_onClearInput}>
                <Clear />
              </ClearContainer>
              <Spacer />
            </>
          )}
          <SearchContainer>
            <Search />
          </SearchContainer>
        </IconsContainer>
      </Container>
    )
  }
)

SearchInput.displayName = 'SearchInput'
export default buildForwardingComponent<HTMLInputElement, SearchInputProps>(
  SearchInput
)
