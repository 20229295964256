import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2C6.47625 2 2 6.4775 2 12C2 17.5225 6.47625 22 12 22C17.5225 22 22 17.5225 22 12C22 6.4775 17.5225 2 12 2ZM12 18.25C12.6904 18.25 13.25 17.6904 13.25 17C13.25 16.3096 12.6904 15.75 12 15.75C11.3096 15.75 10.75 16.3096 10.75 17C10.75 17.6904 11.3096 18.25 12 18.25ZM13.25 5.75H10.75V14.5H13.25V5.75Z'
    />
  </>,
  'Alert'
)
