import React from 'react'
import styled from 'styled-components'
import Button from 'core-system/Button'
import Text from 'core-system/Text'
import { variant } from 'styled-system'
import palette from 'core-system/Themes/palette'
import PlatformSvg from 'core-system/Icons/PlatformSvg'

const Container = styled.div<{ status: boolean }>`
  width: 100%;
  background-color: ${(props) => props.theme.palette.secondary.purple5};
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 0.9375rem;
  position: relative;
  padding: ${(props) => props.theme.pxToRem(42)} 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  overflow: hidden;
  justify-content: ${(props) => (props.status ? 'space-between' : 'unset')};
`

const Description = styled.div`
  ${(props) => props.theme.typography.h4};
  width: 45%;
  font-weight: 400;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
  }
`

const StyledButton = styled(Button)`
  cursor: pointer;

  > button {
    ${(props) => props.theme.typography.action1}
    width: ${(props) => props.theme.pxToRem(240)};
  }

  > button > a {
    text-decoration: none;
    color: inherit;
  }
`

const BaseBannerStyles = styled.div<{ background: string; variant: Variant }>`
  position: absolute;

  ${(props) =>
    variant({
      prop: 'background',
      variants: {
        bannerGeneral: {
          left: '51%',
          bottom: '-1rem',
        },
        bannerCreditCard: {
          left: '51%',
          bottom: '-1rem',
        },
        bannerGeneral2: {
          left: '55%',
          bottom: '-0.375rem',
        },
        bannerGeneral3: {
          left: '51%',
          bottom: 0,
        },
        bannerLeaderboards: {
          left: '49%',
          bottom: props.theme.pxToRem(props.variant === 'cta' ? -212 : -252),
        },
        bannerDashboard: {
          left: '55%',
          bottom: props.theme.pxToRem(-92),
        },
      },
    })}


  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: none;
  }
`

type Variant = 'cta' | 'info'

interface BannerProps {
  variant: Variant
  background?:
    | 'bannerGeneral'
    | 'bannerCreditCard'
    | 'bannerGeneral2'
    | 'bannerGeneral3'
    | 'bannerLeaderboards'
    | 'bannerDashboard'
  title: string
  caption?: string
  description: string
  cta?: string
  location?: string
  ctaCallback?: () => void
}

const Banner = React.memo((props: BannerProps) => {
  const {
    variant,
    background = 'bannerGeneral',
    title,
    caption,
    description,
    cta,
    ctaCallback,
  } = props

  return (
    <Container status={variant === 'cta'}>
      <Text variant='h2' marginBottom='1rem' placeAbove>
        {title}
      </Text>
      {caption && (
        <Text
          variant='caption'
          captionTitle
          textColor={palette.primary.primaryPurple}
          marginBottom='1rem'
        >
          {caption}
        </Text>
      )}
      <Description>{description}</Description>
      <BaseBannerStyles background={background} variant={variant}>
        {background === 'bannerGeneral' && (
          <PlatformSvg
            folder='banners'
            variant='banner2'
            width={603}
            height={304}
          />
        )}
        {background === 'bannerCreditCard' && (
          <PlatformSvg
            folder='banners'
            variant='banner3'
            width={601}
            height={261}
          />
        )}
        {background === 'bannerGeneral2' && (
          <PlatformSvg
            folder='banners'
            variant='banner5'
            width={525}
            height={246}
          />
        )}
        {background === 'bannerGeneral3' && (
          <PlatformSvg
            folder='banners'
            variant='banner4'
            width={580}
            height={249}
          />
        )}
        {background === 'bannerLeaderboards' && (
          <PlatformSvg
            folder='banners'
            variant='banner6'
            width={553}
            height={553}
          />
        )}
        {background === 'bannerDashboard' && (
          <PlatformSvg
            folder='banners'
            variant='banner1'
            width={533}
            height={372}
          />
        )}
      </BaseBannerStyles>
      {ctaCallback && (
        <div>
          <StyledButton
            marginTop='1.375rem'
            size='large'
            onClick={() => ctaCallback()}
          >
            {cta}
          </StyledButton>
        </div>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  Banner.displayName = 'Banner'
}
export default Banner
