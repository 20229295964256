import { employerActions } from 'redux/employer/employerSlice'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import Button from 'core-system/Button'
import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { Worksite } from 'redux/employer/employerTypes'
import ArchiveUtils from 'shared/ArchiveUtils'
import styled from 'styled-components'

const Container = styled(FlexContainer)`
  text-align: center;
  padding: 0.5rem 0 1rem;
`

interface WorksiteModalProps {
  closeModal: () => void
  open: boolean
  worksite: Worksite
}

const ArchiveWorksiteModal = React.memo((props: WorksiteModalProps) => {
  const { open, closeModal, worksite } = props

  const dispatch = useDispatch()

  const allMicroPrograms = useSelector(
    (state: AppState) => state.micromobility.micromobilityPrograms
  )
  const allFlexPrograms = useSelector(
    (state: AppState) => state.flex.flexPrograms
  )
  const { leaderboardsMap } = useSelector(
    (state: AppState) => state.leaderboards
  )
  const { allIncentives } = useSelector((state: AppState) => state.incentives)
  const { worksites, allSegments } = useSelector(
    (state: AppState) => state.employer
  )

  const relevantSegment = allSegments.find(
    (segment) => segment.type === 'WORKSITE' && segment.name === worksite.alias
  )

  const hasFlexProgram =
    relevantSegment && allFlexPrograms[relevantSegment.id] ? 1 : 0
  const hasMicromobilityProgram =
    relevantSegment && allMicroPrograms[relevantSegment.id] ? 1 : 0
  const numPrograms = hasFlexProgram + hasMicromobilityProgram

  const numLeaderboards =
    (relevantSegment &&
      leaderboardsMap[relevantSegment.id] &&
      leaderboardsMap[relevantSegment.id].active) ||
    0

  const numIncentives =
    relevantSegment && allIncentives[relevantSegment.id] ? 1 : (0 as number)

  const handleArchive = () => {
    if (worksites && worksites.filter((wrk) => !wrk.archived).length <= 1) {
      dispatch(
        notificationsActions.generalPageError(
          'You need at least one active worksite!'
        )
      )
    } else {
      dispatch(employerActions.archiveWorksite({ worksiteId: worksite.id }))
    }
    closeModal()
  }

  const formattedArchiveMessage = ArchiveUtils.generateArchiveMessage(
    [
      [worksite.employeeCount, 'commuter'],
      [numPrograms, 'program'],
      [numLeaderboards, 'competition'],
      [numIncentives, 'incentive program'],
    ],
    'It will also archive',
    'All historic data will be preserved for reporting.',
    'This will also archive all related products. All historic data will be preserved for reporting.'
  )

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      width={pxToRem(800)}
      maxHeight='unset'
    >
      <ModalHeader title='Archive a Worksite' />
      <ModalBody>
        <Container center flexDirection='column' padding='0.5rem'>
          <PlatformSvg folder='archive' variant='archiveLogo' width='200px' />
          <Text variant='h3' marginTop='1rem'>
            Are you sure you want to archive this worksite?
          </Text>
          <Text variant='action2' marginTop='0.5rem'>
            {formattedArchiveMessage}
          </Text>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          variant={'tertiary'}
          marginRight={pxToRem(29)}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button onClick={handleArchive}>Archive Worksite</Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ArchiveWorksiteModal.displayName = 'ArchiveWorksiteModal'
}

export default ArchiveWorksiteModal
