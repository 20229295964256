const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

const pluralCheck = (number: number) => {
  return number !== 1 ? 's' : ''
}

const capitalize = (msg: string) => {
  if (msg === '') return ''
  return msg[0].toUpperCase() + msg.slice(1).toLowerCase()
}

const title = (msg: string) => {
  return msg
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ')
}

const arrayToString = (arr: string[], lastSeperator = ' & ') => {
  const copyArr = [...arr]

  const lastStr = copyArr.pop()
  let ret = copyArr.join(', ')
  if (lastStr) ret += (ret && lastSeperator) + lastStr
  return ret
}

const copyInviteCode = (worksiteAddress: string, inviteCode: string) => {
  let url = 'http://localhost:3000'
  if (process.env.REACT_APP_API_SERVER === 'staging') {
    url = 'https://staging.movewithfleet.com'
  } else if (process.env.NODE_ENV === 'production') {
    url = 'https://app.movewithfleet.com'
  }

  copyToClipboard(
    `${url}/employee-sign-up?name=${worksiteAddress
      .split(',')[0]
      .replaceAll(' ', '%')}&id=${inviteCode}`
  )
}

const getPlatformType = (path: string) => {
  const pathComponents = path?.split('/')
  if (!pathComponents) {
    return null
  }
  if (pathComponents.length < 2) {
    return 'employer'
  }

  return pathComponents[1] === 'employee' ? 'employee' : 'employer'
}

const specialFinchNameComponents = {
  aap: 'AAP',
  adp: 'ADP',
  aps: 'APS',
  aspenhr: 'AspenHR',
  basic: 'BASIC',
  bbcs: 'BBCS',
  bbsi: 'BBSI',
  bcn: 'BCN',
  clickup: 'ClickUp',
  coadvantage: 'CoAdvantage',
  cpm: 'CPM',
  csc: 'CSV',
  dm: 'DM',
  gna: 'G&A',
  gtm: 'GTM',
  hcm: 'HCM',
  hcs: 'HCS',
  hr: 'HR',
  infiniti: 'INFINITI',
  mpay: 'MPAY',
  paynw: 'PayNorthwest',
  payrollontime: 'Payroll On Time',
  pcs: 'PCS',
  peo: 'PEO',
  quality_payroll_benefits: 'Quality Payroll and Benefits',
  quickbooks: 'QuickBooks',
  rmi: 'RMI',
  sap: 'SAP',
  trinet: 'TriNet PEO',
  ukg: 'UKG',
  usa: 'USA',
}

const formatFinchProviderName = (providerName: string) => {
  return providerName
    .split('_')
    .map((word) => specialFinchNameComponents[word] || capitalize(word))
    .join(' ')
}

export const screenSizes = {
  mobile: 576,
  tablet: 768,
}

export default {
  copyToClipboard,
  pluralCheck,
  capitalize,
  title,
  arrayToString,
  copyInviteCode,
  getPlatformType,
  formatFinchProviderName,
}
