import React from 'react'
import styled from 'styled-components'
import { buildComponent } from '../utils/buildComponent'
import { variant, space, SpaceProps, border, BorderProps } from 'styled-system'

interface DividerVariants {
  variant: string
  size: string | number
}

const Divider = styled.div<DividerVariants & SpaceProps & BorderProps>`
  background-color: ${(props) => props.theme.palette.grey.grey3};

  ${(props) =>
    variant({
      variants: {
        vertical: {
          height: props.size,
          width: '0.0625rem',
        },
        horizontal: {
          width: props.size,
          height: '0.0625rem',
        },
      },
    })}

  ${space}
  ${border}
`

interface DividerProps extends SpaceProps, BorderProps {
  direction?: 'vertical' | 'horizontal'
  size?: string
}

export default buildComponent((props: DividerProps) => {
  const { size = '100%', direction = 'horizontal' } = props
  return <Divider size={size} variant={direction} {...props} />
}, 'Divider')
