import React from 'react'
import { Marker } from 'react-map-gl'
import { FeatureCollection, Feature, Point } from 'geojson'

interface MapSvg {
  features: FeatureCollection
  svg: React.ReactNode
  onSvgClick: (feature: Feature) => void
}

const MapSvg = React.memo((props: MapSvg) => {
  const { features, svg, onSvgClick } = props

  return (
    <>
      {features.features.map((feature: Feature, index: number) => {
        const point = feature.geometry as Point

        return point ? (
          <Marker
            key={`marker-${index}`}
            latitude={point.coordinates[1]}
            longitude={point.coordinates[0]}
          >
            <div onClick={() => onSvgClick(feature)}>{svg}</div>
          </Marker>
        ) : null
      })}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MapSvg.displayName = 'MapSvg'
}

export default MapSvg
