import React, { useEffect } from 'react'
import Loading from 'core-system/Loading'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AppState } from 'redux/config/store'
import { GrhProgram } from 'redux/grh/grhTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import { grhActions } from 'redux/grh/grhSlice'
import GRHOverviewHeader from './components/GRHOverviewHeader'
import styled from 'styled-components'
import * as Program from 'core-system/Program/Overview'
import moment from 'moment'
import GRHOptions from './components/GRHOptions'
import GRHReasons from './components/GRHReasons'
import GRHReasonsBreakdownCard from './components/GRHReasonsBreakdownCard'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1rem;
  margin-top: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
  }
`

const LargeBodyContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`

const SmallBodyContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  height: fit-content;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`

interface GRHOverviewViewProps {
  activeProgram: GrhProgram
}

const GRHOverviewView = React.memo((props: GRHOverviewViewProps) => {
  const { activeProgram } = props
  const dispatch = useDispatch()
  const segmentId = useQueryParam('segmentId')

  const { dashboardStats, serviceUsage, usageHistory } = useSelector(
    (state: AppState) => state.grh
  )
  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  useEffect(() => {
    dispatch(
      grhActions.getGrhDashboardStats({
        segmentId,
        type: 'GRH',
      })
    )
    dispatch(
      grhActions.getGrhRecommendations({
        segmentId,
      })
    )
    // range = cycle start + today
    dispatch(
      grhActions.getGrhDashboardServices({
        segmentId,
        type: 'GRH',
        rangeStart: activeProgram.cycleStart,
        rangeEnd: moment().utc().format(),
      })
    )
    dispatch(grhActions.getGrhUsageHistory({ segmentId }))
  }, [segmentId, dispatch, activeProgram])

  if (
    !activeProgram ||
    !dashboardStats ||
    !serviceUsage ||
    !usageHistory ||
    !segmentsMap
  ) {
    return <Loading fullPage />
  }

  const totalEmployees = segmentsMap[segmentId].commuters
  const segmentName = segmentsMap[segmentId].name
  const monthsRemaining = moment(activeProgram.cycleEnd).diff(
    moment(),
    'months'
  )
  return (
    <>
      <GRHOverviewHeader
        stats={dashboardStats}
        transactionLimit={activeProgram.budget}
        transactionsPerEmployee={activeProgram.numUses}
        numEmployees={totalEmployees}
        monthsRemaining={monthsRemaining}
      />
      <GridContainer>
        <div>
          <LargeBodyContainer>
            <Program.ProgramOverviewServices
              type='GRH'
              serviceUsageData={serviceUsage}
            />
            <GRHOptions
              numUses={activeProgram.numUses}
              transactionLimit={activeProgram.budget}
              rideshareActive={true}
              taxiActive={true}
            />
          </LargeBodyContainer>
          <GRHReasons usageHistory={usageHistory} segmentName={segmentName} />
        </div>
        <SmallBodyContainer>
          <GRHReasonsBreakdownCard usageHistory={usageHistory} />
        </SmallBodyContainer>
      </GridContainer>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHOverviewView.displayName = 'GRHOverviewView'
}

export default GRHOverviewView
