import React, { useCallback, useEffect, useRef, useState } from 'react'
import MapIntelligenceService from 'redux/mapIntelligence/mapIntelligenceService'
import styled, { css } from 'styled-components'
import { SelectItem } from 'core-system/Dropdown'
import TextField from 'core-system/TextField'
import HomeIcon from 'core-system/Icons/Segments/Home'
import WorksiteIcon from 'core-system/Icons/Accounts/Worksite'

interface AutoCompleteResults {
  type: string
  query: string[]
  features: any[]
  attribution: string
}

export interface AutocompleteResult {
  address: string
  coordinates: number[]
  postcode: string | null
  type?: 'HOME' | 'WORK'
}

const Container = styled.div`
  width: 100%;
  position: relative;
`

const AddressInputField = styled(TextField)<{ isOpen: boolean }>`
  text-align: start;

  ${(props) =>
    props.isOpen &&
    css`
      border-radius: 0.5rem 0.5rem 0 0;
    `};
`

const AddressSuggestion = styled(SelectItem)`
  text-align: start;
`

const AddressSuggestionsContainer = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.dropdowns};
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  padding: 0.5rem 0;
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: ${(props) => props.theme.palette.white};
  width: 100%;
  top: ${(props) => props.theme.pxToRem(64)};
  border: 1px solid ${(props) => props.theme.palette.text.secondary};
`

const formatSuggestions = (results: AutoCompleteResults) => {
  return results.features.map((location) => {
    const postcode = location.context.find((context) =>
      context.id.includes('postcode')
    )?.text
    return {
      address: location.place_name_en,
      coordinates: location.center,
      postcode: postcode || '',
    }
  })
}

const getDefaultSuggestionIcon = (type: string) => {
  if (type === 'HOME') {
    return <HomeIcon />
  } else if (type === 'WORK') {
    return <WorksiteIcon />
  } else {
    return null
  }
}

interface AddressAutocompleteFieldProps {
  label: string
  displayValue: string
  setDisplayValue: (address: string) => void
  onSuggestionSelect: (location: AutocompleteResult) => void
  hasError?: boolean
  customPlaceholder?: string
  customHelpText?: string
  defaultSuggestions?: {
    type: 'HOME' | 'WORK'
    address: string
  }[]
}

const AddressAutocompleteField = React.memo(
  (props: AddressAutocompleteFieldProps) => {
    const {
      label,
      displayValue,
      setDisplayValue,
      onSuggestionSelect,
      hasError,
      customPlaceholder,
      customHelpText,
      defaultSuggestions,
    } = props

    const [suggestions, setSuggestions] = useState(null)
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [defaultSelectedType, setDefaultSelectedType] = useState<
      'HOME' | 'WORK' | null
    >(null)

    // Set the default suggestion results to only their address and type and only generate
    // the full data on user request
    const defaultSuggestionsResults: AutocompleteResult[] | null =
      defaultSuggestions && defaultSuggestions.length > 0
        ? defaultSuggestions.map((suggestion) => {
            return {
              ...suggestion,
              coordinates: [],
              postcode: null,
            }
          })
        : null

    const containerRef = useRef(null)

    const clickOutside = useCallback(
      (e: MouseEvent) => {
        if (
          containerRef &&
          containerRef.current &&
          containerRef.current.contains(e.target)
        ) {
          return
        }
        setShowSuggestions(false)
      },
      [containerRef]
    )

    useEffect(() => {
      document.addEventListener('mousedown', clickOutside, false)
      return () => {
        document.removeEventListener('mousedown', clickOutside, false)
      }
    }, [clickOutside])

    const handleSuggestionClick = (suggestion: AutocompleteResult) => {
      if (suggestion.type) {
        setDisplayValue(`${suggestion.type} (${suggestion.address})`)
      } else {
        setDisplayValue(suggestion.address)
      }
      setShowSuggestions(false)

      // If the user has selected a default suggestion, we want to get the full data for it
      if (suggestion.type) {
        setDefaultSelectedType(suggestion.type)
      } else {
        onSuggestionSelect(suggestion)
      }
    }

    // When the user selects either one of the default options, set the full data for it
    useEffect(() => {
      if (defaultSelectedType) {
        MapIntelligenceService.getAddressAutocomplete(
          displayValue.split('(')[1].split(')')[0]
        )
          .then((res) => {
            const locationData = res.data.features[0]
            const postcode =
              locationData.context.find((context) =>
                context.id.includes('postcode')
              )?.text || ''
            onSuggestionSelect({
              address: locationData.place_name_en,
              coordinates: locationData.center,
              postcode: postcode,
              type: defaultSelectedType,
            })
            setDefaultSelectedType(null)
          })
          .catch((err) => console.log(err))
      }
    }, [
      defaultSelectedType,
      setDefaultSelectedType,
      displayValue,
      onSuggestionSelect,
    ])

    const handleOnInputClick = () => {
      if ((!showSuggestions && suggestions) || defaultSuggestionsResults) {
        setShowSuggestions(true)
      }
    }

    const renderSuggestions = (suggestions: AutocompleteResult[]) => {
      const allSuggestions =
        defaultSuggestionsResults && suggestions
          ? defaultSuggestionsResults.concat(suggestions)
          : defaultSuggestions || suggestions
      return allSuggestions.map((suggestion, idx) => {
        return (
          <AddressSuggestion
            active={true}
            key={idx}
            onClick={() => handleSuggestionClick(suggestion)}
            icon={
              suggestion.type ? getDefaultSuggestionIcon(suggestion.type) : null
            }
          >
            {suggestion.type
              ? `${suggestion.type} (${suggestion.address})`
              : suggestion.address}
          </AddressSuggestion>
        )
      })
    }

    const handleOnAddressInputChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const addressInput = e.currentTarget.value

      setDisplayValue(addressInput)

      if (addressInput.length <= 4 && suggestions) {
        setShowSuggestions(false)
        setSuggestions(null)
      } else if (addressInput.length > 4) {
        MapIntelligenceService.getAddressAutocomplete(addressInput)
          .then((res) => {
            setSuggestions(formatSuggestions(res.data))
            setShowSuggestions(true)
          })
          .catch((err) => console.log(err))
      }

      if (addressInput.trim().length === 0) {
        onSuggestionSelect({ address: '', coordinates: [], postcode: '' })
      }
    }

    return (
      <Container ref={containerRef}>
        <AddressInputField
          label={label}
          onChange={(e) => handleOnAddressInputChange(e)}
          onClick={handleOnInputClick}
          value={displayValue}
          autoComplete='false'
          type='text'
          name='hidden'
          role='presentation'
          placeholder={
            customPlaceholder ? customPlaceholder : 'Street Address *'
          }
          isOpen={showSuggestions}
          invalid={hasError}
          helpText={
            customHelpText ? customHelpText : 'Please select a full address'
          }
        />
        {showSuggestions && (
          <AddressSuggestionsContainer>
            {renderSuggestions(suggestions)}
          </AddressSuggestionsContainer>
        )}
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AddressAutocompleteField.displayName = 'AddressAutocompleteField'
}

export default AddressAutocompleteField
