import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'
import Checkbox from 'core-system/Checkbox'

const Container = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & { disabled: boolean }
>`
  ${(props) => props.theme.typography.action4}
  padding: 0.625rem 1rem;
  width: 100%;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => props.color};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};

  ${flexbox}
  ${space}
  ${layout}
`

interface MultiSelectItemProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  active: boolean
  disabled?: boolean
  label: string
}

const MultiSelectItem = React.memo((props: MultiSelectItemProps) => {
  const { active, disabled = false, label } = props

  return (
    <Container disabled={disabled} {...props}>
      <Checkbox
        checked={active}
        label={label}
        titleCase
        textVariant='action4'
      />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MultiSelectItem.displayName = 'MultiSelectItem'
}

export default MultiSelectItem
