const queryRegex = /.*?\?/
function getQueryParams(queryString: string | null): Dictionary {
  if (!queryString) {
    return {}
  }

  const qs = queryString.replace(queryRegex, '')

  if (qs.length === 0) {
    return {}
  }

  const keyValPairs = qs.split('&')

  const params: Dictionary = {}

  keyValPairs.forEach((pair) => {
    const data = pair.split('=')

    const key = data[0]
    const value = data[1] || ''

    if (key.length > 0) {
      params[key] = decodeURIComponent(value)
    }
  })

  return params
}

export default {
  getQueryParams,
}
