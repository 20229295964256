import Button from 'core-system/Button'
import Checkbox from 'core-system/Checkbox'
import Divider from 'core-system/Divider'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import LeaderboardUtils from 'features/Leaderboards/LeaderboardUtils'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import { HandleFiltersChangeParams } from './MyCommutersSearch'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const CheckBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.theme.pxToRem(187)}, 1fr)
  );
  grid-gap: 1rem;
  margin-top: 1rem;
`

interface MyCommutersFilterModal {
  open: boolean
  closeModal: () => void
  programFilters: string[]
  competitionFilters: string[]
  worksiteFilters: string[]
  handleFiltersChange: (params: HandleFiltersChangeParams) => void
  searchVal: string
  isPending: boolean
}

const MyCommutersFilterModal = React.memo((props: MyCommutersFilterModal) => {
  const {
    open,
    closeModal,
    programFilters,
    competitionFilters,
    worksiteFilters,
    handleFiltersChange,
    searchVal,
    isPending,
  } = props

  const { worksites } = useSelector((state: AppState) => state.employer)

  const [internalProgramFilters, setInternalProgramFilters] =
    useState(programFilters)
  const [internalCompetitionFilters, setInternalCompetitionFilters] =
    useState(competitionFilters)

  const [internalWorksiteFilters, setInternalWorksiteFilters] =
    useState(worksiteFilters)

  const handleFiltersReset = useCallback(() => {
    setInternalProgramFilters([])
    setInternalCompetitionFilters([])
    setInternalWorksiteFilters([])
    SegmentService.track('commuters-group-filter', {
      action: 'clear-all',
    })
  }, [])

  const handleCheckboxFilterChange = (
    type: string,
    value: string | number,
    values: string[] | number[]
  ) => {
    const arrCopy = values ? [...values] : []
    const idx = arrCopy.indexOf(value)
    if (idx === -1) {
      arrCopy.push(value)
    } else {
      arrCopy.splice(idx, 1)
    }

    if (type === 'program') {
      setInternalProgramFilters(arrCopy as string[])
    } else if (type === 'competition') {
      setInternalCompetitionFilters(arrCopy as string[])
    } else {
      setInternalWorksiteFilters(arrCopy as string[])
    }
  }

  return (
    <Modal open={open} onClose={() => closeModal()} width={pxToRem(752)}>
      <ModalHeader title='Filters' />
      <ModalBody padding='1.5rem 3.25rem'>
        {!isPending ? (
          <>
            <Text variant='action2'>Programs Enrolled</Text>
            <CheckBoxes>
              {['MICROMOBILITY', 'FLEX'].map((programType) => {
                return (
                  <Checkbox
                    key={programType}
                    checked={
                      internalProgramFilters &&
                      internalProgramFilters.includes(programType)
                    }
                    onClick={() =>
                      handleCheckboxFilterChange(
                        'program',
                        programType,
                        internalProgramFilters
                      )
                    }
                    label={RandomUtils.capitalize(programType)}
                    marginRight='1rem'
                  />
                )
              })}
            </CheckBoxes>
            <Divider margin='1rem 0' />
            <Text variant='action2' marginTop='1rem'>
              Competitions Enrolled
            </Text>
            <CheckBoxes>
              {LeaderboardUtils.validLeaderboardTypes.map((leaderboardType) => {
                return (
                  <Checkbox
                    key={leaderboardType}
                    checked={
                      internalCompetitionFilters &&
                      internalCompetitionFilters.includes(leaderboardType)
                    }
                    onClick={() =>
                      handleCheckboxFilterChange(
                        'competition',
                        leaderboardType,
                        internalCompetitionFilters
                      )
                    }
                    label={RandomUtils.capitalize(leaderboardType)}
                    marginRight='1rem'
                  />
                )
              })}
            </CheckBoxes>
          </>
        ) : (
          <>
            <Text variant='action2'>Worksites</Text>
            <CheckBoxes>
              {worksites.map((worksite) => {
                return (
                  <Checkbox
                    key={worksite.id}
                    checked={
                      internalWorksiteFilters &&
                      internalWorksiteFilters.includes(worksite.id)
                    }
                    onClick={() =>
                      handleCheckboxFilterChange(
                        'worksite',
                        worksite.id,
                        internalWorksiteFilters
                      )
                    }
                    label={worksite.alias}
                    marginRight='1rem'
                  />
                )
              })}
            </CheckBoxes>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonContainer>
          <Button variant='tertiary' onClick={handleFiltersReset}>
            Clear All
          </Button>
          <Button
            onClick={() => {
              handleFiltersChange({
                newProgramFilters: internalProgramFilters,
                newCompetitionFilters: internalCompetitionFilters,
                newWorksiteFilters: internalWorksiteFilters,
                searchVal: searchVal,
              })
              SegmentService.track('commuters-group-filter', {
                action: 'apply',
                programFilter: internalProgramFilters,
                competitionFilter: internalCompetitionFilters,
              })
            }}
          >
            Apply Filters
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommutersFilterModal.displayName = 'MyCommutersFilterModal'
}

export default MyCommutersFilterModal
