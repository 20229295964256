import Loading from 'core-system/Loading'
import * as Program from 'core-system/Program/Overview'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { flexActions } from 'redux/flex/flexSlice'
import { FlexState } from 'redux/flex/flexSlice'
import { leaderboardsActions } from 'redux/leaderboards/leaderboardsSlice'
import { mapIntelligenceActions } from 'redux/mapIntelligence/mapIntelligenceSlice'
import { CardProgram } from 'redux/programs/programsTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import MerchantUtils from 'shared/MerchantUtils'
import styled from 'styled-components'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1rem;
  margin-top: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
  }
`

const LargeBodyContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  height: ${(props) => props.theme.pxToRem(464)};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`

const SmallBodyContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  height: fit-content;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`

const getActiveOptions = (
  activeProgram: CardProgram,
  availableMerchants: FlexState['availableMerchants']
) => {
  return {
    flex:
      (activeProgram.merchants.length > 0 && !activeProgram.transit) ||
      (activeProgram.merchants.length > availableMerchants.transit.length &&
        activeProgram.transit),
    transit: activeProgram.transit,
    gas: activeProgram.gas,
    parking: activeProgram.parking,
  }
}

interface FlexOverviewViewProps {
  activeProgram: CardProgram
}

const FlexOverviewView = React.memo((props: FlexOverviewViewProps) => {
  const { activeProgram } = props

  const dispatch = useDispatch()
  const segmentId = useQueryParam('segmentId')

  const availableMerchants = useSelector(
    (state: AppState) => state.flex.availableMerchants
  )
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )
  const { dashboardStats, serviceUsage } = useSelector(
    (state: AppState) => state.flex
  )
  const { recentTripOrigins, tripDetails } = useSelector(
    (state: AppState) => state.mapIntelligence.flex
  )
  const { leaderboards } = useSelector((state: AppState) => state.leaderboards)

  useEffect(() => {
    dispatch(
      flexActions.getFlexDashboardStats({
        segmentId,
        type: 'FLEX',
      })
    )
    dispatch(
      flexActions.getFlexDashboardServices({
        segmentId,
        type: 'FLEX',
        rangeStart: null,
        rangeEnd: null,
      })
    )
    dispatch(
      mapIntelligenceActions.getRecentTripOrigins({
        segment: segmentId,
        modeTypes: MerchantUtils.relevantFlexModeTypes,
        type: 'FLEX',
      })
    )
    dispatch(
      flexActions.getFlexRecommendations({
        segmentId,
      })
    )
    dispatch(mapIntelligenceActions.getBaseCommuters({ segmentId }))
    if (leaderboards.length > 0) {
      const activeOverViewLeaderboard = leaderboards.find(
        (leaderboard) =>
          leaderboard.type === 'ENV' && leaderboard.segment === segmentId
      )
      if (activeOverViewLeaderboard) {
        dispatch(
          leaderboardsActions.getLeaderboardRankings({
            familyId: activeOverViewLeaderboard.familyId,
            queryType: 'ENV',
          })
        )
      }
    }
  }, [segmentId, leaderboards, dispatch])

  if (!activeProgram || !availableMerchants || !merchantsMap) {
    return <Loading fullPage />
  }

  return (
    <div>
      <Program.ProgramOverviewHeader
        type='FLEX'
        dashboardStats={dashboardStats}
        segmentId={segmentId}
      />
      <GridContainer>
        <div>
          <LargeBodyContainer>
            <Program.ProgramOverviewServices
              type='FLEX'
              modeTypeFilters
              serviceUsageData={serviceUsage}
            />
            <Program.ProgramOverviewOptions
              activeOptions={getActiveOptions(
                activeProgram,
                availableMerchants
              )}
              budget={activeProgram.budget}
            />
          </LargeBodyContainer>
          <Program.ProgramOverviewMap
            type='FLEX'
            recentTrips={recentTripOrigins}
            tripDetails={tripDetails}
          />
        </div>
        <SmallBodyContainer>
          <Program.ProgramOverviewIncentives
            type='FLEX'
            segmentId={segmentId}
          />
          <Program.ProgramOverviewLeaderboard
            type='FLEX'
            segmentId={segmentId}
          />
        </SmallBodyContainer>
      </GridContainer>
    </div>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FlexOverviewView.displayName = 'FlexOverviewView'
}

export default FlexOverviewView
