import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M10 14.0001H10.74L8.82 5.56011C8.61 4.65011 7.8 4.00011 6.87 4.00011H3V6.00011H6.87L8.29 12.2501H8.28C6.12 12.9001 4.47 14.7301 4.09 17.0001H0V19.0001H6V18.0001C6 15.7901 7.79 14.0001 10 14.0001Z' />
    <path d='M19 8.00008H18.18L16.83 4.31008C16.55 3.52008 15.8 3.00008 14.96 3.00008H11V5.00008H14.96L16.06 8.00008H10.4L10.86 10.0001H15C14.57 10.5801 14.25 11.2501 14.1 12.0001H11.31L11.77 14.0001H14.1C14.54 16.2301 16.41 17.8801 18.75 17.9901C21.55 18.1201 24 15.8001 24 12.9901C24 10.2001 21.8 8.00008 19 8.00008ZM19 16.0001C17.32 16.0001 16 14.6801 16 13.0001C16 12.0701 16.41 11.2701 17.05 10.7201L18.01 13.3601L19.89 12.6801L18.92 10.0101C18.95 10.0101 18.98 10.0001 19.01 10.0001C20.69 10.0001 22.01 11.3201 22.01 13.0001C22.01 14.6801 20.68 16.0001 19 16.0001Z' />
    <path d='M10 15C8.34 15 7 16.34 7 18C7 19.66 8.34 21 10 21C11.66 21 13 19.66 13 18C13 16.34 11.66 15 10 15ZM10 19C9.45 19 9 18.55 9 18C9 17.45 9.45 17 10 17C10.55 17 11 17.45 11 18C11 18.55 10.55 19 10 19Z' />
  </>,
  'Micromobility'
)
