import React, { Children, cloneElement } from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'
import { buildForwardingComponent } from '../utils/buildComponent'

const Container = styled.div`
  ${(props) => props.theme.typography.body1}
  display: flex;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.9375rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  overflow: hidden;
  width: 100%;
  margin-bottom: 0.5rem;
  flex-direction: column;

  ${flexbox}
  ${space}
  ${layout}
`

const buildAccordionStyle = (
  children: React.ReactNode,
  active: boolean,
  setIsActive: (isActive: boolean) => void,
  isDisabled: boolean
) => {
  return Children.toArray(children).map((ele: any, idx: number) => {
    return cloneElement(ele, {
      hasDropdown: idx === 0 && true,
      setIsActive: idx === 0 && !isDisabled && setIsActive,
      isChild: idx !== 0 && true,
      isAnimate: idx !== 0 && active && true,
      isActive: active,
    })
  })
}

export interface AccordionCardProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  setIsOpen: (open: boolean) => void
  isOpen: boolean
  isDisabled?: boolean
}

const AccordionCard = React.forwardRef<HTMLDivElement, AccordionCardProps>(
  (props: AccordionCardProps, ref: React.Ref<HTMLDivElement>) => {
    const { children, setIsOpen, isOpen, isDisabled = false } = props
    const childrenCount = Children.count(children)

    return (
      <Container ref={ref} {...props}>
        {childrenCount === 1
          ? children
          : buildAccordionStyle(children, isOpen, setIsOpen, isDisabled)}
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AccordionCard.displayName = 'AccordionCard'
}

export default buildForwardingComponent(AccordionCard)
