import React, { useState } from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Dropdown from 'core-system/Dropdown'
import palette from 'core-system/Themes/palette'
import MonthRangePicker from 'core-system/MonthRangePicker'
import { YearMonth } from 'core-system/MonthRangePicker/DropdownCalendar'
import Button from 'core-system/Button'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { useDispatch } from 'react-redux'
import { qtfActions } from 'redux/qtf/qtfSlice'

const nextMonth = moment().add(1, 'month').format('MMMM YYYY')
const dropdownItems = [
  {
    id: 'usage',
    text: 'Usage Report',
  },
  {
    id: 'allocations',
    text: `${nextMonth} Allocation Report`,
  },
]

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  position: relative;
  width: 100%;
`

const TitleContainer = styled.div`
  margin-bottom: 1rem;
  justify-content: space-between;
`

const SvgWrapper = styled.div`
  text-align: center;
  padding-bottom: 1rem;
  @media (min-width: ${(props) =>
      props.theme.breakpoints[2]}) and (max-width: 87.5rem) {
    display: none;
  }
`

const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  margin-bottom: 0.5rem;
`

const getIconDimensions = (size: 'sm' | 'lg') => {
  if (size === 'sm')
    return {
      width: 96,
      height: 75,
    }
  return {
    width: 115,
    height: 90,
  }
}

const getMoment = (yearString: string, monthNo: number) => {
  return moment(
    yearString +
      monthNo.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
    'YYYYMM'
  )
}

export interface DropdownItem {
  id: string
  text: string
  active?: boolean
}

export interface QTFReportingCardProps {
  segmentId: string
  size?: 'sm' | 'lg'
  isReportGenerating?: boolean
}

const QTFReportingCard = React.memo((props: QTFReportingCardProps) => {
  const { size = 'lg', isReportGenerating, segmentId } = props
  const dispatch = useDispatch()

  const { width, height } = getIconDimensions(size)

  const currentSegmentName = useSelector(
    (state: AppState) => state.employer.segmentsMap[segmentId].name
  )

  const [selectedReportType, setSelectedReportType] = useState<string>(
    dropdownItems[0].id
  )
  const [selectedMonthsError, setSelectedMonthsError] = useState(false)
  const [selectedStartDate, setSelectedStartDate] = useState<YearMonth>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<YearMonth>(null)

  const GenerateButton = React.memo(() => (
    <Button
      size='small'
      disabled={isReportGenerating}
      width='100%'
      onClick={() => {
        if (selectedReportType === 'allocations') {
          dispatch(
            qtfActions.getQTFDashboardDeductions({
              segmentId,
              format: 'csv',
              segmentName: currentSegmentName,
            })
          )
        } else if (!selectedStartDate) {
          setSelectedMonthsError(true)
        } else {
          const startMoment = getMoment(
            selectedStartDate.year,
            selectedStartDate.month
          )
            .startOf('month')
            .format()
          const endMoment = selectedEndDate
            ? getMoment(selectedEndDate.year, selectedEndDate.month)
                .add(1, 'month')
                .format()
            : getMoment(selectedStartDate.year, selectedStartDate.month)
                .add(1, 'month')
                .format()
          dispatch(
            qtfActions.getQTFDashboardCsvReports({
              startDate: startMoment,
              endDate: endMoment,
              segmentId: segmentId,
              reportType: selectedReportType,
              segmentName: currentSegmentName,
            })
          )
        }
      }}
    >
      {isReportGenerating ? 'Generating...' : 'Generate Report'}
    </Button>
  ))
  GenerateButton.displayName = 'GenerateButton'

  return (
    <Container>
      <TitleContainer>
        <Text variant='h5'>Reports</Text>
      </TitleContainer>
      <SvgWrapper>
        <PlatformSvg
          folder='dashboard'
          variant='dashboardReport'
          width={width}
          height={height}
        />
      </SvgWrapper>
      <Dropdown
        items={dropdownItems}
        active={selectedReportType}
        itemCallback={({ id }) => setSelectedReportType(id)}
        width='100%'
        label='Report Type'
        marginBottom='0.5rem'
      />
      {selectedReportType !== 'allocations' && (
        <FormFieldContainer>
          <Text variant='body1' textColor={palette.text.secondary}>
            Time Period
          </Text>
          <MonthRangePicker
            error={selectedMonthsError}
            errorMessage='Select a Valid Date Range'
            errorSetter={setSelectedMonthsError}
            placeholder='--'
            selectedStartDate={selectedStartDate}
            startDateSetter={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            endDateSetter={setSelectedEndDate}
          />
        </FormFieldContainer>
      )}
      <FormFieldContainer style={{ padding: '0.625rem 0' }}>
        <Text variant='body1' textColor={palette.text.secondary}>
          Group
        </Text>
        <Text variant='action4'>{currentSegmentName}</Text>
      </FormFieldContainer>
      <GenerateButton />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFReportingCard.displayName = 'QTFReportingCard'
}

export default QTFReportingCard
