import AnnouncementsIcon from 'core-system/Icons/Concierge/Announcements'
import FaqIcon from 'core-system/Icons/Concierge/Faq'
import SurveysIcon from 'core-system/Icons/Concierge/Survey'
import palette from 'core-system/Themes/palette'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Announcement, Faqs } from 'redux/concierge/conciergeTypes'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import styled, { css } from 'styled-components'
import { AppState } from 'redux/config/store'
import SearchView from './SearchAi/SearchView'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 4rem;
  margin: 0 auto;
  background-color: ${(props) => props.theme.palette.white};
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: 2.5rem 1.5rem;
  }
`

const HeaderContent = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  display: flex;
  flex-direction: column;
  max-width: 75rem;
  width: 100%;
`

const HeaderBanner = styled.div`
  display: flex;
  margin-bottom: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.pxToRem(21)};
  }
`

const HeaderTitle = styled.div`
  padding-left: 6rem;
  min-height: ${(props) => props.theme.pxToRem(113)};
  position: relative;
  display: flex;
  align-items: flex-end;
`

const BannerIconContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${(props) => props.theme.pxToRem(113)};
`

const BannerTitle = styled.div`
  ${(props) => props.theme.typography.h4}
  width: ${(props) => props.theme.pxToRem(187)};
`

const BannerSubtitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
`

const BannerSubtitle = styled.div`
  ${(props) => props.theme.typography.body1}
  max-width: ${(props) => props.theme.pxToRem(400)};
  min-height: ${(props) => props.theme.pxToRem(50)};
  display: flex;
  align-items: center;
  padding-left: 2rem;
  border-left: 1px solid ${(props) => props.theme.palette.grey.grey4};
  margin-left: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-left: 0;
    border-left: unset;
    margin-left: 0;
    margin-top: ${(props) => props.theme.pxToRem(21)};
  }
`

const HeaderButtons = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-gap: 0.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 1rem;
  }
`

const HeaderButton = styled.div<{ active: boolean; disabled: boolean }>`
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: ${(props) =>
    props.active
      ? props.theme.dropShadows.selected
      : props.theme.dropShadows.normal};
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.palette.secondary.purple2
        : props.theme.palette.grey.grey3};
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.secondary.purple6
      : props.theme.palette.white};
  border-radius: 0.9375rem;

  &:hover {
    box-shadow: ${(props) =>
      !props.disabled && props.theme.dropShadows.selected};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.8;
      background-color: #efefef;
      box-shadow: none;
      cursor: default;
    `}

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: 1rem 0.75rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 1rem;
  }
`

const HeaderIconContainer = styled.div<{
  active: boolean
  backgroundColor: string
}>`
  min-width: 3.25rem;
  height: 3.25rem;
  border-radius: 0.5rem;
  background-color: ${(props) =>
    props.active ? props.theme.palette.chips.purple2 : props.backgroundColor};
  padding: 0.375rem;
`

const HeaderTextContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-left: 0.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-left: 1rem;
  }
`

const Title = styled.div`
  ${(props) => props.theme.typography.action1}
`

const Subtitle = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.secondary};
`

const headerCopyMap = {
  1: 'Connect with your commuters',
  2: 'Ask your commuters questions',
  3: 'Inform your commuters',
}

interface ConciergeHeaderProps {
  activeId: number
  handleClick: (id: number) => void
  allFaqs: Faqs
}

const ConciergeHeader = React.memo((props: ConciergeHeaderProps) => {
  const [showSearch, setShowSearch] = useState(false)
  const { activeId, handleClick, allFaqs } = props
  const allAnnouncements = useSelector(
    (state: AppState) => state.concierge.announcements
  )
  const numAnnouncements =
    allAnnouncements &&
    allAnnouncements.filter(
      (ann: Announcement) =>
        (ann.userType !== 'HR_USER' && ann.status === 'Scheduled') ||
        (ann.userType !== 'HR_USER' &&
          ann.status === 'Persistent' &&
          !ann.sentAt)
    ).length

  return (
    <Container>
      <HeaderContent>
        <HeaderBanner>
          <HeaderTitle>
            <BannerIconContainer>
              <img
                alt={'fleetBot'}
                src={`${StaticAssetsUrl}/concierge/header.png`}
              />
            </BannerIconContainer>
            <BannerTitle>{headerCopyMap[activeId]}</BannerTitle>
          </HeaderTitle>
          <BannerSubtitleContainer>
            <BannerSubtitle>
              A one stop shop to inform, educate, and gather commuter
              preferences to improve mobility programs.
            </BannerSubtitle>
          </BannerSubtitleContainer>
        </HeaderBanner>
        <HeaderButtons>
          <HeaderButton
            active={activeId === 1}
            disabled={false}
            onClick={() => handleClick(1)}
          >
            <HeaderIconContainer
              active={activeId === 1}
              backgroundColor={palette.chips.teal2}
            >
              <AnnouncementsIcon
                width={40}
                height={40}
                color={
                  activeId === 1 ? palette.chips.purple1 : palette.chips.teal1
                }
              />
            </HeaderIconContainer>
            <HeaderTextContainer>
              <Title>Announcements</Title>
              <Subtitle>
                {allAnnouncements ? numAnnouncements : 0} Announcements
                Scheduled
              </Subtitle>
            </HeaderTextContainer>
          </HeaderButton>
          <HeaderButton active={false} disabled={true} onClick={null}>
            <HeaderIconContainer
              active={false}
              backgroundColor={palette.chips.magenta2}
            >
              <SurveysIcon
                width={40}
                height={40}
                color={
                  activeId === 2
                    ? palette.chips.purple1
                    : palette.chips.magenta1
                }
              />
            </HeaderIconContainer>
            <HeaderTextContainer>
              <Title>Surveys</Title>
              <Subtitle>Coming Soon</Subtitle>
              {/* <Subtitle>0 Surveys Scheduled</Subtitle> */}
            </HeaderTextContainer>
          </HeaderButton>
          <HeaderButton
            id='concierge-header-faq'
            active={activeId === 3}
            disabled={false}
            onClick={() => handleClick(3)}
          >
            <HeaderIconContainer
              active={activeId === 3}
              backgroundColor={palette.chips.blue2}
            >
              <FaqIcon
                width={40}
                height={40}
                color={
                  activeId === 3 ? palette.chips.purple1 : palette.chips.blue1
                }
              />
            </HeaderIconContainer>
            <HeaderTextContainer>
              <Title>FAQs</Title>
              <Subtitle>
                {allFaqs && allFaqs.faq ? allFaqs.faq.length : 0} Published
                Q&amp;As
              </Subtitle>
            </HeaderTextContainer>
          </HeaderButton>
          <HeaderButton
            active={false}
            disabled={false}
            onClick={() => setShowSearch(true)}
          >
            <HeaderIconContainer
              active={true}
              backgroundColor={palette.chips.magenta2}
            >
              <SurveysIcon
                width={40}
                height={40}
                color={
                  activeId === 4
                    ? palette.chips.purple1
                    : palette.chips.magenta1
                }
              />
            </HeaderIconContainer>
            <HeaderTextContainer>
              <Title>Fleet AI</Title>
              <Subtitle>Use AI to answer your questions</Subtitle>
            </HeaderTextContainer>
          </HeaderButton>
        </HeaderButtons>
      </HeaderContent>
      <SearchView open={showSearch} onClose={() => setShowSearch(false)} />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ConciergeHeader.displayName = 'ConciergeHeader'
}

export default ConciergeHeader
