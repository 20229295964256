import React from 'react'
import styled from 'styled-components'
import SidebarMenu from './SidebarMenu'

const SidebarContainer = styled.div`
  height: 100%;
  min-width: ${(props) => props.theme.pxToRem(80)};
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: none;
  }
`

//https://medium.com/@justicart/overflow-x-scroll-overflow-y-visible-c1a98238e002
//solves overflox-x: visible, overflow-y: scroll
const LabelContainer = styled.div`
  position: absolute;
  width: ${(props) => props.theme.pxToRem(300)};
  height: 100%;
  left: 0;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

interface SidebarViewProps {
  platformType: 'employee' | 'employer'
}

const SidebarView = React.memo((props: SidebarViewProps) => {
  const { platformType } = props;

  return (
    <SidebarContainer id='page-sidebar'>
      <LabelContainer>
        <SidebarMenu platformType={platformType} />
      </LabelContainer>
    </SidebarContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SidebarView.displayName = 'SidebarView'
}

export default SidebarView
