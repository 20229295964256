import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div<FlexboxProps & SpaceProps & LayoutProps>`

${flexbox}
${space}
${layout}
`

interface ColumnProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {}

const Column = React.memo((props: ColumnProps) => {
  const { children } = props
  return <Container {...props}>{children}</Container>
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  Column.displayName = 'Column'
}

export default Column
