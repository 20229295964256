import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Text from 'core-system/Text'
import Loading from 'core-system/Loading'
import palette from 'core-system/Themes/palette'
import Divider from 'core-system/Divider'
import {
  CommutePreferenceDropdownItems,
  convertToTitleCase,
} from 'employee-platform/shared/utils'
import TextField from 'core-system/TextField'
import AddressAutocompleteField from 'core-system/Map/AddressAutocomplete/AddressAutocompleteField'
import { EditableProfileFields } from '../ProfileSettingsView'
import Dropdown from 'core-system/Dropdown'

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.isMobile ? '0.75rem' : '1rem')};
`

const HeaderContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
    align-items: center;
    gap: ${(props) => (props.isMobile ? '0.5rem' : '1rem')}};
`

const HeaderTextContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
  gap: 0.25rem;
  width: 100%;
`

const HeaderBottomTextContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  gap: ${(props) => (props.isMobile ? '0.25rem' : '1rem')};
  width: 100%;
`

const InfoFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const homeAddressField = (
  streetAddress: string,
  unitNumber: string | null,
  editing: boolean,
  editableFields: EditableProfileFields,
  setEditableFields: (editableFields: EditableProfileFields) => void,
  setSelectedAddress: (address: string) => void,
  addressHasError: boolean,
  includeDivider?: boolean
) => {
  return (
    <>
      <InfoFieldContainer>
        <Text variant='action3' textColor={palette.text.secondary}>
          Home Address
        </Text>
        <InfoFieldContainer>
          <Text variant='body1' textColor={palette.text.placeholder}>
            Street Address
          </Text>
          {editing ? (
            <AddressAutocompleteField
              label={null}
              displayValue={streetAddress}
              setDisplayValue={(address) =>
                setEditableFields({
                  ...editableFields,
                  homeAddress: address,
                })
              }
              onSuggestionSelect={(location) => {
                setEditableFields({
                  ...editableFields,
                  homeAddress: location.address,
                  postcode: location.postcode,
                })
                setSelectedAddress(location.address)
              }}
              hasError={addressHasError}
              customPlaceholder='Street Address'
              customHelpText='Please select an address from the suggestions'
            />
          ) : (
            <Text variant='action2'>{streetAddress}</Text>
          )}
        </InfoFieldContainer>
        {unitNumber || editing ? (
          <InfoFieldContainer>
            <Text variant='body1' textColor={palette.text.placeholder}>
              Unit / Apt Number
            </Text>
            {editing ? (
              <TextField
                placeholder='Unit / Apt Number'
                name='unitNumber'
                type='text'
                value={unitNumber}
                onChange={(e) =>
                  setEditableFields({
                    ...editableFields,
                    unitNumber: e.target.value,
                  })
                }
                maxLength={20}
              />
            ) : (
              <Text variant='action2'>{unitNumber}</Text>
            )}
          </InfoFieldContainer>
        ) : null}
      </InfoFieldContainer>
      {includeDivider ? <Divider /> : null}
    </>
  )
}

const infoField = (
  label: string,
  value: string,
  includeDivider?: boolean,
  editableComponent?: React.ReactNode
) => {
  return (
    <>
      <InfoFieldContainer>
        <Text variant='action3' textColor={palette.text.secondary}>
          {label}
        </Text>
        {editableComponent ? (
          editableComponent
        ) : (
          <Text variant='action2'>{value}</Text>
        )}
      </InfoFieldContainer>
      {includeDivider ? <Divider /> : null}
    </>
  )
}

interface ProfileSettingsProps {
  isMobile: boolean
  editing: boolean
  editableFields: EditableProfileFields
  setEditableFields: (fields: EditableProfileFields) => void
  addressHasError: boolean
  setSelectedAddress: (address: string) => void
}

const ProfileSettings = React.memo((props: ProfileSettingsProps) => {
  const {
    isMobile,
    editing,
    editableFields,
    setEditableFields,
    addressHasError,
    setSelectedAddress,
  } = props

  const { profileData } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  if (!profileData) {
    return <Loading />
  }

  return (
    <Container isMobile={isMobile}>
      <HeaderContainer isMobile={isMobile}>
        {profileData?.avatar && profileData?.avatar !== '' ? (
          <img
            src={profileData.avatar}
            alt={`${profileData.firstName} ${profileData.lastName} Avatar`}
            style={{
              borderRadius: '50%',
            }}
            height={isMobile ? 80 : 50}
          />
        ) : (
          <PlatformSvg
            folder='users'
            variant='userBasic'
            width={isMobile ? 80 : 50}
            height={isMobile ? 80 : 50}
          />
        )}
        <HeaderTextContainer isMobile={isMobile}>
          <Text
            variant='h3'
            textAlign={isMobile ? 'center' : 'left'}
          >{`${profileData.firstName} ${profileData.lastName}`}</Text>
          <HeaderBottomTextContainer isMobile={isMobile}>
            {profileData.employeeCorpId ? (
              <>
                <Text
                  variant='action2'
                  textColor={palette.text.secondary}
                  minWidth='3.8rem'
                  hideOverflow
                >
                  {`ID: ${profileData.employeeCorpId}`}
                </Text>
                {!isMobile ? (
                  <Divider direction='vertical' size='1.25rem' />
                ) : null}
              </>
            ) : null}
            <Text
              variant='action2'
              textColor={palette.primary.primaryPurple}
              maxWidth={isMobile ? '100%' : '70%'}
              hideOverflow
            >
              {profileData.email}
            </Text>
          </HeaderBottomTextContainer>
        </HeaderTextContainer>
      </HeaderContainer>
      <Divider />
      {homeAddressField(
        editing ? editableFields.homeAddress : profileData.homeAddress,
        editing ? editableFields.unitNumber : profileData.unitNumber,
        editing,
        editableFields,
        setEditableFields,
        setSelectedAddress,
        addressHasError,
        true
      )}
      {infoField('Work Address', profileData.worksiteAddress, true)}
      {infoField(
        'Preferred Commute Mode',
        profileData?.currentTransport
          ? convertToTitleCase(profileData.currentTransport)
          : '',
        false,
        editing ? (
          <Dropdown
            items={CommutePreferenceDropdownItems}
            active={editableFields.commutePreference}
            itemCallback={(item) =>
              setEditableFields({
                ...editableFields,
                commutePreference: item.id,
              })
            }
            usePortal
            selectorMaxHeight='10rem'
          />
        ) : null
      )}
    </Container>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProfileSettings.displayName = 'ProfileSettings'
}

export default ProfileSettings
