import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import ProgramChange from 'core-system/Program/ProgramChange'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { FlexActiveOptions, FlexManageState } from 'redux/flex/flexTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
`

const getDescriptionText = (canEdit: boolean, isPending: boolean) => {
  if (isPending) {
    return 'Update this program to apply these changes.'
  } else {
    return canEdit
      ? 'Update this program to apply these changes to the following month.'
      : 'All upcoming changes will be applied to the program the following months.'
  }
}

interface FlexManagePendingProps {
  programState: FlexManageState
  activeOptions: Dictionary<FlexActiveOptions>
  canEdit?: boolean
  isPending: boolean
  openUpdateModal: () => void
}

const FlexManagePending = React.memo((props: FlexManagePendingProps) => {
  const {
    programState,
    activeOptions,
    canEdit = true,
    isPending,
    openUpdateModal,
  } = props

  const currentBudget = FormattingUtils.formatDollar(
    programState.active.budget,
    0
  )
  const nextMonthBudget = FormattingUtils.formatDollar(
    programState.nextMonth.budget,
    0
  )

  const optionChanges = Object.keys(activeOptions.active).flatMap((option) => {
    if (activeOptions.active[option] !== activeOptions.nextMonth[option]) {
      return option
    } else {
      return []
    }
  })

  const providerChanges: any = ProgramUtils.getProviderDifference(
    programState,
    ['flex']
  )

  //only compare provider changes on active options
  const hasProviderChanges =
    Object.keys(providerChanges).length > 0 &&
    Object.keys(providerChanges).some(
      (option) => activeOptions.nextMonth[option]
    )

  const hasChanges =
    currentBudget !== nextMonthBudget ||
    optionChanges.length > 0 ||
    hasProviderChanges ||
    programState.nextMonth.status === 'CANCELLING'

  return (
    <>
      {hasChanges ? (
        <Container>
          <Text variant='h4' marginBottom='0.5rem'>
            {canEdit ? 'Pending Changes' : 'Upcoming Changes'}
          </Text>
          <Text variant='body1' marginBottom='1.5rem'>
            {getDescriptionText(canEdit, isPending)}
          </Text>
          {programState.nextMonth.status === 'CANCELLING' && (
            <>
              <Divider margin='1.5rem 0' />
              <Text
                variant='caption'
                captionTitle
                textColor={palette.text.secondary}
                marginBottom='0.5rem'
              >
                Program Cancellation Request
              </Text>
              <Text variant='body2'>
                Our team will be in touch with you soon.
              </Text>
              {canEdit && (
                <Button onClick={openUpdateModal} width='100%' marginTop='1rem'>
                  Reactivate Program
                </Button>
              )}
            </>
          )}
          {currentBudget !== nextMonthBudget && (
            <ProgramChange
              title='Total Monthly Budget'
              metric='Per Commuter'
              icon={<PlatformSvg folder='metrics' variant='money' />}
              value={`${currentBudget} ➞ ${nextMonthBudget}`}
            />
          )}
          {providerChanges.flex &&
            activeOptions.nextMonth.flex &&
            ProgramUtils.renderProviderChanges('flex', providerChanges.flex)}
          {optionChanges.length > 0 && (
            <>
              <Divider margin='1.5rem 0' />
              <Text
                variant='caption'
                captionTitle
                textColor={palette.text.secondary}
              >
                Program Options
              </Text>
              {optionChanges.map((option) => (
                <ProgramChange
                  key={option}
                  metric={ProgramUtils.optionsCopy[option].title}
                  icon={ProgramUtils.optionsCopy[option].icon}
                  value={
                    activeOptions.nextMonth[option] ? 'Enabled' : 'Disabled'
                  }
                />
              ))}
            </>
          )}
        </Container>
      ) : null}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FlexManagePending.displayName = 'FlexManagePending'
}

export default FlexManagePending
