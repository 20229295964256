import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24 12C24 18.6274 18.6274 24 12 24C5.37257 24 0 18.6274 0 12C0 5.37257 5.37257 0 12 0C18.6274 0 24 5.37257 24 12ZM10.1295 14.2573L17.0676 7.31909L18.6839 8.93534L10.1295 17.4897L4.90649 12.2668L6.52274 10.6505L10.1295 14.2573Z'
    />
  </>,
  'Checkmark'
)
