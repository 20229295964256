import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import AlertIcon from 'core-system/Icons/Misc/Alert'
import Radio from 'core-system/Radio'
import Text from 'core-system/Text'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import AddCreditCardModal from './AddCreditCardModal'
import PaymentMethod from './PaymentMethod'
import IntegrateStripeBankAccount from './IntegrateStripeBankAccount'

const cardTypeMap = {
  virtual: 0,
  worksite: 1,
  employees: 1,
}

const StyledCard = styled.div`
  ${(props) => props.theme.baseCard}
  margin-bottom: 1.5rem;
  padding: 2.5rem;
`

const CardPreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1.5rem;

  img {
    max-width: ${(props) => props.theme.pxToRem(300)};
    width: 100%;
    margin-right: 3.25rem;
  }
`

const CardOptionsContainer = styled.div`
  margin-top: 0.5rem;
  max-width: ${(props) => props.theme.pxToRem(350)};
  color: ${(props) => props.theme.palette.text.secondary};
  ${(props) => props.theme.typography.body1};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-left: 0;
  }
`

const CardOption = styled.div`
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.palette.text.primary};
  ${(props) => props.theme.typography.action3};
`

const getInitialCardType = (segment: Segment, physicalTypes: string[]) => {
  if (!segment.shippingDetails) {
    return null
  } else {
    return physicalTypes.includes(segment.shippingDetails.shippingType) ? 1 : 0
  }
}

const FleetCardsConfig = React.memo(() => {
  const dispatch = useDispatch()

  const { segmentsType, profile, userInfo } = useSelector((state: AppState) => {
    return {
      segmentsType: state.employer.segmentsType,
      profile: state.employer.profile,
      userInfo: state.user.profile,
    }
  })

  const [isModalOpen, setIsModalOpen] = useState(false)

  const physicalTypes = ['worksite', 'employees']
  const allEmployeeSegment = segmentsType.all[0]

  const [cardType, setCardType] = useState(
    getInitialCardType(allEmployeeSegment, physicalTypes)
  )

  const handleCardTypeUpdate = () => {
    dispatch(
      employerActions.updateSegment({
        segmentId: allEmployeeSegment.id,
        name: allEmployeeSegment.name,
        shippingDetails: {
          ...allEmployeeSegment.shippingDetails,
          shippingType: cardType === 0 ? 'virtual' : 'worksite',
        },
      })
    )
    SegmentService.track('cardDistribution-btn-click', {
      action: 'update',
      preference: cardType === 0 ? 'virtual' : 'physical',
    })
  }

  const handleDelete = useCallback(
    (id: string, type: string) => {
      dispatch(employerActions.removePaymentMethod(id))
      setIsModalOpen(false)
      SegmentService.track('paymentMethod-btn-click', {
        action: 'delete',
        type,
      })
    },
    [dispatch]
  )

  const openAddCreditCardModal = useCallback(() => {
    setIsModalOpen(true)
    SegmentService.track('paymentMethod-btn-click', {
      action: 'add',
      type: 'card',
    })
  }, [setIsModalOpen])

  const paymentMethod = profile.platformFeeDetails[0]
  const hasValidPaymentMethod = profile.platformFeeDetails.length > 0

  return (
    <>
      <StyledCard>
        <Text variant='h4' marginBottom='1.5rem'>
          Payments &amp; Card Distribution
        </Text>
        <FlexContainer alignItems='center' marginBottom='1rem'>
          <Text variant='h5' margin='0.125rem 0.25rem 0 0'>
            Your Payment Method
          </Text>
          {!hasValidPaymentMethod && <AlertIcon width={18} height={18} />}
        </FlexContainer>
        {hasValidPaymentMethod ? (
          <PaymentMethod
            id={paymentMethod.internalId}
            type={paymentMethod.type}
            bankName={paymentMethod.bankName}
            brand={paymentMethod.brand}
            last4={paymentMethod.last4}
            expMonth={paymentMethod.expMonth && paymentMethod.expMonth}
            expYear={paymentMethod.expYear && paymentMethod.expYear}
            showActions={true}
            onDeleteAction={handleDelete}
          />
        ) : (
          <>
            <Text variant='body1' marginBottom='1rem'>
              In order to fund your commuters&apos; Fleet Cards and pay the
              monthly subscription fee, we need to collect a payment method. You
              can either directly link to a bank account or can connect a credit
              card. Bank connections are powered by Stripe, so your information
              is always secure. Credit card charges are subject to a 3%
              transaction fee.
            </Text>
            <FlexContainer alignItems='center'>
              <IntegrateStripeBankAccount
                billingEmail={userInfo.email}
                billingName={`${userInfo.firstName} ${userInfo.lastName}`}
              />
              <Text variant='action3' margin='0 1.5rem'>
                OR
              </Text>
              <Button
                size='small'
                onClick={openAddCreditCardModal}
                data-cy='accounts-billing-add-credit-card-btn'
              >
                Add Credit Card
              </Button>
            </FlexContainer>
          </>
        )}
        <Divider direction='horizontal' my='2rem' />
        <FlexContainer alignItems='center'>
          <Text variant='h5' margin='0.125rem 0.25rem 0 0'>
            Distribution
          </Text>
          {!allEmployeeSegment.shippingDetails && (
            <AlertIcon width={18} height={18} />
          )}
        </FlexContainer>
        <Text variant='body1' mt='1rem'>
          Choose whether your commuters will be issued a virtual or virtual +
          physical Fleet Card.
        </Text>
        <CardPreviewContainer>
          <img
            src={`${StaticAssetsUrl}/accounts/fleet-card-preview.png`}
            alt='fleet-card-preview'
          />
          <CardOptionsContainer>
            <FlexContainer alignItems='center' marginBottom='1.5rem'>
              <Radio
                active={cardType === 0}
                onClick={() => setCardType(0)}
                marginRight='1rem'
                data-cy='accounts-billing-virtual-card-radio'
              />
              <div>
                <CardOption>Virtual card</CardOption>
                Instant access via Apple/Google Pay
              </div>
            </FlexContainer>
            <FlexContainer alignItems='center'>
              <Radio
                active={cardType === 1}
                onClick={() => setCardType(1)}
                marginRight='1rem'
                data-cy='accounts-billing-physical-card-radio'
              />
              <div>
                <CardOption>Virtual card + physical card</CardOption>
                Can be shipped to worksites or individual commuters ($3.00 per
                employee + shipping fees)
              </div>
            </FlexContainer>
          </CardOptionsContainer>
          <Button
            height='2.5rem'
            margin='auto 0 0 auto'
            onClick={() => handleCardTypeUpdate()}
            disabled={
              cardType === null ||
              (allEmployeeSegment.shippingDetails &&
                cardTypeMap[allEmployeeSegment.shippingDetails.shippingType] ===
                  cardType)
            }
          >
            {!allEmployeeSegment.shippingDetails ? 'Save' : 'Update'}
          </Button>
        </CardPreviewContainer>
      </StyledCard>
      <AddCreditCardModal
        open={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FleetCardsConfig.displayName = 'FleetCardsConfig'
}

export default FleetCardsConfig
