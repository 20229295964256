import FlexContainer from 'core-system/FlexContainer'
import StarIcon from 'core-system/Icons/Misc/Star'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { Merchant } from 'redux/programs/programsTypes'
import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

const PartnerDescriptionContainer = styled.div<LayoutProps & SpaceProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: ${palette.primary.primaryDark};
  background-color: ${palette.secondary.purple5};
  border: 0.0625rem solid ${palette.secondary.purple3};
  border-radius: 0.25rem;

  ${layout}
  ${space}
`

const PartnerBenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  margin-top: 0.375rem;
`

const StyledLink = styled.a`
  cursor: pointer;
  font-weight: normal;
  line-height: normal;
  color: ${palette.primary.primaryPurple};

  &:hover {
    color: ${palette.text.primary};
  }
`

interface ProviderPartnerBenefitsProps extends SpaceProps, LayoutProps {
  merchant: Merchant
}

const ProviderPartnerBenefits = React.memo(
  (props: ProviderPartnerBenefitsProps) => {
    const { merchant } = props
    if (!merchant.partnershipDescription) return null
    const partnershipData = ProgramUtils.getFormattedPartnerInfo(
      merchant.partnershipDescription
    )
    return (
      <PartnerDescriptionContainer {...props}>
        <FlexContainer alignItems='center' style={{ gap: '0.5rem' }}>
          <StarIcon
            height={20}
            width={20}
            style={{ textAlign: 'center', paddingBottom: '0.125rem' }}
          />
          <Text variant='caption' textColor={palette.primary.primaryDark}>
            FLEET PARTNER
          </Text>
        </FlexContainer>
        <PartnerBenefitsContainer>
          {partnershipData.partnerBlurb && (
            <Text variant='body1'>{partnershipData.partnerBlurb}</Text>
          )}
          {partnershipData.partnerUrl && (
            <Text variant='body1'>
              Please follow the link to use our Fleet discount{` `}
              <StyledLink
                href={partnershipData.partnerUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                here.
              </StyledLink>
            </Text>
          )}
          {partnershipData.partnerCode && (
            <Text variant='body1'>
              Please use this promo code to get your Fleet discount at provider
              checkout: {` `}
              <b>{partnershipData.partnerCode}</b>
            </Text>
          )}
        </PartnerBenefitsContainer>
      </PartnerDescriptionContainer>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProviderPartnerBenefits.displayName = 'Provider Partner Benefits'
}

export default ProviderPartnerBenefits
