import LocalStorage from './LocalStorage'

const getToken = () => {
  const session = window.sessionStorage.getItem('tokenExp')
  return session ? Number(session) : Number(LocalStorage.getItem('tokenExp'))
}

export default function () {
  const expToken = getToken()
  const isExpired = expToken ? Date.now() >= expToken * 1000 : true

  return isExpired
}
