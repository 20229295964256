import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <rect x='18.1999' y='18.2' width='3.9' height='3.9' fill='#16014A' />
    <rect x='12.3499' y='17.55' width='15.6' height='1.3' fill='#16014A' />
    <path
      d='M14.04 0.478824L27.9499 17.55H12.3499L1.44117 2.04814C0.835062 1.18683 1.45112 0 2.50432 0H13.0322C13.4231 0 13.7932 0.175837 14.04 0.478824Z'
      fill='#BEB3FF'
    />
    <circle cx='20.1499' cy='36.4' r='15.6' fill='#E3E3EC' />
    <circle opacity='0.1' cx='20.15' cy='36.3999' r='9.1' fill='#5E5EAA' />
    <path
      d='M26.2598 0.478824L12.3499 17.55H27.9499L38.8586 2.04814C39.4647 1.18683 38.8487 0 37.7955 0H27.2676C26.8767 0 26.5066 0.175837 26.2598 0.478824Z'
      fill='#DBDBFF'
    />
  </>,
  'Silver Medal',
  '0 0 41 52'
)
