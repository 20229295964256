import React, { useState } from 'react'
import GridCard from 'employee-platform/shared/components/GridCard'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import Loading from 'core-system/Loading'
import LeaderboardUtils from 'features/Leaderboards/LeaderboardUtils'
import styled, { css } from 'styled-components'
import {
  Competition,
  Segment,
} from 'redux/employeePlatform/employeePlatformTypes'
import Text from 'core-system/Text'
import CollapsibleList from 'employee-platform/shared/components/CollapsibleList'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import { useDispatch } from 'react-redux'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'

const LeaderboardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const LeaderboardContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};

  ${(props) =>
    props.isSelected
      ? css`
          background-color: ${(props) => props.theme.palette.secondary.purple5};
          box-shadow: ${(props) => props.theme.dropShadows.normal};
          border: 1px solid ${(props) => props.theme.palette.grey.grey3};
          color: ${(props) => props.theme.palette.text.primary};
        `
      : ''}

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple5};
    box-shadow: ${(props) => props.theme.dropShadows.selected};
    border: 1px solid ${(props) => props.theme.palette.grey.grey3};
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const renderLeaderboards = (
  leaderboards: Competition[],
  selectedLeaderboardId: string | null,
  handleLeaderboardClick: (leaderboardId: string) => void
) => {
  if (leaderboards.length === 0) {
    return <NoDataText text='NO LEADERBOARDS' />
  }

  return leaderboards.map((leaderboard) => {
    return (
      <LeaderboardContainer
        key={leaderboard.id}
        isSelected={leaderboard.id === selectedLeaderboardId}
        onClick={() => handleLeaderboardClick(leaderboard.id)}
      >
        {LeaderboardUtils.leaderboardData[leaderboard.queryType].icon}
        <Text variant='action3' hideOverflow>
          {LeaderboardUtils.leaderboardData[leaderboard.queryType].title}
        </Text>
      </LeaderboardContainer>
    )
  })
}

const renderLeaderboardList = (
  segmentsOpen: { [segmentId: string]: boolean } | null,
  setSegmentsOpen: (segments: { [segmentId: string]: boolean }) => void,
  mySegments: Segment[],
  myLeaderboards: { [leaderboardId: string]: Competition },
  selectedLeaderboardId: string | null,
  handleLeaderboardClick: (leaderboardId: string) => void
) => {
  if (!segmentsOpen) {
    return null
  }

  return mySegments?.map((segment) => {
    if (segment.upcoming) {
      return null
    } else {
      const segmentLeaderboards = Object.values(myLeaderboards).filter(
        (leaderboard) => leaderboard.segmentId === segment.id
      )
      return (
        <CollapsibleList
          key={segment.id}
          title={`${segment.name} (${segmentLeaderboards.length})`}
          isOpen={segmentsOpen[segment.id]}
          onClick={() =>
            setSegmentsOpen({
              ...segmentsOpen,
              [segment.id]: !segmentsOpen[segment.id],
            })
          }
        >
          {renderLeaderboards(
            segmentLeaderboards,
            selectedLeaderboardId,
            handleLeaderboardClick
          )}
        </CollapsibleList>
      )
    }
  })
}

interface LeaderboardListSelectProps {
  isMobile: boolean
  selectedLeaderboardId: string | null
  setSelectedLeaderboardId: (id: string) => void
}

const LeaderboardListSelect = React.memo(
  (props: LeaderboardListSelectProps) => {
    const { selectedLeaderboardId, setSelectedLeaderboardId } = props

    const { profileData, myLeaderboards } = useSelector(
      (state: AppState) => state.employeePlatform
    )

    const dispatch = useDispatch()

    const [segmentsOpen, setSegmentsOpen] = useState(
      profileData
        ? profileData.segments.reduce((segments, segment) => {
            if (segment.upcoming) {
              return segments
            }
            return {
              ...segments,
              [segment.id]: false,
            }
          }, {})
        : null
    )

    const handleLeaderboardClick = (leaderboardId: string) => {
      setSelectedLeaderboardId(leaderboardId)
      dispatch(employeePlatformActions.getLeaderboardRankings(leaderboardId))
    }

    if (!profileData || !myLeaderboards) {
      return <Loading />
    }

    return (
      <GridContainer numColumns={1} fitContentHeight>
        <GridCard headerTitle='My Leaderboards'>
          <LeaderboardListContainer>
            {segmentsOpen &&
            myLeaderboards &&
            Object.keys(myLeaderboards).length > 0 ? (
              renderLeaderboardList(
                segmentsOpen,
                setSegmentsOpen,
                profileData.segments,
                myLeaderboards,
                selectedLeaderboardId,
                handleLeaderboardClick
              )
            ) : (
              <NoDataText text='YOU ARE NOT PARTICIPATING IN ANY COMPETITIONS' />
            )}
          </LeaderboardListContainer>
        </GridCard>
      </GridContainer>
    )
  }
)

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardListSelect.displayName = 'LeaderboardListSelect'
}

export default LeaderboardListSelect
