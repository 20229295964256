export type RolloutStatus =
  | 'ACTIVE'
  | 'PENDING'
  | 'UPDATING'
  | 'CANCELLING'
  | 'CANCELLED'

export interface Notification {
  notification_id: string
  title?: string
  body?: string
  id?: string
  read_at?: string
  updated_at?: string
  important?: boolean
  archived?: boolean
}

export type SegmentType = 'ALL' | 'WORKSITE' | 'CUSTOM'
export interface Segment {
  id: string
  name: string
  type: SegmentType
  upcoming: boolean
}

export type UserType = 'HR_ADMIN' | 'HR_USER' | 'EMP_USER'

export type PricingType =
  | 'TIME'
  | 'DISTANCE'
  | 'LEASING'
  | 'FLAT'
  | 'ZONE'
  | 'API'

export type ServiceModeType =
  | 'LINKER'
  | 'WALK'
  | 'BIKE'
  | 'EBIKE'
  | 'SCOOTER'
  | 'ESCOOTER'
  | 'MOPED'
  | 'EMOPED'
  | 'CAR'
  | 'CARSHARE'
  | 'RIDESHARE'
  | 'CARPOOL'
  | 'VANPOOL'
  | 'SHUTTLE'
  | 'BIKESHARE'
  | 'EBIKESHARE'
  | 'SCOOTERSHARE'
  | 'ESCOOTERSHARE'
  | 'MOPEDSHARE'
  | 'EMOPEDSHARE'
  | 'TRANSIT'
  | 'COMMRAIL'
  | 'HEAVYRAIL'
  | 'LITERAIL'
  | 'BUS'
  | 'FERRY'
  | 'AERIAL'
  | 'FUNICULAR'
  | 'MONORAIL'

export interface Mode {
  id: string
  name: string
  iconUrl: string | null
  websiteUrl: string | null
  metroArea: string | null
  modeType: ServiceModeType
  avgPrice: string | null
  fleetPartner: boolean
  description: string | null
  tags: string[]
  pricingType: PricingType | null
  partnershipDescription: string | null
  refundPolicies: string | null
  setupInstructions: string | null
  country: string | null
  merchantId: string
  showUser: boolean
  updatedAt?: string
}

export interface Merchant {
  id: string
  name: string
  fleetPartner: boolean
  partnershipDescription: string | null
  websiteUrl: string | null
  iconUrl: string | null
  description: string | null
  setupInstructions: string | null
  refundPolicies: string | null
  showUser: boolean
  modeTypes: string[]
  metroAreas: string[]
  countries: string[]
  tags: string[]
  pricingTypes: PricingType[]
  modeIds: string[]
  updatedAt?: string
}

export type CompetitionStatus =
  | 'ACTIVE'
  | 'PENDING'
  | 'UPDATING'
  | 'CANCELLING'
  | 'CANCELLED'
export type CompetitionQueryType =
  | 'ENV'
  | 'WALK'
  | 'BIKE'
  | 'ALTERNATIVE'
  | 'MICROMOBILITY'
  | 'TRANSIT'
  | 'CARPOOL'

export interface LeaderboardRanking {
  id: string
  name: string
  value: number | null
  rank: number
}

export interface Competition {
  createdAt: string
  updatedAt: string
  startDate: string
  employerId: string
  endDate: string | null
  familyId: string
  id: string
  parentProductId: string | null
  firstPrize: number
  secondPrize: number
  thirdPrize: number
  queryType: CompetitionQueryType
  segmentId: string
  status: CompetitionStatus
  rankings: LeaderboardRanking[] | null
  myRank: LeaderboardRanking | null
}

export type ProgramStatus = 'ACTIVE' | 'UPDATING' | 'PENDING'
export type ProgramType = 'FLEX' | 'MICROMOBILITY' | 'GRH' | 'OTP'
export interface BaseProgram {
  id: string
  createdAt: string
  updatedAt: string
  employer: string
  segment: string
  startDate: string
  endDate: string | null
  name: string
  type: ProgramType
  status: ProgramStatus
  budget: number
  remainingBudget: number
  yearlyPool: boolean
  shared: boolean
  leasing: boolean
  cycleStart: string
  cycleEnd: string
  numUses: number | null
  remainingUses: number | null
  requiresRequest: boolean
}

export type TaxSavingsProgramStatus = 'ACTIVE' | 'PAUSED' | 'OFF'
export type PreTaxProgramType = 'COMMUTER_TRANSIT' | 'COMMUTER_PARKING'

export interface TaxSavingsProgram {
  balance: number
  contributionDeadlineDay: number
  createdAt: string
  cycleBalance: number
  employeeDeduction: number | null
  employeeDeductionId: string | null
  employerContribution: number
  endDate: string
  familyId: string
  id: string
  name: string
  parentProduct: string
  segment: string
  startDate: string
  status: TaxSavingsProgramStatus
  type: PreTaxProgramType
  updateAt: string
}

// TODO: Refactor the way QTF programs are fetched and saved in the store using this new type
export type PreTaxProgramStatus =
  | 'ACTIVE'
  | 'PENDING'
  | 'UPDATING'
  | 'CANCELLING'
  | 'CANCELLED'
export interface NewTaxSavingsProgram {
  balance: number
  contributionDeadlineDay: number
  createdAt: string
  cycleBalance: number
  employeeDeduction: number | null
  employeeDeductionId: string | null
  employerContribution: number
  endDate: string
  familyId: string
  id: string
  name: string
  parentProduct: string
  segment: string
  startDate: string
  status: PreTaxProgramStatus
  type: PreTaxProgramType
  updatedAt: string
}

// ***********************************
export type FinchEmploymentType = 'employee' | 'contractor'
export const FinchEmploymentTypes = {
  EMPLOYEE: 'employee' as FinchEmploymentType,
  CONTRACTOR: 'contractor' as FinchEmploymentType,
}

// PROFILE
export interface EmployeeProfile {
  id: string
  email: string
  firstName: string
  lastName: string
  avatar: string | null
  updatedAt: string | null
  name: string
  worksiteAddress: string
  worksiteId: string
  employeeCorpId: string
  unitNumber: string | null
  homeAddress: string
  currentTransport: string
  onboardingComplete: boolean
  dateJoined: string
  postcode: string
  openSignup: boolean
  approved: boolean
  segments: Segment[]
  types: UserType[]
  billingAddress: {
    city: string
    line1: string
    line2: string
    state: string
    country: string
    postalCode: string
  }
  employerId: string
  employerWebsite: string
  demoEmployee: boolean
  cardholderVerified: boolean
  referredBy: string | null
  employerProducts: string[]
  finchEmploymentType?: FinchEmploymentType
}

export interface BillingDetails {
  name: string
  email: string | null
  phone: string | null
  address: {
    city: string
    line1: string | null
    line2: string | null
    state: string | null
    country: string | null
    postalCode: string | null
  }
}

export interface FleetCard {
  currency: string
  expMonth: number | null
  expYear: number | null
  internalId: string
  last4: string
  status: string
  type: string
}

export type PaymentMethodType =
  | 'card'
  | 'ach_debit'
  | 'bank_account'
  | 'us_bank_account'
export interface Payment {
  expMonth: number | null
  expYear: number | null
  internalId: string
  last4: string
  status: string
  type: PaymentMethodType
  brand: string | null
  defaultPaymentSource: boolean
  bankName: string | null
  billingDetails: BillingDetails
}

export interface TopUpPayload {
  paymentMethodId: string
  topUpAmount: number
}

export type CardStatus = 'active' | 'inactive'
export type CardType = 'virtual' | 'physical'
export interface Card {
  internalId: string
  status: CardStatus
  type: CardType
  currency: string
  expMonth: number | null
  expYear: number | null
  last4: string
}

export type ChargeStatus = 'succeeded' | 'pending' | 'failed'
export interface Charge {
  internalId: string
  paymentMethodId: string
  paymentMethodType: PaymentMethodType
  created: string
  type: string
  status: ChargeStatus
  name: string
  amount: number
  refunded: boolean
  amountRefunded: number
}

export interface Payout {
  amount: number
  internalId: string
  date: string
  type: string
  details: string
}

export interface Wallet {
  currentBalance: number
  // TODO: create this type
  autoReload: any
  allCharges: Charge[]
  allPayouts: Payout[]
  pendingBalance: number
  paymentMethods: Payment[]
  cards: Card[]
  rewards: {
    balance: number
    realBalance: number
  }
}

// PRETAX
export type PreTaxEmployeeAllocationUpdatePayload = {
  employeeDeduction: number
  benefit: string
}

export interface PreTaxEmployeeDeduction
  extends PreTaxEmployeeAllocationUpdatePayload {
  id: string
}

export interface PreTaxBalanceResponsePayload {
  parkingBalance: number
  parkingCycleBalance: number
  transitBalance: number
  transitCycleBalance: number
}

export interface PreTaxBenefitHistoryEntry {
  id: string | null
  date: string
  employeeTransitDeduction: number | null
  employeeParkingDeduction: number | null
  employerTransitContribution: number | null
  employerParkingContribution: number | null
}

export interface PreTaxProgramResponsePayload {
  contributionDeadlineDay: number
  createdAt: string
  employerContribution: number
  endDate: string | null
  familyId: string
  hrInitialDisplayStartDate: string
  id: string
  manual: boolean
  name: string
  nextValidClose: string | null
  parentProduct: string | null
  payrollCycle: string | null
  segment: string
  startDate: string
  status: PreTaxProgramStatus
  type: PreTaxProgramType
  updatedAt: string
}

export interface PreTaxProgram extends PreTaxProgramResponsePayload {
  balance: number | null
  cycleBalance: number | null
  employeeDeduction: PreTaxEmployeeDeduction
}

export interface ProgramRequest {
  id: string
  programId: string
  reason: string
  cancelled: boolean
  canCancel: boolean
  isOpen: boolean
  createdAt: string
}

export interface CreateProgramRequestPayload {
  programId: string
  reason: string
}

export interface Stop {
  lat: number | null
  lng: number | null
  home: boolean
  worksite: boolean
  id: string
}

export interface CardholderDetailsUpdatePayload {
  dob: string
  user_terms_acceptance: number
}

// CARPOOL TYPES
export interface Point {
  type: string
  coordinates: number[] // Will be [lng, lat]
}

export interface CarpoolMember {
  id: string
  source: Point
  dest: Point
  workArrivalTime: string
  workDepartureTime: string
  driver: boolean
  passenger: boolean
  vanpoolInterest: boolean
  contactInfo: string
  distanceSource: number
  distanceDest: number
  carpoolGroup: SearchedCarpoolGroup
  firstName: string
}

export interface CarpoolGroupCreatePayload {
  source: Point | null
  dest: Point | null
  workArrivalTime: string | null
  workDepartureTime: string | null
  driver: boolean
  passenger: boolean
  vanpoolInterest: boolean
  contactInfo: string | null
  numSeats: number | null
}

export interface CarpoolGroup {
  id: string
  ownerId: string
  source: Point
  dest: Point
  workArrivalTime: string
  workDepartureTime: string
  driver: boolean
  passenger: boolean
  vanpoolInterest: boolean
  contactInfo: string
  numSeats: number
  hasDriver: boolean
  seatsLeft: number
}

export interface MyCarpoolGroupResponsePayload extends CarpoolGroup {
  members: CarpoolMember[]
}

export interface MyCarpoolGroup extends CarpoolGroup {
  members: { [memberId: string]: CarpoolMember }
}

export interface SearchedCarpoolGroup extends CarpoolGroup {
  isMember: boolean
  requestSent: boolean
  distanceSource: number | null
  distanceDest: number | null
}

export interface CarpoolSearchResults {
  geojson: any
  groups: SearchedCarpoolGroup[]
}

export interface CarpoolRequestData {
  id: string
  source: Point
  dest: Point
  workArrivalTime: string
  workDepartureTime: string
  driver: boolean
  passenger: boolean
  vanpoolInterest: boolean
  distanceSource: number
  distanceDest: number
  contactInfo: string
  customMessage: string
}

export interface CarpoolRequestResponsePayload extends CarpoolRequestData {
  carpoolGroupId: string
}

export interface CarpoolRequest extends CarpoolRequestData {
  carpoolGroup: SearchedCarpoolGroup
}

export interface DeclineRequestResponsePayload {
  carpoolRequestId: string
  carpoolGroupId: string
}

export interface ApproveRequestResponsePayload
  extends DeclineRequestResponsePayload {
  member_id: string
}

export interface CarpoolSearchRequestPayload {
  source: number[] | null
  sourceBound: number | null
  dest: number[] | null
  destBound: number | null
  workArrivalTime: string[] | null
  workDepartureTime: string[] | null
}

export interface CarpoolRequestToJoinPayload {
  source: Point | null
  dest: Point | null
  workArrivalTime: string | null
  workDepartureTime: string | null
  driver: boolean
  passenger: boolean
  vanpoolInterest: boolean
  contactInfo: string | null
  customMessage: string
}

export interface CarpoolRequestToJoinRequestData {
  carpoolGroupId: string
  payload: CarpoolRequestToJoinPayload
}

export type CarpoolRelationType = 'MEMBER' | 'OWNER' | 'REQUESTED' | 'NONE'

// CONCIERGE TYPES
export interface FAQ {
  question: string
  answer: string
}

export interface FAQResponsePayload {
  faq: FAQ[]
  createdAt: string
  updatedAt: string
}

// TRIPS TYPES
export interface TripLocation {
  id: string
  home: boolean
  worksite: boolean
  lat: number
  lng: number
}

export interface Trip {
  id: string
  date: string
  fleetDetected: boolean
  CO2Saved: number | null
  incentives: number
  timeRange: string | null
  noTripReason: string | null
  source: TripLocation
  dest: TripLocation
  tripType: string
  modeIds: string[]
  // duration: string
  // handsfreeTime: string
  // cost: number
  // dist: number
  // env: number
}

export interface TripMonth {
  totalCO2Saved: number
  totalIncentives: number
  trips: { [date: string]: Trip[] } // Date keys are in YYYY-MM-DD format
}

export interface AddTripPayload {
  noTripReason: string | null
  date: string
  source: Omit<TripLocation, 'id'>
  dest: Omit<TripLocation, 'id'>
  timeRange: string
  modeIds: string[]
}

// OPT-IN TYPES
export interface OptIn {
  id: string
  question: string
  choices: string[]
  choice: number
  start_date: string
  end_date: string
  segment: string
  status: RolloutStatus
  created_at: string
  updated_at: string
}

export interface SetOptInChoiceData {
  optInId: string
  choice: number
}
