import { all, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { mapIntelligenceActions } from 'redux/mapIntelligence/mapIntelligenceSlice'
import MapIntelligenceService from 'redux/mapIntelligence/mapIntelligenceService'
import {
  AgonyDataParams,
  BaseCommuterParams,
  RecentTripDetailsParams,
  RecentTripOriginsParams,
} from 'redux/mapIntelligence/mapIntelligenceTypes'
import { Action } from 'shared/Types/Actions'

export function* getAgonyData(action: Action<AgonyDataParams>) {
  yield defaultFlow(
    action,
    MapIntelligenceService.getAgonyData,
    mapIntelligenceActions.getAgonyDataSuccess
  )
}

export function* getBaseCommuters(action: Action<BaseCommuterParams>) {
  yield defaultFlow(
    action,
    MapIntelligenceService.getBaseCommuters,
    mapIntelligenceActions.getBaseCommutersSuccess
  )
}

export function* getRecentTripOrigins(action: Action<RecentTripOriginsParams>) {
  yield defaultFlow(
    action,
    MapIntelligenceService.getRecentTripOrigins,
    mapIntelligenceActions.getRecentTripOriginsSuccess
  )
}

export function* getRecentTripDetails(action: Action<RecentTripDetailsParams>) {
  yield defaultFlow(
    action,
    MapIntelligenceService.getRecentTripDetails,
    mapIntelligenceActions.getRecentTripDetailsSuccess
  )
}

/**
 * Watchers
 */
export function* watchGetAgonyData() {
  yield takeEvery([mapIntelligenceActions.getAgonyData.type], getAgonyData)
}

export function* watchGetBaseCommuters() {
  yield takeEvery(
    [mapIntelligenceActions.getBaseCommuters.type],
    getBaseCommuters
  )
}

export function* watchGetRecentTripOrigins() {
  yield takeEvery(
    [mapIntelligenceActions.getRecentTripOrigins.type],
    getRecentTripOrigins
  )
}

export function* watchGetRecentTripDetails() {
  yield takeEvery(
    [mapIntelligenceActions.getRecentTripDetails.type],
    getRecentTripDetails
  )
}

export default function* () {
  yield all([
    watchGetAgonyData(),
    watchGetBaseCommuters(),
    watchGetRecentTripOrigins(),
    watchGetRecentTripDetails(),
  ])
}
