import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='52' height='52' rx='26' fill='#F0F0FF' />
    <path
      d='M32.6665 36.6667H19.3332V38C19.3332 38.3536 19.1927 38.6928 18.9426 38.9428C18.6926 39.1929 18.3535 39.3333 17.9998 39.3333H16.6665C16.3129 39.3333 15.9737 39.1929 15.7237 38.9428C15.4736 38.6928 15.3332 38.3536 15.3332 38V36.6667H13.9998V26H12.6665V20.6667H13.9998V16.6667C13.9998 15.9594 14.2808 15.2811 14.7809 14.781C15.281 14.281 15.9593 14 16.6665 14H35.3332C36.0404 14 36.7187 14.281 37.2188 14.781C37.7189 15.2811 37.9998 15.9594 37.9998 16.6667V20.6667H39.3332V26H37.9998V36.6667H36.6665V38C36.6665 38.3536 36.526 38.6928 36.276 38.9428C36.0259 39.1929 35.6868 39.3333 35.3332 39.3333H33.9998C33.6462 39.3333 33.3071 39.1929 33.057 38.9428C32.807 38.6928 32.6665 38.3536 32.6665 38V36.6667ZM16.6665 16.6667V28.6667H35.3332V16.6667H16.6665ZM16.6665 31.3333V34H21.9998V31.3333H16.6665ZM29.9998 31.3333V34H35.3332V31.3333H29.9998Z'
      fill='#5E5EAA'
    />
  </>,
  'Transit',
  '0 0 52 52'
)
