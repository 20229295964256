import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <g clipPath='url(#clip0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.75 20.55V10C20.75 6.5125 17.4875 5.75 13.2375 5.6375L13.9294 3.75001H14.9999C15.4142 3.75001 15.7499 3.41422 15.7499 3.00001V1H23.25V-0.25H0.75V1H9.49995V3.00001C9.49995 3.41422 9.83573 3.75001 10.2499 3.75001H11.4321L10.7375 5.65C6.825 5.7625 3.25 6.5375 3.25 10V20.55C3.25 22.3625 4.7375 23.875 6.4875 24.2625L9.7875 24.375H17.525C19.6375 24.375 20.75 22.6625 20.75 20.55ZM5.74998 11C5.74998 10.7929 5.91787 10.625 6.12498 10.625H7.87498C8.08208 10.625 8.24998 10.7929 8.24998 11V15.25C8.24998 15.4571 8.08208 15.625 7.87498 15.625H6.12498C5.91787 15.625 5.74998 15.4571 5.74998 15.25V11ZM9.87495 10.625C9.66785 10.625 9.49995 10.7929 9.49995 11V15.25C9.49995 15.4571 9.66784 15.625 9.87495 15.625H14.125C14.3321 15.625 14.5 15.4571 14.5 15.25V11C14.5 10.7929 14.3321 10.625 14.125 10.625H9.87495ZM16.1251 10.625C15.918 10.625 15.7501 10.7929 15.7501 11V15.25C15.7501 15.4571 15.918 15.625 16.1251 15.625H17.8751C18.0822 15.625 18.2501 15.4571 18.2501 15.25V11C18.2501 10.7929 18.0822 10.625 17.8751 10.625H16.1251ZM16.625 20C16.8321 20 17 19.8321 17 19.625V19.125C17 18.9179 16.8321 18.75 16.625 18.75H7.37503C7.16792 18.75 7.00003 18.9179 7.00003 19.125V19.625C7.00003 19.8321 7.16792 20 7.37503 20H16.625Z'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </>,
  'Aerial'
)
