import FlexIcon from 'core-system/Icons/Sidebar/Flex'
import MicromobilityIcon from 'core-system/Icons/Sidebar/Micromobility'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import FlexPreviewModal from 'features/Flex/Overview/components/FlexPreviewModal'
import MicromobilityPreviewModal from 'features/Micromobility/Overview/components/MicromobilityPreviewModal'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppState } from 'redux/config/store'
import { ProgramType } from 'redux/programs/programsTypes'
import { Locations } from 'shared/Router/Locations'
import NoPaymentModal from 'shared/components/NoPaymentModal'
import styled, { css } from 'styled-components'

const programMap = {
  MICROMOBILITY: {
    title: 'Micromobility',
    description:
      'Help your commuters gain access to personal mobility options like bikes, scooters, and mopeds.',
    color: '#DCFCFA',
    icon: (
      <MicromobilityIcon
        width={40}
        height={40}
        color={palette.primary.primaryDark}
      />
    ),
    locationActivate: Locations.Micromobility.Programs,
    locationManage: Locations.Micromobility.Programs,
  },
  FLEX: {
    title: 'Flex',
    description:
      'Give commuters ultimate flexibility with a budget that can be spent on any mobility service a commuter chooses.',
    color: '#FFEBF8',
    icon: (
      <FlexIcon width={40} height={40} color={palette.primary.primaryDark} />
    ),
    locationActivate: Locations.Flex.Programs,
    locationManage: Locations.Flex.Programs,
  },
}

const Container = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.9375rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  margin-bottom: 1rem;
  overflow: hidden;
`

const CardContainer = styled.div`
  padding: 1.5rem;
`

const Banner = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  height: ${(props) => props.theme.pxToRem(97)};
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`

const ButtonContainer = styled.div<{ isPending: boolean; active: boolean }>`
  ${(props) => props.theme.typography.action1}
  height: ${(props) => props.theme.pxToRem(49)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.text.secondary};
  background-color: ${(props) => props.theme.palette.grey.grey3};
  ${(props) =>
    !props.isPending &&
    props.active &&
    css`
      color: ${(props) => props.theme.palette.white};
      background-color: ${(props) => props.theme.palette.primary.primaryPurple};
      cursor: pointer;

      &:hover {
        background-color: ${(props) => props.theme.palette.primary.primaryDark};
      }
    `}

  ${(props) =>
    props.isPending &&
    props.active &&
    css`
      color: ${(props) => props.theme.palette.text.secondary};
      background-color: ${(props) => props.theme.palette.secondary.purple5};

      &:hover {
        background-color: ${(props) => props.theme.palette.secondary.purple4};
        color: ${(props) => props.theme.palette.secondary.lavender1};
      }
    `}
`

const getButtonText = (isPending: boolean, active: boolean) => {
  if (!active) {
    return 'Contact Fleet to Activate'
  } else if (isPending) {
    return 'Programs Pending'
  } else {
    return 'Activate'
  }
}

interface ProgramCardProps {
  type: ProgramType
  isPending: boolean
  startDate: string
}

const ProgramCard = React.memo((props: ProgramCardProps) => {
  const { type, isPending, startDate } = props
  const navigate = useNavigate()

  const [isFlexModalOpen, setIsFlexModalOpen] = useState(false)
  const [isMicromobilityModalOpen, setIsMicromobilityModalOpen] =
    useState(false)

  const products = new Set(
    useSelector((state: AppState) => state.employer.profile.products)
  )
  const { hasValidPaymentMethod, profile } = useSelector(
    (state: AppState) => state.employer
  )

  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)

  const buttonText = getButtonText(isPending, products.has(type))

  const handleOnClick = () => {
    if (!products.has(type)) {
      return
    }
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      if (isPending) {
        navigate(`/${type.toLowerCase()}`, {
          state: { from: Locations.Dashboard },
        })
      } else {
        if (type === 'FLEX') {
          setIsFlexModalOpen(true)
        } else if (type === 'MICROMOBILITY') {
          setIsMicromobilityModalOpen(true)
        }
      }
    } else {
      setIsNoPaymentModalOpen(true)
    }
  }

  return (
    <>
      <Container>
        <CardContainer>
          <Banner color={programMap[type].color}>
            {programMap[type].icon}
          </Banner>
          <Text variant='h5' marginBottom='0.5rem'>
            {programMap[type].title}
          </Text>
          <Text variant='body1'>{programMap[type].description}</Text>
          {startDate && products.has(type) && (
            <Text
              variant='action3'
              marginTop='1rem'
            >{`Your earliest program is set to start on ${moment(
              startDate
            ).format('MMMM Do, YYYY')}.`}</Text>
          )}
        </CardContainer>
        <ButtonContainer
          isPending={isPending}
          onClick={handleOnClick}
          active={products.has(type)}
        >
          {buttonText}
        </ButtonContainer>
      </Container>
      <FlexPreviewModal
        open={isFlexModalOpen}
        closeModal={() => setIsFlexModalOpen(false)}
      />
      <MicromobilityPreviewModal
        open={isMicromobilityModalOpen}
        closeModal={() => setIsMicromobilityModalOpen(false)}
      />
      <NoPaymentModal
        open={isNoPaymentModalOpen}
        closeModal={() => setIsNoPaymentModalOpen(false)}
        product={type.toLowerCase()}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramCard.displayName = 'ProgramCard'
}

export default ProgramCard
