import Table, { TableLabelRow, TableRow, TableRowCell } from 'core-system/Table'
import moment from 'moment'
import React from 'react'
import { PreTaxBenefitHistoryEntry } from 'redux/employeePlatform/employeePlatformTypes'
import styled from 'styled-components'
import Text from 'core-system/Text'
import FormattingUtils from 'shared/FormattingUtils'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { PreTaxMonthlyMaximums } from '../PreTaxUtils'
import palette from 'core-system/Themes/palette'

const TABLE_COLUMN_WIDTHS = ['40%', '25%', '25%']

const EmptyTableRow = styled(TableRow)`
  text-align: center;
`

const MobileTableHeader = styled.div`
  display: grid;
  grid-template-columns: ${TABLE_COLUMN_WIDTHS.join(' ')};
  padding-left: 1rem;
`

const renderAllocationHistoryRows = (
  allocationHistory: PreTaxBenefitHistoryEntry[],
  isMobile: boolean
) => {
  return allocationHistory.map((entry) => {
    return (
      <TableRow key={entry.date} display='flex'>
        <TableRowCell width={TABLE_COLUMN_WIDTHS[0]} equalSize={false}>
          <Text variant='action4' hideOverflow>
            {moment(entry.date).format(`${isMobile ? 'MMM' : 'MMMM'} YYYY`)}
          </Text>
        </TableRowCell>
        <TableRowCell width={TABLE_COLUMN_WIDTHS[1]} equalSize={false}>
          {isMobile ? (
            <Text variant='action4' hideOverflow>
              {FormattingUtils.formatDollar(entry.employeeTransitDeduction, 0)}
            </Text>
          ) : (
            <Text variant='action4' hideOverflow>
              {`${FormattingUtils.formatDollar(
                entry.employeeTransitDeduction,
                0
              )} / ${FormattingUtils.formatDollar(
                PreTaxMonthlyMaximums.transit -
                  entry.employerTransitContribution,
                0
              )}`}
            </Text>
          )}
        </TableRowCell>
        <TableRowCell width={TABLE_COLUMN_WIDTHS[2]} equalSize={false}>
          {isMobile ? (
            <Text variant='action4' hideOverflow>
              {FormattingUtils.formatDollar(entry.employeeParkingDeduction, 0)}
            </Text>
          ) : (
            <Text variant='action4' hideOverflow>
              {`${FormattingUtils.formatDollar(
                entry.employeeParkingDeduction,
                0
              )} / ${FormattingUtils.formatDollar(
                PreTaxMonthlyMaximums.parking -
                  entry.employerParkingContribution,
                0
              )}`}
            </Text>
          )}
        </TableRowCell>
      </TableRow>
    )
  })
}

interface AllocationHistoryTableProps {
  isMobile: boolean
}

const AllocationHistoryTable = React.memo(
  (props: AllocationHistoryTableProps) => {
    const { isMobile } = props

    const { preTaxBenefitHistory } = useSelector(
      (state: AppState) => state.employeePlatform
    )

    return (
      <>
        {isMobile ? (
          <MobileTableHeader>
            <Text variant='action3' textColor={palette.text.secondary}>
              Month
            </Text>
            <Text variant='action3' textColor={palette.text.secondary}>
              Transit
            </Text>
            <Text
              variant='action3'
              textColor={palette.text.secondary}
              paddingLeft='1rem'
            >
              Parking
            </Text>
          </MobileTableHeader>
        ) : null}
        <Table hasHeader={false} maxRows={10} loadMoreAnimation={true}>
          <TableLabelRow padding='1rem 1.5rem' overflow='hidden'>
            <TableRowCell width={TABLE_COLUMN_WIDTHS[0]} equalSize={false}>
              Month
            </TableRowCell>
            <TableRowCell width={TABLE_COLUMN_WIDTHS[1]} equalSize={false}>
              Transit
            </TableRowCell>
            <TableRowCell width={TABLE_COLUMN_WIDTHS[2]} equalSize={false}>
              Parking
            </TableRowCell>
          </TableLabelRow>
          {preTaxBenefitHistory ? (
            renderAllocationHistoryRows(preTaxBenefitHistory, isMobile)
          ) : (
            <EmptyTableRow>No Allocation History</EmptyTableRow>
          )}
        </Table>
      </>
    )
  }
)

if (process.env.NODE_ENV !== 'production') {
  AllocationHistoryTable.displayName = 'AllocationHistoryTable'
}

export default AllocationHistoryTable
