import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import { AppState } from 'redux/config/store'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;

  &:hover {
    .headerLink {
      opacity: 1;
    }
  }
`

const TrackerContainer = styled.div`
  width: 100%;
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ChartContainer = styled.div`
  height: ${(props) => props.theme.pxToRem(52)};
  width: ${(props) => props.theme.pxToRem(52)};
  margin-bottom: 1rem;
`

const HeaderLink = styled(Link)`
  padding: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  height: 2rem;
  width: 2rem;
  display: flex;
  transform: rotate(270deg);
  z-index: 10;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple5};
  }

  &:active {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: ${(props) => props.theme.pxToRem(-83)};
  right: -17%;
  width: 69%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    visibility: hidden;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    visibility: visible;
    right: -29%;
  }
`

const getChartStyles = () => {
  return buildStyles({
    strokeLinecap: 'round',
    pathColor: '#FFE58B',
    trailColor: palette.grey.grey3,
  })
}

interface DashboardTrackerProps {
  totalEmployees: number
}

const DashboardTracker = React.memo((props: DashboardTrackerProps) => {
  const { totalEmployees } = props

  const employeesEngaged = useSelector(
    (state: AppState) => state.dashboard?.trackers?.employeesEngaged
  )

  if (!employeesEngaged) {
    return <Loading height={pxToRem(196)} marginBottom='1.5rem' />
  }

  const value = employeesEngaged.value
    ? Math.round(
        (Math.min(employeesEngaged.value, totalEmployees) / totalEmployees) *
          100
      )
    : 0

  return (
    <Container>
      <TrackerContainer>
        <Content>
          <HeaderContainer>
            <ChartContainer>
              <CircularProgressbarWithChildren
                styles={getChartStyles()}
                strokeWidth={10}
                counterClockwise={true}
                value={value}
              >
                <Text variant='action3'>{value}%</Text>
              </CircularProgressbarWithChildren>
            </ChartContainer>
            <HeaderLink
              to={Locations.Incentives.Programs}
              className='headerLink'
            >
              <ChevronIcon />
            </HeaderLink>
          </HeaderContainer>
          <Text variant='h5' marginBottom='0.5rem'>
            Workforce Engaged
          </Text>
          <Text
            variant='body2'
            textColor={palette.text.secondary}
            maxWidth={pxToRem(160)}
          >
            {`${
              employeesEngaged.value
                ? Math.min(employeesEngaged.value, totalEmployees)
                : 0
            } of your commuters have engaged in either a program OR incentive this month.`}
          </Text>
        </Content>
      </TrackerContainer>
      <ImageContainer>
        <img
          alt='engagementBackground'
          src={`${StaticAssetsUrl}/dashboard/engagement-background.png`}
        />
      </ImageContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardTracker.displayName = 'DashboardTracker'
}

export default DashboardTracker
