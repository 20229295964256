import { userActions } from 'redux/user/userSlice'
import PlatformPng from 'core-system/Icons/PlatformPng'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import { SubMenu } from 'features/Sidebar/SidebarShared'
import React, { Dispatch, useState } from 'react'
import { SyntheticEvent } from 'react-draft-wysiwyg'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Action } from 'redux'
import IntercomService from 'redux/config/services/IntercomService'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import { Employer } from 'redux/employer/employerTypes'
import { EmployeeProfile } from 'redux/employeePlatform/employeePlatformTypes'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'

const EmployerLogo = {
  movewithfleet: 'fleet-logo',
  sfmta: 'sfmta-logo',
}

const getAccountSubmenu = (
  platformType: 'employee' | 'employer',
  adminProfileGroups: string[] | null | undefined,
  employeeProfileGroups: string[] | null | undefined
) => {
  const submenu =
    platformType === 'employer'
      ? [
          {
            location: Locations.Accounts.Profile,
            title: 'Settings',
            metric: 'accounts-view',
          },
          {
            location: Locations.ProviderCatalog,
            title: 'Provider Catalog',
            metric: 'merchant-catalog',
          },
          // {
          //   location: Locations.GettingStarted,
          //   title: 'Getting Started',
          //   metric: 'getting-started',
          // },
          {
            location: 'intercom',
            title: 'Support',
            metric: 'intercom',
          },
          {
            location: Locations.Login,
            title: 'Logout',
            metric: 'logout',
          },
        ]
      : [
          {
            location: Locations.EmployeeSettings,
            title: 'Profile Settings',
            metric: 'profile-settings',
          },
          {
            location: Locations.Login,
            title: 'Logout',
            metric: 'logout',
          },
        ]

  if (platformType === 'employer') {
    return adminProfileGroups?.includes('Empusers')
      ? [
          ...submenu,
          {
            location: Locations.EmployeeDashboard,
            title: 'Employee Platform',
            metric: 'employee-platform',
          },
        ]
      : submenu
  } else {
    return employeeProfileGroups?.includes('HR_ADMIN') ||
      employeeProfileGroups?.includes('HR_USER')
      ? [
          ...submenu,
          {
            location: Locations.Dashboard,
            title: 'Admin Platform',
            metric: 'admin-platform',
          },
        ]
      : submenu
  }
}

const Container = styled.div<{ isVisible: boolean; isOnboarding: boolean }>`
  cursor: ${(props) => (props.isOnboarding ? null : 'pointer')};
  background-color: ${(props) =>
    props.isVisible
      ? props.theme.palette.secondary.purple5
      : props.theme.palette.white};
  position: relative;
  height: ${(props) => props.theme.pxToRem(51)};
  padding: 0.5rem;
  border-radius: 0.5rem;
`

const SubMenuContainer = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.tooltip};
  right: 0;
  padding-top: 0.5rem;
`

const SubMenuItems = styled.div`
  ${(props) => props.theme.typography.action4};
  display: flex;
  flex-direction: column;
  border: 0.0625rem solid ${(props) => props.theme.palette.grey.grey3};
  width: ${(props) => props.theme.pxToRem(180)};
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  padding: 0.5rem 0;
`

const SubMenuItem = styled(Link)`
  text-decoration: none;
  padding: 0.8125rem 1rem;
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: 500;
  line-height: 0.875rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple6};
  }
`

const renderSubMenu = (
  menu: SubMenu[],
  setMenuOpen: (open: boolean) => void,
  dispatch: Dispatch<Action>
) => {
  return menu.map((item: SubMenu, idx: number) => (
    <SubMenuItem
      key={idx}
      to={item.location !== 'intercom' ? item.location : '#'}
      id={
        item.location !== 'intercom' ? 'page-header-item' : 'intercom-launcher'
      }
      onClick={(e) =>
        handleOnClick(item.metric, item.location, setMenuOpen, dispatch, e)
      }
    >
      {item.title}
    </SubMenuItem>
  ))
}

const renderHeaderIcon = (
  platformType: 'employee' | 'employer',
  employerProfile: Employer | null,
  employeeProfileData: EmployeeProfile | null
) => {
  if (platformType === 'employer') {
    if (EmployerLogo[employerProfile?.name?.toLowerCase()]) {
      return (
        <PlatformPng
          folder='customer_logos'
          variant={EmployerLogo[employerProfile?.name.toLowerCase()]}
          style={{
            borderRadius: '50%',
          }}
          height={35}
        />
      )
    } else if (employerProfile?.logoUrl && employerProfile?.logoUrl !== '') {
      return (
        <img
          alt={employerProfile?.domain.split('.')[0]}
          src={employerProfile?.logoUrl}
          style={{
            borderRadius: '50%',
          }}
          height={35}
        />
      )
    }

    return (
      <PlatformSvg folder='offices' variant='office4' height={35} width={35} />
    )
  } else {
    if (employeeProfileData?.avatar && employeeProfileData?.avatar !== '') {
      return (
        <img
          alt={`${employeeProfileData?.firstName} ${employeeProfileData?.lastName}`}
          src={employeeProfileData?.avatar}
          style={{
            borderRadius: '50%',
          }}
          height={35}
        />
      )
    }

    return (
      <PlatformSvg folder='users' variant='userBasic' width={35} height={35} />
    )
  }
}

const handleOnClick = (
  stat: string,
  location: string,
  setIsVisible: (open: boolean) => void,
  dispatch: Dispatch<Action<any>>,
  e: SyntheticEvent
) => {
  e.stopPropagation()
  SegmentService.track('sidebar-item-click', {
    item: stat,
  })

  if (location === Locations.Login) {
    // IntercomService.shutdown()
    dispatch(userActions.logout())
  } else if (location === 'intercom') {
    IntercomService.show()
  } else if (location === Locations.Dashboard) {
    dispatch(userActions.getUser())
  } else if (location === Locations.EmployeeDashboard) {
    dispatch(employeePlatformActions.getProfileData())
  }

  setIsVisible(false)
}

interface PageHeaderIconProps {
  platformType: 'employee' | 'employer'
}

const PageHeaderIcon = React.memo((props: PageHeaderIconProps) => {
  const { platformType } = props

  const userProfile = useSelector((state: AppState) => state.user.profile)
  const { profile } = useSelector((state: AppState) => state.employer)
  const { profileData } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(false)
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= screenSizes.tablet
  )
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsTablet(width <= screenSizes.tablet)
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsTablet, setIsMobile]
  )

  useResizeListener(() => handleResize(window.innerWidth), [])

  const isOnboarding =
    location.pathname === Locations.EmployeeOnboarding ||
    location.pathname === Locations.GettingStarted ||
    location.pathname === Locations.QTF.ActivationOnboarding

  const accountSubMenu = getAccountSubmenu(
    platformType,
    userProfile?.groups,
    profileData?.types
  )

  const onIconClick = () => {
    SegmentService.track('sidebar-item-click', {
      platformType: platformType,
      item: 'logo',
    })
    if (isMobile || isTablet) {
      setIsVisible(!isVisible)
    } else if (platformType === 'employer') {
      location.pathname !== Locations.Accounts.Profile &&
        navigate(Locations.Accounts.Profile)
    } else {
      location.pathname !== Locations.EmployeeSettings &&
        navigate(Locations.EmployeeSettings)
    }
  }

  return (
    <Container
      id={platformType === 'employer' ? 'employer-icon' : 'employee-icon'}
      onMouseEnter={() => !isOnboarding && setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      onClick={() => !isOnboarding && onIconClick()}
      isVisible={isVisible}
      isOnboarding={isOnboarding}
    >
      {/* {EmployerLogo[profile?.name?.toLowerCase()] ? (
        <PlatformPng
          folder='customer_logos'
          variant={EmployerLogo[profile?.name.toLowerCase()]}
          style={{
            borderRadius: '50%',
          }}
          height={35}
        />
      ) : // Quick fix, adjust in the future to be a bit cleaner
      // May want to only use the logoUrl (avatar in backend) and remove hardcoded EmployerLogo dict
      profile.logoUrl && profile.logoUrl !== '' ? (
        <img
          alt={profile.domain.split('.')[0]}
          src={profile.logoUrl}
          style={{
            borderRadius: '50%',
          }}
          height={35}
        />
      ) : (
        <PlatformSvg
          folder='offices'
          variant='office4'
          height={35}
          width={35}
        />
      )} */}
      {renderHeaderIcon(platformType, profile, profileData)}
      {isVisible && (
        <SubMenuContainer>
          <SubMenuItems>
            {renderSubMenu(accountSubMenu, setIsVisible, dispatch)}
          </SubMenuItems>
        </SubMenuContainer>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PageHeaderIcon.displayName = 'PageHeaderIcon'
}

export default PageHeaderIcon
