import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import {
  CardProgram,
  Merchant,
  ServiceProvider,
} from 'redux/programs/programsTypes'
import MerchantUtils from 'shared/MerchantUtils'

export interface ProgramsState {
  serviceProviders: ServiceProvider[]
  serviceProviderMap: Dictionary<ServiceProvider>

  merchants: Merchant[]
  merchantsWithTransit: Merchant[]
  merchantsMap: Dictionary<Merchant>

  programs: CardProgram[]
  programsLoaded: boolean
}

export const initialState: ProgramsState = {
  serviceProviders: null,
  serviceProviderMap: null,

  merchants: null,
  merchantsWithTransit: null,
  merchantsMap: null,

  programs: null,
  programsLoaded: false,
}

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    getMerchantsSuccess(state, action: PayloadAction<Merchant[]>) {
      const groupedData = action.payload.reduce(
        (agg, merchant) => {
          agg.merchantsMap[merchant.id] = {
            ...merchant,
            metroAreas: ['USNewYorkMetropolitanArea', 'USSanFranciscoBayArea'],
          }

          const isTransit = merchant.modeTypes.some((m) =>
            ProgramUtils.transitModeTypes.includes(m)
          )
          const isDefaultMode = ProgramUtils.modeTypes.includes(
            merchant.name.toUpperCase()
          )

          if (!isTransit && !isDefaultMode) {
            agg.merchants.push(merchant)
          }
          if (!isDefaultMode) {
            agg.merchantsWithTransit.push(merchant)
          }

          return agg
        },
        {
          merchants: [],
          merchantsWithTransit: [],
          merchantsMap: {},
        }
      )

      state.merchants = groupedData.merchants
      state.merchantsWithTransit = groupedData.merchantsWithTransit
      state.merchantsMap = groupedData.merchantsMap
    },
    getAllServiceProvidersSuccess(
      state,
      action: PayloadAction<ServiceProvider[]>
    ) {
      const parsedPayload = action.payload.map((sp) => ({
        ...sp,
        country: sp.country && parseInt(sp.country),
      }))
      const combinedProviders = [
        ...parsedPayload,
        ...MerchantUtils.selfOwnedModes,
      ]

      return {
        ...state,
        serviceProviders: combinedProviders,
        serviceProviderMap: combinedProviders.reduce((agg, m) => {
          agg[m.id] = m
          return agg
        }, {}),
      }
    },
    getAllProgramsSuccess(state, action: PayloadAction<CardProgram[]>) {
      return {
        ...state,
        programsLoaded: true,
        programs: action.payload
          .filter((program) => program.status !== 'CANCELLED')
          .map((program) => {
            return {
              ...program,
              budget: parseInt(program.budget),
            }
          }),
      }
    },
  },
})

export const programsReducer = programsSlice.reducer
export const programsActions = { ...programsSlice.actions }
