import * as React from 'react'

/**
 * Attach a callback to browser global scroll event
 * @param callback: Callback function when scroll is triggered
 */
export default function (callback: (evt: React.UIEvent) => void): void {
  const scrollRef = React.useRef(null)
  React.useEffect(() => {
    scrollRef.current = callback
    window.addEventListener('scroll', scrollRef.current, true)
    return () => {
      window.removeEventListener('scroll', scrollRef.current, true)
    }
  }, [callback])
}
