import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { SpaceProps, space } from 'styled-system'
import { SizeProps, size } from '../@styled-system/Size'
import { buildComponent } from '../utils/buildComponent'
import Theme from '../Themes/Main'
import pxToRem from '../utils/pxToRem'
import {
  Editor,
  RawDraftContentState as _RawDraftContentState,
} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import refExists from '../../shared/refExists'

// Usefull links
// Docs: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
// Guide: https://blog.logrocket.com/building-rich-text-editors-in-react-using-draft-js-and-react-draft-wysiwyg/

const wrapperStyles = {
  ...Theme.typography.action4,
  color: Theme.palette.text.primary,
  border: `1px solid ${Theme.palette.grey.grey3}`,
  borderRadius: '0.5rem',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column-reverse' as const,
}

const editorStyles = {
  padding: '0.625rem 1rem',
  backgroundColor: Theme.palette.white,
  minHeight: `${pxToRem(140)}`,
  cursor: 'text',
}

const toolbarStyles = {
  border: 0,
  borderRadius: 0,
  margin: 0,
  backgroundColor: Theme.palette.grey.grey1,
  padding: '0.625rem 0.75rem',
  borderTop: `1px solid ${Theme.palette.grey.grey3}`,
  '> div': {
    margin: 0,
  },
}

const Container = styled.div<
  SpaceProps & SizeProps & { popupPosition: number }
>`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.secondary};
  min-width: ${(props) => props.theme.pxToRem(458)};
  .rdw-editor-toolbar > div {
    margin-bottom: 0;
  }
  .rdw-option-wrapper {
    border: 0;
    background: none;
    box-shadow: none !important;
    height: 1.5rem;
    min-width: 1.5rem;
    border-radius: 0.25rem;

    &:hover {
      background-color: ${(props) => props.theme.palette.secondary.purple5};
    }
  }

  .rdw-option-active {
    background-color: ${(props) => props.theme.palette.secondary.purple5};
  }

  .rdw-emoji-modal,
  .rdw-link-modal {
    top: auto;
    bottom: 1.75rem;
    height: ${(props) => props.theme.pxToRem(125)};
    overflow-y: auto;
    padding: 0.5rem;
    border-radius: 0.5rem;
    left: -${(props) => props.popupPosition}px;
    scrollbar-width: thin;

    /* width */
    ::-webkit-scrollbar {
      width: 0.25rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 1rem;
      opacity: 0.8;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
      opacity: 1;
    }
  }

  .rdw-link-modal {
    ${(props) => props.theme.typography.body2};
    input {
      padding: 0.5rem;
      border-radius: 0.5rem;
    }

    button {
      ${(props) => props.theme.typography.action4};
      box-shadow: none;
      background: white;
      border-radius: 0.5rem;
      color: ${(props) => props.theme.palette.primary.primaryPurple};
      border: 0.0625rem solid
        ${(props) => props.theme.palette.primary.primaryPurple};

      &:hover,
      :active {
        background-color: ${(props) => props.theme.palette.secondary.purple5};
      }

      :disabled {
        pointer-events: none;
        color: ${(props) => props.theme.palette.text.disabled};
        border-color: ${(props) => props.theme.palette.grey.grey2};
      }
    }
  }

  ${space}
  ${size}
`

const HelpText = styled.div`
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.palette.secondary.red1};
  margin-top: 0.25rem;
`

const Label = styled.div`
  margin-bottom: 0.25rem;
`

const getWrapperStyles = (hover: boolean, focus: boolean, invalid: boolean) => {
  if (hover || focus) {
    return {
      ...wrapperStyles,
      border: `1px solid ${
        invalid ? Theme.palette.secondary.red1 : Theme.palette.text.secondary
      }`,
    }
  }
  return {
    ...wrapperStyles,
    border: `1px solid ${
      invalid ? Theme.palette.secondary.red1 : Theme.palette.grey.grey3
    }`,
  }
}

const getEditorStyles = (focused: boolean, invalid: boolean) => {
  if (!focused) {
    return {
      ...editorStyles,
      backgroundColor: invalid
        ? Theme.palette.secondary.red2
        : Theme.palette.secondary.purple7,
    }
  }
  return {
    ...editorStyles,
    backgroundColor: invalid
      ? Theme.palette.secondary.red2
      : Theme.palette.white,
  }
}

const getToolbarStyles = (focused: boolean) => {
  if (focused) {
    return toolbarStyles
  }

  return {
    ...toolbarStyles,
    opacity: 0.6,
  }
}

export interface RawDraftContentState extends _RawDraftContentState {}
export interface TextEditorProps extends SpaceProps, SizeProps {
  oncontentStateChange: (contentState: RawDraftContentState) => void
  contentState: RawDraftContentState
  label?: string
  readOnly?: boolean
  invalid?: boolean
  helpText?: string
  hideToolbar?: boolean
}

const TextEditor = (props: TextEditorProps) => {
  const {
    label,
    oncontentStateChange,
    contentState,
    readOnly,
    invalid,
    helpText,
    hideToolbar,
  } = props
  const [focus, setFocus] = useState(false)
  const [hover, setHover] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [popupPosition, setPopupPosition] = useState<number>(0)

  React.useEffect(() => {
    if (refExists(containerRef.current)) {
      const rect = containerRef.current.getBoundingClientRect()
      setPopupPosition(Math.round(rect.width * 0.28))
    }
  }, [containerRef])

  return (
    <Container
      {...props}
      ref={containerRef}
      popupPosition={popupPosition}
      onMouseEnter={() => !readOnly && setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {label && <Label>{label}</Label>}
      <Editor
        defaultContentState={contentState}
        onContentStateChange={oncontentStateChange}
        readOnly={readOnly}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        wrapperStyle={getWrapperStyles(hover, focus, invalid)}
        editorStyle={getEditorStyles(focus, invalid)}
        toolbarStyle={getToolbarStyles(focus)}
        toolbar={{
          options: ['inline', 'list', 'link', 'emoji'],
          inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] },
          link: { options: ['link'] },
        }}
        stripPastedStyles={true}
        toolbarHidden={hideToolbar ? true : false}
      />
      {invalid && helpText && <HelpText>{helpText}</HelpText>}
    </Container>
  )
}

export default buildComponent(TextEditor, 'TextEditor')
