import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M11.9348 1H1.5V11.4348H11.9348V1Z' fill='#500F28' />
    <path d='M23.4131 1H12.9783V11.4348H23.4131V1Z' fill='#500F28' />
    <path d='M1.5 12.4783H11.9348V22.9131H1.5V12.4783Z' fill='#500F28' />
    <path
      d='M23.4131 12.4783H12.9783V22.9131H23.4131V12.4783Z'
      fill='#500F28'
    />
  </>,
  'Microsoft'
)
