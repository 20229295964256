import React, { useState } from 'react'
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'core-system/Modal'
import { CarpoolRequestToJoinPayload } from 'redux/employeePlatform/employeePlatformTypes'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import pxToRem from 'core-system/utils/pxToRem'
import Button from 'core-system/Button'
import { useDispatch } from 'react-redux'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import styled from 'styled-components'
import AddressAutocomplete from 'core-system/Map/AddressAutocomplete/AddressAutocomplete'
import TextEditor, { RawDraftContentState } from 'core-system/TextEditor'
import { ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import Checkbox from 'core-system/Checkbox'
import Text from 'core-system/Text'
import EditIcon from 'core-system/Icons/Actions/Edit'
import FlexContainer from 'core-system/FlexContainer'
import palette from 'core-system/Themes/palette'
import CloseIcon from 'core-system/Icons/Actions/Close'
import Dropdown from 'core-system/Dropdown'
import { LocationDisclaimers, RoleDropDownItems } from '../CarpoolUtils'
import { Result } from 'core-system/Map/AddressAutocomplete/AddressAutocomplete'
import { AutocompleteResult } from 'core-system/Map/AddressAutocomplete/AddressAutocompleteField'

const rawDraftToString = (raw: RawDraftContentState) => {
  return draftToHtml(raw)
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&nbsp;/g, ' ')
    .trim()
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const LocationContainer = styled.div<{
  alignment: 'flex-start' | 'center' | 'flex-end'
}>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: ${(props) => props.alignment};
`

const EditIconContainer = styled(FlexContainer)`
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple6};
  }
`

const renderLocationField = (
  location: string | null,
  header: string,
  onClick: (state: any) => void,
  type: 'source' | 'dest'
) => {
  return (
    <FlexContainer flexDirection='column'>
      <Text variant='body1' textColor={palette.text.secondary}>
        {header}
      </Text>
      <LocationContainer alignment='center'>
        <Text variant='action3' hideOverflow>
          {location}
        </Text>
        <EditIconContainer>
          <EditIcon
            color={palette.text.placeholder}
            onClick={() => onClick(type)}
          />
        </EditIconContainer>
      </LocationContainer>
    </FlexContainer>
  )
}

const renderAddressInput = (
  label: string,
  setCoords: (coords: number[]) => void,
  handleButtonClick: (type: 'source' | 'dest') => void,
  type: 'source' | 'dest',
  hasSearchedAddress: boolean
) => {
  return (
    <LocationContainer alignment='flex-end'>
      <AddressAutocomplete
        label={label}
        onActiveChange={(location: Result) => setCoords(location.coordinates)}
        hasError={false}
      />
      {hasSearchedAddress ? (
        <FlexContainer marginBottom='0.25rem'>
          <EditIconContainer>
            <CloseIcon
              color={palette.text.placeholder}
              onClick={() => handleButtonClick(type)}
            />
          </EditIconContainer>
        </FlexContainer>
      ) : null}
    </LocationContainer>
  )
}

interface CarpoolRequestModalProps {
  open: boolean
  closeModal: () => void
  selectedCarpool: string | null
  setSelectedCarpool: (groupId: string | null) => void
  source: AutocompleteResult | null
  destination: AutocompleteResult | null
}

const CarpoolRequestModal = React.memo((props: CarpoolRequestModalProps) => {
  const {
    open,
    closeModal,
    selectedCarpool,
    setSelectedCarpool,
    source,
    destination,
  } = props

  const dispatch = useDispatch()

  const { profileData, allCarpoolGroups } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const [role, setRole] = useState<null | string>(null)
  const [sourceEditing, setSourceEditing] = useState(false)
  const [destEditing, setDestEditing] = useState(false)
  const [sourceCoords, setSourceCoords] = useState(
    source?.coordinates ? source.coordinates : null
  )
  const [destCoords, setDestCoords] = useState(
    destination?.coordinates ? destination.coordinates : null
  )
  const [customMessage, setCustomMessage] = useState<RawDraftContentState>(
    convertToRaw(ContentState.createFromText(''))
  )
  const [vanpoolInterest, setVanpoolInterest] = useState(false)

  const clearFields = () => {
    setSelectedCarpool(null)
    setRole(null)
    setSourceCoords(null)
    setDestCoords(null)
    setCustomMessage(convertToRaw(ContentState.createFromText('')))
    setVanpoolInterest(false)
  }

  const handleCloseModal = () => {
    clearFields()
    closeModal()
  }

  const handleSubmit = () => {
    const payload: CarpoolRequestToJoinPayload = {
      source: { type: 'Point', coordinates: sourceCoords },
      dest: { type: 'Point', coordinates: destCoords },
      workArrivalTime: allCarpoolGroups[selectedCarpool]?.workArrivalTime,
      workDepartureTime: allCarpoolGroups[selectedCarpool]?.workDepartureTime,
      contactInfo: profileData.email,
      driver: role === 'passenger' ? false : true,
      passenger: role === 'driver' ? false : true,
      customMessage:
        rawDraftToString(customMessage) === ''
          ? 'I would like to join your carpool!'
          : rawDraftToString(customMessage),
      vanpoolInterest: vanpoolInterest,
    }

    dispatch(
      employeePlatformActions.requestToJoinCarpool({
        carpoolGroupId: selectedCarpool,
        payload: payload,
      })
    )

    handleCloseModal()
  }

  const handleEditToggleClick = (type: 'source' | 'dest') => {
    if (type === 'source') {
      setSourceCoords(!sourceCoords && source ? source.coordinates : null)
      setSourceEditing(!sourceEditing)
    } else {
      setDestCoords(!destCoords && destination ? destination.coordinates : null)
      setDestEditing(!destEditing)
    }
  }

  return (
    <Modal open={open} onClose={() => handleCloseModal()} width={pxToRem(450)}>
      <ModalHeader title='Request to Join Carpool' />
      <ModalBody>
        <FormContainer>
          <FlexContainer flexDirection='column'>
            <Dropdown
              label='I want to be a...'
              items={RoleDropDownItems}
              active={role}
              itemCallback={(item) => setRole(item.id)}
              placeholder='Select a role'
              usePortal
            />
          </FlexContainer>
          {sourceEditing || !source
            ? renderAddressInput(
                'Departure Location *',
                setSourceCoords,
                handleEditToggleClick,
                'source',
                source ? true : false
              )
            : renderLocationField(
                source?.address,
                'Departing From',
                handleEditToggleClick,
                'source'
              )}
          {destEditing || !destination
            ? renderAddressInput(
                'Destination *',
                setDestCoords,
                handleEditToggleClick,
                'dest',
                destination ? true : false
              )
            : renderLocationField(
                destination?.address,
                'Destination',
                handleEditToggleClick,
                'dest'
              )}
          <Text variant='body2' textColor={palette.text.placeholder}>
            {`*${LocationDisclaimers.RequestCreation}`}
          </Text>
          <TextEditor
            label='Custom Message (optional)'
            contentState={customMessage}
            oncontentStateChange={(contentState) =>
              setCustomMessage(contentState)
            }
            minWidth={pxToRem(250)}
            hideToolbar
            data-cy='custom-carpool-request-message'
          />
          <Checkbox
            checked={vanpoolInterest}
            onClick={() => setVanpoolInterest(!vanpoolInterest)}
            label='Add me to the vanpool interest list'
          />
        </FormContainer>
      </ModalBody>
      <ModalFooter>
        <Button variant='tertiary' onClick={() => handleCloseModal()}>
          Cancel
        </Button>
        <Button
          disabled={
            !sourceCoords ||
            !destCoords ||
            !role ||
            !allCarpoolGroups[selectedCarpool]?.workArrivalTime ||
            !allCarpoolGroups[selectedCarpool]?.workDepartureTime
          }
          onClick={() => handleSubmit()}
          marginLeft='1rem'
        >
          Submit Request
        </Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolRequestModal.displayName = 'CarpoolRequestModal'
}

export default CarpoolRequestModal
