import { AnyAction } from 'redux'
import { all, call, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { userActions } from 'redux/user/userSlice'
import UserService from 'redux/user/userService'
import {
  LoginPayload,
  SocialLoginPayload,
  UserApiType,
} from 'redux/user/userTypes'
import { Action } from 'shared/Types/Actions'

/**************************************
 * Sagas
 **************************************/

export function* login(action: Action<LoginPayload>) {
  yield defaultFlow(action, UserService.login, userActions.loginSuccess)
}

export function* getUser(action: AnyAction) {
  yield defaultFlow(action, UserService.getAuthUser, userActions.getUserSuccess)
}

export function* socialLogin(action: Action<SocialLoginPayload>) {
  yield defaultFlow(
    action,
    UserService.socialLogin,
    userActions.socialLoginSuccess
  )
}

export function socialSignup(action: Action<SocialLoginPayload>) {
  return defaultFlow(
    action,
    UserService.socialSignup,
    userActions.socialSignupSuccess
  )
}

export function* updateUser(action: Action<UserApiType>) {
  yield defaultFlow(
    action,
    UserService.updateUser,
    userActions.updateUserSuccess
  )
}

export function* logout() {
  yield call(UserService.logout)
}

/**************************************
 * Watchers
 **************************************/

export function* watchLogin() {
  yield takeEvery(userActions.login.type, login)
}

export function* watchSocialLogin() {
  yield takeEvery(userActions.socialLogin.type, socialLogin)
}

export function* watchSocialSignup() {
  yield takeEvery(userActions.socialSignup.type, socialSignup)
}

export function* watchLogout() {
  yield takeEvery(
    [userActions.logout.type, userActions.getUserFailed.type],
    logout
  )
}

export function* watchGetUser() {
  yield takeEvery([userActions.getUser.type], getUser)
}

export function* watchUpdateUser() {
  yield takeEvery([userActions.updateUser.type], updateUser)
}

export default function* () {
  yield all([
    watchLogin(),
    watchSocialLogin(),
    watchSocialSignup(),
    watchLogout(),
    watchGetUser(),
    watchUpdateUser(),
  ])
}
