import Button from 'core-system/Button'
import ColumnView, { Column, ColumnCard } from 'core-system/ColumnView'
import DatePicker from 'core-system/DatePicker'
import Dropdown from 'core-system/Dropdown'
import EditIcon from 'core-system/Icons/Actions/Edit'
import Loading from 'core-system/Loading'
import Radio from 'core-system/Radio'
import TextEditor, { RawDraftContentState } from 'core-system/TextEditor'
import TextField from 'core-system/TextField'
import TextToggle from 'core-system/TextToggle'
import pxToRem from 'core-system/utils/pxToRem'
import { endOfDay, isSameDay, startOfToday } from 'date-fns'
import { ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { conciergeActions } from 'redux/concierge/conciergeSlice'
import {
  Announcement,
  AnnouncementPlatform,
} from 'redux/concierge/conciergeTypes'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import MobileAppPreview from './MobileAppPreview'
import SlackPreview from './SlackPreview'

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem ${(props) => props.theme.pxToRem(52)};
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 5rem);
  box-shadow: ${(props) => props.theme.dropShadows.bottom};
  z-index: ${(props) => props.theme.zIndex.max};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
  }
`

const Content = styled.div`
  padding-bottom: 15rem;
`

const ButtonContainer = styled.div`
  display: flex;
`

const CardTitle = styled.div<SpaceProps>`
  ${(props) => props.theme.typography.action2}
  ${space}
`

const CardSubtitle = styled.div`
  ${(props) => props.theme.typography.body1}
  margin-bottom: 1.5rem;
`

const PreviewSubtitle = styled(CardSubtitle)`
  padding-bottom: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
  margin-bottom: 2rem;
`

const RadioContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  color: ${(props) => props.theme.palette.text.primary};
`

const Title = styled.div`
  ${(props) => props.theme.typography.action1}
  margin-right: 0.5rem;
`

const Label = styled.div`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: 0.25rem;
`

const DatePickerContainer = styled.div`
  margin-bottom: 1.5rem;
`

const StyledColumnView = styled(ColumnView)`
  grid-template-columns: repeat(2, 50%);

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    max-width: ${(props) => props.theme.pxToRem(700)};
    grid-gap: unset;
  }
`

const renderPreview = (
  title: string,
  description: RawDraftContentState,
  activeId: number
) => {
  switch (activeId) {
    case 0:
      return <SlackPreview title={title} description={description} />
    case 1:
      return <MobileAppPreview title={title} description={description} />
    default:
      return null
  }
}

// get next hour
const getDefaultDate = () => {
  const date = new Date()
  date.setMinutes(0)
  date.setHours(date.getHours() + 1)
  return date
}

const rawDraftToString = (raw: RawDraftContentState) => {
  return draftToHtml(raw)
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&nbsp;/g, ' ')
    .trim()
}

const defaultValidation = {
  title: true,
  description: true,
}

type LocationState = {
  location: string
  announcement: Announcement
  status?: string
}

const AnnouncementsView = React.memo(() => {
  const { conciergeSuccessAction, announcements } = useSelector(
    (state: AppState) => state.concierge
  )
  const { allSegments, allEmployeeSegmentId, segmentsMap } = useSelector(
    (state: AppState) => state.employer
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as LocationState

  const draftJs =
    state && state.announcement && htmlToDraft(state.announcement.htmlBody)
  const contentState = draftJs
    ? ContentState.createFromBlockArray(
        draftJs.contentBlocks,
        draftJs.entityMap
      )
    : ContentState.createFromText('')
  const raw = convertToRaw(contentState)

  const [title, setTitle] = useState(
    state && state.announcement ? state.announcement.title : ''
  )
  const [description, setDescription] = useState<RawDraftContentState>(raw)
  const [selectedSegment, setSelectedSegment] = useState(allEmployeeSegmentId)
  const [sendNow, setSendNow] = useState(true)
  const [formValidation, setFormValidation] = useState(defaultValidation)
  const [selectedDate, setSelectedDate] = useState(getDefaultDate())
  const [selectedPreview, setSelectedPreview] = useState(0)

  const date = new Date()
  const minTime = isSameDay(selectedDate, date)
    ? getDefaultDate()
    : startOfToday()

  useEffect(() => {
    if (!announcements) {
      dispatch(conciergeActions.getAnnouncements())
    }
  }, [announcements, dispatch])

  const handleSegmentChange = useCallback(({ id }) => {
    setSelectedSegment(id)
  }, [])

  const handlePreviewChange = useCallback(
    (id: number) => {
      setSelectedPreview(id)
    },
    [setSelectedPreview]
  )

  const handleCreateAnnouncement = (isDraft: boolean) => {
    const isTitleValid = title.trim() !== ''
    const isDescriptionValid = rawDraftToString(description) !== ''

    if (!isTitleValid || !isDescriptionValid) {
      setFormValidation({
        title: isTitleValid,
        description: isDescriptionValid,
      })
    } else {
      const announcement = {
        cancel: false,
        segment: selectedSegment,
        title: title,
        htmlBody: draftToHtml(description),
        distributionPlatforms: [
          'Slack',
          'App',
          'Email',
        ] as AnnouncementPlatform[],
        scheduledAt: sendNow ? null : String(selectedDate),
        draft: isDraft,
      }

      if (
        isDraft &&
        state.announcement &&
        state.announcement.status === 'Draft' &&
        state.status === 'draft'
      ) {
        //update a draft
        dispatch(
          conciergeActions.updateAnnouncement({
            ...state.announcement,
            title: announcement.title,
            htmlBody: announcement.htmlBody,
            scheduledAt: String(announcement.scheduledAt),
            segment: announcement.segment,
            cancel: false,
            draft: true,
          })
        )
      } else if (
        state.announcement &&
        state.announcement.status === 'Draft' &&
        state.status !== 'new' &&
        !isDraft
      ) {
        //promote a draft to a scheduled/sent announcement
        dispatch(
          conciergeActions.updateAnnouncement({
            ...state.announcement,
            title: announcement.title,
            htmlBody: announcement.htmlBody,
            scheduledAt: String(announcement.scheduledAt),
            segment: announcement.segment,
            cancel: false,
            draft: false,
          })
        )
        SegmentService.track('announcements-create-click', {
          state: 'scheduled',
          titleLength: title.length,
          descriptionLength: announcement.htmlBody.length,
          scheduledAt: sendNow
            ? 'asap'
            : moment(selectedDate).format('DD-MM-YYYY h:mm'),
          segmentsMap: segmentsMap[selectedSegment].name || null,
        })
      } else {
        //check if scheduled time has already passed
        if (!sendNow && selectedDate < new Date()) {
          dispatch(
            notificationsActions.generalPageError(
              'Schedule time has already passed! Please select a new schedule date'
            )
          )
        } else {
          //create a new announcement
          dispatch(conciergeActions.createAnnouncement(announcement))
          SegmentService.track('announcements-create-click', {
            state: isDraft ? 'draft' : 'scheduled',
            titleLength: title.length,
            descriptionLength: announcement.htmlBody.length,
            scheduledAt: sendNow
              ? 'asap'
              : moment(selectedDate).format('DD-MM-YYYY h:mm'),
            segmentsMap: segmentsMap[selectedSegment].name || null,
          })
        }
      }
    }
  }

  useEffect(() => {
    if (conciergeSuccessAction) {
      dispatch(conciergeActions.cancelConciergeSuccess())
      navigate(`/concierge`, {
        state: { from: 'announcement' },
      })
    }
  }, [conciergeSuccessAction, dispatch, navigate])

  const handleDescriptionChange = (contentState: RawDraftContentState) => {
    setDescription(contentState)
  }

  const handleDateChange = (selectedDate) => {
    if (selectedDate > new Date()) setSelectedDate(selectedDate)
  }

  const handleCancel = useCallback(() => {
    navigate(`/concierge`, {
      state: { from: 'announcement' },
    })
    SegmentService.track('announcements-cancel-click')
  }, [navigate])

  const segmentItems = allSegments.map((segment: Segment) => {
    return {
      id: segment.id,
      text: `${segment.name} (${segment.commuters} Commuters)`,
    }
  })

  return (
    <>
      <Content>
        <TitleContainer>
          <Title>
            {state &&
            state.announcement &&
            state.announcement.status === 'Draft' &&
            state.status === 'draft'
              ? 'Edit Announcement'
              : 'New Announcement'}
          </Title>
          <EditIcon />
        </TitleContainer>
        <StyledColumnView>
          <Column>
            <ColumnCard>
              <CardTitle marginBottom={'1.5rem'}>Announcement</CardTitle>
              <TextField
                label={'Title'}
                value={title}
                maxLength={200}
                onChange={(e) => setTitle(e.currentTarget.value.trimStart())}
                invalid={!formValidation.title && title === ''}
                helpText={
                  !formValidation.title && title === ''
                    ? 'Please enter a title'
                    : ''
                }
                data-cy='announcement-title'
              />
              <TextEditor
                marginTop={'1.5rem'}
                minWidth={pxToRem(379)}
                label={'Description'}
                contentState={description}
                oncontentStateChange={handleDescriptionChange}
                invalid={
                  !formValidation.description &&
                  rawDraftToString(description) === ''
                }
                helpText={
                  !formValidation.description &&
                  rawDraftToString(description) === ''
                    ? 'Please enter a description'
                    : ''
                }
                width={'100%'}
                data-cy='announcement-body'
              />
            </ColumnCard>
            {allSegments ? (
              <ColumnCard>
                <CardTitle marginBottom={'0.5rem'}>Settings</CardTitle>
                <CardSubtitle>
                  Set which commuters you&apos;d like to deliver this
                  announcement to and when.
                </CardSubtitle>
                <RadioContainer>
                  <Radio
                    active={sendNow}
                    label={'Send Now'}
                    marginRight={'2rem'}
                    onClick={() => setSendNow(true)}
                  />
                  <Radio
                    id='send-later-radio'
                    active={!sendNow}
                    label={'Send Later'}
                    onClick={() => setSendNow(false)}
                  />
                </RadioContainer>
                {!sendNow && (
                  <DatePickerContainer>
                    <Label>Date &amp; Time</Label>
                    <DatePicker
                      id='date-picker'
                      onChange={handleDateChange}
                      selected={selectedDate}
                      shouldCloseOnSelect={true}
                      minDate={new Date()}
                      minTime={minTime}
                      maxTime={endOfDay(selectedDate)}
                      dateFormat='MMMM d, yyyy, h:mm aa'
                      timeIntervals={30}
                      fixedHeight
                      showTimeSelect
                      required={true}
                      popperModifiers={{
                        flip: {
                          enabled: false,
                        },
                        preventOverflow: {
                          escapeWithReference: true,
                        },
                      }}
                    />
                  </DatePickerContainer>
                )}
                <Dropdown
                  active={selectedSegment}
                  itemCallback={handleSegmentChange}
                  items={segmentItems}
                  label='Group'
                  marginBottom='1.5rem'
                  width='100%'
                  selectorMaxHeight={pxToRem(230)}
                />
              </ColumnCard>
            ) : (
              <Loading height={pxToRem(292)} marginBottom={'1rem'} />
            )}
          </Column>
          <Column>
            <ColumnCard>
              <CardTitle marginBottom={'0.5rem'}>Preview</CardTitle>
              <PreviewSubtitle>
                Fill out the information on the details of your announcement to
                see a live preview of what it will look like for your commuters.
              </PreviewSubtitle>
              <TextToggle
                options={['Slack', 'Mobile App']}
                toggled={selectedPreview}
                onClick={handlePreviewChange}
                size={'small'}
                variant={'light'}
                optionWidth={5.5}
                marginBottom={'2rem'}
                display={'flex'}
                justifyContent={'center'}
              />
              {renderPreview(title, description, selectedPreview)}
            </ColumnCard>
          </Column>
        </StyledColumnView>
      </Content>
      <Footer>
        <Button variant='tertiary' onClick={handleCancel}>
          Cancel
        </Button>
        <ButtonContainer>
          <Button
            onClick={() => handleCreateAnnouncement(true)}
            variant={'tertiary'}
            marginRight={'1rem'}
          >
            Save as Draft
          </Button>
          <Button onClick={() => handleCreateAnnouncement(false)}>
            {sendNow ? 'Send' : 'Schedule'}
          </Button>
        </ButtonContainer>
      </Footer>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AnnouncementsView.displayName = 'AnnouncementsView'
}

export default AnnouncementsView
