import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M13.1623 1.20689L16.3741 1.19998L16.3999 2.30403V4.66976L5.91324 15.3838L2.5999 11.9985L13.1623 1.20689Z' />
    <path d='M16.3945 13.5951L13.4956 16.5569L16.3996 19.524V22.8856L13.2569 22.8871L7.03928 16.5347L13.1772 10.2636L16.3737 10.241L16.3945 13.5951Z' />
  </>,
  'FleetAutomated'
)
