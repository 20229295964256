import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='52' height='52' rx='26' fill='#F0F0FF' />
    <path
      d='M37.2282 17.9467C37.2282 18.6571 36.6469 19.2267 35.922 19.2267C35.1971 19.2267 34.6159 18.6571 34.6159 17.9467C34.6159 17.2363 35.1971 16.6667 35.922 16.6667C36.6469 16.6667 37.2282 17.2363 37.2282 17.9467Z'
      fill='#5E5EAA'
    />
    <path
      d='M36.3792 22.6095L33.7715 20.5813C33.9854 20.1588 34.4308 19.8667 34.9424 19.8667H36.9016C37.225 19.8667 37.5219 19.9834 37.7506 20.1759V21.6952L36.3792 22.6095Z'
      fill='#5E5EAA'
    />
    <path
      d='M15.75 21.9167C15.75 22.607 15.1904 23.1667 14.5 23.1667C13.8096 23.1667 13.25 22.607 13.25 21.9167C13.25 21.2263 13.8096 20.6667 14.5 20.6667C15.1904 20.6667 15.75 21.2263 15.75 21.9167Z'
      fill='#5E5EAA'
    />
    <path
      d='M22.5 23.1667C23.1903 23.1667 23.75 22.607 23.75 21.9167C23.75 21.2263 23.1903 20.6667 22.5 20.6667C21.8096 20.6667 21.25 21.2263 21.25 21.9167C21.25 22.607 21.8096 23.1667 22.5 23.1667Z'
      fill='#5E5EAA'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.6761 24.069L29.8837 18.2765C29.44 17.8328 28.8283 17.581 28.1927 17.581H12.3985C11.0793 17.581 10 18.6483 10 19.9795V28.3743C10 29.6935 11.0793 30.7728 12.3985 30.7728C12.3985 32.7636 14.0055 34.3706 15.9963 34.3706C17.9871 34.3706 19.5941 32.7636 19.5941 30.7728H26.7896C26.7896 32.7636 28.3966 34.3706 30.3874 34.3706C32.3781 34.3706 33.9851 32.7636 33.9851 30.7728C35.3043 30.7728 36.3837 29.6935 36.3837 28.3743V25.7719C36.3837 25.1363 36.1318 24.5247 35.6761 24.069ZM12.3985 23.5773V21.1787C12.3985 20.5192 12.9382 19.9795 13.5978 19.9795H17.1955V24.7765H16.9689C16.8316 24.1445 16.2557 23.6667 15.5714 23.6667H13.4286C13.067 23.6667 12.7357 23.8001 12.4833 24.0191C12.4286 23.8822 12.3985 23.7331 12.3985 23.5773ZM15.9963 32.2719C15.1688 32.2719 14.4972 31.6003 14.4972 30.7728C14.4972 29.9453 15.1688 29.2737 15.9963 29.2737C16.8238 29.2737 17.4954 29.9453 17.4954 30.7728C17.4954 31.6003 16.8238 32.2719 15.9963 32.2719ZM23.5714 23.6667C23.8758 23.6667 24.1587 23.7612 24.3911 23.9218V19.9795H19.5941V24.7765H20.0311C20.1683 24.1445 20.7443 23.6667 21.4286 23.6667H23.5714ZM30.3874 32.2719C29.5599 32.2719 28.8883 31.6003 28.8883 30.7728C28.8883 29.9453 29.5599 29.2737 30.3874 29.2737C31.2149 29.2737 31.8864 29.9453 31.8864 30.7728C31.8864 31.6003 31.2149 32.2719 30.3874 32.2719ZM26.7896 24.7765V19.9795H27.9889L32.7859 24.7765H26.7896Z'
      fill='#5E5EAA'
    />
    <path
      d='M41.0204 21.6038C41.0204 22.3142 40.4392 22.8838 39.7143 22.8838C38.9894 22.8838 38.4082 22.3142 38.4082 21.6038C38.4082 20.8934 38.9894 20.3238 39.7143 20.3238C40.4392 20.3238 41.0204 20.8934 41.0204 21.6038Z'
      fill='#5E5EAA'
    />
    <path
      d='M41.0204 28.6438V33.1238H38.4082V28.6438H37.4286V24.8038C37.4286 24.0998 38.0163 23.5238 38.7347 23.5238H40.6939C41.4122 23.5238 42 24.0998 42 24.8038V28.6438H41.0204Z'
      fill='#5E5EAA'
    />
  </>,
  'AlternativeModes',
  '0 0 52 52'
)
