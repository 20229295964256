import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.24099 8.39164L4.9603 6.4232V2.6661H6.2239V5.76407L8.88164 7.34097L8.24099 8.39164ZM5.72975 0C2.56376 0 0 2.5695 0 5.73548C0 8.90149 2.56376 11.471 5.72975 11.471C8.90147 11.471 11.471 8.90149 11.471 5.73548C11.471 2.5695 8.90147 0 5.72975 0V0Z'
        fill='#6200E0'
      />
    </svg>
  </>,
  'BasicClock',
  '0 0 12 12'
)
