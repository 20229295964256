import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <rect
      x='79.9861'
      y='29.3837'
      width='2.35832'
      height='2.35832'
      fill='#16014A'
    />
    <rect
      x='76.4486'
      y='28.9908'
      width='9.43327'
      height='0.786106'
      fill='#16014A'
    />
    <path
      d='M77.4707 18.6678L85.882 28.9907H76.4487L69.8522 19.6168C69.4857 19.096 69.8582 18.3783 70.4951 18.3783H76.8613C77.0976 18.3783 77.3214 18.4846 77.4707 18.6678Z'
      fill='#BEB3FF'
    />
    <circle cx='81.1653' cy='40.3893' r='9.43327' fill='#F6C892' />
    <circle
      opacity='0.1'
      cx='81.1653'
      cy='40.3893'
      r='5.50274'
      fill='#733E00'
    />
    <path
      d='M84.8598 18.6678L76.4486 28.9907H85.8819L92.4783 19.6168C92.8449 19.096 92.4723 18.3783 91.8355 18.3783H85.4693C85.2329 18.3783 85.0091 18.4846 84.8598 18.6678Z'
      fill='#DBDBFF'
    />
    <rect
      x='11.6554'
      y='29.3837'
      width='2.35832'
      height='2.35832'
      fill='#16014A'
    />
    <rect
      x='8.11804'
      y='28.9908'
      width='9.43327'
      height='0.786106'
      fill='#16014A'
    />
    <path
      d='M9.14003 18.6678L17.5513 28.9907H8.118L1.52154 19.6168C1.15502 19.096 1.52755 18.3783 2.16442 18.3783H8.53061C8.76695 18.3783 8.99074 18.4846 9.14003 18.6678Z'
      fill='#BEB3FF'
    />
    <circle cx='12.8346' cy='40.3893' r='9.43327' fill='#E3E3EC' />
    <circle
      opacity='0.1'
      cx='12.8346'
      cy='40.3893'
      r='5.50274'
      fill='#5E5EAA'
    />
    <path
      d='M16.5293 18.6678L8.11804 28.9907H17.5513L24.1478 19.6168C24.5143 19.096 24.1418 18.3783 23.5049 18.3783H17.1387C16.9024 18.3783 16.6786 18.4846 16.5293 18.6678Z'
      fill='#DBDBFF'
    />
    <rect
      x='43.4146'
      y='18.4427'
      width='3.87097'
      height='3.87097'
      fill='#16014A'
    />
    <rect
      x='37.608'
      y='17.7976'
      width='15.4839'
      height='1.29032'
      fill='#16014A'
    />
    <path
      d='M39.2856 0.853496L53.0919 17.7976H37.608L26.7805 2.41113C26.1789 1.55623 26.7904 0.378235 27.8358 0.378235H38.2853C38.6732 0.378235 39.0406 0.552764 39.2856 0.853496Z'
      fill='#BEB3FF'
    />
    <circle cx='45.35' cy='36.5073' r='15.4839' fill='#FFE58B' />
    <circle opacity='0.1' cx='45.35' cy='36.5073' r='9.03226' fill='#7C6203' />
    <path
      d='M51.4143 0.853496L37.608 17.7976H53.0919L63.9194 2.41113C64.521 1.55623 63.9095 0.378235 62.8642 0.378235H52.4146C52.0267 0.378235 51.6594 0.552764 51.4143 0.853496Z'
      fill='#DBDBFF'
    />
    <path
      d='M63.65 30.6782L64.35 29.3782L65.05 30.6782L66.35 31.3782L65.05 32.0782L64.35 33.3782L63.65 32.0782L62.35 31.3782L63.65 30.6782Z'
      fill='#BEB3FF'
    />
    <path
      d='M26.35 20.3782L25.3 22.3282L23.35 23.3782L25.3 24.4282L26.35 26.3782L27.4 24.4282L29.35 23.3782L27.4 22.3282L26.35 20.3782Z'
      fill='#947BFF'
    />
    <path
      d='M64.85 8.37823L63.09 12.1182L59.35 13.8782L63.09 15.6382L64.85 19.3782L66.83 15.6382L70.35 13.8782L66.83 12.1182L64.85 8.37823Z'
      fill='#947BFF'
    />
  </>,
  'Bronze Medal',
  '0 0 94 52'
)
