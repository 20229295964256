import { AnyAction } from 'redux'
import { all, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { employerActions } from 'redux/employer/employerSlice'
import { flexActions } from 'redux/flex/flexSlice'
import { micromobilityActions } from 'redux/micromobility/micromobilitySlice'
import { programsActions } from 'redux/programs/programsSlice'
import ProgramsService from 'redux/programs/programsService'
import { userActions } from 'redux/user/userSlice'
import { grhActions } from 'redux/grh/grhSlice'
import { otpActions } from 'redux/otp/otpSlice'

// ---- Programs ----
export function* getAllPrograms(action: AnyAction) {
  yield defaultFlow(
    action,
    ProgramsService.getAllPrograms,
    programsActions.getAllProgramsSuccess
  )
}

// ---- Transportation Service Providers ----
export function* getAllServiceProviders(action: AnyAction) {
  yield defaultFlow(
    action,
    ProgramsService.getAllServiceProviders,
    programsActions.getAllServiceProvidersSuccess
  )
}

export function* getMerchants(action: AnyAction) {
  yield defaultFlow(
    action,
    ProgramsService.getMerchants,
    programsActions.getMerchantsSuccess
  )
}

// ---- Watchers ----
export function* watchGetAllPrograms() {
  yield takeEvery(
    [
      userActions.getUserSuccess.type,
      flexActions.createFlexProgramSuccess.type,
      flexActions.updateFlexProgramSuccess.type,
      flexActions.cancelFlexProgramSuccess.type,
      micromobilityActions.updateMicromobilityProgramSuccess.type,
      micromobilityActions.createMicromobilityProgramSuccess.type,
      micromobilityActions.cancelMicromobilityProgramSuccess.type,
      employerActions.archiveWorksiteSuccess.type,
      grhActions.createGrhProgramSuccess.type,
      grhActions.updateGrhProgramSuccess.type,
      grhActions.cancelGrhProgramSuccess.type,
      otpActions.createOtpProgramSuccess.type,
      otpActions.updateOtpProgramSuccess.type,
      otpActions.cancelOtpProgramSuccess.type,
    ],
    getAllPrograms
  )
}

export function* watchAllServiceProviders() {
  yield takeEvery([userActions.getUserSuccess.type], getAllServiceProviders)
}

export function* watchGetMerchants() {
  yield takeEvery([userActions.getUserSuccess.type], getMerchants)
}

export default function* () {
  yield all([
    watchAllServiceProviders(),
    watchGetMerchants(),
    watchGetAllPrograms(),
  ])
}
