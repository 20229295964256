import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <mask
      id='mask0'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='18'
      height='14'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 0H17.9995V13.104H0V0Z'
        fill='white'
      />
    </mask>
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4135 5.287V1.41H15.0925C15.4525 1.41 15.7535 1.68 15.7935 2.038L15.9845 5.287H12.4135ZM13.6625 11.604C13.0205 11.604 12.4985 11.083 12.4985 10.441C12.4985 9.8 13.0205 9.277 13.6625 9.277C14.3035 9.277 14.8255 9.8 14.8255 10.441C14.8255 11.083 14.3035 11.604 13.6625 11.604V11.604ZM8.50651 5.287H11.0035V1.41H8.50651V5.287ZM7.09751 5.287H2.15951L3.34251 2.513C3.71351 1.833 4.42451 1.41 5.19951 1.41H7.09751V5.287ZM4.51251 11.604C3.87151 11.604 3.34951 11.083 3.34951 10.441C3.34951 9.8 3.87151 9.277 4.51251 9.277C5.15351 9.277 5.67551 9.8 5.67551 10.441C5.67551 11.083 5.15351 11.604 4.51251 11.604V11.604ZM17.1945 1.882C17.0755 0.809 16.1725 0 15.0925 0H5.19951C3.90851 0 2.72251 0.704 2.10451 1.838L-0.000488281 6.501V11.145H1.95551C2.26751 12.271 3.28951 13.104 4.51251 13.104C5.73551 13.104 6.75751 12.271 7.06951 11.145H11.1045C11.4165 12.271 12.4385 13.104 13.6625 13.104C14.8845 13.104 15.9075 12.271 16.2185 11.145H17.9995V9.932L17.1945 1.882Z'
        fill='#6200E0'
      />
    </g>
  </>,
  'Programs Van',
  '0 0 18 14'
)
