import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19 7C19 5.9 18.1 5 17 5H14V7H17V9.65L13.52 14H9.99997V9H5.99997C3.78997 9 1.99997 10.79 1.99997 13V16H3.99997C3.99997 17.66 5.33997 19 6.99997 19C8.65997 19 9.99997 17.66 9.99997 16H14.48L19 10.35V7ZM6.99997 17C6.44997 17 5.99997 16.55 5.99997 16H7.99997C7.99997 16.55 7.54997 17 6.99997 17Z'
    />
    <path d='M9.99997 6.00001H4.99997V8.00001H9.99997V6.00001Z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 16C16 14.34 17.34 13 19 13C20.66 13 22 14.34 22 16C22 17.66 20.66 19 19 19C17.34 19 16 17.66 16 16ZM18 16C18 16.55 18.45 17 19 17C19.55 17 20 16.55 20 16C20 15.45 19.55 15 19 15C18.45 15 18 15.45 18 16Z'
    />
  </>,
  'Moped'
)
