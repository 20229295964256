import Text from 'core-system/Text'
import React from 'react'
import styled from 'styled-components'
import EmployerProducts from './components/EmployerProducts'
// import Button from 'core-system/Button'
// import ProductConfig from './components/ProductConfig'

const Container = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ProductsView = React.memo(() => {
  // const [showConfig, setShowConfig] = useState<boolean>(false)

  return (
    <Container>
      <HeaderContainer>
        <Text variant='h4'>
          {/* {showConfig ? 'Configure Products' : 'Your Products'} */}
          Your Products
        </Text>
        {/* <Button
                    onClick={() => setShowConfig(!showConfig)}
                >
                    {showConfig ? 'Cancel' : 'Configure Products'}
                </Button> */}
      </HeaderContainer>
      {/* {showConfig ? <ProductConfig /> : <EmployerProducts />} */}
      <EmployerProducts />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProductsView.displayName = 'ProductsView'
}

export default ProductsView
