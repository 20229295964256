import React from 'react'
import styled from 'styled-components'
import PaymentMethods from './PaymentMethods'
import Divider from 'core-system/Divider'
import FleetCards from './FleetCards'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

interface WalletDetailsProps {
  isMobile: boolean
}

const WalletDetails = React.memo((props: WalletDetailsProps) => {
  const { isMobile } = props

  return (
    <Container>
      <PaymentMethods isMobile={isMobile} />
      <Divider direction='horizontal' margin='0.5rem 0' />
      <FleetCards isMobile={isMobile} />
    </Container>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  WalletDetails.displayName = 'WalletDetails'
}

export default WalletDetails
