import React from 'react'
import { SnackbarProvider } from 'notistack'
import Notification from 'core-system/Notification'

const NotificationsProvider = (props: any) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      dense={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      preventDuplicate={true}
      autoHideDuration={3000}
      content={(key, message) => <Notification id={key} message={message} />}
    >
      {props.children}
    </SnackbarProvider>
  )
}

export default NotificationsProvider

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NotificationsProvider.displayName = 'NotificationsProvider'
}
