import { AxiosResponse } from 'axios'
import Request from 'redux/config/services/Request'
import { OptInCreatePayload, UpdateEmployeeOptInsData } from './optInTypes'

const getAllOptIns = (): Promise<AxiosResponse> => {
  return Request.get('/hr/concierge/opt_ins/')
}

const createOptIn = (data: OptInCreatePayload): Promise<AxiosResponse> => {
  return Request.post('/hr/concierge/opt_ins/', data)
}

const updateEmployeeOptIns = (
  data: UpdateEmployeeOptInsData
): Promise<AxiosResponse> => {
  return Request.post(
    `/hr/concierge/opt_ins/${data.optInId}/update_employee_opt_ins/`,
    data.employeeOptIns
  )
}

export default {
  getAllOptIns,
  createOptIn,
  updateEmployeeOptIns,
}
