import { TextVariants } from 'core-system/Text/Text'
import React from 'react'
import RandomUtils from 'shared/RandomUtils'
import styled, { css } from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

import Checkmark from '../Icons/Misc/Checkmark'
import { buildComponent } from '../utils/buildComponent'

const Container = styled.div<LayoutProps & SpaceProps & FlexboxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${layout}
  ${space} 
   ${flexbox}
`

const CheckboxContainer = styled.div<{
  checked: boolean
  disabled: boolean
  darkBg: boolean
}>`
  height: 1.25rem;
  width: 1.25rem;
  padding: 0.125rem;
  border-radius: 0.1875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border: 1px solid
    ${(props) =>
      props.checked
        ? props.theme.palette.secondary.maroon1
        : props.theme.palette.text.primary};
  background-color: ${(props) =>
    props.checked
      ? props.theme.palette.secondary.maroon1
      : props.theme.palette.white};
  stroke: ${(props) =>
    props.checked
      ? props.theme.palette.white
      : props.theme.palette.secondary.maroon1};

  & > svg {
    display: ${(props) => (props.checked ? 'block' : 'none')};
    opacity: ${(props) => (props.checked ? 1 : 0.3)};
  }

  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        cursor: pointer;
        border-color: ${(props) => props.theme.palette.secondary.maroon1};
        & > svg {
          display: block;
        }
      `};
  }

  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${(props) => props.theme.palette.grey.grey3};
      background-color: ${(props) => props.theme.palette.white};
      stroke: ${(props) => props.theme.palette.secondary.maroon1};

      & > svg {
        display: block;
        opacity: 0.3;
      }
    `};
`
const Label = styled.p<{ textVariant: TextVariants; darkBg: boolean }>`
  ${(props) => props.theme.typography[props.textVariant]};
  margin: 0.125rem 0 0 0.625rem;
  color: ${(props) =>
    props.darkBg
      ? props.theme.palette.white
      : props.theme.palette.text.primary};
`

interface CheckboxProps
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  checked: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  label?: string
  disabled?: boolean
  textVariant?: TextVariants
  titleCase?: boolean
  darkBg?: boolean
}

const CheckboxV2 = (props: CheckboxProps) => {
  const {
    checked,
    onClick,
    label,
    disabled,
    textVariant = 'body1',
    titleCase = false,
    darkBg = false,
    ...rest
  } = props

  return (
    <Container {...rest}>
      <CheckboxContainer
        className='checkBoxContainer'
        checked={checked}
        onClick={onClick}
        disabled={disabled}
        darkBg={darkBg}
      >
        {disabled ? null : <Checkmark fill='none' />}
      </CheckboxContainer>
      {label && (
        <Label textVariant={textVariant} darkBg={darkBg}>
          {titleCase ? RandomUtils.title(label) : label}
        </Label>
      )}
    </Container>
  )
}

export default buildComponent(CheckboxV2, 'CheckboxV2')
