import Button from 'core-system/Button'
import Chip from 'core-system/Chip'
import { ColumnCard } from 'core-system/ColumnView'
import Divider from 'core-system/Divider'
import MedalGoldIcon from 'core-system/Icons/Incentives/MedalGold'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { Segment } from 'redux/employer/employerTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'
import LeaderboardUtils from '../../LeaderboardUtils'

const positionalCopy = ['1st', '2nd', '3rd']

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`

const CaptionText = styled.div`
  ${(props) => props.theme.typography.caption}
  color: ${(props) => props.theme.palette.text.secondary};
  text-transform: uppercase;
  letter-spacing: 0.7px;
  white-space: nowrap;
`

const ModeTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  align-self: flex-start;
  pointer-events: none;
`

const PurpleContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  margin-bottom: 1rem;
`

const EndDateContainer = styled.div`
  margin-top: 1rem;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`

const BudgetContainer = styled.div`
  display: flex;
  align-items: center;
`
interface ManageLeaderboardSummary {
  isActive: boolean
  leaderboardType: string
  currentSegment: Segment
  startDate: string
  endDate: string
  bonuses: number[]
  showEndDate: boolean
  canEdit?: boolean
  toggleEdit?: () => void
}

const ManageLeaderboardSummary = React.memo(
  (props: ManageLeaderboardSummary) => {
    const {
      isActive,
      leaderboardType,
      currentSegment,
      startDate,
      endDate,
      bonuses,
      showEndDate,
      canEdit,
      toggleEdit,
    } = props

    const totalBudget =
      bonuses && bonuses.reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0)

    return (
      <ColumnCard>
        <Text
          variant='h4'
          marginBottom='1.5rem'
          dataCy='competition-summary-title'
        >
          {isActive ? 'Edit' : 'Activate'} Competition
        </Text>
        <HeaderContainer>
          {LeaderboardUtils.leaderboardData[leaderboardType].iconLg}
          <Text variant='action3' marginLeft='1rem'>
            {LeaderboardUtils.leaderboardData[leaderboardType].title}
          </Text>
        </HeaderContainer>
        <Text variant='body1' margin='1rem 0 1.5rem'>
          {LeaderboardUtils.leaderboardData[leaderboardType].description}
        </Text>
        <CaptionText>Coverage</CaptionText>
        <ModeTypes>
          {LeaderboardUtils.leaderboardData[leaderboardType].modeTypes.map(
            (modeType: string) => (
              <Chip
                variant={'filter'}
                key={modeType}
                margin='0.5rem 0.5rem 0 0'
              >
                {modeType.toLowerCase()}
              </Chip>
            )
          )}
        </ModeTypes>
        <Divider marginY='1.5rem' />
        <PurpleContainer>
          <HeaderContainer>
            <PlatformSvg folder='metrics' variant='graphs' />
            <Text variant='action3' marginLeft='0.5rem'>
              Overview
            </Text>
          </HeaderContainer>
          <CaptionText>Group</CaptionText>
          <Text variant='action4' margin='0.25rem 0 1rem'>
            {currentSegment.name} ({currentSegment.commuters} Commuters)
          </Text>
          <CaptionText>Start Date</CaptionText>
          <Text variant='action4' marginTop='0.25rem'>
            {startDate}
          </Text>
          {showEndDate && (
            <EndDateContainer>
              <CaptionText>End Date</CaptionText>
              <Text variant='action4' marginTop='0.25rem'>
                {endDate}
              </Text>
            </EndDateContainer>
          )}
        </PurpleContainer>
        <PurpleContainer>
          <HeaderContainer>
            <MedalGoldIcon />
            <Text variant='action3' marginLeft='0.5rem'>
              Bonuses
            </Text>
          </HeaderContainer>
          <TextContainer>
            <CaptionText>Place</CaptionText>
            <CaptionText>Reward</CaptionText>
          </TextContainer>
          {bonuses.map((bonus, idx) => (
            <TextContainer key={idx}>
              <Text variant='action4'>{positionalCopy[idx]}</Text>
              <Text variant='action4'>
                {FormattingUtils.formatDollar(bonus, 0)}
              </Text>
            </TextContainer>
          ))}
        </PurpleContainer>
        <PurpleContainer>
          <HeaderContainer>
            <PlatformSvg folder='metrics' variant='money' />
            <Text variant='action3' marginLeft='0.5rem'>
              Total Monthly Rewards
            </Text>
          </HeaderContainer>
          <BudgetContainer>
            <Text variant='h2'>
              {FormattingUtils.formatDollar(totalBudget, 0)}
            </Text>
            <Text variant='action3' margin='0.625rem 0 0 0.5rem'>
              monthly
            </Text>
          </BudgetContainer>
        </PurpleContainer>
        <Text
          variant='body2'
          textColor={palette.text.placeholder}
          marginBottom={!canEdit && isActive && '1.5rem'}
        >
          All budget amounts are estimated based on max commuter spend.
        </Text>
        {!canEdit && isActive && (
          <Button width='100%' onClick={toggleEdit}>
            Edit Next Month&apos;s Competition
          </Button>
        )}
      </ColumnCard>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ManageLeaderboardSummary.displayName = 'ManageLeaderboardSummary'
}

export default ManageLeaderboardSummary
