import React from 'react'
import styled from 'styled-components'
import palette from 'core-system/Themes/palette'
import GridCard from 'employee-platform/shared/components/GridCard'
import Text from 'core-system/Text'
import Divider from 'core-system/Divider'
import { GRHCopy } from '../EmployeeGRHUtils'
import pxToRem from 'core-system/utils/pxToRem'

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const GRHSteps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const GRHStepContainer = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 10% 80%;
  gap: ${(props) => (props.isMobile ? '1rem' : '0.5rem')};
  align-items: center;
`

const GRHStepNumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.secondary.purple5};
`

const infoSection = (
  header: string | null,
  content: React.ReactNode | string
) => {
  return (
    <InfoSection>
      {header ? (
        <Text variant='action3' textColor={palette.text.primary}>
          {header}
        </Text>
      ) : null}
      {typeof content === 'string' ? (
        <Text variant='action4' textColor={palette.text.secondary}>
          {content}
        </Text>
      ) : (
        content
      )}
    </InfoSection>
  )
}

const grhStep = (stepNumber: number, stepText: string, isMobile: boolean) => {
  return (
    <GRHStepContainer isMobile={isMobile}>
      <GRHStepNumberContainer>
        <Text variant='body2'>{stepNumber}</Text>
      </GRHStepNumberContainer>
      <Text variant='action4' textColor={palette.text.secondary}>
        {stepText}
      </Text>
    </GRHStepContainer>
  )
}

interface GRHInfoCardProps {
  isMobile: boolean
}

const GRHInfoCard = React.memo((props: GRHInfoCardProps) => {
  const { isMobile } = props

  return (
    <GridCard headerTitle='GRH Information'>
      {infoSection(null, GRHCopy.description)}
      <Divider margin='1rem 0' />
      {infoSection(
        'How To Use GRH',
        <GRHSteps>
          {GRHCopy.howToUseSteps.map((stepText, index) => {
            return grhStep(index + 1, stepText, isMobile)
          })}
        </GRHSteps>
      )}
    </GridCard>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHInfoCard.displayName = 'GRHInfoCard'
}

export default GRHInfoCard
