import React from 'react'
import styled from 'styled-components'
import palette from '../Themes/palette'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps & { disabled: boolean; active: boolean; color: string }
>`
  ${(props) => props.theme.typography.action4}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1rem;
  width: 100%;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => props.color};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? props.theme.palette.transparent
        : props.theme.palette.secondary.maroon3};
  }

  ${flexbox}
  ${space}
  ${layout}
`

const Text = styled.div`
  display: block;
  color: ${palette.secondary.maroon1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const getColor = (active: boolean, disabled: boolean) => {
  if (active) {
    return palette.primary.primaryPurple
  } else if (disabled) {
    return palette.text.disabled
  } else {
    return palette.text.primary
  }
}

interface SelectItemProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  active: boolean
  disabled?: boolean
  icon?: React.ReactNode | null
}

const SelectItemV2 = React.memo((props: SelectItemProps) => {
  const { active, disabled = false, icon, children } = props

  return (
    <Container
      active={active}
      disabled={disabled}
      color={getColor(active, disabled)}
      {...props}
    >
      {icon}
      <Text>{children}</Text>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SelectItemV2.displayName = 'SelectItemV2'
}

export default SelectItemV2
