import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.975 7H10.75V8.17625C10.7489 8.18818 10.7454 8.19933 10.742 8.21041C10.7379 8.22372 10.7338 8.23693 10.7338 8.25125C10.7338 8.2664 10.7379 8.27964 10.7421 8.29288C10.7455 8.30367 10.7489 8.31446 10.75 8.32625V9.5H11.9588C11.9649 9.50056 11.9708 9.50235 11.9767 9.50418C11.9841 9.50644 11.9917 9.50875 12 9.50875C12.0073 9.50875 12.0142 9.50665 12.0214 9.50449C12.0277 9.50256 12.0342 9.50059 12.0413 9.5H13.25V8.3275C13.2506 8.31511 13.254 8.30356 13.2574 8.29192C13.2612 8.2789 13.265 8.26577 13.265 8.25125C13.265 8.23772 13.2614 8.22519 13.2577 8.21246C13.2542 8.20048 13.2506 8.18833 13.25 8.175V7H12.025C12.0206 7 12.0166 6.99875 12.0125 6.9975C12.0085 6.99625 12.0044 6.995 12 6.995C11.9956 6.995 11.9916 6.99625 11.9875 6.9975C11.9835 6.99875 11.9794 7 11.975 7ZM9.97499 17.0075H14.0237C14.29 17.0075 14.5062 16.7562 14.5062 16.4475C14.5062 16.1387 14.29 15.8875 14.0237 15.8875L13.25 15.75V10.75C13.25 10.7045 13.2539 10.6856 13.2575 10.6688C13.2579 10.6667 13.2583 10.6646 13.2587 10.6625C13.245 10.695 13.2137 10.735 13.1175 10.735H9.97499C9.70874 10.735 9.49374 10.9862 9.49374 11.295C9.49374 11.6037 9.70874 11.855 9.97499 11.855L10.75 12V15.75L9.97499 15.8875C9.70874 15.8875 9.49374 16.1387 9.49374 16.4475C9.49374 16.7562 9.70874 17.0075 9.97499 17.0075ZM12 2C6.47625 2 2 6.4775 2 12C2 17.5225 6.47625 22 12 22C17.5225 22 22 17.5225 22 12C22 6.4775 17.5225 2 12 2Z'
    />
  </>,
  'Info'
)
