import React, { useState } from 'react'
import styled from 'styled-components'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import palette from 'core-system/Themes/palette'
import Tooltip from 'core-system/Tooltip'

const CardContainer = styled.div`
  ${(props) => props.theme.baseCard};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const CardHeader = styled(FlexContainer)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const HeaderIconContainer = styled.div<{ isCustom: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0.75rem;
  ${(props) =>
    !props.isCustom
      ? `background-color: ${props.theme.palette.chips.purple2};
      border-radius: 50%;
      padding: 0.25rem;
    `
      : ''}
`

const ClickableIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple6};
  }
`

const ChevronContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  user-select: none;
  transform: rotate(270deg);

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple6};
  }
`

interface GridCardProps {
  headerTitle?: string
  children: React.ReactNode
  headerIcon?: React.ReactNode
  customHeaderIcon?: boolean
  clickable?: boolean
  clickableIcon?: React.ReactNode | null
  headerButtons?: React.ReactNode | null
  onClick?: () => void | null
  tooltipText?: string | null
}

const GridCard = React.memo((props: GridCardProps) => {
  const {
    headerTitle,
    headerIcon,
    customHeaderIcon = false,
    clickable,
    clickableIcon,
    headerButtons,
    onClick,
    children,
    tooltipText,
  } = props

  const ref = React.useRef(null)

  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <CardContainer>
      {headerTitle || headerIcon ? (
        <CardHeader>
          <FlexContainer flexDirection='row' alignItems='center'>
            {headerIcon ? (
              <HeaderIconContainer isCustom={customHeaderIcon}>
                {headerIcon}
              </HeaderIconContainer>
            ) : null}
            <Text variant='h5'>{headerTitle}</Text>
          </FlexContainer>
          {clickable && !headerButtons ? (
            clickableIcon ? (
              <ClickableIconContainer
                ref={ref}
                onClick={() => onClick()}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                {clickableIcon}
                {tooltipText ? (
                  <Tooltip target={ref} show={showTooltip} position='bottom'>
                    {tooltipText}
                  </Tooltip>
                ) : null}
              </ClickableIconContainer>
            ) : (
              <ChevronContainer
                ref={ref}
                onClick={() => onClick()}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <ChevronIcon color={palette.text.secondary} />
                {tooltipText ? (
                  <Tooltip target={ref} show={showTooltip}>
                    {tooltipText}
                  </Tooltip>
                ) : null}
              </ChevronContainer>
            )
          ) : null}
          {headerButtons ? <>{headerButtons}</> : null}
        </CardHeader>
      ) : null}
      {children}
    </CardContainer>
  )
})

if (process.env.NODE_ENV !== 'production') {
  GridCard.displayName = 'GridCard'
}

export default GridCard
