import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.3656 0.214844L18.6842 0.226813L0 18.911L5.86109 24.7723L24.4113 6.22231V2.12635L24.3656 0.214844Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.2743 26.8033L24.4024 21.6753L24.3655 15.8682L18.7112 15.9072L7.85352 26.7649L18.8521 37.7632L24.4114 37.7607V31.9404L19.2743 26.8033Z'
      fill='white'
    />
  </>,
  'FleetLogo',
  '0 0 25 38'
)
