import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <rect
      x='20.0646'
      y='18.0645'
      width='3.87097'
      height='3.87097'
      fill='#16014A'
    />
    <rect
      x='14.2581'
      y='17.4194'
      width='15.4839'
      height='1.29032'
      fill='#16014A'
    />
    <path
      d='M15.9356 0.475261L29.7419 17.4194H14.2581L3.43056 2.03289C2.82897 1.17799 3.44043 0 4.4858 0H14.9353C15.3233 0 15.6906 0.174529 15.9356 0.475261Z'
      fill='#BEB3FF'
    />
    <circle cx='22' cy='36.129' r='15.4839' fill='#FFE58B' />
    <circle opacity='0.1' cx='22' cy='36.1291' r='9.03226' fill='#7C6203' />
    <path
      d='M28.0644 0.475261L14.2581 17.4194H29.7419L40.5694 2.03289C41.171 1.17799 40.5596 0 39.5142 0H29.0647C28.6767 0 28.3094 0.174529 28.0644 0.475261Z'
      fill='#DBDBFF'
    />
    <path
      d='M40.3 30.3L41 29L41.7 30.3L43 31L41.7 31.7L41 33L40.3 31.7L39 31L40.3 30.3Z'
      fill='#BEB3FF'
    />
    <path
      d='M3 20L1.95 21.95L0 23L1.95 24.05L3 26L4.05 24.05L6 23L4.05 21.95L3 20Z'
      fill='#947BFF'
    />
    <path
      d='M41.5 8L39.74 11.74L36 13.5L39.74 15.26L41.5 19L43.48 15.26L47 13.5L43.48 11.74L41.5 8Z'
      fill='#947BFF'
    />
  </>,
  'Gold Medal',
  '0 0 47 52'
)
