import { incentivesActions } from 'redux/incentives/incentivesSlice'
import Button from 'core-system/Button'
import RadialChartCenterText, {
  colors,
} from 'core-system/charts/Radial/RadialChartCenterText'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import LegendColor from 'core-system/Legend'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { AppState } from 'redux/config/store'
import { IncentivesRewarded } from 'redux/incentives/incentivesTypes'
import { ProgramType } from 'redux/programs/programsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'

const typeMap = {
  MICROMOBILITY: {
    modeTypes: [
      'MOPEDSHARE',
      'SCOOTER',
      'MOPED',
      'BIKESHARE',
      'BIKE',
      'SCOOTERSHARE',
    ],
  },
  FLEX: {
    modeTypes: [
      'MOPEDSHARE',
      'SCOOTER',
      'MOPED',
      'BIKESHARE',
      'BIKE',
      'SCOOTERSHARE',
      'SHUTTLE',
      'CARPOOL',
      'VANPOOL',
      'CAR',
      'TRANSIT',
      'WALK',
      'RIDESHARE',
    ],
  },
}

const Container = styled.div`
  ${(props) => props.theme.baseCard}

  &:hover {
    .headerLink {
      opacity: 1;
    }
  }
`

const HeaderLink = styled(Link)`
  padding: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  height: 2rem;
  width: 2rem;
  display: flex;
  transform: rotate(270deg);

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple5};
  }

  &:active {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

interface RelevantTrips {
  labels: string[]
  trips: number[]
  rewarded: number[]
  modeTypes: string[]
  totalTrips: number
}

const getRelevantTrips = (
  type: ProgramType,
  incentivesRewarded: IncentivesRewarded[]
): RelevantTrips => {
  const defaultState = {
    labels: [],
    trips: [],
    rewarded: [],
    modeTypes: [],
    totalTrips: 0,
  }
  return incentivesRewarded
    ? [...incentivesRewarded]
        .sort((a, b) => b.numTrips - a.numTrips)
        .reduce(
          (agg, modeType) => {
            if (
              typeMap[type].modeTypes.includes(modeType.modeType) &&
              modeType.numTrips > 0
            ) {
              agg.labels.push(
                `${RandomUtils.capitalize(modeType.modeType)} (${
                  modeType.numTrips
                } Trips)`
              )
              agg.trips.push(modeType.numTrips)
              agg.rewarded.push(modeType.rewarded)
              agg.modeTypes.push(modeType.modeType)
              agg.totalTrips += modeType.numTrips
            }
            return agg
          },
          { ...defaultState }
        )
    : defaultState
}

const getModeWithoutIncentives = (incentivesRewarded: IncentivesRewarded[]) => {
  return [...incentivesRewarded]
    .sort((a, b) => b.numTrips - a.numTrips)
    .find((modeType) => modeType.rewarded === 0 && modeType.numTrips > 0)
}

interface ProgramOverviewIncentivesProps {
  type: ProgramType
  segmentId: string
}

const ProgramOverviewIncentives = React.memo(
  (props: ProgramOverviewIncentivesProps) => {
    const { type, segmentId } = props

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { allIncentives, incentivesRewarded } = useSelector(
      (state: AppState) => state.incentives
    )

    const products = new Set(
      useSelector((state: AppState) => state.employer.profile.products)
    )

    useEffect(() => {
      if (allIncentives[segmentId]) {
        dispatch(
          incentivesActions.getIncentivesRewarded({
            segmentId,
            rangeStart: moment().startOf('month').format(),
          })
        )
      }
    }, [dispatch, segmentId, allIncentives])

    if (allIncentives[segmentId] && !incentivesRewarded) {
      return <Loading height={pxToRem(271)} />
    }

    const activeIncentives = allIncentives[segmentId]
    const activeRewarded = incentivesRewarded[segmentId]

    const relevantTrips = getRelevantTrips(type, activeRewarded)
    const relevantMode =
      activeRewarded && getModeWithoutIncentives(activeRewarded)

    return (
      <Container>
        <FlexContainer justifyContent='space-between' marginBottom='1rem'>
          <Text variant='h5'>Incentives</Text>
          {products.has('INCENTIVES') && (
            <HeaderLink to={`/trips/${segmentId}`} className='headerLink'>
              <ChevronIcon />
            </HeaderLink>
          )}
        </FlexContainer>
        <>
          {!activeIncentives || !products.has('INCENTIVES') ? (
            <>
              <Text variant='body1' marginBottom='1.75rem'>
                Reward your commuters for commuting with specific transportation
                modes.
              </Text>
              <Button
                size='small'
                onClick={() =>
                  navigate(`/trips/${segmentId}/incentives/activation`)
                }
                width='100%'
                disabled={!products.has('INCENTIVES')}
              >
                {`${
                  !products.has('INCENTIVES') ? 'Contact Fleet to ' : ''
                }Activate Incentives`}
              </Button>
            </>
          ) : (
            <>
              <FlexContainer center width='100%'>
                <RadialChartCenterText
                  value={relevantTrips.totalTrips}
                  label='Total Trips'
                  data={relevantTrips.trips.slice(0, 3)}
                  labels={relevantTrips.labels.slice(0, 3)}
                  errorMsg='No Trips This Month'
                  size={175}
                />
              </FlexContainer>
              {relevantTrips.modeTypes.slice(0, 3).map((modeType, idx) => (
                <FlexContainer
                  key={idx}
                  alignItems='center'
                  margin='0.875rem 0'
                >
                  <LegendColor color={colors[idx]} marginRight='0.5rem' />
                  <Text variant='action4' capitalize>
                    {modeType.toLowerCase()}
                  </Text>
                  <Text
                    variant='body2'
                    marginLeft='auto'
                    textColor={palette.text.secondary}
                  >
                    {relevantTrips.trips[idx]} Trips (
                    {relevantTrips.rewarded[idx] > 0
                      ? FormattingUtils.formatDollar(
                          relevantTrips.rewarded[idx]
                        )
                      : 'N/A'}
                    )
                  </Text>
                </FlexContainer>
              ))}
              {activeIncentives && (
                <>
                  {activeIncentives.nextMonth && !activeIncentives.active && (
                    <>
                      <Divider margin='1.75rem 0' />
                      <Text
                        variant='caption'
                        captionTitle
                        marginBottom='1rem'
                        textColor={palette.text.secondary}
                      >
                        Incentives Pending
                      </Text>
                      <Text variant='body1' marginBottom='1.75rem'>
                        Your incentives are set to start on{' '}
                        <strong>
                          {moment(activeIncentives.nextMonth.startDate).format(
                            'MMMM Do, YYYY'
                          )}
                        </strong>
                        .
                      </Text>
                      <Button
                        size='small'
                        onClick={() =>
                          navigate(`/trips/${segmentId}/incentives/manage`)
                        }
                        width='100%'
                      >
                        Edit Incentives
                      </Button>
                    </>
                  )}
                  {relevantMode && activeIncentives.nextMonth && (
                    <>
                      <Divider margin='1.75rem 0' />
                      <Text
                        variant='caption'
                        captionTitle
                        marginBottom='1rem'
                        textColor={palette.text.secondary}
                      >
                        Add Incentives for{' '}
                        {RandomUtils.capitalize(relevantMode.modeType)}
                      </Text>
                      <Text variant='body1' marginBottom='1.75rem'>
                        Your commuters have taken{' '}
                        <strong>
                          {relevantMode.numTrips}{' '}
                          {RandomUtils.capitalize(relevantMode.modeType)} trips
                        </strong>{' '}
                        this month! Reward them for these trips.
                      </Text>
                      <Button
                        size='small'
                        onClick={() =>
                          navigate(`/trips/${segmentId}/incentives/manage`)
                        }
                        width='100%'
                      >
                        New Incentives
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramOverviewIncentives.displayName = 'ProgramOverviewIncentives'
}

export default ProgramOverviewIncentives
