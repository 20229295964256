import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import ProgramChange from 'core-system/Program/ProgramChange'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import TransitLogo from 'core-system/TransitLogo'
import React from 'react'
import { IncentivesManageState } from 'redux/incentives/incentivesTypes'
import FormattingUtils from 'shared/FormattingUtils'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
`

const getDescriptionText = (canEdit: boolean, isPending: boolean) => {
  if (isPending) {
    return 'Update to apply these changes to incentives.'
  } else {
    return canEdit
      ? 'Update to apply these changes to the following month.'
      : 'All upcoming changes will be applied in following months.'
  }
}

interface IncentivesManagePendingProps {
  incentivesState: IncentivesManageState
  canEdit?: boolean
  isPending: boolean
  openUpdateModal: () => void
}

const IncentivesManagePending = React.memo(
  (props: IncentivesManagePendingProps) => {
    const {
      incentivesState,
      canEdit = true,
      isPending,
      openUpdateModal,
    } = props

    const allActiveModes = [
      ...Array.from(
        new Set([
          ...incentivesState.active.activeModes.flatMap((mode) => {
            return mode.text ? [mode.text] : []
          }),
          ...incentivesState.nextMonth.activeModes.flatMap((mode) => {
            return mode.text ? [mode.text] : []
          }),
        ])
      ),
    ]

    const modeTypeChanges = allActiveModes.flatMap((mode) => {
      const activeVal = incentivesState.active.activeModes.find(
        (activeMode) => activeMode.text === mode
      )
      const nextVal = incentivesState.nextMonth.activeModes.find(
        (activeMode) => activeMode.text === mode
      )

      if (activeVal && nextVal && activeVal.perTrip !== nextVal.perTrip) {
        return {
          mode,
          activeVal: activeVal.perTrip * 100,
          nextVal: nextVal.perTrip * 100,
        }
      } else if (activeVal && !nextVal) {
        return { mode, activeVal: activeVal.perTrip * 100, nextVal: 0 }
      } else if (!activeVal && nextVal && nextVal.perTrip) {
        return { mode, activeVal: 0, nextVal: nextVal.perTrip * 100 }
      } else {
        return []
      }
    })

    const modeMaxChanges = allActiveModes.flatMap((mode) => {
      const activeVal = incentivesState.active.activeModes.find(
        (activeMode) => activeMode.text === mode
      )
      const nextVal = incentivesState.nextMonth.activeModes.find(
        (activeMode) => activeMode.text === mode
      )

      if (activeVal && nextVal && (activeVal.max || 0) !== (nextVal.max || 0)) {
        return {
          mode,
          activeVal: activeVal.max * 100,
          nextVal: nextVal.max * 100,
        }
      } else if (activeVal && !nextVal) {
        return { mode, activeVal: activeVal.max * 100, nextVal: 0 }
      } else if (!activeVal && nextVal && nextVal.max) {
        return { mode, activeVal: 0, nextVal: nextVal.max * 100 }
      } else {
        return []
      }
    })

    const hasBudgetChange =
      incentivesState.active.maxVal !== incentivesState.nextMonth.maxVal
    const hasChanges =
      incentivesState.nextMonth.status === 'CANCELLING' ||
      hasBudgetChange ||
      modeTypeChanges.length > 0 ||
      modeMaxChanges.length > 0

    return (
      <>
        {hasChanges ? (
          <Container>
            <Text variant='h4' marginBottom='0.5rem'>
              {canEdit ? 'Pending Changes' : 'Upcoming Changes'}
            </Text>
            <Text variant='body1' marginBottom='1.5rem'>
              {getDescriptionText(canEdit, isPending)}
            </Text>
            {incentivesState.nextMonth.status === 'CANCELLING' && (
              <>
                <Divider margin='1.5rem 0' />
                <Text
                  variant='caption'
                  captionTitle
                  textColor={palette.text.secondary}
                  marginBottom='0.5rem'
                >
                  Incentives Cancellation Request
                </Text>
                <Text variant='body2'>
                  Our team will be in touch with you soon.
                </Text>
                {canEdit && (
                  <Button
                    onClick={openUpdateModal}
                    width='100%'
                    marginTop='1rem'
                  >
                    Reactivate Incentives
                  </Button>
                )}
              </>
            )}
            {hasBudgetChange && (
              <ProgramChange
                title='Monthly Max'
                metric='Per Commuter'
                icon={
                  <PlatformSvg
                    folder='metrics'
                    variant='money'
                    width={32}
                    height={32}
                  />
                }
                value={`${FormattingUtils.formatDollar(
                  incentivesState.active.maxVal,
                  0
                )} ➞ ${FormattingUtils.formatDollar(
                  incentivesState.nextMonth.maxVal,
                  0
                )}`}
              />
            )}
            {modeTypeChanges.length > 0 && (
              <>
                <Divider margin='1.5rem 0' />
                <Text
                  variant='caption'
                  captionTitle
                  textColor={palette.text.secondary}
                >
                  Rewards
                </Text>
                {modeTypeChanges.map((change) => (
                  <ProgramChange
                    key={change.mode}
                    metric={RandomUtils.capitalize(change.mode)}
                    icon={
                      <TransitLogo
                        modeType={change.mode}
                        src={change.mode}
                        size='small'
                      />
                    }
                    value={`${FormattingUtils.formatDollar(
                      change.activeVal
                    )} ➞ ${FormattingUtils.formatDollar(change.nextVal)}`}
                  />
                ))}
              </>
            )}
            {modeMaxChanges.length > 0 && (
              <>
                <Divider margin='1.5rem 0' />
                <Text
                  variant='caption'
                  captionTitle
                  textColor={palette.text.secondary}
                >
                  Monthly Maxes
                </Text>
                {modeMaxChanges.map((change) => (
                  <ProgramChange
                    key={change.mode}
                    metric={`${RandomUtils.capitalize(change.mode)} Max`}
                    icon={
                      <TransitLogo
                        modeType={change.mode}
                        src={change.mode}
                        size='small'
                      />
                    }
                    value={`${
                      !change.activeVal
                        ? 'No max'
                        : FormattingUtils.formatDollar(change.activeVal)
                    } ➞ ${
                      !change.nextVal
                        ? 'No max'
                        : FormattingUtils.formatDollar(change.nextVal)
                    }`}
                  />
                ))}
              </>
            )}
          </Container>
        ) : null}
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  IncentivesManagePending.displayName = 'IncentivesManagePending'
}

export default IncentivesManagePending
