import React from 'react'
import Card, { CardHeader, CardRow } from './index'
import pxToRem from '../utils/pxToRem'
import { FlexboxProps, SpaceProps, LayoutProps } from 'styled-system'
export interface CardLoadingProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps {
  cardItems?: number
}

const CardLoading = React.memo((props: CardLoadingProps) => {
  const { cardItems = 3 } = props
  return (
    <>
      {[...Array(cardItems)].map((_element: number, idx: number) => (
        <Card paddingBottom={pxToRem(72)} key={idx} {...props}>
          <CardHeader isLoading={true} />
          <CardRow isLoading={true} />
          <CardRow isLoading={true} />
          <CardRow isLoading={true} />
        </Card>
      ))}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CardLoading.displayName = 'CardLoading'
}

export default CardLoading
