import palette from 'core-system/Themes/palette'

export const employerOnboardingStepData = {
  setupWorkforce: {
    title: 'Step 1: Add your employees',
    subtitle: 'Choose how to add your team:',
    loading: 'Please wait while we connect your payroll provider...',
    title2: 'Transform commuter mobility for your workforce',
    backgroundColor: palette.white,
  },
  finchSync: {
    title: 'Step 1: Workforce Setup',
    subtitle: null,
    loading:
      'Please wait while we integrate your worksites and employees. This may take up to a few minutes...',
    title2: 'Transform commuter mobility for your workforce',
    backgroundColor: palette.secondary.purple4,
  },
  csvUpload: {
    title: 'Step 2: Workforce Setup',
    subtitle:
      "Let's get your team onboard! Here's how to manually add your team:",
    loading: 'Please wait while we connect your payroll provider...',
    title2: null,
    backgroundColor: palette.grey.grey1,
  },
  addPayment: {
    title: 'Step 2: Add Funding Source',
    subtitle:
      "Before we roll out benefits to your team, we need to set up a funding source. But hey, no stress! We'll give you an estimate before anything goes live.",
    loading: null,
    title2: 'Transform commuter mobility for your workforce',
    backgroundColor: palette.secondary.purple4,
  },
  success: {
    title: "You're the Workplace Superhero!",
    subtitle:
      "Congratulations! You've just unlocked commuter benefits for your employees, and that's pretty awesome. Here's what happens next:",
    loading: null,
    title2: null,
    backgroundColor: palette.secondary.purple4,
  },
  inviteAdmins: {
    title: 'Getting Started',
    subtitle: 'Invite the rest of your team to Fleet.',
    title2: 'Learn and improve the commuting patterns of your workforce',
    backgroundColor: palette.chips.magenta2,
  },
  addWorksites: {
    title: 'Upload Commuter Data',
    subtitle:
      "Before you get started, upload your company's commuter data csv, connect using our HRIS Integration, or add a worksite and invite commuters using a custom sign up link.",
    title2: 'Empower your workforce with the best commute options',
    backgroundColor: palette.secondary.purple4,
  },
  uploading: {
    title: 'Processing Data...',
    subtitle:
      'Give us a minute to process your data so we can find the best possible programs for your commuters.',
    title2: null,
    backgroundColor: palette.chips.blue2,
  },
}

export const extraOnboardingCopy = {
  setupWorkforce: {
    automated: {
      header: 'Integrate your HRIS (Recommended)',
      text: 'Seamlessly connect Fleet with your existing HR system via Finch for automatic updates and easy syncing',
    },
    manual: {
      header: 'Manual Upload',
      text: 'Prefer to do it yourself? No problem! Upload a CSV with your workforce data.',
    },
  },
  addPayment: {
    bankAccount: {
      header: 'Link your Bank Account (Recommended)',
      text: 'Connect Fleet to your bank effortlessly via Stripe.',
    },
    creditCard: {
      header: 'Add a Credit Card',
      text: "Prefer using a credit card? We've got you covered.",
    },
  },
  success: {
    welcomeEmail: {
      header: 'Welcome Email',
      text: "Your employees will be greeted with a welcome email. They're going to love this!",
    },
    productTour: {
      header: 'Product Tour',
      text: 'Dive into the product tour to master managing your programs like a pro.',
    },
    enjoy: {
      header: 'Enjoy',
      text: "Sit back and bask in your awesomeness. You've earned it",
    },
    extra:
      "Got any questions or need a hand? We're always here to help. Here's to happier commuting!",
  },
}
