import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import Home from 'core-system/Icons/Segments/Home'
import Other from 'core-system/Icons/Segments/Other'
import Worksite from 'core-system/Icons/Segments/Worksite'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import TransitLogo from 'core-system/TransitLogo'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { DailyTrip, TripType } from 'redux/employee/employeeTypes'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

const baseModeTypes = [
  ...ProgramUtils.modeTypes,
  ...ProgramUtils.transitModeTypes,
]

const Container = styled.div`
  ${(props) => props.theme.baseCard};
  padding: 1rem;

  ${space}
`

const Leg = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0.75rem 0;
`

const Border = styled.div`
  height: 1rem;
  width: 0.0125rem;
  background-color: ${(props) => props.theme.palette.secondary.lavender2};
`

const IconContainer = styled.div`
  min-width: 3rem;
`

const FleetDetected = styled.div`
  padding: 0.375rem 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.secondary.purple3};
  background-color: ${(props) => props.theme.palette.secondary.purple5};
  margin-left: auto;
  border-radius: 0.25rem;
`

const getDestinationType = (tripType: TripType) => {
  return (
    <FlexContainer alignItems='center'>
      {tripType === 'WORK2HOME' && (
        <>
          <Home />
          <Text variant='action3' marginLeft='0.5rem'>
            Back Home
          </Text>
        </>
      )}
      {tripType === 'HOME2WORK' && (
        <>
          <Worksite />
          <Text variant='action4' marginLeft='0.5rem'>
            To Work
          </Text>
        </>
      )}
      {tripType === 'OTHER' && (
        <>
          <Other />
          <Text variant='action3' marginLeft='0.5rem'>
            Other
          </Text>
        </>
      )}
      {tripType === 'NOTRIP' && (
        <>
          <Other />
          <Text variant='action3' marginLeft='0.5rem'>
            No Trip
          </Text>
        </>
      )}
    </FlexContainer>
  )
}

interface MyCommuterTripProps
  extends SpaceProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  trip: DailyTrip
}

const MyCommuterTrip = React.memo((props: MyCommuterTripProps) => {
  const { trip, ...rest } = props

  const { serviceProviderMap } = useSelector((app: AppState) => app.programs)

  return (
    <Container {...rest}>
      <FlexContainer alignItems='center'>
        {getDestinationType(trip.tripType)}
        <Divider direction='vertical' size='1.25rem' margin='0 1rem' />
        <Text variant='action4' textColor={palette.text.placeholder}>
          ~{trip.timeRange}
        </Text>
        {trip.fleetDetected && (
          <FleetDetected>
            <Text
              variant='caption'
              captionTitle
              textColor={palette.chips.purple1}
            >
              Fleet Detected
            </Text>
          </FleetDetected>
        )}
      </FlexContainer>
      <Divider margin='1rem 0' />
      <FlexContainer>
        <div>
          {trip.modeList.map((_mode, idx) => (
            <React.Fragment key={idx}>
              <Leg>
                <Text variant='caption' textColor={palette.text.secondary}>
                  {idx + 1}
                </Text>
              </Leg>
              {idx + 1 < trip.numLegs && (
                <FlexContainer alignItems='center' justifyContent='center'>
                  <Border />
                </FlexContainer>
              )}
            </React.Fragment>
          ))}
        </div>
        <div>
          {trip.modeList.map((mode, idx) => {
            const providerData = serviceProviderMap[mode]
            const isBaseModeType =
              providerData && baseModeTypes.includes(providerData.name)
            const formattedModeType =
              providerData &&
              ProgramUtils.formatElectricModeTypes(
                RandomUtils.capitalize(providerData.modeType)
              )
            return providerData ? (
              <FlexContainer
                key={idx}
                marginLeft='1.5rem'
                marginBottom='1rem'
                alignItems='center'
              >
                <IconContainer>
                  <TransitLogo
                    modeType={
                      providerData.iconUrl ? 'custom' : providerData.modeType
                    }
                    src={providerData.iconUrl || providerData.modeType}
                    size='large'
                  />
                </IconContainer>
                <FlexContainer
                  flexDirection='column'
                  marginLeft='1.5rem'
                  maxWidth={pxToRem(208)}
                >
                  <Text variant='action4' hideOverflow capitalize>
                    {isBaseModeType ? formattedModeType : providerData.name}
                  </Text>
                  <Text variant='body1' textColor={palette.text.placeholder}>
                    {isBaseModeType ? 'No Service Selected' : formattedModeType}
                  </Text>
                </FlexContainer>
              </FlexContainer>
            ) : null
          })}
        </div>
      </FlexContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommuterTrip.displayName = 'MyCommuterTrip'
}

// : trip.modeTypes.map((modeType, idx) => (
//   <FlexContainer
//     key={idx}
//     marginLeft='1.5rem'
//     marginBottom='1rem'
//     alignItems='center'
//   >
//     <IconContainer>
//       <TransitLogo
//         modeType={modeType}
//         src={modeType}
//         size='large'
//       />
//     </IconContainer>
//     <FlexContainer flexDirection='column' marginLeft='1.5rem'>
//       <Text variant='action4'>
//         {ProgramUtils.formatElectricModeTypes(
//           RandomUtils.capitalize(modeType)
//         )}
//       </Text>
//       <Text variant='body1' textColor={palette.text.placeholder}>
//         No Service Selected
//       </Text>
//     </FlexContainer>
//   </FlexContainer>
// ))}

export default MyCommuterTrip
