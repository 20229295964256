import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useSelector } from 'react-redux'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'

const getProgressStyles = () => {
  return buildStyles({
    strokeLinecap: 'round',
    pathColor: palette.chips.yellow2,
    trailColor: palette.grey.grey3,
  })
}

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  position: relative;
  overflow: hidden;
`

const CircularProgressContainer = styled.div`
  height: ${(props) => props.theme.pxToRem(52)};
  width: ${(props) => props.theme.pxToRem(52)};
  color: ${(props) => props.theme.palette.chips.blue1};
  margin-right: 1rem;
`

const ImageContainer = styled.div`
  position: absolute;
  top: 1.3125rem;
  right: ${(props) => props.theme.pxToRem(-70)};
`

interface TripsParticipationProps {
  employeeEngaged: number
  isLoading: boolean
  activeSegmentId: string
}

const TripsParticipation = React.memo((props: TripsParticipationProps) => {
  const { employeeEngaged, isLoading, activeSegmentId } = props

  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  if (isLoading) {
    return <Loading height={pxToRem(224)} spinnerSize={45} />
  }

  const activeSegmentEmployees = segmentsMap[activeSegmentId]
    ? segmentsMap[activeSegmentId].commuters
    : employeeEngaged

  const engagementPercentage = Math.round(
    (employeeEngaged / activeSegmentEmployees) * 100
  )

  return (
    <Container>
      <Text variant='h5' marginBottom='1.5rem'>
        Workforce Participation
      </Text>
      <CircularProgressContainer>
        <CircularProgressbarWithChildren
          styles={getProgressStyles()}
          strokeWidth={10}
          counterClockwise={true}
          value={engagementPercentage}
        >
          <PlatformSvg folder='metrics' variant='employees' />
        </CircularProgressbarWithChildren>
      </CircularProgressContainer>
      <Text variant='h3' marginTop='1rem'>
        {engagementPercentage ? engagementPercentage : 0}% of Commuters
      </Text>
      <Text variant='action2'>Have Logged Their Trips</Text>
      <ImageContainer>
        <img
          alt='engagementBackground'
          src={`${StaticAssetsUrl}/dashboard/engagement-background.png`}
        />
      </ImageContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TripsParticipation.displayName = 'TripsParticipation'
}

export default TripsParticipation
