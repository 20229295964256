const EMAIL_REGEX =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function checkEmail(email: string): boolean {
  return EMAIL_REGEX.test(email)
}

/**
 * Passwords must be
 * - At least 10 characters long, 30 max length
 * - 1 number
 * - 1 special character => !@#$%^&*
 */
const PASSWORD_REGEX = new RegExp(
  '^^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{10,30}$'
)

function checkPassword(password: string): boolean {
  return PASSWORD_REGEX.test(password)
}

export default {
  checkEmail,
  checkPassword,
}
