import { AxiosResponse } from 'axios'
import Request from '../config/services/Request'
import { Announcement, AnnouncementCreate, Faqs } from './conciergeTypes'

// ---- Announcements ----
const getAnnouncements = (): Promise<AxiosResponse> => {
  return Request.get('/hr/concierge/announcements/')
}

const getAutomatedAnnouncements = (): Promise<AxiosResponse> => {
  return Request.get('/hr/concierge/announcements/automated_announcements/')
}

const createAnnouncement = (
  announcement: AnnouncementCreate
): Promise<AxiosResponse> => {
  return Request.post('/hr/concierge/announcements/', {
    ...announcement,
    scheduledAt: announcement.scheduledAt
      ? new Date(announcement.scheduledAt)
      : null,
  })
}

const updateAnnouncement = (
  announcement: Announcement
): Promise<AxiosResponse> => {
  return Request.put(`/hr/concierge/announcements/${announcement.id}/`, {
    ...announcement,
    scheduledAt: announcement.scheduledAt
      ? new Date(announcement.scheduledAt)
      : null,
  })
}

const deleteAnnouncement = (announcementId: string): Promise<AxiosResponse> => {
  return Request.del(`/hr/concierge/announcements/${announcementId}/`)
}

// ---- FAQs ----
const getFaqs = (): Promise<AxiosResponse> => {
  return Request.get('/hr/concierge/faqs/')
}

const updateFaq = (faq: Faqs): Promise<AxiosResponse> => {
  return Request.put(`/hr/concierge/faqs/`, faq)
}

export default {
  getAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  getFaqs,
  updateFaq,
  getAutomatedAnnouncements,
}
