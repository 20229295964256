import Text from 'core-system/Text/Text'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import allProducts, { EmployerProduct } from '../utils/ProductsUtils'
import FlexContainer from 'core-system/FlexContainer/FlexContainer'
import Chip from 'core-system/Chip'
import Divider from 'core-system/Divider'

const StyledCard = styled.div`
  ${(props) => props.theme.baseCard}
  ${(props) => props.theme.typography.h4};
  margin-top: 1.5rem;
  padding: 2.5rem;
`

const IconContainer = styled(FlexContainer)`
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${(props) => props.theme.palette.chips.purple2};
  border-radius: 25%;
  margin-right: 1rem;
`

const renderProductList = (products: EmployerProduct[], active: boolean) => {
  return products.length === 0 ? (
    <Text variant='body1'>No {active ? 'Active' : 'Disabled'} Products</Text>
  ) : (
    products.map((product) => (
      <FlexContainer
        alignItems='center'
        marginBottom='0.25rem'
        padding='0.5rem'
        key={product.productLabel}
      >
        <IconContainer center>{product.icon}</IconContainer>
        <Text variant='action3'>{product.productName}</Text>
        <Chip marginLeft='auto' variant={active ? 'green' : 'grey'}>
          {active ? 'Active' : 'Disabled'}
        </Chip>
      </FlexContainer>
    ))
  )
}

const renderEmployerProducts = (products: Set<string>) => {
  const activeProducts = []
  const disabledProducts = []
  const allProductsMap = allProducts.allProducts
  for (const product of allProductsMap.keys()) {
    products.has(product)
      ? activeProducts.push(allProductsMap.get(product))
      : disabledProducts.push(allProductsMap.get(product))
  }
  return (
    <>
      <Text variant='h4' marginBottom='1rem'>
        Active
      </Text>
      {renderProductList(activeProducts, true)}
      <Divider marginTop='1rem' marginBottom='1rem' />
      <Text variant='h4' marginBottom='1rem'>
        Disabled
      </Text>
      {renderProductList(disabledProducts, false)}
    </>
  )
}

const EmployerProducts = React.memo(() => {
  const products = new Set(
    useSelector((state: AppState) => state.employer.profile.products)
  )

  return (
    <>
      <StyledCard>{renderEmployerProducts(products)}</StyledCard>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployerProducts.displayName = 'EmployerProducts'
}

export default EmployerProducts
