import FormattingUtils from 'shared/FormattingUtils'

export const PreTaxMonthlyMaximums = {
  transit: 31500,
  parking: 31500,
}

export const PreTaxInfoCopy = {
  general: `Fleet's Pre-Tax Commuter Benefits program allows you to allocate pre-tax dollars to pay for your monthly parking and transit expenses, which can save you up to 40%!`,
  whatIsAPreTaxBenefit: `Pre-Tax benefits are a perk setup by the federal government and provided by your employer to help you save money on any expenses related to commuting to and from work`,
  whatQualifiesForPreTax: {
    transit: `Use your monthly budget to pay for public transportation including subway, train, bus, and transit passes,`,
    parking: `Use your monthly budget to pay for parking.`,
  },
  doesThisExpire: `No! Funds roll over from month to month and will not expire while you are employed at your current company.`,
  isThereAMaxMonthlySpend: `Yes. While funds do roll over from month to month, your monthly spend through this program may not exceed ${FormattingUtils.formatDollar(
    PreTaxMonthlyMaximums.parking,
    0
  )} for parking and/or ${FormattingUtils.formatDollar(
    PreTaxMonthlyMaximums.transit,
    0
  )} for transit, depending on what is offered by your employer.`,
}
