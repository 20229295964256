import { AnyAction } from 'redux'
import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import { defaultFlow, multiResponseFlow } from 'redux/config/saga/Common'
import { employeeActions } from 'redux/employee/employeeSlice'
import EmployeeService from 'redux/employee/employeeService'
import {
  ChangeWorksitePayload,
  EmployeeIdParams,
  EmployeeSuccessPayload,
  MyCommuterDetailsParams,
  MyCommutersParams,
  MyCommuterTransactionsParams,
} from 'redux/employee/employeeTypes'
import { employerActions } from 'redux/employer/employerSlice'
import { userActions } from 'redux/user/userSlice'
import { Action } from 'shared/Types/Actions'

export function* getAllEmployees(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployeeService.getAllEmployees,
    employeeActions.getEmployeesSuccess
  )
}

export function* updateEmployee(action: Action<EmployeeSuccessPayload>) {
  yield defaultFlow(
    action,
    EmployeeService.updateEmployee,
    employeeActions.updateEmployeeSuccess
  )
}

export function* deleteEmployee(action: Action<string>) {
  yield defaultFlow(
    action,
    EmployeeService.deleteEmployee,
    employeeActions.deleteEmployeeSuccess
  )
}

export function* createEmployee(action: Action<any>) {
  yield defaultFlow(
    action,
    EmployeeService.createEmployee,
    employeeActions.createEmployeeSuccess
  )
}

export function* inviteEmployees(action: Action<string[]>) {
  yield multiResponseFlow(
    action,
    EmployeeService.inviteEmployees,
    employeeActions.inviteEmployeesSuccess
  )
}

export function* getMyCommuters(action: Action<MyCommutersParams>) {
  yield defaultFlow(
    action,
    EmployeeService.getMyCommuters,
    employeeActions.getMyCommutersSuccess
  )
}

export function* getCommuters(action: Action<string>) {
  yield defaultFlow(
    action,
    EmployeeService.getCommuters,
    employeeActions.getCommutersSuccess
  )
}

export function* getMyCommuterDetails(action: Action<MyCommuterDetailsParams>) {
  yield defaultFlow(
    action,
    EmployeeService.getMyCommuterDetails,
    employeeActions.getMyCommuterDetailsSuccess
  )
}

export function* getMyCommuterTransactions(
  action: Action<MyCommuterTransactionsParams>
) {
  yield defaultFlow(
    action,
    EmployeeService.getMyCommuterTransactions,
    employeeActions.getMyCommuterTransactionsSuccess
  )
}

export function* resendEmployeeInvite(action: Action<string>) {
  yield defaultFlow(
    action,
    EmployeeService.resendEmployeeInvite,
    employeeActions.resendEmployeeInviteSuccess
  )
}

export function* getArchivedEmployees(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployeeService.getArchivedEmployees,
    employeeActions.getArchivedEmployeesSuccess
  )
}

export function* archiveEmployee(action: Action<EmployeeIdParams>) {
  yield defaultFlow(
    action,
    EmployeeService.archiveEmployee,
    employeeActions.archiveEmployeeSuccess
  )
}

export function* reactivateEmployee(action: Action<EmployeeIdParams>) {
  yield defaultFlow(
    action,
    EmployeeService.reactivateEmployee,
    employeeActions.reactivateEmployeeSuccess
  )
}

export function* getDeclinedEmployees(action: AnyAction) {
  yield defaultFlow(
    action,
    EmployeeService.getDeclinedEmployees,
    employeeActions.getDeclinedEmployeesSuccess
  )
}

export function* changeEmployeeWorksite(action: Action<ChangeWorksitePayload>) {
  yield defaultFlow(
    action,
    EmployeeService.changeEmployeeWorksite,
    employeeActions.changeEmployeeWorksiteSuccess
  )
}

/**************************************
 * Watchers
 **************************************/

export function* watchGetAllEmployees() {
  yield takeLatest(
    [employeeActions.getEmployees.type, userActions.getUserSuccess.type],
    getAllEmployees
  )
}

export function* watchUpdateEmployee() {
  yield takeLatest([employeeActions.updateEmployee.type], updateEmployee)
}

export function* watchDeleteEmployee() {
  yield takeLatest([employeeActions.deleteEmployee.type], deleteEmployee)
}

export function* watchCreateEmployee() {
  yield takeLatest([employeeActions.createEmployee.type], createEmployee)
}

export function* watchInviteEmployees() {
  yield takeLatest([employeeActions.inviteEmployees.type], inviteEmployees)
}

export function* watchGetCommuters() {
  yield takeEvery(
    [
      employeeActions.getCommuters.type,
      userActions.getUserSuccess.type,
      employerActions.uploadFileSuccess.type,
    ],
    getCommuters
  )
}

export function* watchGetMyCommuters() {
  yield takeEvery(
    [
      employeeActions.getMyCommuters.type,
      employerActions.uploadFileSuccess.type,
      employerActions.unarchiveWorksiteSuccess.type,
    ],
    getMyCommuters
  )
}

export function* watchGetMyCommuterDetails() {
  yield takeEvery(
    [employeeActions.getMyCommuterDetails.type],
    getMyCommuterDetails
  )
}

export function* watchGetMyCommuterTransactions() {
  yield takeEvery(
    [employeeActions.getMyCommuterTransactions.type],
    getMyCommuterTransactions
  )
}

export function* watchResendEmployeeInvite() {
  yield takeEvery(
    [employeeActions.resendEmployeeInvite.type],
    resendEmployeeInvite
  )
}

export function* watchGetArchivedEmployees() {
  yield takeEvery(
    [
      employeeActions.archiveEmployeeSuccess.type,
      employeeActions.reactivateEmployeeSuccess.type,
      employeeActions.getArchivedEmployees.type,
    ],
    getArchivedEmployees
  )
}

export function* watchArchiveEmployee() {
  yield takeEvery([employeeActions.archiveEmployee.type], archiveEmployee)
}

export function* watchReactivateEmployee() {
  yield takeEvery([employeeActions.reactivateEmployee.type], reactivateEmployee)
}

export function* watchGetDeclineEmployees() {
  yield takeEvery(
    [
      employeeActions.getDeclinedEmployees.type,
      userActions.getUserSuccess.type,
      employerActions.declineInactiveEmployeesSuccess.type,
    ],
    getDeclinedEmployees
  )
}

export function* watchChangeEmployeeWorksite() {
  yield takeEvery(
    [employeeActions.changeEmployeeWorksite.type],
    changeEmployeeWorksite
  )
}

export default function* () {
  yield all([
    watchGetAllEmployees(),
    watchUpdateEmployee(),
    watchDeleteEmployee(),
    watchCreateEmployee(),
    watchInviteEmployees(),
    watchGetCommuters(),
    watchGetMyCommuters(),
    watchGetMyCommuterDetails(),
    watchGetMyCommuterTransactions(),
    watchResendEmployeeInvite(),
    watchGetArchivedEmployees(),
    watchArchiveEmployee(),
    watchReactivateEmployee(),
    watchGetDeclineEmployees(),
    watchChangeEmployeeWorksite(),
  ])
}
