import Request from 'redux/config/services/Request'
import { AxiosResponse } from 'axios'
import { SegmentParams } from 'redux/employer/employerTypes'
import { GrhProgram, GrhProgramUpdate } from './grhTypes'
import {
  SegmentAndType,
  SegmentAndTypeAndDate,
} from 'redux/programs/programsTypes'

const getGrhRecommendations = (
  payload: SegmentParams
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/programs/merchants/?segment=${payload.segmentId}&mode_types=RIDESHARE`
  )
}

const createGrhProgram = (payload: GrhProgram): Promise<AxiosResponse> => {
  return Request.post('/hr/programs/card_programs/', payload)
}

const updateGrhProgram = (
  payload: GrhProgramUpdate
): Promise<AxiosResponse> => {
  const { programId, ...rest } = payload
  return Request.put(`/hr/programs/card_programs/${programId}/`, rest)
}

const cancelGrhProgram = (programId: string): Promise<AxiosResponse> => {
  return Request.del(`/hr/programs/card_programs/${programId}/`)
}

const getGrhDashboardStats = (
  segmentAndType: SegmentAndType
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/programs/card_programs/dashboard/?segment=${segmentAndType.segmentId}&type=${segmentAndType.type}`
  )
}

const getGrhDashboardServices = (
  segmentAndTypeAndDate: SegmentAndTypeAndDate
): Promise<AxiosResponse> => {
  return Request.post('/hr/programs/card_programs/services/', {
    rangeEnd: segmentAndTypeAndDate.rangeEnd,
    rangeStart: segmentAndTypeAndDate.rangeStart,
    segment: segmentAndTypeAndDate.segmentId,
    type: segmentAndTypeAndDate.type,
  })
}

const getGrhUsageHistory = (
  segmentAndType: SegmentAndType
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/programs/card_programs/transaction_requests/?segment=${segmentAndType.segmentId}&type=GRH`
  )
}

export default {
  getGrhRecommendations,
  createGrhProgram,
  updateGrhProgram,
  cancelGrhProgram,
  getGrhDashboardServices,
  getGrhDashboardStats,
  getGrhUsageHistory,
}
