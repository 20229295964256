import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 18.75H7.82C7.4 19.91 6.3 20.75 5 20.75C3.16 20.75 1.7 19.09 2.05 17.19C2.27 16.01 3.22 15.04 4.4 14.81C5.96 14.51 7.34 15.41 7.82 16.75H13.09C13.47 14.48 15.12 12.65 17.28 12H17.29L15.89 5.75H12V3.75H15.89C16.82 3.75 17.63 4.4 17.84 5.31L19.74 13.75H19C16.79 13.75 15 15.54 15 17.75V18.75ZM4 17.75C4 18.3 4.45 18.75 5 18.75C5.55 18.75 6 18.3 6 17.75C6 17.2 5.55 16.75 5 16.75C4.45 16.75 4 17.2 4 17.75Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 17.75C16 16.09 17.34 14.75 19 14.75C20.66 14.75 22 16.09 22 17.75C22 19.41 20.66 20.75 19 20.75C17.34 20.75 16 19.41 16 17.75ZM18 17.75C18 18.3 18.45 18.75 19 18.75C19.55 18.75 20 18.3 20 17.75C20 17.2 19.55 16.75 19 16.75C18.45 16.75 18 17.2 18 17.75Z'
    />
  </>,
  'Scooter'
)
