import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Tooltip from './Tooltip'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div<FlexboxProps & SpaceProps & LayoutProps>`
  ${flexbox}
  ${space}
  ${layout}
`

interface SvgTooltipProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  svg: React.ReactElement
  description: string
  isMultiLine?: boolean
  position?: 'left' | 'right' | 'top' | 'bottom'
}

const SvgTooltip = React.memo((props: SvgTooltipProps) => {
  const { svg, description, isMultiLine = false, position = 'bottom' } = props
  const [showTooltip, setShowTooltip] = useState(false)
  const ref = useRef(null)

  return (
    <Container
      ref={ref}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      {...props}
    >
      {svg}
      <Tooltip
        target={ref}
        show={showTooltip}
        isMultiLine={isMultiLine}
        position={position}
      >
        {description}
      </Tooltip>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SvgTooltip.displayName = 'SvgTooltip'
}

export default SvgTooltip
