import snakeize from 'snakeize'

function removeKey(key: any, obj: any) {
  const copy = Object.assign({}, obj)
  delete copy[key]
  return copy
}

function buildQueryParams(params: any) {
  if (!params) {
    return ''
  }
  const snakedParams = snakeize(params)
  return Object.keys(snakedParams)
    .filter((k) => snakedParams[k] !== null)
    .map((key) => {
      return key + '=' + snakedParams[key]
    })
    .join('&')
}

function getFormData(data: any) {
  if (data === null || undefined) return null
  return Object.keys(data).reduce((formData, key) => {
    formData.append(snakeize(key), data[key])
    return formData
  }, new FormData())
}

export default {
  removeKey,
  buildQueryParams,
  getFormData,
}
