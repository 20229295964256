import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'
import { buildForwardingComponent } from '../utils/buildComponent'
import LoadingBar from '../LoadingBar'

const Content = styled.div<FlexboxProps & SpaceProps & LayoutProps>`
  display: flex;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5rem;
  padding: 1.25rem 1.25rem 0.75rem;
  align-items: center;
 
  ${flexbox}
  ${space}
  ${layout}
`

const LoadingSquare = styled(LoadingBar)`
  width: ${(props) => props.theme.pxToRem(64)};
  height: ${(props) => props.theme.pxToRem(64)};
  margin: 0 1rem 0 0;
`

const LoadingTitle = styled(LoadingBar)`
  width: ${(props) => props.theme.pxToRem(115)};
  height: ${(props) => props.theme.pxToRem(32)};
  margin: 0;
`

const Loading = () => (
  <>
    <LoadingSquare />
    <LoadingTitle />
  </>
)

export interface CardHeaderProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  isLoading?: boolean
}

const CardHeader = React.forwardRef<HTMLDivElement, CardHeaderProps>(
  (props: CardHeaderProps, ref: React.Ref<HTMLDivElement>) => {
    const { children, isLoading = false } = props
    return (
      <Content ref={ref} {...props}>
        {isLoading ? <Loading /> : children}
      </Content>
    )
  }
)

if (process.env.NODE_ENV !== 'production') {
  CardHeader.displayName = 'CardHeader'
}

export default buildForwardingComponent(CardHeader)
