import { ActionCreator, AnyAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, put } from 'redux-saga/effects'

type ApiRequest<T> = (...args: any[]) => Promise<T>

function getErrorMessage(error: any) {
  return {
    message: error instanceof Error ? error.message : String(error),
    status: error?.response?.status || null,
    statusText: error?.response?.statusText || null,
  }
}

export function* defaultFlow<T>(
  action: AnyAction,
  api: ApiRequest<T>,
  onSuccess: ActionCreator<any>
) {
  try {
    const res = yield call(api, action.payload)
    yield put({
      type: onSuccess().type,
      payload: res.data,
      meta: action.payload,
    })
  } catch (error) {
    yield put({ type: `${action.type}Failed`, error: getErrorMessage(error) })
  }
}

export function* multiResponseFlow(
  action: AnyAction,
  apis: (...args: any[]) => Promise<any>[],
  onSuccess: any
) {
  try {
    const promises = apis(action.payload)
    const res = yield all(promises)
    const data = res.map((res: AxiosResponse) => res.data)
    yield put({
      type: onSuccess().type,
      payload: data,
      meta: action.payload,
    })
  } catch (error) {
    yield put({ type: `${action.type}Failed`, error: getErrorMessage(error) })
  }
}
