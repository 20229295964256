import Button from 'core-system/Button'
import RadialChartCenterText from 'core-system/charts/Radial/RadialChartCenterText'
import FlexContainer from 'core-system/FlexContainer'
import HorizontalScroller from 'core-system/HorizontalScroller'
import HorizontalScrollerUtils from 'core-system/HorizontalScroller/HorizontalScrollerUtils'
import Loading from 'core-system/Loading'
import ServiceProviderSelectorModal from 'core-system/ServiceProvider/ServiceProviderSelectorModal'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import {
  Merchant,
  ProgramServiceUsage,
  ProgramType,
} from 'redux/programs/programsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const getChartData = (
  merchantUsageData: ProgramServiceUsage[],
  merchantsMap: Dictionary<Merchant>
) => {
  return [...merchantUsageData]
    .sort((a, b) => b.spend - a.spend)
    .reduce(
      (agg, merchantData) => {
        const spend = merchantData.spend || 0
        agg.totalSpend = agg.totalSpend + spend
        agg.data.push(spend)
        agg.labels.push(
          `${
            merchantsMap[merchantData.merchantId]?.name
          } (${FormattingUtils.formatDollar(merchantData.spend)})`
        )

        return agg
      },
      {
        totalSpend: 0,
        data: [],
        labels: [],
      }
    )
}

interface ProgramOverviewServicesProps {
  serviceUsageData: ProgramServiceUsage[]
  type: ProgramType
  modeTypeFilters?: boolean
}

const ProgramOverviewServices = React.memo(
  (props: ProgramOverviewServicesProps) => {
    const { modeTypeFilters, type, serviceUsageData } = props

    const merchantsMap = useSelector(
      (state: AppState) => state.programs.merchantsMap
    )

    const [isModalOpen, setIsModalOpen] = useState(false)

    const modalServiceData = useMemo(() => {
      return (
        serviceUsageData &&
        serviceUsageData.reduce((agg, service) => {
          agg[service.merchantId] = {
            trips: service.numTrips,
            spend: FormattingUtils.formatDollar(service.spend) || '$0',
          }
          return agg
        }, {})
      )
    }, [serviceUsageData])

    if (!serviceUsageData || !modalServiceData) {
      return <Loading height={pxToRem(type === 'MICROMOBILITY' ? 417 : 464)} />
    }

    const merchantIds = serviceUsageData.map((data) => data.merchantId)
    const customData = serviceUsageData.reduce((agg, s) => {
      agg[s.merchantId] = {
        spend: FormattingUtils.formatDollar(s.spend) || '$0',
        trips: s.numTrips,
      }
      return agg
    }, {})

    const chartData = getChartData(serviceUsageData, merchantsMap)

    return (
      <>
        <Container>
          <Text variant='h5' marginBottom='1.5rem'>
            Services Breakdown
          </Text>
          <FlexContainer
            flexDirection='column'
            justifyContent='space-between'
            flex={1}
          >
            <FlexContainer
              center
              width='100%'
              marginBottom={type === 'GRH' || type === 'OTP' ? '1rem' : '0'}
            >
              <RadialChartCenterText
                value={serviceUsageData.length}
                label='Service Providers'
                data={chartData.data.slice(0, 9)}
                labels={chartData.labels.slice(0, 9)}
                size={195}
                errorMsg='No Service Providers Used'
              />
            </FlexContainer>
            {serviceUsageData.length === 0 ? (
              <FlexContainer center>
                <Text variant='body1'>No service providers used yet</Text>
              </FlexContainer>
            ) : (
              <HorizontalScroller
                translationPerClick={165}
                opacityWidth={pxToRem(120)}
              >
                {HorizontalScrollerUtils.getProviders(
                  merchantIds,
                  merchantsMap
                )}
              </HorizontalScroller>
            )}
            <Button
              variant='secondary'
              size='small'
              width='100%'
              onClick={() => {
                setIsModalOpen(true)
                SegmentService.track('modes-modal-toggle', {
                  action: 'open',
                  state: 'view-all',
                  location: 'program-dashboard',
                })
              }}
              disabled={serviceUsageData.length === 0}
              marginTop={type === 'GRH' || type === 'OTP' ? '1rem' : '0'}
            >
              View All Service Providers
            </Button>
          </FlexContainer>
        </Container>
        <ServiceProviderSelectorModal
          open={isModalOpen}
          closeModal={() => {
            setIsModalOpen(false)
            SegmentService.track('modes-modal-toggle', {
              action: 'close',
              state: 'view-all',
              location: 'program-dashboard',
            })
          }}
          title='Used Service Providers'
          activeProviders={merchantIds}
          availableProviders={merchantIds}
          canEdit={false}
          modeTypeFilters={modeTypeFilters}
          customData={customData}
          serviceCardHeight={240}
        />
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramOverviewServices.displayName = 'ProgramOverviewServices'
}

export default ProgramOverviewServices
