import React from 'react'
import styled from 'styled-components'
import NumberFormat, {
  NumberFormatValues,
  NumberFormatProps,
} from 'react-number-format'
import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system'
import DateUtils from 'shared/DateUtils'

const CustomInput = styled(NumberFormat)<
  FlexboxProps & SpaceProps & { fontVariant?: string }
>`
  ${(props) =>
    props.fontVariant
      ? props.theme.typography[props.fontVariant]
      : props.theme.typography.action4};
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  padding: 0;

  ${flexbox}
  ${space}
`

const getSuffix = (
  format: 'dollar' | 'cents' | 'days' | 'count',
  value?: number
) => {
  if (format === 'cents') {
    return '¢'
  } else if (format === 'days' && value) {
    const day = DateUtils.getDayOfTheMonth(value)
    return ` ${day.replace(/[0-9]/g, '')}`
  } else {
    return ''
  }
}

export interface NumberFormatterProps
  extends FlexboxProps,
    SpaceProps,
    NumberFormatProps {
  onInputValueChange?: (value: number, callbackItem?: any) => void
  value?: number
  callbackItem?: any
  setIsHover?: (isHover: boolean) => void
  currencyFormat?: 'dollar' | 'cents' | 'days' | 'count'
  fontVariant?: string
  placeholder?: string
  showPlaceholderOnZero?: boolean
}

const NumberFormatter = React.memo((props: NumberFormatterProps) => {
  const {
    value,
    callbackItem,
    onInputValueChange,
    setIsHover,
    currencyFormat = 'dollar',
    fontVariant,
    placeholder = '$0',
    showPlaceholderOnZero,
    ...rest
  } = props

  const overwritePlaceholder = showPlaceholderOnZero && (value === 0 || !value)
  return (
    <CustomInput
      onFocus={() => setIsHover(true)}
      onBlur={() => setIsHover(false)}
      value={overwritePlaceholder ? placeholder : value}
      prefix={currencyFormat === 'dollar' ? '$' : null}
      suffix={getSuffix(currencyFormat, value)}
      decimalScale={currencyFormat === 'dollar' ? 2 : null}
      thousandSeparator={true}
      placeholder={placeholder}
      allowNegative={false}
      maxLength={8}
      decimalSeparator='.'
      fontVariant={fontVariant}
      onValueChange={(value: NumberFormatValues) =>
        callbackItem || callbackItem === 0
          ? onInputValueChange(value.floatValue, callbackItem)
          : onInputValueChange(value.floatValue)
      }
      style={
        overwritePlaceholder
          ? {
              color: '#75758a',
            }
          : {}
      }
      {...rest}
    />
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NumberFormatter.displayName = 'NumberFormatter'
}

export default NumberFormatter
