import { TextButton } from 'core-system/Button'
import Dropdown from 'core-system/Dropdown'
import {
  Filter as FilterIcon,
  Sort as SortIcon,
} from 'core-system/Icons/Actions'
import SearchInput from 'core-system/SearchInput'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Commuter, MyCommuter } from 'redux/employee/employeeTypes'
import { LeaderboardType } from 'redux/leaderboards/leaderboardsTypes'
import { ProgramType } from 'redux/programs/programsTypes'
import styled, { css } from 'styled-components'
import MyCommutersFilterModal from './MyCommutersFilterModal'
import { employeeActions } from 'redux/employee/employeeSlice'

export interface HandleFiltersChangeParams {
  newProgramFilters?: string[]
  newCompetitionFilters?: string[]
  newWorksiteFilters?: string[]
  searchVal?: string
}

const dropdownFilters = [
  { id: 'desc', text: 'Descending', disabled: false },
  { id: 'asc', text: 'Ascending', disabled: false },
]

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2.125rem;
`

const CustomButton = styled.div<{ disabled: boolean }>`
  padding: 0.375rem 0.625rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.white};
  margin-left: 1.5rem;
  height: 2rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple5};
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;

      &:hover {
        background-color: ${(props) => props.theme.palette.white};
      }
    `}
`

interface MyCommutersSearchProps {
  myCommuters: MyCommuter[] | Commuter[]
  filteredCommuters: MyCommuter[] | Commuter[]
  sortByFilter: string
  activeSegment?: string
  setFilteredCommuters?: (commuters: MyCommuter[]) => void
  setFilteredPendingCommuters?: (commuters: Commuter[]) => void
  setSortByFilter: (filter: string) => void
  isAdd: boolean
  isPending?: boolean
}

const MyCommutersSearch = React.memo((props: MyCommutersSearchProps) => {
  const {
    myCommuters,
    filteredCommuters,
    sortByFilter,
    activeSegment,
    setSortByFilter,
    setFilteredCommuters,
    setFilteredPendingCommuters,
    isAdd,
    isPending,
  } = props

  const dispatch = useDispatch()

  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  const [searchVal, setSearchVal] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [programFilters, setProgramFilters] = useState<ProgramType[]>([])
  const [competitionFilters, setCompetitionFilters] = useState([])
  const [worksiteFilters, setWorksiteFilters] = useState([])

  const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(evt.target.value)
    handleFiltersChange({ searchVal: evt.target.value })
    SegmentService.track('commuters-group-search', {
      searchLength: evt.target.value.length,
    })
  }

  const handleSearchClear = useCallback(() => {
    setSearchVal('')
    if (isPending) {
      const filterLen = worksiteFilters.length
      const newFilteredCommuters = (myCommuters as Array<Commuter>).filter(
        (commuter) =>
          worksiteFilters.includes(commuter.worksiteId) || filterLen === 0
      )
      setFilteredPendingCommuters(newFilteredCommuters)
    } else {
      const filterLen = programFilters.length + competitionFilters.length
      const newFilteredCommuters = (myCommuters as Array<MyCommuter>).filter(
        (commuter) =>
          filterLen === 0 ||
          commuter.programsEnrolled.some((program) =>
            programFilters.includes(program as ProgramType)
          ) ||
          commuter.competitionsEnrolled.some((competition) =>
            competitionFilters.includes(competition)
          ) ||
          worksiteFilters.includes(commuter.worksiteId)
      )

      setFilteredCommuters(newFilteredCommuters)
    }
    SegmentService.track('commuters-group-search', {
      action: 'clear',
    })
  }, [
    myCommuters,
    programFilters,
    competitionFilters,
    worksiteFilters,
    setFilteredCommuters,
    setFilteredPendingCommuters,
    isPending,
  ])

  const handleModalClose = () => {
    setIsModalOpen(false)
    SegmentService.track('commuters-group-filter', {
      action: 'close',
    })
  }

  const handleDropdownFilterChange = ({ id }) => {
    setSortByFilter(id)
    SegmentService.track('commuters-group-sort', {
      orderBy: id,
    })
  }

  const handleFiltersChange = ({
    newProgramFilters = null,
    newCompetitionFilters = null,
    newWorksiteFilters = null,
    searchVal = '',
  }: HandleFiltersChangeParams) => {
    setIsModalOpen(false)

    //this is searching for pending employees
    //the only difference is the worksite filters vs
    //programs and competitions
    if (isPending) {
      let newFilteredCommuters: Commuter[]
      if (newWorksiteFilters) {
        setWorksiteFilters(newWorksiteFilters as string[])
        const filterLen = newWorksiteFilters.length
        newFilteredCommuters = (myCommuters as Array<Commuter>).reduce(
          (filteredCommuters, commuter) => {
            if (
              filterLen === 0 ||
              newWorksiteFilters.includes(commuter.worksiteId)
            ) {
              if (
                commuter.name.toLowerCase().includes(searchVal.toLowerCase()) ||
                commuter.email
                  .toLowerCase()
                  .includes(searchVal.toLowerCase()) ||
                (commuter.employeeCorporateId &&
                  commuter.employeeCorporateId
                    .toLowerCase()
                    .includes(searchVal.toLowerCase()))
              ) {
                filteredCommuters.push(commuter)
              }
            }
            return filteredCommuters
          },
          []
        )
      } else {
        const filterLen = worksiteFilters.length
        newFilteredCommuters = (myCommuters as Array<Commuter>).reduce(
          (filteredCommuters, commuter) => {
            if (
              filterLen === 0 ||
              worksiteFilters.includes(commuter.worksiteId)
            ) {
              if (
                commuter.name.toLowerCase().includes(searchVal.toLowerCase()) ||
                commuter.email
                  .toLowerCase()
                  .includes(searchVal.toLowerCase()) ||
                (commuter.employeeCorporateId &&
                  commuter.employeeCorporateId
                    .toLowerCase()
                    .includes(searchVal.toLowerCase()))
              ) {
                filteredCommuters.push(commuter)
              }
            }
            return filteredCommuters
          },
          []
        )
      }
      setFilteredPendingCommuters(newFilteredCommuters)
    } else {
      let newFilteredCommuters: MyCommuter[]
      if (newProgramFilters || newCompetitionFilters) {
        setProgramFilters(newProgramFilters as ProgramType[])
        setCompetitionFilters(newCompetitionFilters as LeaderboardType[])

        const filterLen =
          newProgramFilters.length + newCompetitionFilters.length
        newFilteredCommuters = (myCommuters as Array<MyCommuter>).reduce(
          (filteredCommuters, commuter) => {
            if (
              commuter.programsEnrolled.some((program) =>
                newProgramFilters.includes(program)
              ) ||
              commuter.competitionsEnrolled.some((competition) =>
                newCompetitionFilters.includes(competition)
              ) ||
              filterLen === 0
            ) {
              if (
                commuter.name.toLowerCase().includes(searchVal.toLowerCase()) ||
                commuter.email
                  .toLowerCase()
                  .includes(searchVal.toLowerCase()) ||
                (commuter.employeeCorporateId &&
                  commuter.employeeCorporateId
                    .toLowerCase()
                    .includes(searchVal.toLowerCase()))
              ) {
                filteredCommuters.push(commuter)
              }
            }
            return filteredCommuters
          },
          []
        )
      } else {
        const filterLen = programFilters.length + competitionFilters.length
        newFilteredCommuters = (myCommuters as Array<MyCommuter>).reduce(
          (filteredCommuters, commuter) => {
            if (
              commuter.programsEnrolled.some((program) =>
                programFilters.includes(program as ProgramType)
              ) ||
              commuter.competitionsEnrolled.some((competition) =>
                competitionFilters.includes(competition)
              ) ||
              filterLen === 0
            ) {
              if (
                commuter.name.toLowerCase().includes(searchVal.toLowerCase()) ||
                commuter.email
                  .toLowerCase()
                  .includes(searchVal.toLowerCase()) ||
                (commuter.employeeCorporateId &&
                  commuter.employeeCorporateId
                    .toLowerCase()
                    .includes(searchVal.toLowerCase()))
              ) {
                filteredCommuters.push(commuter)
              }
            }
            return filteredCommuters
          },
          []
        )
      }
      setFilteredCommuters(newFilteredCommuters)
    }
  }

  const downloadCsv = () => {
    dispatch(
      employeeActions.getMyCommuters({ csv: true, segment: activeSegment })
    )
    SegmentService.track('commuters-group-download', {
      groupName: segmentsMap[activeSegment].name,
    })
  }

  const canDownload =
    myCommuters.length > 0 &&
    !isAdd &&
    !isPending &&
    activeSegment !== 'archived'

  return (
    <>
      <Header>
        <SearchInput
          barBg='white'
          variant='xsmall'
          placeholder='Search...'
          value={searchVal}
          onsearch={handleSearch}
          onclearSearch={handleSearchClear}
          onChange={handleSearch}
          flex={1}
        />
        <TextButton
          size='small'
          iconLeft={<FilterIcon />}
          active={
            programFilters.length +
              competitionFilters.length +
              worksiteFilters.length >
            0
          }
          margin='0 1.5rem'
          height='2rem'
          disabled={activeSegment === 'archived'}
          onClick={() => {
            setIsModalOpen(true)
            SegmentService.track('commuters-group-filter', {
              action: 'open',
            })
          }}
        >
          {filteredCommuters.length}
        </TextButton>
        <Dropdown
          items={dropdownFilters}
          active={sortByFilter}
          itemCallback={handleDropdownFilterChange}
          icon={<SortIcon />}
          variant={'textual'}
        />
        {!isPending && (
          <CustomButton
            disabled={!canDownload}
            onClick={() => canDownload && downloadCsv()}
          >
            <Text
              variant='action3'
              lineHeight={pxToRem(22)}
              textColor={!canDownload && palette.text.disabled}
            >
              CSV
            </Text>
          </CustomButton>
        )}
      </Header>
      <MyCommutersFilterModal
        open={isModalOpen}
        closeModal={handleModalClose}
        programFilters={programFilters}
        competitionFilters={competitionFilters}
        worksiteFilters={worksiteFilters}
        handleFiltersChange={handleFiltersChange}
        searchVal={searchVal}
        isPending={isPending ? true : false}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommutersSearch.displayName = 'MyCommutersSearch'
}

export default MyCommutersSearch
