import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Commuter } from 'redux/employee/employeeTypes'
import RandomUtils from 'shared/RandomUtils'

const getEmployees = (employeeIds: string[], allCommuters: Commuter[]) => {
  return employeeIds.flatMap((id) => {
    const activeCommuter = allCommuters.find((commuter) => commuter.id === id)
    if (activeCommuter) {
      return (
        <FlexContainer
          marginTop='0.75rem'
          alignItems='center'
          key={activeCommuter.id}
        >
          <PlatformSvg
            variant='userBasic'
            folder='users'
            width={40}
            height={40}
          />
          <FlexContainer marginLeft='1.5rem' flexDirection='column'>
            <Text variant='action4'>{activeCommuter.name}</Text>
            <Text variant='body2' textColor={palette.text.placeholder}>
              #{activeCommuter.employeeCorporateId}
            </Text>
          </FlexContainer>
        </FlexContainer>
      )
    } else {
      return []
    }
  })
}

interface SegmentsUpcomingChangesProps {
  closeModal: () => void
  open: boolean
  custom: boolean
  activeSegmentId: string
  upcomingAdditions: string[]
  upcomingRemovals: string[]
}

const SegmentsUpcomingChanges = React.memo(
  (props: SegmentsUpcomingChangesProps) => {
    const dispatch = useDispatch()

    const {
      open,
      custom,
      closeModal,
      activeSegmentId,
      upcomingAdditions,
      upcomingRemovals,
    } = props

    const { allCommuters } = useSelector((state: AppState) => state.employee)

    const handleForceActivateUpcoming = (
      segmentId: string,
      upcomingEmployees: string[]
    ) => {
      dispatch(
        employerActions.activateSegmentUpcomingAdditions({
          segmentId: segmentId,
          employees: upcomingEmployees,
        })
      )
      closeModal()
      SegmentService.track('commuters-group-manage', {
        action: 'activateSegmentUpcomingAdditions',
        numEmployees: [upcomingAdditions].length,
      })
    }

    const handleForceRemoveUpcoming = (
      segmentId: string,
      upcomingRemovals: string[]
    ) => {
      dispatch(
        employerActions.removeSegmentUpcomingRemovals({
          segmentId: segmentId,
          employees: upcomingRemovals,
        })
      )
      closeModal()
      SegmentService.track('commuters-group-manage', {
        action: 'removeSegmentUpcomingRemovals',
        numEmployees: [upcomingRemovals].length,
      })
    }

    return (
      <Modal
        open={open}
        onClose={() => closeModal()}
        width={pxToRem(600)}
        maxHeight='unset'
      >
        <ModalHeader title='Upcoming Changes' />
        <ModalBody maxHeight={pxToRem(600)}>
          <Text variant='body1'>
            {custom
              ? 'Since there is an active product for this segment, all upcoming changes will be applied to the group the following month. You can overrule this logic by using the buttons below to add or remove all commuters right away.'
              : 'Since there is an active product for this segment, all upcoming changes will be applied to the group the following month. You can overrule the addition logic by using the button below to add all commuters right away. You cannot force remove commuters from worksite or all commuters segments.'}
          </Text>
          {upcomingRemovals?.length > 0 && (
            <>
              <Divider margin='1rem 0' />
              <FlexContainer alignItems='center' justifyContent='space-between'>
                <Text
                  variant='caption'
                  captionTitle
                  textColor={palette.text.secondary}
                >
                  Commuter{RandomUtils.pluralCheck(upcomingRemovals.length)}{' '}
                  Removed
                </Text>
                {custom && (
                  <Button
                    size='small'
                    onClick={() =>
                      handleForceRemoveUpcoming(
                        activeSegmentId,
                        upcomingRemovals
                      )
                    }
                    data-cy='segments-upcoming-changes-remove-btn'
                  >
                    Remove All Now
                  </Button>
                )}
              </FlexContainer>
              {getEmployees(upcomingRemovals, allCommuters)}
            </>
          )}
          {upcomingAdditions?.length > 0 && (
            <>
              <Divider margin='1rem 0' />
              <FlexContainer alignItems='center' justifyContent='space-between'>
                <Text
                  variant='caption'
                  captionTitle
                  textColor={palette.text.secondary}
                >
                  Commuter{RandomUtils.pluralCheck(upcomingAdditions.length)}{' '}
                  Added
                </Text>
                <Button
                  size='small'
                  onClick={() =>
                    handleForceActivateUpcoming(
                      activeSegmentId,
                      upcomingAdditions
                    )
                  }
                  data-cy='segments-upcoming-changes-add-btn'
                >
                  Add All Now
                </Button>
              </FlexContainer>
              {getEmployees(upcomingAdditions, allCommuters)}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => closeModal()}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SegmentsUpcomingChanges.displayName = 'SegmentsUpcomingChanges'
}

export default SegmentsUpcomingChanges
