import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <circle cx='12' cy='12' r='11.5' fill='#947BFF' stroke='white' />
    <path
      d='M15.225 7.44V9.6C14.415 9.13 13.575 8.895 12.705 8.895C12.315 8.895 12.015 8.975 11.805 9.135C11.605 9.295 11.505 9.5 11.505 9.75C11.505 10 11.63 10.225 11.88 10.425C12.13 10.615 12.505 10.82 13.005 11.04C13.315 11.18 13.57 11.305 13.77 11.415C13.98 11.515 14.215 11.66 14.475 11.85C14.745 12.03 14.955 12.225 15.105 12.435C15.265 12.635 15.4 12.885 15.51 13.185C15.62 13.485 15.675 13.81 15.675 14.16C15.675 14.92 15.43 15.565 14.94 16.095C14.46 16.625 13.805 16.97 12.975 17.13V18.87H11.16V17.175C10.33 17.095 9.64 16.9 9.09 16.59V14.265C10 14.925 10.97 15.255 12 15.255C12.35 15.255 12.64 15.165 12.87 14.985C13.1 14.795 13.215 14.55 13.215 14.25C13.215 14.15 13.2 14.055 13.17 13.965C13.14 13.875 13.085 13.79 13.005 13.71C12.925 13.63 12.845 13.565 12.765 13.515C12.695 13.455 12.585 13.39 12.435 13.32C12.295 13.24 12.175 13.175 12.075 13.125C11.975 13.075 11.825 13.005 11.625 12.915C11.435 12.825 11.28 12.755 11.16 12.705C10.51 12.405 9.985 12.035 9.585 11.595C9.195 11.155 9 10.57 9 9.84C9 9.07 9.255 8.43 9.765 7.92C10.275 7.4 10.985 7.085 11.895 6.975V5.25H13.725V7.005C14.255 7.085 14.755 7.23 15.225 7.44Z'
      fill='white'
    />
  </>,
  'Bonus Medal'
)
