import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <g filter='url(#filter0_ddd)'>
      <path
        d='M12 26.8664V2.5H32V26.8664C32 27.6135 31.2105 28.0968 30.5452 27.757L22 23.3929L13.4548 27.757C12.7895 28.0968 12 27.6135 12 26.8664Z'
        fill='#BEB3FF'
      />
      <path
        d='M12 1.75H11.25V2.5V26.8664C11.25 28.1739 12.6316 29.0196 13.796 28.4249L22 24.235L30.204 28.4249C31.3684 29.0196 32.75 28.1739 32.75 26.8664V2.5V1.75H32H12Z'
        stroke='white'
        strokeWidth='1.5'
      />
    </g>
    <path
      d='M21.3237 7.08146C21.5366 6.4263 22.4634 6.4263 22.6763 7.08146L23.6365 10.0365C23.7317 10.3295 24.0047 10.5279 24.3128 10.5279H27.4199C28.1087 10.5279 28.3952 11.4094 27.8379 11.8143L25.3242 13.6406C25.0749 13.8217 24.9706 14.1426 25.0658 14.4356L26.026 17.3907C26.2388 18.0458 25.489 18.5906 24.9317 18.1857L22.418 16.3594C22.1687 16.1783 21.8313 16.1783 21.582 16.3594L19.0683 18.1857C18.511 18.5906 17.7612 18.0458 17.974 17.3907L18.9342 14.4356C19.0294 14.1426 18.9251 13.8217 18.6758 13.6406L16.1621 11.8143C15.6048 11.4094 15.8913 10.5279 16.5801 10.5279H19.6872C19.9953 10.5279 20.2683 10.3295 20.3635 10.0365L21.3237 7.08146Z'
      fill='#6200E0'
    />
    <defs>
      <filter
        id='filter0_ddd'
        x='0.5'
        y='-8'
        width='43'
        height='50.3697'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='1' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.670588 0 0 0 0 0.670588 0 0 0 0 0.737255 0 0 0 0.12 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='5' />
        <feGaussianBlur stdDeviation='4' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.670588 0 0 0 0 0.670588 0 0 0 0 0.737255 0 0 0 0.14 0'
        />
        <feBlend
          mode='normal'
          in2='effect1_dropShadow'
          result='effect2_dropShadow'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='3' />
        <feGaussianBlur stdDeviation='2.5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.670588 0 0 0 0 0.670588 0 0 0 0 0.737255 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='effect2_dropShadow'
          result='effect3_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect3_dropShadow'
          result='shape'
        />
      </filter>
    </defs>
  </>,
  'Partner',
  '0 0 44 43',
  43,
  44
)
