import Button from 'core-system/Button'
import { ColumnCard } from 'core-system/ColumnView'
import FlexContainer from 'core-system/FlexContainer'
import IncentivesIcon from 'core-system/Icons/Misc/Incentives'
import SummaryBudget from 'core-system/Program/Summary/SummaryBudget'
import SummaryOverview from 'core-system/Program/Summary/SummaryOverview'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React from 'react'
import SegmentService from 'redux/config/services/SegmentService'
import { Segment } from 'redux/employer/employerTypes'
import { ModeType } from 'redux/incentives/incentivesTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'

const Container = styled(ColumnCard)`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
`

const OverviewContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem 0;
`

const OverviewHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

const RewardHeader = styled(OverviewHeader)`
  margin-bottom: 1rem;
`

const RewardRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`

const getRewards = (modeTypes: ModeType[]) => {
  return modeTypes.flatMap((modeType) => {
    if (modeType.id && (modeType.perTrip || modeType.max)) {
      const perTrip = FormattingUtils.formatDollar(
        modeType.perTrip ? modeType.perTrip * 100 : 0,
        2
      )
      const max = modeType.max
        ? FormattingUtils.formatDollar(modeType.max * 100, 2)
        : 'No max'

      return (
        <RewardRow key={modeType.id}>
          <Text variant='action4' style={{ textTransform: 'capitalize' }}>
            {modeType.text}
          </Text>
          <FlexContainer>
            <Text variant='action4' marginRight='0.5rem' width={pxToRem(60)}>
              {perTrip}
            </Text>
            <Text variant='action4' width={pxToRem(60)}>
              {max}
            </Text>
          </FlexContainer>
        </RewardRow>
      )
    } else {
      return []
    }
  })
}

interface ActivateIncentiveSummaryProps {
  modeTypes: ModeType[]
  startDate: string
  segment: Segment
  monthlyMax: number
  canEdit?: boolean
  toggleEdit?: () => void
}

const ActivateIncentiveSummary = React.memo(
  (props: ActivateIncentiveSummaryProps) => {
    const { modeTypes, startDate, segment, monthlyMax, canEdit, toggleEdit } =
      props
    const budget = monthlyMax || 0
    const noActiveIncentives = modeTypes.length === 1 && !modeTypes[0].text

    return (
      <Container>
        <Text variant='h4' marginBottom='1.5rem'>
          My Incentives
        </Text>
        <SummaryOverview
          startDate={moment(startDate).format('MMMM Do, YYYY')}
          currentSegment={segment}
          marginBottom='1rem'
        />
        <OverviewContainer>
          <RewardHeader>
            <IncentivesIcon />
            <Text variant='action3' marginLeft='0.5rem'>
              Rewards
            </Text>
          </RewardHeader>
          {noActiveIncentives ? (
            <Text
              variant='body2'
              marginTop='0.5rem'
              textColor={palette.text.secondary}
            >
              No incentives selected
            </Text>
          ) : (
            <>
              <RewardRow>
                <Text
                  variant='caption'
                  captionTitle
                  textColor={palette.text.secondary}
                >
                  Mode
                </Text>
                <FlexContainer>
                  <Text
                    variant='caption'
                    captionTitle
                    textColor={palette.text.secondary}
                    marginRight='0.5rem'
                    width={pxToRem(60)}
                  >
                    Per Trip
                  </Text>
                  <Text
                    variant='caption'
                    captionTitle
                    textColor={palette.text.secondary}
                    width={pxToRem(60)}
                  >
                    Max
                  </Text>
                </FlexContainer>
              </RewardRow>
              {getRewards(modeTypes)}
            </>
          )}
        </OverviewContainer>
        <SummaryBudget
          title='Monthly Max'
          metric='per commuter'
          numEmps={segment.employees.length}
          budget={budget}
          marginBottom='1.5rem'
        />
        <Text
          variant='body2'
          textColor={palette.text.placeholder}
          marginBottom={!canEdit && '1.5rem'}
        >
          All budget amounts are estimated based on max commuter spend.
        </Text>
        {!canEdit && (
          <Button
            width='100%'
            onClick={() => {
              toggleEdit()
              SegmentService.track('incentive-manage-action', {
                action: 'edit',
                segmentName: segment.name,
              })
            }}
          >
            Edit Next Month&apos;s Incentives
          </Button>
        )}
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ActivateIncentiveSummary.displayName = 'ActivateIncentiveSummary'
}

export default ActivateIncentiveSummary
