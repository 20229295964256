import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M18.3587 6.31208L17.0887 5.04108C16.5967 4.55108 15.7987 4.55108 15.3057 5.04108L14.0887 6.25808L16.9387 9.10608L18.3587 7.68808C18.7397 7.30608 18.7397 6.69208 18.3587 6.31208Z' />
    <path d='M13.3467 6.99908L16.1967 9.84708L8.98369 17.0551L6.13469 14.2081L13.3467 6.99908Z' />
    <path d='M5.03569 18.5971C4.80269 18.6521 4.59169 18.4431 4.64269 18.2101L5.36369 14.9771L8.21169 17.8271L5.03569 18.5971Z' />
  </>,
  'Edit'
)
