import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='52' height='52' rx='26' fill='#F0F0FF' />
    <path
      d='M23.3333 28.6667H24.32L21.76 17.4133C21.48 16.2 20.4 15.3333 19.16 15.3333H14V18H19.16L21.0533 26.3333H21.04C18.16 27.2 15.96 29.64 15.4533 32.6667H10V35.3333H18V34C18 31.0533 20.3867 28.6667 23.3333 28.6667Z'
      fill='#5E5EAA'
    />
    <path
      d='M35.3334 20.6667H34.24L32.44 15.7467C32.0667 14.6933 31.0667 14 29.9467 14H24.6667V16.6667H29.9467L31.4134 20.6667H23.8667L24.48 23.3333H30C29.4267 24.1067 29 25 28.8 26H25.08L25.6934 28.6667H28.8C29.3867 31.64 31.88 33.84 35 33.9867C38.7334 34.16 42 31.0667 42 27.32C42 23.6 39.0667 20.6667 35.3334 20.6667ZM35.3334 31.3333C33.0934 31.3333 31.3334 29.5733 31.3334 27.3333C31.3334 26.0933 31.88 25.0267 32.7334 24.2933L34.0134 27.8133L36.52 26.9067L35.2267 23.3467C35.2667 23.3467 35.3067 23.3333 35.3467 23.3333C37.5867 23.3333 39.3467 25.0933 39.3467 27.3333C39.3467 29.5733 37.5734 31.3333 35.3334 31.3333Z'
      fill='#5E5EAA'
    />
    <path
      d='M23.3335 30C21.1202 30 19.3335 31.7867 19.3335 34C19.3335 36.2133 21.1202 38 23.3335 38C25.5468 38 27.3335 36.2133 27.3335 34C27.3335 31.7867 25.5468 30 23.3335 30ZM23.3335 35.3333C22.6002 35.3333 22.0002 34.7333 22.0002 34C22.0002 33.2667 22.6002 32.6667 23.3335 32.6667C24.0668 32.6667 24.6668 33.2667 24.6668 34C24.6668 34.7333 24.0668 35.3333 23.3335 35.3333Z'
      fill='#5E5EAA'
    />
  </>,
  'Micromobility',
  '0 0 52 52'
)
