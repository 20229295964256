import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import Checkmark from 'core-system/Icons/Actions/Checkmark'
import Search from 'core-system/Icons/Actions/Search'
import LoadingBar from 'core-system/LoadingBar'
import React, { ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import Markdown from 'react-markdown'
import { v4 as uuidv4 } from 'uuid'

const SearchDiv = styled.button`
  background-color: ${(props) => props.theme.palette.grey.grey1};
  padding: 1rem;
  margin-top: 0.5rem;
  width: 100%;
  text-align: left;
  display: flex;
  gapx: 1rem;
  justify-content: start;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey1};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;
`

const Bold = styled.span`
  font-weight: 600;
`

const HeaderTextContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-left: 0.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-left: 1rem;
  }
`

const Title = styled.div`
  ${(props) => props.theme.typography.action2}
`

const Heading = styled(Title)`
  ${(props) => props.theme.typography.h3}
  margin-bottom:0.5rem;
`

const Subtitle = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.secondary};
`

const Chatbox = styled.div<{ sender: String }>`
  padding: 0 0.75rem;
  border-radius: ${(props) =>
    props.sender === 'user'
      ? '0.5rem 0.5rem 0 0.5rem'
      : '0.5rem 0.5rem 0.5rem 0'};
  ${(props) => props.sender === 'ai' && 'min-width:50%;'}
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    max-width: 100%;
  }
  max-width: 70%;
  background-color: ${(props) =>
    props.sender === 'user'
      ? props.theme.palette.grey.grey3
      : props.theme.palette.grey.grey1};
`
const ChatContainer = styled.div<{ sender: String }>`
  display: flex;
  justify-content: ${(props) => (props.sender === 'user' ? 'end' : 'start')};
  margin: 0.5rem 0;
`

const Input = styled(TextField)`
  ${(props) => ({
    borderColor: props.theme.palette.grey.grey3,
    backgroundColor: props.theme.palette.grey.grey1,
  })}
`
const CustomImage = styled.img`
  width: 270px;
  height: 270px;
  border-radius: 0.4rem;
  object-fit: contain;
`
const LargeImage = styled.img`
  width: 100%;
  border-radius: 0.4rem;
  object-fit: contain;
`
const useStyles = makeStyles({
  root: {
    '& .MuiDialog-scrollPaper': {
      alignItems: 'flex-start',
    },
  },
}) as any

interface SearchViewProps {
  open: boolean
  onClose: () => void
}

function SearchView({ open, onClose }: SearchViewProps) {
  const classes = useStyles()
  const user = useSelector((state: AppState) => state.user.profile)
  const [searchInput, setSearchInput] = useState('')
  // const [showResults, setShowResults] = useState(false)
  const [chatHistory, setChatHistory] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const getAnswerFromAi = async (question: string, user_id: string) => {
    const formData = new FormData()

    formData.append('question', question)
    formData.append('user_id', user_id)

    const fleetAiInstance = axios.create({
      withCredentials: false,
    })
    const response = await fleetAiInstance.post(
      `https://fleet-ai-assistant.onrender.com/ask_question`,
      formData
    )
    return {
      answer: response.data?.text,
      image: response.data?.image,
    }
  }
  const handleSearch = async () => {
    setIsSearching(true)
    // setShowResults(true)

    const _searchInput = searchInput
    try {
      const { answer, image } = await getAnswerFromAi(_searchInput, user?.id)
      setChatHistory([
        {
          id: uuidv4(),
          text: _searchInput,
          sender: 'user',
        },
        {
          id: uuidv4(),
          text: answer,
          image,
          sender: 'ai',
        },
        ...chatHistory,
      ])
      // Show results
    } catch (e) {
      console.log(e)
    } finally {
      setIsSearching(false)
      setSearchInput('')
    }
  }
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
    // if (e.target.value.length > 0) {
    //   setShowResults(false)
    // }
  }
  // useEffect(() => {
  //   if (open) {
  //     setShowResults(false)
  //   }
  // }, [open])
  if (!open) return null
  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        classes={{ root: classes.root }}
      >
        <DialogTitle id='alert-dialog-title'></DialogTitle>
        <DialogContent>
          <Heading>Fleet AI</Heading>

          <Input
            variant='outlined'
            label=''
            disabled={isSearching}
            placeholder='Search or ask anything'
            value={searchInput}
            onChange={handleInput}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch()
              }
            }}
            onClick={null}
            style={{
              width: '100%',
              margin: 0,
              padding: 0,
              fontSize: '0.75rem',
              outline: 'none',
              background: 'none',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search height={20} width={20} />
                </InputAdornment>
              ),
            }}
          />
          {isSearching ? (
            <>
              <ChatContainer sender='user'>
                <Chatbox sender='user'>
                  <Markdown>{searchInput}</Markdown>
                </Chatbox>
              </ChatContainer>
              <ChatContainer sender='ai'>
                <Chatbox sender='ai'>
                  <LoadingBar />
                </Chatbox>
              </ChatContainer>
            </>
          ) : (
            searchInput && (
              <SearchDiv onClick={handleSearch}>
                <Checkmark height={22} width={20} />
                <HeaderTextContainer>
                  <Title>
                    Can you tell me about{' '}
                    <Bold>{searchInput?.toLowerCase()}</Bold>
                  </Title>
                  <Subtitle>Use AI to answer your question</Subtitle>
                </HeaderTextContainer>
              </SearchDiv>
            )
          )}
          {/* {showResults && */}
          {chatHistory.map((chat) => (
            <div key={chat?.id}>
              <ChatContainer sender={chat?.sender}>
                <Chatbox sender={chat.sender}>
                  <Markdown>{chat?.text}</Markdown>
                </Chatbox>
              </ChatContainer>

              {chat?.image && (
                <ChatContainer
                  sender={chat?.sender}
                  onClick={() => setSelectedImage(chat?.image)}
                >
                  <CustomImage src={chat?.image} />{' '}
                </ChatContainer>
              )}
            </div>
          ))}
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={() => setSelectedImage(null)}
        open={selectedImage !== null}
        maxWidth='md'
      >
        {selectedImage && <LargeImage src={selectedImage} />}
      </Dialog>
    </>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SearchView.displayName = 'SearchView'
}

export default SearchView
