import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <mask
      id='mask0'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='15'
      height='15'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 0H14.412V14.0305H0V0Z'
        fill='white'
      />
    </mask>
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.976 3.00651C5.976 2.32651 6.528 1.77651 7.206 1.77651C7.884 1.77651 8.435 2.32651 8.435 3.00651C8.435 3.35651 8.285 3.66851 8.048 3.89351C7.828 4.10251 7.533 4.23551 7.206 4.23551C6.867 4.23551 6.56 4.09751 6.338 3.87551C6.115 3.65251 5.976 3.34551 5.976 3.00651M13.56 2.15351H10.257C10.196 2.15351 10.142 2.17751 10.085 2.18951C9.726 0.930512 8.579 -0.000488281 7.206 -0.000488281C5.833 -0.000488281 4.685 0.930512 4.327 2.18951C4.269 2.17751 4.216 2.15351 4.155 2.15351H0.851C0.381 2.15351 0 2.53551 0 3.00551C0 3.47551 0.381 3.85751 0.851 3.85751H4.155C4.216 3.85751 4.269 3.83351 4.327 3.82151C4.393 4.05351 4.484 4.27251 4.601 4.47651C2.595 5.41551 1.193 6.97751 1.193 9.26751V12.5655C1.193 13.3645 1.882 14.0305 2.708 14.0305H3.575V11.3795C3.575 9.37851 5.204 7.74851 7.206 7.74851C9.208 7.74851 10.837 9.37851 10.837 11.3795V14.0305H11.704C12.53 14.0305 13.218 13.3645 13.218 12.5655V9.31151C13.218 7.01651 11.811 5.41651 9.798 4.50151C9.92 4.28851 10.017 4.06251 10.085 3.82151C10.142 3.83351 10.196 3.85751 10.257 3.85751H13.56C14.03 3.85751 14.412 3.47551 14.412 3.00551C14.412 2.53551 14.03 2.15351 13.56 2.15351'
        fill='#6200E0'
      />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.20601 9.52502C6.18301 9.52502 5.35101 10.357 5.35101 11.379V14.031V14.778C5.35101 15.8 6.18301 16.633 7.20601 16.633C8.22801 16.633 9.06001 15.8 9.06001 14.778V14.031V11.379C9.06001 10.357 8.22801 9.52502 7.20601 9.52502'
      fill='#6200E0'
    />
  </>,
  'Programs Moped',
  '0 0 18 18'
)
