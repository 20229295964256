import { leaderboardsActions } from 'redux/leaderboards/leaderboardsSlice'
import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import Loading from 'core-system/Loading'
import { ProgramModal, SegmentAndDateConfig } from 'core-system/Program'
import ActivateIncentiveSpending from 'features/Incentives/Activation/components/ActivateIncentiveSpending'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import {
  Leaderboard,
  LeaderboardManageState,
} from 'redux/leaderboards/leaderboardsTypes'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import LeaderboardManagePending from '../components/LeaderboardManagePending'
import LeaderboardUtils from '../LeaderboardUtils'
import ManageLeaderboardBonus from './components/ManageLeaderboardBonus'
import ManageLeaderboardSummary from './components/ManageLeaderboardSummary'

export interface LeaderboardDate {
  val: string
  text: string
}

const StyledColumnView = styled(ColumnView)`
  margin-bottom: 10rem;
  grid-template-columns: 64.5% 34.5%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    max-width: ${(props) => props.theme.pxToRem(700)};
    grid-gap: unset;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem ${(props) => props.theme.pxToRem(52)};
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 5rem);
  box-shadow: ${(props) => props.theme.dropShadows.bottom};
  z-index: ${(props) => props.theme.zIndex.max};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const getDate = (
  field: string,
  currentLeaderboard: Leaderboard,
  startDate: LeaderboardDate,
  endDate: LeaderboardDate
) => {
  if (currentLeaderboard && currentLeaderboard[field]) {
    return moment(currentLeaderboard[field], 'YYYY-MM-DD').format(
      'MMMM Do YYYY'
    )
  } else if (field === 'startDate' && startDate) {
    return startDate.text
  } else {
    return endDate ? endDate.text : null
  }
}

// interface ParamTypes {
//   leaderboardType: string
//   segmentId: string
// }

const ManageLeaderboardView = React.memo(() => {
  const { leaderboardType, segmentId } = useParams()

  const { successTrigger, leaderboardsMap } = useSelector(
    (state: AppState) => state.leaderboards
  )
  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const [showEndDate, setShowEndDate] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)

  const [activeState, setActiveState] = useState('active')
  const [leaderboardState, setLeaderboardState] =
    useState<LeaderboardManageState>({
      active: null,
      nextMonth: null,
    })
  const [pendingLeaderboard, setPendingLeaderboard] = useState(null)

  useEffect(() => {
    if (leaderboardsMap) {
      const leaderboardGroup =
        leaderboardsMap[segmentId] &&
        leaderboardsMap[segmentId].programGroups[leaderboardType.toUpperCase()]

      // if leaderboard is 'PENDING'
      if (leaderboardGroup) {
        if (!leaderboardGroup.active && leaderboardGroup.nextMonth) {
          setLeaderboardState((prevState) => {
            return {
              ...prevState,
              nextMonth: leaderboardGroup.nextMonth,
            }
          })
          setActiveState('nextMonth')
          setIsEdit(true)
          setPendingLeaderboard(leaderboardGroup.nextMonth)
          // if (leaderboardGroup.nextMonth.endDate) {
          //   setShowEndDate(true)
          // }
        } else if (leaderboardGroup.active) {
          setLeaderboardState({
            active: leaderboardGroup.active,
            nextMonth: leaderboardGroup.nextMonth
              ? leaderboardGroup.nextMonth
              : {
                  ...leaderboardGroup.active,
                  startDate: moment()
                    .add(1, 'M')
                    .startOf('month')
                    .format('YYYY-MM-DDTHH:mm:ss'),
                },
          })
          // if (leaderboardGroup.active) {
          //   setShowEndDate(true)
          // }
        }
      }
    }
  }, [leaderboardType, leaderboardsMap, segmentId])

  useEffect(() => {
    if (successTrigger) {
      dispatch(leaderboardsActions.getLeaderboards())
      if (
        !leaderboardsMap[segmentId].programGroups[leaderboardType.toUpperCase()]
          .active
      ) {
        navigate(`/leaderboards/${segmentId}`)
      } else {
        setIsEdit(false)
        setActiveState('active')
        setIsUpdateModalOpen(false)
      }
    }
  }, [
    navigate,
    successTrigger,
    segmentId,
    dispatch,
    leaderboardType,
    leaderboardsMap,
  ])

  if (
    !leaderboardsMap[segmentId] ||
    !leaderboardsMap[segmentId].programGroups[leaderboardType.toUpperCase()]
  ) {
    return <Navigate to={Locations.Leaderboards.Programs} replace />
  }

  if (
    !leaderboardsMap ||
    (!leaderboardState.active && !leaderboardState.nextMonth)
  ) {
    return <Loading fullPage />
  }

  const handleUpdate = () => {
    const nextMonthLeaderboard = leaderboardState.nextMonth
    const bonusesFormatted = nextMonthLeaderboard.bonuses.map((bonus: number) =>
      !bonus ? 0 : bonus
    )
    dispatch(
      leaderboardsActions.updateLeaderboard({
        firstPrize: bonusesFormatted[0],
        secondPrize: bonusesFormatted[1],
        thirdPrize: bonusesFormatted[2],
        endDate: nextMonthLeaderboard.endDate,
        id: nextMonthLeaderboard.id,
        segment: segmentId,
        startDate: nextMonthLeaderboard.startDate,
        queryType: nextMonthLeaderboard.type,
      })
    )
    SegmentService.track('leaderboard-action-click', {
      action: 'update',
      boardType: nextMonthLeaderboard.type.toLowerCase(),
      segmentName: segmentsMap[nextMonthLeaderboard.segment].name,
      startDate: moment(nextMonthLeaderboard.startDate).format('DD-MM-YYYY'),
      endDate: nextMonthLeaderboard.endDate
        ? moment(nextMonthLeaderboard.endDate).format('DD-MM-YYYY')
        : null,
      firstPrize: bonusesFormatted[0],
      secondPrize: bonusesFormatted[1],
      thirdPrize: bonusesFormatted[2],
    })
  }

  const handleCancel = (leaderboardId: string) => {
    dispatch(leaderboardsActions.cancelLeaderboard(leaderboardId))
    const bonusesFormatted = leaderboardState.nextMonth.bonuses.map(
      (bonus: number) => (!bonus ? 0 : bonus)
    )
    SegmentService.track('leaderboard-action-click', {
      action: 'cancel',
      boardType: currentLeaderboard.type.toLowerCase(),
      segmentName: segmentsMap[currentLeaderboard.segment].name,
      startDate: moment(currentLeaderboard.startDate).format('DD-MM-YYYY'),
      endDate: currentLeaderboard.endDate
        ? moment(currentLeaderboard.endDate).format('DD-MM-YYYY')
        : null,
      firstPrize: bonusesFormatted[0],
      secondPrize: bonusesFormatted[1],
      thirdPrize: bonusesFormatted[2],
    })
    setIsCancelModalOpen(false)
  }

  const handleReActivate = () => {
    const hasChanges = LeaderboardUtils.leaderboardHasChanges(
      leaderboardState.active
        ? leaderboardState
        : { ...leaderboardState, active: pendingLeaderboard }
    )

    if (!leaderboardState.active || hasChanges) {
      handleUpdate()
    } else {
      handleCancel(leaderboardState.nextMonth.id)
    }
  }

  const toggleEdit = () => {
    if (isEdit) {
      const leaderboardGroup =
        leaderboardsMap[segmentId] &&
        leaderboardsMap[segmentId].programGroups[leaderboardType.toUpperCase()]
      setLeaderboardState((prevState) => {
        return {
          ...prevState,
          nextMonth: leaderboardGroup.nextMonth
            ? leaderboardGroup.nextMonth
            : prevState.active,
        }
      })
    }

    setIsEdit((prevState) => !prevState)
    setActiveState((prevState) => {
      return prevState === 'active' ? 'nextMonth' : 'active'
    })

    SegmentService.track('leaderboard-manage-action', {
      action: isEdit ? 'close' : 'edit',
      segmentName: segmentsMap[segmentId].name,
    })
  }

  const handleBonusChange = (updatedBonuses: number[]) => {
    setLeaderboardState((prevState) => {
      return {
        ...prevState,
        nextMonth: {
          ...prevState.nextMonth,
          bonuses: updatedBonuses,
        },
      }
    })
  }

  const handleEndDateChange = (iso: string, _text: string) => {
    setLeaderboardState((prevState) => {
      return {
        ...prevState,
        nextMonth: {
          ...prevState.nextMonth,
          endDate: iso,
        },
      }
    })
  }

  const handleUpdateModalToggle = () => {
    setIsUpdateModalOpen((prevState) => !prevState)
  }

  const currentLeaderboard = leaderboardState[activeState]
  const noBonuses =
    currentLeaderboard &&
    currentLeaderboard.bonuses.filter((bonus) => bonus === 0).length === 3
  const startDateFormatted = getDate(
    'startDate',
    currentLeaderboard,
    leaderboardState[activeState].startDate,
    leaderboardState[activeState].endDate
  )
  const endDateFormatted = getDate(
    'endDate',
    currentLeaderboard,
    leaderboardState[activeState].startDate,
    leaderboardState[activeState].endDate
  )

  return (
    <>
      <StyledColumnView>
        <Column>
          <SegmentAndDateConfig
            isActive
            canEdit={isEdit}
            currentSegment={segmentsMap[segmentId]}
            startDate={moment(leaderboardState[activeState].startDate).format(
              'MMMM Do, YYYY'
            )}
            endDate={
              leaderboardState[activeState].endDate &&
              moment(leaderboardState[activeState].endDate).format(
                'MMMM Do, YYYY'
              )
            }
            onEndDateChange={handleEndDateChange}
          />
          <ManageLeaderboardBonus
            bonuses={
              activeState === 'active'
                ? leaderboardState.active.bonuses
                : leaderboardState.nextMonth.bonuses
            }
            setBonuses={handleBonusChange}
            noBonuses={noBonuses}
            currentLeaderboard={currentLeaderboard}
            isActive
            canEdit={isEdit}
          />
          <ActivateIncentiveSpending />
        </Column>
        <Column>
          <ManageLeaderboardSummary
            isActive
            leaderboardType={leaderboardType.toUpperCase()}
            currentSegment={segmentsMap[segmentId]}
            startDate={startDateFormatted}
            endDate={endDateFormatted}
            bonuses={
              activeState === 'active'
                ? leaderboardState.active.bonuses
                : leaderboardState.nextMonth.bonuses
            }
            showEndDate={false}
            canEdit={isEdit}
            toggleEdit={toggleEdit}
          />
          <LeaderboardManagePending
            leaderboardState={
              leaderboardState.active
                ? leaderboardState
                : { ...leaderboardState, active: pendingLeaderboard }
            }
            isPending={!leaderboardState.active && true}
            canEdit={isEdit}
            openUpdateModal={() =>
              setIsUpdateModalOpen((prevState) => !prevState)
            }
          />
        </Column>
      </StyledColumnView>
      {isEdit && (
        <Footer>
          {leaderboardState.nextMonth.status !== 'CANCELLING' && (
            <Button variant='cancel' onClick={() => setIsCancelModalOpen(true)}>
              Cancel Competition
            </Button>
          )}
          <ButtonContainer>
            <Button
              variant='tertiary'
              marginRight='1rem'
              onClick={() => {
                leaderboardState.active
                  ? toggleEdit()
                  : navigate(`/leaderboards/${segmentId}`)
                SegmentService.track('leaderboard-manage-action', {
                  action: 'cancel',
                  boardType: leaderboardType.toLowerCase(),
                  locationAt: `leaderboards-${segmentsMap[segmentId].name}`,
                })
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                leaderboardState.nextMonth.status === 'CANCELLING'
                  ? handleUpdateModalToggle()
                  : handleUpdate()
              }
            >
              Update Competition
            </Button>
          </ButtonContainer>
        </Footer>
      )}
      <ProgramModal
        title='Cancel Competition'
        description='Are you sure you want to cancel this competition? Commuters will no longer be able to compete in this competition.'
        buttonText='Cancel Competition'
        open={isCancelModalOpen}
        closeModal={() => setIsCancelModalOpen(false)}
        buttonCallback={() =>
          handleCancel(
            leaderboardState.active
              ? leaderboardState.active.id
              : leaderboardState.nextMonth.id
          )
        }
      />
      <ProgramModal
        title='Reactivate Competition'
        description='Do you want to reactive this competition? All previous rewards will be available again next month.'
        buttonText='Reactivate Competition'
        open={isUpdateModalOpen}
        closeModal={handleUpdateModalToggle}
        buttonCallback={handleReActivate}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ManageLeaderboardView.displayName = 'ManageLeaderboardView'
}

export default ManageLeaderboardView
