import { createGlobalStyle } from 'styled-components'
export default createGlobalStyle`
  * { box-sizing: border-box; }
  body {
    margin: 0;
  }
  /* For finch connect iframe, so its not blocked by our items */
  body iframe {
    z-index: 1001 !important;
  }

  #app {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    ${(props) => props.theme.typography.BaseFont};
    color: ${(props) => props.theme.palette.primary.primaryPurple};
  }

  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(PolySans-Slim),
      url('/static/fonts/PolySans/PolySans-Slim.otf') format('opentype');
  }
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(PolySans-Neutral),
      url('/static/fonts/PolySans/PolySans-Neutral.otf') format('opentype');
  }
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(PolySans-Median),
      url('/static/fonts/PolySans/PolySans-Median.otf') format('opentype');
  }
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('PolySans-Bulky'), url('/static/fonts/PolySans/PolySans-Bulky.otf') format('opentype');
  }

   /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .mapboxgl-popup-content {
    width: fit-content;
  }

  /* move mapbox logo to the bottom right */
  .mapboxgl-ctrl-bottom-left {
    bottom: 0;
    right: 5px;
    left: unset;
  }

  .mapboxgl-ctrl-bottom-right {
    display: flex;
    align-items: center;
    height: 2.5rem;
  }

  .mapboxgl-ctrl-attrib.mapboxgl-compact {
    margin: 6px 98px 0 0;
    min-height: 1.5rem;
  }

  .mapboxgl-ctrl-attrib-button:focus {
    box-shadow: none;
  }

  .mapboxgl-ctrl-geocoder {
    ${(props) => props.theme.typography.body1}
    color: ${(props) => props.theme.palette.primary.primaryDark};
    box-shadow: ${(props) => props.theme.dropShadows.normal};
    border-radius: 0.5rem;
  }

  .mapboxgl-ctrl-geocoder--button {
    background-color: transparent;

    :hover {
      background-color: transparent !important;
    }
  }

  .mapboxgl-ctrl-geocoder {
    ${(props) => props.theme.typography.body1}
    background-color: ${(props) => props.theme.palette.secondary.purple7};
    border: 1px solid ${(props) => props.theme.palette.grey.grey3};
    border-radius: 0.5rem;
    outline: none !important;

    :focus {
      background-color: ${(props) => props.theme.palette.white};
      border-color: ${(props) => props.theme.palette.text.secondary};
      outline: none !important;
    }

    :hover {
      border-color: ${(props) => props.theme.palette.text.secondary};
    } 
  }

  .mapboxgl-ctrl-geocoder--input {
    outline: none !important;
  }

  .mapboxgl-ctrl-geocoder--powered-by {
    display: none !important;
  }
`
