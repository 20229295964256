import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d='M8.25023 3.15038C7.58357 3.81704 8.6669 3.59504 9.33357 4.92838C10.0002 6.26171 12.0002 5.59504 11.3336 4.26171C11.1929 3.97971 10.6376 3.53104 10.1796 3.19238C10.1796 3.19238 10.5702 2.73438 10.3616 2.24838C10.1529 1.76238 8.25023 3.15038 8.25023 3.15038Z'
      fill='#F7DECE'
    />
    <path
      d='M7.98553 0.458922C6.95886 0.485589 6.14819 1.33892 6.17419 2.36559C6.20086 3.39226 7.05419 4.20292 8.08086 4.17692C9.10753 4.15026 9.91819 3.29692 9.89219 2.27026C9.86619 1.24292 9.01219 0.432255 7.98553 0.458922Z'
      fill='#F7DECE'
    />
    <path
      d='M13.3018 1.49755C12.7318 1.65555 12.0412 1.87222 10.9945 1.10888C10.2472 0.56355 9.54983 0.382217 8.08183 0.70355C6.52316 1.04488 5.42783 2.19288 6.53516 2.50955C7.01849 2.64755 7.36649 2.46288 8.09583 2.38688C9.16583 2.27555 9.60049 3.17955 10.6385 3.17888C12.6692 3.17622 14.1525 1.26155 13.3018 1.49755V1.49755Z'
      fill='#16014A'
    />
    <path
      d='M12.6665 9.59507C12.6665 9.59507 10.6665 10.9284 10.6665 11.5951C10.6665 12.2617 13.3332 14.2617 13.9998 14.9284C14.6665 15.5951 15.9998 15.5951 15.3332 14.2617C14.6665 12.9284 12.6665 9.59507 12.6665 9.59507Z'
      fill='#EEC2AD'
    />
    <path
      d='M13.7562 14.2757C13.7562 14.2757 14.5382 14.8017 15.0642 14.0191C15.5902 13.2364 15.9742 15.1997 15.9742 15.1997C15.9742 15.1997 14.2682 16.8924 12.9602 17.1491C11.6522 17.4057 12.1782 16.6231 12.7035 15.8411L13.7562 14.2757V14.2757Z'
      fill='#005FB8'
    />
    <path
      d='M4.66667 13.5949C2.08933 13.5949 0 15.6842 0 18.2616C0 20.8389 2.08933 22.9282 4.66667 22.9282C7.244 22.9282 9.33333 20.8389 9.33333 18.2616C9.33333 15.6842 7.24467 13.5949 4.66667 13.5949ZM4.66667 21.5949C2.826 21.5949 1.33333 20.1029 1.33333 18.2616C1.33333 16.4202 2.826 14.9282 4.66667 14.9282C6.50733 14.9282 8 16.4202 8 18.2616C8 20.1029 6.508 21.5949 4.66667 21.5949ZM19.3333 13.5949C16.7567 13.5949 14.6667 15.6842 14.6667 18.2616C14.6667 20.8389 16.7567 22.9282 19.3333 22.9282C21.9113 22.9282 24 20.8389 24 18.2616C24 15.6842 21.9113 13.5949 19.3333 13.5949ZM19.3333 21.5949C17.4927 21.5949 16 20.1029 16 18.2616C16 16.4202 17.4927 14.9282 19.3333 14.9282C21.1747 14.9282 22.6667 16.4202 22.6667 18.2616C22.6667 20.1029 21.1747 21.5949 19.3333 21.5949Z'
      fill='#16014A'
    />
    <path
      d='M14.6667 12.2615C14.6667 11.8929 14.344 11.5949 13.9447 11.5949H6.722C6.32333 11.5949 6 11.8929 6 12.2615C6 12.6302 6.32333 12.9282 6.722 12.9282H13.944C14.344 12.9282 14.6667 12.6302 14.6667 12.2615ZM20 18.2615C20 17.8929 19.702 17.5949 19.3333 17.5949H12C11.632 17.5949 11.3333 17.8929 11.3333 18.2615C11.3333 18.6302 11.632 18.9282 12 18.9282H19.3333C19.702 18.9282 20 18.6302 20 18.2615Z'
      fill='#AAB8C2'
    />
    <path
      d='M14.1495 10.2614L11.3721 18.0374C11.2488 18.3834 11.4295 18.7654 11.7761 18.8888C11.8501 18.9154 11.9255 18.9281 12.0001 18.9281C12.2741 18.9281 12.5308 18.7581 12.6281 18.4854L15.5655 10.2614H14.1495Z'
      fill='#AAB8C2'
    />
    <path
      d='M19.3339 18.9283C19.1139 18.9283 18.8979 18.819 18.7706 18.6196L14.1039 11.2863C13.9059 10.9756 13.9979 10.5636 14.3086 10.3656C14.6199 10.1676 15.0313 10.2596 15.2293 10.5703L19.8959 17.9036C20.0939 18.2143 20.0019 18.6263 19.6913 18.8243C19.5799 18.8943 19.4566 18.9283 19.3339 18.9283V18.9283ZM12.6666 16.0843L7.07527 11.735C6.78527 11.5083 6.36661 11.5616 6.13994 11.8523C5.91394 12.1436 5.96661 12.5616 6.25727 12.7876L12.2573 17.4543C12.3786 17.5496 12.5226 17.595 12.6659 17.595H12.6666V16.0843V16.0843Z'
      fill='#AAB8C2'
    />
    <path
      d='M4.66662 18.9283C4.59728 18.9283 4.52595 18.9169 4.45595 18.8936C4.10662 18.7776 3.91795 18.4003 4.03462 18.0503L5.88262 12.5063L3.60062 10.7943C3.30595 10.5736 3.24662 10.1556 3.46728 9.86093C3.68795 9.5676 4.10528 9.50693 4.40062 9.7276L7.45195 12.0163L5.29995 18.4723C5.20595 18.7516 4.94528 18.9283 4.66662 18.9283V18.9283Z'
      fill='#AAB8C2'
    />
    <path
      d='M17.5885 11.5948C17.5685 11.5948 17.5491 11.5935 17.5291 11.5908L13.7478 11.1188C13.4998 11.0881 13.3185 10.8695 13.3345 10.6201C13.3498 10.3708 13.5565 10.1768 13.8058 10.1768C13.8331 10.1768 16.5171 10.1648 17.7985 9.31082C17.9651 9.19948 18.1865 9.20615 18.3478 9.32815C18.5071 9.45148 18.5725 9.66215 18.5085 9.85348L18.0358 11.2715C17.9718 11.4668 17.7898 11.5948 17.5885 11.5948V11.5948ZM7.33313 10.9281H3.9998C3.6318 10.9281 3.33313 10.6295 3.33313 10.2615C3.33313 9.89348 3.6318 9.59481 3.9998 9.59481H7.33313C7.70113 9.59481 7.9998 9.89348 7.9998 10.2615C7.9998 10.6295 7.70113 10.9281 7.33313 10.9281Z'
      fill='#16014A'
    />
    <path
      d='M14.6659 7.59497L11.9999 9.59497L13.9999 11.595L14.6659 7.59497Z'
      fill='#005FB8'
    />
    <path
      d='M13.3325 10.2617C13.3325 10.2617 11.9992 12.2617 11.9992 12.9284C11.9992 13.595 12.6658 15.595 12.6658 17.595C12.6658 19.595 13.9992 19.595 13.9992 18.9284C13.9992 18.2617 15.0532 15.3157 13.9992 14.2617C13.3325 13.595 15.3325 11.595 15.3325 11.595L13.3325 10.2617ZM10.4565 7.79302L8.62317 7.27036C8.32517 8.57436 6.60784 10.261 5.99984 10.261C5.33317 10.261 4.6665 10.261 4.6665 10.9277C4.6665 11.5944 4.6665 11.5944 5.99984 11.5944C7.33317 11.5944 8.6665 10.261 9.33317 9.59436C9.7665 9.16102 10.2058 8.42302 10.4565 7.79302V7.79302Z'
      fill='#F7DECE'
    />
    <path
      d='M14.6658 7.59502L12.6658 10.2617L15.3324 12.2617C15.3324 12.2617 18.6658 8.92836 18.6658 7.59502C18.6658 6.26169 16.6658 5.59502 16.6658 5.59502L14.6658 7.59502Z'
      fill='#0D72D1'
    />
    <path
      d='M13.9993 3.59424C11.9993 2.92758 10.2759 3.31824 9.33259 4.26091C8.66592 4.92758 8.66592 5.59424 8.66592 6.92757C8.66592 7.04491 8.64592 7.16891 8.61725 7.29424L10.4466 7.81691C10.5813 7.47758 10.6666 7.16091 10.6666 6.92757C10.6666 6.26091 11.3333 6.26091 11.9999 6.92757C12.6666 7.59424 13.3333 8.26091 13.9999 8.26091C14.6666 8.26091 17.9999 5.59424 17.9999 5.59424C15.9993 4.92757 15.2646 4.01558 13.9993 3.59424V3.59424ZM12.6666 18.2616C12.6666 18.2616 13.3333 18.9282 13.9999 18.2616C14.6666 17.5949 14.6666 19.5949 14.6666 19.5949C14.6666 19.5949 12.6666 20.9282 11.3333 20.9282C9.99992 20.9282 10.6666 20.2616 11.3333 19.5949L12.6666 18.2616ZM7.33325 0.261575C8.66659 -0.405092 11.3333 0.261575 9.99992 1.59491C9.33325 2.26158 5.33325 2.92824 5.33325 2.92824C5.33325 2.26158 5.99992 0.928241 7.33325 0.261575Z'
      fill='#1E92FF'
    />
  </>,
  'ActiveCommute',
  '0 0 24 23'
)
