import React from 'react'

export interface FocusCallbacks<T = HTMLInputElement> {
  onFocus?(evt: React.FocusEvent<T>): void
  onBlur?(evt: React.FocusEvent<T>): void
}

export interface UseFocusHook<T = HTMLInputElement> {
  focused: boolean
  onFocus(evt: React.FocusEvent<T>): void
  onFocus(evt: React.FocusEvent<T>): void
}

export default function <T = HTMLInputElement>(
  callbacks: FocusCallbacks<T> = {}
) {
  const [focused, setFocus] = React.useState<boolean>(false)

  const onFocus = React.useCallback(
    (evt: React.FocusEvent<T>) => {
      setFocus(true)
      callbacks.onFocus && callbacks.onFocus(evt)
    },
    [setFocus, callbacks]
  )

  const onBlur = React.useCallback(
    (evt: React.FocusEvent<T>) => {
      setFocus(false)
      callbacks.onBlur && callbacks.onBlur(evt)
    },
    [setFocus, callbacks]
  )

  return {
    focused,
    onFocus,
    onBlur,
  }
}
