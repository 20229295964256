import * as React from 'react'

export interface SvgIconProps extends React.SVGProps<SVGSVGElement> {
  /** html class identifier */
  readonly className?: string
  /** html children to render within component */
  readonly children?: React.ReactNode
}

const SvgIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props: SvgIconProps, ref: React.Ref<SVGSVGElement>) => {
    const {
      className = '',
      children,
      fill = 'currentColor',
      viewBox = '0 0 24 24',
      preserveAspectRatio = 'xMidYMid meet',
      height = 24,
      width = 24,
      ...other
    } = props
    return (
      <svg
        ref={ref}
        className={className}
        preserveAspectRatio={preserveAspectRatio}
        fill={fill}
        viewBox={viewBox}
        focusable="false"
        height={height}
        width={width}
        {...other}
      >
        {children}
      </svg>
    )
  }
)

SvgIcon.displayName = 'SvgIcon'

export default SvgIcon
