import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <mask
      id='mask0'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='16'
      height='16'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 0H15.5537V15.5538H0V0Z'
        fill='white'
      />
    </mask>
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.777 13.877C4.413 13.877 1.677 11.141 1.677 7.776C1.677 4.413 4.413 1.677 7.777 1.677C11.14 1.677 13.877 4.413 13.877 7.776C13.877 11.141 11.14 13.877 7.777 13.877M7.777 0C3.482 0 0 3.482 0 7.776C0 12.071 3.482 15.554 7.777 15.554C12.071 15.554 15.554 12.071 15.554 7.776C15.554 3.482 12.071 0 7.777 0'
        fill='#6200E0'
      />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.527 8.52651V9.90951C8.527 10.3235 8.191 10.6595 7.777 10.6595C7.363 10.6595 7.027 10.3235 7.027 9.90951V8.52651H5.644C5.23 8.52651 4.894 8.19051 4.894 7.77651C4.894 7.36251 5.23 7.02651 5.644 7.02651H7.027V5.83251V5.64351C7.027 5.22951 7.363 4.89351 7.777 4.89351C8.191 4.89351 8.527 5.22951 8.527 5.64351V5.83251V7.02651H9.909C10.323 7.02651 10.659 7.36251 10.659 7.77651C10.659 8.19051 10.323 8.52651 9.909 8.52651H8.527ZM11.93 5.83251C11.195 4.26951 9.616 3.17651 7.777 3.17651C5.938 3.17651 4.359 4.26951 3.623 5.83251C3.344 6.42451 3.177 7.07951 3.177 7.77651C3.177 7.84551 3.194 7.91051 3.197 7.97951C3.289 10.0355 4.726 11.7345 6.655 12.2225C7.016 12.3135 7.388 12.3775 7.777 12.3775C8.166 12.3775 8.538 12.3135 8.899 12.2225C10.827 11.7345 12.264 10.0355 12.356 7.97951C12.359 7.91051 12.377 7.84551 12.377 7.77651C12.377 7.07951 12.209 6.42451 11.93 5.83251V5.83251Z'
      fill='#6200E0'
    />
  </>,
  'Programs Rideshare',
  '0 0 16 16'
)
