import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='40' height='40' rx='8' fill='#FFD7EF' />
    <path
      d='M15.3333 30.6666C15.3333 31.4026 14.736 32 14 32H12.6666C11.9306 32 11.3333 31.4026 11.3333 30.6666V26C11.3333 25.264 11.9306 24.6666 12.6666 24.6666H14C14.736 24.6666 15.3333 25.264 15.3333 26V30.6666ZM28.6666 30.6666C28.6666 31.4026 28.0693 32 27.3333 32H26C25.264 32 24.6666 31.4026 24.6666 30.6666V26C24.6666 25.264 25.264 24.6666 26 24.6666H27.3333C28.0693 24.6666 28.6666 25.264 28.6666 26V30.6666Z'
      fill='#292F33'
    />
    <path
      d='M10 19.3333C10 19.702 9.70133 20 9.33333 20H8.66667C8.29867 20 8 19.702 8 19.3333V14.6667C8 14.2987 8.29867 14 8.66667 14H9.33333C9.70133 14 10 14.2987 10 14.6667V19.3333ZM32 19.3333C32 19.702 31.702 20 31.3333 20H30.6667C30.298 20 30 19.702 30 19.3333V14.6667C30 14.2987 30.298 14 30.6667 14H31.3333C31.702 14 32 14.2987 32 14.6667V19.3333Z'
      fill='#99AAB5'
    />
    <path
      d='M31.3334 15.3333C31.3334 15.7013 31.0354 16 30.6667 16H9.33335C8.96535 16 8.66669 15.7013 8.66669 15.3333C8.66669 14.9653 8.96535 14.6666 9.33335 14.6666H30.6667C31.0354 14.6666 31.3334 14.9653 31.3334 15.3333ZM31.3334 18C31.3334 18.3686 31.0354 18.6666 30.6667 18.6666H9.33335C8.96535 18.6666 8.66669 18.3686 8.66669 18C8.66669 17.6313 8.96535 17.3333 9.33335 17.3333H30.6667C31.0354 17.3333 31.3334 17.6313 31.3334 18Z'
      fill='#99AAB5'
    />
    <path
      d='M24 9.33333C24 10.0693 23.4027 10.6667 22.6667 10.6667H17.3333C16.5973 10.6667 16 10.0693 16 9.33333C16 8.59733 16.5973 8 17.3333 8H22.6667C23.4027 8 24 8.59733 24 9.33333Z'
      fill='#C7C7D2'
    />
    <path
      d='M26.6667 9.33337H13.3334C11.8607 9.33337 10.6667 10.5274 10.6667 12V29.3334H29.3334V12C29.3334 10.5274 28.1394 9.33337 26.6667 9.33337Z'
      fill='#C7C7D2'
    />
    <path
      d='M10.6667 18.6666H29.3334V29.3333H10.6667V18.6666Z'
      fill='#FFCC4D'
    />
    <path
      d='M30 29.3333C30 29.702 29.702 30 29.3333 30H10.6667C10.2987 30 10 29.702 10 29.3333V28.6666C10 28.298 10.2987 28 10.6667 28H29.3333C29.702 28 30 28.298 30 28.6666V29.3333ZM29.3333 19.3333C29.3333 20.438 28.438 21.3333 27.3333 21.3333H12.6667C11.562 21.3333 10.6667 20.438 10.6667 19.3333V14.6666C10.6667 13.562 11.562 12.6666 12.6667 12.6666H27.3333C28.438 12.6666 29.3333 13.562 29.3333 14.6666V19.3333Z'
      fill='#66757F'
    />
    <path
      d='M28 18.6667C28 19.4027 27.4027 20 26.6667 20H13.3333C12.5973 20 12 19.4033 12 18.6667V15.3333C12 14.5973 12.5973 14 13.3333 14H26.6667C27.4027 14 28 14.5967 28 15.3333V18.6667Z'
      fill='#88C9F9'
    />
    <path d='M10.6667 24.6666H29.3334V28H10.6667V24.6666Z' fill='#FFAC33' />
    <path
      d='M12 24.6666H16V26.6666H12V24.6666ZM24 24.6666H28V26.6666H24V24.6666Z'
      fill='white'
    />
    <path
      d='M10.6667 24.6666H12V26.6666H10.6667V24.6666ZM28 24.6666H29.3334V26.6666H28V24.6666Z'
      fill='#DD2E44'
    />
    <path
      d='M17.3333 24.6666H22.6666V26.6666H17.3333V24.6666Z'
      fill='#66757F'
    />
    <path
      d='M22.6666 10.3334C22.6666 10.886 22.2186 11.3334 21.6666 11.3334H18.3333C17.7806 11.3334 17.3333 10.886 17.3333 10.3334C17.3333 9.78071 17.7806 9.33337 18.3333 9.33337H21.6666C22.2186 9.33337 22.6666 9.78071 22.6666 10.3334Z'
      fill='#292F33'
    />
  </>,
  'Transit',
  '0 0 40 40'
)
