import { ButtonVariants } from 'core-system/Button/Button'

export const Locations = {
  Login: '/login',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  EmployeeSignUp: '/employee-sign-up',
  EmployerSignUp: '/employer-sign-up',
  EmployerEmailConfirmation: '/email-confirmation',
  // Onboarding: '/onboarding',
  GettingStarted: '/getting-started',

  Dashboard: '/dashboard',

  MapIntelligence: '/map-intelligence',

  Report: '/report',

  ProviderCatalog: '/provider-catalog',

  Flex: {
    Programs: '/flex',
    Overview: '/flex/:segmentId',
    Activation: '/flex/:segmentId/activation',
    Manage: '/flex/:segmentId/manage',
  },

  Micromobility: {
    Programs: '/micromobility',
    Overview: '/micromobility/:segmentId',
    Activation: '/micromobility/:segmentId/activation',
    Manage: '/micromobility/:segmentId/manage',
  },

  QTF: {
    Programs: '/tax-savings',
    Overview: '/tax-savings/:segmentId',
    Activation: '/tax-savings/:segmentId/activation',
    ActivationDefault: '/tax-savings/activation',
    /** ActivationOnboarding is a separate page because we can set path: null
     * in locationdata which prevents the user from navigating backwards using
     * the navigation  bar. This could be reinvestigated in the future.
     */
    ActivationOnboarding: '/tax-savings/activation/onboarding',
    Manage: '/tax-savings/:segmentId/manage',
  },

  GRH: {
    Programs: '/guaranteed-rides',
    Overview: '/guaranteed-rides/:segmentId',
    Activation: '/guaranteed-rides/:segmentId/activation',
    Manage: '/guaranteed-rides/:segmentId/manage',
  },

  OTP: {
    Programs: '/one-time-purchase',
    Overview: '/one-time-purchase/:segmentId',
    Activation: '/one-time-purchase/:segmentId/activation',
    Manage: '/one-time-purchase/:segmentId/manage',
  },

  Incentives: {
    Programs: '/trips',
    Trips: '/trips/:segmentId',
    Activation: '/trips/:segmentId/incentives/activation',
    Manage: '/trips/:segmentId/incentives/manage',
    OTRHistory: '/trips/:segmentId/incentives/otr-history',
  },

  OptIn: {
    Programs: '/opt-in',
    Overview: '/opt-in/:segmentId',
    QuestionOverview: '/opt-in/:segmentId/:questionId',
  },

  OTR: {
    OTRHistory: '/one-time-rewards',
  },

  Leaderboards: {
    Programs: '/leaderboards',
    Leaderboards: '/leaderboards/:segmentId',
    Activation: '/leaderboards/:segmentId/:leaderboardType/activation',
    Manage: '/leaderboards/:segmentId/:leaderboardType/manage',
  },

  Segments: {
    Overview: '/segments/overview',
    UploadHistory: '/segments/upload-history',
  },

  Concierge: {
    Concierge: '/concierge',
    Announcement: '/concierge/announcement',
    Faq: '/concierge/faq',
  },

  Accounts: {
    Profile: '/accounts/profile',
    Billing: '/accounts/billing',
    Products: '/accounts/products',
  },

  ActivateCard: '/activate-card',

  Error: '/error',

  // Employee Platform Locations
  EmployeeOnboarding: '/employee/onboarding',

  EmployeeDashboard: '/employee/dashboard',

  EmployeeSettings: '/employee/settings',

  EmployeePreTax: {
    Overview: '/employee/pretax',
  },

  EmployeeCarpool: {
    Overview: '/employee/carpool',
    CarpoolDetails: '/employee/carpool/:carpoolGroupId',
    CarpoolRequestDetails:
      '/employee/carpool/:carpoolGroupId/:carpoolRequestId',
  },

  EmployeeGRH: {
    Overview: '/employee/grh',
  },

  EmployeeTrips: {
    Overview: '/employee/trips',
    AddTrip: '/employee/trips/add-trip/',
  },

  EmployeeLeaderboards: {
    Overview: '/employee/leaderboards',
  },

  EmployeeOptIn: {
    Overview: '/employee/opt-in',
  },

  EmployeeConcierge: '/employee/concierge',
}

export const arrayOfRoutes = (locations: Dictionary) => {
  const routes = []
  Object.values(locations).forEach((location) => {
    if (typeof location === 'object') {
      routes.push(
        ...Object.values(location).map((x) => {
          return { path: x }
        })
      )
    } else {
      routes.push({ path: location })
    }
  })
  return routes
}

interface LocationData {
  title?: string
  path?: string[]
  ignoreMaxWidth?: boolean
  hasDate?: boolean
  head?: string
  btnVariant?: ButtonVariants
  btnType?: 'sidebar' | 'cta'
  btnText?: string
  analyticsTitle?: string
  noPadding?: boolean
  showVerticalScroll?: boolean
}

export const locationData: Dictionary<LocationData> = {
  // [Locations.Onboarding]: {
  //   title: 'Onboarding',
  //   // ignoreMaxWidth: true,
  //   noPadding: true,
  // },

  [Locations.GettingStarted]: {
    title: '',
    head: 'Getting Started',
    ignoreMaxWidth: true,
    noPadding: true,
  },

  [Locations.Dashboard]: {
    title: 'Dashboard',
    hasDate: true,
  },

  [Locations.MapIntelligence]: {
    title: 'Map Intelligence',
    ignoreMaxWidth: true,
    noPadding: true,
  },

  [Locations.Report]: {
    title: 'Fleet Usage Report',
  },

  [Locations.ProviderCatalog]: {
    title: 'Service Provider Catalog',
    showVerticalScroll: true,
  },

  //Flex
  [Locations.Flex.Programs]: {
    title: 'Flex Programs',
    head: 'Flex',
    btnType: 'cta',
    btnVariant: 'primary',
    btnText: 'New Program',
    analyticsTitle: 'flex',
  },
  [Locations.Flex.Overview]: {
    title: ':id',
    path: [Locations.Flex.Programs, Locations.Flex.Overview],
    hasDate: true,
    head: 'Flex',
    btnType: 'sidebar',
    btnVariant: 'secondary',
    btnText: 'All Programs',
    analyticsTitle: 'flex',
  },
  [Locations.Flex.Activation]: {
    title: 'New Program',
    path: [
      Locations.Flex.Programs,
      Locations.Flex.Overview,
      Locations.Flex.Activation,
    ],
    head: 'Flex',
  },
  [Locations.Flex.Manage]: {
    title: 'Manage Program',
    path: [
      Locations.Flex.Programs,
      Locations.Flex.Overview,
      Locations.Flex.Manage,
    ],
    head: 'Flex',
  },

  //GRH
  [Locations.GRH.Programs]: {
    title: 'Guaranteed Ride Home Programs',
    head: 'Guaranteed Ride',
    btnType: 'cta',
    btnVariant: 'primary',
    btnText: 'New Program',
    analyticsTitle: 'grh',
  },
  [Locations.GRH.Overview]: {
    title: ':id',
    path: [Locations.GRH.Programs, Locations.GRH.Overview],
    hasDate: true,
    head: 'Guaranteed Ride',
    btnType: 'sidebar',
    btnVariant: 'secondary',
    btnText: 'All Programs',
    analyticsTitle: 'grh',
  },
  [Locations.GRH.Activation]: {
    title: 'New Program',
    path: [
      Locations.GRH.Programs,
      Locations.GRH.Overview,
      Locations.GRH.Activation,
    ],
    head: 'Guaranteed Ride',
  },
  [Locations.GRH.Manage]: {
    title: 'Manage Program',
    path: [
      Locations.GRH.Programs,
      Locations.GRH.Overview,
      Locations.GRH.Manage,
    ],
    head: 'Guaranteed Ride',
  },

  //OTP
  [Locations.OTP.Programs]: {
    title: 'One Time Purchase Programs',
    head: 'One Time Purchase',
    btnType: 'cta',
    btnVariant: 'primary',
    btnText: 'New Program',
    analyticsTitle: 'otp',
  },
  [Locations.OTP.Overview]: {
    title: ':id',
    path: [Locations.OTP.Programs, Locations.OTP.Overview],
    hasDate: true,
    head: 'One Time Purchase',
    btnType: 'sidebar',
    btnVariant: 'secondary',
    btnText: 'All Programs',
    analyticsTitle: 'otp',
  },
  [Locations.OTP.Activation]: {
    title: 'New Program',
    path: [
      Locations.OTP.Programs,
      Locations.OTP.Overview,
      Locations.OTP.Activation,
    ],
    head: 'One Time Purchase',
  },
  [Locations.OTP.Manage]: {
    title: 'Manage Program',
    path: [
      Locations.OTP.Programs,
      Locations.OTP.Overview,
      Locations.OTP.Manage,
    ],
    head: 'One Time Purchase',
  },

  //QTF
  [Locations.QTF.Programs]: {
    title: 'Pre-Tax Commuter Programs',
    head: 'Tax Savings',
    btnType: 'cta',
    btnVariant: 'primary',
    btnText: 'New Program',
    analyticsTitle: 'qtf',
  },
  [Locations.QTF.Overview]: {
    title: ':id',
    path: [Locations.QTF.Programs, Locations.QTF.Overview],
    hasDate: true,
    head: 'Tax Savings',
    btnType: 'sidebar',
    btnVariant: 'secondary',
    btnText: 'All Programs',
    analyticsTitle: 'qtf',
  },
  [Locations.QTF.Activation]: {
    title: 'New Program',
    path: [
      Locations.QTF.Programs,
      Locations.QTF.Overview,
      Locations.QTF.Activation,
    ],
    head: 'Tax Savings',
  },
  [Locations.QTF.ActivationOnboarding]: {
    title: '',
    path: null,
    head: 'Tax Savings',
  },
  [Locations.QTF.ActivationDefault]: {
    title: 'New Program',
    path: [Locations.QTF.Programs, Locations.QTF.ActivationDefault],
    head: 'Tax Savings',
  },
  [Locations.QTF.Manage]: {
    title: 'Manage Program',
    path: [
      Locations.QTF.Programs,
      Locations.QTF.Overview,
      Locations.QTF.Manage,
    ],
    head: 'Tax Savings',
  },

  //Micromobility
  [Locations.Micromobility.Programs]: {
    title: 'Micromobility Programs',
    head: 'Micromobility',
    btnType: 'cta',
    btnVariant: 'primary',
    btnText: 'New Program',
    analyticsTitle: 'micromobility',
  },
  [Locations.Micromobility.Overview]: {
    title: ':id',
    path: [Locations.Micromobility.Programs, Locations.Micromobility.Overview],
    hasDate: true,
    head: 'Micromobility',
    btnType: 'sidebar',
    btnVariant: 'secondary',
    btnText: 'All Programs',
    analyticsTitle: 'micromobility',
  },
  [Locations.Micromobility.Activation]: {
    title: 'New Program',
    path: [
      Locations.Micromobility.Programs,
      Locations.Micromobility.Overview,
      Locations.Micromobility.Activation,
    ],
    head: 'Micromobility',
  },
  [Locations.Micromobility.Manage]: {
    title: 'Manage Program',
    path: [
      Locations.Micromobility.Programs,
      Locations.Micromobility.Overview,
      Locations.Micromobility.Manage,
    ],
    head: 'Micromobility',
  },

  //Incentives
  [Locations.Incentives.Programs]: {
    title: 'Trips & Incentives',
    hasDate: false,
    head: 'Trips',
    btnType: 'cta',
    btnVariant: 'primary',
    btnText: 'New Incentives',
    analyticsTitle: 'incentives',
  },
  [Locations.Incentives.Trips]: {
    title: ':id',
    path: [Locations.Incentives.Programs, Locations.Incentives.Trips],
    ignoreMaxWidth: true,
    head: 'Trips',
    btnType: 'sidebar',
    btnVariant: 'secondary',
    btnText: 'All Incentives',
    analyticsTitle: 'incentives',
    noPadding: true,
  },
  [Locations.Incentives.Activation]: {
    title: 'New Incentives',
    path: [
      Locations.Incentives.Programs,
      Locations.Incentives.Trips,
      Locations.Incentives.Activation,
    ],
    head: 'Trips',
  },
  [Locations.Incentives.Manage]: {
    title: 'Manage Incentives',
    path: [
      Locations.Incentives.Programs,
      Locations.Incentives.Trips,
      Locations.Incentives.Manage,
    ],
    head: 'Trips',
  },
  [Locations.Incentives.OTRHistory]: {
    title: 'OTR History',
    path: [
      Locations.Incentives.Programs,
      Locations.Incentives.Trips,
      Locations.Incentives.OTRHistory,
    ],
    head: 'Trips',
  },

  // Opt-In
  [Locations.OptIn.Programs]: {
    title: 'Opt-In',
    hasDate: false,
    head: 'Opt-In',
    // btnType: 'cta',
    // btnVariant: 'primary',
    // btnText: 'New Opt-In',
    analyticsTitle: 'optin',
  },
  [Locations.OptIn.Overview]: {
    title: ':id',
    path: [Locations.OptIn.Programs, Locations.OptIn.Overview],
    hasDate: false,
    head: 'Opt-In',
    // btnType: 'sidebar',
    // btnVariant: 'secondary',
    // btnText: 'All Programs',
    analyticsTitle: 'optin',
  },
  [Locations.OptIn.QuestionOverview]: {
    title: ':questionId',
    path: [
      Locations.OptIn.Programs,
      Locations.OptIn.Overview,
      Locations.OptIn.QuestionOverview,
    ],
    hasDate: false,
    head: 'Opt-In',
    analyticsTitle: 'optin',
  },

  //OTR
  [Locations.OTR.OTRHistory]: {
    title: 'One Time Rewards',
    head: 'One Time Rewards',
    analyticsTitle: 'one-time-rewards',
  },

  //Leaderboards
  [Locations.Leaderboards.Programs]: {
    title: 'Leaderboards',
    btnType: 'cta',
    btnVariant: 'primary',
    btnText: 'New Competition',
    analyticsTitle: 'leaderboards',
  },
  [Locations.Leaderboards.Leaderboards]: {
    title: ':id',
    head: 'Leaderboards',
    btnType: 'sidebar',
    btnVariant: 'secondary',
    btnText: 'All Leaderboards',
    analyticsTitle: 'leaderboards',
    path: [
      Locations.Leaderboards.Programs,
      Locations.Leaderboards.Leaderboards,
    ],
  },
  [Locations.Leaderboards.Activation]: {
    title: 'Activate Competition',
    path: [
      Locations.Leaderboards.Programs,
      Locations.Leaderboards.Leaderboards,
      Locations.Leaderboards.Activation,
    ],
    head: 'Leaderboards',
  },
  [Locations.Leaderboards.Manage]: {
    title: 'Manage Competition',
    path: [
      Locations.Leaderboards.Programs,
      Locations.Leaderboards.Leaderboards,
      Locations.Leaderboards.Manage,
    ],
    head: 'Leaderboards',
  },

  //Segments
  [Locations.Segments.Overview]: {
    title: 'My Groups',
    ignoreMaxWidth: true,
    noPadding: true,
  },
  [Locations.Segments.UploadHistory]: {
    title: 'Upload History',
    path: [Locations.Segments.Overview, Locations.Segments.UploadHistory],
  },

  //Concierge
  [Locations.Concierge.Concierge]: {
    title: 'Concierge',
    ignoreMaxWidth: true,
    noPadding: true,
  },
  [Locations.Concierge.Announcement]: {
    title: 'Announcements',
    path: [Locations.Concierge.Concierge, Locations.Concierge.Announcement],
  },
  [Locations.Concierge.Faq]: {
    title: 'Faqs',
    path: [Locations.Concierge.Concierge, Locations.Concierge.Faq],
  },

  //Accounts
  [Locations.Accounts.Profile]: {
    title: 'Settings',
    ignoreMaxWidth: true,
    noPadding: true,
  },
  [Locations.Accounts.Billing]: {
    title: 'Settings',
    ignoreMaxWidth: true,
    noPadding: true,
  },
  [Locations.Accounts.Products]: {
    title: 'Settings',
    ignoreMaxWidth: true,
    noPadding: true,
  },

  [Locations.Error]: {
    title: 'Not Found',
  },

  // Employee Platform Location Data

  // Employee Profile
  [Locations.EmployeeOnboarding]: {
    title: 'Onboarding',
    ignoreMaxWidth: true,
    noPadding: true,
    analyticsTitle: 'employee-onboarding',
  },

  [Locations.EmployeeSettings]: {
    title: 'Settings',
    analyticsTitle: 'employee-profile-settings',
  },

  // Employee Dashboard
  [Locations.EmployeeDashboard]: {
    title: 'Dashboard',
    analyticsTitle: 'employee-dashboard',
  },

  // Employee PreTax
  [Locations.EmployeePreTax.Overview]: {
    title: 'Pre-Tax Benefits',
    analyticsTitle: 'employee-pretax',
  },

  // Employee Carpool
  [Locations.EmployeeCarpool.Overview]: {
    title: 'Carpool',
    analyticsTitle: 'employee-carpool',
  },
  [Locations.EmployeeCarpool.CarpoolDetails]: {
    title: 'Carpool Details',
    path: [
      Locations.EmployeeCarpool.Overview,
      Locations.EmployeeCarpool.CarpoolDetails,
    ],
    analyticsTitle: 'employee-carpool',
  },
  [Locations.EmployeeCarpool.CarpoolRequestDetails]: {
    title: 'Carpool Request Details',
    path: [
      Locations.EmployeeCarpool.Overview,
      Locations.EmployeeCarpool.CarpoolDetails,
      Locations.EmployeeCarpool.CarpoolRequestDetails,
    ],
    analyticsTitle: 'employee-carpool',
  },

  // Employee GRH
  [Locations.EmployeeGRH.Overview]: {
    title: 'Guaranteed Ride Home',
    analyticsTitle: 'employee-grh',
  },

  // Employee Trips
  [Locations.EmployeeTrips.Overview]: {
    title: 'Trips',
    analyticsTitle: 'employee-trips',
  },
  [Locations.EmployeeTrips.AddTrip]: {
    title: 'Add Trip',
    analyticsTitle: 'employee-trips',
  },

  // Employee Leaderboards
  [Locations.EmployeeLeaderboards.Overview]: {
    title: 'Leaderboards',
    analyticsTitle: 'employee-leaderboards',
  },

  // Employee Opt-Ins
  [Locations.EmployeeOptIn.Overview]: {
    title: 'Opt-In',
    analyticsTitle: 'employee-optin',
  },

  // Employee Concierge
  [Locations.EmployeeConcierge]: {
    title: 'Concierge',
    analyticsTitle: 'employee-concierge',
  },
}
