import CategoryBreakdown from 'core-system/CategoryBreakdown'
import ChipDifference from 'core-system/Chip/ChipDifference'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import RenewIcon from 'core-system/Icons/Actions/Renew'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import LegendColor from 'core-system/Legend'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import {
  ProgramDashboardStats,
  ProgramType,
} from 'redux/programs/programsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import NumberUtils from 'shared/NumberUtils'
import styled from 'styled-components'

const spendTypeMap = {
  leasing: {
    color: palette.chips.magenta2,
    title: 'Leasing',
  },
  shared: {
    color: palette.primary.primaryPurple,
    title: 'Shared Micromobility',
  },
  oneTime: {
    color: palette.secondary.purple5,
    title: 'One-Time Purchase',
  },
  flex: {
    color: palette.primary.primaryDark,
    title: 'Flex',
  },
  gas: {
    color: palette.chips.magenta2,
    title: 'Gas',
  },
  parking: {
    color: palette.secondary.purple5,
    title: 'Parking',
  },
  transit: {
    color: palette.primary.primaryPurple,
    title: 'Public Transportation',
  },
}

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 1.5rem 0 1.5rem 1.5rem;
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: 1.5rem;
  }
`

const HeaderContainer = styled.div`
  width: 70%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 85%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 100%;
  }
`

const HeaderImg = styled.div`
  width: 30%;
  overflow: hidden;
  margin-left: 1.1875rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    display: none;
  }
`

const StyledDivider = styled(Divider)`
  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin: 0 5%;
  }
`

const getCategoryBreakdown = (
  type: ProgramType,
  dashboardStats: ProgramDashboardStats
) => {
  if (type === 'MICROMOBILITY') {
    return [
      {
        value: dashboardStats.totalSpend.shared,
        color: spendTypeMap.shared.color,
        title: spendTypeMap.shared.title,
      },
      {
        value: dashboardStats.totalSpend.leasing,
        color: spendTypeMap.leasing.color,
        title: spendTypeMap.leasing.title,
      },
      //changing amortized to current until we figure out how we want to display it better
      {
        value: dashboardStats.totalSpend.oneTime,
        color: spendTypeMap.oneTime.color,
        title: spendTypeMap.oneTime.title,
      },
    ]
  } else {
    return [
      {
        value: dashboardStats.totalSpend.flex,
        color: spendTypeMap.flex.color,
        title: spendTypeMap.flex.title,
      },
      {
        value: dashboardStats.totalSpend.transit,
        color: spendTypeMap.transit.color,
        title: spendTypeMap.transit.title,
      },
      {
        value: dashboardStats.totalSpend.gas,
        color: spendTypeMap.gas.color,
        title: spendTypeMap.gas.title,
      },
      {
        value: dashboardStats.totalSpend.parking,
        color: spendTypeMap.parking.color,
        title: spendTypeMap.parking.title,
      },
    ]
  }
}

interface ProgramOverviewHeaderProps {
  type: ProgramType
  dashboardStats: ProgramDashboardStats
  segmentId: string
}

const ProgramOverviewHeader = React.memo(
  (props: ProgramOverviewHeaderProps) => {
    const { type, dashboardStats, segmentId } = props

    const { segmentsMap } = useSelector((state: AppState) => state.employer)

    if (!dashboardStats) {
      return <Loading height={pxToRem(287)} />
    }

    const totalBudget =
      dashboardStats.totalSpend.total + dashboardStats.totalSpend.remaining
    const categoryBreakdown = getCategoryBreakdown(type, dashboardStats)
    const daysLeftInMonth = moment().daysInMonth() - moment().date() + 1

    return (
      <Container>
        <HeaderContainer>
          <FlexContainer alignItems='center'>
            <Text variant='h5'>Total Spend</Text>
          </FlexContainer>
          <FlexContainer margin='1.5rem 0 0.5rem' alignItems='center'>
            <PlatformSvg
              folder='metrics'
              variant='money'
              width={40}
              height={40}
            />
            <Text variant='h1' marginLeft='1rem'>
              {FormattingUtils.formatDollar(dashboardStats.totalSpend.total, 0)}
            </Text>
            <ChipDifference
              percentDifference={dashboardStats.totalSpend.difference}
              marginLeft='3.25rem'
            />
          </FlexContainer>
          <CategoryBreakdown
            categories={categoryBreakdown}
            total={totalBudget}
            isCurrency
          />
          <FlexContainer marginTop='0.6875rem'>
            {categoryBreakdown.map((category, idx) => (
              <FlexContainer key={idx} alignItems='center' marginRight='1.5rem'>
                <LegendColor color={category.color} marginRight='0.5rem' />
                <Text
                  variant='caption'
                  captionTitle
                  textColor={palette.text.secondary}
                >
                  {category.title}
                </Text>
              </FlexContainer>
            ))}
            <Divider
              direction='vertical'
              margin='0 2rem 0 0.5rem'
              size='1.5rem'
            />
            <FlexContainer alignItems='center'>
              <RenewIcon color={palette.text.secondary} />
              <Text
                variant='caption'
                captionTitle
                textColor={palette.text.secondary}
                marginLeft='0.5rem'
              >
                Program Resets in {daysLeftInMonth} Days
              </Text>
            </FlexContainer>
          </FlexContainer>
          <Divider margin='1rem 0 1.5rem' />
          <FlexContainer>
            <div>
              <FlexContainer marginBottom='0.5rem' alignItems='center'>
                <PlatformSvg
                  folder='metrics'
                  variant='co2'
                  width={40}
                  height={40}
                />
                <Text variant='h3' marginLeft='1rem'>
                  {NumberUtils.formatNumber(dashboardStats.envSavings, 0)}lb
                </Text>
                <Text
                  variant='body2'
                  textColor={palette.text.disabled}
                  alignSelf='flex-end'
                  margin='0 0 0.3125rem 0.3125rem'
                >
                  /month
                </Text>
                <ChipDifference
                  percentDifference={dashboardStats.envSavingsDifference}
                  marginLeft='4rem'
                />
              </FlexContainer>
              <Text
                variant='caption'
                captionTitle
                textColor={palette.text.secondary}
              >
                Environmental Savings
              </Text>
            </div>
            <StyledDivider
              direction='vertical'
              margin='0 3.25rem'
              size={pxToRem(49)}
            />
            <div>
              <FlexContainer marginBottom='0.5rem' alignItems='center'>
                <PlatformSvg
                  folder='metrics'
                  variant='employees'
                  width={40}
                  height={40}
                />
                <Text variant='h3' marginLeft='1rem'>
                  {FormattingUtils.formatNumber(
                    (dashboardStats.engagement /
                      segmentsMap[segmentId].commuters) *
                      100,
                    0
                  )}
                  %
                </Text>
                <Text
                  variant='body2'
                  textColor={palette.text.disabled}
                  alignSelf='flex-end'
                  margin='0 0 0.3125rem 0.3125rem'
                >
                  ({dashboardStats.engagement}/
                  {segmentsMap[segmentId].commuters})
                </Text>
                <ChipDifference
                  percentDifference={dashboardStats.engagementDifference * 100}
                  marginLeft='4rem'
                />
              </FlexContainer>
              <Text
                variant='caption'
                captionTitle
                textColor={palette.text.secondary}
              >
                Workforce Engagement
              </Text>
            </div>
          </FlexContainer>
        </HeaderContainer>
        <HeaderImg>
          {type === 'MICROMOBILITY' ? (
            <PlatformSvg
              folder='banners'
              variant='banner7'
              width={358}
              height={219}
            />
          ) : (
            <PlatformSvg
              folder='banners'
              variant='banner5'
              width={431}
              height={208}
            />
          )}
        </HeaderImg>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramOverviewHeader.displayName = 'ProgramOverviewHeader'
}

export default ProgramOverviewHeader
