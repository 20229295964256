import Button from 'core-system/Button'
import Chip from 'core-system/Chip'
import Dropdown, { SelectItemProps } from 'core-system/Dropdown'
import AddIcon from 'core-system/Icons/Actions/Add'
import AutomatedAnnouncementIcon from 'core-system/Icons/Concierge/FleetAutomated'
import NormalAnnouncementIcon from 'core-system/Icons/Concierge/Message'
import OverflowIcon from 'core-system/Icons/Misc/Overflow'
import Loading from 'core-system/Loading'
import Table, {
  TableHeader,
  TableHeaderItem,
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { conciergeActions } from 'redux/concierge/conciergeSlice'
import { Announcement } from 'redux/concierge/conciergeTypes'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import useResizeListener from 'shared/Hooks/useResizeListener'
import styled from 'styled-components'
import { getAnnouncementCopy } from '../shared/utils'
import AnnouncementDetailsModal from './AnnouncementDetailsModal'

const statusMap = {
  scheduled: 'purple',
  draft: 'yellow',
  sent: 'green',
  sending: 'blue',
  failed: 'red',
  cancelled: 'grey',
  persistent: 'purple',
}

const headerMap = {
  upcoming: ['scheduled', 'draft', 'sending', 'failed', 'persistent'],
  sent: ['sent'],
  archived: ['cancelled'],
}

const metricsMap = {
  1: 'duplicate',
  2: 'delete',
  3: 'edit',
}

const headerBasedSort = {
  upcoming: 'createdAt',
  sent: 'sentAt',
  archived: 'createdAt',
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
`

const Title = styled.div`
  ${(props) => props.theme.typography.h4}
`

const Subtitle = styled.div`
  ${(props) => props.theme.typography.body1}
  max-width: ${(props) => props.theme.pxToRem(550)};
  margin-bottom: 1.5rem;
`

const EmptyContainer = styled.div`
  padding: ${(props) => props.theme.pxToRem(40)};
  height: ${(props) => props.theme.pxToRem(348)};
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 0.9375rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const IconContainer = styled.div`
  margin-bottom: 2.25rem;
`

const Text = styled.div`
  ${(props) => props.theme.typography.action3}
  padding-bottom: 1.5rem;
`

const UserContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-top: 0.125rem;
  }
`

const HeaderSpan = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  margin-left: 0.5rem;
`

const UserSvg = styled.div`
  margin-right: 1.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  display: flex;
  justify-content: center;
  align-items: center;
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Alias = styled.div`
  ${(props) => props.theme.typography.action4}
  color: ${(props) => props.theme.palette.text.primary};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
`

const Automated = styled.div`
  ${(props) => props.theme.typography.caption}
  border: 2px solid ${(props) => props.theme.palette.grey.grey4};
  background-color: ${(props) => props.theme.palette.grey.grey1};
  color: ${(props) => props.theme.palette.text.placeholder};
  margin-left: 0.5rem;
  border-radius: 0.125rem;
  padding: 0.0625rem 0.125rem;
  text-transform: uppercase;
  line-height: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.125rem;
`

const UserAddress = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.placeholder};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    max-width: 75%;
  }
`

const UserRowCell = styled(TableRowCell)`
  min-width: ${(props) => props.theme.pxToRem(235)};
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
    margin-right: 0;
  }
`

const RoleRowCell = styled(TableRowCell)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    position: absolute;
    right: 0;
    top: ${(props) => props.theme.pxToRem(35)};
    margin-right: 0.5rem;
  }
`

const LocationRowCell = styled(TableRowCell)`
  min-width: ${(props) => props.theme.pxToRem(150)};
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    min-width: unset;
  }
`

const getHeaderSpanColor = (active: boolean, disabled: boolean) => {
  if (active) {
    return palette.white
  } else if (disabled) {
    return palette.text.disabled
  } else {
    return palette.primary.primaryPurple
  }
}

type StatusCountType = {
  [key: string]: number
}

const getTableHeaders = (
  allAnnouncements: Announcement[],
  activeHeader: string,
  updateActiveHeader: (city: string) => void
) => {
  const statusCount: StatusCountType = allAnnouncements.reduce(
    (obj, announcement: Announcement) => {
      obj[announcement.status.toLowerCase()] =
        ++obj[announcement.status.toLowerCase()] || 1
      return obj
    },
    {}
  )

  let upcoming = 0
  for (const status in statusCount) {
    if (headerMap.upcoming.includes(status)) {
      upcoming += statusCount[status]
    }
  }

  const { sent, cancelled } = statusCount
  const headerGroups = {
    upcoming: upcoming,
    sent: sent ? sent : 0,
    archived: cancelled ? cancelled : 0,
  }

  return Object.keys(headerGroups).map((status: string, idx: number) => {
    return (
      <TableHeaderItem
        id={`table-header-${status}`}
        onClick={() => updateActiveHeader(status)}
        key={idx}
        active={activeHeader === status}
        disabled={headerGroups[status] === 0}
      >
        {status}
        <HeaderSpan
          color={getHeaderSpanColor(
            activeHeader === status,
            headerGroups[status] === 0
          )}
        >
          {headerGroups[status]}
        </HeaderSpan>
      </TableHeaderItem>
    )
  })
}

const getTableRows = (
  segmentsMap: Dictionary<Segment>,
  allAnnouncements: Announcement[],
  activeHeader: string,
  isTablet: boolean,
  menuCallback: (item: SelectItemProps) => void,
  setIsDetailModalOpen: any,
  setSelectedAnnouncement: any
) => {
  return allAnnouncements
    .filter((a) => headerMap[activeHeader].includes(a.status.toLowerCase()))
    .sort(
      (a, b) =>
        new Date(b[headerBasedSort[activeHeader]]).getTime() -
        new Date(a[headerBasedSort[activeHeader]]).getTime()
    )
    .map((announcement: Announcement, idx: number) => {
      const { announcementBody, announcementTitle, announcementTime } =
        getAnnouncementCopy(announcement)

      const withinTheHour =
        announcement.scheduledAt &&
        moment(announcement.scheduledAt).isAfter(moment().add(1, 'hours'))

      const selectItems = []

      if (!announcement.automated) {
        selectItems.push({
          id: '1',
          text: 'Duplicate',
          announcement: announcement,
        })
      }
      if (
        announcement.status === 'Draft' ||
        (announcement.status === 'Scheduled' && withinTheHour)
      ) {
        selectItems.push({
          id: '2',
          text: 'Delete',
          announcement: announcement,
        })
      }
      if (announcement.status === 'Draft') {
        selectItems.unshift({
          id: '3',
          text: 'Edit',
          announcement: announcement,
        })
      }

      return (
        <TableRow key={idx}>
          <TableRowContents
            id={`table-row-contents-${announcement.status.toLowerCase()}`}
            gridTemplateColumns={'1fr 1fr 3rem'}
            gridTemplateRows={'1fr 3rem'}
            onClick={() => {
              setIsDetailModalOpen(true)
              setSelectedAnnouncement(announcement)
            }}
            isClickable
          >
            <UserRowCell
              flex={'6'}
              gridColumn={'1/4'}
              label={isTablet && 'Announcement'}
            >
              <UserContainer>
                <UserSvg>
                  {announcement.automated ? (
                    <AutomatedAnnouncementIcon />
                  ) : (
                    <NormalAnnouncementIcon />
                  )}
                </UserSvg>
                <UserInfo>
                  <Alias>
                    {announcementTitle}
                    {announcement.automated && <Automated>Automated</Automated>}
                    {announcement.scheduledAt && (
                      <Automated>
                        {announcementTime.format('MMM Do YY, h:mm a')}
                      </Automated>
                    )}
                  </Alias>
                  <UserAddress>{announcementBody}</UserAddress>
                </UserInfo>
              </UserContainer>
            </UserRowCell>
            <RoleRowCell minWidth={pxToRem(90)}>
              <Chip variant={statusMap[announcement.status.toLowerCase()]}>
                {announcement.status === 'Persistent'
                  ? 'Scheduled'
                  : announcement.status}
              </Chip>
            </RoleRowCell>
            <LocationRowCell label={isTablet && 'Audience'}>
              {segmentsMap && segmentsMap[announcement.segment]
                ? segmentsMap[announcement.segment].name
                : null}
            </LocationRowCell>

            <TableRowCell
              gridColumn={'3/4'}
              equalSize={false}
              marginRight={'0.5rem'}
            >
              {selectItems.length === 0 && (
                <div style={{ width: pxToRem(40) }} />
              )}
              {selectItems.length > 0 && (
                <Dropdown
                  id='announcement-table-dropdown'
                  variant={'textual'}
                  svgDropdown={true}
                  icon={<OverflowIcon />}
                  active={null}
                  items={selectItems}
                  itemCallback={menuCallback}
                  width={pxToRem(180)}
                  top={'2.75rem'}
                  right={0}
                />
              )}
            </TableRowCell>
          </TableRowContents>
        </TableRow>
      )
    })
}

const AnnouncementsTable = React.memo(() => {
  const allAnnouncements = useSelector(
    (state: AppState) => state.concierge.announcements
  )
  const filteredAnnouncements =
    allAnnouncements && allAnnouncements.filter((a) => a.userType !== 'HR_USER')
  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  const [isTablet, setIsTablet] = useState(window.innerWidth <= 768)
  const [activeHeader, setActiveHeader] = useState(null)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<Announcement>()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isEmpty = filteredAnnouncements && filteredAnnouncements.length === 0

  const handleResize = useCallback(
    (width: number) => {
      setIsTablet(width <= 768)
    },
    [setIsTablet]
  )

  useResizeListener(() => handleResize(window.innerWidth), [])

  const handleHeaderClick = useCallback(
    (filter: string) => {
      if (filter !== activeHeader) {
        setActiveHeader(filter)
      }
      SegmentService.track('table-view-filter', {
        filter,
        tableType: 'announcements',
      })
    },
    [activeHeader]
  )

  const handleAnnouncementClick = useCallback(() => {
    navigate(`/concierge/announcement`, {
      state: { from: location.pathname },
    })
    SegmentService.track('concierge-actionBtn-click', {
      feature: 'announcements',
    })
  }, [navigate, location.pathname])

  const menuCallback = useCallback(
    (item: SelectItemProps) => {
      SegmentService.track('table-item-click', {
        action: metricsMap[item.id],
        tableType: 'announcements',
      })
      if (item.id === '1' || item.id === '3') {
        navigate(`/concierge/announcement`, {
          state: {
            from: location.pathname,
            announcement: item.announcement,
            status: item.id === '1' ? 'new' : 'draft',
          },
        })
      }
      if (item.id === '2') {
        dispatch(conciergeActions.deleteAnnouncement(item.announcement.id))
      }
    },
    [dispatch, navigate, location.pathname]
  )

  const updateActiveHeader = useCallback((allAnnouncements: Announcement[]) => {
    const announcementStatus = allAnnouncements.reduce(
      (agg, announcement) => {
        if (headerMap.upcoming.includes(announcement.status.toLowerCase())) {
          agg.upcoming++
        } else if (headerMap.sent.includes(announcement.status.toLowerCase())) {
          agg.sent++
        } else {
          agg.archived++
        }
        return agg
      },
      { upcoming: 0, sent: 0, archived: 0 }
    )

    if (announcementStatus.upcoming > 0) {
      setActiveHeader('upcoming')
    } else if (announcementStatus.sent > 0) {
      setActiveHeader('sent')
    } else {
      setActiveHeader('archived')
    }
  }, [])

  const renderContent = () => {
    if (!filteredAnnouncements) {
      return <Loading marginTop={'0.75rem'} />
    } else if (isEmpty) {
      return (
        <EmptyContainer>
          <IconContainer>
            <img
              alt={'announcements'}
              src={`${StaticAssetsUrl}/concierge/announcements.png`}
            />
          </IconContainer>
          <Text>No Announcements Found</Text>
          <Button onClick={() => handleAnnouncementClick()}>
            Send an Announcement
          </Button>
        </EmptyContainer>
      )
    } else {
      if (!activeHeader) {
        updateActiveHeader(filteredAnnouncements)
        return null
      } else {
        return (
          <Table marginBottom={'2rem'}>
            <TableHeader>
              {getTableHeaders(
                filteredAnnouncements,
                activeHeader,
                handleHeaderClick
              )}
            </TableHeader>
            <TableLabelRow>
              <TableRowCell
                minWidth={pxToRem(235)}
                flex={'6'}
                fontType={'body2'}
              >
                Announcement
              </TableRowCell>
              <TableRowCell minWidth={pxToRem(90)} fontType={'body2'}>
                Status
              </TableRowCell>
              <TableRowCell minWidth={pxToRem(150)} fontType={'body2'}>
                Group
              </TableRowCell>
              <TableRowCell
                equalSize={false}
                width={'2.5rem'}
                marginRight={'0.5rem'}
              />
            </TableLabelRow>
            {getTableRows(
              segmentsMap,
              filteredAnnouncements,
              activeHeader,
              isTablet,
              menuCallback,
              setIsDetailModalOpen,
              setSelectedAnnouncement
            )}
          </Table>
        )
      }
    }
  }

  return (
    <>
      <Header>
        <Title>Announcements</Title>
        {!isEmpty && allAnnouncements && (
          <Button
            onClick={() => handleAnnouncementClick()}
            iconLeft={<AddIcon />}
          >
            Announcement
          </Button>
        )}
      </Header>
      <Subtitle>
        Set up scheduled or instant announcements to alert your commuters of
        updates regarding their mobility benefits.
      </Subtitle>
      {renderContent()}
      <AnnouncementDetailsModal
        isModalOpen={isDetailModalOpen}
        setIsModalOpen={setIsDetailModalOpen}
        announcement={selectedAnnouncement}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AnnouncementsTable.displayName = 'AnnouncementsTable'
}

export default AnnouncementsTable
