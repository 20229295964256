import Text from 'core-system/Text'
import { TextVariants } from 'core-system/Text/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${flexbox}
  ${space}
  ${layout}
`

interface FleetErrorProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  description: string
  textVariant?: TextVariants
}

const FleetError = React.memo((props: FleetErrorProps) => {
  const { description, textVariant = 'action2' } = props

  return (
    <ErrorContainer {...props}>
      <img alt={'error'} src={`${StaticAssetsUrl}/shared/error.png`} />
      <Text
        variant={textVariant}
        margin='1rem 1.5rem 0 0'
        textColor={palette.text.primary}
      >
        {description}
      </Text>
    </ErrorContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FleetError.displayName = 'FleetError'
}

export default FleetError
