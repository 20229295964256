import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M16 20V4H4V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H16ZM19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V10H22V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22ZM18 12V19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V12H18Z' />
    <path d='M11.5 14H7V16H9V17H11V16H11.5C12.163 16 12.7989 15.7366 13.2678 15.2678C13.7366 14.7989 14 14.163 14 13.5C14 12.837 13.7366 12.2011 13.2678 11.7322C12.7989 11.2634 12.163 11 11.5 11H8.5C8.36739 11 8.24021 10.9473 8.14645 10.8536C8.05268 10.7598 8 10.6326 8 10.5C8 10.3674 8.05268 10.2402 8.14645 10.1464C8.24021 10.0527 8.36739 10 8.5 10H13V8H11V7H9V8H8.5C7.83696 8 7.20107 8.26339 6.73223 8.73223C6.26339 9.20107 6 9.83696 6 10.5C6 11.163 6.26339 11.7989 6.73223 12.2678C7.20107 12.7366 7.83696 13 8.5 13H11.5C11.6326 13 11.7598 13.0527 11.8536 13.1464C11.9473 13.2402 12 13.3674 12 13.5C12 13.6326 11.9473 13.7598 11.8536 13.8536C11.7598 13.9473 11.6326 14 11.5 14Z' />
  </>,
  'QTF'
)
