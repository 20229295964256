import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import RandomUtils from 'shared/RandomUtils'

const StyledCard = styled.div`
  ${(props) => props.theme.baseCard}
  ${(props) => props.theme.typography.h4};
  margin-bottom: 1.5rem;
  padding: 2.5rem;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1.5rem;
`

const Plan = styled.div`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.primary};
  min-width: ${(props) => props.theme.pxToRem(330)};
  margin-right: 1.5rem;
`

interface SubscriptionCardProps {
  totalEmployees: number
  planDetails: string
  promoCode: string
  referralCredits: string
}

const SubscriptionCard = (props: SubscriptionCardProps) => {
  const { totalEmployees, planDetails, promoCode, referralCredits } = props

  return (
    <>
      <StyledCard>
        Subscription
        <FlexContainer>
          <Plan>
            <Text variant='h5' mb='1rem'>
              Plan Details
            </Text>
            <div>
              {totalEmployees} Commuter
              {RandomUtils.pluralCheck(totalEmployees)}
            </div>
            <div>{planDetails}</div>
            <div style={{ marginTop: '1rem' }}>
              <strong>Promo Code: </strong>
              {promoCode}
            </div>
            <div style={{ marginTop: '1rem' }}>
              <strong>Referral Credits: </strong>
              {referralCredits}
            </div>
          </Plan>
          <Plan>
            <Text variant='h5' mb='1rem'>
              Next Bill
            </Text>
            <div>
              {moment().add(1, 'M').startOf('month').format('MMMM Do, YYYY')}
            </div>
            <div>{planDetails}</div>
          </Plan>
        </FlexContainer>
      </StyledCard>
    </>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SubscriptionCard.displayName = 'SubscriptionCard'
}

export default SubscriptionCard
