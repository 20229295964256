import React from 'react'
import GridCard from 'employee-platform/shared/components/GridCard'
import { useNavigate } from 'react-router-dom'
import { Locations } from 'shared/Router/Locations'
import palette from 'core-system/Themes/palette'
import QTFIcon from 'core-system/Icons/Sidebar/QTF'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { PreTaxProgramTypeMap } from 'employee-platform/shared/utils'
import BudgetBreakdown from 'employee-platform/shared/components/BudgetBreakdown'

const PreTaxDashboardCard = React.memo(() => {
  const { preTaxPrograms } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const preTaxBudgetItems = preTaxPrograms
    ? Object.values(preTaxPrograms).map((program) => {
        if (program && Object.keys(program).length > 0) {
          return {
            name: PreTaxProgramTypeMap[program.type],
            amount: program?.cycleBalance,
          }
        } else {
          return null
        }
      })
    : null

  const navigate = useNavigate()

  return (
    <GridCard
      headerTitle='Pre-Tax Benefit Balance'
      headerIcon={<QTFIcon color={palette.primary.primaryPurple} />}
      clickable={true}
      onClick={() => navigate(Locations.EmployeePreTax.Overview)}
      tooltipText='Go to Pre-Tax Overview'
    >
      {preTaxBudgetItems && (
        <BudgetBreakdown
          budgetItems={preTaxBudgetItems}
          summaryDirection='row'
        />
      )}
    </GridCard>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PreTaxDashboardCard.displayName = 'PreTaxDashboardCard'
}

export default PreTaxDashboardCard
