import { ColumnCard } from 'core-system/ColumnView'
import SummaryBudget from 'core-system/Program/Summary/SummaryBudget'
import SummaryOverview from 'core-system/Program/Summary/SummaryOverview'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import MicromobilitySummaryOptions from 'features/Micromobility/Shared/components/MicromobilitySummaryOptions'
import React from 'react'
import { Segment } from 'redux/employer/employerTypes'
import {
  MicromobilityActiveOptions,
  MicromobilityType,
} from 'redux/micromobility/micromobilityTypes'
import styled from 'styled-components'

const Container = styled(ColumnCard)`
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
`

interface MicromobilityActivationSummaryProps {
  startDate: string
  currentSegment: Segment
  budget: number
  activeOptions: MicromobilityActiveOptions
  handleOpenModal: (type: MicromobilityType) => void
  activeLeasingMerchantIds: string[]
  availableLeasingMerchantIds: string[]
  activeSharedMerchantIds: string[]
  availableSharedMerchantIds: string[]
  activeOneTimeMerchantIds: string[]
  availableOneTimeMerchantIds: string[]
}

const MicromobilityActivationSummary = React.memo(
  (props: MicromobilityActivationSummaryProps) => {
    const {
      startDate,
      currentSegment,
      budget,
      activeOptions,
      handleOpenModal,
      activeLeasingMerchantIds,
      availableLeasingMerchantIds,
      activeSharedMerchantIds,
      availableSharedMerchantIds,
      activeOneTimeMerchantIds,
      availableOneTimeMerchantIds,
    } = props

    return (
      <Container>
        <Text variant='h4' marginBottom='1.5rem'>
          My Micromobility Program
        </Text>
        <SummaryOverview
          startDate={startDate}
          currentSegment={currentSegment}
          marginBottom='1rem'
        />
        <MicromobilitySummaryOptions
          activeOptions={activeOptions}
          activeLeasingMerchantIds={activeLeasingMerchantIds}
          availableLeasingMerchantIds={availableLeasingMerchantIds}
          activeSharedMerchantIds={activeSharedMerchantIds}
          availableSharedMerchantIds={availableSharedMerchantIds}
          activeOneTimeMerchantIds={activeOneTimeMerchantIds}
          availableOneTimeMerchantIds={availableOneTimeMerchantIds}
          budget={budget}
          handleOpenModal={handleOpenModal}
        />
        <SummaryBudget
          title='Total Monthly Budget'
          metric='per commuter'
          numEmps={currentSegment.commuters}
          budget={budget}
          marginBottom='1.5rem'
        />
        <Text variant='body2' textColor={palette.text.placeholder}>
          All budget amounts are estimated based on max commuter spend.
        </Text>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityActivationSummary.displayName = 'MicromobilityActivationSummary'
}

export default MicromobilityActivationSummary
