import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'
import { buildForwardingComponent } from '../utils/buildComponent'
import pxToRem from '../utils/pxToRem'

const Container = styled.div<{ animate: boolean }>`
  display: flex;
  background-color: ${(props) => props.theme.palette.white};
  border: ${(props) =>
    `${props.animate ? '1px' : '0'} solid ${props.theme.palette.transparent}`};
  border-top: ${(props) =>
    props.animate ? `1px solid ${props.theme.palette.grey.grey3}` : 'none'};
  align-items: center;
  width: 100%;
  opacity: ${(props) => (props.animate ? 1 : 0)};
  transition: max-height 0.8s ease-in-out, opacity 0.8s ease-in-out;
  max-height: ${(props) => (props.animate ? pxToRem(1000) : '0')};
  overflow: ${(props) => (props.animate ? 'unset' : 'hidden')};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    border-radius: 0.5rem !important;
    margin-bottom: ${(props) => (props.animate ? '0.5rem' : 0)};
    border: ${(props) =>
      props.animate ? `1px solid ${props.theme.palette.grey.grey3}` : 'none'};
    padding-right: 1rem;
  }

  ${flexbox}
  ${space}
  ${layout}
`

const Padding = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
`

export interface TableRowProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  animate?: boolean
}

const TableRow = React.forwardRef<HTMLDivElement, TableRowProps>(
  (props: TableRowProps, ref: React.Ref<HTMLDivElement>) => {
    const { animate = true, children } = props
    return (
      <Container ref={ref} animate={animate} {...props}>
        <Padding>{children}</Padding>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TableRow.displayName = 'TableRow'
}

export default buildForwardingComponent(TableRow)
