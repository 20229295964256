import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d='M22.8319 4.21606L21.0039 6.04476C21.0039 6.04476 19.7846 6.6541 18.5659 5.43475C17.3465 4.21607 17.9559 2.99671 17.9559 2.99671L19.7846 1.16802C20.3946 0.558675 19.6619 0 19.1119 0C16.4112 0 14.2225 2.1887 14.2225 4.88874C14.2225 5.26808 14.2705 5.63609 14.3518 5.99076L10.2191 10.1235L13.8758 13.7802L18.0085 9.64815C18.3639 9.72948 18.7312 9.77748 19.1112 9.77748C21.8113 9.77748 24 7.58878 24 4.88874C24.0006 4.3354 23.4413 3.60672 22.8319 4.21606V4.21606ZM10.1471 10.2428L2.02095 18.3683C1.02427 19.3656 1.02427 20.9823 2.02095 21.9797C3.01896 22.9764 4.63499 22.9764 5.63234 21.9797L13.7571 13.8542L10.1471 10.2428ZM3.84231 21.2257C3.2963 21.2257 2.8543 20.783 2.8543 20.2376C2.8543 19.6923 3.29697 19.249 3.84231 19.249C4.38832 19.249 4.83033 19.6916 4.83033 20.2376C4.83033 20.783 4.38832 21.2257 3.84231 21.2257Z'
      fill='#9E9ECF'
    />
    <path
      d='M20.554 22.1863C21.3313 22.9637 22.5687 23.0004 23.3013 22.267C24.0347 21.5337 23.998 20.297 23.2207 19.5196L7.71044 4.00939C6.93243 3.23138 5.69641 3.19538 4.96306 3.92873C4.22972 4.66207 4.26639 5.89876 5.04373 6.6761L20.554 22.1863Z'
      fill='#4100A6'
    />
    <path
      d='M14.2965 0.595489C14.2965 0.595489 10.2965 -1.40454 6.96307 1.92884L2.29633 6.59558C2.29633 6.59558 1.62965 5.9289 0.962978 6.59558L0.296301 7.26226C0.296301 7.26226 -0.370376 7.92894 0.296301 8.59561L2.96301 11.2623C2.96301 11.2623 3.62969 11.929 4.29636 11.2623L4.96304 10.5956C4.96304 10.5956 5.62972 9.92897 4.96304 9.26229L4.91104 9.21029C5.42438 8.71495 6.19306 8.21027 6.96307 8.59561L9.62978 5.9289C9.62978 5.9289 8.9631 3.92887 10.2965 2.59552C11.6298 1.26217 12.2965 1.26217 13.6298 1.26217C14.9632 1.26217 14.2965 0.595489 14.2965 0.595489V0.595489Z'
      fill='#6200E0'
    />
  </>,
  'Custom',
  '0 0 24 23'
)
