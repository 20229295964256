import ProgramUtils from 'core-system/Program/ProgramUtils'
import { FlexActiveOptions, FlexManageState } from 'redux/flex/flexTypes'
import { Merchant } from 'redux/programs/programsTypes'
import FormattingUtils from 'shared/FormattingUtils'

const recBudget = 7000
const monthlyParking = 20000
const monthlyGas = 30000
const monthlyTransit = 12000

const defaultErrorState = {
  flex: false,
  budget: false,
}

const metroAreaTransitProviders = {
  USSanFranciscoBayArea: ['BART', 'Muni', 'Caltrain', 'SamTrans'],
  USNewYorkMetropolitanArea: [
    'New York City Subway',
    'MTA Bus Company',
    'Long Island Rail Road',
    'MetroNorth Railroad',
    'NYC Ferry',
    'PATH',
    'Staten Island Railway',
    'NJ Transit Rail',
    'Amtrak',
  ],
  CAGreaterTorontoArea: ['TTC', 'GO Transit', 'Viva'],
  USAustinMetropolitanArea: [],
  USSeattleMetropolitanArea: [],
  USLosAngelesMetropolitanArea: [],
  USDCMetropolitanArea: [],
  USChicagoMetropolitanArea: [],
  USBostonMetropolitanArea: [],
  CAEdmontonMetropolitanArea: [],
  USPhiladelphiaMetropolitanArea: [],
}

const getFlexTransitProviders = (
  transitMerchantIds: string[],
  merchantsWithTransit: Merchant[],
  metroAreas: string[]
) => {
  const relevantMerchants = metroAreas.reduce((agg, metroArea) => {
    return metroArea ? [...agg, ...metroAreaTransitProviders[metroArea]] : agg
  }, [])

  const relevantMerchantIds = relevantMerchants.map((name) => {
    return merchantsWithTransit.find((m) => m.name === name)?.id
  })

  return relevantMerchantIds.filter((merchantId: string) =>
    transitMerchantIds.includes(merchantId)
  )
}

//this is used to see if the upcoming program has changes
const programHasChanges = (
  programState: FlexManageState,
  activeOptions: Dictionary<FlexActiveOptions>
) => {
  const currentBudget = FormattingUtils.formatDollar(
    programState.active.budget,
    0
  )
  const nextMonthBudget = FormattingUtils.formatDollar(
    programState.nextMonth.budget,
    0
  )

  const optionChanges = Object.keys(activeOptions.active).flatMap((option) => {
    if (activeOptions.active[option] !== activeOptions.nextMonth[option]) {
      return option
    } else {
      return []
    }
  })

  const providerChanges: any = ProgramUtils.getProviderDifference(
    programState,
    ['flex']
  )

  //only compare provider changes on active options
  const hasProviderChanges =
    Object.keys(providerChanges).length > 0 &&
    Object.keys(providerChanges).some(
      (option) => activeOptions.nextMonth[option]
    )

  return (
    currentBudget !== nextMonthBudget ||
    optionChanges.length > 0 ||
    hasProviderChanges
  )
}

export default {
  recBudget,
  monthlyParking,
  monthlyGas,
  monthlyTransit,
  defaultErrorState,
  metroAreaTransitProviders,
  getFlexTransitProviders,
  programHasChanges,
}
