import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import { SegmentParams } from 'redux/employer/employerTypes'
import {
  CardProgram,
  Merchant,
  ProgramDashboardStats,
  ProgramGroup,
  ProgramServiceUsage,
  SegmentAndType,
  SegmentAndTypeAndDate,
} from 'redux/programs/programsTypes'
import { FlexProgramUpdate } from './flexTypes'
import { programsActions } from 'redux/programs/programsSlice'

export interface FlexState {
  flexPrograms: Dictionary<ProgramGroup>
  flexProgramUpdated: boolean
  flexProgramsLoaded: boolean

  dashboardStats: ProgramDashboardStats
  serviceUsage: ProgramServiceUsage[]
  availableMerchants: {
    transit: Merchant[]
    flex: Merchant[]
  }
}

export const initialState: FlexState = {
  flexPrograms: null,
  flexProgramUpdated: false,
  flexProgramsLoaded: false,

  dashboardStats: null,
  serviceUsage: null,
  availableMerchants: null,
}

const handleToggleProgramUpdate = (state: FlexState) => {
  if (!state.flexProgramUpdated) {
    return {
      ...state,
      flexProgramUpdated: !state.flexProgramUpdated,
      flexProgramsLoaded: false,
    }
  }

  return {
    ...state,
    flexProgramUpdated: !state.flexProgramUpdated,
  }
}

const createFlexProgram = createAction<CardProgram>('flex/createFlexProgram')
const createFlexProgramSuccess = createAction('flex/createFlexProgramSuccess')

const updateFlexProgram = createAction<FlexProgramUpdate>(
  'flex/updateFlexProgram'
)

const cancelFlexProgram = createAction<string>('flex/cancelFlexProgram')

const flexSlice = createSlice({
  name: 'flex',
  initialState,
  reducers: {
    updateFlexProgramSuccess(state) {
      return handleToggleProgramUpdate(state)
    },
    cancelFlexProgramSuccess(state) {
      return handleToggleProgramUpdate(state)
    },
    toggleFlexProgramUpdated(state) {
      return handleToggleProgramUpdate(state)
    },
    getFlexDashboardStats(state, _action: PayloadAction<SegmentAndType>) {
      state.dashboardStats = null
    },
    getFlexDashboardStatsSuccess(
      state,
      action: PayloadAction<ProgramDashboardStats>
    ) {
      state.dashboardStats = action.payload
    },
    getFlexDashboardServices(
      state,
      _action: PayloadAction<SegmentAndTypeAndDate>
    ) {
      state.serviceUsage = null
    },
    getFlexDashboardServicesSuccess(
      state,
      action: PayloadAction<ProgramServiceUsage[]>
    ) {
      state.serviceUsage = action.payload
    },
    getFlexRecommendations(state, _action: PayloadAction<SegmentParams>) {
      state.availableMerchants = null
    },
    getFlexRecommendationsSuccess(state, action: PayloadAction<Merchant[]>) {
      const groupings = action.payload.reduce(
        (
          modeGroupings: FlexState['availableMerchants'],
          merchant: Merchant
        ) => {
          if (!ProgramUtils.modeTypes.includes(merchant.name)) {
            if (
              merchant.modeTypes.some((modeType) =>
                ProgramUtils.transitModeTypes.includes(modeType)
              )
            ) {
              modeGroupings.transit.push(merchant)
            } else {
              modeGroupings.flex.push(merchant)
            }
          }

          return modeGroupings
        },
        {
          transit: [],
          flex: [],
        }
      )

      state.availableMerchants = groupings
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      programsActions.getAllProgramsSuccess,
      (state, action: PayloadAction<CardProgram[]>) => {
        const activeStatuses = ['ACTIVE', 'UPDATING']
        const groupedProgramsById = action.payload
          .filter((p) => p.type === 'FLEX')
          .reduce((agg, program) => {
            if (program.status === 'CANCELLED') return agg

            const defaultProgramGroup = {} as any
            const formattedProgram = {
              ...program,
              budget: parseInt(program.budget),
            }

            if (activeStatuses.includes(program.status)) {
              defaultProgramGroup.active = formattedProgram
            } else {
              defaultProgramGroup.nextMonth = formattedProgram
            }

            agg[program.segment] = {
              ...agg[program.segment],
              ...defaultProgramGroup,
            }

            return agg
          }, {})

        state.flexPrograms = groupedProgramsById
        state.flexProgramsLoaded = true
      }
    )
  },
})

export const flexReducer = flexSlice.reducer
export const flexActions = {
  ...flexSlice.actions,
  createFlexProgram,
  createFlexProgramSuccess,
  updateFlexProgram,
  cancelFlexProgram,
}
