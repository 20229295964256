import Button from 'core-system/Button'
import Chip from 'core-system/Chip'
import FlexContainer from 'core-system/FlexContainer'
import AddIcon from 'core-system/Icons/Actions/Add'
import Loading from 'core-system/Loading'
import { TableRowCell } from 'core-system/Table'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import AddWorksiteModal from 'features/Accounts/Users/AddWorksiteModal'
import React, { useEffect, useState } from 'react'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import SegmentService from 'redux/config/services/SegmentService'
import { Employee } from 'redux/employee/employeeTypes'
import { Worksite } from 'redux/employer/employerTypes'
import { User } from 'redux/user/userTypes'
import styled from 'styled-components'
import AccountsHeader from '../AccountsHeader'
import UsersInfoModal from './UsersInfoModal'
import UsersInviteModal from './UsersInviteModal'
import UsersTable from './UsersTable'
import WorksiteTable from './WorksiteTable'
import { AppState } from 'redux/config/store'
import { useSelector } from 'react-redux'
import { getRoleChipConfig } from '../AccountsUtils'

const Container = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
`

const Content = styled.div`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.primary};
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 5rem;
`

const HeaderContainer = styled.div`
  margin: 1rem 0;
`

const HeaderBottomContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  color: ${(props) => props.theme.palette.text.secondary};

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    flex-direction: column;
    align-items: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-top: 0.5rem;
    justify-content: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledButton = styled(Button)`
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    display: none;
  }
`

const RoleChip = styled(TableRowCell)`
  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-bottom: 0.5rem;
  }
`

interface UsersViewProps {
  user: User
  allWorksites: Worksite[]
  allEmployees: Employee[]
}

const UsersView = React.memo((props: UsersViewProps) => {
  const { user, allWorksites, allEmployees } = props

  const primaryAccountHolderId = useSelector(
    (state: AppState) => state.employer.profile.primaryAccountHolderId
  )

  const [currentUser, setCurrentUser] = useState<User>()
  const [showUserModal, setShowUserModal] = useState<boolean>(false)
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false)
  const [worksiteModalOpen, setWorksiteModalOpen] = useState(false)
  const [userWorksite, setUserWorksite] = useState<Worksite | null>(null)
  const isAdmin = user.role === 'HRadmins'
  const isPrimaryAccountHolder = user.id === primaryAccountHolderId

  const { roleChipVariant, roleChipLabel } = getRoleChipConfig(
    isAdmin,
    isPrimaryAccountHolder
  )

  const openModal = (user: User) => {
    setCurrentUser(user)
    setShowUserModal(true)
    SegmentService.track('userInfo-modal-click', {
      action: 'open',
    })
  }

  const openInviteUserModal = () => {
    setShowInviteModal(true)
    SegmentService.track('inviteUser-modal-click', {
      action: 'open',
    })
  }

  const closeInviteModal = () => {
    setShowInviteModal(false)
    SegmentService.track('inviteUser-modal-click', {
      action: 'close',
    })
  }

  const closeUserModal = () => {
    setShowUserModal(false)
    SegmentService.track('userInfo-modal-click', {
      action: 'close',
    })
  }

  const openAddWorksiteModal = () => {
    setWorksiteModalOpen(true)
    SegmentService.track('addWorksite-modal-click', {
      action: 'open',
    })
  }

  const closeAddWorksiteModal = () => {
    setWorksiteModalOpen(false)
    SegmentService.track('addWorksite-modal-click', {
      action: 'close',
    })
  }

  useEffect(() => {
    if (!user.worksiteId) {
      user.worksiteId = 'no-worksite'
    }

    const site = !allWorksites
      ? null
      : allWorksites.filter((wrks) => wrks.id === user.worksiteId)[0]
    setUserWorksite(site || null)
  }, [allWorksites, user])

  return (
    <Container>
      <Text variant='h4'>Your Information</Text>
      <HeaderContainer>
        {user.email === '' ? (
          <Loading height={pxToRem(122)} />
        ) : (
          <AccountsHeader
            data-cy='account-header'
            src={`${StaticAssetsUrl}/accounts/users-header.png`}
            srcMobile={`${StaticAssetsUrl}/accounts/default-user.png`}
            title={`${user.firstName} ${user.lastName}`}
            subtitle={user.email}
            onClick={() => openModal(user)}
          >
            <HeaderBottomContainer>
              <RoleChip label={'Role'} equalSize={false}>
                <Chip marginTop={'0.1325rem'} variant={roleChipVariant}>
                  {roleChipLabel}
                </Chip>
              </RoleChip>
              {userWorksite && (
                <TableRowCell
                  icon={'location'}
                  label={'Worksite'}
                  marginRight={0}
                  overflow={'hidden'}
                >
                  {userWorksite.alias ? userWorksite.alias : 'No Worksite'}
                </TableRowCell>
              )}
            </HeaderBottomContainer>
          </AccountsHeader>
        )}
      </HeaderContainer>
      <Content>
        <FlexContainer centerApart>
          <Text variant='h4'>Users</Text>
          {allEmployees && allWorksites && (
            <StyledButton
              variant={'secondary'}
              size={'small'}
              iconLeft={<AddIcon />}
              onClick={() => openInviteUserModal()}
              data-cy='accounts-user-invite-user-btn'
            >
              Invite User
            </StyledButton>
          )}
        </FlexContainer>
        <UsersTable
          allEmployees={allEmployees}
          allWorksites={allWorksites || null}
          isAdmin={isAdmin}
        />
        <FlexContainer centerApart marginTop='2rem'>
          <Text variant='h4'>Worksites</Text>
          {allEmployees && allWorksites && (
            <StyledButton
              variant='secondary'
              size='small'
              iconLeft={<AddIcon />}
              onClick={openAddWorksiteModal}
              data-cy='accounts-user-create-worksite-btn'
            >
              Add a Worksite
            </StyledButton>
          )}
        </FlexContainer>
        <WorksiteTable
          allWorksites={allWorksites.filter(
            (worksite) => worksite.id !== 'no-worksite'
          )}
        />
      </Content>
      {currentUser && allWorksites && (
        <UsersInfoModal
          isAuthUser={true}
          closeModal={closeUserModal}
          open={showUserModal}
          user={currentUser}
          allWorksites={allWorksites}
          isAdmin={isAdmin}
        />
      )}
      {allWorksites && (
        <UsersInviteModal
          open={showInviteModal}
          allWorksites={allWorksites}
          closeModal={closeInviteModal}
        />
      )}
      <AddWorksiteModal
        open={worksiteModalOpen}
        closeModal={closeAddWorksiteModal}
      />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  UsersView.displayName = 'UsersView'
}
export default UsersView
