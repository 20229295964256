import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FeatureCollection } from 'geojson'
import {
  AgonyDataParams,
  AgonyDataPayload,
  BaseCommuterParams,
  DashboardRecentTripsData,
  MapIntelligenceStats,
  RecentTripDetailsParams,
  RecentTripOriginsParams,
} from 'redux/mapIntelligence/mapIntelligenceTypes'
import { MicromobilityIsochronePayload } from 'redux/micromobility/micromobilityTypes'
import { dashboardActions } from 'redux/dashboard/dashboardSlice'
import { micromobilityActions } from 'redux/micromobility/micromobilitySlice'

export interface MapIntelligenceState {
  employeesGeojson: FeatureCollection
  worksiteGeojson: FeatureCollection
  agonyGeojson: FeatureCollection
  bikeIsochrones: FeatureCollection
  dashRecentTrips: DashboardRecentTripsData
  micromobility: {
    recentTripOrigins: FeatureCollection
    tripDetails: FeatureCollection
  }
  flex: {
    recentTripOrigins: FeatureCollection
    tripDetails: FeatureCollection
  }
  statistics: MapIntelligenceStats
}

const initialState: MapIntelligenceState = {
  employeesGeojson: null,
  worksiteGeojson: null,
  agonyGeojson: null,
  bikeIsochrones: null,
  dashRecentTrips: null,
  micromobility: {
    recentTripOrigins: null,
    tripDetails: null,
  },
  flex: {
    recentTripOrigins: null,
    tripDetails: null,
  },
  statistics: null,
}

const getAgonyData = createAction<AgonyDataParams>(
  'mapIntelligence/getAgonyData'
)
const getBaseCommuters = createAction<BaseCommuterParams>(
  'mapIntelligence/getBaseCommuters'
)

const updateEmployeeGeoJson = (
  employeeState: FeatureCollection,
  employeeData: FeatureCollection
) => {
  return !employeeState ||
    employeeData.features.length !== employeeState.features.length
    ? employeeData
    : {
        type: 'FeatureCollection',
        features: employeeState.features.map((emp) => {
          const match = employeeData.features.find(
            (innerEmp) => String(innerEmp.id) === String(emp.id)
          )
          return {
            ...emp,
            properties: match
              ? { ...emp.properties, ...match.properties }
              : emp.properties,
          }
        }),
      }
}

const mapIntelligenceSlice = createSlice({
  name: 'mapIntelligence',
  initialState,
  reducers: {
    getBaseCommutersSuccess(state, action: any) {
      return {
        ...state,
        employeesGeojson: updateEmployeeGeoJson(
          state.employeesGeojson,
          action.payload.employeesGeojson
        ) as FeatureCollection,
        worksiteGeojson: action.payload.worksitesGeojson,
        statistics: {
          agony: action.payload.statistics
            ? action.payload.statistics.agony
            : {},
          modes: action.payload.statistics
            ? action.payload.statistics.modes
            : {},
          savings: action.payload.statistics
            ? action.payload.statistics.savings
            : 0,
          totalEmployees: action.payload.statistics
            ? action.payload.numEmployees
            : 0,
        },
      }
    },
    getAgonyDataSuccess(state, action: PayloadAction<AgonyDataPayload>) {
      state.agonyGeojson = action.payload.geojson
    },
    getRecentTripOriginsSuccess(state, action: any) {
      const data = !action.payload.features
        ? ({ features: [], type: 'FeatureCollection' } as FeatureCollection)
        : action.payload

      if (action.meta.type === 'MICROMOBILITY') {
        return {
          ...state,
          micromobility: {
            ...state.micromobility,
            recentTripOrigins: data,
          },
        }
      } else {
        return {
          ...state,
          flex: {
            ...state.flex,
            recentTripOrigins: data,
          },
        }
      }
    },
    getRecentTripDetailsSuccess(state, action: any) {
      if (action.meta.type === 'MICROMOBILITY') {
        return {
          ...state,
          micromobility: {
            ...state.micromobility,
            tripDetails: action.payload,
          },
        }
      } else {
        return {
          ...state,
          flex: {
            ...state.flex,
            tripDetails: action.payload,
          },
        }
      }
    },
    getRecentTripDetails(
      state,
      action: PayloadAction<RecentTripDetailsParams>
    ) {
      if (action.payload.type === 'MICROMOBILITY') {
        return {
          ...state,
          micromobility: {
            ...state.micromobility,
            tripDetails: {} as FeatureCollection,
          },
        }
      } else {
        return {
          ...state,
          flex: {
            ...state.flex,
            tripDetails: {} as FeatureCollection,
          },
        }
      }
    },
    getRecentTripOrigins(
      state,
      action: PayloadAction<RecentTripOriginsParams>
    ) {
      if (action.payload.type === 'MICROMOBILITY') {
        return {
          ...state,
          micromobility: {
            recentTripOrigins: null,
            tripDetails: null,
          },
        }
      } else {
        return {
          ...state,
          flex: {
            recentTripOrigins: null,
            tripDetails: null,
          },
        }
      }
    },
    resetMapData(state, action: PayloadAction<string>) {
      const stateCopy = { ...state }
      for (const key in stateCopy) {
        if (action.payload.includes(key)) {
          stateCopy[key] = null
        }
      }
      return stateCopy
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        dashboardActions.getDashboardMapDataSuccess,
        (state, action: PayloadAction<DashboardRecentTripsData>) => {
          state.dashRecentTrips = action.payload
        }
      )
      .addCase(
        micromobilityActions.getMicromobilityIsochronesSuccess,
        (state, action: PayloadAction<MicromobilityIsochronePayload>) => {
          return {
            ...state,
            employeesGeojson: updateEmployeeGeoJson(
              state.employeesGeojson,
              action.payload.employees
            ) as FeatureCollection,
            bikeIsochrones: action.payload.isochrones,
          }
        }
      )
  },
})

export const mapIntelligenceReducer = mapIntelligenceSlice.reducer
export const mapIntelligenceActions = {
  ...mapIntelligenceSlice.actions,
  getAgonyData,
  getBaseCommuters,
}
