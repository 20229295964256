import { AnyAction } from '@reduxjs/toolkit'
import { defaultFlow } from 'redux/config/saga/Common'
import employeePlatformService from './employeePlatformService'
import { employeePlatformActions } from './employeePlatformSlice'
import { all, takeEvery, takeLatest } from 'redux-saga/effects'

// PROFILE FLOWS
export function* getProfileData(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getProfileData,
    employeePlatformActions.getProfileDataSuccess
  )
}
export function* updateProfileData(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.updateProfileData,
    employeePlatformActions.updateProfileDataSuccess
  )
}
export function* getWalletBalance(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getWalletBalance,
    employeePlatformActions.getWalletBalanceSuccess
  )
}
export function* getAllPayouts(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getAllPayouts,
    employeePlatformActions.getAllPayoutsSuccess
  )
}
export function* getAllRewards(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getAllRewards,
    employeePlatformActions.getAllRewardsSuccess
  )
}
export function* getAllCharges(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getAllCharges,
    employeePlatformActions.getAllChargesSuccess
  )
}
export function* getDOB(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getDOB,
    employeePlatformActions.getDOBSuccess
  )
}
export function* getDashboardStats(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getDashboardStats,
    employeePlatformActions.getDashboardStatsSuccess
  )
}

// PAYMENT METHODS FLOWS
export function* getAllPaymentMethods(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getAllPaymentMethods,
    employeePlatformActions.getAllPaymentMethodsSuccess
  )
}
export function* deletePaymentMethod(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.deletePaymentMethod,
    employeePlatformActions.deletePaymentMethodSuccess
  )
}

// CARDS FLOWS
export function* getCards(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getCards,
    employeePlatformActions.getCardsSuccess
  )
}
export function* lockCard(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.lockCard,
    employeePlatformActions.lockCardSuccess
  )
}
export function* unlockCard(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.unlockCard,
    employeePlatformActions.unlockCardSuccess
  )
}
export function* topUpCard(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.topUpCard,
    employeePlatformActions.topUpCardSuccess
  )
}

// PROGRAMS FLOWS
export function* getAllPrograms(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getAllPrograms,
    employeePlatformActions.getAllProgramsSuccess
  )
}
export function* getAllProgramRequests(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getAllProgramRequests,
    employeePlatformActions.getAllProgramRequestsSuccess
  )
}
export function* createProgramRequest(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.createProgramRequest,
    employeePlatformActions.createProgramRequestSuccess
  )
}
export function* cancelProgramRequest(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.cancelProgramRequest,
    employeePlatformActions.cancelProgramRequestSuccess
  )
}

// PRETAX FLOWS
export function* getPreTaxEmployeeDeduction(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getPreTaxEmployeeDeduction,
    employeePlatformActions.getPreTaxEmployeeDeductionSuccess
  )
}
export function* getPreTaxPrograms(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getPreTaxPrograms,
    employeePlatformActions.getPreTaxProgramsSuccess
  )
}
export function* getPreTaxBalance(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getPreTaxBalance,
    employeePlatformActions.getPreTaxBalanceSuccess
  )
}
export function* getPreTaxBenefitHistory(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getPreTaxBenefitHistory,
    employeePlatformActions.getPreTaxBenefitHistorySuccess
  )
}
export function* createPreTaxEmployeeAllocation(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.createPreTaxEmployeeAllocation,
    employeePlatformActions.createPreTaxEmployeeAllocationSuccess
  )
}
export function* updatePreTaxEmployeeAllocation(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.updatePreTaxEmployeeAllocation,
    employeePlatformActions.updatePreTaxEmployeeAllocationSuccess
  )
}

// CARPOOL FLOWS
export function* getAllCarpoolGroups(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getAllCarpoolGroups,
    employeePlatformActions.getAllCarpoolGroupsSuccess
  )
}
export function* getMyCarpoolGroups(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getMyCarpoolGroups,
    employeePlatformActions.getMyCarpoolGroupsSuccess
  )
}
export function* searchCarpoolGroups(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.searchCarpoolGroups,
    employeePlatformActions.searchCarpoolGroupsSuccess
  )
}
export function* createCarpoolGroup(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.createCarpoolGroup,
    employeePlatformActions.createCarpoolGroupSuccess
  )
}
export function* updateCarpoolGroup(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.updateCarpoolGroup,
    employeePlatformActions.updateCarpoolGroupSuccess
  )
}
export function* deleteCarpoolGroup(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.deleteCarpoolGroup,
    employeePlatformActions.deleteCarpoolGroupSuccess
  )
}
export function* getCarpoolGroupMemberships(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getCarpoolGroupMemberships,
    employeePlatformActions.getCarpoolGroupMembershipsSuccess
  )
}
export function* getReceivedCarpoolRequests(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getReceivedCarpoolRequests,
    employeePlatformActions.getReceivedCarpoolRequestsSuccess
  )
}
export function* getSentCarpoolRequests(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getSentCarpoolRequests,
    employeePlatformActions.getSentCarpoolRequestsSuccess
  )
}
export function* requestToJoinCarpool(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.requestToJoinCarpool,
    employeePlatformActions.requestToJoinCarpoolSuccess
  )
}
export function* acceptCarpoolRequest(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.acceptCarpoolRequest,
    employeePlatformActions.acceptCarpoolRequestSuccess
  )
}
export function* declineCarpoolRequest(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.declineCarpoolRequest,
    employeePlatformActions.declineCarpoolRequestSuccess
  )
}

// TRIPS FLOWS
export function* getCurrentMonthTrips(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getCurrentMonthTrips,
    employeePlatformActions.getCurrentMonthTripsSuccess
  )
}
export function* addTrip(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.addTrip,
    employeePlatformActions.addTripSuccess
  )
}
// export function* getAllMerchants(action: AnyAction) {
//   yield defaultFlow(
//     action,
//     employeePlatformService.getAllMerchants,
//     employeePlatformActions.getAllMerchantsSuccess
//   )
// }
export function* getAllModes(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getAllModes,
    employeePlatformActions.getAllModesSuccess
  )
}

// LEADERBOARDS FLOWS
export function* getMyLeaderboards(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getMyLeaderboards,
    employeePlatformActions.getMyLeaderboardsSuccess
  )
}
export function* getLeaderboardRankings(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getLeaderboardRankings,
    employeePlatformActions.getLeaderboardRankingsSuccess
  )
}

// OPT-IN FLOWS
export function* getAllOptIns(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getAllOptIns,
    employeePlatformActions.getAllOptInsSuccess
  )
}
export function* setOptInChoice(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.setOptInChoice,
    employeePlatformActions.setOptInChoiceSuccess
  )
}

// CONCIERGE FLOWS
export function* getFAQs(action: AnyAction) {
  yield defaultFlow(
    action,
    employeePlatformService.getFAQs,
    employeePlatformActions.getFAQsSuccess
  )
}

/**************************************
 * Watchers
 **************************************/

// PROFILE WATCHERS
export function* watchGetProfileData() {
  yield takeEvery([employeePlatformActions.getProfileData.type], getProfileData)
}
export function* watchUpdateProfileData() {
  yield takeEvery(
    [employeePlatformActions.updateProfileData.type],
    updateProfileData
  )
}
export function* watchGetWalletBalance() {
  yield takeEvery(
    [
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.getWalletBalance.type,
      employeePlatformActions.topUpCardSuccess.type,
    ],
    getWalletBalance
  )
}
export function* watchGetAllPayouts() {
  yield takeEvery(
    [
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.getAllPayouts.type,
    ],
    getAllPayouts
  )
}
export function* watchGetAllRewards() {
  yield takeEvery(
    [
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.getAllRewards.type,
    ],
    getAllRewards
  )
}
export function* watchGetAllCharges() {
  yield takeEvery(
    [
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.getAllCharges.type,
    ],
    getAllCharges
  )
}
export function* watchGetDOB() {
  yield takeEvery([employeePlatformActions.getDOB.type], getDOB)
}
export function* watchGetDashboardStats() {
  yield takeEvery(
    [
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.getDashboardStats.type,
    ],
    getDashboardStats
  )
}

// PAYMENT METHODS WATCHERS
export function* watchGetAllPaymentMethods() {
  yield takeEvery(
    [
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.getAllPaymentMethods.type,
    ],
    getAllPaymentMethods
  )
}
export function* watchDeletePaymentMethod() {
  yield takeEvery(
    [employeePlatformActions.deletePaymentMethod.type],
    deletePaymentMethod
  )
}

// CARDS WATCHERS
export function* watchGetCards() {
  yield takeEvery(
    [
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.getCards.type,
    ],
    getCards
  )
}
export function* watchLockCard() {
  yield takeEvery([employeePlatformActions.lockCard.type], lockCard)
}
export function* watchUnlockCard() {
  yield takeEvery([employeePlatformActions.unlockCard.type], unlockCard)
}
export function* watchTopUpCard() {
  yield takeEvery([employeePlatformActions.topUpCard.type], topUpCard)
}

// PROGRAMS WATCHERS
export function* watchGetAllPrograms() {
  yield takeEvery(
    [
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.getAllPrograms.type,
      employeePlatformActions.createProgramRequestSuccess.type,
      employeePlatformActions.cancelProgramRequestSuccess.type,
    ],
    getAllPrograms
  )
}
export function* watchGetAllProgramRequests() {
  yield takeEvery(
    [
      employeePlatformActions.getAllProgramsSuccess.type,
      employeePlatformActions.getAllProgramRequests.type,
      employeePlatformActions.createProgramRequestSuccess.type,
      employeePlatformActions.cancelProgramRequestSuccess.type,
    ],
    getAllProgramRequests
  )
}
export function* watchCreateProgramRequest() {
  yield takeEvery(
    [employeePlatformActions.createProgramRequest.type],
    createProgramRequest
  )
}
export function* watchCancelProgramRequest() {
  yield takeEvery(
    [employeePlatformActions.cancelProgramRequest.type],
    cancelProgramRequest
  )
}

// PRETAX WATCHERS
export function* watchGetPreTaxEmployeeDeduction() {
  yield takeEvery(
    [
      employeePlatformActions.getPreTaxEmployeeDeduction.type,
      employeePlatformActions.getPreTaxProgramsSuccess.type,
    ],
    getPreTaxEmployeeDeduction
  )
}
export function* watchGetPreTaxPrograms() {
  yield takeLatest(
    [
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.getPreTaxPrograms.type,
    ],
    getPreTaxPrograms
  )
}
export function* watchGetPreTaxBalance() {
  yield takeEvery(
    [
      employeePlatformActions.getPreTaxBalance.type,
      employeePlatformActions.getPreTaxProgramsSuccess.type,
    ],
    getPreTaxBalance
  )
}
export function* watchGetPreTaxBenefitHistory() {
  yield takeEvery(
    [
      employeePlatformActions.getPreTaxBenefitHistory.type,
      employeePlatformActions.getPreTaxProgramsSuccess.type,
    ],
    getPreTaxBenefitHistory
  )
}
export function* watchCreatePreTaxEmployeeAllocation() {
  yield takeEvery(
    [employeePlatformActions.createPreTaxEmployeeAllocation.type],
    createPreTaxEmployeeAllocation
  )
}
export function* watchUpdatePreTaxEmployeeAllocation() {
  yield takeEvery(
    [employeePlatformActions.updatePreTaxEmployeeAllocation.type],
    updatePreTaxEmployeeAllocation
  )
}

// CARPOOL WATCHERS
export function* watchGetAllCarpoolGroups() {
  yield takeEvery(
    [
      employeePlatformActions.getAllCarpoolGroups.type,
      employeePlatformActions.getProfileDataSuccess.type,
    ],
    getAllCarpoolGroups
  )
}
export function* watchGetMyCarpoolGroups() {
  yield takeEvery(
    [
      employeePlatformActions.getMyCarpoolGroups.type,
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.acceptCarpoolRequestSuccess.type,
    ],
    getMyCarpoolGroups
  )
}
export function* watchSearchCarpoolGroups() {
  yield takeLatest(
    [employeePlatformActions.searchCarpoolGroups.type],
    searchCarpoolGroups
  )
}
export function* watchCreateCarpoolGroup() {
  yield takeEvery(
    [employeePlatformActions.createCarpoolGroup.type],
    createCarpoolGroup
  )
}
export function* watchUpdateCarpoolGroup() {
  yield takeEvery(
    [employeePlatformActions.updateCarpoolGroup.type],
    updateCarpoolGroup
  )
}
export function* watchDeleteCarpoolGroup() {
  yield takeEvery(
    [employeePlatformActions.deleteCarpoolGroup.type],
    deleteCarpoolGroup
  )
}
export function* watchGetCarpoolGroupMemberships() {
  yield takeEvery(
    [
      employeePlatformActions.getCarpoolGroupMemberships.type,
      employeePlatformActions.getAllCarpoolGroupsSuccess.type,
    ],
    getCarpoolGroupMemberships
  )
}
export function* watchGetReceivedCarpoolRequests() {
  yield takeEvery(
    [
      employeePlatformActions.getReceivedCarpoolRequests.type,
      employeePlatformActions.getMyCarpoolGroupsSuccess.type,
    ],
    getReceivedCarpoolRequests
  )
}
export function* watchGetSentCarpoolRequests() {
  yield takeEvery(
    [
      employeePlatformActions.getSentCarpoolRequests.type,
      employeePlatformActions.getAllCarpoolGroupsSuccess.type,
    ],
    getSentCarpoolRequests
  )
}
export function* watchRequestToJoinCarpool() {
  yield takeEvery(
    [employeePlatformActions.requestToJoinCarpool.type],
    requestToJoinCarpool
  )
}
export function* watchAcceptCarpoolRequest() {
  yield takeEvery(
    [employeePlatformActions.acceptCarpoolRequest.type],
    acceptCarpoolRequest
  )
}
export function* watchDeclineCarpoolRequest() {
  yield takeEvery(
    [employeePlatformActions.declineCarpoolRequest.type],
    declineCarpoolRequest
  )
}

// TRIPS WATCHERS
export function* watchGetCurrentMonthTrips() {
  yield takeEvery(
    [employeePlatformActions.getCurrentMonthTrips.type],
    getCurrentMonthTrips
  )
}
export function* watchAddTrip() {
  yield takeEvery([employeePlatformActions.addTrip.type], addTrip)
}
// export function* watchGetAllMerchants() {
//   yield takeEvery(
//     [
//       employeePlatformActions.getAllMerchants.type,
//       employeePlatformActions.getProfileDataSuccess.type,
//     ],
//     getAllMerchants
//   )
// }
export function* watchGetAllModes() {
  yield takeEvery(
    [
      employeePlatformActions.getAllModes.type,
      employeePlatformActions.getProfileDataSuccess.type,
    ],
    getAllModes
  )
}

// LEADERBOARDS WATCHERS
export function* watchGetAllLeaderboards() {
  yield takeEvery(
    [
      employeePlatformActions.getMyLeaderboards.type,
      employeePlatformActions.getProfileDataSuccess.type,
    ],
    getMyLeaderboards
  )
}
export function* watchGetLeaderboardRankings() {
  yield takeEvery(
    [employeePlatformActions.getLeaderboardRankings.type],
    getLeaderboardRankings
  )
}

// OPT-IN WATCHERS
export function* watchGetAllOptIns() {
  yield takeEvery(
    [
      employeePlatformActions.getAllOptIns.type,
      employeePlatformActions.getProfileDataSuccess.type,
      employeePlatformActions.setOptInChoiceSuccess.type,
    ],
    getAllOptIns
  )
}
export function* watchSetOptInChoice() {
  yield takeEvery([employeePlatformActions.setOptInChoice.type], setOptInChoice)
}

// CONCIERGE WATCHERS
export function* watchGetFAQs() {
  yield takeEvery(
    [
      employeePlatformActions.getFAQs.type,
      employeePlatformActions.getProfileDataSuccess.type,
    ],
    getFAQs
  )
}

export default function* () {
  yield all([
    // PROFILE
    watchGetProfileData(),
    watchUpdateProfileData(),
    watchGetWalletBalance(),
    watchGetAllPayouts(),
    watchGetAllRewards(),
    watchGetAllCharges(),
    watchGetDOB(),
    watchGetDashboardStats(),

    // PAYMENT METHODS
    watchGetAllPaymentMethods(),
    watchDeletePaymentMethod(),

    // CARDS
    watchGetCards(),
    watchLockCard(),
    watchUnlockCard(),
    watchTopUpCard(),

    // PROGRAMS
    watchGetAllPrograms(),
    watchGetAllProgramRequests(),
    watchCreateProgramRequest(),
    watchCancelProgramRequest(),

    // PRETAX
    watchGetPreTaxEmployeeDeduction(),
    watchGetPreTaxPrograms(),
    watchGetPreTaxBalance(),
    watchGetPreTaxBenefitHistory(),
    watchCreatePreTaxEmployeeAllocation(),
    watchUpdatePreTaxEmployeeAllocation(),

    // CARPOOL
    watchGetAllCarpoolGroups(),
    watchGetMyCarpoolGroups(),
    watchSearchCarpoolGroups(),
    watchCreateCarpoolGroup(),
    watchUpdateCarpoolGroup(),
    watchDeleteCarpoolGroup(),
    watchGetCarpoolGroupMemberships(),
    watchGetReceivedCarpoolRequests(),
    watchGetSentCarpoolRequests(),
    watchRequestToJoinCarpool(),
    watchAcceptCarpoolRequest(),
    watchDeclineCarpoolRequest(),

    // TRIPS
    watchGetCurrentMonthTrips(),
    watchAddTrip(),
    // watchGetAllMerchants(),
    watchGetAllModes(),

    // LEADERBOARDS
    watchGetAllLeaderboards(),
    watchGetLeaderboardRankings(),

    // OPT-IN
    watchGetAllOptIns(),
    watchSetOptInChoice(),

    // CONCIERGE
    watchGetFAQs(),
  ])
}
