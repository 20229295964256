import Button from 'core-system/Button'
import EditIcon from 'core-system/Icons/Actions/Edit'
import Loading from 'core-system/Loading'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Faq, Faqs } from 'redux/concierge/conciergeTypes'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import SegmentService from 'redux/config/services/SegmentService'
import { Worksite } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import FaqCard from './FaqCard'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
`

const Title = styled.div`
  ${(props) => props.theme.typography.h4}
`

const Subtitle = styled.div`
  ${(props) => props.theme.typography.body1}
  max-width: ${(props) => props.theme.pxToRem(550)};
  margin-bottom: 1.5rem;
`

const EmptyContainer = styled.div`
  padding: ${(props) => props.theme.pxToRem(40)};
  height: ${(props) => props.theme.pxToRem(348)};
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 0.9375rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const IconContainer = styled.div`
  margin-bottom: 2.25rem;
`

const Text = styled.div`
  ${(props) => props.theme.typography.action3}
  padding-bottom: 1.5rem;
`

interface FaqTableProps {
  allFaqs: Faqs
  allWorksites: Worksite[]
}

const FaqTable = React.memo((props: FaqTableProps) => {
  const { allWorksites, allFaqs } = props

  const navigate = useNavigate()
  const location = useLocation()
  const isEmpty = allFaqs && !allFaqs.faq

  const handleAnnouncementClick = useCallback(() => {
    navigate(`/concierge/faq`, {
      state: { from: location.pathname },
    })
    SegmentService.track('concierge-actionBtn-click', {
      feature: 'faqs',
    })
  }, [navigate, location.pathname])

  const renderContent = () => {
    if (!allWorksites || !allFaqs) {
      return <Loading isCard={false} height={pxToRem(300)} />
    } else if (isEmpty) {
      return (
        <EmptyContainer>
          <IconContainer>
            <img alt={'faq'} src={`${StaticAssetsUrl}/concierge/faq.png`} />
          </IconContainer>
          <Text>You Don&apos;t Have Your FAQ Section Set Up Yet!</Text>
          <Button id='faq-get-started-btn' onClick={handleAnnouncementClick}>
            Get Started
          </Button>
        </EmptyContainer>
      )
    } else {
      return allFaqs.faq.map((faq: Faq, idx: number) => {
        return <FaqCard key={idx} faq={faq} idx={idx} />
      })
    }
  }

  return (
    <>
      <Header>
        <Title>FAQs</Title>
        {!isEmpty && allFaqs && (
          <Button
            id='faq-edit-btn'
            onClick={handleAnnouncementClick}
            iconLeft={<EditIcon />}
          >
            Edit
          </Button>
        )}
      </Header>
      <Subtitle>
        Set up questions &amp; answers to your commuters most frequently asked
        questions about their mobility program.
      </Subtitle>
      {renderContent()}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FaqTable.displayName = 'FaqTable'
}

export default FaqTable
