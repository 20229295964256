import { css } from 'styled-components'

export const Name = 'Montserrat, san-serif'

const font = css`
  @font-face {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
  @font-face {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
  }
`

export default font
