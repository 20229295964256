import { AxiosResponse } from 'axios'
import Request from '../config/services/Request'
import {
  CreateQtfProgramParams,
  EmployeeAllocationUpdateData,
  QtfCsvReportParams,
  UpdateQtfProgramParams,
} from './qtfTypes'

const getAllQtfPrograms = (): Promise<AxiosResponse> => {
  return Request.get(`/hr/tax_benefits/benefits_config/`)
}

const createQtfProgram = (
  params: CreateQtfProgramParams
): Promise<AxiosResponse>[] => {
  const {
    types,
    commUserIds: _c,
    employerParkingContribution,
    employerTransitContribution,
    ...rest
  } = params

  return types.map((type) => {
    return Request.post('/hr/tax_benefits/benefits_config/', {
      type,
      employerContribution:
        type === 'COMMUTER_PARKING'
          ? employerParkingContribution
          : employerTransitContribution,
      ...rest,
    })
  })
}

const updateQtfProgram = (
  params: UpdateQtfProgramParams
): Promise<AxiosResponse>[] => {
  const {
    typeMap,
    types,
    employerParkingContribution,
    employerTransitContribution,
    ...rest
  } = params
  return types.map((type) => {
    const programId = typeMap[type].id
    return Request.put(`/hr/tax_benefits/benefits_config/${programId}/`, {
      type,
      employerContribution:
        type == 'COMMUTER_PARKING'
          ? employerParkingContribution
          : employerTransitContribution,
      ...rest,
    })
  })
}

const cancelQtfProgram = (params: string[]): Promise<AxiosResponse>[] => {
  return params.map((id) => {
    return Request.del(`/hr/tax_benefits/benefits_config/${id}/`)
  })
}

const getQTFDashboardServices = (params: {
  segmentId: string
}): Promise<AxiosResponse> => {
  const { segmentId } = params
  return Request.get(`/hr/tax_benefits/benefits_config/${segmentId}/services/`)
}

const getQTFDashboardStats = (params: {
  segmentId: string
}): Promise<AxiosResponse> => {
  const { segmentId } = params
  return Request.get(
    `/hr/tax_benefits/benefits_config/${segmentId}/summary_stats/`
  )
}

const getQTFDashboardDeductions = (params: {
  segmentId: string
  format: 'csv' | 'json'
}): Promise<AxiosResponse> => {
  const { segmentId, format } = params
  return Request.get(
    `/hr/tax_benefits/current_deductions/report/?segment=${segmentId}&format=${format}`,
    format === 'csv'
  )
}

const getQTFUpcomingAllocations = (params: {
  segmentId: string
}): Promise<AxiosResponse> => {
  const { segmentId } = params
  return Request.get(
    `/hr/tax_benefits/current_deductions/per_employee/?segment=${segmentId}`
  )
}

const getAllQTFDeductions = (): Promise<AxiosResponse> => {
  return Request.get(`/hr/tax_benefits/current_deductions/report/`)
}

const getDashboardCsvReports = (
  params: QtfCsvReportParams
): Promise<AxiosResponse> => {
  const reportUrls = {
    usage: '/hr/dashboard/reports/qtf_stats/',
    allocation: '',
  }
  let URLQuery = ''
  console.log(params)
  if (params.startDate) {
    URLQuery = 'date__gte=' + params.startDate
  }
  if (params.endDate) {
    URLQuery += '&date__lt=' + params.endDate
  }
  if (params.segmentId) {
    URLQuery += '&segment=' + params.segmentId
  }

  return Request.get(
    `${reportUrls[params.reportType]}?${URLQuery}&format=csv`,
    true
  )
}

const sendEmployeeReminder = (params: {
  segmentId: string
}): Promise<AxiosResponse> => {
  const { segmentId } = params
  return Request.post(
    `/hr/tax_benefits/benefits_config/${segmentId}/send_reminder/`,
    {}
  )
}

const getProgramCommUsers = (params: {
  segmentId: string
}): Promise<AxiosResponse> => {
  const { segmentId } = params
  return Request.get(
    `/hr/tax_benefits/benefits_config/${segmentId}/user_notifications/`,
    true
  )
}

const updateProgramCommUsers = (params: {
  segmentId: string
  userIds: string[]
}): Promise<AxiosResponse> => {
  const { segmentId, userIds } = params
  return Request.put(
    `/hr/tax_benefits/benefits_config/${segmentId}/user_notifications/`,
    { users: userIds },
    false,
    true
  )
}

const updateEmployeeAllocation = (params: {
  allocationId: string
  updatedAllocation: EmployeeAllocationUpdateData
}): Promise<AxiosResponse> => {
  const { allocationId, updatedAllocation } = params
  return Request.put(
    `/hr/tax_benefits/current_deductions/${allocationId}/`,
    updatedAllocation
  )
}

const createEmployeeAllocation = (
  params: EmployeeAllocationUpdateData
): Promise<AxiosResponse> => {
  const newAllocation = params
  return Request.post('/hr/tax_benefits/current_deductions/', newAllocation)
}

export default {
  getAllQtfPrograms,
  createQtfProgram,
  updateQtfProgram,
  cancelQtfProgram,
  getQTFDashboardDeductions,
  getQTFUpcomingAllocations,
  getQTFDashboardServices,
  getQTFDashboardStats,
  getAllQTFDeductions,
  getDashboardCsvReports,
  sendEmployeeReminder,
  updateProgramCommUsers,
  getProgramCommUsers,
  updateEmployeeAllocation,
  createEmployeeAllocation,
}
