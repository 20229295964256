import { all, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { SegmentParams } from 'redux/employer/employerTypes'
import {
  SegmentAndType,
  WorksiteAndTypeAndDate,
} from 'redux/programs/programsTypes'

import { Action } from 'shared/Types/Actions'
import GrhService from './grhService'
import { grhActions } from './grhSlice'
import { GrhProgram, GrhProgramUpdate } from './grhTypes'

export function* getGrhRecommendations(action: Action<SegmentParams>) {
  yield defaultFlow(
    action,
    GrhService.getGrhRecommendations,
    grhActions.getGrhRecommendationsSuccess
  )
}

export function* createGrhProgram(action: Action<GrhProgram>) {
  yield defaultFlow(
    action,
    GrhService.createGrhProgram,
    grhActions.createGrhProgramSuccess
  )
}

export function* updateGrhProgram(action: Action<GrhProgramUpdate>) {
  yield defaultFlow(
    action,
    GrhService.updateGrhProgram,
    grhActions.updateGrhProgramSuccess
  )
}

export function* cancelGrhProgram(action: Action<string>) {
  yield defaultFlow(
    action,
    GrhService.cancelGrhProgram,
    grhActions.cancelGrhProgramSuccess
  )
}

export function* getGrhUsageHistory(action: Action<SegmentAndType>) {
  yield defaultFlow(
    action,
    GrhService.getGrhUsageHistory,
    grhActions.getGrhUsageHistorySuccess
  )
}

export function* getGrhDashboardServices(
  action: Action<WorksiteAndTypeAndDate>
) {
  yield defaultFlow(
    action,
    GrhService.getGrhDashboardServices,
    grhActions.getGrhDashboardServiceSuccess
  )
}

export function* getGrhDashboardStats(action: Action<SegmentAndType>) {
  yield defaultFlow(
    action,
    GrhService.getGrhDashboardStats,
    grhActions.getGrhDashboardStatsSuccess
  )
}

/**
 * Watchers
 */
export function* watchGetGrhRecommendations() {
  yield takeEvery(
    [grhActions.getGrhRecommendations.type],
    getGrhRecommendations
  )
}

export function* watchCreateGrhProgram() {
  yield takeEvery([grhActions.createGrhProgram.type], createGrhProgram)
}

export function* watchUpdateGrhProgram() {
  yield takeEvery([grhActions.updateGrhProgram.type], updateGrhProgram)
}

export function* watchCancelGrhProgram() {
  yield takeEvery([grhActions.cancelGrhProgram.type], cancelGrhProgram)
}

export function* watchGetGrhUsageHistory() {
  yield takeEvery([grhActions.getGrhUsageHistory.type], getGrhUsageHistory)
}

export function* watchGetGrhDashboardServices() {
  yield takeEvery(
    [grhActions.getGrhDashboardServices.type],
    getGrhDashboardServices
  )
}
export function* watchGetGrhDashboardStats() {
  yield takeEvery([grhActions.getGrhDashboardStats.type], getGrhDashboardStats)
}

export default function* () {
  yield all([
    watchGetGrhRecommendations(),
    watchCreateGrhProgram(),
    watchUpdateGrhProgram(),
    watchCancelGrhProgram(),
    watchGetGrhUsageHistory(),
    watchGetGrhDashboardServices(),
    watchGetGrhDashboardStats(),
  ])
}
