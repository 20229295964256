import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import Table, {
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React, { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { MyCommuter, MyCommuterDeclined } from 'redux/employee/employeeTypes'
import { Worksite } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import MyCommuterDetailsModal from './MyCommuterDetailsModal'
import MyCommutersSearch from './MyCommutersSearch'

const Body = styled.div`
  margin: 1.5rem 0 6rem;
`

const UserContainer = styled.div`
  display: flex;
`

const NameContainer = styled.div`
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`

const EmptyTableRow = styled(TableRow)`
  text-align: center;
`

const getTableRows = (
  filteredCommuters: MyCommuter[],
  sortByFilter: string,
  worksites: Worksite[]
) => {
  return filteredCommuters
    .sort((a, b) =>
      sortByFilter === 'desc'
        ? new Date(b.archived).getTime() - new Date(a.archived).getTime()
        : new Date(a.archived).getTime() - new Date(b.archived).getTime()
    )
    .map((commuter, idx) => {
      const matchingWorksite = worksites.find(
        (wrk) => wrk.id === commuter.worksiteId
      )
      return (
        <TableRow key={idx} display='flex'>
          <TableRowContents isClickable={false} autoResize={false}>
            <TableRowCell
              minWidth={pxToRem(240)}
              equalSize={false}
              overflow='hidden'
            >
              <UserContainer>
                <PlatformSvg
                  folder='users'
                  variant='userBasic'
                  width={40}
                  height={40}
                />
                <NameContainer>
                  <Text variant='action4' hideOverflow>
                    {commuter.name}
                  </Text>
                  <Text variant='body2' textColor={palette.text.placeholder}>
                    {commuter.employeeCorporateId
                      ? `#${commuter.employeeCorporateId}`
                      : ''}
                  </Text>
                </NameContainer>
              </UserContainer>
            </TableRowCell>
            <TableRowCell
              icon='time'
              overflow='hidden'
              equalSize={false}
              flex='1'
            >
              {moment(commuter.declinedAt).format('MMMM Do, YYYY')}
            </TableRowCell>
            <TableRowCell
              icon='location'
              overflow='hidden'
              equalSize={false}
              flex='1'
            >
              {matchingWorksite ? matchingWorksite.alias : 'unknown'}
            </TableRowCell>
            <TableRowCell overflow='hidden' equalSize={false} flex='1.8'>
              {commuter.email}
            </TableRowCell>
          </TableRowContents>
        </TableRow>
      )
    })
}

const formatMyCommuters = (employees: MyCommuterDeclined[]): MyCommuter[] => {
  return employees.map((emp) => {
    return {
      id: emp.id,
      name: emp.name,
      email: emp.email,
      employeeCorporateId: emp.employeeCorporateId,
      worksiteId: emp.worksiteId,
      declinedAt: emp.declinedAt,
      totalTrips: 0,
      programsEnrolled: [],
      segmentsEnrolled: [],
      competitionsEnrolled: [],
      hasPhysicalCard: false,
      remainingProgramBudget: null,
      currentIncentives: null,
      fleetRemaining: null,
      hasRegistered: false,
      hasInvite: false,
    }
  })
}

interface MyCommutersDeclined {
  declinedEmployees: MyCommuterDeclined[]
}

const MyCommutersDeclined = React.memo((props: MyCommutersDeclined) => {
  const { declinedEmployees } = props

  const formattedEmployees = formatMyCommuters(declinedEmployees)

  const { worksites } = useSelector((state: AppState) => state.employer)
  const [sortByFilter, setSortByFilter] = useState('desc')
  const [filteredCommuters, setFilteredCommuters] = useState(formattedEmployees)
  const [activeEmployeeId, setActiveEmployeeId] = useState(null)

  useLayoutEffect(() => {
    setFilteredCommuters(formatMyCommuters(declinedEmployees))
  }, [declinedEmployees])

  if (!formattedEmployees || !filteredCommuters) {
    return <Loading isCard={false} />
  }

  return (
    <>
      <MyCommutersSearch
        myCommuters={formattedEmployees}
        filteredCommuters={filteredCommuters}
        setFilteredCommuters={setFilteredCommuters}
        sortByFilter={sortByFilter}
        setSortByFilter={setSortByFilter}
        activeSegment={'archived'}
        isAdd={false}
      />
      <Body>
        <Table hasHeader={false} maxRows={10} loadMoreAnimation={false}>
          <TableLabelRow overflow='hidden'>
            <TableRowCell minWidth={pxToRem(240)} equalSize={false}>
              Commuter
            </TableRowCell>
            <TableRowCell equalSize={false} flex='1'>
              Decline Date
            </TableRowCell>
            <TableRowCell equalSize={false} flex='1'>
              Worksite
            </TableRowCell>
            <TableRowCell equalSize={false} flex='1.8'>
              Email
            </TableRowCell>
          </TableLabelRow>
          {filteredCommuters.length > 0 ? (
            getTableRows(filteredCommuters, sortByFilter, worksites)
          ) : (
            <EmptyTableRow>No Results Found</EmptyTableRow>
          )}
        </Table>
      </Body>
      <MyCommuterDetailsModal
        employeeId={activeEmployeeId}
        open={activeEmployeeId && true}
        closeModal={() => setActiveEmployeeId(null)}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommutersDeclined.displayName = 'MyCommutersDeclined'
}

export default MyCommutersDeclined
