import React from 'react'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'

interface NoDataTextProps {
  text: string
  center?: boolean
}

const NoDataText = React.memo((props: NoDataTextProps) => {
  const { text, center = true } = props
  return (
    <Text
      variant='h4'
      textColor={palette.text.placeholder}
      textAlign={center ? 'center' : 'start'}
    >
      {text}
    </Text>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NoDataText.displayName = 'NoDataText'
}

export default NoDataText
