import {
  CardProgram,
  Merchant,
  ProgramServiceUsage,
  SegmentAndType,
  SegmentAndTypeAndDate,
} from 'redux/programs/programsTypes'
import {
  OtpDashboardStats,
  OtpGroup,
  OtpProgram,
  OtpProgramUpdate,
  OtpServiceUsage,
  OtpUsageHistory,
} from './otpTypes'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'
import { SegmentParams } from 'redux/employer/employerTypes'
import { programsActions } from 'redux/programs/programsSlice'
import { oneTimeProviders } from 'redux/micromobility/micromobilitySlice'

export interface OtpState {
  otpPrograms: Dictionary<OtpGroup>
  otpProgramUpdated: boolean
  otpProgramsLoaded: boolean

  dashboardStats: OtpDashboardStats
  serviceUsage: ProgramServiceUsage[]
  availableMerchants: {
    micromobility: Merchant[]
  }
  usageHistory: OtpUsageHistory[]
}

export const initialState: OtpState = {
  otpPrograms: null,
  otpProgramUpdated: false,
  otpProgramsLoaded: false,

  dashboardStats: null,
  serviceUsage: null,
  availableMerchants: null,
  usageHistory: null,
}

const handleToggleProgramUpdate = (state: OtpState) => {
  if (!state.otpProgramUpdated) {
    return {
      ...state,
      otpProgramUpdated: !state.otpProgramUpdated,
      otpProgramsLoaded: false,
    }
  }

  return {
    ...state,
    otpProgramUpdated: !state.otpProgramUpdated,
  }
}

const createOtpProgram = createAction<OtpProgram>('otp/createOtpProgram')
const createOtpProgramSuccess = createAction('otp/createOtpProgramSuccess')
const updateOtpProgram = createAction<OtpProgramUpdate>('otp/updateOtpProgram')
const cancelOtpProgram = createAction<string>('otp/cancelOtpProgram')

const otpSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    updateOtpProgramSuccess(state) {
      return handleToggleProgramUpdate(state)
    },
    cancelOtpProgramSuccess(state) {
      return handleToggleProgramUpdate(state)
    },
    toggleOtpProgramUpdated(state) {
      return handleToggleProgramUpdate(state)
    },
    getOtpRecommendations(state, _action: PayloadAction<SegmentParams>) {
      state.availableMerchants = null
    },
    getOtpRecommendationsSuccess(state, action: any) {
      const otpMerchants = action.payload.filter((merchant) =>
        oneTimeProviders.includes(merchant.name)
      )
      state.availableMerchants = { micromobility: otpMerchants }
    },
    getOtpDashboardStats(state, _action: PayloadAction<SegmentAndType>) {
      state.dashboardStats = null
    },
    getOtpDashboardStatsSuccess(state, action: any) {
      const payload = action.payload
      state.dashboardStats = {
        ...state.dashboardStats,
        totalSpend: payload.totalSpend.total,
        totalSpendDifference: payload.totalSpend.difference,
        numEmployees: payload.engagement,
        numEmployeesDifference: payload.engagementDifference,
      }
    },
    getOtpDashboardServices(
      state,
      _action: PayloadAction<SegmentAndTypeAndDate>
    ) {
      state.serviceUsage = null
    },
    getOtpDashboardServicesSuccess(
      state,
      action: PayloadAction<OtpServiceUsage[]>
    ) {
      state.serviceUsage = action.payload
    },
    getOtpUsageHistory(state, _action: PayloadAction<SegmentParams>) {
      state.usageHistory = null
    },
    getOtpUsageHistorySuccess(
      state,
      action: PayloadAction<OtpUsageHistory[]> | any
    ) {
      state.usageHistory = action.payload
      state.dashboardStats = {
        ...state.dashboardStats,
        numTransactions: action.payload.length,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      programsActions.getAllProgramsSuccess,
      (state, action: PayloadAction<CardProgram[]>) => {
        const activeStatuses = ['ACTIVE', 'UPDATING']
        const groupedProgramsById = action.payload
          .filter((p) => p.type === 'OTP')
          .reduce((agg, program) => {
            if (program.status === 'CANCELLED') return agg

            const defaultProgramGroup = {} as any
            const formattedProgram = {
              ...program,
              budget: parseInt(program.budget),
            }

            if (activeStatuses.includes(program.status)) {
              defaultProgramGroup.active = formattedProgram
            } else {
              defaultProgramGroup.nextMonth = formattedProgram
            }

            agg[program.segment] = {
              ...agg[program.segment],
              ...defaultProgramGroup,
            }

            return agg
          }, {})
        state.otpPrograms = groupedProgramsById
        state.otpProgramsLoaded = true
      }
    )
  },
})

export const otpReducer = otpSlice.reducer
export const otpActions = {
  ...otpSlice.actions,
  createOtpProgram,
  createOtpProgramSuccess,
  updateOtpProgram,
  cancelOtpProgram,
}
