import React from 'react'
import { ButtonProps as _ButtonProps } from './Button'
import styled from 'styled-components'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import ButtonV2 from '.'

const ButtonTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
`

export interface HeaderTextButtonProps extends _ButtonProps {
  header: string
  text: string
}

const HeaderTextButton = React.memo((props: HeaderTextButtonProps) => {
  const { header, text } = props
  return (
    <ButtonV2
      variant='secondary'
      paddingY='1rem'
      onClick={props.onClick}
      iconRight={props.iconRight}
      width={props.width}
    >
      <ButtonTextContainer>
        <Text
          variant='h5'
          textAlign='left'
          textColor={palette.secondary.maroon1}
        >
          {header}
        </Text>
        <Text
          variant='body1'
          textAlign={'start'}
          textColor={palette.grey.grey10}
        >
          {text}
        </Text>
      </ButtonTextContainer>
    </ButtonV2>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  HeaderTextButton.displayName = 'HeaderTextButton'
}

export default HeaderTextButton
