import { AxiosResponse } from 'axios'
import { LeaderboardRankingsParams } from 'redux/employer/employerTypes'
import {
  LeaderboardCreatePayload,
  LeaderboardUpdatePayload,
} from 'redux/leaderboards/leaderboardsTypes'
import Request from '../config/services/Request'

const getLeaderboards = (): Promise<AxiosResponse> => {
  return Request.get(`/hr/engagement/competitions/`)
}

const cancelLeaderboard = (leaderboardId: string): Promise<AxiosResponse> => {
  return Request.del(`/hr/engagement/competitions/${leaderboardId}/`)
}

const getLeaderboardRankings = (
  params: LeaderboardRankingsParams
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/engagement/competitions/${params.familyId}/rankings/?month=${
      params.month || ''
    }`
  )
}

const createLeaderboard = (
  payload: LeaderboardCreatePayload
): Promise<AxiosResponse> => {
  return Request.post('/hr/engagement/competitions/', payload)
}

const updateLeaderboard = (
  payload: LeaderboardUpdatePayload
): Promise<AxiosResponse> => {
  return Request.put(`/hr/engagement/competitions/${payload.id}/`, payload)
}

export default {
  getLeaderboards,
  cancelLeaderboard,
  getLeaderboardRankings,
  createLeaderboard,
  updateLeaderboard,
}
