import { AnyAction } from '@reduxjs/toolkit'
import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import { defaultFlow, multiResponseFlow } from 'redux/config/saga/Common'
import { userActions } from 'redux/user/userSlice'
import { Action } from 'shared/Types/Actions'
import qtfService from './qtfService'
import { qtfActions } from './qtfSlice'
import { CreateQtfProgramParams, UpdateQtfProgramParams } from './qtfTypes'

export function* getAllQtfPrograms(action: AnyAction) {
  yield defaultFlow(
    action,
    qtfService.getAllQtfPrograms,
    qtfActions.getAllQtfProgramSuccess
  )
}

export function* createQtfProgram(action: Action<CreateQtfProgramParams>) {
  yield multiResponseFlow(
    action,
    qtfService.createQtfProgram,
    qtfActions.createQtfProgramSuccess
  )
}

export function* updateQtfProgram(action: Action<UpdateQtfProgramParams>) {
  yield multiResponseFlow(
    action,
    qtfService.updateQtfProgram,
    qtfActions.updateQtfProgramSuccess
  )
}

export function* cancelQtfProgram(action: Action<string>) {
  yield multiResponseFlow(
    action,
    qtfService.cancelQtfProgram,
    qtfActions.cancelQtfProgramSuccess
  )
}

export function* getQtfDashboardStats(action: Action<{ segmentId: string }>) {
  yield defaultFlow(
    action,
    qtfService.getQTFDashboardStats,
    qtfActions.getQTFDashboardStatsSuccess
  )
}

export function* getQTFDashboardDeductions(
  action: Action<{
    segmentId: string
    format: 'csv' | 'json'
    segmentName?: string
  }>
) {
  yield defaultFlow(
    action,
    qtfService.getQTFDashboardDeductions,
    qtfActions.getQTFDashboardDeductionsSuccess
  )
}

export function* getQTFUpcomingAllocations(
  action: Action<{ segmentId: string }>
) {
  yield defaultFlow(
    action,
    qtfService.getQTFUpcomingAllocations,
    qtfActions.getQTFUpcomingAllocationsSuccess
  )
}

export function* getQTFDashboardServices(
  action: Action<{ segmentId: string }>
) {
  yield defaultFlow(
    action,
    qtfService.getQTFDashboardServices,
    qtfActions.getQTFDashboardServicesSuccess
  )
}

export function* getDashboardCsvReport(action: Action<string>) {
  yield defaultFlow(
    action,
    qtfService.getDashboardCsvReports,
    qtfActions.getQTFDashboardCsvReportsSuccess
  )
}

export function* sendEmployeeReminder(action: Action<{ segmentId: string }>) {
  yield defaultFlow(
    action,
    qtfService.sendEmployeeReminder,
    qtfActions.sendEmployeeReminderSuccess
  )
}

export function* getProgramCommUsers(action: Action<{ segmentId: string }>) {
  yield defaultFlow(
    action,
    qtfService.getProgramCommUsers,
    qtfActions.updateQtfProgramCommUsersSuccess
  )
}
export function* updateProgramCommUsers(action: AnyAction) {
  if (action.type === 'qtf/createQtfProgramSuccess') {
    const modifiedAction: AnyAction = {
      ...action,
      type: action.type,
      payload: {
        segmentId: action.meta.segment,
        userIds: action.meta.commUserIds,
      },
    }
    yield defaultFlow(
      modifiedAction,
      qtfService.updateProgramCommUsers,
      qtfActions.updateQtfProgramCommUsersSuccess
    )
  } else {
    yield defaultFlow(
      action,
      qtfService.updateProgramCommUsers,
      qtfActions.updateQtfProgramCommUsersSuccess
    )
  }
}
export function* updateEmployeeAllocation(action: AnyAction) {
  yield defaultFlow(
    action,
    qtfService.updateEmployeeAllocation,
    qtfActions.updateEmployeeAllocationSuccess
  )
}
export function* createEmployeeAllocation(action: AnyAction) {
  yield defaultFlow(
    action,
    qtfService.createEmployeeAllocation,
    qtfActions.createEmployeeAllocationSuccess
  )
}

/**
 * Watchers
 */
export function* watchGetAllQtfPrograms() {
  yield takeLatest(
    [
      userActions.getUserSuccess.type,
      qtfActions.createQtfProgramSuccess.type,
      qtfActions.updateQtfProgramSuccess.type,
      qtfActions.cancelQtfProgramSuccess.type,
    ],
    getAllQtfPrograms
  )
}

export function* watchCreateQtfProgram() {
  yield takeEvery([qtfActions.createQtfProgram.type], createQtfProgram)
}

export function* watchUpdateQtfProgram() {
  yield takeEvery([qtfActions.updateQtfProgram.type], updateQtfProgram)
}

export function* watchCancelQtfProgram() {
  yield takeEvery([qtfActions.cancelQtfProgram.type], cancelQtfProgram)
}

export function* watchGetQtfDashboardStats() {
  yield takeEvery([qtfActions.getQTFDashboardStats.type], getQtfDashboardStats)
}

export function* watchGetQtfDashboardServices() {
  yield takeEvery(
    [qtfActions.getQTFDashboardServices.type],
    getQTFDashboardServices
  )
}

export function* watchGetQtfDashboardDeductions() {
  yield takeEvery(
    [qtfActions.getQTFDashboardDeductions.type],
    getQTFDashboardDeductions
  )
}

export function* watchGetQtfUpcomingAllocations() {
  yield takeEvery(
    [qtfActions.getQTFUpcomingAllocations.type],
    getQTFUpcomingAllocations
  )
}

export function* watchGetDashboardCsvReport() {
  yield takeEvery(
    [qtfActions.getQTFDashboardCsvReports.type],
    getDashboardCsvReport
  )
}

export function* watchSendEmployeeReminder() {
  yield takeEvery([qtfActions.sendEmployeeReminder.type], sendEmployeeReminder)
}

export function* watchGetProgramCommUsers() {
  yield takeEvery([qtfActions.getQtfProgramCommUsers.type], getProgramCommUsers)
}

export function* watchUpdateProgramCommUsers() {
  yield takeEvery(
    [
      qtfActions.updateQtfProgramCommUsers.type,
      qtfActions.createQtfProgramSuccess.type,
    ],
    updateProgramCommUsers
  )
}

export function* watchUpdateEmployeeAllocation() {
  yield takeEvery(
    [qtfActions.updateEmployeeAllocation.type],
    updateEmployeeAllocation
  )
}

export function* watchCreateEmployeeAllocation() {
  yield takeEvery(
    [qtfActions.createEmployeeAllocation.type],
    createEmployeeAllocation
  )
}

export default function* () {
  yield all([
    watchGetAllQtfPrograms(),
    watchCreateQtfProgram(),
    watchUpdateQtfProgram(),
    watchCancelQtfProgram(),
    watchGetQtfDashboardStats(),
    watchGetQtfDashboardServices(),
    watchGetQtfDashboardDeductions(),
    watchGetQtfUpcomingAllocations(),
    watchGetDashboardCsvReport(),
    watchSendEmployeeReminder(),
    watchGetProgramCommUsers(),
    watchUpdateProgramCommUsers(),
    watchUpdateEmployeeAllocation(),
    watchCreateEmployeeAllocation(),
  ])
}
