import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import fileDownload from 'js-file-download'
import { DashboardRecentTripsData } from 'redux/mapIntelligence/mapIntelligenceTypes'
import {
  DashboardBulkDataPayload,
  DashboardMetric,
  DashboardMetricType,
  DashboardReportParams,
  DashboardTopServices,
  DashboardTracker,
  DashboardTrackerPayload,
} from './dashboardTypes'

export interface DashboardState {
  metrics: Dictionary<DashboardMetric>
  topServices: DashboardTopServices
  trackers: Dictionary<DashboardTracker>
  isReportGenerating: boolean
}

const initialState: DashboardState = {
  metrics: null,
  topServices: null,
  trackers: null,
  isReportGenerating: false,
}

const getDashboardBulkData = createAction('dashboard/getDashboardBulkData')
const getDashboardMapData = createAction('dashboard/getDashboardMapData')
const getDashboardMapDataSuccess = createAction<DashboardRecentTripsData>(
  'dashboard/getDashboardMapDataSuccess'
)
const getDashboardTrackers = createAction('dashboard/getDashboardTrackers')
const getDashboardReport = createAction<DashboardReportParams>(
  'dashboard/getDashboardReport'
)

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getDashboardBulkDataSuccess(
      state,
      action: PayloadAction<DashboardBulkDataPayload>
    ) {
      const metrics = action.payload.metrics

      for (const metric in metrics) {
        metrics[metric].type = metric as DashboardMetricType
        if (metric === 'timeSaved') {
          metrics.timeSaved = {
            type: 'timeSaved',
            currentMonth: metrics.timeSaved.currentMonth / 3600,
            lastMonth: metrics.timeSaved.lastMonth / 3600,
            percentDiff: metrics.timeSaved.percentDiff,
          }
        }
      }

      state.topServices = action.payload.topServices
      state.metrics = metrics
    },
    getDashboardTrackersSuccess(
      state,
      action: PayloadAction<Dictionary<DashboardTrackerPayload>>
    ) {
      const trackers = action.payload

      for (const tracker in trackers) {
        trackers[tracker]['type'] = tracker
      }

      state.trackers = trackers as Dictionary<DashboardTracker>
    },
    getDashboardReport(state) {
      state.isReportGenerating = true
    },
    getDashboardReportSuccess(state) {
      state.isReportGenerating = false
    },
    getDashboardReportFailed(state) {
      state.isReportGenerating = false
    },
    getDashboardCsvReport(state, _action: any) {
      state.isReportGenerating = true
    },
    getDashboardCsvReportSuccess(state, action: any) {
      const reportNames = {
        otr: 'fleet_one_time_rewards',
        competitions: 'fleet_competition_winners',
        trips: 'fleet_trips',
        incentives: 'fleet_incentives_rewarded',
        transactions: 'fleet_transactions',
        taxSavings: 'fleet_tax_savings_eligible_transactions',
      }

      state.isReportGenerating = false
      fileDownload(action.payload, `${reportNames[action.meta.reportType]}.csv`)
    },
    getDashboardCsvReportFailed(state) {
      state.isReportGenerating = false
    },
  },
})

export const dashboardReducer = dashboardSlice.reducer
export const dashboardActions = {
  ...dashboardSlice.actions,
  getDashboardBulkData,
  getDashboardMapData,
  getDashboardMapDataSuccess,
  getDashboardTrackers,
  getDashboardReport,
}
