import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import Chevron from 'core-system/Icons/Actions/Chevron'
import useResizeListener from 'shared/Hooks/useResizeListener'
import pxToRem from 'core-system/utils/pxToRem'
import { space, layout, SpaceProps, LayoutProps } from 'styled-system'
import usePrevious from 'shared/Hooks/usePrevious'

const Container = styled.div<SpaceProps>`
  display: flex;
  position: relative;
  overflow: hidden;

  ${space}
  ${layout}
`

const Content = styled.div<{ leftOffset: number }>`
  display: flex;
  height: 100%;
  transform: translate(${(props) => props.leftOffset}px, 0);
  transition: 0.4s ease 0s;
`

const Arrow = styled.div<{
  isRight: boolean
  isVisible: boolean
  opacityWidth: string
}>`
  position: absolute;
  user-select: none;
  transition: all 0.1s ease-in-out;
  top: 0;
  width: ${(props) => props.opacityWidth};
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: ${(props) => (props.isVisible ? 'unset' : 'none')};

  ${(props) =>
    props.isRight
      ? css`
          right: 0;
          justify-content: flex-end;
          background-image: linear-gradient(
            to right,
            rgba(255, 0, 0, 0),
            rgba(255, 255, 255, 255)
          );

          svg {
            transform: rotate(270deg);
            cursor: pointer;
          }
        `
      : css`
          left: 0;
          justify-content: flex-start;
          background-image: linear-gradient(
            to left,
            rgba(255, 0, 0, 0),
            rgba(255, 255, 255, 255)
          );

          svg {
            transform: rotate(90deg);
            cursor: pointer;
          }
        `}
`

interface HorizontalScrollerProps
  extends SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  translationPerClick: number
  opacityWidth?: string
  resetOnChildrenUpdate?: boolean
}

const HorizontalScroller = React.memo((props: HorizontalScrollerProps) => {
  const {
    children,
    translationPerClick,
    opacityWidth = pxToRem(200),
    resetOnChildrenUpdate = false,
    ...rest
  } = props

  const [leftOffset, setLeftOffset] = useState(0)
  const [maxOffset, setMaxOffset] = useState(null)

  const servicesRef = useRef<HTMLDivElement>()
  const wrapperRef = useRef<HTMLDivElement>()
  const prevChildren = usePrevious(children)

  const calculateMaxOffset = useCallback(() => {
    if (!maxOffset && wrapperRef.current && servicesRef.current) {
      setMaxOffset(
        wrapperRef.current.clientWidth - servicesRef.current.scrollWidth
      )
    }
  }, [maxOffset])

  useEffect(() => {
    if (
      React.Children.toArray(children).length !==
        React.Children.toArray(prevChildren).length &&
      resetOnChildrenUpdate
    ) {
      setLeftOffset(0)
      setMaxOffset(
        wrapperRef.current.clientWidth - servicesRef.current.scrollWidth
      )
    }
  }, [children, prevChildren, resetOnChildrenUpdate])

  useResizeListener(() => calculateMaxOffset(), [])

  const handleArrowClick = useCallback(
    (direction: string) => {
      if (direction === 'right') {
        const maxOffset =
          wrapperRef.current.clientWidth - servicesRef.current.scrollWidth

        if (leftOffset - translationPerClick * 1.25 < maxOffset) {
          setLeftOffset(maxOffset)
        } else {
          setLeftOffset(leftOffset - translationPerClick)
        }
      } else {
        if (leftOffset >= -(translationPerClick * 1.25)) {
          setLeftOffset(0)
        } else {
          setLeftOffset(leftOffset + translationPerClick)
        }
      }
    },
    [leftOffset, translationPerClick]
  )

  useEffect(() => {
    calculateMaxOffset()
  }, [maxOffset, calculateMaxOffset])

  return (
    <Container ref={wrapperRef} {...rest}>
      <Arrow
        isVisible={leftOffset < 0}
        isRight={false}
        opacityWidth={opacityWidth}
      >
        <Chevron onClick={() => handleArrowClick('left')} />
      </Arrow>
      <Content ref={servicesRef} leftOffset={leftOffset}>
        {children}
      </Content>
      <Arrow
        isVisible={maxOffset && leftOffset > maxOffset}
        isRight={true}
        opacityWidth={opacityWidth}
      >
        <Chevron onClick={() => handleArrowClick('right')} />
      </Arrow>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  HorizontalScroller.displayName = 'HorizontalScroller'
}

export default HorizontalScroller
