import * as MicromobilityIcons from 'core-system/Icons/Micromobility'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import palette from 'core-system/Themes/palette'
import React from 'react'
import {
  MicromobilityActiveOptions,
  MicromobilityManageState,
} from 'redux/micromobility/micromobilityTypes'
import FormattingUtils from 'shared/FormattingUtils'

const recBudget = 7000
const avgMonthlyLeasing = 8500
const avgAnnualLeasing = 90000
const providerOptions = [
  'leasingMerchants',
  'sharedMerchants',
  'oneTimeMerchants',
]

const optionsCopy = {
  shared: {
    title: 'Shared Micromobility',
    stateKey: 'sharedModes',
    icon: (
      <MicromobilityIcons.SharedMicromobility color={palette.text.secondary} />
    ),
    description:
      'Allow commuters to use their budget to rent micromobility vehicles on a per-trip basis.',
  },
  leasing: {
    title: 'Leasing',
    stateKey: 'leasingModes',
    icon: <MicromobilityIcons.Leasing color={palette.text.secondary} />,
    description:
      'Allow commuters to pay a monthly membership with their budget to lease a micromobility vehicle.',
  },
  oneTime: {
    title: 'One-time Purchase',
    stateKey: 'oneTimeModes',
    icon: <MicromobilityIcons.OneTimePurchase color={palette.text.secondary} />,
    description:
      'Let commuters use their remaining annual budget towards purchasing a micromobility vehicle or an annual membership.',
  },
}

const defaultErrorState = {
  budget: false,
  shared: false,
  leasing: false,
  oneTime: false,
}

const monthsLeft = 6
// const monthsLeft = 13 - parseInt(moment().add(1, 'month').format('M'))

//this is used to see if the upcoming program has changes
const programHasChanges = (
  programState: MicromobilityManageState,
  activeOptions: Dictionary<MicromobilityActiveOptions>
) => {
  const currentBudget = FormattingUtils.formatDollar(
    programState.active.budget,
    0
  )
  const nextMonthBudget = FormattingUtils.formatDollar(
    programState.nextMonth.budget,
    0
  )

  const optionChanges = Object.keys(activeOptions.active).flatMap((option) => {
    if (activeOptions.active[option] !== activeOptions.nextMonth[option]) {
      return option
    } else {
      return []
    }
  })

  const providerChanges = ProgramUtils.getProviderDifference(
    programState,
    providerOptions
  )

  //only compare provider changes on active options
  const hasProviderChanges =
    Object.keys(providerChanges).length > 0 &&
    Object.keys(providerChanges).some(
      (option) => activeOptions.nextMonth[option]
    )

  return (
    currentBudget !== nextMonthBudget ||
    optionChanges.length > 0 ||
    hasProviderChanges
  )
}

export default {
  recBudget,
  avgMonthlyLeasing,
  avgAnnualLeasing,
  providerOptions,
  optionsCopy,
  defaultErrorState,
  monthsLeft,
  programHasChanges,
}
