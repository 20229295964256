import { StatusVariant } from 'core-system/Card/CardStatus'
import { ChipVariants } from 'core-system/Chip'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import React from 'react'

export const worksiteIcons = {
  1: <PlatformSvg folder='offices' variant='office2' width={40} height={40} />,
  2: <PlatformSvg folder='offices' variant='office1' width={40} height={40} />,
  3: <PlatformSvg folder='offices' variant='office3' width={40} height={40} />,
  4: <PlatformSvg folder='offices' variant='office4' width={40} height={40} />,
  5: <PlatformSvg folder='offices' variant='office5' width={40} height={40} />,
}

export const getRoleChipConfig = (
  isAdmin: boolean,
  isPrimaryAccountHolder: boolean
): { roleChipVariant: ChipVariants; roleChipLabel: string } => {
  if (isPrimaryAccountHolder) {
    return {
      roleChipVariant: 'blue',
      roleChipLabel: 'owner',
    }
  } else if (isAdmin) {
    return {
      roleChipVariant: 'green',
      roleChipLabel: 'admin',
    }
  } else {
    return {
      roleChipVariant: 'regular',
      roleChipLabel: 'regular',
    }
  }
}

export const getRoleCardConfig = (
  isAdmin: boolean,
  isPrimaryAccountHolder: boolean
): { roleCardStatusVariant: StatusVariant; roleCardLabel: string } => {
  if (isPrimaryAccountHolder) {
    return {
      roleCardStatusVariant: 'blue',
      roleCardLabel: 'owner',
    }
  } else if (isAdmin) {
    return {
      roleCardStatusVariant: 'green',
      roleCardLabel: 'admin',
    }
  } else {
    return {
      roleCardStatusVariant: 'regular',
      roleCardLabel: 'regular',
    }
  }
}
