import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d='M9.51914 2.15363L16 8H8.74419L3.96627 3.0093C3.60298 2.62983 3.87194 2 4.39728 2H9.11947C9.26712 2 9.40952 2.05474 9.51914 2.15363Z'
      fill='#9E9ECF'
    />
    <path
      d='M14.4809 2.15363L8 8H15.4286L20.0689 3.00269C20.4234 2.6209 20.1527 2 19.6317 2H14.8805C14.7329 2 14.5905 2.05474 14.4809 2.15363Z'
      fill='#9E9ECF'
    />
    <path
      d='M8 7H16V8C16 8.55228 15.5523 9 15 9H9C8.44772 9 8 8.55228 8 8V7Z'
      fill='#9E9ECF'
    />
    <circle cx='12' cy='15.0075' r='7.16013' fill='#FFE58B' />
    <circle opacity='0.3' cx='12' cy='15.0076' r='4.17674' fill='#7C6203' />
  </>,
  'MedalGold'
)
