import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.6614 17.3143H43.6052V29.1016H38.4858V17.3143H34.662V13.301H38.4858V12.3845C38.4858 8.49747 41.3615 6.66464 44.9008 6.66464C46.3229 6.66464 47.5554 6.98074 48.4403 7.26506L47.6501 10.9309C46.955 10.7728 46.2912 10.7096 45.8805 10.7096C44.6479 10.7096 43.6052 11.2152 43.6052 12.5742V13.301H48.6614V17.3143Z'
      fill='#6200E0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.6525 29.1017H55.7719V6.66473H50.6525V29.1017Z'
      fill='#6200E0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.5777 19.2104H72.0468C71.7625 17.8833 70.3403 16.9667 67.9071 16.9667C65.4107 16.9667 64.0201 17.9464 63.5777 19.2104ZM77.2296 23.761C76.6292 27.1424 73.3426 29.4177 68.1916 29.4177C62.2821 29.4177 58.3003 26.2576 58.3003 21.3278C58.3003 16.145 62.5032 12.985 67.9071 12.985C72.5525 12.985 77.0714 15.2603 77.1032 21.2012C77.1032 21.6755 77.0714 22.1493 77.0083 22.6866H63.4513C63.799 24.2667 65.4107 25.4359 68.1916 25.4359C70.4037 25.4359 71.7625 24.8355 72.205 23.761H77.2296Z'
      fill='#6200E0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M84.688 19.2104H93.1572C92.8729 17.8833 91.4507 16.9667 89.0173 16.9667C86.5208 16.9667 85.1305 17.9464 84.688 19.2104ZM98.3397 23.761C97.7395 27.1424 94.453 29.4177 89.302 29.4177C83.3922 29.4177 79.4106 26.2576 79.4106 21.3278C79.4106 16.145 83.6135 12.985 89.0173 12.985C93.6628 12.985 98.1818 15.2603 98.2134 21.2012C98.2134 21.6755 98.1818 22.1493 98.1186 22.6866H84.5614C84.9091 24.2667 86.5208 25.4359 89.302 25.4359C91.5139 25.4359 92.8728 24.8355 93.3153 23.761H98.3397Z'
      fill='#6200E0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M103.807 24.1719V17.3144H99.8573V13.3009H101.406C103.365 13.3009 104.187 12.5425 104.187 10.8044V8.84506H108.927V13.3009H113.888V17.3144H108.927V23.2554C108.927 24.1719 109.085 25.4043 111.329 25.4043C111.834 25.4043 112.529 25.3411 113.13 25.2148V29.0384C112.277 29.2597 110.981 29.4176 109.717 29.4176C105.229 29.4176 103.807 26.9528 103.807 24.1719Z'
      fill='#6200E0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.3656 0.214996L18.6842 0.226965L0 18.9112L5.86109 24.7725L24.4113 6.22246V2.12651L24.3656 0.214996Z'
      fill='#6200E0'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.2743 26.8035L24.4024 21.6755L24.3655 15.8684L18.7112 15.9074L7.85352 26.7651L18.8521 37.7634L24.4114 37.7609V31.9406L19.2743 26.8035Z'
      fill='#6200E0'
    />
  </>,
  'FleetLogo',
  '0 0 114 38'
)
