import { SelectItemProps } from 'core-system/Dropdown'
import { Segment, SegmentTypes } from 'redux/employer/employerTypes'

const segmentDropdownItems = (
  allSegments: Segment[],
  allItems?: any,
  typeFilter: SegmentTypes = null,
  excludeContractors?: boolean
): SelectItemProps[] => {
  return allSegments
    ? [...allSegments]
        .sort((a, b) => b.employees.length - a.employees.length)
        .filter((s) => !typeFilter || s.type === typeFilter)
        .map((segment) => {
          const displayCommuterCount =
            segment.employees.length -
            (excludeContractors ? segment.contractors.length : 0)
          return {
            id: segment.id,
            text: `${
              segment.name === 'All Employees' ? 'All Commuters' : segment.name
            } (${displayCommuterCount} ${
              excludeContractors ? 'Eligible ' : ''
            }Commuters)`,
            disabled: allItems && allItems[segment.id] ? true : false,
          }
        })
    : []
}

export default {
  segmentDropdownItems,
}
