import React from 'react'
import styled from 'styled-components'
import CaretIcon from 'core-system/Icons/Actions/Caret'
import Text from 'core-system/Text'
import NumberUtils from 'shared/NumberUtils'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Difference = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & { isPositive: boolean }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.3125rem 0 0.125rem;
  border-radius: 5rem;
  background-color: ${(props) =>
    props.isPositive
      ? props.theme.palette.chips.teal2
      : props.theme.palette.chips.magenta2};
  color: ${(props) =>
    props.isPositive
      ? props.theme.palette.chips.teal1
      : props.theme.palette.chips.magenta1};
  align-items: center;
  height: 1.3125rem;

  ${flexbox}
  ${space}
  ${layout}
`

const IconContainer = styled.div<{ isPositive: boolean }>`
  height: 1.5rem;
  transform: ${(props) => (props.isPositive ? 'rotate(180deg)' : 'unset')};
`

interface ChipDifferenceProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  percentDifference: number
  reverse?: boolean
}

const ChipDifference = React.memo((props: ChipDifferenceProps) => {
  const { percentDifference, reverse = false, ...rest } = props
  const mathSign = Math.sign(percentDifference)
  return (
    <>
      {Math.round(percentDifference) !== 0 ? (
        <Difference isPositive={mathSign === 1 || reverse} {...rest}>
          <IconContainer isPositive={mathSign === 1}>
            <CaretIcon width='24' height='24' />
          </IconContainer>
          <Text variant='body2' marginTop='0.125rem'>
            {NumberUtils.formatNumber(percentDifference, 0)}%
          </Text>
        </Difference>
      ) : null}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ChipDifference.displayName = 'ChipDifference'
}

export default ChipDifference
