import { AxiosResponse } from 'axios'
import { SegmentParams } from 'redux/employer/employerTypes'
import {
  MicromobilityIsochroneParams,
  MicromobilityProgram,
  MicromobilityProgramUpdate,
} from 'redux/micromobility/micromobilityTypes'
import {
  SegmentAndType,
  SegmentAndTypeAndDate,
} from 'redux/programs/programsTypes'
import Request from '../config/services/Request'

const getMicromobilityRecommendations = (
  payload: SegmentParams
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/programs/merchants/?segment=${payload.segmentId}&mode_types=MICROMOBILITY`
  )
}

const createMicromobilityProgram = (
  payload: MicromobilityProgram
): Promise<AxiosResponse> => {
  return Request.post('/hr/programs/card_programs/', payload)
}

const updateMicromobilityProgram = (
  payload: MicromobilityProgramUpdate
): Promise<AxiosResponse> => {
  const { programId, ...rest } = payload
  return Request.put(`/hr/programs/card_programs/${programId}/`, rest)
}

const cancelMicromobilityProgram = (
  programId: string
): Promise<AxiosResponse> => {
  return Request.del(`/hr/programs/card_programs/${programId}/`)
}

const getMicromobilityIsochrones = (
  payload: MicromobilityIsochroneParams
): Promise<AxiosResponse> => {
  return Request.post('/hr/maps/intelligence/worksite_isochrones/', {
    values_seconds: payload.valuesSeconds,
    segment: payload.segment,
    mode_types: ['BIKE'],
  })
}

const getMicromobilityDashboardStats = (
  segmentAndType: SegmentAndType
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/programs/card_programs/dashboard/?segment=${segmentAndType.segmentId}&type=${segmentAndType.type}`
  )
}

const getMicromobilityDashboardServices = (
  segmentAndTypeAndDate: SegmentAndTypeAndDate
): Promise<AxiosResponse> => {
  return Request.post('/hr/programs/card_programs/services/', {
    rangeEnd: segmentAndTypeAndDate.rangeEnd,
    rangeStart: segmentAndTypeAndDate.rangeStart,
    segment: segmentAndTypeAndDate.segmentId,
    type: segmentAndTypeAndDate.type,
  })
}

export default {
  getMicromobilityRecommendations,
  createMicromobilityProgram,
  updateMicromobilityProgram,
  cancelMicromobilityProgram,
  getMicromobilityIsochrones,
  getMicromobilityDashboardStats,
  getMicromobilityDashboardServices,
}
