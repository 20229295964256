const statusOrder = {
  UPDATING: 1,
  ACTIVE: 2,
  PENDING: 3,
  CANCELLING: 4,
  CANCELLED: 5,
  OFF: 6,
}

export default {
  statusOrder,
}
