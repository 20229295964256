import Button from 'core-system/Button'
import React from 'react'
import InviteUsersModal from './InviteUsersModal'
import SegmentService from 'redux/config/services/SegmentService'

interface InviteUsersProps {
  segmentId: string
  btnSize?: 'small' | 'medium' | 'large'
  active?: boolean
  width?: string
  margin?: string
}

const InviteUsers = (props: InviteUsersProps) => {
  const { segmentId, btnSize, active, width, margin } = props
  const [showInviteUsersModal, setShowInviteUsersModal] = React.useState(false)

  const toggleInviteUsersModal = React.useCallback(() => {
    setShowInviteUsersModal(!showInviteUsersModal)
    SegmentService.track('inviteUsers-modal-click', {
      action: showInviteUsersModal ? 'close' : 'open',
    })
  }, [showInviteUsersModal])

  return (
    <>
      <Button
        size={btnSize || 'small'}
        variant='primary'
        style={{ textTransform: 'capitalize' }}
        active={active}
        onClick={toggleInviteUsersModal}
        width={width || null}
        margin={margin || null}
        minWidth='8rem'
      >
        Invite Commuters
      </Button>
      <InviteUsersModal
        segmentId={segmentId}
        closeModal={toggleInviteUsersModal}
        open={showInviteUsersModal}
      />
    </>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  InviteUsers.displayName = 'InviteUsers'
}

export default InviteUsers
