import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d='M12.25 1L4.51562 8.5L1 5.09091'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>,
  'Checkmark',
  '0 0 14 10',
  10,
  14
)
