import moment from 'moment'
import { Announcement } from 'redux/concierge/conciergeTypes'

// reminders
// employee_tax_reminder: QTF Reminder
// replace deadline date and days left 5,0
// employee_tax_changes: QTF New/Update
// active buckets, and deadline date

// Static templates
// employee_tax_launched: Launched QTF Program
// employee_tax_info: Your QTF benefits
// employee_tax_welcome: QTF welcome email

export const staticEmailTemplates = {
  employee_tax_reminder: {
    title:
      'reminder_value: allocation_deadline pre-tax commute contribution deadline',
    body: `Your {deadline_date} pre-tax commute savings allocation deadline is {days_left}.\nTo start saving, set your allocation using the Fleet mobile app...`,
  },
  employee_tax_changes: {
    title: 'Check out your Commuter Pre-Tax Savings Program!',
    body: `Your admin has added {active_buckets} to your pre-tax commuter savings program. You can allocate pre-tax funds any time before {deadline_date}. Any funds you allocate will be available on {spend_date}.\n\nYou can allocate funds or learn more about the program in the Fleet mobile app.\n\n...`,
  },
  employee_tax_launched: {
    title: 'Your new pre-tax commuter benefits',
    body: `Great news! You now have access to pre-tax commuter benefits through Fleet. With pre-tax benefits, you save on commuting costs, pay less income tax, and increase your take-home pay. Commuters save 35% on commute costs when they take advantage of pre-tax spending through Fleet. That's $1,500 - $2,000 a year!\n\nEvery month you'll have the option to set aside funds before taxes via our super intuitive, user-friendly mobile app. These will be added to your Flex Mobility Card (also in the mobile app), which you can then use to pay for your commuting expenses. It's that simple - no expense reports, reimbursements processes, or headaches. ...`,
  },
  employee_tax_info: {
    title: 'Your new pre-tax commuter benefits',
    body: `Thanks for signing up for Fleet. We cant wait to help you save on commuting!\n\nIf you're wondering which commuting costs are eligible, we've got you covered. The IRS allows pre-tax dollars to be spent on any of the following ...`,
  },
  employee_tax_welcome: {
    title: 'Save with pre-tax commuter benefits through Fleet',
    body: `Hello,\nYou now have access to Pre-tax Commuter Benefits through Fleet!\n\nYou can now pay for commuting expenses with pre-tax dollars. This means you'll reduce your cost of commuting, pay less in income taxes, and increase your take-home pay. All good things.\n\n\nCommuters save 35% on commute costs when they take advantage of pre-tax spending through Fleet. That's $1,500 - $2,000 a year! ...`,
  },
}

const getAnnouncementTime = (announcement: Announcement) => {
  // qtf reminder, custom
  if (announcement.sentAt) return moment(announcement.sentAt)
  if (announcement.scheduledFor) return moment(announcement.scheduledFor)
  return moment(announcement.scheduledAt || announcement.createdAt)
}

export const htmlToString = (html: string) =>
  html.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, ' ')

export const getAnnouncementCopy = (announcement: Announcement) => {
  if (announcement.name === 'employee_tax_reminder') {
    const schedulingDate = announcement.sentAt
      ? moment(announcement.sentAt, 'YYYY-MM-DD hh:mm:ss')
      : moment(announcement.scheduledFor, 'YYYY-MM-DD hh:mm:ss')

    const deadlineDate = moment(
      `${announcement.extraData?.reminderDay}-${
        schedulingDate.month() + 1
      }-${schedulingDate.year()}T07:00:00Z`,
      'DD-MM-YYYY hh:mm:ss'
    )

    const isFinalDeadline =
      schedulingDate.date() === announcement.extraData?.reminderDay

    const title = `${
      isFinalDeadline ? 'Final Reminder' : 'Reminder'
    }: ${deadlineDate.format(
      'MMMM Do, YYYY'
    )} pre-tax commute contribution deadline`

    const body = `Your ${deadlineDate.format(
      'MMMM Do, YYYY'
    )} pre-tax commute savings allocation deadline is ${
      isFinalDeadline ? 'today' : `in 5 days`
    }. To start saving, set your allocation using the Fleet mobile app.\n\nCommuters save 35% on commute costs when they take advantage of pre-tax spending through Fleet. That's $1,500 - $2,000 a year! ...`

    return {
      announcementTitle: title,
      announcementBody: body,
      announcementTime: schedulingDate,
    }
  }

  if (announcement.name === 'employee_tax_changes') {
    const selectedMonth = announcement.sentAt
      ? moment(announcement.sentAt)
      : moment(announcement.scheduledFor)
    const deadlineDate = moment(
      `${announcement.extraData?.reminderDay}-${
        selectedMonth.month() + 1
      }-${selectedMonth.year()}T07:00:00Z`,
      'YYYY-MM-DD hh:mm:ss'
    )
    const transitActive =
      announcement.extraData?.extraTemplateInfo?.newTransit || false
    const parkingActive =
      announcement.extraData?.extraTemplateInfo?.newParking || false
    const spendDate =
      announcement.extraData?.extraTemplateInfo?.spendDate || null

    const body = `Your admin has added ${transitActive && 'transit'}${
      transitActive && parkingActive ? ' and ' : ''
    }${
      parkingActive && 'parking'
    } to your pre-tax commuter savings program. You can allocate pre-tax funds any time before ${deadlineDate.format(
      'MMMM Do, YYYY'
    )}. Any funds you allocate will be available on ${
      spendDate && spendDate
    }.\n\nYou can allocate funds or learn more about the program in the Fleet mobile app ...`

    return {
      announcementTitle: staticEmailTemplates.employee_tax_changes.title,
      announcementBody: body,
      announcementTime: selectedMonth,
    }
  }

  const isStaticComm = announcement?.productType === 'QTF'
  const template = staticEmailTemplates[announcement.name]
  const title = isStaticComm ? template.title : announcement.title

  const body = isStaticComm
    ? template.body
    : htmlToString(announcement.htmlBody)

  return {
    announcementTitle: title,
    announcementBody: body,
    announcementTime: getAnnouncementTime(announcement),
  }
}

export const generateTaxReminderReducerAnnouncements = (
  rawAnnouncement: any,
  date: any,
  isFuture: boolean
) => {
  const scheduledFor = moment(date, 'YYYY-MM-DD hh:mm:ss')

  const finalDeadline = moment(
    `${rawAnnouncement?.reminderDay}-${
      scheduledFor.month() + 1
    }-${scheduledFor.year()}}T07:00:00Z`,
    'DD-MM-YYYY hh:mm:ss'
  )
  const firstDeadline = finalDeadline.clone().subtract(5, 'days')

  return [0, 1].map((idx: number) => {
    return {
      automated: true,
      id: `${rawAnnouncement.id}-${finalDeadline.format('M')}-${idx}`,
      name: rawAnnouncement.name,
      segment: rawAnnouncement.segmentId,
      createdAt: rawAnnouncement.createdAt,
      status: isFuture ? rawAnnouncement.status : 'Sent',
      title: rawAnnouncement.title,
      htmlBody: '',
      distributionPlatforms: rawAnnouncement.platforms,
      body: rawAnnouncement.body,
      scheduledFor:
        idx === 0
          ? firstDeadline.format('YYYY-MM-DDThh:mm:ss')
          : finalDeadline.format('YYYY-MM-DDThh:mm:ss'),
      sentAt: isFuture
        ? null
        : idx === 0
        ? firstDeadline.format('YYYY-MM-DDThh:mm:ss')
        : finalDeadline.format('YYYY-MM-DDThh:mm:ss'),
      userType: rawAnnouncement.userType,
      productType: rawAnnouncement.productType,
      extraData: {
        reminderDay: rawAnnouncement?.reminderDay || null,
        extraTemplateInfo: rawAnnouncement?.extraTemplateData || null,
      },
    } as Announcement
  })
}
