import React, { useState } from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import { space, SpaceProps } from 'styled-system'
import { Alert, Info, Success } from 'core-system/Icons/Misc'
import { Close } from 'core-system/Icons/Actions'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'

type AlertBannerVariant = 'success' | 'yellowAlert' | 'redAlert' | 'info'

const getBannerContent = (variant: AlertBannerVariant, isMobile: boolean) => {
  const iconSizeProps = {
    width: isMobile ? '3.5rem' : '1.5rem',
    height: isMobile ? '3.5rem' : '1.5rem',
  }
  switch (variant) {
    case 'success': {
      return {
        textColor: palette.secondary.green1,
        icon: <Success {...iconSizeProps} color={palette.secondary.green1} />,
      }
    }
    case 'yellowAlert': {
      return {
        textColor: palette.chips.yellow1,
        icon: <Alert {...iconSizeProps} color={palette.chips.yellow1} />,
      }
    }
    case 'redAlert': {
      return {
        textColor: palette.secondary.red1,
        icon: <Alert {...iconSizeProps} color={palette.secondary.red1} />,
      }
    }
    case 'info': {
      return {
        textColor: palette.grey.grey10,
        icon: <Info {...iconSizeProps} color={palette.grey.grey10} />,
      }
    }
  }
}

const Container = styled.div<
  { variant: AlertBannerVariant; isMobile: boolean } & SpaceProps
>`
  width: 100%;
  background-color: ${(props) => {
    switch (props.variant) {
      case 'success': {
        return props.theme.palette.secondary.green3
      }
      case 'yellowAlert': {
        return props.theme.palette.chips.yellow2
      }
      case 'redAlert': {
        return props.theme.palette.secondary.red2
      }
      case 'info': {
        return props.theme.palette.grey.grey3
      }
    }
  }};
  border-radius: 0.9375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  padding: ${(props) => (props.isMobile ? '0.75rem' : '1.25rem')};

  ${space}
`

const BannerIconMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`

const CloseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

interface AlertBannerProps extends SpaceProps {
  variant: AlertBannerVariant
  message: string
  show?: boolean
  closeFn?: () => void
}

const AlertBanner = React.memo((props: AlertBannerProps) => {
  const { variant, message, closeFn } = props

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  useResizeListener(
    () => setIsMobile(window.innerWidth <= screenSizes.mobile),
    []
  )

  const bannerContent = getBannerContent(variant, isMobile)

  return (
    <Container variant={variant} isMobile={isMobile} {...props}>
      <BannerIconMessageContainer>
        {bannerContent['icon']}
        <Text
          variant={isMobile ? 'action4' : 'action2'}
          textColor={bannerContent['textColor']}
        >
          {message}
        </Text>
      </BannerIconMessageContainer>
      {closeFn && (
        <CloseButtonContainer>
          <Close
            width='1.5rem'
            height='1.5rem'
            color={bannerContent['textColor']}
            onClick={closeFn}
          />
        </CloseButtonContainer>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AlertBanner.displayName = 'AlertBanner'
}
export default AlertBanner
