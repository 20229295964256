import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 3C4.27614 3 4.5 3.22386 4.5 3.5V10H10.7929L8.46447 7.67157C8.2692 7.47631 8.2692 7.15973 8.46447 6.96447C8.65973 6.7692 8.97631 6.7692 9.17157 6.96447L12.3536 10.1464C12.5488 10.3417 12.5488 10.6583 12.3536 10.8536L9.17157 14.0355C8.97631 14.2308 8.65973 14.2308 8.46447 14.0355C8.2692 13.8403 8.2692 13.5237 8.46447 13.3284L10.7929 11H4C3.72386 11 3.5 10.7761 3.5 10.5V3.5C3.5 3.22386 3.72386 3 4 3Z'
    />
  </>,
  'EnterArrow',
  '0 0 16 17'
)
