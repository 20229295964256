import { ColumnCard } from 'core-system/ColumnView'
import SummaryOverview from 'core-system/Program/Summary/SummaryOverview'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { Segment } from 'redux/employer/employerTypes'
import { OtpActiveOptions } from 'redux/otp/otpTypes'
import styled from 'styled-components'
import OTPSummaryOptions from './OTPSummaryOptions'
import OTPSummaryBudget from './OTPSummaryBudget'

const Container = styled(ColumnCard)`
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
`
interface OTPActivationSummaryProps {
  startDate: string
  currentSegment: Segment
  transactionLimit: number
  activeOptions: OtpActiveOptions
  activeMerchantIds: string[]
  availableMerchantIds: string[]
  handleOpenModal: () => void
}

const OTPActivationSummary = React.memo((props: OTPActivationSummaryProps) => {
  const {
    startDate,
    currentSegment,
    transactionLimit,
    activeOptions,
    activeMerchantIds,
    availableMerchantIds,
    handleOpenModal,
  } = props
  const numEmps = currentSegment.commuters

  return (
    <Container>
      <Text variant='h4' marginBottom='1.5rem'>
        My GRH Program
      </Text>
      <SummaryOverview
        startDate={startDate}
        currentSegment={currentSegment}
        marginBottom='1rem'
      />
      <OTPSummaryOptions
        activeOptions={activeOptions}
        activeMerchantIds={activeMerchantIds}
        availableMerchantIds={availableMerchantIds}
        handleOpenModal={handleOpenModal}
        canEdit
      />
      <OTPSummaryBudget
        totalEmployees={numEmps}
        transactionLimit={transactionLimit}
      />

      <Text variant='body2' textColor={palette.text.placeholder}>
        All amounts are estimated based on max commuter spend.
      </Text>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OTPActivationSummary.displayName = 'OTPActivationSummary'
}

export default OTPActivationSummary
