import { FeatureCollection, Position } from 'geojson'
import turfBbox from '@turf/bbox'
import Pin from 'core-system/Icons/Misc/Location'
import styled from 'styled-components'

export type MetroArea =
  | 'sanFrancisco'
  | 'USSanFranciscoBayArea'
  | 'newYork'
  | 'USNewYorkMetropolitanArea'
  | 'philadelphia'
  | 'USPhiladelphiaMetropolitanArea'

const metroAreas = {
  sanFrancisco: [-123.338013, 36.213255, -120.536499, 38.826871],
  USSanFranciscoBayArea: [-123.338013, 36.213255, -120.536499, 38.826871],
  newYork: [-74.333496, 40.58945, -73.565826, 40.932709],
  USNewYorkMetropolitanArea: [-74.333496, 40.58945, -73.565826, 40.932709],
  CAGreaterTorontoArea: [-80.757751, 43.131057, -78.197937, 44.150681],
  USAustinMetropolitanArea: [-98.401794, 29.893043, -97.102661, 30.817346],
  USSeattleMetropolitanArea: [-123.338013, 46.796299, -121.327515, 48.367198],
  USLosAngelesMetropolitanArea: [
    -119.080811, 33.413102, -116.949463, 34.583475,
  ],
  USDCMetropolitanArea: [-77.341003, 38.658705, -76.681824, 39.124734],
  USChicagoMetropolitanArea: [-88.428955, 41.393294, -87.09137, 42.362603],
  USBostonMetropolitanArea: [-71.5979, 41.983994, -70.458069, 42.724821],
  CAEdmontonMetropolitanArea: [-113.858185, 53.357928, -113.135834, 53.691828],
  USPhiladelphiaMetropolitanArea: [
    -75.567447, 39.508616, -75.560317, 39.520534,
  ],
  philadelphia: [-75.567447, 39.508616, -75.560317, 39.520534],
}

interface GetBboxProps {
  featureCollection?: FeatureCollection
  metroArea?: MetroArea
}

const getBbox = (params: GetBboxProps) => {
  if (params.featureCollection) {
    return turfBbox(params.featureCollection)
  } else if (params.metroArea) {
    return metroAreas[params.metroArea]
  }
  //North America
  return [-125.727539, 26.509905, -62.402344, 49.037868]
}

const makeDefaultFeatureCollection = (coords: number[]): FeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [coords[0], coords[1]],
              [coords[2], coords[3]],
            ],
          ] as Position[][],
        },
      },
    ],
  }
}

const defaultViewport = {
  latitude: 37.7577,
  longitude: -122.4376,
  zoom: 10,
  width: 100,
  height: 100,
  maxZoom: 20,
  minZoom: 0,
  maxPitch: 60,
  minPitch: 0,
}

const WorksiteSvg = styled(Pin)`
  cursor: pointer;
  stroke: none;
  transform: translate(0px, -2px);
  color: ${(props) => props.theme.palette.primary.primaryDark};
`

export default {
  defaultViewport,
  getBbox,
  makeDefaultFeatureCollection,
  WorksiteSvg,
}
