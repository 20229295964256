import React from 'react'
import styled from 'styled-components'
import EditIcon from 'core-system/Icons/Actions/Edit'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const HeaderContent = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
    padding-bottom: 1rem;
  }
`

const EditButton = styled.div`
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-left: auto;
  background-color: ${(props) => props.theme.palette.secondary.purple5};
  color: ${(props) => props.theme.palette.text.secondary};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: none;
  }
`

const Container = styled.div<FlexboxProps & SpaceProps & LayoutProps>`
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  overflow: hidden;
  padding: ${(props) =>
    `${props.theme.pxToRem(47)} 0.75rem ${props.theme.pxToRem(
      47
    )} ${props.theme.pxToRem(178)}`};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-radius: 0.9375rem;
  background-color: ${(props) => props.theme.palette.white};

  &:hover {
    ${EditButton} {
      opacity: 1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    padding: ${(props) =>
      `${props.theme.pxToRem(47)} 0.75rem ${props.theme.pxToRem(
        47
      )} ${props.theme.pxToRem(135)}`};
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: ${(props) =>
      `${props.theme.pxToRem(47)} 0.75rem ${props.theme.pxToRem(
        47
      )} ${props.theme.pxToRem(178)}`};
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: ${(props) => `2rem 1.5rem 2rem ${props.theme.pxToRem(195)}`};

    &:hover {
      background-color: ${(props) => props.theme.palette.secondary.purple5};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    padding: 1rem;
    align-items: center;
    justify-content: center;

    &:hover {
      ${HeaderContent} {
        border-bottom: 1px solid
          ${(props) => props.theme.palette.primary.primaryDark};
      }
    }
  }

  ${flexbox}
  ${space}
  ${layout}
`

const InnerContainer = styled.div`
  display: flex;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
  }
`

const MobileIcon = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    display: flex;
    margin-right: 1rem;
    height: 2.5rem;
  }
`

const HeaderIcon = styled.div`
  position: absolute;
  bottom: -1rem;
  left: 0;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    left: -2.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    bottom: -0.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    display: none;
  }
`

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 3rem;
  border-right: 1px solid ${(props) => props.theme.palette.grey.grey4};
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    padding-right: 1.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    border-right: none;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 3rem;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    margin: 0 0 0 1.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin: 0.75rem 0 0 0;
  }
`

const Title = styled.div`
  ${(props) => props.theme.typography.h4};
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 0.5rem;
`

const Subtitle = styled.div`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.primary.primaryPurple};
`

interface AccountsHeaderProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  src: string
  srcMobile: string
  title: string
  subtitle: string
}

const AccountsHeader = React.memo((props: AccountsHeaderProps) => {
  const { src, srcMobile, title, subtitle, children } = props

  return (
    <Container {...props}>
      <InnerContainer>
        <HeaderIcon>
          <img alt={'headerIcon'} src={src} />
        </HeaderIcon>
        <HeaderContent>
          <MobileIcon>
            <img alt={'headerIconMobile'} src={srcMobile} />{' '}
          </MobileIcon>
          <HeaderText>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </HeaderText>
        </HeaderContent>
        <ContentContainer>{children}</ContentContainer>
      </InnerContainer>
      <EditButton>
        <EditIcon />
      </EditButton>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AccountsHeader.displayName = 'AccountsHeader'
}

export default AccountsHeader
