import React from 'react'
import Modal from './Modal'
import ModalBody from './ModalBody'
import styled from 'styled-components'
import { Alert, Success } from 'core-system/Icons/Misc'
import palette from 'core-system/Themes/palette'
import Text from 'core-system/Text/Text'
import Button from 'core-system/Button'

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 1rem;
`

interface AlertSuccessModalProps {
  open: boolean
  onClose: (e: React.MouseEvent<any>) => void
  type: 'alert' | 'success'
  headerText: string
  message?: string
}

// TODO: Add option for multiple buttons (e.g. 'Sync Another in Finch Worksite Select Modal')
const AlertSuccessModal = React.memo((props: AlertSuccessModalProps) => {
  const { open, onClose, type, headerText, message } = props

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody paddingBottom='1.5rem' paddingTop='1.5rem'>
        <ModalHeader>
          {type === 'alert' ? (
            <Alert
              color={palette.secondary.red1}
              height={50}
              width={50}
              style={{ marginBottom: '0.5rem' }}
            />
          ) : (
            <Success
              color={palette.secondary.green2}
              height={50}
              width={50}
              style={{ marginBottom: '0.5rem' }}
            />
          )}
          <Text variant='h2'>{headerText}</Text>
        </ModalHeader>
        {message && message !== '' ? (
          <Text variant='action3'>{message}</Text>
        ) : null}
        <Button onClick={onClose} width='100%' marginTop='2rem'>
          OK
        </Button>
      </ModalBody>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AlertSuccessModal.displayName = 'AlertSuccessModal'
}

export default AlertSuccessModal
