import Loading from 'core-system/Loading'
import { ProgramMissingPaymentMethod } from 'core-system/Program'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getClientEnvironment } from 'redux/config/services/Constants'
import { AppState } from 'redux/config/store'
import { dashboardActions } from 'redux/dashboard/dashboardSlice'
import {
  DashboardMetric,
  DashboardMetricType,
} from 'redux/dashboard/dashboardTypes'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import DashboardConcierge from './components/DashboardConcierge'
import DashboardEmb from './components/DashboardEmb'
import DashboardEngagement from './components/DashboardEngagement'
import DashboardIncentives from './components/DashboardIncentives'
import DashboardLeaderboards from './components/DashboardLeaderboards'
import DashboardMetrics from './components/DashboardMetrics'
import DashboardPrograms from './components/DashboardPrograms'
import DashboardReporting from './components/DashboardReporting'
import DashboardTrips from './components/DashboardTrips/DashboardTrips'
import DashboardOnboarding from './Onboarding/DashboardOnboarding'

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.theme.pxToRem(541)}, 1fr)
  );
  gap: 1rem;
  grid-auto-flow: row;
  margin-bottom: 1.5rem;
`

const StatsGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(
    2,
    minmax(${(props) => props.theme.pxToRem(263)}, 1fr)
  );
  column-gap: 1rem;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 0.6fr) minmax(0, 0.4fr);
  row-gap: 1.5rem;
  column-gap: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-template-columns: 100%;
  }
`

const getProductiveGains = (timeSaved: DashboardMetric) => {
  return {
    type: 'productiveGains' as DashboardMetricType,
    lastMonth: timeSaved.lastMonth * 22.9885,
    currentMonth: timeSaved.currentMonth * 22.9885,
    percentDiff: timeSaved.percentDiff,
  }
}

const DashboardView = React.memo(() => {
  const dispatch = useDispatch()

  const { totalEmployees } = useSelector((state: AppState) => state.employer)
  const { segmentsMap, hasValidPaymentMethod, hasValidDistributionType } =
    useSelector((state: AppState) => state.employer)
  const { allIncentives } = useSelector((state: AppState) => state.incentives)
  const { programs } = useSelector((state: AppState) => state.programs)
  const { qtfPrograms } = useSelector((state: AppState) => state.qtf)

  const { metrics, topServices, trackers } = useSelector(
    (state: AppState) => state.dashboard
  )

  const profile = useSelector((state: AppState) => state.employer.profile)

  const isReportGenerating = useSelector(
    (state: AppState) => state.dashboard.isReportGenerating
  )

  const products = new Set(profile?.products)

  const hasCardPrograms =
    products.has('FLEX') ||
    products.has('MICROMOBILITY') ||
    products.has('GRH') ||
    products.has('OTP')

  useEffect(() => {
    dispatch(dashboardActions.getDashboardTrackers())
    dispatch(dashboardActions.getDashboardBulkData())
  }, [dispatch])

  if (
    !segmentsMap ||
    !topServices ||
    !programs ||
    !trackers ||
    !metrics ||
    !allIncentives ||
    !profile
  ) {
    return <Loading fullPage={true} />
  }

  const isValid = hasValidDistributionType && hasValidPaymentMethod
  const activatedStatuses = ['ACTIVE', 'UPDATING']
  const activated = programs.filter((program) =>
    activatedStatuses.includes(program.status)
  )
  const sortedQtfPrograms = qtfPrograms
    ? Object.values(qtfPrograms).reduce(
        (sortedPrograms, segment) => {
          if (segment.active) {
            sortedPrograms.active.push(segment.active)
          }
          if (segment.nextMonth) {
            sortedPrograms.nextMonth.push(segment.nextMonth)
          }
          return sortedPrograms
        },
        { active: [], nextMonth: [] }
      )
    : {}

  return (
    <>
      {(activated.length === 0 && sortedQtfPrograms.active.length === 0) ||
      (!isValid && getClientEnvironment() !== 'development') ? (
        <>
          {!isValid &&
            (programs.length > 0 || sortedQtfPrograms.active.length > 0) && (
              <ProgramMissingPaymentMethod from={Locations.Dashboard} />
            )}
          {activated.length === 0 && sortedQtfPrograms.active.length === 0 ? (
            <DashboardOnboarding
              programs={programs}
              nextMonthQtfPrograms={sortedQtfPrograms.nextMonth}
            />
          ) : null}
        </>
      ) : (
        <>
          <StatsContainer>
            <StatsGroup>
              <DashboardMetrics
                title='Spend Impact'
                metrics={[
                  metrics.discountSavings,
                  getProductiveGains(metrics.timeSaved),
                ]}
                totalEmployees={totalEmployees}
              />
              <DashboardMetrics
                title='Environmental Impact'
                metrics={[metrics.carbonFootprint, metrics.offsetContribution]}
                totalEmployees={totalEmployees}
              />
            </StatsGroup>
            <DashboardMetrics
              title='Commuter Wellness'
              isDouble={true}
              totalEmployees={totalEmployees}
              metrics={[
                metrics.activeTrips,
                metrics.commuterAgony,
                metrics.timeSaved,
              ]}
            />
          </StatsContainer>
          <Content>
            <div>
              {hasCardPrograms && (
                <DashboardPrograms segmentsMap={segmentsMap} />
              )}
              {products.has('COMPETITIONS') && <DashboardLeaderboards />}
              <DashboardTrips />
            </div>
            <div>
              <DashboardEmb
                services={topServices}
                activatedPrograms={activated}
                employerProfile={profile}
              />
              <DashboardConcierge />
              {(products.has('INCENTIVES') || hasCardPrograms) && (
                <DashboardEngagement totalEmployees={totalEmployees} />
              )}
              <DashboardReporting isReportGenerating={isReportGenerating} />
              {products.has('INCENTIVES') && (
                <DashboardIncentives
                  allIncentives={allIncentives}
                  segmentsMap={segmentsMap}
                />
              )}
            </div>
          </Content>
        </>
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardView.displayName = 'DashboardView'
}
export default DashboardView
