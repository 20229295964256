import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import TextField from 'core-system/TextField'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'

interface SegmentEditModalProps {
  closeModal: (status?: number) => void
  open: boolean
  segment?: Segment
}

const SegmentEditModal = React.memo((props: SegmentEditModalProps) => {
  const { open, closeModal, segment } = props

  const dispatch = useDispatch()
  const { allSegments } = useSelector((state: AppState) => state.employer)

  const [segmentName, setSegmentName] = useState<string>(segment?.name)
  const [hasError, setHasError] = useState(false)

  const handleEditSegment = (newSegmentName: string) => {
    const hasError = allSegments.some(
      (segment) => segment.name === newSegmentName
    )

    if (hasError) {
      setHasError(true)
    } else {
      dispatch(
        employerActions.updateSegment({
          segmentId: segment.id,
          name: newSegmentName,
          shippingDetails: segment.shippingDetails || null,
        })
      )
      closeModal()
    }
  }

  useEffect(() => {
    setSegmentName(segment?.name)
    setHasError(false)
  }, [segment])

  return (
    <Modal open={open} onClose={() => closeModal()} width={pxToRem(750)}>
      <ModalHeader title='Edit Group Name' />
      <ModalBody paddingBottom='2rem'>
        <TextField
          label='Segment Name'
          placeholder={segmentName}
          name='segmentName'
          type='text'
          onChange={(e) => {
            setSegmentName(e.currentTarget.value)
            setHasError(false)
          }}
          value={segmentName}
          invalid={hasError}
          helpText='Please enter a valid group name'
          required
        />
      </ModalBody>
      <ModalFooter>
        <Button
          variant='tertiary'
          marginRight='1rem'
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleEditSegment(segmentName)}
          disabled={segmentName === segment?.name}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SegmentEditModal.displayName = 'SegmentEditModal'
}

export default SegmentEditModal
