import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment, { Moment } from 'moment'
import React from 'react'
import { TripStats } from 'redux/incentives/incentivesTypes'
import styled, { css } from 'styled-components'

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const Container = styled.div``

const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 4rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey3};
  border-left: 1px solid ${(props) => props.theme.palette.grey.grey3};
`

const HeaderDay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  border-right: 1px solid ${(props) => props.theme.palette.grey.grey3};
  overflow: hidden;

  &:first-of-type {
    border-top-left-radius: 0.9375rem;
  }

  &:last-of-type {
    border-top-right-radius: 0.9375rem;
  }
`

const Calendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: ${(props) => props.theme.pxToRem(119)};
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey3};
  border-left: 1px solid ${(props) => props.theme.palette.grey.grey3};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-bottom-right-radius: 0.9375rem;
  border-bottom-left-radius: 0.9375rem;
`

const Day = styled.div<{
  isDisabled: boolean
  isToday: boolean
  isActive: boolean
  isFuture: boolean
}>`
  padding: 0.375rem 0.5rem;
  border-right: 1px solid ${(props) => props.theme.palette.grey.grey3};
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
  background-color: ${(props) => props.theme.palette.white};
  cursor: pointer;

  &:hover {
    .dayContent {
      border: 1px solid ${(props) => props.theme.palette.secondary.purple3};
      background-color: ${(props) => props.theme.palette.secondary.purple6};
    }
  }

  &:nth-of-type(7n),
  &:nth-of-type(7n + 1) {
    background-color: ${(props) => props.theme.palette.grey.grey1};
    /* pointer-events: none;

    .dayText {
      color: ${(props) => props.theme.palette.text.disabled};
    }

    .trips {
      visibility: hidden;
    } */
  }

  &:last-of-type {
    border-bottom-right-radius: 0.9375rem;
  }

  &:nth-last-child(7) {
    border-bottom-left-radius: 0.9375rem;
  }

  ${(props) =>
    props.isDisabled &&
    css`
      background-color: ${(props) => props.theme.palette.grey.grey1};
      pointer-events: none;
    `}

  ${(props) =>
    props.isFuture &&
    css`
      pointer-events: none;
    `}

  ${(props) =>
    props.isToday &&
    css`
      .dayText {
        color: ${(props) => props.theme.palette.primary.primaryPurple};
      }
    `}
  
  ${(props) =>
    props.isActive &&
    css`
      .dayContent {
        border: 1px solid ${(props) => props.theme.palette.secondary.purple3};
        background-color: ${(props) => props.theme.palette.secondary.purple6};
      }
    `}
`

const Content = styled.div`
  border-radius: 0.25rem;
  padding: 0.375rem 0.5rem;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
`

const Trips = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
`

const TripsText = styled(Text)`
  color: ${(props) => props.theme.palette.text.placeholder};
  text-transform: uppercase;
  letter-spacing: 0.7px;
`

const getDays = (
  firstDayOfMonth: Moment,
  tripsMonth: TripStats,
  daySelected: number,
  setActiveTripStats: (dayNumber: number) => void
) => {
  const daysInMonth = firstDayOfMonth.daysInMonth()
  const firstDayNumber = firstDayOfMonth.weekday()

  const days = firstDayNumber + daysInMonth
  const totalDays = days + (days % 7 === 0 ? 0 : 7 - (days % 7))

  return [...Array(totalDays)].map((_day, idx) => {
    const dayNumber = idx - firstDayNumber + 1
    const isDisabled = idx < firstDayNumber || idx + 1 > days
    const recordedTrips = !isDisabled && tripsMonth.tripsPerDay[dayNumber]

    const day = `${tripsMonth.month}-${
      dayNumber <= 9 ? `0${dayNumber}` : dayNumber
    }`
    const today = moment(moment().format('YYYY-MM-DD'))
    const isToday = !isDisabled && today.isSame(day)
    const isFuture = today.isBefore(day)
    const isActive = daySelected && daySelected === dayNumber

    return (
      <Day
        isDisabled={isDisabled}
        isActive={!isDisabled && isActive}
        isFuture={isFuture}
        isToday={isToday}
        key={idx}
        onClick={() =>
          !isDisabled && !isFuture && setActiveTripStats(dayNumber)
        }
      >
        {!isDisabled && (
          <Content className='dayContent'>
            <Text
              variant='action3'
              textColor={palette.text.secondary}
              className='dayText'
            >
              {dayNumber}
            </Text>
            {!isFuture && (
              <Trips className='trips'>
                <Text variant='h3'>{recordedTrips ? recordedTrips : 0}</Text>
                <TripsText variant='caption'>Trips</TripsText>
              </Trips>
            )}
          </Content>
        )}
      </Day>
    )
  })
}

interface TripsCalendarProps {
  firstDayOfMonth: Moment
  tripsMonth: TripStats
  daySelected: number
  setActiveTripStats: (dayNumber: number) => void
  isLoading: boolean
}

const TripsCalendar = React.memo((props: TripsCalendarProps) => {
  const {
    firstDayOfMonth,
    tripsMonth,
    daySelected,
    setActiveTripStats,
    isLoading,
  } = props

  if (isLoading || !tripsMonth) {
    return <Loading height={pxToRem(660)} spinnerSize={45} />
  }

  return (
    <Container>
      <Header>
        {daysOfWeek.map((day) => (
          <HeaderDay key={day}>
            <Text
              variant='action4'
              hideOverflow={true}
              textColor={palette.text.secondary}
              padding='0 0.125rem'
            >
              {day}
            </Text>
          </HeaderDay>
        ))}
      </Header>
      <Calendar>
        {getDays(firstDayOfMonth, tripsMonth, daySelected, setActiveTripStats)}
      </Calendar>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TripsCalendar.displayName = 'TripsCalendar'
}

export default TripsCalendar
