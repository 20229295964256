import Arrow from 'core-system/Icons/MapIntelligence/ArrowPrimary'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import { Feature } from 'geojson'
import React from 'react'
import { useSelector } from 'react-redux'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'

const PopupContainer = styled.div`
  display: flex;
  padding: 0.3125rem 2rem 0.3125rem 0.4375rem;
  flex-direction: column;
`

const EmployeeMainStats = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0.875rem;
`

const ArrowContainer = styled(Arrow)`
  margin-left: 0.25rem;
  transform: rotate(45deg);
`

const CommuteStatContainer = styled.div`
  display: flex;
  align-items: center;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

interface MapPopupEmployeeProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  feature: Feature
}

const MapPopupEmployee = React.memo((props: MapPopupEmployeeProps) => {
  const { feature, children } = props

  const allWorksites = useSelector(
    (state: AppState) => state.employer.worksites
  )

  if (!allWorksites) {
    return <Loading />
  }

  const distance = (feature.properties.distance / 1609).toFixed(0)
  const worksite =
    allWorksites &&
    allWorksites.filter((s) => s.id === feature.properties.worksiteId)[0]
  return (
    <PopupContainer>
      <FlexContainer>
        <img
          alt={'employee'}
          src={`${StaticAssetsUrl}/accounts/default-user.png`}
        />
        <EmployeeMainStats>
          <Text variant='h5'>ID: {feature.properties.employeeCorporateId}</Text>
          <FlexContainer>
            <Text
              variant='body2'
              textColor={palette.text.secondary}
              marginRight='0.25rem'
            >
              {worksite?.alias} -
            </Text>
            <CommuteStatContainer>
              <Text variant='body2' textColor={palette.text.secondary}>
                {distance} miles
              </Text>
              <ArrowContainer
                height={9}
                width={8}
                color={palette.text.secondary}
              />
            </CommuteStatContainer>
          </FlexContainer>
        </EmployeeMainStats>
      </FlexContainer>
      {children}
    </PopupContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MapPopupEmployee.displayName = 'MapPopupEmployee'
}

export default MapPopupEmployee
