const Colors = {
  primary: '#6200e0',
  primaryDark: '#20006D',
  primaryMedium: '#B588FF',
  primaryLight: '#ECECFF',
  primaryLighter: '#F0F0FF',
  primaryRgb: '98, 0, 224',
  white: '#fff',
  grey: '#9E9ECF',
  greyLight: '#EBEBF5',
  greyLighter: '#F9F9FD',
  greyLightest: '#F8F8FF',
  greyMedium: '#D9D9E3',
  greyText: '#85858C',
  purpleGrey: '#7E7DC7',
  purpleGreyLight: '#F8F8FF',
  purpleGreyMedium: '#8D8DCA',
  purpleGreyText: '#7E7EC2',
  blueGreyLight: '#DBDBFF',
  green: '#28AFAB',
  greenDark: '#00A19D',
  greenLight: '#C9F3F1',
  greenLightest: '#C9F3F1',
  red: '#C30078',
  redLight: '#FFE3F3',
  redLighter: '#FFBDE4',
  redError: '#F32747',
  black: '#000',
  transparent: 'transparent',
  success: '#62BC64',
  yellowLight: '#FFE68B',
  yellow: '#C09703',
  blue: '#0085FF',
  blueLight: '#BCDFFF',
}

export default Colors
