import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, LinkProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import styled, { css } from 'styled-components'
import { userActions } from 'redux/user/userSlice'
import * as Colors from '../../core-system/colors'
import Tooltip from '../../core-system/Tooltip'
import SegmentService from '../../redux/config/services/SegmentService'
import { Locations } from '../../shared/Router/Locations'
import { Action } from '../../shared/Types/Actions'
import { checkActive, SubMenu } from './SidebarShared'

const Container = styled.div<{
  disabled: boolean
  hoverColor: string
  hasMenu: boolean
}>`
  margin-bottom: 1rem;
  padding: 0 ${(props) => props.theme.pxToRem(20)};
  position: relative;
  z-index: ${(props) => props.theme.zIndex.max};

  &:hover {
    > span {
      display: ${(props) => (props.disabled ? 'none' : 'block')};
    }
  }

  ${(props) =>
    props.hasMenu &&
    css`
      &:hover {
        > a {
          background-color: ${props.hoverColor};
          color: ${props.disabled
            ? props.theme.palette.secondary.lavender1
            : props.theme.palette.white};
        }
      }
    `}
`

const LinkContainer = styled(Link)<{
  disabled?: boolean
  $active?: boolean
  $hoverColor: string
}>`
  display: flex;
  align-items: center;
  transition: width 0.5s ease;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  border: none;
  text-decoration: none;

  background-color: ${(props) => props.theme.palette.primary.primaryDark};
  color: ${(props) =>
    props.disabled
      ? props.theme.palette.secondary.lavender1
      : props.theme.palette.secondary.lavender2};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.$active
      ? {
          backgroundColor: props.theme.palette.secondary.purple2,
          color: props.theme.palette.white,
        }
      : ''}

  &:hover {
    background-color: ${(props) => props.$hoverColor};
    color: ${(props) =>
      props.disabled
        ? props.theme.palette.secondary.lavender1
        : props.theme.palette.white};
  }
`

const IconContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuContainer = styled.div<{
  isVisible: boolean
  position: PositionVariants
}>`
  position: absolute;
  left: ${(props) => props.theme.pxToRem(80)};
  padding-left: 0.5rem;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  z-index: ${(props) => props.theme.zIndex.tooltip};

  ${(props) =>
    props.position === 'bottom'
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `}
`

const SubMenuItems = styled.div`
  ${(props) => props.theme.typography.action4};
  display: flex;
  flex-direction: column;
  border: 0.0625rem solid ${(props) => props.theme.palette.grey.grey3};
  width: ${(props) => props.theme.pxToRem(180)};
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  padding: 0.5rem 0;
`

const SubMenuItem = styled(Link)<{ $active: boolean }>`
  text-decoration: none;
  padding: 0.8125rem 1rem;
  color: ${(props) =>
    props.$active
      ? props.theme.palette.primary.primaryPurple
      : props.theme.palette.text.primary};
  font-weight: 500;
  line-height: 0.875rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple6};
  }
`

const handleOnClick = (
  stat: string,
  location: string,
  setIsVisible: (open: boolean) => void,
  dispatch: Dispatch
) => {
  SegmentService.track('sidebar-item-click', {
    item: stat,
  })

  if (location === Locations.Login) {
    //@ts-ignore
    window.Intercom('shutdown')
    dispatch(userActions.logout())
  }

  setIsVisible(false)
}

const renderSubMenu = (
  menu: SubMenu[],
  setMenuOpen: (open: boolean) => void,
  path: string,
  dispatch: Dispatch<Action<any>>
) => {
  return menu.map((item: SubMenu, idx: number) => (
    <SubMenuItem
      key={idx}
      to={item.location}
      $active={item.location === path}
      onClick={() =>
        handleOnClick(item.metric, item.location, setMenuOpen, dispatch)
      }
    >
      {item.title}
    </SubMenuItem>
  ))
}

const getHoverColor = (active: boolean, disabled: boolean) => {
  if (active) {
    return Colors.Purple.Two
  } else if (disabled) {
    return Colors.Indigo.Two
  }
  return Colors.Purple.One
}

type PositionVariants = 'top' | 'bottom'

interface SidebarItemProps extends LinkProps {
  label: string
  disabled?: boolean
  icon?: React.ReactNode
  metric: string
  menu?: SubMenu[]
  menuposition?: PositionVariants
  path: string
  to: string
}

const SidebarItem = (props: SidebarItemProps) => {
  const {
    disabled = false,
    icon,
    label,
    metric,
    menu,
    path,
    to,
    menuposition = 'top',
  } = props
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef(null)
  const dispatch = useDispatch()

  const active = checkActive(to, path, menu)

  return (
    <Container
      disabled={disabled}
      onMouseEnter={() => !disabled && menu && setIsVisible(true)}
      onMouseLeave={() => !disabled && menu && setIsVisible(false)}
      hasMenu={menu ? true : false}
      hoverColor={getHoverColor(active, disabled)}
      ref={ref}
    >
      <LinkContainer
        {...props}
        disabled={disabled}
        $active={active}
        onClick={() => handleOnClick(metric, to, setIsVisible, dispatch)}
        $hoverColor={getHoverColor(active, disabled)}
        onMouseEnter={() => !disabled && !menu && setIsVisible(true)}
        onMouseLeave={() => !disabled && !menu && setIsVisible(false)}
      >
        {icon && <IconContainer>{icon}</IconContainer>}
      </LinkContainer>
      {menu ? (
        <SubMenuContainer position={menuposition} isVisible={isVisible}>
          <SubMenuItems>
            {renderSubMenu(menu, setIsVisible, path, dispatch)}
          </SubMenuItems>
        </SubMenuContainer>
      ) : (
        <Tooltip
          target={ref}
          show={isVisible}
          position={'right'}
          marginLeft={'0.25rem'}
        >
          {label}
        </Tooltip>
      )}
    </Container>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SidebarItem.displayName = 'SidebarItem'
}

export default SidebarItem
