import FlexContainer from 'core-system/FlexContainer'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import SearchInput from 'core-system/SearchInput'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AppState } from 'redux/config/store'
import { Merchant } from 'redux/programs/programsTypes'
import ProviderCatalogDetailModal from './ProviderCatalogDetailModal'
import ProviderFilter, { Group, spFilter } from './ProviderFilter'
import ProviderGrid from './ProviderGrid'

const ProviderCatalogView = React.memo(() => {
  const search = useLocation().search
  const merchantId = new URLSearchParams(search).get('id')

  const merchants = useSelector((state: AppState) => state.programs.merchants)
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )
  const serviceProviderMap = useSelector(
    (state: AppState) => state.programs.serviceProviderMap
  )

  const [currentFilters, setCurrentFilters] = useState<spFilter[]>([])
  const [searchVal, setSearchVal] = useState('')

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(!!merchantId)
  const [focusedMerchant, setFocusedMerchant] = useState(
    merchantId ? merchantsMap[merchantId] : null
  )

  const isLoading = !merchants
  const availableCities: string[] = isLoading
    ? []
    : Array.from(new Set(merchants.flatMap((m) => m.metroAreas)))

  const isProviderInGroup = (
    merchant: Merchant,
    allFilters: spFilter[],
    filterGroup: Group
  ) => {
    const groupFilters = allFilters.filter((f) => f.group === filterGroup)
    if (!groupFilters.length) return true
    return groupFilters.some((f) => f.func(merchant))
  }

  const filteredMerchants: Merchant[] = useMemo(() => {
    if (isLoading) return []
    const filteredMerchants: Merchant[] = merchants.filter((m) => {
      const searchFilter = isProviderInGroup(
        m,
        currentFilters,
        Group.SEARCH_TEXT
      )
      const modeFilter = isProviderInGroup(m, currentFilters, Group.MODE_TYPE)
      const partnerFilter = isProviderInGroup(m, currentFilters, Group.PARTNER)
      const cityFilter = isProviderInGroup(m, currentFilters, Group.CITY)
      return searchFilter && modeFilter && partnerFilter && cityFilter
    })

    // filter by country based off of selected metro areas
    const countryFilters = new Set(
      currentFilters
        .filter((f) => f.group === Group.CITY)
        .map((f) => ProgramUtils.getMetroAreaCountryNumber(f.name.toString()))
    )

    return !countryFilters.size
      ? filteredMerchants
      : filteredMerchants.filter((m) =>
          m.modeIds.some((modeId) =>
            countryFilters.has(serviceProviderMap[modeId]?.country)
          )
        )
  }, [merchants, currentFilters, isLoading, serviceProviderMap])

  const handleSearchValChange = useCallback(
    (newSearchVal: string) => {
      setSearchVal(newSearchVal)
      const newFilters = currentFilters.filter(
        (f) => f.group !== Group.SEARCH_TEXT
      )
      if (newSearchVal) {
        setCurrentFilters([
          ...newFilters,
          {
            name: 'search text',
            group: Group.SEARCH_TEXT,
            func: (m: Merchant) =>
              m.name.toLowerCase().includes(newSearchVal.toLowerCase()),
          },
        ])
      } else {
        setCurrentFilters(newFilters)
      }
    },
    [currentFilters, setSearchVal]
  )

  return (
    <>
      <FlexContainer flexDirection='column'>
        <SearchInput
          placeholder='Search by service name...'
          value={searchVal}
          onclearSearch={() => {
            handleSearchValChange('')
          }}
          onsearch={() => null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSearchValChange(e.target.value)
          }
          data-cy='merchant-catalog-search-input'
        />
        <ProviderFilter
          marginTop='1.5rem'
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
          availableCities={availableCities}
        />
        <FlexContainer centerApart margin='3rem 0 1.5rem'>
          <Text variant='h4'>Service Providers</Text>
          <Text
            variant='action4'
            textColor={palette.text.secondary}
            data-cy='merchant-catalog-results-number'
          >
            {filteredMerchants.length} Results
          </Text>
        </FlexContainer>
        <ProviderGrid
          filteredMerchants={filteredMerchants}
          isLoading={isLoading}
          onCellClick={(merchant) => {
            setFocusedMerchant(merchant)
            setIsDetailModalOpen(true)
          }}
        />
      </FlexContainer>
      <ProviderCatalogDetailModal
        merchant={focusedMerchant}
        isModalOpen={isDetailModalOpen}
        setIsModalOpen={setIsDetailModalOpen}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProviderCatalogView.displayName = 'Provider Catalog View'
}

export default ProviderCatalogView
