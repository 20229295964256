import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import FlexContainer from 'core-system/FlexContainer'
import * as SegmentIcons from 'core-system/Icons/Segments'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import TextField from 'core-system/TextField'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import styled, { css } from 'styled-components'

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

const TypeContainer = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  color: ${(props) => props.theme.palette.text.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  margin-right: 1rem;
  flex: 1;

  &:hover {
    ${(props) =>
      !props.isDisabled &&
      css`
        background-color: ${(props) => props.theme.palette.secondary.purple6};
        border: 1px solid
          ${(props) => props.theme.palette.primary.primaryPurple};

        div {
          &:nth-of-type(1) {
            background-color: ${(props) =>
              props.theme.palette.secondary.purple5};
            color: ${(props) => props.theme.palette.primary.primaryPurple};
          }
        }
      `};
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${(props) => props.theme.palette.secondary.purple6};
      border: 1px solid ${(props) => props.theme.palette.primary.primaryPurple};

      div {
        &:nth-of-type(1) {
          background-color: ${(props) => props.theme.palette.secondary.purple5};
          color: ${(props) => props.theme.palette.primary.primaryPurple};
        }
      }
    `};

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${(props) => props.theme.palette.text.disabled};
      cursor: default;
    `};

  &:last-of-type {
    margin-right: 0;
  }
`

const IconContainer = styled.div<{}>`
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  display: flex;
  justify-content: center;
  align-items: center;
`

interface SegmentsCreateModalProps {
  open: boolean
  closeModal: () => void
  handleSegmentChange: (params: {
    segmentId: string
    creationType?: number
  }) => void
}

const SegmentsCreateModal = React.memo((props: SegmentsCreateModalProps) => {
  const { open, closeModal, handleSegmentChange } = props

  const dispatch = useDispatch()
  const { segmentCreateFlag, allSegments } = useSelector(
    (state: AppState) => state.employer
  )

  const [activeType, setActiveType] = useState(0)
  const [segmentName, setSegmentName] = useState('')
  const [hasError, setHasError] = useState(false)

  const handleCreateSegment = (name: string) => {
    const hasError = allSegments.some((segment) => segment.name === name)

    if (hasError) {
      setHasError(true)
    } else {
      dispatch(employerActions.createSegment({ name, employees: [] }))
      SegmentService.track('commuters-modal-action', {
        action: 'create',
        groupType: activeType === 0 ? 'custom' : 'upload',
      })
    }
  }

  useEffect(() => {
    if (segmentCreateFlag && open) {
      handleSegmentChange({
        segmentId: segmentCreateFlag,
        creationType: activeType,
      })
      dispatch(employerActions.toggleSegmentCreateFlag())
      closeModal()
    }
  }, [
    dispatch,
    closeModal,
    handleSegmentChange,
    segmentCreateFlag,
    activeType,
    open,
  ])

  useEffect(() => {
    setHasError(false)
  }, [segmentName])

  return (
    <Modal open={open} onClose={closeModal} width={pxToRem(752)}>
      <ModalHeader title='New Group' />
      <ModalBody padding={`1rem ${pxToRem(52)} 2.75rem`}>
        <Text variant='body1' margin='1rem 0'>
          Select the type of group you&apos;d like to create:
        </Text>
        <FlexContainer justifyContent='space-between' marginBottom='1.5rem'>
          <TypeContainer
            isActive={activeType === 0}
            onClick={() => setActiveType(0)}
          >
            <IconContainer>
              <SegmentIcons.CustomGroup />
            </IconContainer>
            <Text variant='action4' margin='0.75rem 0 0.5rem'>
              Custom Group
            </Text>
            <Text variant='body2'>
              Manually add commuters to your group from any worksite.
            </Text>
          </TypeContainer>
          <TypeContainer
            isActive={activeType === 1}
            onClick={() => setActiveType(1)}
          >
            <IconContainer>
              <SegmentIcons.Upload />
            </IconContainer>
            <Text variant='action4' margin='0.75rem 0 0.5rem'>
              Upload List
            </Text>
            <Text variant='body2'>
              Add commuters to your group by uploading a commuter list.
            </Text>
          </TypeContainer>
          <TypeContainer isActive={activeType === 2} isDisabled>
            <IconContainer>
              <SegmentIcons.SmartGroup />
            </IconContainer>
            <Text
              variant='action4'
              margin='0.75rem 0 0.5rem'
              textColor='inherit'
            >
              Smart Group
            </Text>
            <Text variant='body2' textColor='inherit'>
              Create groups based on commuter locations.
            </Text>
          </TypeContainer>
        </FlexContainer>
        <TextField
          label='Name'
          onChange={(e) => setSegmentName(e.currentTarget.value)}
          invalid={hasError}
          helpText='Please enter a valid group name'
          data-cy='segment-name-input'
        />
      </ModalBody>
      <ModalFooter>
        <ButtonContainer>
          <Button variant='tertiary' marginRight='1rem' onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={() => handleCreateSegment(segmentName)}>
            Create
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SegmentsCreateModal.displayName = 'SegmentsCreateModal'
}

export default SegmentsCreateModal
