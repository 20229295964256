import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { useLocation } from 'react-router-dom'
import OptInQuestionHeader from './components/OptInQuestionHeader'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import Divider from 'core-system/Divider'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import OptInQuestionResponse from './components/OptInQuestionResponse'
import OptInQuestionNoResponses from './components/OptInQuestionNoResponses'
import OptInQuestionResponsesModal from './components/OptInQuestionResponsesModal'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const QuestionOverviewBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
  }
`

const ResponsesContainer = styled.div<{ noResponses?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noResponses ? '0.25rem' : '1rem')};
`

const OptInQuestionOverviewView = React.memo(() => {
  const location = useLocation()

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const [showResponsesModal, setShowResponsesModal] = useState(false)
  const [selectedChoice, setSelectedChoice] = useState(null)

  const handleChoiceClick = (choice: number) => {
    setShowResponsesModal(true)
    setSelectedChoice(choice)
  }

  const handleCloseResponsesModal = () => {
    setShowResponsesModal(false)
    setSelectedChoice(null)
  }

  const pathComponents = location.pathname.split('/')

  const segmentId = pathComponents.slice(-2)[0]
  const questionId = pathComponents.slice(-1)[0]

  const { optIns } = useSelector((state: AppState) => state.optIn)
  const { allCommutersMap } = useSelector((state: AppState) => state.employee)

  const currentQuestion = optIns
    ? optIns[segmentId]?.find((optIn) => optIn.id === questionId)
    : null

  const currentQuestionResponders = currentQuestion
    ? new Set(currentQuestion.employeeChoices)
    : new Set()

  const noResponseCommuters = allCommutersMap
    ? Object.values(allCommutersMap).reduce((commuters, commuter) => {
        if (!currentQuestionResponders.has(commuter.id)) {
          commuters.push({
            name: commuter.name,
            corporateId: commuter.employeeCorporateId,
            email: commuter.email,
          })
        }
        return commuters
      }, [])
    : []

  if (!optIns || !allCommutersMap) {
    return <Loading fullPage />
  }

  return (
    <Container>
      <OptInQuestionHeader isMobile={isMobile} question={currentQuestion} />
      <Divider direction='horizontal' />
      <QuestionOverviewBody>
        <ResponsesContainer>
          <Text variant='h3'>Responses</Text>
          {currentQuestion.choices.map((choice, idx) => {
            const responses = currentQuestion.employeeChoices.filter(
              (choice) => choice.choice === idx
            )
            return (
              <OptInQuestionResponse
                key={idx}
                choice={choice}
                responses={responses}
                isMobile={isMobile}
                onClick={() => handleChoiceClick(idx)}
              />
            )
          })}
        </ResponsesContainer>
        <ResponsesContainer noResponses>
          <Text variant='h3'>No Response</Text>
          <OptInQuestionNoResponses
            isMobile={isMobile}
            noResponseCommuters={noResponseCommuters}
          />
        </ResponsesContainer>
      </QuestionOverviewBody>
      <OptInQuestionResponsesModal
        open={showResponsesModal}
        closeModal={() => handleCloseResponsesModal()}
        responses={currentQuestion?.employeeChoices || []}
        optInId={currentQuestion?.id || ''}
        response={currentQuestion?.choices[selectedChoice]}
      />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OptInQuestionOverviewView.displayName = 'OptInQuestionOverviewView'
}

export default OptInQuestionOverviewView
