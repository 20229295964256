import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import useQueryParam from 'shared/Hooks/useQueryParam'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import Loading from 'core-system/Loading'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import CarpoolDetailsCard from './components/CarpoolDetailsCard'
import CarpoolRequestCard from './components/CarpoolRequestCard'
import { CarpoolRelationType } from 'redux/employeePlatform/employeePlatformTypes'
import GridCard from 'employee-platform/shared/components/GridCard'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import { Footer } from 'core-system/Program'
import styled from 'styled-components'
import Button from 'core-system/Button'
import { getCarpoolRelation } from './CarpoolUtils'
import { Locations } from 'shared/Router/Locations'
import SegmentService from 'redux/config/services/SegmentService'
import { CardListContainer } from 'employee-platform/shared/styles/CardList'
import CarpoolMemberCard from './components/CarpoolMemberCard'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
`

const FooterButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const CarpoolDetailsView = React.memo(() => {
  const groupId = useQueryParam('carpoolGroupId')
  const navigate = useNavigate()

  const {
    profileData,
    allCarpoolGroups,
    myCarpoolGroups,
    carpoolMemberships,
    receivedCarpoolRequests,
    sentCarpoolRequests,
  } = useSelector((state: AppState) => state.employeePlatform)

  const [editing, setEditing] = useState(false)

  const showRequestDetails = useCallback(
    (requestId: string, groupId: string) => {
      navigate(`${requestId}`, {
        state: { from: `${Locations.EmployeeCarpool.Overview}/${groupId}` },
      })
      SegmentService.track('carpool-request-click', {
        action: `carpool-request-${requestId}`,
        locationAt: `employee-platform-carpool-details-${groupId}`,
      })
    },
    [navigate]
  )

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const handleUpdateCarpool = () => {
    console.log('TODO')
  }

  if (
    !profileData ||
    !allCarpoolGroups ||
    !myCarpoolGroups ||
    !carpoolMemberships ||
    !receivedCarpoolRequests ||
    !sentCarpoolRequests
  ) {
    return <Loading />
  }

  const carpoolRelation: CarpoolRelationType = getCarpoolRelation(
    myCarpoolGroups,
    carpoolMemberships,
    sentCarpoolRequests,
    groupId
  )

  return (
    <div>
      <GridContainer numColumns={1}>
        <CarpoolDetailsCard
          isMobile={isMobile}
          carpoolRelation={carpoolRelation}
          carpoolId={groupId}
          editing={editing}
          setEditing={setEditing}
        />
        {carpoolRelation === 'OWNER' ? (
          <GridContainer numColumns={2}>
            <GridContainer numColumns={1} fitContentHeight>
              <GridCard headerTitle='Requests to Join'>
                <CardListContainer>
                  {receivedCarpoolRequests &&
                  receivedCarpoolRequests[groupId] &&
                  Object.keys(receivedCarpoolRequests[groupId]).length > 0 ? (
                    Object.keys(receivedCarpoolRequests[groupId]).map(
                      (requestId: string) => {
                        return (
                          <CarpoolRequestCard
                            key={requestId}
                            isMobile={isMobile}
                            requestData={
                              receivedCarpoolRequests[groupId][requestId]
                            }
                            onViewDetailsClick={showRequestDetails}
                          />
                        )
                      }
                    )
                  ) : (
                    <NoDataText text='NO REQUESTS' />
                  )}
                </CardListContainer>
              </GridCard>
            </GridContainer>
            <GridContainer numColumns={1} fitContentHeight>
              <GridCard headerTitle='Current Members'>
                <CardListContainer>
                  {myCarpoolGroups[groupId] &&
                  myCarpoolGroups[groupId].members &&
                  Object.keys(myCarpoolGroups[groupId].members).length > 0 ? (
                    Object.keys(myCarpoolGroups[groupId].members).map(
                      (memberId: string) => {
                        return (
                          <CarpoolMemberCard
                            key={memberId}
                            isMobile={isMobile}
                            memberData={
                              myCarpoolGroups[groupId].members[memberId]
                            }
                          />
                        )
                      }
                    )
                  ) : (
                    <NoDataText text='NO MEMBERS' />
                  )}
                </CardListContainer>
              </GridCard>
            </GridContainer>
          </GridContainer>
        ) : null}
      </GridContainer>
      {editing ? (
        <Footer>
          <FooterContainer>
            <FooterButtonsContainer>
              <Button variant='tertiary' onClick={() => setEditing(false)}>
                Cancel
              </Button>
              <Button onClick={() => handleUpdateCarpool()}>
                Update Carpool
              </Button>
            </FooterButtonsContainer>
          </FooterContainer>
        </Footer>
      ) : null}
    </div>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolDetailsView.displayName = 'CarpoolDetailsView'
}

export default CarpoolDetailsView
