import MapPin from 'core-system/Icons/Misc/Location'
import palette from 'core-system/Themes/palette'
import { CarpoolMapLegendIcons } from 'employee-platform/features/Carpool/CarpoolUtils'
import { Feature, FeatureCollection, Point } from 'geojson'
import React from 'react'
import { Marker } from 'react-map-gl'

const getMarker = (layer: string, type?: 'source' | 'dest') => {
  if (layer === 'worksites') {
    return MapPin
  } else {
    return type === 'source'
      ? CarpoolMapLegendIcons.source
      : CarpoolMapLegendIcons.dest
  }
}

interface MarkerProps {
  features: FeatureCollection
  layer: string
  markerClick: (feature: Feature) => void
}

const MapIntelligenceMarker = React.memo((props: MarkerProps) => {
  const { features, layer, markerClick } = props

  return (
    <>
      {features.features.map((feature: Feature, index: number) => {
        const point = feature.geometry as Point
        const SVGMarker = getMarker(layer, feature.properties.pointType)
        const markerColor =
          feature &&
          Object.keys(feature.properties).find((key) => key === 'markerColor')
            ? feature.properties['markerColor']
            : palette.primary.primaryDark

        return (
          <Marker
            key={`marker-${index}`}
            latitude={point.coordinates[1]}
            longitude={point.coordinates[0]}
          >
            <SVGMarker
              height={layer === 'worksites' ? 28 : 20}
              width={layer === 'worksites' ? 28 : 20}
              style={{
                cursor: 'pointer',
                stroke: 'none',
                transform: `translate(0px,-3px)`,
                color: markerColor,
              }}
              onClick={() => markerClick(feature)}
            />
          </Marker>
        )
      })}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MapIntelligenceMarker.displayName = 'MapIntelligenceMarker'
}

export default MapIntelligenceMarker
