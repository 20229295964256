import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 9.33335C7 6.39259 9.39259 4 12.3333 4C15.2741 4 17.6667 6.39259 17.6667 9.33331C17.6667 10.2161 17.446 11.0915 17.028 11.8652L12.625 19.8281C12.5664 19.9343 12.4548 20 12.3333 20C12.2119 20 12.1003 19.9343 12.0417 19.8281L7.64031 11.8678C7.22072 11.0915 7 10.2162 7 9.33335ZM9.66669 9.33335C9.66669 10.8037 10.863 12 12.3333 12C13.8037 12 15 10.8037 15 9.33335C15 7.86297 13.8037 6.66669 12.3333 6.66669C10.863 6.66669 9.66669 7.86297 9.66669 9.33335Z'
    />
  </>,
  'Location'
)
