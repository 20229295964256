import styled from 'styled-components'

export const GridContainer = styled.div<{
  numColumns: number
  fitContentHeight?: boolean
  customGridGap?: string
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.numColumns}, 1fr);
  grid-gap: ${(props) => props.customGridGap || '1rem'};
  height: ${(props) => (props.fitContentHeight ? 'fit-content' : 'auto')};

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-template-columns: 100%;
  }
`

export const CustomGridContainer = styled.div<{
  columnLayout: string
  fitContentHeight?: boolean
  maintainLayoutMobile?: boolean
}>`
  display: grid;
  grid-template-columns: ${(props) => props.columnLayout};
  grid-gap: 1rem;
  height: ${(props) => (props.fitContentHeight ? 'fit-content' : 'auto')};

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: ${(props) =>
      props.maintainLayoutMobile ? props.columnLayout : '100%'};
  }
`
