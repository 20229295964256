/**
 * Allows a number to be formatted to set currency format.
 * @param num the value to convert into currency
 * @param decimal number of decimals to include
 * @param format the desired currency format (ex: 'en-US')
 */
function formatDollar(
  num: number | string,
  decimal = 2,
  roundToThousand = false,
  format = 'en-US'
): string {
  const formattedNum = typeof num === 'string' ? parseFloat(num) : num
  if (!formattedNum) return '$0'

  const numInDollars = formattedNum / 100

  const formattedDollar = new Intl.NumberFormat(format, {
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal,
  }).format(roundToThousand ? numInDollars / 1000 : numInDollars)

  return `$${formattedDollar}${roundToThousand ? 'k' : ''}`
}

/**
 * Allows a number to be formatted to specific decimals as number or string.
 * @param num the number to format
 * @param decimal number of decimals to include
 * @param asFloat if number should be a float or string
 */
function formatNumber(num: number | string, decimal = 2): number {
  if (!num) return 0

  const formattedNumber =
    typeof num === 'number'
      ? (num as number).toFixed(decimal)
      : parseFloat(num as string).toFixed(decimal)

  return parseFloat(formattedNumber)
}

const isNumTooLarge = (num: number, roundToThousand: boolean) => {
  return roundToThousand && (num >= 1000 || num <= -1000)
}

type MetricFormatType = 'money' | 'env' | null

/**
 * Basic formatter for our common metrics.
 * @param num the value to format
 * @param format the desired metric format ('money' | 'env' | null)
 * @param decimals number of decimals to include
 * @param roundToThousand round the value into thousands (1100 -> 1.1k)
 */
function formatMetric(
  num: number | null | undefined,
  format: MetricFormatType,
  decimals = 2,
  roundToThousand = true
): string {
  if (!num && format === 'money') return '$0'

  if (!num) return format === 'env' ? '0lbs' : '0'

  if (format === 'env') {
    const rawPoundVal = num / 454
    const poundTooLarge = isNumTooLarge(rawPoundVal, roundToThousand)
    const formattedPoundVal = formatNumber(
      poundTooLarge ? rawPoundVal / 1000 : rawPoundVal,
      decimals
    )
    return `${formattedPoundVal}${poundTooLarge ? 'k' : ''} lbs`
  }

  return formatDollar(num, decimals, isNumTooLarge(num, roundToThousand))
}

export default {
  formatDollar,
  formatNumber,
  formatMetric,
}
