class IntercomService {
  boot(traits: Dictionary) {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'q9wk6ezv',
      ...traits,
      hide_default_launcher: false,
    })
  }

  update(traits: Dictionary) {
    window.Intercom('update', traits)
  }

  onShow(func: () => void) {
    window.Intercom('onShow', func)
  }

  onHide(func: () => void) {
    window.Intercom('onHide', func)
  }

  show() {
    window.Intercom('show')
  }

  shutdown() {
    window.Intercom('shutdown')
  }
}

export default new IntercomService()
