import React from 'react'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import RadialChartCenterText from 'core-system/charts/Radial/RadialChartCenterText'
import FormattingUtils from 'shared/FormattingUtils'
import palette from 'core-system/Themes/palette'
import Divider from 'core-system/Divider'
import styled from 'styled-components'

const IconContainer = styled(FlexContainer)`
  width: 2rem;
  height: 2rem;
  background-color: ${(props) => props.theme.palette.chips.purple2};
  border-radius: 50%;
  margin-right: 0.5rem;
`

interface BudgetItem {
  name: string
  amount: number
  icon?: React.ReactNode
}

const renderBudgetItem = (item: BudgetItem) => {
  return (
    <FlexContainer
      key={item.name}
      flexDirection='row'
      alignItems='center'
      marginBottom='0.5rem'
    >
      {item.icon ? <IconContainer center>{item.icon}</IconContainer> : null}
      <FlexContainer flexDirection='column' marginBottom='0.5rem'>
        <Text variant='action3'>{item.name}</Text>
        <Text variant='h4' textColor={palette.primary.primaryPurple}>
          {FormattingUtils.formatDollar(item.amount, 0)}
        </Text>
      </FlexContainer>
    </FlexContainer>
  )
}

interface BudgetBreakdownProps {
  budgetItems: BudgetItem[]
  summaryDirection?: 'row' | 'column'
}

const BudgetBreakdown = React.memo((props: BudgetBreakdownProps) => {
  const { budgetItems, summaryDirection } = props

  const totalBudget = budgetItems.reduce(
    (total, item) => total + (item ? item.amount : 0),
    0
  )
  // Write the two lines below as one line to avoid a bug in the RadialChartCenterText component

  const chartValues = budgetItems.map((item) => (item ? item.amount : 0))
  const chartLabels = budgetItems.map(
    (item) =>
      `${item ? item.name : ''} (${FormattingUtils.formatDollar(
        item ? item.amount : 0,
        0
      )})`
  )

  return (
    <FlexContainer
      flexDirection={summaryDirection ? summaryDirection : 'column'}
      alignItems='center'
      justifyContent={summaryDirection === 'row' ? 'space-evenly' : 'center'}
    >
      <RadialChartCenterText
        value={totalBudget}
        data={chartValues}
        labels={chartLabels}
        size={170}
        isCurrency
      />
      {summaryDirection ? (
        <>
          <Divider
            size='100%'
            direction={
              summaryDirection === 'column' ? 'horizontal' : 'vertical'
            }
            margin={summaryDirection === 'column' ? '1.5rem 0' : '0 1.5rem'}
          />
          <FlexContainer flexDirection='column'>
            {budgetItems.map((item) => item && renderBudgetItem(item))}
          </FlexContainer>
        </>
      ) : null}
    </FlexContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  BudgetBreakdown.displayName = 'BudgetBreakdown'
}

export default BudgetBreakdown
