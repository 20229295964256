import React from 'react'
import PlatformSvg, { SVGVariant } from 'core-system/Icons/PlatformSvg'

const getRandomUserSvg = (size: number) => {
  const userVariants = [
    'userFemale1',
    'userFemale2',
    'userFemale3',
    'userFemale4',
    'userFemale5',
    'userFemale6',
    'userMale1',
    'userMale2',
    'userMale3',
    'userMale4',
    'userMale5',
    'userMale6',
  ] as SVGVariant[]
  return (
    <PlatformSvg
      folder='users'
      variant={userVariants[Math.floor(Math.random() * 12)]}
      width={size}
      height={size}
    />
  )
}

export default { getRandomUserSvg }
