import React, { useState } from 'react'
import styled from 'styled-components'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import Button from 'core-system/Button'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { BankMandateModalCopy, PaymentMethodCopy } from '../ProfileUtils'
import { useStripe } from '@stripe/react-stripe-js'
import Bugsnag from '@bugsnag/js'
import { useDispatch } from 'react-redux'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import Divider from 'core-system/Divider'

const Container = styled.div<{ centered: boolean }>`
  display: flex;
  margin: 1rem 0;
  justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

const BankAccountDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const BankAccountDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const renderBankAccountDetail = (label: string, text: string) => {
  return (
    <BankAccountDetailContainer>
      <Text variant='action3'>{label}</Text>
      <Text variant='body1'>{text}</Text>
    </BankAccountDetailContainer>
  )
}

interface BankMandateModalProps {
  open: boolean
  closeModal: () => void
  bankAccountInfo: any
}

const BankMandateModal = (props: BankMandateModalProps) => {
  const { open, closeModal, bankAccountInfo } = props

  const today = moment()

  const stripe = useStripe()
  const dispatch = useDispatch()

  const { profileData } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const [mandateLoading, setMandateLoading] = useState(false)
  const [mandateStatus, setMandateStatus] = useState(null)

  const handleCloseModal = () => {
    setMandateLoading(false)
    setMandateStatus(null)
    closeModal()
  }

  const confirmPaymentMandateUpdate = async (clientSecret: string) => {
    setMandateLoading(true)
    const { setupIntent, error } = await stripe.confirmUsBankAccountSetup(
      clientSecret
    )
    if (error) {
      console.log('Stripe bank account mandate error: ', error.message)
      Bugsnag.notify(error as any)
      setMandateLoading(false)
      dispatch(
        notificationsActions.generalPageError('Unable to setup bank account')
      )
    }
    if (setupIntent) {
      const info = BankMandateModalCopy[setupIntent.status]
      setMandateStatus(info)
      setTimeout(() => {
        dispatch(employeePlatformActions.getAllPaymentMethods())
      }, 1000)
      setTimeout(() => {
        setMandateLoading(false)
      }, 2000)
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => handleCloseModal()}
      disableEsc={true}
      width={pxToRem(550)}
    >
      <ModalHeader title='Terms & Conditions' />
      <ModalBody>
        <Container centered={mandateStatus ? true : false}>
          {mandateLoading && <Loading isCard={false} />}
          {!mandateLoading && !mandateStatus ? (
            <BankAccountDetails>
              {renderBankAccountDetail('Date', today.format('MMM DD, YYYY'))}
              {renderBankAccountDetail(
                'Name',
                `${profileData?.firstName} ${profileData?.lastName}`
              )}
              {renderBankAccountDetail(
                'Financial Institution',
                bankAccountInfo?.account?.bank_name
              )}
              {renderBankAccountDetail(
                'Account Number',
                `•••• ${bankAccountInfo?.account?.last4}`
              )}
              {renderBankAccountDetail(
                'Routing Number',
                bankAccountInfo?.account?.routing_number
              )}
              <Divider direction='horizontal' marginY='1rem' />
              <Text variant='action3'>
                Please review the terms and conditions below:
              </Text>
              <Text variant='body1'>
                {PaymentMethodCopy.bankAcctMandateCopy}
              </Text>
            </BankAccountDetails>
          ) : null}
          {!mandateLoading && mandateStatus ? (
            <StatusContainer>
              {mandateStatus?.icon}
              <Text variant='h4'>{mandateStatus?.title}</Text>
              <Text variant='action4'>{mandateStatus?.message}</Text>
            </StatusContainer>
          ) : null}
        </Container>
      </ModalBody>
      <ModalFooter>
        <ButtonContainer>
          {!mandateLoading && mandateStatus ? (
            <Button onClick={() => handleCloseModal()}>Close</Button>
          ) : null}
          {!mandateStatus ? (
            <>
              <Button
                variant='tertiary'
                marginRight='1rem'
                onClick={() => handleCloseModal()}
              >
                Cancel
              </Button>
              <Button
                onClick={() =>
                  confirmPaymentMandateUpdate(bankAccountInfo.clientSecret)
                }
              >
                Accept
              </Button>
            </>
          ) : null}
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  BankMandateModal.displayName = 'Bank Mandate Modal'
}

export default BankMandateModal
