import * as React from 'react'

type PropsAreEqual = (
  prevProps: Readonly<any>,
  nextProps: Readonly<any>
) => boolean

/**
 * Utilities function to wrap component with React.memo along with setting a
 * display name according to the environment the component is running within.
 *
 * For typescript to reflect correct props on Component the the props type should be
 * set on component
 *
 * @param Component React.FunctionComponent<T>
 * @param name string
 * @param propsAreEqual PropsAreEqual
 */
export function buildComponent<T>(
  Component: React.FunctionComponent<T>,
  name: string,
  propsAreEqual?: PropsAreEqual
) {
  if (process.env.NODE_ENV !== 'production') {
    Component.displayName = name
  }
  return React.memo(Component, propsAreEqual)
}

/**
 * Utilities function to wrap a forwarding ref component with React.memo along with setting a
 * display name according to the environment the component is running within.
 *
 * For typescript to reflect correct props on Component the the props type should be
 * set on component
 *
 * @param Component React.FunctionComponent<T>
 * @param name string
 * @param propsAreEqual PropsAreEqual
 */
export function buildForwardingComponent<T, P = {}>(
  Component: React.ForwardRefExoticComponent<P & React.RefAttributes<T>>,

  propsAreEqual?: PropsAreEqual
) {
  // Disabling this for the moment, since this fails linting checks. Some combo of forwarding ref + memo messes with display name
  // if (process.env.NODE_ENV !== 'production') {
  //   Component.displayName = Component.displayName || Component.name
  // }
  return React.memo(Component, propsAreEqual)
}
