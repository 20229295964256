import React from 'react'
import { Locations } from 'shared/Router/Locations'
import * as SidebarIcons from 'core-system/Icons/Sidebar'
import {
  Car as CarIcon,
  Carpool as CarpoolIcon,
} from 'core-system/Icons/modeTypes'
import { OneTimePurchase as OTPIcon } from 'core-system/Icons/Micromobility'
import SurveyIcon from 'core-system/Icons/Concierge/Survey'

export type SubMenu = { location: string; title: string; metric: string }

export interface SidebarItem {
  label: string
  disabled?: boolean
  icon?: React.ReactNode
  metric?: string
  menu?: SubMenu[]
  link: string
  productLabel?: string
}

export interface SubMenuItem {
  location: string
  title: string
  metric: string
}

export const checkActive = (to: string, path: string, _menu: SubMenu[]) => {
  const pathComponents = path.split('/')
  const isEmployeePlatform =
    pathComponents.length >= 2 && pathComponents[1] == 'employee'
  if (isEmployeePlatform) {
    return pathComponents.includes(to.split('/')[2])
  }
  return path.split('/').includes(to.split('/')[1])
}

export const accountSubMenu = [
  {
    location: Locations.Accounts.Profile,
    title: 'Settings',
    metric: 'accounts-view',
  },
  {
    label: 'Provider Catalog',
    link: Locations.ProviderCatalog,
    metric: 'merchant-catalog',
  },
  {
    location: Locations.Login,
    title: 'Logout',
    metric: 'logout',
  },
]

export const pageLinks = [
  {
    label: 'Dashboard',
    icon: <SidebarIcons.Dashboard />,
    link: Locations.Dashboard,
    metric: 'dashboard',
    productLabel: null,
  },
  // {
  //   label: 'Map Intelligence',
  //   icon: <SidebarIcons.Insights />,
  //   link: Locations.MapIntelligence,
  //   metric: 'maps',
  // },
  {
    label: 'Flex Programs',
    icon: <SidebarIcons.Flex />,
    link: Locations.Flex.Programs,
    metric: 'flex',
    productLabel: 'FLEX',
  },
  {
    label: 'Micromobility Programs',
    icon: <SidebarIcons.Micromobility />,
    link: Locations.Micromobility.Programs,
    metric: 'micromobility',
    productLabel: 'MICROMOBILITY',
  },
  {
    label: 'Pre-Tax Commuter Programs',
    icon: <SidebarIcons.QTF />,
    link: Locations.QTF.Programs,
    metric: 'tax-savings',
    productLabel: 'QTF',
  },
  {
    label: 'Guaranteed Ride Home Programs',
    icon: <CarIcon />,
    link: Locations.GRH.Programs,
    metric: 'grh',
    productLabel: 'GRH',
  },
  {
    label: 'One Time Purchase Programs',
    icon: <OTPIcon />,
    link: Locations.OTP.Programs,
    metric: 'otp',
    productLabel: 'OTP',
  },
  {
    label: 'Trips & Incentives',
    icon: <SidebarIcons.MapIntelligence />,
    link: Locations.Incentives.Programs,
    metric: 'trips',
    productLabel: 'INCENTIVES',
  },
  {
    label: 'Opt-In',
    icon: <SurveyIcon />,
    link: Locations.OptIn.Programs,
    metric: 'optin',
    productLabel: 'OPT_INS',
  },
  {
    label: 'One Time Rewards',
    icon: <SidebarIcons.Incentives />,
    link: Locations.OTR.OTRHistory,
    metric: 'one-time-rewards',
    productLabel: 'OTR',
  },
  {
    label: 'Leaderboards',
    icon: <SidebarIcons.Leaderboards />,
    link: Locations.Leaderboards.Programs,
    metric: 'leaderboards',
    productLabel: 'COMPETITIONS',
  },
  {
    label: 'My Commuters',
    icon: <SidebarIcons.MyCommuters />,
    link: Locations.Segments.Overview,
    metric: 'segments',
    productLabel: null,
  },
  {
    label: 'Concierge',
    icon: <SidebarIcons.Concierge />,
    link: Locations.Concierge.Concierge,
    metric: 'concierge',
    productLabel: null,
  },
]

export const employeePageLinks = [
  {
    label: 'Dashboard',
    icon: <SidebarIcons.Dashboard />,
    link: Locations.EmployeeDashboard,
    metric: 'employee-dashboard',
    productLabel: null,
  },
  {
    label: 'Pre-Tax Commuter Benefits',
    icon: <SidebarIcons.QTF />,
    link: Locations.EmployeePreTax.Overview,
    metric: 'employee-qtf',
    productLabel: 'QTF',
  },
  {
    label: 'Carpool',
    icon: <CarpoolIcon />,
    link: Locations.EmployeeCarpool.Overview,
    metric: 'employee-carpool',
    productLabel: 'CARPOOL',
  },
  {
    label: 'Guaranteed Ride Home',
    icon: <CarIcon />,
    link: Locations.EmployeeGRH.Overview,
    metric: 'employee-grh',
    productLabel: 'GRH',
  },
  {
    label: 'Trips',
    icon: <SidebarIcons.MapIntelligence />,
    link: Locations.EmployeeTrips.Overview,
    metric: 'employee-trips',
    productLabel: null,
  },
  {
    label: 'Leaderboards',
    icon: <SidebarIcons.Leaderboards />,
    link: Locations.EmployeeLeaderboards.Overview,
    metric: 'employee-leaderboards',
    productLabel: 'COMPETITIONS',
  },
  {
    label: 'Opt-In',
    icon: <SurveyIcon />,
    link: Locations.EmployeeOptIn.Overview,
    metric: 'employee-optin',
    productLabel: 'OTP_INS',
  },
  {
    label: 'Concierge',
    icon: <SidebarIcons.Concierge />,
    link: Locations.EmployeeConcierge,
    metric: 'employee-concierge',
    productLabel: null,
  },
]

export const getSettingsSidebarLink = (
  platformType: 'employee' | 'employer'
) => {
  return {
    label: 'Settings',
    icon: <SidebarIcons.Settings />,
    link:
      platformType === 'employer'
        ? Locations.Accounts.Profile
        : Locations.EmployeeSettings,
    metric: `${platformType === 'employer' ? 'employee' : 'employer'}-settings`,
    productLabel: null,
  }
}
