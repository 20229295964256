import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & { isSticky: boolean }
>`
  width: 100%;
  padding: 2rem ${(props) => props.theme.pxToRem(52)} ${(props) =>
  props.theme.pxToRem(52)};
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.text.primary};
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-radius: 0.9375rem;
  margin-bottom: 1rem;
  position: ${(props) => (props.isSticky ? 'sticky' : 'unset')};
  top: ${(props) => (props.isSticky ? 0 : 'unset')};

  ${flexbox}
  ${space}
  ${layout}
`

interface ColumnCardProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  isSticky?: boolean
}

const ColumnCard = React.memo((props: ColumnCardProps) => {
  const { children, isSticky = false } = props
  return (
    <Container isSticky={isSticky} {...props}>
      {children}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ColumnCard.displayName = 'ColumnCard'
}

export default ColumnCard
