import React from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import FlexContainer from 'core-system/FlexContainer'
import RadialChartCenterText, {
  colors,
} from 'core-system/charts/Radial/RadialChartCenterText'
import { OtpUsageHistory } from 'redux/otp/otpTypes'
import pxToRem from 'core-system/utils/pxToRem'
import Loading from 'core-system/Loading'
import palette from 'core-system/Themes/palette'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import LegendColor from 'core-system/Legend'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
const getChartData = (usageData: OtpUsageHistory[]) => {
  return usageData.reduce((reasons, transaction) => {
    reasons[transaction.reason] = reasons[transaction.reason] || 0
    reasons[transaction.reason] += 1
    return reasons
  }, {})
}

interface OTPReasonsBreakdownCardProps {
  usageHistory: OtpUsageHistory[]
}

const OTPReasonsBreakdownCard = React.memo(
  (props: OTPReasonsBreakdownCardProps) => {
    const { usageHistory } = props
    const merchantsMap = useSelector(
      (state: AppState) => state.programs.merchantsMap
    )

    if (!usageHistory || !merchantsMap) return <Loading height={pxToRem(417)} />

    const groupedReasons = getChartData(usageHistory)

    return (
      <Container>
        <Text variant='h5' marginBottom='1.5rem'>
          Reasons Breakdown
        </Text>
        <FlexContainer
          flexDirection='column'
          justifyContent='space-between'
          flex={1}
        >
          <FlexContainer center width='100%' marginBottom={'1.5rem'}>
            <RadialChartCenterText
              value={usageHistory.length}
              label={usageHistory.length === 1 ? 'Reason' : 'Reasons'}
              data={Object.values(groupedReasons)}
              labels={Object.keys(groupedReasons)}
              size={195}
              errorMsg='No transactions yet'
            />
          </FlexContainer>
          {usageHistory.length === 0 ? (
            <FlexContainer center>
              <Text variant='body1'>No transactions yet</Text>
            </FlexContainer>
          ) : (
            Object.keys(groupedReasons).map((reason, idx) => (
              <FlexContainer key={idx} alignItems='center'>
                <LegendColor color={colors[idx]} marginRight='0.5rem' />
                <Text variant='action4'>{reason}</Text>
                <Text
                  variant='body2'
                  marginLeft='auto'
                  textColor={palette.text.secondary}
                >
                  {groupedReasons[reason]}{' '}
                  {groupedReasons[reason] === 1 ? 'Trip' : 'Trips'}
                </Text>
              </FlexContainer>
            ))
          )}
        </FlexContainer>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OTPReasonsBreakdownCard.displayName = 'OTPReasonsBreakdownCard'
}

export default OTPReasonsBreakdownCard
