import { css } from 'styled-components'
// Import the font file using a different method or module

export const Name = 'PolySans'

const font = css`
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(PolySans-Slim),
      url('/static/fonts/PolySans/PolySans-Slim.otf') format('opentype');
  }
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(PolySans-Neutral),
      url('/static/fonts/PolySans/PolySans-Neutral.otf') format('opentype');
  }
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(PolySans-Median),
      url('/static/fonts/PolySans/PolySans-Median.otf') format('opentype');
  }
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('PolySans-Bulky'),
      url('/static/fonts/PolySans/PolySans-Bulky.otf') format('opentype');
  }
`

export default font
