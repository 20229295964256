import React from 'react'
import styled from 'styled-components'
import { LeaderboardRanking } from 'redux/employeePlatform/employeePlatformTypes'
import palette from 'core-system/Themes/palette'
import Table, { TableRow, TableRowCell, TableLabelRow } from 'core-system/Table'
import Text from 'core-system/Text'
import Loading from 'core-system/Loading'
import { CustomGridContainer } from 'employee-platform/shared/styles/Grid'
import { convertMetresToMiles } from 'employee-platform/shared/utils'

const TABLE_COLUMN_WIDTHS = ['14%', '56%', '30%']

const MyRankContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`

const MyRank = styled.div<{ isMobile: boolean }>`
  border: 1px solid ${palette.grey.grey3};
  border-radius: 0.5rem;
  padding: ${(props) => (props.isMobile ? '1rem' : '1rem 0 1rem 1.25rem')};
  margin-bottom: 1rem;
`

const MyRankText = styled.div`
  display: flex;
  align-items: center;
`

const RankNumberContainer = styled.div<{
  rankColors: { background: string; text: string }
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${(props) => props.rankColors.background};
  color: ${(props) => props.rankColors.text};
`

const EmptyTableRow = styled(TableRow)`
  text-align: center;
  align-items: center;
  justify-content: center;
`

const getRankContainerColors = (rank: number) => {
  switch (rank) {
    case 1:
      return {
        text: palette.leaderboards.goldText,
        background: palette.leaderboards.goldBackground,
      }
    case 2:
      return {
        text: palette.leaderboards.silverText,
        background: palette.leaderboards.silverBackground,
      }
    case 3:
      return {
        text: palette.leaderboards.bronzeText,
        background: palette.leaderboards.bronzeBackground,
      }
    default:
      return {
        text: palette.text.secondary,
        background: palette.transparent,
      }
  }
}

const renderRankings = (
  rankings: LeaderboardRanking[],
  isMobile: boolean,
  statUnit: string
) => {
  return rankings.map((ranking) => {
    return (
      <TableRow key={ranking.id} paddingLeft={isMobile ? 0 : '0.25rem'}>
        <TableRowCell
          width={TABLE_COLUMN_WIDTHS[0]}
          equalSize={false}
          alignItems='center'
        >
          <RankNumberContainer
            rankColors={getRankContainerColors(ranking.rank)}
          >
            {ranking.rank}
          </RankNumberContainer>
        </TableRowCell>
        <TableRowCell
          width={TABLE_COLUMN_WIDTHS[1]}
          equalSize={false}
          alignItems='center'
        >
          <Text
            variant='action2'
            textColor={palette.text.secondary}
            hideOverflow
          >
            {ranking.name}
          </Text>
        </TableRowCell>
        <TableRowCell
          width={TABLE_COLUMN_WIDTHS[2]}
          equalSize={false}
          alignItems='center'
        >
          <Text
            variant='action2'
            textColor={palette.text.secondary}
            hideOverflow
          >
            {`${ranking.value || 0} ${statUnit}`}
          </Text>
        </TableRowCell>
      </TableRow>
    )
  })
}

interface LeaderboardRankingsProps {
  isMobile: boolean
  rankings: LeaderboardRanking[] | null
  myRank: LeaderboardRanking | null
  leaderboardTypeInfo: {
    icon: React.ReactNode
    iconLg: React.ReactNode
    title: string
    smallTitle: string
    description: string
    modeTypes: string[]
    statUnit: string
    statDesc: string
  } | null
}

const LeaderboardRankings = React.memo((props: LeaderboardRankingsProps) => {
  const { isMobile, rankings, myRank, leaderboardTypeInfo } = props

  return (
    <>
      {myRank ? (
        <MyRankContainer>
          <Text variant='action3' marginLeft='0.5rem'>
            My Rank
          </Text>
          <MyRank isMobile={isMobile}>
            <CustomGridContainer
              columnLayout={TABLE_COLUMN_WIDTHS.join(' ')}
              maintainLayoutMobile
            >
              <RankNumberContainer
                rankColors={getRankContainerColors(myRank.rank)}
              >
                {myRank.rank}
              </RankNumberContainer>
              <MyRankText>
                <Text
                  variant='action2'
                  textColor={palette.text.secondary}
                  hideOverflow
                >
                  {myRank.name}
                </Text>
              </MyRankText>
              <MyRankText>
                <Text
                  variant='action2'
                  textColor={palette.text.secondary}
                  hideOverflow
                >
                  {/* CONFIRM THAT LEADERBOARD DATA IS BEING SENT IN METRES */}
                  {`${
                    leaderboardTypeInfo && leaderboardTypeInfo.statUnit === 'mi'
                      ? convertMetresToMiles(myRank.value)
                      : myRank.value || 0
                  } ${leaderboardTypeInfo?.statUnit}`}
                </Text>
              </MyRankText>
            </CustomGridContainer>
          </MyRank>
        </MyRankContainer>
      ) : null}
      <Text variant='action3' marginLeft='0.5rem'>{`Top ${
        rankings ? rankings.length : 20
      } Rankings`}</Text>
      <Table hasHeader={false} maxRows={10} loadMoreAnimation={true}>
        <TableLabelRow padding='1rem 1.5rem' overflow='hidden'>
          <TableRowCell width={TABLE_COLUMN_WIDTHS[0]} equalSize={false}>
            Rank
          </TableRowCell>
          <TableRowCell width={TABLE_COLUMN_WIDTHS[1]} equalSize={false}>
            Name
          </TableRowCell>
          <TableRowCell width={TABLE_COLUMN_WIDTHS[2]} equalSize={false}>
            {leaderboardTypeInfo ? leaderboardTypeInfo.statDesc : 'Value'}
          </TableRowCell>
        </TableLabelRow>
        {!rankings ? (
          <Loading />
        ) : rankings.length === 0 ? (
          <EmptyTableRow>NO RANKINGS</EmptyTableRow>
        ) : (
          renderRankings(
            rankings,
            isMobile,
            leaderboardTypeInfo ? leaderboardTypeInfo.statUnit : ''
          )
        )}
      </Table>
    </>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardRankings.displayName = 'LeaderboardRankings'
}

export default LeaderboardRankings
