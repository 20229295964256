import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import ProgramChange from 'core-system/Program/ProgramChange'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { QtfManageState } from 'redux/qtf/qtfTypes'
import DateUtils from 'shared/DateUtils'
import styled from 'styled-components'
import FormattingUtils from 'shared/FormattingUtils'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
`

const getDescriptionText = (canEdit: boolean, isPending: boolean) => {
  if (isPending) {
    return 'Update this program to apply these changes.'
  } else {
    return canEdit
      ? 'Update this program to apply these changes to the following month.'
      : 'All upcoming changes will be applied to the program the following months.'
  }
}

interface QTFManagePendingProps {
  programState: QtfManageState
  activeOptions: Dictionary
  canEdit?: boolean
  isPending: boolean
  openUpdateModal: () => void
}

const QTFManagePending = React.memo((props: QTFManagePendingProps) => {
  const {
    programState,
    activeOptions,
    canEdit = true,
    isPending,
    openUpdateModal,
  } = props

  const optionChanges = Object.keys(activeOptions.active).flatMap((option) => {
    if (activeOptions.active[option] !== activeOptions.nextMonth[option]) {
      return option
    } else {
      return []
    }
  })

  const isCancelling =
    programState.nextMonth.typeMap?.COMMUTER_PARKING?.status === 'CANCELLING' &&
    programState.nextMonth.typeMap?.COMMUTER_TRANSIT?.status === 'CANCELLING'

  const hasChanges =
    programState.active.contributionDeadlineDay !==
      programState.nextMonth.contributionDeadlineDay ||
    programState.active.employerTransitContribution !==
      programState.nextMonth.employerTransitContribution ||
    programState.active.employerParkingContribution !==
      programState.nextMonth.employerParkingContribution ||
    optionChanges.length > 0 ||
    isCancelling

  return (
    <>
      {hasChanges ? (
        <Container>
          <Text variant='h4' marginBottom='0.5rem'>
            {canEdit ? 'Pending Changes' : 'Upcoming Changes'}
          </Text>
          <Text variant='body1' marginBottom='1.5rem'>
            {getDescriptionText(canEdit, isPending)}
          </Text>
          {isCancelling && (
            <>
              <Divider margin='1.5rem 0' />
              <Text
                variant='caption'
                captionTitle
                textColor={palette.text.secondary}
                marginBottom='0.5rem'
              >
                Program Cancellation Request
              </Text>
              <Text variant='body2'>
                Our team will be in touch with you soon.
              </Text>
              {canEdit && (
                <Button onClick={openUpdateModal} width='100%' marginTop='1rem'>
                  Reactivate Program
                </Button>
              )}
            </>
          )}
          {programState.active.contributionDeadlineDay !==
            programState.nextMonth.contributionDeadlineDay && (
            <ProgramChange
              title='Allocation Deadline'
              metric='Day of every month'
              icon={
                <PlatformSvg folder='metrics' variant='productiveTimeGains' />
              }
              value={`${DateUtils.getDayOfTheMonth(
                programState.active.contributionDeadlineDay
              )} ➞ ${DateUtils.getDayOfTheMonth(
                programState.nextMonth.contributionDeadlineDay
              )}`}
            />
          )}
          {optionChanges.length > 0 && (
            <>
              <Divider margin='1.5rem 0' />
              <Text
                variant='caption'
                captionTitle
                textColor={palette.text.secondary}
              >
                Program Options
              </Text>
              {optionChanges.map((option) => (
                <ProgramChange
                  key={option}
                  metric={ProgramUtils.optionsCopy[option].title}
                  icon={ProgramUtils.optionsCopy[option].icon}
                  value={
                    activeOptions.nextMonth[option] ? 'Enabled' : 'Disabled'
                  }
                />
              ))}
            </>
          )}
          {programState.active.employerTransitContribution !==
            programState.nextMonth.employerTransitContribution ||
          programState.active.employerParkingContribution !==
            programState.nextMonth.employerParkingContribution ? (
            <>
              <Divider margin='1.5rem 0' />
              <Text
                variant='caption'
                captionTitle
                textColor={palette.text.secondary}
              >
                Employer Contribution
              </Text>
              {programState.active.employerTransitContribution !==
              programState.nextMonth.employerTransitContribution ? (
                <ProgramChange
                  metric={'Transit'}
                  icon={ProgramUtils.optionsCopy['transit'].icon}
                  value={
                    activeOptions.nextMonth['transit']
                      ? `${FormattingUtils.formatDollar(
                          programState.active.employerTransitContribution,
                          0
                        )} ➞ ${FormattingUtils.formatDollar(
                          programState.nextMonth.employerTransitContribution,
                          0
                        )}`
                      : 'Disabled'
                  }
                />
              ) : null}
              {programState.active.employerParkingContribution !==
              programState.nextMonth.employerParkingContribution ? (
                <ProgramChange
                  metric={'Parking'}
                  icon={ProgramUtils.optionsCopy['parking'].icon}
                  value={
                    activeOptions.nextMonth['parking']
                      ? `${FormattingUtils.formatDollar(
                          programState.active.employerParkingContribution,
                          0
                        )} ➞ ${FormattingUtils.formatDollar(
                          programState.nextMonth.employerParkingContribution,
                          0
                        )}`
                      : 'Disabled'
                  }
                />
              ) : null}
            </>
          ) : null}
        </Container>
      ) : null}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFManagePending.displayName = 'QTFManagePending'
}

export default QTFManagePending
