import React from 'react'
import { OptIn } from 'redux/optin/optInTypes'
import styled from 'styled-components'
import Text from 'core-system/Text'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import MyCommutersIcon from 'core-system/Icons/Sidebar/MyCommuters'
import palette from 'core-system/Themes/palette'
import Divider from 'core-system/Divider'

const HeaderContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: ${(props) =>
    props.isMobile ? 'flex-start' : 'space-between'};
  align-items: ${(props) => (props.isMobile ? 'flex-start' : 'center')};
  gap: ${(props) => (props.isMobile ? '1rem' : '0')};
`

const ExtraInfoContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  gap: 1rem;
  align-items: center;
`

const SegmentNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

interface OptInQuestionHeaderProps {
  isMobile: boolean
  question: OptIn
}

const OptInQuestionHeader = React.memo((props: OptInQuestionHeaderProps) => {
  const { isMobile, question } = props

  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  return (
    <HeaderContainer isMobile={isMobile}>
      <Text variant='h2'>{question.question}</Text>
      <ExtraInfoContainer isMobile={isMobile}>
        {/* SEGMENT NAME */}
        <SegmentNameContainer>
          <MyCommutersIcon color={palette.primary.primaryDark} />
          <Text variant='h4' hideOverflow>
            {segmentsMap[question.segment]?.name}
          </Text>
        </SegmentNameContainer>
        {isMobile ? null : <Divider direction='vertical' size='2rem' />}
        {/* # RESPONSES */}
        <Text variant='h4'>
          {`${question.employeeChoices.length} / ${
            segmentsMap[question.segment]?.commuters
          } Responded`}
        </Text>
      </ExtraInfoContainer>
    </HeaderContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OptInQuestionHeader.displayName = 'OptInQuestionHeader'
}

export default OptInQuestionHeader
