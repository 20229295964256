import * as Colors from '../colors'
import fade from '../utils/fade'

interface Primary {
  primaryPurple: string
  primaryDark: string
}

interface Text {
  primary: string
  secondary: string
  placeholder: string
  disabled: string
}

interface Grey {
  grey1: string
  grey2: string
  grey3: string
  grey4: string
  grey10: string
  overlay: string
}

interface Secondary {
  purple1: string
  purple2: string
  purple3: string
  purple4: string
  purple5: string
  purple6: string
  purple7: string
  lavender1: string
  lavender2: string
  green1: string
  green2: string
  green3: string
  red1: string
  red2: string
  maroon1: string
  maroon2: string
  maroon3: string
  magenta3: string
  magenta4: string
}

interface Chips {
  yellow1: string
  yellow2: string
  teal1: string
  teal2: string
  blue1: string
  blue2: string
  magenta1: string
  magenta2: string
  purple1: string
  purple2: string
  grey1: string
  grey2: string
}

interface Leaderboards {
  bronzeBackground: string
  bronzeText: string
  silverBackground: string
  silverText: string
  goldBackground: string
  goldText: string
}

export interface Palette {
  fade(color: string, alpha?: number): string
  primary: Primary
  text: Text
  grey: Grey
  secondary: Secondary
  chips: Chips
  white: string
  black: string
  transparent: string
  leaderboards: Leaderboards
}

const palette: Palette = {
  fade,
  primary: {
    primaryPurple: Colors.Indigo.One,
    primaryDark: Colors.Indigo.Two,
  },
  text: {
    primary: Colors.Indigo.Three,
    secondary: Colors.Indigo.Four,
    placeholder: Colors.Grey.Five,
    disabled: Colors.Grey.Six,
  },
  grey: {
    grey1: Colors.Grey.One,
    grey2: Colors.Grey.Two,
    grey3: Colors.Grey.Three,
    grey4: Colors.Grey.Four,
    grey10: Colors.Grey.Ten,
    overlay: fade(Colors.Grey.Seven, 0.22),
  },
  white: '#ffffff',
  black: '#000000',
  transparent: 'transparent',
  secondary: {
    purple1: Colors.Purple.One,
    purple2: Colors.Purple.Two,
    purple3: Colors.Purple.Three,
    purple4: Colors.Purple.Four,
    purple5: Colors.Purple.Five,
    purple6: Colors.Purple.Six,
    purple7: Colors.Purple.Seven,
    lavender1: Colors.Lavender.One,
    lavender2: Colors.Lavender.Two,
    green1: Colors.Green.One,
    green2: Colors.Green.Two,
    green3: Colors.Green.Three,
    red1: Colors.Red.One,
    red2: Colors.Red.Two,
    maroon1: Colors.Maroon.One,
    maroon2: Colors.Maroon.Two,
    maroon3: Colors.Maroon.Three,
    magenta3: Colors.Mangenta.Three,
    magenta4: Colors.Mangenta.Four,
  },
  chips: {
    yellow1: Colors.Yellow.One,
    yellow2: Colors.Yellow.Two,
    teal1: Colors.Teal.One,
    teal2: Colors.Teal.Two,
    blue1: Colors.Blue.One,
    blue2: Colors.Blue.Two,
    magenta1: Colors.Mangenta.One,
    magenta2: Colors.Mangenta.Two,
    purple1: Colors.Indigo.One,
    purple2: Colors.Purple.Five,
    grey1: Colors.Grey.Eight,
    grey2: Colors.Grey.Nine,
  },
  leaderboards: {
    bronzeBackground: '#F6C892',
    bronzeText: '#733E00',
    silverBackground: Colors.Grey.Three,
    silverText: Colors.Indigo.Four,
    goldBackground: Colors.Yellow.Two,
    goldText: Colors.Yellow.One,
  },
}

export default palette
