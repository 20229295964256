import Button from 'core-system/Button'
// import Divider from 'core-system/Divider'
import Dropdown from 'core-system/Dropdown'
// import FlexContainer from 'core-system/FlexContainer'
// import {
//   Car as CarIcon,
//   Rideshare as RideshareIcon,
// } from 'core-system/Icons/modeTypes'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import SegmentDropdownUtils from 'core-system/SegmentDropdown/SegmentDropdownUtils'
// import SwitchToggle from 'core-system/SwitchToggle'
// import Text from 'core-system/Text'
// import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
// import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
// import styled from 'styled-components'

// TODO: Change this so that it's unique to OTP
// const programOptions = [
//   {
//     icon: (
//       <RideshareIcon width={32} height={32} color={palette.text.secondary} />
//     ),
//     title: 'Rideshare',
//     subtitle:
//       'Allow employees to use their budget on any rideshare provider. Fleet automatically saves IRS qualified digital receipts.',
//     type: 'rideshare',
//   },
//   {
//     icon: <CarIcon width={36} height={36} color={palette.text.secondary} />,
//     title: 'Local Taxi',
//     subtitle:
//       'Allow employees to use their budget to pay for local taxis. Your employees will be able to submit their receipts.',
//     type: 'localTaxi',
//   },
// ]

const defaultActiveOptions = {
  rideshare: true,
  localTaxi: true,
}

// const OptionText = styled.div`
//   margin-left: 1rem;
// `

// const IconContainer = styled.div`
//   min-width: 4rem;
//   height: 4rem;
//   background-color: ${(props) => props.theme.palette.chips.purple2};
//   border-radius: ${(props) => props.theme.pxToRem(13)};
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

interface OTPPreviewModalProps {
  open: boolean
  closeModal: () => void
  segmentId?: string
}

const OTPPreviewModal = React.memo((props: OTPPreviewModalProps) => {
  const { open, closeModal, segmentId } = props
  const navigate = useNavigate()
  const location = useLocation()

  const allSegments = useSelector(
    (state: AppState) => state.employer.allSegments
  )
  const allPrograms = useSelector((state: AppState) => state.otp.otpPrograms)

  const [currentSegment, setCurrentSegment] = useState(null)
  // const [activeOptions, setActiveOptions] = useState(defaultActiveOptions)
  const activeOptions = useState(defaultActiveOptions)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setCurrentSegment(segmentId)
  }, [segmentId])

  // const handleOptionChange = (option: string) => {
  //   setActiveOptions({
  //     ...activeOptions,
  //     [option]: !activeOptions[option],
  //   })
  //   SegmentService.track('program-option-toggle', {
  //     [option]: !activeOptions[option],
  //   })
  // }

  const handleNext = () => {
    if (!currentSegment) {
      setHasError(true)
    } else {
      navigate(`/one-time-purchase/${currentSegment}/activation`, {
        state: {
          from: location.pathname,
          activeOptions,
          currentSegment,
        },
      })
      closeModal()
    }
  }

  return (
    <Modal open={open} onClose={closeModal} width={pxToRem(826)}>
      <ModalHeader title='New One Time Purchase Program' />
      <ModalBody padding='1.5rem 3.25rem 3.25rem'>
        <Dropdown
          items={SegmentDropdownUtils.segmentDropdownItems(
            allSegments || [],
            allPrograms || []
          )}
          active={currentSegment}
          itemCallback={({ id }) => setCurrentSegment(id)}
          width='100%'
          label='Select a Group of Commuters:'
          placeholder={!currentSegment ? '--' : null}
          error={hasError && !currentSegment}
          errorMsg='Select a Valid Group'
          usePortal
        />
        {/* TODO: To be added in the future */}
        {/* <Divider margin='1.5rem 0' /> */}
        {/* <Text
          variant='body1'
          marginBottom='1rem'
          textColor={palette.text.secondary}
        >
          Select the options you would like to offer employees as part of your
          One Time Purchase program:
        </Text>
        {programOptions.map((option, idx) => (
          <React.Fragment key={option.type}>
            {idx !== 0 && <Divider margin='1.5rem 0' />}
            <FlexContainer alignItems='center' width='100%'>
              <IconContainer>{option.icon}</IconContainer>
              <OptionText>
                <Text variant='action4' marginBottom='0.5rem'>
                  {option.title}
                </Text>
                <Text variant='body1' marginRight='1rem'>
                  {option.subtitle}
                </Text>
              </OptionText>
              <SwitchToggle
                marginLeft='auto'
                active={activeOptions[option.type]}
                onClick={() => handleOptionChange(option.type)}
              />
            </FlexContainer>
          </React.Fragment>
        ))}
      */}
      </ModalBody>
      <ModalFooter>
        <Button variant='tertiary' onClick={closeModal}>
          Cancel
        </Button>
        <Button marginLeft='1rem' onClick={handleNext}>
          Next
        </Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OTPPreviewModal.displayName = 'OTPPreviewModal'
}

export default OTPPreviewModal
