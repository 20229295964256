import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  OptIn,
  OptInCreatePayload,
  UpdateEmployeeOptInsData,
} from './optInTypes'

export interface OptInState {
  optIns: { [segmentId: string]: OptIn[] } | null
}

export const initialState: OptInState = {
  optIns: null,
}

const getAllOptIns = createAction('optIn/getAllOptIns')
const createOptIn = createAction<OptInCreatePayload>('optIn/createOptIn')
const updateEmployeeOptIns = createAction<UpdateEmployeeOptInsData>(
  'optIn/updateEmployeeOptIns'
)

const optInSlice = createSlice({
  name: 'optIn',
  initialState,
  reducers: {
    getAllOptInsSuccess(state, action: PayloadAction<OptIn[]>) {
      if (!action.payload || action.payload.length === 0) {
        state.optIns = {}
      } else {
        state.optIns = action.payload.reduce((optIns, optIn) => {
          if (!optIns[optIn.segment]) {
            optIns[optIn.segment] = [optIn]
          } else {
            optIns[optIn.segment].push(optIn)
          }
          return optIns
        }, {})
      }
    },
    createOptInSuccess(state, action: PayloadAction<OptIn>) {
      if (!state.optIns) {
        state.optIns = { [action.payload.segment]: [action.payload] }
      } else if (!state.optIns[action.payload.segment]) {
        state.optIns[action.payload.segment] = [action.payload]
      } else {
        state.optIns[action.payload.segment].push(action.payload)
      }
    },
    updateEmployeeOptInsSuccess() {
      // DO NOTHING RE-FETCH OPT-INS IN SAGA
    },
  },
})

export const optInReducer = optInSlice.reducer
export const optInActions = {
  ...optInSlice.actions,
  getAllOptIns,
  createOptIn,
  updateEmployeeOptIns,
}
