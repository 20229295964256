import { ColorProps as StyledColorProps, system } from 'styled-system'

export type ColorProps = {
  bg?: StyledColorProps['bg']
  backgroundColor?: StyledColorProps['backgroundColor']
  textColor?: StyledColorProps['color']
}

export const color = system({
  textColor: {
    property: 'color',
    scale: 'colors',
  },
  bg: {
    property: 'backgroundColor',
    scale: 'colors',
  },
  backgroundColor: {
    property: 'backgroundColor',
    scale: 'colors',
  },
  opacity: true,
})
