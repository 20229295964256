import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import styled from 'styled-components'

const PurpleContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  width: 100%;
  display: flex;
`

const ChartContainer = styled.div`
  height: ${(props) => props.theme.pxToRem(52)};
  width: ${(props) => props.theme.pxToRem(52)};
  margin-right: 1rem;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  height: ${(props) => props.theme.pxToRem(52)};
  width: ${(props) => props.theme.pxToRem(52)};
  margin-right: 1rem;
  border-radius: 50%;
  padding: 0.625px;
  background-color: ${(props) => props.theme.palette.secondary.purple5};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.primary.primaryPurple};
`

interface ProgramPriceCoverageProps {
  percentage: number
  title: string
  description: string
  children?: React.ReactNode
  icon?: React.ReactNode
}

const ProgramPriceCoverage = React.memo((props: ProgramPriceCoverageProps) => {
  const { icon, percentage, title, description, children } = props
  return (
    <PurpleContainer>
      {icon && <IconContainer>{icon}</IconContainer>}
      {!icon && (
        <ChartContainer>
          <CircularProgressbarWithChildren
            styles={buildStyles({
              strokeLinecap: 'round',
              pathColor: palette.primary.primaryPurple,
              trailColor: palette.text.disabled,
            })}
            strokeWidth={10}
            counterClockwise={true}
            value={percentage}
          >
            <Text variant='action3'>{percentage}%</Text>
          </CircularProgressbarWithChildren>
        </ChartContainer>
      )}
      <TextContainer>
        <Text variant='action4'>{title}</Text>
        <DescriptionContainer>
          <Text variant='body2' textColor={palette.text.secondary}>
            {description}
          </Text>
          {children && children}
        </DescriptionContainer>
      </TextContainer>
    </PurpleContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramPriceCoverage.displayName = 'ProgramPriceCoverage'
}

export default ProgramPriceCoverage
