import { leaderboardsActions } from 'redux/leaderboards/leaderboardsSlice'
import Banner from 'core-system/Banner'
import Button from 'core-system/Button'
import Chip, { statusMap } from 'core-system/Chip/Chip'
import ColumnView, { Column, ColumnCard } from 'core-system/ColumnView'
import AddIcon from 'core-system/Icons/Actions/Add'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import ProgramHeader from 'core-system/Program/ProgramHeader'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import MyCommuterDetailsModal from 'features/Segments/MyCommuters/MyCommuterDetailsModal'
import moment from 'moment'
import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import {
  Leaderboard,
  LeaderboardType,
} from 'redux/leaderboards/leaderboardsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import useQueryParam from 'shared/Hooks/useQueryParam'
import useResizeListener from 'shared/Hooks/useResizeListener'
import NumberUtils from 'shared/NumberUtils'
import styled, { css } from 'styled-components'
import LeaderboardList from './components/LeaderboardList'
import LeaderboardModal from './components/LeaderboardModal'
import LeaderboardUtils from './LeaderboardUtils'
import NoPaymentModal from 'shared/components/NoPaymentModal'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledColumnView = styled(ColumnView)`
  grid-template-columns: 33% 66%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    grid-gap: unset;
  }
`

const FiltersColumnCard = styled(ColumnCard)`
  padding: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    padding: 0.5rem 1rem;
    display: flex;
  }
`

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  width: 100%;
  flex: 1;
  min-width: ${(props) => props.theme.pxToRem(175)};
  background-color: ${(props) => props.theme.palette.secondary.purple7};
  border-radius: 0.9375rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    padding: 0.625rem 0.25rem 0.5rem;
    flex-direction: row;
    background-color: ${(props) => props.theme.palette.white};
    overflow-x: scroll;
    border-radius: unset;
    scrollbar-width: none;

    /* width */
    ::-webkit-scrollbar {
      height: 0.25rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      visibility: hidden;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      visibility: hidden;
    }

    &:hover {
      /* Track */
      ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.palette.grey.grey1};
        border-radius: 2rem;
        visibility: visible;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.palette.grey.grey2};
        border-radius: 1rem;
        opacity: 0.8;
        visibility: visible;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.palette.grey.grey4};
        opacity: 1;
      }
    }
  }
`

const Filter = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.75rem;
  cursor: pointer;
  border: 1px solid transparent;
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.palette.text.secondary};

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${(props) => props.theme.palette.white};
      box-shadow: ${(props) => props.theme.dropShadows.normal};
      border: 1px solid ${(props) => props.theme.palette.grey.grey3};
      color: ${(props) => props.theme.palette.text.primary};
    `}

  &:last-of-type {
    margin-bottom: unset;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.white};
    box-shadow: ${(props) => props.theme.dropShadows.selected};
    border: 1px solid ${(props) => props.theme.palette.grey.grey3};
    color: ${(props) => props.theme.palette.text.primary};
  }

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
`

const FilterText = styled.div`
  ${(props) => props.theme.typography.caption}
  text-transform: uppercase;
  margin: 0 0.5rem 0 1rem;
  letter-spacing: 0.7px;
  white-space: nowrap;
`

const ButtonContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    border-left: 1px solid ${(props) => props.theme.palette.grey.grey3};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin: 1.5rem 0 0 0;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    width: unset;
    margin: 0 0 0 1.5rem;
  }
`

const LeaderboardCard = styled(ColumnCard)`
  padding: 1.5rem;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const Metrics = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 40%;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Metric = styled.div`
  border-right: 1px solid ${(props) => props.theme.palette.grey.grey3};
  margin-right: 2rem;

  &:last-of-type {
    border-right: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    border-right: none;
    text-align: center;
    margin-bottom: 1rem;
  }
`

const MetricTitle = styled.div`
  ${(props) => props.theme.typography.caption}
  color: ${(props) => props.theme.palette.text.secondary};
  text-transform: uppercase;
  letter-spacing: 0.7px;
  white-space: nowrap;
`

const MetricText = styled.div`
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
`

const BonusContainer = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-right: 1rem;
  }
`

const Bonuses = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
  height: 2rem;
`

const Bonus = styled.div`
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.secondary.purple5};
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const renderLeaderboardFilters = (
  leaderboards: Leaderboard[],
  needsResize: boolean,
  activeFilter: number,
  setActiveFilter: (activeFilter: number) => void
) => {
  return leaderboards.map((leaderboard: Leaderboard, idx: number) => {
    return (
      <Filter
        key={leaderboard.type}
        onClick={() => setActiveFilter(idx)}
        isActive={activeFilter === idx}
      >
        {LeaderboardUtils.leaderboardData[leaderboard.type].icon}
        <FilterText>
          {needsResize &&
          LeaderboardUtils.leaderboardData[leaderboard.type].smallTitle
            ? LeaderboardUtils.leaderboardData[leaderboard.type].smallTitle
            : LeaderboardUtils.leaderboardData[leaderboard.type].title}
        </FilterText>
      </Filter>
    )
  })
}

const LeaderboardsView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { leaderboardsMap, rankings, summaryStats } = useSelector(
    (state: AppState) => state.leaderboards
  )
  const { segmentsMap, hasValidPaymentMethod, profile } = useSelector(
    (state: AppState) => state.employer
  )

  const [activeFilter, setActiveFilter] = useState(0)
  const [needsResize, setNeedsResize] = useState(window.innerWidth <= 1200)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)
  const [activeEmployeeId, setActiveEmployeeId] = useState(null)
  useResizeListener(() => setNeedsResize(window.innerWidth <= 1200), [])

  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM'))

  const handleDateChange = (isIncrease: boolean, date: string) => {
    const currentMoment = moment(date)
    if (isIncrease) {
      const newDate = currentMoment.add(1, 'month')
      setCurrentDate(newDate.format('YYYY-MM'))
    } else {
      const newDate = currentMoment.subtract(1, 'month')
      setCurrentDate(newDate.format('YYYY-MM'))
    }
  }

  useLayoutEffect(() => {
    if (leaderboardsMap && leaderboardsMap[segmentId]) {
      const leaderboards = LeaderboardUtils.getActiveLeaderboards(
        leaderboardsMap[segmentId].programGroups
      )
      dispatch(
        leaderboardsActions.getLeaderboardRankings({
          familyId: leaderboards[activeFilter].familyId,
          queryType: leaderboards[activeFilter].type,
          month: currentDate,
        })
      )
    }
  }, [segmentId, leaderboardsMap, activeFilter, currentDate, dispatch])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    SegmentService.track('leaderboard-manage-action', {
      action: 'close-add-modal',
      locationAt: `leaderboards-${segmentsMap[segmentId].name}`,
    })
  }, [segmentsMap, segmentId])

  const handleAddClick = useCallback(() => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      setIsModalOpen(true)
      SegmentService.track('leaderboard-manage-action', {
        action: 'open-add-modal',
        locationAt: `leaderboards-${segmentsMap[segmentId].name}`,
      })
    } else {
      setIsNoPaymentModalOpen(true)
    }
  }, [hasValidPaymentMethod, profile, segmentId, segmentsMap])

  const handleEditClick = useCallback(
    (leaderboardType: LeaderboardType) => {
      navigate(
        `/leaderboards/${segmentId}/${leaderboardType.toLowerCase()}/manage`,
        {
          state: {
            from: '/leaderboards',
          },
        }
      )
      SegmentService.track('leaderboard-manage-action', {
        action: 'edit',
        boardType: leaderboardType.toLowerCase(),
        locationAt: `leaderboards-${segmentsMap[segmentId].name}`,
      })
    },
    [navigate, segmentsMap, segmentId]
  )

  if (!leaderboardsMap) {
    return <Loading fullPage />
  }

  if (!leaderboardsMap[segmentId]) {
    return (
      <>
        <ProgramHeader segmentId={segmentId} />
        <Banner
          variant='cta'
          title='Set Up Leaderboards Today!'
          description={`Empower ${
            segmentsMap[segmentId].name === 'All Employees'
              ? 'All Commuters'
              : segmentsMap[segmentId].name
          } to compete for monthly prizes while incentivizing smart commuter choices.`}
          cta='Add a Competition'
          ctaCallback={handleAddClick}
          background='bannerLeaderboards'
        />
        <LeaderboardModal
          open={isModalOpen}
          closeModal={closeModal}
          segmentId={segmentId}
        />
        <NoPaymentModal
          open={isNoPaymentModalOpen}
          closeModal={() => setIsNoPaymentModalOpen(false)}
          product='competition'
        />
      </>
    )
  }

  const leaderboards = LeaderboardUtils.getActiveLeaderboards(
    leaderboardsMap[segmentId].programGroups
  )
  const activeLeaderboard =
    LeaderboardUtils.leaderboardData[leaderboards[activeFilter].type]
  const isPending = leaderboards[activeFilter].status === 'PENDING'
  const noBonuses =
    leaderboards[activeFilter].bonuses.filter((bonus) => bonus === 0).length ===
    3

  return (
    <>
      <Container>
        <ProgramHeader
          segmentId={segmentId}
          currentDate={currentDate}
          onIncreaseClick={() => handleDateChange(true, currentDate)}
          onDecreaseClick={() => handleDateChange(false, currentDate)}
        />
        <StyledColumnView>
          <Column>
            <FiltersColumnCard>
              <Filters>
                {renderLeaderboardFilters(
                  leaderboards,
                  needsResize,
                  activeFilter,
                  setActiveFilter
                )}
              </Filters>
              <ButtonContainer>
                <StyledButton
                  onClick={() => {
                    setIsModalOpen(true)
                    SegmentService.track('leaderboard-manage-action', {
                      action: 'open-add-modal',
                      locationAt: `leaderboards-${segmentsMap[segmentId].name}`,
                    })
                  }}
                  iconLeft={<AddIcon />}
                >
                  {needsResize ? 'Add' : 'Add New Competition'}
                </StyledButton>
              </ButtonContainer>
            </FiltersColumnCard>
          </Column>
          <Column>
            <LeaderboardCard>
              <Header>
                <Text variant='h5' marginRight='1rem'>
                  {activeLeaderboard.title}
                </Text>
                <Chip
                  variant={statusMap[leaderboards[activeFilter].status].variant}
                >
                  {statusMap[leaderboards[activeFilter].status].text}
                </Chip>
                <Button
                  marginLeft='auto'
                  size='small'
                  onClick={() =>
                    handleEditClick(leaderboards[activeFilter].type)
                  }
                >
                  Manage Competition
                </Button>
              </Header>
              {!summaryStats?.[leaderboards[activeFilter].type] || !rankings ? (
                <Loading isCard={false} />
              ) : (
                <>
                  <Metrics>
                    <Metric>
                      <MetricTitle>Total Miles Recorded</MetricTitle>
                      <MetricText>
                        <PlatformSvg folder='metrics' variant='graphs' />
                        <Text variant='action1' margin='0.1875rem 0 0 1rem'>
                          {isPending
                            ? 0
                            : NumberUtils.formatNumber(
                                (summaryStats[leaderboards[activeFilter].type]
                                  .totalDistance || 0) / 1609,
                                0
                              )}{' '}
                          miles
                        </Text>
                      </MetricText>
                    </Metric>
                    <Metric>
                      <MetricTitle>Total CO2 Saved</MetricTitle>
                      <MetricText>
                        <PlatformSvg folder='metrics' variant='co2' />
                        <Text variant='action1' margin='0.1875rem 0 0 1rem'>
                          {isPending
                            ? 0
                            : NumberUtils.formatNumber(
                                (summaryStats[leaderboards[activeFilter].type]
                                  .totalEnvSavings || 0) / 454,
                                0
                              )}{' '}
                          lbs
                        </Text>
                      </MetricText>
                    </Metric>
                    <Metric>
                      <MetricTitle>Monthly Bonus Rewards</MetricTitle>
                      {!noBonuses ? (
                        <Bonuses>
                          {leaderboards[activeFilter].bonuses.map(
                            (bonus: number, idx: number) => (
                              <BonusContainer key={idx}>
                                <Bonus>
                                  <Text
                                    variant='caption'
                                    textColor={palette.text.secondary}
                                  >
                                    {idx + 1}
                                  </Text>
                                </Bonus>
                                <Text variant='action1'>
                                  {FormattingUtils.formatDollar(bonus, 0)}
                                </Text>
                              </BonusContainer>
                            )
                          )}
                        </Bonuses>
                      ) : (
                        <Button
                          size='small'
                          iconLeft={<AddIcon />}
                          marginTop='0.25rem'
                          onClick={() =>
                            handleEditClick(leaderboards[activeFilter].type)
                          }
                        >
                          Add Bonus Rewards
                        </Button>
                      )}
                    </Metric>
                  </Metrics>
                  {rankings[leaderboards[activeFilter].type] && (
                    <LeaderboardList
                      date={currentDate}
                      leaderboard={leaderboards[activeFilter]}
                      rankings={rankings[leaderboards[activeFilter].type]}
                      onEmployeeClick={(id: string) => {
                        setActiveEmployeeId(id)
                      }}
                    />
                  )}
                </>
              )}
            </LeaderboardCard>
          </Column>
        </StyledColumnView>
      </Container>
      <LeaderboardModal
        open={isModalOpen}
        closeModal={closeModal}
        segmentId={segmentId}
      />
      <MyCommuterDetailsModal
        employeeId={activeEmployeeId}
        open={activeEmployeeId && true}
        closeModal={() => setActiveEmployeeId(null)}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardsView.displayName = 'LeaderboardsView'
}

export default LeaderboardsView
