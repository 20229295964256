import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import Loading from 'core-system/Loading'
import { tripModeTypes } from '../TripsUtils'
import CloseIcon from 'core-system/Icons/Actions/Close'
import palette from 'core-system/Themes/palette'
import Text from 'core-system/Text'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import { CardListContainer } from 'employee-platform/shared/styles/CardList'

const ModeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  padding: 0.5rem 1rem;
`

const ModeIconTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`

const ModeIconContainer = styled.div`
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
`

const ModeNameTypeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
`

const DeleteIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.25rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple5};
  }
`

interface SelectedModesListProps {
  selectedModes: string[]
  setSelectedModes: (selectedModes: string[]) => void
}

const SelectedModesList = React.memo((props: SelectedModesListProps) => {
  const { selectedModes, setSelectedModes } = props

  const { allModes } = useSelector((state: AppState) => state.employeePlatform)

  if (!allModes) {
    return <Loading />
  }

  return (
    <CardListContainer maxHeight='15rem'>
      {!selectedModes || selectedModes.length === 0 ? (
        <NoDataText text='NO MODES ADDED' />
      ) : null}
      {selectedModes?.map((modeId) => {
        return (
          <ModeContainer key={modeId}>
            <ModeIconTextContainer>
              <ModeIconContainer>
                {allModes[modeId] && allModes[modeId].iconUrl ? (
                  <img
                    src={allModes[modeId].iconUrl}
                    height='100%'
                    width='100%'
                  />
                ) : (
                  tripModeTypes[allModes[modeId].modeType].icon
                )}
              </ModeIconContainer>
              <ModeNameTypeTextContainer>
                <Text variant='action3' hideOverflow>
                  {allModes[modeId].name}
                </Text>
                {allModes[modeId].name !== allModes[modeId].modeType ? (
                  <Text variant='body2' textColor={palette.text.placeholder}>
                    {allModes[modeId].modeType}
                  </Text>
                ) : null}
              </ModeNameTypeTextContainer>
            </ModeIconTextContainer>
            <DeleteIconContainer
              onClick={() =>
                setSelectedModes(
                  selectedModes.filter((mode) => mode !== modeId)
                )
              }
            >
              <CloseIcon />
            </DeleteIconContainer>
          </ModeContainer>
        )
      })}
    </CardListContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SelectedModesList.displayName = 'SelectedModesList'
}

export default SelectedModesList
