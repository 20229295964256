function getItem(key: string): Dictionary | string | boolean | number {
  const value = window.localStorage.getItem(key) || null
  return JSON.parse(value)
}

function setItem(
  key: string,
  value: Dictionary | string | boolean | number
): void {
  const stringValue = JSON.stringify(value)
  window.localStorage.setItem(key, stringValue)
}

function removeItem(key: string): void {
  window.localStorage.removeItem(key)
}

export default {
  getItem,
  setItem,
  removeItem,
}
