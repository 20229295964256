import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { MyCommuterDetails } from 'redux/employee/employeeTypes'
import ArchiveUtils from 'shared/ArchiveUtils'
import styled from 'styled-components'

const Container = styled(FlexContainer)`
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ContentWrapper = styled(FlexContainer)`
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
`

interface MyCommuterArchiveProps {
  commuterDetails: MyCommuterDetails
}

const MyCommuterArchive = React.memo((props: MyCommuterArchiveProps) => {
  const { commuterDetails } = props

  const numPrograms = commuterDetails.programData.length || 0
  const numSegments = commuterDetails.segmentsEnrolled.length || 0
  const numIncentives = commuterDetails.incentiveData.length || 0
  const numCompetitions = commuterDetails.competitionData.length || 0

  const formattedArchiveMessage = ArchiveUtils.generateArchiveMessage(
    [
      [numSegments, 'segment'],
      [numPrograms, 'program'],
      [numCompetitions, 'competition'],
      [numIncentives, 'incentive program'],
    ],
    'They will be removed from',
    'All historic data will be preserved for reporting.',
    'This will also archive all related products. All historic data will be preserved for reporting.'
  )

  return (
    <Container>
      <ContentWrapper maxWidth={pxToRem(550)}>
        <PlatformSvg folder='archive' variant='archiveLogo' width='200px' />
        <Text variant='h3' marginTop='1rem'>
          Are you sure you want to archive this commuter?
        </Text>
        <Text variant='action2' marginTop='1rem'>
          {formattedArchiveMessage}
        </Text>
      </ContentWrapper>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommuterArchive.displayName = 'MyCommuterArchive'
}

export default MyCommuterArchive
