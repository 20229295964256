import React from 'react'
import styled from 'styled-components'
import palette from 'core-system/Themes/palette'
import RadialChart from 'core-system/charts/Radial'
import pxToRem from 'core-system/utils/pxToRem'
import Text from 'core-system/Text'
import Divider from 'core-system/Divider'
import MapPin from 'core-system/Icons/Misc/Location'
import { FeatureCollection } from 'geojson'
import RadialChartUtils from 'core-system/charts/Radial/RadialChartUtils'

const chartOptions = {
  ...RadialChartUtils.defaultChartOptions,
  legend: { display: false },
  cutoutPercentage: 60,
}

const legendLabels = [
  'micromobility first/last mile',
  'micromobility main mile',
  'others',
]

const Container = styled.div`
  ${(props) => props.theme.baseCard};
  padding: 1rem 1.25rem 1rem 1rem;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: unset;
`

const MetricsContainer = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.text.primary};
  height: 100%;
  width: 100%;
  max-width: ${(props) => props.theme.pxToRem(550)};
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
`

const ChartLegend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const CustomLegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.125rem;
`

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const LegendColorContainer = styled.div`
  min-width: 1.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CustomLegendColor = styled.div<{ background: string }>`
  background-color: ${(props) => props.background};
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
`

const CustomLegendRect = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.purple4};
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.125rem;
  border: 1px solid ${(props) => props.theme.palette.secondary.purple3};
`

const ModeText = styled(Text)`
  margin: 0.125rem 3rem 0 0.5rem;
  text-transform: capitalize;
`

const displayBarChartLegend = (
  colors: string[],
  labels: string[],
  data: number[]
) => {
  return labels.map((label: string, idx: number) => (
    <CustomLegendItem key={idx}>
      <LegendColorContainer>
        <CustomLegendColor background={colors[idx]} />
      </LegendColorContainer>
      <ModeText variant='action4'>{label}</ModeText>
      <Text variant='action4' marginLeft='auto'>
        {data[idx]}%
      </Text>
    </CustomLegendItem>
  ))
}

const getChartLabels = (employeeGroupings: Dictionary<number>) => {
  return [
    `First/Last Mile (${employeeGroupings.withinTransit})`,
    `Main Mile (${employeeGroupings.within900})`,
    `Others (${employeeGroupings.other})`,
  ]
}

interface MicromobilityMapMetricsProps {
  employeeData: FeatureCollection
}

const MicromobilityMapMetrics = React.memo(
  (props: MicromobilityMapMetricsProps) => {
    const { employeeData } = props

    const employeeGroupings: any = employeeData.features.reduce(
      (grouped, emp) => {
        if (emp.properties.bike900) {
          grouped['within900'] = ++grouped['within900'] || 1
        } else if (emp.properties.withinTransit) {
          grouped['withinTransit'] = ++grouped['withinTransit'] || 1
        } else {
          grouped['other'] = ++grouped['other'] || 1
        }
        return grouped
      },
      { within900: 0, withinTransit: 0, other: 0 }
    )
    const numEmployees = employeeData.features.length
    const withinTransit = Math.round(
      (employeeGroupings.withinTransit / numEmployees) * 100
    )
    const withinIso = Math.round(
      (employeeGroupings.within900 / numEmployees) * 100
    )
    const rest = 100 - (withinTransit + withinIso)

    const colors = [
      '#C30078',
      palette.primary.primaryPurple,
      palette.secondary.lavender2,
    ]
    const chartData = {
      datasets: [
        {
          data: [withinTransit, withinIso, rest],
          backgroundColor: colors,
          borderWidth: 0,
          hoverBackgroundColor: colors,
        },
      ],
      labels: getChartLabels(employeeGroupings),
    }

    return (
      <>
        {numEmployees > 0 ? (
          <Container>
            <MetricsContainer>
              <RadialChart
                width={pxToRem(140)}
                height={pxToRem(140)}
                data={chartData}
                options={chartOptions}
              />
              <Content>
                <Text variant='action3' marginBottom='0.5rem'>
                  Ideal Commuters for Micromobility Programs
                </Text>
                <ChartLegend>
                  <LegendContainer>
                    {displayBarChartLegend(
                      chartData.datasets[0].backgroundColor,
                      legendLabels,
                      chartData.datasets[0].data
                    )}
                  </LegendContainer>
                </ChartLegend>
                <Divider margin='0.5rem 0' />
                <CustomLegendItem>
                  <LegendColorContainer>
                    <MapPin width={20} height={20} />
                  </LegendColorContainer>
                  <ModeText variant='action4'>Worksites</ModeText>
                </CustomLegendItem>
                <CustomLegendItem>
                  <LegendColorContainer>
                    <CustomLegendRect />
                  </LegendColorContainer>
                  <ModeText variant='action4'>15-min Bike Range</ModeText>
                </CustomLegendItem>
              </Content>
            </MetricsContainer>
          </Container>
        ) : (
          <Container>
            <MetricsContainer>
              <Text variant='h4' textAlign='center' flex='1'>
                No Active Commuters
              </Text>
            </MetricsContainer>
          </Container>
        )}
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityMapMetrics.displayName = 'MicromobilityMapMetrics'
}

export default MicromobilityMapMetrics
