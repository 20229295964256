import Button from 'core-system/Button'
import Error from 'core-system/Error'
import FlexContainer from 'core-system/FlexContainer'
import AllMedalsIcon from 'core-system/Icons/Leaderboards/AllMedals'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import LeaderboardMedal from 'features/Leaderboards/components/LeaderboardMedal'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import { AppState } from 'redux/config/store'
import { ProgramType } from 'redux/programs/programsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'

const activeStatuses = ['ACTIVE', 'UPDATING', 'CANCELLING']
const typeMap = {
  MICROMOBILITY: {
    dashboardLeaderboardType: null,
    leaderboardType: 'MICROMOBILITY',
    CallToAction: 'Start A Micromobility Leaderboard',
    leaderboardTitle: 'Micromobility',
    metricTitle: 'Micromobility Trips',
    metric: 'Trips',
  },
  FLEX: {
    leaderboardType: 'ENV',
    dashboardLeaderboardType: null,
    CallToAction: 'Start An Env. Savings Leaderboard',
    leaderboardTitle: 'Environmental Savings',
    metricTitle: 'CO2 Saved',
    metric: 'lbs',
  },
}

const Container = styled.div`
  ${(props) => props.theme.baseCard}
`

const EmployeeContainer = styled(FlexContainer)`
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }
`

interface ProgramOverviewLeaderboardProps {
  type: ProgramType
  segmentId: string
}

const ProgramOverviewLeaderboard = React.memo(
  (props: ProgramOverviewLeaderboardProps) => {
    const { type, segmentId } = props

    const navigate = useNavigate()

    const { leaderboards, rankings } = useSelector(
      (state: AppState) => state.leaderboards
    )

    const products = new Set(
      useSelector((state: AppState) => state.employer.profile.products)
    )

    const activeLeaderboard =
      leaderboards.length > 0 &&
      leaderboards.find(
        (leaderboard) =>
          leaderboard.type === typeMap[type].leaderboardType &&
          leaderboard.segment === segmentId
      )

    if (!leaderboards || (activeLeaderboard && !rankings)) {
      return <Loading height={pxToRem(653)} />
    }

    const activeLeaderboardHasStarted =
      activeLeaderboard && activeStatuses.includes(activeLeaderboard.status)

    return (
      <Container>
        <Text variant='h5' marginBottom='1rem'>
          Leaderboard
        </Text>
        {(!activeLeaderboard || !products.has('COMPETITIONS')) && (
          <>
            <FlexContainer center>
              <AllMedalsIcon width={92} height={51} />
            </FlexContainer>
            <Text variant='body1' margin='1.5rem 0'>
              Reward commuters who go above and beyond expectations with
              additional bonuses.
            </Text>
            <Button
              size='small'
              width='100%'
              onClick={() => navigate(`/leaderboards/${segmentId}`)}
              disabled={!products.has('COMPETITIONS')}
            >
              {`${!products.has('COMPETITIONS') ? 'Contact Fleet to ' : ''}${
                typeMap[type].CallToAction
              }`}
            </Button>
          </>
        )}
        {activeLeaderboard && products.has('COMPETITIONS') && (
          <>
            {!activeLeaderboardHasStarted && (
              <>
                <FlexContainer center>
                  <AllMedalsIcon width={92} height={51} />
                </FlexContainer>
                <Text variant='body1' marginTop='1rem'>
                  Scheduled to start on{' '}
                  <strong>
                    {moment(activeLeaderboard.startDate).format(
                      'MMMM Do, YYYY'
                    )}
                  </strong>
                  .
                </Text>
              </>
            )}
            {!rankings[activeLeaderboard.type] && activeLeaderboardHasStarted && (
              <FlexContainer center flexDirection='column'>
                <FlexContainer center>
                  <AllMedalsIcon width={92} height={51} />
                </FlexContainer>
                <Text variant='body1' marginTop='1rem'>
                  No Trips Recorded Yet
                </Text>
              </FlexContainer>
            )}
            {rankings[activeLeaderboard.type] &&
              rankings[activeLeaderboard.type].length > 0 &&
              activeLeaderboardHasStarted && (
                <>
                  <Text variant='caption' captionTitle marginBottom='1.5rem'>
                    {typeMap[type].leaderboardTitle}
                  </Text>
                  {rankings[activeLeaderboard.type]
                    .slice(0, 5)
                    .map((employee, idx) => (
                      <EmployeeContainer key={idx} alignItems='center'>
                        <LeaderboardMedal
                          size={24}
                          position={idx + 1}
                          marginRight='1rem'
                        />
                        <img
                          alt={'commmuter'}
                          src={`${StaticAssetsUrl}/accounts/default-user-32.png`}
                        />
                        <FlexContainer flexDirection='column' marginLeft='1rem'>
                          <Text variant='action4'>{employee.name}</Text>
                          <Text variant='caption' captionTitle>
                            {FormattingUtils.formatNumber(employee.value, 0)}{' '}
                            {typeMap[type].metric}
                          </Text>
                        </FlexContainer>
                      </EmployeeContainer>
                    ))}
                </>
              )}
            {rankings[activeLeaderboard.type] &&
              rankings[activeLeaderboard.type].length === 0 && (
                <Error
                  margin='1rem 0 0 2rem'
                  description='No commuter engagement yet'
                  textVariant='body1'
                />
              )}
          </>
        )}
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramOverviewLeaderboard.displayName = 'ProgramOverviewLeaderboard'
}

export default ProgramOverviewLeaderboard
