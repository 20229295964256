import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M17.4628 10.6386C17.3105 10.1891 16.8838 9.86905 16.3809 9.86905H7.99999C7.49713 9.86905 7.07808 10.1891 6.91808 10.6386L5.33332 15.2024V21.2976C5.33332 21.7167 5.67618 22.0595 6.09523 22.0595H6.85713C7.27618 22.0595 7.61904 21.7167 7.61904 21.2976V20.5357H16.7619V21.2976C16.7619 21.7167 17.1048 22.0595 17.5238 22.0595H18.2857C18.7048 22.0595 19.0476 21.7167 19.0476 21.2976V15.2024L17.4628 10.6386ZM7.99999 18.25C7.36761 18.25 6.85713 17.7395 6.85713 17.1071C6.85713 16.4748 7.36761 15.9643 7.99999 15.9643C8.63237 15.9643 9.14285 16.4748 9.14285 17.1071C9.14285 17.7395 8.63237 18.25 7.99999 18.25ZM16.3809 18.25C15.7486 18.25 15.2381 17.7395 15.2381 17.1071C15.2381 16.4748 15.7486 15.9643 16.3809 15.9643C17.0133 15.9643 17.5238 16.4748 17.5238 17.1071C17.5238 17.7395 17.0133 18.25 16.3809 18.25ZM6.85713 14.4405L7.99999 11.0119H16.3809L17.5238 14.4405H6.85713Z' />
    <path d='M16.381 3.77381C16.381 4.61952 15.7029 5.29762 14.8571 5.29762C14.0114 5.29762 13.3333 4.61952 13.3333 3.77381C13.3333 2.9281 14.0114 2.25 14.8571 2.25C15.7029 2.25 16.381 2.9281 16.381 3.77381Z' />
    <path d='M2.66667 6.82143C3.51238 6.82143 4.19048 6.14333 4.19048 5.29762C4.19048 4.45191 3.51238 3.77381 2.66667 3.77381C1.82095 3.77381 1.14286 4.45191 1.14286 5.29762C1.14286 6.14333 1.82095 6.82143 2.66667 6.82143Z' />
    <path d='M4.19048 19.0119V13.6786H4.65722L5.33333 12.101V9.10714C5.33333 8.26905 4.64762 7.58333 3.80952 7.58333H1.52381C0.685714 7.58333 0 8.26905 0 9.10714V13.6786H1.14286V19.0119H4.19048Z' />
    <path d='M11.4286 8.53571H7.74049C7.20712 8.53571 6.72507 8.85358 6.51497 9.34382L6.09524 10.3232V7.58333C6.09524 6.74524 6.78095 6.05952 7.61905 6.05952H9.90476C10.7429 6.05952 11.4286 6.74524 11.4286 7.58333V8.53571Z' />
    <path d='M17.5238 8.90191C17.2813 8.67191 16.9553 8.53571 16.6059 8.53571H12.1905V7.58333C12.1905 6.74524 12.8762 6.05952 13.7143 6.05952H16C16.8381 6.05952 17.5238 6.74524 17.5238 7.58333V8.90191Z' />
    <path d='M19.3823 13.6786L18.6667 11.6748V9.10714C18.6667 8.26905 19.3524 7.58333 20.1905 7.58333H22.4762C23.3143 7.58333 24 8.26905 24 9.10714V13.6786H22.8571V19.0119H19.8095V13.6786H19.3823Z' />
    <path d='M10.2857 3.77381C10.2857 4.61952 9.60762 5.29762 8.7619 5.29762C7.91619 5.29762 7.2381 4.61952 7.2381 3.77381C7.2381 2.9281 7.91619 2.25 8.7619 2.25C9.60762 2.25 10.2857 2.9281 10.2857 3.77381Z' />
    <path d='M22.8571 5.29762C22.8571 6.14333 22.179 6.82143 21.3333 6.82143C20.4876 6.82143 19.8095 6.14333 19.8095 5.29762C19.8095 4.45191 20.4876 3.77381 21.3333 3.77381C22.179 3.77381 22.8571 4.45191 22.8571 5.29762Z' />
  </>,
  'Carpool'
)
