import { keyframes } from 'styled-components'

export const FadeInKeyFrame = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const FadeOutKeyFrame = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const FadeInDownOpenKeyFrame = keyframes`
  from {
    transform: translateY(-15%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const FadeInDownCloseKeyFrame = keyframes`
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(15%);
  }
`
