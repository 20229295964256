import React from 'react'
import PlatformSvg from 'core-system/Icons/PlatformSvg'

export const monthlyStatsUtils = {
  envSavings: {
    title: 'CO2 Saved',
    unit: 'lbs',
    type: 'env',
    icon: (
      <PlatformSvg folder='metrics' variant='leaf2' width={32} height={32} />
    ),
  },
  taxSavings: {
    title: 'Tax Savings',
    unit: '$',
    type: 'money',
    icon: (
      <PlatformSvg folder='metrics' variant='money2' width={32} height={32} />
    ),
  },
}
