import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M9.54565 9.66147H11.8587C13.1308 9.66147 14.3508 10.1942 15.2504 11.1426C16.1499 12.0909 16.6553 13.3771 16.6553 14.7183H9.19283L9.1939 15.842H17.7212V14.7183C17.7178 13.5226 17.3901 12.3528 16.7768 11.3471H19.853C20.8605 11.3468 21.8475 11.6476 22.6995 12.2146C23.5515 12.7817 24.2336 13.5917 24.6666 14.5508C22.1458 18.058 18.0644 20.3369 13.4575 20.3369C10.5146 20.3369 8.0214 19.6739 5.99617 18.5109V8.05566C7.29297 8.2515 8.51755 8.80551 9.54565 9.66147ZM4.93026 18.0895C4.93026 18.3875 4.81796 18.6733 4.61806 18.8841C4.41817 19.0948 4.14705 19.2132 3.86435 19.2132H1.73253C1.44984 19.2132 1.17872 19.0948 0.978823 18.8841C0.778927 18.6733 0.666626 18.3875 0.666626 18.0895V7.97587C0.666626 7.67784 0.778927 7.39202 0.978823 7.18128C1.17872 6.97054 1.44984 6.85214 1.73253 6.85214H3.86435C4.14705 6.85214 4.41817 6.97054 4.61806 7.18128C4.81796 7.39202 4.93026 7.67784 4.93026 7.97587V18.0895Z' />
    <path d='M14.1273 2.66784L21.2135 2.66784V4.66346L19.9515 4.66346V6.65907L16.7962 6.65954L16.7962 4.66393L14.1277 4.66393C13.9007 5.42127 13.4242 6.06804 12.7844 6.48739C12.1446 6.90673 11.3836 7.071 10.6389 6.9505C9.89425 6.83001 9.21501 6.4327 8.72393 5.83036C8.23285 5.22802 7.96231 4.46038 7.96118 3.66612C7.96058 2.87091 8.2302 2.10179 8.72119 1.49811C9.21218 0.894443 9.89205 0.496169 10.6376 0.375466C11.3831 0.254763 12.145 0.419616 12.7852 0.840153C13.4254 1.26069 13.9015 1.90908 14.1273 2.66784ZM11.1165 4.99606C11.2841 4.99913 11.4505 4.96699 11.6062 4.90151C11.7619 4.83602 11.9036 4.73852 12.0231 4.61467C12.1427 4.49083 12.2376 4.34313 12.3024 4.18019C12.3672 4.01725 12.4006 3.84233 12.4006 3.66565C12.4006 3.48896 12.3672 3.31405 12.3024 3.15111C12.2376 2.98817 12.1427 2.84047 12.0231 2.71662C11.9036 2.59278 11.7619 2.49527 11.6062 2.42979C11.4505 2.36431 11.2841 2.33217 11.1165 2.33524C10.7856 2.3413 10.4702 2.48413 10.2383 2.73297C10.0063 2.98181 9.8763 3.31675 9.8763 3.66565C9.8763 4.01454 10.0063 4.34948 10.2383 4.59832C10.4702 4.84716 10.7856 4.99 11.1165 4.99606Z' />
  </>,
  'OneTimePurchase',
  '0 0 25 21'
)
