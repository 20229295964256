import React, { useState } from 'react'
import styled from 'styled-components'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import EmployeeOptInResponses from './components/EmployeeOptInResponses'
import EmployeeOptInOpenQuestions from './components/EmployeeOptInOpenQuestions'
import Loading from 'core-system/Loading'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const EmployeeOptInOverviewView = React.memo(() => {
  const { allOptIns } = useSelector(
    (state: AppState) => state.employeePlatform.optIn
  )

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  if (!allOptIns) return <Loading />

  return (
    <Container>
      <GridContainer numColumns={2} customGridGap='2rem'>
        {/* Questions that require the user's response */}
        <EmployeeOptInOpenQuestions
          openQuestions={allOptIns.filter(
            (question) => !question.choice && question.choice !== 0
          )}
        />
        {/* Your responses */}
        <EmployeeOptInResponses
          isMobile={isMobile}
          questions={allOptIns.filter(
            (question) => question.choice || question.choice === 0
          )}
        />
      </GridContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeOptInOverviewView.displayName = 'EmployeeOptInOverviewView'
}

export default EmployeeOptInOverviewView
