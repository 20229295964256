import React, { useMemo } from 'react'
import styled from 'styled-components'
import Dropdown, { SelectItemProps } from 'core-system/Dropdown'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import moment from 'moment'

const currentYear = moment().year()
const allLeapYears = new Set(
  Array.from({ length: 200 }, (_, i) => currentYear - i).filter((year) =>
    moment([year]).isLeapYear()
  )
)
const days = Array.from({ length: 31 }, (_, i) => ({
  id: String(i + 1),
  text: String(i + 1),
}))
const monthLastDayMap = {
  '1': 31,
  '2': 28,
  '3': 31,
  '4': 30,
  '5': 31,
  '6': 30,
  '7': 31,
  '8': 31,
  '9': 30,
  '10': 31,
  '11': 30,
  '12': 31,
}
const months = [
  { id: '1', text: 'January' },
  { id: '2', text: 'February' },
  { id: '3', text: 'March' },
  { id: '4', text: 'April' },
  { id: '5', text: 'May' },
  { id: '6', text: 'June' },
  { id: '7', text: 'July' },
  { id: '8', text: 'August' },
  { id: '9', text: 'September' },
  { id: '10', text: 'October' },
  { id: '11', text: 'November' },
  { id: '12', text: 'December' },
]
const years = Array.from({ length: 200 }, (_, i) => ({
  id: String(currentYear - i),
  text: String(currentYear - i),
}))
const futureYears = Array.from({ length: 200 }, (_, i) => ({
  id: String(currentYear + i),
  text: String(currentYear + i),
}))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const InputContainer = styled.div<{ isMobile?: boolean }>`
  display: grid;
  grid-template-columns: 25% ${(props) =>
      props.isMobile ? '40% 30%' : '48% 25%'};
  grid-gap: 0.5rem;
`

type DayMonthYearDate = {
  day: string | null
  month: string | null
  year: string | null
}

interface DayMonthYearInputProps {
  date: DayMonthYearDate
  setDate: (date: DayMonthYearDate) => void
  label?: string
  errorMessage?: string | null
  isMobile?: boolean
  futureDates?: boolean
}

const DayMonthYearInput = React.memo((props: DayMonthYearInputProps) => {
  const {
    date,
    setDate,
    label,
    errorMessage,
    isMobile,
    futureDates = false,
  } = props

  const daysOfSelectedMonth = useMemo(() => {
    if (date?.month) {
      if (date?.month === '2') {
        return days.slice(
          0,
          !date?.year || allLeapYears.has(Number(date?.year))
            ? monthLastDayMap[date?.month] + 1
            : monthLastDayMap[date?.month]
        )
      } else {
        return days.slice(0, monthLastDayMap[date?.month])
      }
    }
    return days
  }, [date])

  return (
    <Container>
      {label ? (
        <Text variant='body2' textColor={palette.text.secondary}>
          {label}
        </Text>
      ) : null}
      <InputContainer isMobile={isMobile}>
        <Dropdown
          items={daysOfSelectedMonth as SelectItemProps[]}
          active={date?.day}
          itemCallback={(item) => setDate({ ...date, day: item.id })}
          width='100%'
          placeholder='Day'
          selectorMaxHeight='150px'
          usePortal
        />
        <Dropdown
          items={months as SelectItemProps[]}
          active={date?.month}
          itemCallback={(item) => setDate({ ...date, month: item.id })}
          width='100%'
          placeholder='Month'
          selectorMaxHeight='150px'
          usePortal
        />
        <Dropdown
          items={
            futureDates
              ? (futureYears as SelectItemProps[])
              : (years as SelectItemProps[])
          }
          active={date?.year}
          itemCallback={(item) => setDate({ ...date, year: item.id })}
          width='100%'
          placeholder='Year'
          selectorMaxHeight='150px'
          usePortal
        />
      </InputContainer>
      {errorMessage ? (
        <Text
          variant='body2'
          textColor={palette.secondary.red1}
          style={{ marginTop: '0.25rem' }}
        >
          {errorMessage}
        </Text>
      ) : null}
    </Container>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DayMonthYearInput.displayName = 'DayMonthYearInput'
}

export default DayMonthYearInput
