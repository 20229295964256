import * as React from 'react'
import ReactDOM from 'react-dom'
import { buildComponent } from '../utils/buildComponent'

export interface PortalProps {
  children?: React.ReactNode
  mountNode?: Element
}

export default buildComponent<PortalProps>((props) => {
  const mountNode = props.mountNode || document.body
  return ReactDOM.createPortal(props.children, mountNode)
}, 'Portal')
