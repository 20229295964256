import React, { useState } from 'react'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import CarpoolDashboardCard from '../components/CarpoolDashboardCard'
import ProfileDashboardCard from '../components/ProfileDashboardCard'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import WalletDashboardCard from '../components/WalletDashboardCard'
import PreTaxDashboardCard from '../components/PreTaxDashboardCard'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import SavingsStatsCard from '../components/SavingsStatsCard'

const EmployeeDashboardView = React.memo(() => {
  const { profileData, preTaxPrograms } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const showPreTaxCard =
    preTaxPrograms &&
    profileData?.finchEmploymentType !== 'contractor' &&
    Object.values(preTaxPrograms).some((program) => program !== null)

  return (
    <GridContainer numColumns={2} fitContentHeight>
      <GridContainer numColumns={1} fitContentHeight>
        <ProfileDashboardCard isMobile={isMobile} />
        <WalletDashboardCard isMobile={isMobile} />
      </GridContainer>
      <GridContainer numColumns={1}>
        <GridContainer numColumns={1} fitContentHeight>
          <SavingsStatsCard />
        </GridContainer>
        {profileData?.employerProducts?.includes('CARPOOL') ? (
          <GridContainer numColumns={1} fitContentHeight>
            <CarpoolDashboardCard isMobile={isMobile} />
          </GridContainer>
        ) : null}
        {profileData?.employerProducts?.includes('QTF') && showPreTaxCard ? (
          <GridContainer numColumns={1} fitContentHeight>
            <PreTaxDashboardCard />
          </GridContainer>
        ) : null}
      </GridContainer>
    </GridContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeDashboardView.displayName = 'EmployeeDashboardView'
}

export default EmployeeDashboardView
