import Button from 'core-system/Button'
import Dropdown from 'core-system/Dropdown'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import TextField from 'core-system/TextField'
import TextToggle from 'core-system/TextToggle'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { Worksite } from 'redux/employer/employerTypes'
import Validators from 'shared/Validators'
import styled from 'styled-components'
import { employeeActions } from 'redux/employee/employeeSlice'

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;

  > div {
    width: 49% !important;
    margin-bottom: 0.75rem;
    @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
      width: 100% !important;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    margin-bottom: 0;
  }
`

interface UsersInviteModalProps {
  allWorksites: Worksite[]
  closeModal: () => void
  open: boolean
}

const DEFAULT_STATE = {
  firstName: '',
  lastName: '',
  isAdmin: 1,
  email: '',
  errors: {
    firstName: false,
    lastName: false,
    email: false,
  },
}

const UsersInviteModal = React.memo((props: UsersInviteModalProps) => {
  const { closeModal, allWorksites, open } = props
  const [userState, setUserState] = useState(DEFAULT_STATE)
  const [worksiteId, setWorksiteId] = useState('no-worksite')
  const dispatch = useDispatch()
  const selectableLocations = useMemo(
    () => [
      ...allWorksites.map((wrk) => {
        return {
          id: wrk.id,
          text: wrk.alias,
        }
      }),
    ],
    [allWorksites]
  )

  const handleIsAdmin = useCallback(
    (id: number) => {
      setUserState({
        ...userState,
        isAdmin: id,
      })
    },
    [userState]
  )

  const locationValueChange = ({ id }) => {
    setWorksiteId(id)
  }

  const onChange = useCallback(
    (val: string | boolean | number, key: string) => {
      setUserState({
        ...userState,
        [key]: val,
        errors: {
          firstName: key === 'firstName' ? false : userState.errors.firstName,
          lastName: key === 'lastName' ? false : userState.errors.lastName,
          email: key === 'email' ? false : userState.errors.email,
        },
      })
    },
    [userState]
  )

  const inviteUser = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      const isValidEmail =
        userState.email !== '' && Validators.checkEmail(userState.email)
      if (
        userState.firstName !== '' &&
        userState.lastName !== '' &&
        isValidEmail
      ) {
        const newUser = {
          firstName: userState.firstName,
          lastName: userState.lastName,
          email: userState.email,
          isAdmin: userState.isAdmin === 0,
          worksiteId: worksiteId !== 'no-worksite' ? worksiteId : null,
        }
        dispatch(
          employeeActions.createEmployee({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            email: newUser.email,
            groups: [newUser.isAdmin ? 'HRadmins' : 'HRusers'],
            worksiteId: newUser.worksiteId,
          })
        )

        SegmentService.track('inviteUser-btn-sent', {
          email: newUser.email,
          role: userState.isAdmin === 0 ? 'HRadmins' : 'HRusers',
          worksite:
            worksiteId !== 'no-worksite'
              ? selectableLocations.filter(
                  (sl) => sl.id === newUser.worksiteId
                )[0].id
              : 'noWorksite',
        })

        setUserState(DEFAULT_STATE)
        closeModal()
      } else {
        setUserState({
          ...userState,
          errors: {
            firstName: userState.firstName === '',
            lastName: userState.lastName === '',
            email: !isValidEmail,
          },
        })
      }
    },
    [userState, worksiteId, dispatch, selectableLocations, closeModal]
  )

  return (
    <Modal open={open} onClose={() => closeModal()} width={pxToRem(450)}>
      <ModalHeader title={'Invite User'} />
      <ModalBody padding='1.5rem 3.25rem'>
        <NameContainer>
          <TextField
            label='First Name*'
            defaultValue={userState.firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e.currentTarget.value, 'firstName')
            }
            invalid={userState.errors.firstName}
            helpText='This is a required field.'
            width='100%'
            data-cy='accounts-first-name-input'
          />
          <TextField
            label='Last Name*'
            defaultValue={userState.lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e.currentTarget.value, 'lastName')
            }
            invalid={userState.errors.lastName}
            helpText='This is a required field.'
            data-cy='accounts-last-name-input'
          />
        </NameContainer>
        <TextField
          label='Email*'
          defaultValue={userState.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.currentTarget.value, 'email')
          }
          invalid={userState.errors.email}
          helpText='Please enter a valid email.'
          data-cy='accounts-email-input'
        />
        <TextToggle
          label='Role'
          options={['Admin Only', 'Admin & User']}
          toggled={userState.isAdmin}
          onClick={handleIsAdmin}
          fullWidth={true}
          variant='dark'
          size='large'
          marginBottom='1rem'
        />
        <Dropdown
          active={worksiteId}
          items={selectableLocations}
          label={'Worksite'}
          itemCallback={locationValueChange}
          usePortal={true}
          width={'100%'}
          marginBottom={'0.75rem'}
          data-cy='user-worksite-dropdown'
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={(e) => inviteUser(e)}>Invite</Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  UsersInviteModal.displayName = 'UsersInviteModal'
}

export default UsersInviteModal
