import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='40' height='40' rx='8' fill='#DBDBFF' />
    <path
      d='M18.3297 15.9104C19.4904 15.9104 20.4844 16.5835 20.9719 17.5544L23.034 16.523C22.1776 14.7586 20.3735 13.5381 18.2799 13.5381C17.5083 13.5381 16.7771 13.7071 16.1158 14.0048L17.105 16.1814C17.4793 16.0112 17.892 15.9104 18.3297 15.9104ZM31.3773 15.9104C32.2474 15.9104 33.0213 16.2917 33.5646 16.8878L35.2827 15.325C34.3142 14.2314 32.9033 13.5381 31.3275 13.5381C29.2067 13.5381 27.3824 14.7901 26.5396 16.5924L28.7032 17.6137C29.1788 16.6102 30.193 15.9104 31.3773 15.9104Z'
      fill='#BEB3FF'
    />
    <path
      d='M18.3297 14.7242C16.0369 14.7242 14.1782 16.5829 14.1782 18.8758C14.1782 21.1686 16.0369 23.0273 18.3297 23.0273C20.6226 23.0273 22.4812 21.1686 22.4812 18.8758C22.4812 16.5829 20.6232 14.7242 18.3297 14.7242ZM18.3297 21.8411C16.6923 21.8411 15.3644 20.5138 15.3644 18.8758C15.3644 17.2377 16.6923 15.9104 18.3297 15.9104C19.9672 15.9104 21.2951 17.2377 21.2951 18.8758C21.2951 20.5138 19.9678 21.8411 18.3297 21.8411ZM31.3773 14.7242C29.0851 14.7242 27.2258 16.5829 27.2258 18.8758C27.2258 21.1686 29.0851 23.0273 31.3773 23.0273C33.6708 23.0273 35.5289 21.1686 35.5289 18.8758C35.5289 16.5829 33.6708 14.7242 31.3773 14.7242ZM31.3773 21.8411C29.7399 21.8411 28.412 20.5138 28.412 18.8758C28.412 17.2377 29.7399 15.9104 31.3773 15.9104C33.0154 15.9104 34.3427 17.2377 34.3427 18.8758C34.3427 20.5138 33.0154 21.8411 31.3773 21.8411Z'
      fill='#16014A'
    />
    <path
      d='M31.9609 18.8292C31.958 18.7894 31.9485 18.7509 31.9372 18.7117C31.9259 18.6732 31.9135 18.637 31.8951 18.6014C31.8874 18.5872 31.8862 18.5706 31.8779 18.557L27.7264 12.0331C27.5502 11.7568 27.1843 11.6749 26.9074 11.8511C26.73 11.9638 26.644 12.1553 26.6434 12.351H21.2951C20.9677 12.351 20.702 12.6161 20.702 12.9441C20.702 13.2721 20.9677 13.5372 21.2951 13.5372H27.2258C27.2418 13.5372 27.2555 13.5289 27.2715 13.5277L30.2968 18.2824H24.8535C24.5262 18.2824 24.2605 18.5475 24.2605 18.8754C24.2605 19.2034 24.5262 19.4685 24.8535 19.4685H31.3779C31.4467 19.4685 31.5143 19.4519 31.5814 19.4276C31.6015 19.4199 31.6193 19.4116 31.6389 19.4021C31.6573 19.392 31.678 19.3878 31.6964 19.376C31.7142 19.3641 31.7249 19.3457 31.7409 19.3333C31.7735 19.3072 31.802 19.2805 31.8281 19.2485C31.8524 19.2194 31.872 19.1898 31.8898 19.1571C31.9076 19.1239 31.9224 19.0919 31.9342 19.0551C31.9467 19.016 31.9538 18.9768 31.958 18.9359C31.9603 18.9146 31.9704 18.8968 31.9704 18.8754C31.9704 18.8594 31.9621 18.8452 31.9609 18.8292Z'
      fill='#947BFF'
    />
    <path
      d='M26.9773 11.1658L25.258 15.9797L21.7417 11.9617C21.5258 11.7155 21.151 11.6895 20.9049 11.9059C20.6587 12.1218 20.6338 12.496 20.8491 12.7428L24.8014 17.259L24.2949 18.6765C24.1852 18.9843 24.3459 19.3241 24.6543 19.4338C24.7201 19.4575 24.7871 19.4688 24.8535 19.4688C25.0973 19.4688 25.3256 19.3176 25.4122 19.075L28.237 11.1658H26.9773Z'
      fill='#947BFF'
    />
    <path
      d='M18.3298 19.4688C18.2408 19.4688 18.1501 19.4486 18.0652 19.4065C17.7723 19.26 17.6536 18.9036 17.8001 18.6106L19.6161 14.9893C19.6262 14.9727 20.702 13.3311 20.702 11.7588C20.702 9.83608 19.3421 9.36103 19.3285 9.35628C19.0177 9.25249 18.8499 8.91681 18.9537 8.60604C19.0569 8.29527 19.3919 8.12565 19.7039 8.23122C19.7929 8.26088 21.8882 8.99095 21.8882 11.7588C21.8882 13.5784 20.7993 15.3422 20.6244 15.612L18.8606 19.1402C18.7568 19.349 18.5474 19.4688 18.3298 19.4688Z'
      fill='#947BFF'
    />
    <path
      d='M22.8442 9.77969C22.8122 9.77969 22.779 9.77732 22.7464 9.77139L19.1879 9.17831C18.8653 9.12434 18.6465 8.81891 18.7004 8.49569C18.7544 8.17305 19.0569 7.95421 19.3831 8.00818L22.9415 8.60125C23.2641 8.65522 23.483 8.96065 23.429 9.28388C23.3804 9.57389 23.1289 9.77969 22.8442 9.77969ZM29.8253 11.7588C29.8075 11.7588 29.7903 11.7576 29.7725 11.7552L26.4086 11.3353C26.188 11.308 26.0267 11.1135 26.0409 10.8917C26.0546 10.6699 26.2384 10.4973 26.4602 10.4973C26.4845 10.4973 28.8722 10.4866 30.0121 9.72691C30.1604 9.62786 30.3573 9.63379 30.5008 9.74233C30.6426 9.85204 30.7007 10.0395 30.6438 10.2097L30.2233 11.4711C30.1663 11.6449 30.0044 11.7588 29.8253 11.7588Z'
      fill='#16014A'
    />
    <path
      d='M26.0397 18.8757C26.0397 19.5305 25.5089 20.0619 24.8536 20.0619C24.1988 20.0619 23.6674 19.5305 23.6674 18.8757C23.6674 18.221 24.1988 17.6896 24.8536 17.6896C25.5089 17.6896 26.0397 18.221 26.0397 18.8757Z'
      fill='#5E5EAA'
    />
    <path
      d='M6.76478 31.2953C8.23873 31.2953 9.43361 30.1004 9.43361 28.6265C9.43361 27.1525 8.23873 25.9576 6.76478 25.9576C5.29082 25.9576 4.09595 27.1525 4.09595 28.6265C4.09595 30.1004 5.29082 31.2953 6.76478 31.2953Z'
      fill='#16014A'
    />
    <path
      d='M6.76475 30.4057C7.74739 30.4057 8.54397 29.6091 8.54397 28.6265C8.54397 27.6439 7.74739 26.8473 6.76475 26.8473C5.78212 26.8473 4.98553 27.6439 4.98553 28.6265C4.98553 29.6091 5.78212 30.4057 6.76475 30.4057Z'
      fill='#947BFF'
    />
    <path
      d='M22.7778 31.2953C24.2517 31.2953 25.4466 30.1004 25.4466 28.6265C25.4466 27.1525 24.2517 25.9576 22.7778 25.9576C21.3038 25.9576 20.1089 27.1525 20.1089 28.6265C20.1089 30.1004 21.3038 31.2953 22.7778 31.2953Z'
      fill='#16014A'
    />
    <path
      d='M22.7778 30.4057C23.7604 30.4057 24.557 29.6091 24.557 28.6265C24.557 27.6439 23.7604 26.8473 22.7778 26.8473C21.7951 26.8473 20.9985 27.6439 20.9985 28.6265C20.9985 29.6091 21.7951 30.4057 22.7778 30.4057Z'
      fill='#947BFF'
    />
    <path
      d='M22.7778 29.5162H10.9163C10.427 29.5162 10.0267 29.1158 10.0267 28.6265C10.0267 28.1373 10.427 27.7369 10.9163 27.7369H22.7778C23.267 27.7369 23.6674 28.1373 23.6674 28.6265C23.6674 29.1158 23.267 29.5162 22.7778 29.5162Z'
      fill='#947BFF'
    />
    <path
      d='M19.5229 28.7724C19.5212 28.7238 19.5158 28.6758 19.5158 28.6266C19.5158 26.8248 20.976 25.3646 22.7777 25.3646C23.4953 25.3646 24.1572 25.5995 24.6957 25.9921L25.2236 25.2775C24.5368 24.7751 23.6934 24.475 22.7771 24.475C20.4843 24.475 18.6256 26.3337 18.6256 28.6266C18.6256 28.687 18.6321 28.7458 18.6345 28.8057L19.5229 28.7724ZM7.43433 25.434C8.91404 25.7424 10.0255 27.0531 10.0266 28.6236L12.1024 28.6254C12.1018 26.0514 10.2793 23.9033 7.85422 23.4004C7.71663 24.0706 7.57192 24.7704 7.43433 25.434Z'
      fill='#947BFF'
    />
    <path
      d='M6.70255 28.9218C6.54301 28.888 6.44041 28.7297 6.47422 28.5701L10.0338 11.8229C10.0676 11.6634 10.226 11.5608 10.3855 11.5946C10.5451 11.6284 10.6477 11.7867 10.6139 11.9463L7.05424 28.6935C7.02044 28.853 6.86209 28.9556 6.70255 28.9218Z'
      fill='#5E5EAA'
    />
    <path
      d='M7.7273 25.5195L7.14728 25.3962C6.82821 25.3286 6.62241 25.0119 6.69061 24.6928L7.43018 21.212C7.49779 20.893 7.81449 20.6872 8.13356 20.7554L8.71359 20.8787C9.03266 20.9463 9.23846 21.263 9.17025 21.5821L8.43128 25.0623C8.36308 25.3813 8.04638 25.5871 7.7273 25.5195Z'
      fill='#947BFF'
    />
    <path
      d='M9.61155 12.5091L8.4509 12.2624C7.97229 12.1604 7.66389 11.6859 7.7659 11.2073C7.86732 10.7287 8.34237 10.4203 8.82098 10.5217L9.98103 10.7685C10.4596 10.8705 10.768 11.3449 10.666 11.8235C10.5646 12.3022 10.0896 12.6105 9.61155 12.5091Z'
      fill='#947BFF'
    />
    <path
      d='M10.5248 10.9381C10.0462 10.8361 9.57118 11.1445 9.46977 11.6231L8.66853 15.3938C8.60092 15.7129 8.80612 16.0296 9.12519 16.0972L9.70522 16.2206C10.0243 16.2882 10.341 16.083 10.4086 15.7639L11.2098 11.9932C11.3119 11.5146 11.0035 11.0401 10.5248 10.9381Z'
      fill='#947BFF'
    />
    <path
      d='M12.4612 13.1145L10.7212 12.7444C10.2426 12.6424 9.93416 12.168 10.0362 11.6894C10.1382 11.2108 10.6126 10.9024 11.0912 11.0044L12.8313 11.3744C13.3099 11.4765 13.6183 11.9509 13.5163 12.4295C13.4149 12.9081 12.9399 13.2165 12.4612 13.1145Z'
      fill='#947BFF'
    />
    <path
      d='M13.5276 12.4321C13.4256 12.9125 12.9529 13.2197 12.4725 13.1171C11.9921 13.0151 11.6849 12.5424 11.7875 12.0621C11.8895 11.5817 12.3622 11.2745 12.8426 11.3771C13.323 11.4797 13.6302 11.9512 13.5276 12.4321Z'
      fill='#BEB3FF'
    />
    <path
      d='M24.9662 26.0764C25.2086 26.0764 25.4051 25.8799 25.4051 25.6375C25.4051 25.3951 25.2086 25.1986 24.9662 25.1986C24.7238 25.1986 24.5273 25.3951 24.5273 25.6375C24.5273 25.8799 24.7238 26.0764 24.9662 26.0764Z'
      fill='#947BFF'
    />
  </>,
  'Micromobility',
  '0 0 40 40'
)
