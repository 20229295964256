import CSSProperties from '../@types/CSSProperties'
import pxToRem from '../utils/pxToRem'
import { Fonts } from '../Fonts'

export interface Font extends CSSProperties {
  fontFamily: string
  fontWeight: number
  fontSize: string
  lineHeight: number | string
  textDecoration?: string
  cursor?: string
}

export interface Typography {
  BaseFont: Font
  h1: Font
  h2: Font
  h3: Font
  h4: Font
  h5: Font
  body1: Font
  body2: Font
  action1: Font
  action2: Font
  action3: Font
  action4: Font
  action5: Font
  action6: Font
  caption: Font
  hyperlink: Font
}

const typography: Typography = {
  BaseFont: {
    fontFamily: Fonts.Hind.Name,
    fontWeight: Fonts.Hind.Weights.Regular,
    fontSize: '100%',
    lineHeight: 1.5,
  },
  h1: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Bold,
    fontSize: pxToRem(36),
    lineHeight: pxToRem(50.4),
  },
  h2: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Bold,
    fontSize: pxToRem(26),
    lineHeight: pxToRem(36.4),
  },
  h3: {
    fontFamily: Fonts.Montserrat.Name,
    fontWeight: Fonts.Montserrat.Weights.Semibold,
    fontSize: pxToRem(22),
    lineHeight: pxToRem(30.8),
  },
  h4: {
    fontFamily: Fonts.Montserrat.Name,
    fontWeight: Fonts.Montserrat.Weights.Semibold,
    fontSize: pxToRem(18),
    lineHeight: pxToRem(25.2),
  },
  h5: {
    fontFamily: Fonts.Hind.Name,
    fontWeight: Fonts.Hind.Weights.Bold,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22.4),
  },
  body1: {
    fontFamily: Fonts.Hind.Name,
    fontWeight: Fonts.Hind.Weights.Regular,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19.6),
  },
  body2: {
    fontFamily: Fonts.Hind.Name,
    fontWeight: Fonts.Hind.Weights.Regular,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16.8),
  },
  action1: {
    fontFamily: Fonts.Hind.Name,
    fontWeight: Fonts.Hind.Weights.Semibold,
    fontSize: pxToRem(18),
    lineHeight: pxToRem(25.2),
  },
  action2: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Medium,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22.4),
  },
  action3: {
    fontFamily: Fonts.Hind.Name,
    fontWeight: Fonts.Hind.Weights.Semibold,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19.6),
  },
  action4: {
    fontFamily: Fonts.Hind.Name,
    fontWeight: Fonts.Hind.Weights.Medium,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19.6),
  },
  action5: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Semibold,
    fontSize: pxToRem(18),
    lineHeight: pxToRem('auto'),
  },
  action6: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Regular,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(17),
  },
  caption: {
    fontFamily: Fonts.Hind.Name,
    fontWeight: Fonts.Hind.Weights.Bold,
    fontSize: pxToRem(10),
    lineHeight: pxToRem(10),
  },
  hyperlink: {
    fontFamily: Fonts.Hind.Name,
    fontWeight: Fonts.Hind.Weights.Regular,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}

export default typography
