import React from 'react'
import styled from 'styled-components'
import CalendarIcon from '../Icons/Misc/Calendar'
import DollarIcon from '../Icons/Misc/Money'
import LocationIcon from '../Icons/Misc/Location'
import LoadingBar from '../LoadingBar'
import { buildForwardingComponent } from '../utils/buildComponent'
import PortraitIcon from '../Icons/Misc/Employees'
import { color, ColorProps } from '../@styled-system/Color'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const icons = {
  employee: PortraitIcon,
  time: CalendarIcon,
  location: LocationIcon,
  dollar: DollarIcon,
}

const Content = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & ColorProps
>`
  ${(props) => props.theme.typography.action4}
  color: ${(props) => props.theme.palette.text.secondary};
  display: flex;
  padding: 1rem 1.25rem 0;
 
  ${flexbox}
  ${space}
  ${layout}
  ${color}
`

const LoadingRow = styled(LoadingBar)`
  width: 100%;
  height: ${(props) => props.theme.pxToRem(24)};
  margin: 0;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.div`
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  display: flex;
  margin-right: 0.5rem;
`

const Label = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.placeholder};
  margin-bottom: 0.5rem;
`

export interface CardRowProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  icon?: 'employee' | 'time' | 'location' | 'dollar'
  isLoading?: boolean
  label?: string
}

const CardRow = React.forwardRef<HTMLDivElement, CardRowProps>(
  (props: CardRowProps, ref: React.Ref<HTMLDivElement>) => {
    const { children, icon = null, isLoading = false, label } = props
    const Icon = icon && icons[icon]
    return (
      <Content ref={ref} {...props}>
        {isLoading ? (
          <LoadingRow />
        ) : (
          <Row>
            {label && <Label>{label}</Label>}
            <Text>
              {icon && (
                <IconContainer>
                  <Icon />
                </IconContainer>
              )}
              {children}
            </Text>
          </Row>
        )}
      </Content>
    )
  }
)

if (process.env.NODE_ENV !== 'production') {
  CardRow.displayName = 'CardRow'
}

export default buildForwardingComponent(CardRow)
