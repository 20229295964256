import FleetLogoWhite from 'core-system/Icons/Misc/FleetLogoWhite'
import HamburgerIcon from 'core-system/Icons/Misc/Hamburger'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import styled from 'styled-components'
import SidebarItemMobile from './SidebarItemMobile'
import {
  employeePageLinks,
  getSettingsSidebarLink,
  pageLinks,
  SidebarItem,
} from './SidebarShared'
import { Locations } from 'shared/Router/Locations'

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.primary.primaryDark};
  color: ${(props) => props.theme.palette.secondary.lavender2};
  height: 100%;
  overflow: auto;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Content = styled.div`
  padding: 1.25rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Header = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(40)};
`

const HeaderIcon = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.purple2};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.palette.white};

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple1};
  }
`

const ItemsContainer = styled.div`
  margin: ${(props) => props.theme.pxToRem(40)} 0 2rem;
`

interface SidebarMobileProps {
  handleToggle: () => void
  platformType: 'employee' | 'employer'
}

const SidebarMobile = React.memo((props: SidebarMobileProps) => {
  const { handleToggle, platformType } = props
  const location = useLocation()

  const links = platformType === 'employer' ? pageLinks : employeePageLinks

  const settingsLink = getSettingsSidebarLink(platformType)

  return (
    <Container>
      <Content>
        <div>
          <Header>
            <HeaderIcon onClick={() => handleToggle()}>
              <HamburgerIcon width={24} height={24} />
            </HeaderIcon>
          </Header>
          <Link
            to={platformType === 'employer' ? '/' : Locations.EmployeeDashboard}
            onClick={() =>
              SegmentService.track('sidebar-item-click', {
                item: 'logo-mobile',
              })
            }
          >
            <FleetLogoWhite height={38} width={114} />
          </Link>
          <ItemsContainer>
            {links.map((item: SidebarItem, idx: number) => (
              <SidebarItemMobile
                key={idx}
                label={item.label}
                disabled={item.disabled}
                icon={item.icon}
                metric={item.metric}
                menu={item.menu}
                path={location.pathname}
                to={item.link}
                toggle={handleToggle}
              />
            ))}
          </ItemsContainer>
        </div>
        <SidebarItemMobile
          label={settingsLink.label}
          disabled={
            location.pathname === Locations.EmployeeOnboarding ||
            location.pathname === Locations.GettingStarted ||
            location.pathname === Locations.QTF.ActivationOnboarding
          }
          icon={settingsLink.icon}
          path={location.pathname}
          metric={settingsLink.metric}
          to={settingsLink.link}
          toggle={handleToggle}
        />
      </Content>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SidebarMobile.displayName = 'SidebarMobile'
}

export default SidebarMobile
