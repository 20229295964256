import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import { AppState } from 'redux/config/store'

let displayed = []

const Notifier = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(
    (state: AppState) => state.notifications.notifications
  )
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {} }) => {
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (_event, myKey) => {
          // remove this snackbar from redux store
          const k = Number(myKey)
          dispatch(notificationsActions.removeSnackbar(k))
          removeDisplayed(myKey)
        },
      })

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}

export default Notifier
