import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d='M7 4.00006L5.95 5.95006L4 7.00006L5.95 8.05006L7 10.0001L8.05 8.05006L10 7.00006L8.05 5.95006L7 4.00006Z'
      fill='#6200E0'
    />
    <path
      d='M14.5 7.00006L12.74 10.7401L9 12.5001L12.74 14.2601L14.5 18.0001L16.48 14.2601L20 12.5001L16.48 10.7401L14.5 7.00006Z'
      fill='#6200E0'
    />
    <path
      d='M6.3 16.3001L7 15.0001L7.7 16.3001L9 17.0001L7.7 17.7001L7 19.0001L6.3 17.7001L5 17.0001L6.3 16.3001Z'
      fill='#6200E0'
    />
  </>,
  'Incentives'
)
