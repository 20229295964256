import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useDispatch } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { Worksite } from 'redux/employer/employerTypes'

interface WorksiteModalProps {
  worksite: Worksite
  closeModal: () => void
  open: boolean
}

const ToggleWorksiteInviteCodeModal = React.memo(
  (props: WorksiteModalProps) => {
    const { open, closeModal, worksite } = props

    const dispatch = useDispatch()

    const activeWorksiteInviteCode = worksite.inviteCode && true

    const handleSubmit = () => {
      if (activeWorksiteInviteCode) {
        dispatch(employerActions.disableWorksiteInviteId(worksite.id))
        SegmentService.track('openInvite-btn-click', {
          action: 'disable',
          worksiteName: worksite.alias,
        })
      } else {
        dispatch(employerActions.getWorksiteInviteId(worksite))
        SegmentService.track('openInvite-btn-click', {
          action: 'activate',
          worksiteName: worksite.alias,
        })
      }
      closeModal()
    }

    return (
      <Modal
        open={open}
        onClose={() => closeModal()}
        width={pxToRem(500)}
        maxHeight='unset'
      >
        <ModalHeader
          title={`${
            activeWorksiteInviteCode ? 'Disable' : 'Activate'
          } Invite Code`}
        />
        <ModalBody>
          <Text variant='body1' margin='1rem 0'>
            {activeWorksiteInviteCode
              ? `Are you sure you want to disable this worksite's commuter invite code? This means commuters from this worksite will not be able to sign up through the open invite page.`
              : 'Do you want to activate an open invite code for this worksite? Commuters will be able to sign up for this worksite with the generated invite link.'}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant={'tertiary'}
            marginRight={pxToRem(29)}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit}>
            {activeWorksiteInviteCode ? 'Disable' : 'Activate'}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ToggleWorksiteInviteCodeModal.displayName = 'ToggleWorksiteInviteCodeModal'
}

export default ToggleWorksiteInviteCodeModal
