import { all, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { SegmentParams } from 'redux/employer/employerTypes'
import { flexActions } from 'redux/flex/flexSlice'
import FlexService from 'redux/flex/flexService'
import { FlexProgram, FlexProgramUpdate } from 'redux/flex/flexTypes'
import {
  WorksiteAndType,
  WorksiteAndTypeAndDate,
} from 'redux/programs/programsTypes'
import { Action } from 'shared/Types/Actions'

export function* getFlexRecommendations(action: Action<SegmentParams>) {
  yield defaultFlow(
    action,
    FlexService.getFlexRecommendations,
    flexActions.getFlexRecommendationsSuccess
  )
}

export function* createFlexProgram(action: Action<FlexProgram>) {
  yield defaultFlow(
    action,
    FlexService.createFlexProgram,
    flexActions.createFlexProgramSuccess
  )
}

export function* updateFlexProgram(action: Action<FlexProgramUpdate>) {
  yield defaultFlow(
    action,
    FlexService.updateFlexProgram,
    flexActions.updateFlexProgramSuccess
  )
}

export function* cancelFlexProgram(action: Action<string>) {
  yield defaultFlow(
    action,
    FlexService.cancelFlexProgram,
    flexActions.cancelFlexProgramSuccess
  )
}

export function* getFlexDashboardStats(action: Action<WorksiteAndType>) {
  yield defaultFlow(
    action,
    FlexService.getFlexDashboardStats,
    flexActions.getFlexDashboardStatsSuccess
  )
}

export function* getFlexDashboardServices(
  action: Action<WorksiteAndTypeAndDate>
) {
  yield defaultFlow(
    action,
    FlexService.getFlexDashboardServices,
    flexActions.getFlexDashboardServicesSuccess
  )
}

/**
 * Watchers
 */
export function* watchGetFlexRecommendations() {
  yield takeEvery(
    [flexActions.getFlexRecommendations.type],
    getFlexRecommendations
  )
}

export function* watchCreateFlexProgram() {
  yield takeEvery([flexActions.createFlexProgram.type], createFlexProgram)
}

export function* watchUpdateFlexProgram() {
  yield takeEvery([flexActions.updateFlexProgram.type], updateFlexProgram)
}

export function* watchCancelFlexProgram() {
  yield takeEvery([flexActions.cancelFlexProgram.type], cancelFlexProgram)
}

export function* watchGetFlexDashboardStats() {
  yield takeEvery(
    [flexActions.getFlexDashboardStats.type],
    getFlexDashboardStats
  )
}

export function* watchGetFlexDashboardServices() {
  yield takeEvery(
    [flexActions.getFlexDashboardServices.type],
    getFlexDashboardServices
  )
}

export default function* () {
  yield all([
    watchGetFlexRecommendations(),
    watchCreateFlexProgram(),
    watchUpdateFlexProgram(),
    watchCancelFlexProgram(),
    watchGetFlexDashboardStats(),
    watchGetFlexDashboardServices(),
  ])
}
