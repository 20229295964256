import Chip from 'core-system/Chip'
import MonthToggle from 'core-system/DatePicker/MonthToggle'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React, { useState } from 'react'
import { DailyTrip, MyCommuterDetails } from 'redux/employee/employeeTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'
import MyCommuterCalendar from './MyCommuterCalendar'
import MyCommuterTrip from './MyCommuterTrip'

const MetricRow = styled(FlexContainer)`
  justify-content: space-between;
  margin-top: 0.75rem;
`

const Trips = styled.div`
  overflow: auto;
  height: ${(props) => props.theme.pxToRem(425)};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const getStartDate = (currentDate: string) => {
  const thisMonth = moment().format('YYYY-MM')
  if (thisMonth === currentDate) {
    return moment().date()
  } else {
    return moment(currentDate).endOf('month').date()
  }
}

const renderTrips = (
  tripsByDay: Dictionary<DailyTrip[]>,
  daySelected: number
) => {
  const hasNoTripReason =
    tripsByDay[daySelected] &&
    tripsByDay[daySelected].find((trip) => trip.noTripReason)
  if (!tripsByDay[daySelected] || hasNoTripReason) {
    return (
      <FlexContainer width='100%' height='100%' flexDirection='column' center>
        <PlatformSvg folder='segments' variant='noTrip' />
        <Text
          variant='action1'
          textColor={palette.text.secondary}
          marginTop='2rem'
        >
          No Trips Logged
        </Text>
        {hasNoTripReason && (
          <Chip
            variant='filter'
            selected={true}
            marginTop='1rem'
            isInteractive={false}
          >
            {hasNoTripReason.noTripReason}
          </Chip>
        )}
      </FlexContainer>
    )
  } else if (tripsByDay[daySelected] && tripsByDay[daySelected].length > 0) {
    return tripsByDay[daySelected].map((trip, idx) => (
      <MyCommuterTrip key={idx} trip={trip} marginTop='0.5rem' />
    ))
  } else {
    return null
  }
}

interface MyCommuterDetailsTripsProps {
  currentDate: string
  onIncreaseClick: () => void
  onDecreaseClick: () => void
  commuterDetails: MyCommuterDetails
}

const MyCommuterDetailsTrips = React.memo(
  (props: MyCommuterDetailsTripsProps) => {
    const { currentDate, commuterDetails, onIncreaseClick, onDecreaseClick } =
      props

    const [daySelected, setDaySelected] = useState(getStartDate(currentDate))

    const tripsByDay = Object.keys(commuterDetails.tripsByDay).reduce(
      (agg, key) => {
        agg[key] = commuterDetails.tripsByDay[key].length
        return agg
      },
      {}
    )

    const budgetSpend = commuterDetails.programData.reduce(
      (agg, program) => (agg += program.totalSpend ? program.totalSpend : 0),
      0
    )

    const handleSetActiveTripStats = (dayNumber: number) => {
      if (dayNumber !== daySelected) {
        setDaySelected(dayNumber)
      }
    }

    return (
      <FlexContainer>
        <FlexContainer width='50%' flexDirection='column'>
          <MonthToggle
            currentDate={currentDate}
            onIncreaseClick={onIncreaseClick}
            onDecreaseClick={onDecreaseClick}
          />
          <MyCommuterCalendar
            marginTop='1rem'
            setActiveTripStats={handleSetActiveTripStats}
            daySelected={daySelected}
            tripsByDay={tripsByDay}
            firstDayOfMonth={moment(currentDate).startOf('month')}
            currentMonth={moment(currentDate).format('YYYY-MM')}
          />
          <Divider margin='0.875rem 0 0' />
          <MetricRow>
            <Text variant='action4'>Trips Logged</Text>
            <Text variant='action4'>
              {FormattingUtils.formatNumber(commuterDetails.tripsThisMonth)}
            </Text>
          </MetricRow>
          <MetricRow>
            <Text variant='action4'>Budget Spend</Text>
            <Text variant='action4'>
              {FormattingUtils.formatDollar(budgetSpend || 0)}
            </Text>
          </MetricRow>
          <MetricRow>
            <Text variant='action4'>Rewards Spent</Text>
            <Text variant='action4'>
              {FormattingUtils.formatDollar(
                commuterDetails.rewardsUtilization || 0
              )}
            </Text>
          </MetricRow>
        </FlexContainer>
        <Divider
          direction='vertical'
          size={pxToRem(452)}
          margin='0.5rem 1.5rem 0.5rem 2rem'
        />
        <FlexContainer width='50%' flexDirection='column'>
          <Text variant='action2' margin='0.3125rem 0 0.5rem'>
            {moment(`${currentDate}-${daySelected}`).format('MMMM Do, YYYY')}
          </Text>
          <Trips>{renderTrips(commuterDetails.tripsByDay, daySelected)}</Trips>
        </FlexContainer>
      </FlexContainer>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommuterDetailsTrips.displayName = 'MyCommuterDetailsTrips'
}

export default MyCommuterDetailsTrips
