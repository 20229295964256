import { incentivesActions } from 'redux/incentives/incentivesSlice'
import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import { SelectItemProps } from 'core-system/Dropdown'
import Loading from 'core-system/Loading'
import { Footer, SegmentAndDateConfig } from 'core-system/Program'
import SegmentDropdownUtils from 'core-system/SegmentDropdown/SegmentDropdownUtils'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import {
  IncentiveProgramCreate,
  ModeType,
} from 'redux/incentives/incentivesTypes'
import DateUtils from 'shared/DateUtils'
import styled from 'styled-components'
import ActivateIncentiveRewards from './components/ActivateIncentiveRewards'
import ActivateIncentiveSpending from './components/ActivateIncentiveSpending'
import ActivateIncentiveSummary from './components/ActivateIncentiveSummary'

export interface RewardsCallbackItem {
  type: string
  modeType: ModeType
  idxRow: number
}

const getEmptyModeType = () => {
  return {
    perTrip: null,
    id: null,
    text: null,
    max: null,
  }
}

const StyledColumnView = styled(ColumnView)`
  margin-bottom: 10rem;
  grid-template-columns: 64.5% 34.5%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    max-width: ${(props) => props.theme.pxToRem(700)};
    grid-gap: unset;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const formatUpdatedPrograms = (
  modeTypes: ModeType[],
  monthlyMax: number,
  startDate: string,
  activeSegment: string
): IncentiveProgramCreate => {
  const updatedModes = modeTypes.reduce((acc, mode) => {
    const formattedMaxValName = mode.id.replace('Val', 'MaxVal')

    return {
      ...acc,
      [mode.id]: (mode.perTrip * 100).toString(),
      [formattedMaxValName]: mode.max ? (mode.max * 100).toString() : null,
    }
  }, {})

  return {
    segment: activeSegment,
    walkVal: '0',
    bikeVal: '0',
    scooterVal: '0',
    mopedVal: '0',
    carVal: '0',
    rideshareVal: '0',
    carpoolVal: '0',
    vanpoolVal: '0',
    shuttleVal: '0',
    bikeshareVal: '0',
    scootershareVal: '0',
    mopedshareVal: '0',
    transitVal: '0',
    bikeMaxVal: null,
    bikeshareMaxVal: null,
    carMaxVal: null,
    carpoolMaxVal: null,
    mopedMaxVal: null,
    mopedshareMaxVal: null,
    rideshareMaxVal: null,
    scooterMaxVal: null,
    scootershareMaxVal: null,
    shuttleMaxVal: null,
    transitMaxVal: null,
    vanpoolMaxVal: null,
    walkMaxVal: null,
    ...updatedModes,
    maxVal: monthlyMax.toString(),
    startDate: moment(startDate, 'MMMM Do, YYYY').format(),
    endDate: null,
  }
}

const ActivateIncentiveView = React.memo(() => {
  const { segmentId: segmentIdParam } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { segmentsMap, allSegments } = useSelector(
    (state: AppState) => state.employer
  )
  const { incentivesLoaded, allIncentives } = useSelector(
    (state: AppState) => state.incentives
  )

  const nextMonthDate = useMemo(() => {
    return DateUtils.nextMonthDate()
  }, [])

  const [modeTypes, setModeTypes] = useState<ModeType[]>([getEmptyModeType()])
  const [monthlyMax, setMonthlyMax] = useState<number>(2000)
  const [modeTypeErrors, setModeTypeErrors] = useState([])
  const [budgetError, setBudgetError] = useState(false)
  const [startDate, setStartDate] = useState(nextMonthDate)

  const handleModeTypeChange = (item: SelectItemProps) => {
    const updatedModeTypes = modeTypes.map((modeType, idx) =>
      modeType.id === item.currentModeType.id && idx === item.idxRow
        ? { ...modeType, id: item.id, text: item.text }
        : modeType
    )

    if (modeTypes[item.idxRow].text) {
      SegmentService.track('mode-manage-action', {
        action: 'remove',
        mode: modeTypes[item.idxRow].text,
      })
    }
    SegmentService.track('mode-manage-action', {
      action: 'add',
      mode: item.text,
    })

    setModeTypes(updatedModeTypes)
  }

  const handleModeStatChange = (
    value: number,
    callbackItem: RewardsCallbackItem
  ) => {
    const updatedModeTypes = modeTypes.map((modeType, idx) => {
      return modeType.id === callbackItem.modeType.id &&
        idx === callbackItem.idxRow
        ? { ...modeType, [callbackItem.type]: value }
        : modeType
    })

    setModeTypes(updatedModeTypes)
  }

  const handleModeTypeDelete = (idx: number) => {
    if (modeTypes[idx].text) {
      SegmentService.track('mode-manage-action', {
        action: 'remove',
        mode: modeTypes[idx].text,
      })
    }

    const modeTypesCopy = [...modeTypes]
    if (modeTypes.length > 1) {
      modeTypesCopy.splice(idx, 1)
    }
    setModeTypes(modeTypes.length > 1 ? modeTypesCopy : [])
  }

  const handleModeTypeAdd = (addedModeTypes?: string[]) => {
    if (modeTypes.length < 13) {
      if (!addedModeTypes) {
        setModeTypes([...modeTypes, getEmptyModeType()])
      } else {
        const currentModeTypes = modeTypes.map((modeType) => modeType.id)
        const modesToAdd = addedModeTypes.reduce((acc, modeKey) => {
          if (!currentModeTypes.includes(modeKey)) {
            const newMode = {
              id: modeKey,
              text: modeKey.split('Val')[0],
              perTrip: null,
            }
            acc.push(newMode)
          }
          return acc
        }, [])

        const updatedModeTypes = modeTypes.filter((m) => m.id !== null)

        setModeTypes([...updatedModeTypes, ...modesToAdd])
      }
    }
  }

  const handleUpdateIncentives = () => {
    const modeTypesWithErrors = modeTypes.reduce((errorModes, mode) => {
      if (
        !mode.id ||
        !mode.perTrip ||
        mode.max > monthlyMax ||
        (mode.max && mode.max < mode.perTrip)
      )
        errorModes.push(mode.id)
      return errorModes
    }, [])
    const budgetError = !monthlyMax

    if (modeTypesWithErrors.length > 0 || budgetError) {
      setModeTypeErrors([...modeTypesWithErrors])
      if (budgetError) {
        setBudgetError(true)
      }
    } else {
      const updatedIncentiveProgram = formatUpdatedPrograms(
        modeTypes,
        monthlyMax,
        startDate.text,
        segmentIdParam
      )
      dispatch(incentivesActions.createIncentives(updatedIncentiveProgram))
      SegmentService.track('incentive-action-click', {
        action: 'activate',
        segmentName: segmentsMap[segmentIdParam].name,
        startDate: moment(updatedIncentiveProgram.startDate).format(
          'DD-MM-YYYY'
        ),
        endDate: null,
        budget: monthlyMax,
        walk: parseFloat(updatedIncentiveProgram.walkVal),
        bike: parseFloat(updatedIncentiveProgram.bikeVal),
        scooter: parseFloat(updatedIncentiveProgram.scooterVal),
        moped: parseFloat(updatedIncentiveProgram.mopedVal),
        car: parseFloat(updatedIncentiveProgram.carVal),
        rideshare: parseFloat(updatedIncentiveProgram.rideshareVal),
        carpool: parseFloat(updatedIncentiveProgram.carpoolVal),
        vanpool: parseFloat(updatedIncentiveProgram.vanpoolVal),
        shuttle: parseFloat(updatedIncentiveProgram.shuttleVal),
        bikeshare: parseFloat(updatedIncentiveProgram.bikeshareVal),
        scootershare: parseFloat(updatedIncentiveProgram.scootershareVal),
        mopedshare: parseFloat(updatedIncentiveProgram.mopedshareVal),
        transit: parseFloat(updatedIncentiveProgram.transitVal),
        bikeMaxVal: parseFloat(updatedIncentiveProgram.bikeMaxVal) || null,
        bikeshareMaxVal:
          parseFloat(updatedIncentiveProgram.bikeshareMaxVal) || null,
        carMaxVal: parseFloat(updatedIncentiveProgram.carMaxVal) || null,
        carpoolMaxVal:
          parseFloat(updatedIncentiveProgram.carpoolMaxVal) || null,
        mopedMaxVal: parseFloat(updatedIncentiveProgram.mopedMaxVal) || null,
        mopedshareMaxVal:
          parseFloat(updatedIncentiveProgram.mopedshareMaxVal) || null,
        rideshareMaxVal:
          parseFloat(updatedIncentiveProgram.rideshareMaxVal) || null,
        scooterMaxVal:
          parseFloat(updatedIncentiveProgram.scooterMaxVal) || null,
        scootershareMaxVal:
          parseFloat(updatedIncentiveProgram.scootershareMaxVal) || null,
        shuttleMaxVal:
          parseFloat(updatedIncentiveProgram.shuttleMaxVal) || null,
        transitMaxVal:
          parseFloat(updatedIncentiveProgram.transitMaxVal) || null,
        vanpoolMaxVal:
          parseFloat(updatedIncentiveProgram.vanpoolMaxVal) || null,
        walkMaxVal: parseFloat(updatedIncentiveProgram.walkMaxVal) || null,
      })
    }
  }

  const handleSegmentChange = (segmentId: string) => {
    if (segmentId !== segmentIdParam) {
      navigate(`/trips/${segmentId}/incentives/activation`)
      SegmentService.track('baseOptions-toggle-action', {
        actionType: 'segment-name',
      })
    }
  }

  if (!segmentsMap || !incentivesLoaded || !modeTypes || !allIncentives) {
    return <Loading fullPage={true} />
  }

  if (allIncentives[segmentIdParam]) {
    return <Navigate to={`/trips/${segmentIdParam}`} replace />
  }

  return (
    <>
      <Helmet>
        <title>Activate | Fleet</title>
      </Helmet>
      <StyledColumnView>
        <Column>
          <SegmentAndDateConfig
            currentSegment={segmentsMap[segmentIdParam]}
            dropdownItems={SegmentDropdownUtils.segmentDropdownItems(
              allSegments,
              allIncentives
            )}
            onDateChange={(val, text, initial) =>
              setStartDate(initial ? nextMonthDate : { val, text })
            }
            onSegmentChange={handleSegmentChange}
          />
          <ActivateIncentiveRewards
            modeTypes={modeTypes}
            modeTypeErrors={modeTypeErrors}
            budgetError={budgetError}
            monthlyMax={monthlyMax}
            setMonthlyMax={(value) => {
              setMonthlyMax(value * 100)
            }}
            handleModeTypeChange={handleModeTypeChange}
            handleModeStatChange={handleModeStatChange}
            handleModeTypeDelete={handleModeTypeDelete}
            handleModeTypeAdd={handleModeTypeAdd}
            canEdit
          />
          <ActivateIncentiveSpending />
        </Column>
        <Column>
          <ActivateIncentiveSummary
            modeTypes={modeTypes}
            monthlyMax={monthlyMax}
            startDate={moment(startDate.text, 'MMMM Do, YYYY').format(
              'YYYY-MM-DD'
            )}
            segment={segmentsMap[segmentIdParam]}
            canEdit
          />
        </Column>
      </StyledColumnView>
      <Footer>
        <ButtonContainer>
          <Button
            variant='tertiary'
            marginRight='1rem'
            onClick={() => navigate(`/trips/${segmentIdParam}`)}
          >
            Cancel
          </Button>
          <Button onClick={handleUpdateIncentives}>Activate</Button>
        </ButtonContainer>
      </Footer>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ActivateIncentiveView.displayName = 'ActivateIncentiveView'
}

export default ActivateIncentiveView
