import React from 'react'
import FAQList from './components/FAQList'
import { GridContainer } from 'employee-platform/shared/styles/Grid'

const EmployeeConciergeView = React.memo(() => {
  return (
    <GridContainer numColumns={1}>
      <FAQList />
    </GridContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeConciergeView.displayName = 'EmployeeConciergeView'
}

export default EmployeeConciergeView
