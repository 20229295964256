import * as React from 'react'
import SvgIcon from '../SvgIcon'
import { SvgIconProps } from '../SvgIcon/SvgIcon'

export default function createSvgIcon(
  path: React.ReactNode,
  displayName: string,
  viewBox?: string,
  height?: number,
  width?: number,
  preserveAspectRatio?: string
) {
  const Component = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>(
      (props: SvgIconProps, ref) => (
        <SvgIcon
          viewBox={viewBox}
          preserveAspectRatio={preserveAspectRatio}
          height={height}
          width={width}
          {...props}
          ref={ref}
        >
          {path}
        </SvgIcon>
      )
    )
  )

  Component.displayName = `${displayName}Icon`

  return Component
}
