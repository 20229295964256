import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { EmployeeOptInChoice } from 'redux/optin/optInTypes'
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'core-system/Modal'
import Button from 'core-system/Button'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import { useDispatch } from 'react-redux'
import { optInActions } from 'redux/optin/optInSlice'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import Loading from 'core-system/Loading'
import Divider from 'core-system/Divider'
import palette from 'core-system/Themes/palette'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import { OptInCopy } from 'features/OptIn/Shared/OptInUtils'

const TabSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
`

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const EmployeeChoice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${palette.grey.grey3};
  padding-bottom: 0.5rem;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

interface OptInQuestionResponsesModalProps {
  open: boolean
  closeModal: () => void
  responses: EmployeeOptInChoice[]
  optInId: string
  response: string
}

const OptInQuestionResponsesModal = React.memo(
  (props: OptInQuestionResponsesModalProps) => {
    const { open, closeModal, responses, optInId, response } = props

    const dispatch = useDispatch()

    const { allCommutersMap } = useSelector((state: AppState) => state.employee)

    const [currentTab, setCurrentTab] = useState<
      'needsAction' | 'ignored' | 'completed'
    >('needsAction')

    const [actionedResponses, setActionedResponses] = useState({})

    const displayResponses = useMemo(() => {
      if (currentTab === 'needsAction') {
        return responses?.filter(
          (response) => !response.ignore && !response.completed
        )
      } else if (currentTab === 'ignored') {
        return responses?.filter((response) => response.ignore)
      } else {
        return responses?.filter((response) => response.completed)
      }
    }, [currentTab, responses])

    const noDataDisplayText = useMemo(() => {
      if (currentTab === 'needsAction') {
        return 'NO RESPONSES REQUIRE ACTION'
      } else if (currentTab === 'ignored') {
        return 'NO RESPONSES HAVE BEEN IGNORED'
      } else {
        return 'NO RESPONSES HAVE BEEN COMPLETED'
      }
    }, [currentTab])

    const handleCloseModal = () => {
      setActionedResponses({})
      closeModal()
    }

    const handleSubmit = () => {
      dispatch(
        optInActions.updateEmployeeOptIns({
          optInId: optInId,
          employeeOptIns: Object.values(actionedResponses),
        })
      )
      handleCloseModal()
    }

    const handleActionOnChoice = (
      responseId: string,
      action: 'ignore' | 'complete'
    ) => {
      setActionedResponses({
        ...actionedResponses,
        [responseId]: {
          id: responseId,
          ignore: action === 'ignore' ? true : false,
          completed: action === 'complete' ? true : false,
        },
      })
    }

    if (!allCommutersMap) return <Loading />

    return (
      <Modal
        open={open}
        onClose={() => handleCloseModal()}
        width={pxToRem(450)}
      >
        <ModalHeader title='Commuter Responses' />
        <ModalBody>
          <TabSelectorContainer>
            <Button
              onClick={() => setCurrentTab('needsAction')}
              disabled={currentTab === 'needsAction'}
              size='small'
            >
              {`Requires Action (${
                responses.filter(
                  (response) => !response.ignore && !response.completed
                ).length
              })`}
            </Button>
            <Button
              onClick={() => setCurrentTab('ignored')}
              disabled={currentTab === 'ignored'}
              size='small'
            >
              {`Ignored (${
                responses.filter((response) => response.ignore).length
              })`}
            </Button>
            <Button
              onClick={() => setCurrentTab('completed')}
              disabled={currentTab === 'completed'}
              size='small'
            >
              {`Completed (${
                responses.filter((response) => response.completed).length
              })`}
            </Button>
          </TabSelectorContainer>
          <Divider direction='horizontal' marginY='1rem' size='100%' />
          <Text variant='action3' textColor={palette.text.secondary}>
            Response
          </Text>
          <Text variant='action2'>{response}</Text>
          <Divider direction='horizontal' marginY='1rem' size='100%' />
          <ChoicesContainer>
            <Text variant='action3' textColor={palette.text.secondary}>
              Commuters
            </Text>
            {currentTab === 'needsAction' &&
            displayResponses &&
            displayResponses.length > 0 ? (
              <Text
                variant='body2'
                textColor={palette.text.placeholder}
                marginBottom='0.5rem'
              >
                {OptInCopy.updateEmployeeChoices}
              </Text>
            ) : null}
            {displayResponses && displayResponses.length > 0 ? (
              displayResponses.map((response, idx) => {
                return (
                  <EmployeeChoice key={idx}>
                    <Text variant='action2'>
                      {allCommutersMap[response.employeeId]?.name}
                    </Text>
                    <ButtonsContainer>
                      <Button
                        onClick={() =>
                          handleActionOnChoice(response.id, 'ignore')
                        }
                        disabled={actionedResponses[response.id]?.ignore}
                        size='small'
                      >
                        Ignore
                      </Button>
                      <Button
                        onClick={() =>
                          handleActionOnChoice(response.id, 'complete')
                        }
                        disabled={actionedResponses[response.id]?.completed}
                        size='small'
                      >
                        Complete
                      </Button>
                    </ButtonsContainer>
                  </EmployeeChoice>
                )
              })
            ) : (
              <NoDataText text={noDataDisplayText} />
            )}
          </ChoicesContainer>
        </ModalBody>
        <ModalFooter>
          <Button variant='tertiary' onClick={() => handleCloseModal()}>
            {currentTab === 'needsAction' ? 'Cancel' : 'Close'}
          </Button>
          <Button
            onClick={() => handleSubmit()}
            disabled={
              Object.values(actionedResponses).length === 0 ||
              currentTab !== 'needsAction'
            }
            marginLeft='1rem'
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OptInQuestionResponsesModal.displayName = 'OptInQuestionResponsesModal'
}

export default OptInQuestionResponsesModal
