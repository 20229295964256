import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import Text from 'core-system/Text'
import Divider from 'core-system/Divider'
import EmployeesIcon from 'core-system/Icons/Misc/Employees'
import palette from 'core-system/Themes/palette'
import AddIcon from 'core-system/Icons/Actions/Add'
import Button from 'core-system/Button'
import AddOptInModal from './AddOptInModal'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const HeaderLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`

const CommuterCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

interface OptInOverviewHeaderProps {
  segmentId: string
  isMobile: boolean
}

const OptInOverviewHeader = React.memo((props: OptInOverviewHeaderProps) => {
  const { segmentId, isMobile } = props

  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  const [showAddOptInModal, setShowAddOptInModal] = useState(false)

  const handleAddOptIn = useCallback(() => {
    setShowAddOptInModal(true)
  }, [])

  if (!segmentsMap) {
    return null
  }

  return (
    <HeaderContainer>
      <HeaderLeftContainer>
        <Text variant='h2'>{segmentsMap[segmentId]?.name}</Text>
        <Divider direction='vertical' size='2rem' />
        <CommuterCountContainer>
          <EmployeesIcon color={palette.text.primary} />
          <Text variant='h4'>{`${segmentsMap[segmentId]?.commuters} Commuter${
            segmentsMap[segmentId]?.commuters === 1 ? '' : 's'
          }`}</Text>
        </CommuterCountContainer>
      </HeaderLeftContainer>
      {isMobile ? (
        <AddIcon onClick={handleAddOptIn} />
      ) : (
        <Button
          onClick={handleAddOptIn}
          iconLeft={<AddIcon color={palette.white} />}
        >
          Add Opt-In
        </Button>
      )}
      <AddOptInModal
        open={showAddOptInModal}
        closeModal={() => setShowAddOptInModal(false)}
        segmentId={segmentId}
        isMobile={isMobile}
      />
    </HeaderContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OptInOverviewHeader.displayName = 'OptInOverviewHeader'
}

export default OptInOverviewHeader
