import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='40' height='40' rx='8' fill='#FFF0BA' />
    <path
      d='M20 32C26.6274 32 32 26.6274 32 20C32 13.3726 26.6274 8 20 8C13.3726 8 8 13.3726 8 20C8 26.6274 13.3726 32 20 32Z'
      fill='#F4900C'
    />
    <path
      d='M20 29.6667C25.3387 29.6667 29.6666 25.3388 29.6666 20C29.6666 14.6613 25.3387 10.3334 20 10.3334C14.6612 10.3334 10.3333 14.6613 10.3333 20C10.3333 25.3388 14.6612 29.6667 20 29.6667Z'
      fill='#FFD983'
    />
    <path
      d='M20 28.6667C24.7864 28.6667 28.6666 24.7865 28.6666 20C28.6666 15.2136 24.7864 11.3334 20 11.3334C15.2135 11.3334 11.3333 15.2136 11.3333 20C11.3333 24.7865 15.2135 28.6667 20 28.6667Z'
      fill='#F5F8FA'
    />
    <path
      d='M20 13.3334L21.0206 17.536L24.714 15.286L22.464 18.9794L26.6666 20L22.464 21.0207L24.714 24.714L21.0206 22.464L20 26.6667L18.9793 22.464L15.286 24.714L17.536 21.0207L13.3333 20L17.536 18.9794L15.286 15.286L18.9793 17.536L20 13.3334Z'
      fill='#CCD6DD'
    />
    <path
      d='M19.562 21.832L25.4133 25.4194L21.8273 19.5674L19.562 21.832Z'
      fill='#292F33'
    />
    <path
      d='M20.438 18.178L14.586 14.5907L18.1727 20.4434L20.438 18.178Z'
      fill='#DD2E44'
    />
    <path
      d='M20 22.042C21.1248 22.042 22.0367 21.1301 22.0367 20.0053C22.0367 18.8805 21.1248 17.9686 20 17.9686C18.8752 17.9686 17.9633 18.8805 17.9633 20.0053C17.9633 21.1301 18.8752 22.042 20 22.042Z'
      fill='#8899A6'
    />
    <path
      d='M20 21.1039C20.6068 21.1039 21.0986 20.6121 21.0986 20.0053C21.0986 19.3985 20.6068 18.9066 20 18.9066C19.3932 18.9066 18.9013 19.3985 18.9013 20.0053C18.9013 20.6121 19.3932 21.1039 20 21.1039Z'
      fill='#F5F8FA'
    />
  </>,
  'Alternative',
  '0 0 40 40'
)
