import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import React, { MouseEvent, useCallback, useState } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import styled from 'styled-components'
import { checkActive, SubMenu, SubMenuItem } from './SidebarShared'

const ItemContainer = styled.div`
  margin-bottom: 1.5rem;
`

const LinkContainer = styled(Link)<{
  $disabled?: boolean
  $active?: boolean
  $child?: boolean
  $open?: boolean
}>`
  display: flex;
  align-items: center;
  transition: width 0.5s ease;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  border: none;
  text-decoration: none;
  margin-bottom: ${(props) => (props.$child ? '0.25rem' : '0')};
  background-color: ${(props) => props.theme.palette.primary.primaryDark};
  color: ${(props) =>
    props.$disabled
      ? props.theme.palette.secondary.lavender1
      : props.theme.palette.secondary.lavender2};
  cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'all')};
  overflow: hidden;
  white-space: nowrap;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${(props) =>
      props.$active
        ? props.theme.palette.secondary.purple2
        : props.theme.palette.secondary.purple1};
    color: ${(props) => props.theme.palette.white};
  }

  ${(props) =>
    props.$open
      ? {
          backgroundColor: props.theme.palette.secondary.purple1,
          color: props.theme.palette.white,
        }
      : ''}

  ${(props) =>
    props.$active
      ? {
          backgroundColor: props.theme.palette.secondary.purple2,
          color: props.theme.palette.white,
        }
      : ''}
`

const IconContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
`

const ItemLabel = styled.div`
  ${(props) => props.theme.typography.action4};
  margin-left: 1rem;
  line-height: 0.9375rem;
`

const ChevronContainer = styled(IconContainer)<{ isOpen: boolean }>`
  margin-left: auto;
  transform: rotate(${(props) => (props.isOpen ? '180deg' : 0)});
  transition: all 0.2s ease;
`

const MenuContainer = styled.div<{ isOpen: boolean; items: number }>`
  margin-top: ${(props) => (props.isOpen ? '0.5rem' : 0)};
  display: flex;
  flex-direction: column;
  max-height: ${(props) =>
    props.isOpen ? props.theme.pxToRem(props.items) : 0};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
`

interface SidebarItemMobileProps extends LinkProps {
  label: string
  disabled?: boolean
  icon?: React.ReactNode
  metric: string
  menu?: SubMenu[]
  path: string
  to: string
  toggle: () => void
}

const SidebarItemMobile = React.memo((props: SidebarItemMobileProps) => {
  const { label, disabled, icon, metric, menu, path, to, toggle } = props
  const [menuOpen, setMenuOpen] = useState(false)

  const handleOnClick = useCallback(
    (e: MouseEvent, metric: string, toggle: () => void, menu: boolean) => {
      SegmentService.track('sidebar-item-click', {
        item: metric,
      })
      if (menu) {
        e.preventDefault()
        setMenuOpen(!menuOpen)
      } else {
        toggle()
      }
    },
    [menuOpen]
  )

  return (
    <ItemContainer>
      <LinkContainer
        to={to}
        onClick={(e) => handleOnClick(e, metric, toggle, menu && true)}
        $disabled={disabled}
        $active={checkActive(to, path, menu)}
        $open={menuOpen}
      >
        <IconContainer>{icon}</IconContainer>
        <ItemLabel>{label}</ItemLabel>
        {menu && (
          <ChevronContainer isOpen={menuOpen}>
            <ChevronIcon />
          </ChevronContainer>
        )}
      </LinkContainer>
      {menu && (
        <MenuContainer isOpen={menuOpen} items={menu.length * 84}>
          {menu.map((menuItem: SubMenuItem, idx: number) => (
            <LinkContainer
              key={idx}
              onClick={(e) => handleOnClick(e, metric, toggle, false)}
              to={menuItem.location}
              $active={menuItem.location === path}
              $child={true}
            >
              <IconContainer />
              <ItemLabel>{menuItem.title}</ItemLabel>
            </LinkContainer>
          ))}
        </MenuContainer>
      )}
    </ItemContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SidebarItemMobile.displayName = 'SidebarItemMobile'
}

export default SidebarItemMobile
