import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'
import { buildForwardingComponent } from '../utils/buildComponent'

const Content = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps & { clickable: boolean; useMinWidth: boolean }
>`
  min-width: ${(props) => props.useMinWidth && props.theme.pxToRem(270)};
  border-radius: 0.9375rem !important;
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  color: ${(props) => props.theme.palette.text.primary};
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  margin-top: 1rem;
  overflow: hidden;
  font-family: Hind;
  display: flex;
  flex-direction: column;
  cursor: ${(props) => props.clickable && 'pointer'};
 
  ${flexbox}
  ${space}
  ${layout}
`

export interface CardProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  clickable?: boolean
  useMinWidth?: boolean
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (props: CardProps, ref: React.Ref<HTMLDivElement>) => {
    const { children, clickable = true, useMinWidth = true } = props

    return (
      <Content
        ref={ref}
        clickable={clickable}
        useMinWidth={useMinWidth}
        {...props}
      >
        {children}
      </Content>
    )
  }
)

if (process.env.NODE_ENV !== 'production') {
  Card.displayName = 'Card'
}

export default buildForwardingComponent(Card)
