import React, { useState, useEffect } from 'react'
import ColumnView, { Column } from 'core-system/ColumnView'
import styled from 'styled-components'
import useQueryParam from 'shared/Hooks/useQueryParam'
import { Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import OTPUtils from '../Shared/OTPUtils'
import {
  OtpActiveOptions,
  OtpManageState,
  OtpProgram,
} from 'redux/otp/otpTypes'
import { otpActions } from 'redux/otp/otpSlice'
import { Merchant, OptionState } from 'redux/programs/programsTypes'
import SegmentService from 'redux/config/services/SegmentService'
import moment from 'moment'
import Loading from 'core-system/Loading'
import { Locations } from 'shared/Router/Locations'
import { BudgetSelector } from 'core-system/Program'
import ProgramOption from 'core-system/Program/ProgramOption'
import OTPManageSummary from './components/OTPManageSummary'
import OTPManagePending from './components/OTPManagePending'
import Footer from 'core-system/Program/Footer'
import Button from 'core-system/Button'
import ServiceProviderSelectorModal from 'core-system/ServiceProvider/ServiceProviderSelectorModal'
import ProgramModal from 'core-system/Program/ProgramModal'
import { notificationsActions } from 'redux/notifications/notificationsSlice'

const StyledColumnView = styled(ColumnView)`
  margin-bottom: 10rem;
  grid-template-columns: 64.5% 34.5%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    max-width: ${(props) => props.theme.pxToRem(700)};
    grid-gap: unset;
  }
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`
const getDefaultOptions = (
  program: OtpProgram,
  micromobilityMerchantIds: string[]
) => {
  return program.merchants.reduce(
    (vals, id) => {
      if (micromobilityMerchantIds.includes(id)) {
        vals.micromobility = true
      }
      return vals
    },
    { micromobility: false }
  )
}

const formatMerchantIds = (
  activeMerchantIds: string[],
  availableMerchants: Merchant[]
): OptionState => {
  return {
    activeMerchantIds: activeMerchantIds,
    availableMerchantIds: availableMerchants.map((m) => m.id),
  }
}

const OTPManageView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )

  const {
    otpProgramsLoaded,
    otpProgramUpdated,
    availableMerchants,
    otpPrograms,
  } = useSelector((state: AppState) => state.otp)

  const [isEdit, setIsEdit] = useState(false)
  const [errorState, setErrorState] = useState(OTPUtils.defaultErrorState)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [programState, setProgramState] = useState<OtpManageState>({
    active: null,
    nextMonth: null,
  })
  const [pendingProgram, setPendingProgram] = useState({
    program: null,
    activeOptions: null,
  })
  const [activeState, setActiveState] = useState('active')
  const [activeOptions, setActiveOptions] =
    useState<Dictionary<OtpActiveOptions>>(null)

  useEffect(() => {
    //ensure all providers are loaded so proper options groupings can be done
    if (segmentId && merchantsMap && !availableMerchants) {
      dispatch(
        otpActions.getOtpRecommendations({
          segmentId,
        })
      )
    }
  }, [segmentId, merchantsMap, availableMerchants, dispatch])

  useEffect(() => {
    if (merchantsMap && availableMerchants && otpPrograms) {
      const activeProgramGroup = otpPrograms[segmentId]
      const micromobilityMerchantIds = availableMerchants.micromobility.map(
        (m) => m.id
      )

      if (!activeProgramGroup.active && activeProgramGroup.nextMonth) {
        const nextMonthState = {
          ...activeProgramGroup.nextMonth,
          micromobility: formatMerchantIds(
            activeProgramGroup.nextMonth.merchants,
            availableMerchants.micromobility
          ),
        }
        const nextMonthOptions = getDefaultOptions(
          activeProgramGroup.nextMonth,
          micromobilityMerchantIds
        )

        setProgramState((prevState) => {
          return {
            ...prevState,
            nextMonth: nextMonthState,
          }
        })
        setActiveOptions({
          active: null,
          nextMonth: nextMonthOptions,
        })
        setIsEdit(true)
        setActiveState('nextMonth')
        setPendingProgram({
          program: nextMonthState,
          activeOptions: nextMonthOptions,
        })
      } else if (activeProgramGroup.active) {
        const activeProgramState = {
          ...activeProgramGroup.active,
          micromobility: formatMerchantIds(
            activeProgramGroup.active.merchants,
            availableMerchants.micromobility
          ),
        }
        setProgramState({
          active: activeProgramState,
          nextMonth: activeProgramGroup.nextMonth
            ? {
                ...activeProgramGroup.nextMonth,
                micromobility: formatMerchantIds(
                  activeProgramGroup.nextMonth.merchants,
                  availableMerchants.micromobility
                ),
              }
            : {
                ...activeProgramState,
                startDate: moment()
                  .add(1, 'M')
                  .startOf('month')
                  .format('YYYY-MM-DDTHH:mm:ss'),
              },
        })
        const defaultActiveOptions = getDefaultOptions(
          activeProgramGroup.active,
          micromobilityMerchantIds
        )
        setActiveOptions({
          active: defaultActiveOptions,
          nextMonth: activeProgramGroup.nextMonth
            ? getDefaultOptions(
                activeProgramGroup.nextMonth,
                micromobilityMerchantIds
              )
            : defaultActiveOptions,
        })
      }
    }
  }, [otpPrograms, merchantsMap, availableMerchants, segmentId, segmentsMap])

  useEffect(() => {
    if (otpProgramUpdated && otpProgramsLoaded) {
      dispatch(otpActions.toggleOtpProgramUpdated())
      if (!otpPrograms[segmentId].active) {
        navigate(`/one-time-purchase/${segmentId}`)
      } else {
        setIsEdit(false)
        setActiveState('active')
        setIsUpdateModalOpen(false)
      }
    }
  }, [
    navigate,
    otpProgramUpdated,
    dispatch,
    otpProgramsLoaded,
    segmentId,
    otpPrograms,
  ])
  const toggleEdit = () => {
    if (isEdit) {
      const activeProgramGroup = otpPrograms[segmentId]
      // const micromobilityMerchantIds = availableMerchants.micromobility.map(
      //   (m) => m.id
      // )

      setProgramState((prevState) => {
        return {
          ...prevState,
          nextMonth: activeProgramGroup.nextMonth
            ? {
                ...activeProgramGroup.nextMonth,
                micromobility: formatMerchantIds(
                  activeProgramGroup.nextMonth.merchants,
                  availableMerchants.micromobility
                ),
              }
            : prevState.active,
        }
      })
    }

    setIsEdit((prevState) => !prevState)
    setActiveState((prevState) => {
      return prevState === 'active' ? 'nextMonth' : 'active'
    })

    SegmentService.track('program-manage-action', {
      action: isEdit ? 'close' : 'edit',
      programType: 'otp',
      segmentName: segmentsMap[segmentId].name,
    })
  }

  const handleOptionToggle = (type: string) => {
    //activate all providers when turning on a blank option
    if (
      !activeOptions.nextMonth[type] &&
      programState.nextMonth[type].activeMerchantIds.length === 0
    ) {
      setProgramState((prevState) => {
        return {
          ...prevState,
          nextMonth: {
            ...prevState.nextMonth,
            [type]: {
              ...prevState.nextMonth[type],
              activeMerchantIds: prevState.nextMonth[type].availableMerchantIds,
            },
          },
        }
      })
    }

    setActiveOptions((prevState) => {
      return {
        ...prevState,
        nextMonth: {
          ...prevState.nextMonth,
          [type]: !prevState.nextMonth[type],
        },
      }
    })
  }

  const handleProviderChange = (
    type: string,
    newActiveMerchantIds: string[]
  ) => {
    setProgramState((prevState) => {
      return {
        ...prevState,
        nextMonth: {
          ...prevState.nextMonth,
          [type]: {
            ...prevState.nextMonth[type],
            activeMerchantIds: newActiveMerchantIds,
          },
        },
      }
    })
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
    SegmentService.track('modes-modal-toggle', {
      action: 'open',
      state: isEdit ? 'edit' : 'view-all',
      location: 'otp-providers',
    })
  }

  const handleUpdate = () => {
    const nextMonthProgram = programState.nextMonth
    const checkErrors = {
      transactionLimit: !nextMonthProgram.budget && true,
      micromobility:
        nextMonthProgram.micromobility.activeMerchantIds.length === 0 &&
        activeOptions.nextMonth.micromobility &&
        true,
    }

    const hasNoErrors = Object.keys(checkErrors).every(
      (key) => !checkErrors[key]
    )

    if (hasNoErrors) {
      dispatch(
        otpActions.updateOtpProgram({
          programId: nextMonthProgram.id,
          budget: nextMonthProgram.budget,
          type: nextMonthProgram.type,
          name: nextMonthProgram.name,
          segment: segmentId,
          startDate: nextMonthProgram.startDate,
          endDate: nextMonthProgram.endDate,
          numUses: nextMonthProgram.numUses,
          requiresRequest: true,
          merchants: activeOptions.nextMonth.micromobility
            ? nextMonthProgram.micromobility.activeMerchantIds
            : [],
        })
      )
      SegmentService.track('programs-action-click', {
        action: 'update',
        programType: 'otp',
        segmentName: segmentsMap[segmentId].name,
        startDate: moment(nextMonthProgram.startDate).format('DD-MM-YYYY'),
        budget: nextMonthProgram.budget,
        micromobility:
          nextMonthProgram.micromobility.activeMerchantIds.length > 0 &&
          activeOptions.nextMonth.micromobility
            ? nextMonthProgram.micromobility.activeMerchantIds.map(
                (id) => merchantsMap[id]?.name
              )
            : false,
        gas: null,
        parking: null,
        transit: null,
        oneTime: null,
        shared: null,
        leasing: null,
        localTaxi: false,
      })
    } else {
      setErrorState(checkErrors)
      dispatch(
        notificationsActions.generalPageError(
          'Make sure a transaction limit is set and there is at least one service provider'
        )
      )
    }
  }

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false)
  }

  const handleUpdateModalToggle = () => {
    setIsUpdateModalOpen((prevState) => !prevState)
  }

  const handleProgramCancel = (programGroup: 'active' | 'nextMonth') => {
    const nextMonthProgram = programState.nextMonth
    dispatch(otpActions.cancelOtpProgram(programState[programGroup].id))
    SegmentService.track('programs-action-click', {
      action: programGroup === 'active' ? 'cancel' : 'reactivate',
      programType: 'otp',
      segmentName: segmentsMap[segmentId].name,
      startDate: moment(nextMonthProgram.startDate).format('DD-MM-YYYY'),
      budget: nextMonthProgram.budget,
      micromobility:
        nextMonthProgram.micromobility.activeMerchantIds.length > 0 &&
        activeOptions.nextMonth.micromobility
          ? nextMonthProgram.micromobility.activeMerchantIds.map(
              (id) => merchantsMap[id]?.name
            )
          : false,
      localTaxi: false,
      flexProviders: null,
      gas: null,
      parking: null,
      transit: null,
      oneTime: null,
      shared: null,
      leasing: null,
    })
    setIsCancelModalOpen(false)
  }
  const handleReActivate = () => {
    const hasChanges = OTPUtils.programHasChanges(
      programState.active
        ? programState
        : { ...programState, active: pendingProgram.program },
      programState.active
        ? activeOptions
        : { ...activeOptions, active: pendingProgram.activeOptions }
    )

    if (!programState.active || hasChanges) {
      handleUpdate()
    } else {
      handleProgramCancel('nextMonth')
    }
  }

  if (otpProgramsLoaded && !otpPrograms[segmentId]) {
    return <Navigate to={Locations.OTP.Programs} replace />
  }

  if (
    !otpProgramsLoaded ||
    (!programState.active && !programState.nextMonth) ||
    !merchantsMap ||
    !availableMerchants
  ) {
    return <Loading fullPage />
  }

  const noActiveOptions = Object.keys(activeOptions.nextMonth).every(
    (option) => !activeOptions.nextMonth[option]
  )
  const transactionLimit = programState[activeState].budget

  return (
    <>
      <StyledColumnView>
        <Column>
          <BudgetSelector
            canEdit={isEdit}
            budget={transactionLimit}
            recBudget={OTPUtils.recTransacLimit}
            onChange={(newLimit) => {
              setProgramState((prevState) => {
                return {
                  ...prevState,
                  nextMonth: {
                    ...prevState.nextMonth,
                    budget: newLimit ? newLimit * 100 : 0,
                  },
                }
              })
            }}
            customHeader='Per Commuter Purchase Limit'
          />
          {/* Micromobility Option */}
          <ProgramOption
            type='micromobility'
            active={activeOptions[activeState].micromobility}
            activeMerchantIds={
              programState[activeState].micromobility.activeMerchantIds
            }
            availableMerchantIds={
              programState[activeState].micromobility.availableMerchantIds
            }
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.micromobility}
            canEdit={isEdit}
          />
        </Column>
        <Column>
          {/* manage summary */}
          <OTPManageSummary
            currentSegment={segmentsMap[segmentId]}
            activeOptions={activeOptions[activeState]}
            program={programState[activeState]}
            handleOpenModal={handleOpenModal}
            canEdit={isEdit}
            toggleEdit={toggleEdit}
          />
          {/* manage pending, next year */}
          <OTPManagePending
            isPending={!programState.active && true}
            programState={
              programState.active
                ? programState
                : { ...programState, active: pendingProgram.program }
            }
            activeOptions={
              programState.active
                ? activeOptions
                : { ...activeOptions, active: pendingProgram.activeOptions }
            }
            canEdit={isEdit}
            openUpdateModal={handleUpdateModalToggle}
          />
        </Column>
      </StyledColumnView>
      {/* is edit and footer */}
      {isEdit && (
        <Footer>
          <FooterContainer>
            {programState.nextMonth.status !== 'CANCELLING' && (
              <Button
                variant='cancel'
                onClick={() => {
                  setIsCancelModalOpen(true)
                  SegmentService.track('program-manage-action', {
                    action: 'cancel',
                    programType: 'otp',
                    segmentName: segmentsMap[segmentId].name,
                  })
                }}
              >
                End Program
              </Button>
            )}
            <ButtonContainer>
              <Button
                variant='tertiary'
                marginRight='1rem'
                onClick={() =>
                  programState.active
                    ? toggleEdit()
                    : navigate(`/one-time-purchase/${segmentId}`)
                }
              >
                Cancel
              </Button>
              <Button
                disabled={noActiveOptions}
                onClick={() =>
                  programState.nextMonth.status === 'CANCELLING'
                    ? handleUpdateModalToggle()
                    : handleUpdate()
                }
              >
                Update Program
              </Button>
            </ButtonContainer>
          </FooterContainer>
        </Footer>
      )}
      {/* services modal */}
      <ServiceProviderSelectorModal
        open={isModalOpen}
        title='Available Service Providers'
        closeModal={() => {
          setIsModalOpen(false)
          SegmentService.track('modes-modal-toggle', {
            action: 'close',
            state: isEdit ? 'edit' : 'view-all',
            location: 'otp-providers',
          })
        }}
        canEdit={isEdit}
        activeProviders={
          programState[activeState].micromobility.activeMerchantIds
        }
        availableProviders={
          programState[activeState].micromobility.availableMerchantIds
        }
        onSave={(newActiveMerchantIds: string[]) =>
          handleProviderChange('micromobility', newActiveMerchantIds)
        }
        modeTypeFilters
      />
      {/* program cancel modal */}
      <ProgramModal
        title='Cancel Program'
        description='Are you sure you want to cancel this program? Your commuters will lose
        all access to this programs benefits.'
        buttonText='Cancel Program'
        open={isCancelModalOpen}
        closeModal={handleCancelModalClose}
        buttonCallback={() =>
          handleProgramCancel(programState.active ? 'active' : 'nextMonth')
        }
      />
      {/* program reactivate modal */}
      <ProgramModal
        title='Reactivate Program'
        description='Do you want to reactive this program? All previous benefits will be available again next month.'
        buttonText='Reactivate Program'
        open={isUpdateModalOpen}
        closeModal={handleUpdateModalToggle}
        buttonCallback={handleReActivate}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OTPManageView.displayName = 'OTPManageView'
}

export default OTPManageView
