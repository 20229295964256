import React from 'react'
import styled from 'styled-components'
import { buildForwardingComponent } from '../utils/buildComponent'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Header = styled.div<FlexboxProps & SpaceProps & LayoutProps>`
  display: flex;
  background-color: ${(props) => props.theme.palette.secondary.purple7};
  padding: 1rem;
  align-items: center;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    background-color: ${(props) => props.theme.palette.white};
    border-radius: 0.9375rem !important;
    border: 1px solid ${(props) => props.theme.palette.grey.grey3};
    box-shadow: ${(props) => props.theme.dropShadows.normal};
    margin-bottom: 0.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    background-color: unset;
    border: unset;
    box-shadow: unset;
    padding: 0;
    margin-bottom: 0.5rem;
    border-radius: unset !important;
  }

  ${flexbox}
  ${space}
  ${layout}
`

export interface TableHeaderProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

const TableHeader = React.forwardRef<HTMLDivElement, TableHeaderProps>(
  (props: TableHeaderProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <Header ref={ref} {...props}>
        {props.children}
      </Header>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TableHeader.displayName = 'TableHeader'
}

export default buildForwardingComponent(TableHeader)
