import React from 'react'
import useCurrentPath from 'shared/Hooks/useCurrentPath'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import NavigationBarFlex from './NavigationBarFlex'
import NavigationBarGRH from './NavigationBarGRH'
import NavigationBarIncentives from './NavigationBarIncentives'
import NavigationBarLeaderboards from './NavigationBarLeaderboards'
import NavigationBarQTF from './NavigationBarQTF'
import NavigationBarOTP from './NavigationBarOTP'
import NavigationMicromobilityBar from './NavigationMicromobilityBar'

const Container = styled.div`
  padding: 2.5rem 2rem;
  height: 100vh;
  overflow: hidden;
  position: relative;
`

interface NavigationBarProps {
  onClose: () => void
  toggleNoPaymentModal: () => void
  toggleFlexModal: () => void
  toggleMicromobilityModal: () => void
  toggleLeaderboardsModal: () => void
  toggleIncentivesModal: () => void
  toggleQTFModal: () => void
  toggleGRHModal: () => void
  toggleOTPModal: () => void
  segmentId: string
}

const NavigationBar = React.memo((props: NavigationBarProps) => {
  const {
    onClose,
    toggleNoPaymentModal,
    toggleFlexModal,
    toggleMicromobilityModal,
    toggleLeaderboardsModal,
    toggleIncentivesModal,
    toggleQTFModal,
    toggleGRHModal,
    toggleOTPModal,
    segmentId,
  } = props
  const path = useCurrentPath()

  const renderContent = () => {
    if (path === Locations.Flex.Overview) {
      return (
        <NavigationBarFlex
          onClose={onClose}
          toggleFlexModal={toggleFlexModal}
          toggleNoPaymentModal={toggleNoPaymentModal}
          segmentId={segmentId}
        />
      )
    } else if (path === Locations.Micromobility.Overview) {
      return (
        <NavigationMicromobilityBar
          onClose={onClose}
          toggleMicromobilityModal={toggleMicromobilityModal}
          toggleNoPaymentModal={toggleNoPaymentModal}
          segmentId={segmentId}
        />
      )
    } else if (path === Locations.Leaderboards.Leaderboards) {
      return (
        <NavigationBarLeaderboards
          onClose={onClose}
          toggleLeaderboardsModal={toggleLeaderboardsModal}
          toggleNoPaymentModal={toggleNoPaymentModal}
          segmentId={segmentId}
        />
      )
    } else if (path === Locations.Incentives.Trips) {
      return (
        <NavigationBarIncentives
          onClose={onClose}
          toggleIncentivesModal={toggleIncentivesModal}
          toggleNoPaymentModal={toggleNoPaymentModal}
          segmentId={segmentId}
        />
      )
    } else if (path === Locations.QTF.Overview) {
      return (
        <NavigationBarQTF
          onClose={onClose}
          toggleQTFModal={toggleQTFModal}
          toggleNoPaymentModal={toggleNoPaymentModal}
          segmentId={segmentId}
        />
      )
    } else if (path === Locations.GRH.Overview) {
      return (
        <NavigationBarGRH
          onClose={onClose}
          toggleGRHModal={toggleGRHModal}
          toggleNoPaymentModal={toggleNoPaymentModal}
          segmentId={segmentId}
        />
      )
    } else if (path === Locations.OTP.Overview) {
      return (
        <NavigationBarOTP
          onClose={onClose}
          toggleOTPModal={toggleOTPModal}
          toggleNoPaymentModal={toggleNoPaymentModal}
          segmentId={segmentId}
        />
      )
    } else {
      return null
    }
  }

  return <Container>{renderContent()}</Container>
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NavigationBar.displayName = 'NavigationBar'
}

export default NavigationBar
