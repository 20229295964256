import React from 'react'
import styled from 'styled-components'
import Table, { TableLabelRow, TableRow, TableRowCell } from 'core-system/Table'
import Text from 'core-system/Text'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import palette from 'core-system/Themes/palette'

interface CommuterTableInfo {
  name: string
  corporateId: string | null
  email: string
}

const NameIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

const NameIdContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const EmptyTableRow = styled(TableRow)`
  text-align: center;
`

const renderCommuterRows = (commuterList: CommuterTableInfo[]) => {
  return commuterList.map((commuter, idx) => {
    return (
      <TableRow key={idx} display='flex'>
        <TableRowCell width='40%' equalSize={false}>
          <NameIconContainer>
            <PlatformSvg
              folder='users'
              variant='userBasic'
              width={40}
              height={40}
            />
            <NameIdContainer>
              <Text variant='action4' hideOverflow>
                {commuter.name}
              </Text>
              {commuter.corporateId ? (
                <Text
                  variant='body2'
                  textColor={palette.text.placeholder}
                  hideOverflow
                >
                  {`#${commuter.corporateId}`}
                </Text>
              ) : null}
            </NameIdContainer>
          </NameIconContainer>
        </TableRowCell>
        {/* TODO: center this vertically */}
        <TableRowCell width='50%' equalSize={false}>
          {commuter.email}
        </TableRowCell>
      </TableRow>
    )
  })
}

interface OptInQuestionNoResponsesProps {
  isMobile: boolean
  noResponseCommuters: CommuterTableInfo[]
}

const OptInQuestionNoResponses = React.memo(
  (props: OptInQuestionNoResponsesProps) => {
    const { noResponseCommuters } = props

    return (
      <Table hasHeader={false} loadMoreAnimation={false}>
        <TableLabelRow padding='1rem 1.5rem' overflow='hidden'>
          <TableRowCell width=' 40%' equalSize={false}>
            Commuter
          </TableRowCell>
          <TableRowCell width='50%' equalSize={false}>
            Email
          </TableRowCell>
        </TableLabelRow>
        {noResponseCommuters.length > 0 ? (
          renderCommuterRows(noResponseCommuters)
        ) : (
          <EmptyTableRow>All Commuters Have Responded</EmptyTableRow>
        )}
      </Table>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OptInQuestionNoResponses.displayName = 'OptInQuestionNoResponses'
}

export default OptInQuestionNoResponses
