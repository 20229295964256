import React, { useState, useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import palette from '../Themes/palette'
import Text from 'core-system/Text'
import CaretIcon from '../Icons/Actions/Caret'
import CalendarIcon from 'core-system/Icons/Misc/Calendar'
import { FlexboxProps, SpaceProps, LayoutProps } from 'styled-system'
import moment from 'moment'

import DropdownCalendar, { YearMonth } from './DropdownCalendar'

const Container = styled.div<FlexboxProps & SpaceProps & LayoutProps>`
  display: flex;
  flex-direction: column;
  position: relative;
`

const Content = styled.div<{
  open: boolean
  error: boolean
  isPlaceholder: boolean
}>`
  color: ${(props) => getTextColor(props.open, props.isPlaceholder, false)};
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: ${(props) => (props.open ? '0.5rem 0.5rem 0 0' : '0.5rem')};
  padding: 0.5rem 0.25rem 0.5rem 1rem;
  background-color: ${(props) => getBackgroundColor(props.open, props.error)};
  border: 1px solid ${(props) => getBorderColor(props.open, props.error, false)};

  &:hover {
    border: 1px solid
      ${(props) => getBorderColor(props.open, props.error, true)};
    color: ${(props) => getTextColor(props.open, props.isPlaceholder, true)};
  }
`

const Label = styled.div`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: 0.25rem;
`

const CaretWrapper = styled.div<{ open: boolean }>`
  width: ${(props) => props.theme.pxToRem(24)};
  height: ${(props) => props.theme.pxToRem(24)};
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
  transition: all 0.2s ease-in-out;
  margin-left: auto;
`

const StyledCalendarIcon = styled(CalendarIcon)`
  width: ${(props) => props.theme.pxToRem(24)};
  height: ${(props) => props.theme.pxToRem(24)};
  margin-right: 0.5rem;
`

const Error = styled.div`
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.palette.secondary.red1};
  margin-top: 0.25rem;
`

const getBorderColor = (isOpen: boolean, error: boolean, hover: boolean) => {
  if (isOpen) {
    return palette.text.secondary
  } else if (error) {
    return palette.secondary.red1
  } else if (hover) {
    return palette.text.secondary
  } else {
    return palette.grey.grey3
  }
}

const getBackgroundColor = (isOpen: boolean, error: boolean) => {
  if (isOpen) {
    return palette.white
  } else if (error) {
    return palette.secondary.red2
  } else {
    return palette.secondary.purple7
  }
}

const getTextColor = (
  isOpen: boolean,
  isPlaceholder: boolean,
  hover: boolean
) => {
  if (isOpen) {
    return palette.text.primary
  } else if (isPlaceholder && !hover) {
    return palette.text.placeholder
  } else if (isPlaceholder && hover) {
    return palette.text.secondary
  } else {
    return palette.text.primary
  }
}

interface MonthRangePickerProps extends SpaceProps, LayoutProps, FlexboxProps {
  selectedStartDate: YearMonth
  startDateSetter: React.Dispatch<React.SetStateAction<YearMonth>>
  selectedEndDate: YearMonth
  endDateSetter: React.Dispatch<React.SetStateAction<YearMonth>>
  error: boolean
  errorMessage: string
  errorSetter: React.Dispatch<React.SetStateAction<boolean>>
  label?: string
  placeholder?: string
}

const MonthRangePicker = React.memo((props: MonthRangePickerProps) => {
  const {
    selectedStartDate,
    startDateSetter: setStartDate,
    selectedEndDate,
    endDateSetter: setEndDate,
    error,
    errorMessage,
    errorSetter: setError,
    label,
    placeholder,
    ...rest
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [activeYear, setActiveYear] = useState(moment().format('YYYY'))
  const [previewText, setPreviewText] = useState(placeholder)

  // buffers used to keep track of last "successful input" date field value
  const [startDateBuffer, setStartDateBuffer] = useState(selectedStartDate)
  const [endDateBuffer, setEndDateBuffer] = useState(selectedEndDate)

  const containerRef = useRef(null)
  const dropdownRef = useRef(null)

  // close the calendar dropdown when clicking outside of the container
  // - do not save field values when
  const clickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        containerRef &&
        containerRef.current &&
        !containerRef.current.contains(e.target)
      ) {
        setStartDate(startDateBuffer)
        setEndDate(endDateBuffer)
        setIsOpen(false)
      }
    },
    [
      containerRef,
      startDateBuffer,
      endDateBuffer,
      setIsOpen,
      setStartDate,
      setEndDate,
    ]
  )
  useEffect(() => {
    document.addEventListener('mousedown', clickOutside, false)
    return () => {
      document.removeEventListener('mousedown', clickOutside, false)
    }
  }, [clickOutside])

  // update the dropdown preview text depending on current months selection
  useEffect(() => {
    const shortMonths = moment.monthsShort()
    const startDateText =
      selectedStartDate &&
      `${shortMonths[selectedStartDate.month - 1]} ${selectedStartDate.year}`
    const endDateText =
      selectedEndDate &&
      `${shortMonths[selectedEndDate.month - 1]} ${selectedEndDate.year}`

    if (selectedStartDate && !selectedEndDate) {
      setPreviewText(startDateText)
    } else if (selectedStartDate && selectedEndDate) {
      setPreviewText(startDateText + ' - ' + endDateText)
    } else {
      setPreviewText(placeholder)
    }
  }, [selectedStartDate, selectedEndDate, placeholder])

  return (
    <>
      {label && <Label>{label}</Label>}
      <Container ref={containerRef} {...rest}>
        <Content
          open={isOpen}
          error={error}
          isPlaceholder={true}
          onClick={() => {
            setIsOpen(!isOpen)
            setStartDate(startDateBuffer)
            setEndDate(endDateBuffer)
          }}
          ref={dropdownRef}
        >
          <StyledCalendarIcon color={palette.text.primary} />
          <Text variant='action4'>{previewText}</Text>
          <CaretWrapper open={isOpen}>
            <CaretIcon color={palette.text.primary} height={24} width={24} />
          </CaretWrapper>
        </Content>
        {isOpen && (
          <DropdownCalendar
            top={dropdownRef.current.getBoundingClientRect().height - 1}
            activeYear={activeYear}
            activeYearSetter={setActiveYear}
            selectedStartDate={selectedStartDate}
            startDateSetter={setStartDate}
            selectedEndDate={selectedEndDate}
            endDateSetter={setEndDate}
            onClear={() => {
              setStartDate(null)
              setEndDate(null)
              setStartDateBuffer(null)
              setEndDateBuffer(null)
            }}
            onApply={() => {
              setIsOpen(false)
              setStartDateBuffer(selectedStartDate)
              setEndDateBuffer(selectedEndDate)

              if (selectedStartDate) {
                setError(false)
              }
            }}
          />
        )}
        {error && <Error>{errorMessage}</Error>}
      </Container>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MonthRangePicker.displayName = 'MonthRangePicker'
}

export default MonthRangePicker
