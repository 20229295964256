import React from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import FlexContainer from 'core-system/FlexContainer'
import Button from 'core-system/Button'
import DownloadIcon from 'core-system/Icons/Actions/Download'
import palette from 'core-system/Themes/palette'
import Table, {
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import FormattingUtils from 'shared/FormattingUtils'
import moment from 'moment'
import { GrhUsageHistory } from 'redux/grh/grhTypes'
import csvDownload from 'json-to-csv-export'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-top: 1rem;
`
const EmptyTableRow = styled(TableRow)`
  text-align: center;
`
const UserContainer = styled.div`
  display: flex;
`
const NameContainer = styled.div`
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`

const getTableRows = (usageHistory: GrhUsageHistory[]) => {
  return usageHistory.map((usage: GrhUsageHistory, idx: number) => {
    const date = moment(usage.date).format('MMM Do, YYYY')
    const time = moment(usage.date).format('LT')
    const formattedReason = usage.reason.startsWith('Other')
      ? 'Other'
      : usage.reason
    return (
      <TableRow key={idx} display='flex'>
        <TableRowContents isClickable={false} autoResize={false}>
          <TableRowCell width='25%' equalSize={false}>
            <UserContainer>
              <PlatformSvg
                folder='users'
                variant='userBasic'
                width={40}
                height={40}
              />
              <NameContainer>
                <Text variant='action4' hideOverflow>
                  {usage.name}
                </Text>
                <Text variant='body2' textColor={palette.text.placeholder}>
                  {usage.employeeCorporateId
                    ? `#${usage.employeeCorporateId}`
                    : ''}
                </Text>
              </NameContainer>
            </UserContainer>
          </TableRowCell>
          <TableRowCell width='15%' equalSize={false}>
            <NameContainer style={{ marginLeft: '0' }}>
              <Text variant='action4' hideOverflow>
                {date}
              </Text>
              <Text variant='body2' textColor={palette.text.placeholder}>
                {time}
              </Text>
            </NameContainer>
          </TableRowCell>
          <TableRowCell width='8%' equalSize={false}>
            <Text variant='action4'>
              {usage.amount
                ? FormattingUtils.formatDollar(usage.amount, 2)
                : '--'}
            </Text>
          </TableRowCell>
          <TableRowCell width='20%' equalSize={false}>
            <Text variant='action4' hideOverflow>
              {formattedReason}
            </Text>
          </TableRowCell>
          <TableRowCell width='20%' equalSize={false}>
            <Text variant='action4' hideOverflow>
              {usage.email || '-'}
            </Text>
          </TableRowCell>
        </TableRowContents>
      </TableRow>
    )
  })
}

interface GRHReasonsProps {
  usageHistory: GrhUsageHistory[]
  segmentName: string
}

const GRHReasons = React.memo((props: GRHReasonsProps) => {
  const { usageHistory, segmentName } = props
  const displayYear = moment().format('YYYY')

  const handleDownloadBtn = () => {
    const csvData = usageHistory.map((transaction: GrhUsageHistory) => {
      return {
        ...transaction,
        amount: FormattingUtils.formatDollar(
          transaction.amount ? transaction.amount : 0
        ),
      }
    })
    csvDownload({
      data: csvData,
      filename: `grh_usage_report_${segmentName}`,
      delimiter: ',',
      headers: [
        'reason',
        'email',
        'name',
        'date',
        'employeeCorporateId',
        'amount',
      ],
    })
  }

  return (
    <Container>
      <FlexContainer centerApart={true}>
        <div>
          <Text variant='h5'>Past Transactions</Text>
          <Text
            variant='action4'
            fontWeight={600}
            textColor={palette.text.secondary}
            captionTitle={true}
          >
            For {displayYear}
          </Text>
        </div>
        <Button
          size='small'
          iconLeft={<DownloadIcon height={18} width={18} />}
          onClick={handleDownloadBtn}
        >
          Download Transactions Report
        </Button>
      </FlexContainer>
      <Table hasHeader={false} maxRows={10} loadMoreAnimation={false}>
        <TableLabelRow padding='1rem 1.5rem' overflow='hidden'>
          <TableRowCell width='25%' equalSize={false}>
            Commuter
          </TableRowCell>
          <TableRowCell width='15%' equalSize={false}>
            Transaction Date
          </TableRowCell>
          <TableRowCell width='8%' equalSize={false}>
            Amount
          </TableRowCell>
          <TableRowCell width='20%' equalSize={false}>
            Reason
          </TableRowCell>
          <TableRowCell width='20%' equalSize={false}>
            Email
          </TableRowCell>
        </TableLabelRow>
        {usageHistory.length > 0 ? (
          getTableRows(usageHistory)
        ) : (
          <EmptyTableRow>No Transactions</EmptyTableRow>
        )}
      </Table>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHReasons.displayName = 'GRHReasons'
}

export default GRHReasons
