import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d='M7.63806 4.46694C7.63806 4.46694 13.141 1.02702 23.4903 0.716012C23.9824 0.700914 25 0.692611 25 1.44748C20.4708 8.24127 18.2062 18.8094 18.2062 18.8094L7.63806 4.46694Z'
      fill='#F4900C'
    />
    <path
      d='M8.39294 9.75087C8.39294 9.75087 17.4513 1.44734 23.4903 0.715873C23.9787 0.656238 25 0.692472 25 1.44734C19.7159 4.4668 15.9416 15.7898 15.9416 15.7898L8.39294 9.75087Z'
      fill='#FFAC33'
    />
    <path
      d='M10.7753 7.06208C11.3988 7.61086 12.5779 7.94074 13.3955 7.79581L17.0883 7.14209C17.9065 6.99716 18.3059 7.50292 17.9775 8.26533L16.4919 11.7098C16.1628 12.4722 16.2134 13.6951 16.6036 14.4288L18.3663 17.7382C18.7573 18.4719 18.3995 19.0078 17.5729 18.9308L13.8386 18.5813C13.012 18.5043 11.8638 18.9301 11.2879 19.5279L8.68359 22.2281C8.10762 22.826 7.48712 22.6523 7.30445 21.8416L6.4824 18.1828C6.30047 17.3728 5.54032 16.4126 4.79376 16.0488L1.42177 14.4069C0.67521 14.0438 0.64879 13.3992 1.36289 12.9757L4.58919 11.0629C5.3033 10.6394 5.98192 9.62032 6.09666 8.79751L6.61676 5.08357C6.7315 4.26077 7.33615 4.03657 7.95967 4.58536L10.7753 7.06208V7.06208Z'
      fill='#FFD983'
    />
  </>,
  'Flex',
  '0 0 25 23'
)
