// https://css-tricks.com/debouncing-throttling-explained-examples/
// https://github.com/mui-org/material-ui/blob/af13581127278bf295c64cacb9ed101f00e76262/packages/material-ui/src/utils/debounce.js#L3
// https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940
export type Debounce = {
  (...args: any): void
  clear: () => void
}

export function VoidDebounce() {
  function debounced() {
    // do nothing
  }
  debounced.clear = () => {
    // do nothing
  }
  return debounced
}

export default function (func: (...args: any) => void, wait = 166): Debounce {
  let timeout: ReturnType<typeof setTimeout>
  const debounced = (...args: any) => {
    timeout = setTimeout(() => func(...args), wait)
  }

  debounced.clear = () => {
    clearTimeout(timeout)
  }

  return debounced
}
