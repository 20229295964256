import React from 'react'
import styled from 'styled-components'
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import Button from 'core-system/Button'

const StyledFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

interface TemplateSurveyModalProps {
  closeModal: (e: React.MouseEvent<HTMLElement>) => void
  open: boolean
  surveyLink: string
  title: string
}

const TemplateSurvey = (props: TemplateSurveyModalProps) => {
  const { closeModal, open, surveyLink, title } = props

  return (
    <Modal open={open} onClose={closeModal} width={pxToRem(752)}>
      <ModalHeader title={title} />
      <ModalBody height={pxToRem(500)} p={0} style={{ overflow: 'hidden' }}>
        <StyledFrame src={surveyLink} />
      </ModalBody>
      <ModalFooter backgroundColor={'#fff'}>
        <Button variant='tertiary' onClick={closeModal} marginRight={'1rem'}>
          close
        </Button>
      </ModalFooter>
    </Modal>
  )
}
// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TemplateSurvey.displayName = 'TemplateSurvey'
}

export default TemplateSurvey
