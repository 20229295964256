import { userActions } from 'redux/user/userSlice'
import Button from 'core-system/Button'
import AccountCircleIcon from 'core-system/Icons/Accounts/AccountCircle'
import SubscriptionIcon from 'core-system/Icons/Accounts/Subscription'
import Loading from 'core-system/Loading'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppState } from 'redux/config/store'
import { Employee } from 'redux/employee/employeeTypes'
import {
  Employer as EmployerProfile,
  Worksite,
} from 'redux/employer/employerTypes'
import { User } from 'redux/user/userTypes'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import SecondarySidebarItem from '../Sidebar/SecondarySidebar/SecondarySidebarItem'
import SecondarySidebarView from '../Sidebar/SecondarySidebar/SecondarySidebarView'
import BillingView from './Payments/BillingView'
import UsersView from './Users/UsersView'
import ProductsView from './Products/ProductsView'
import { Summary } from 'core-system/Icons/Misc'

const Container = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.text.primary};

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: column-reverse;
  }
`

const MainView = styled.div`
  width: 100%;
  overflow: auto;
  padding: 2rem 4rem;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  max-width: 75rem;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    padding: 1.875rem 1rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin: 0 0 5rem 0;
    padding: 2rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 1rem 1.5rem 2rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    padding: 1rem 0.5rem 2rem;
  }
`

const LogoutBtn = styled(Button)`
  margin-top: auto;
  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin: auto 0 auto auto;
  }
`

const renderContent = (
  activePage: number,
  allEmployees: Employee[],
  employerInfo: {
    profile: EmployerProfile
    allWorksites: Worksite[]
  },
  user: User
) => {
  switch (activePage) {
    case 0: {
      //account for users without a worksite
      const noWorksite = {
        id: 'no-worksite',
        alias: 'No Worksite',
        address: '',
        employeeCount: null,
        employeeArchivedCount: null,
        contractorCount: null,
        formattedAddress: '',
        city: '',
        iconId: null,
        physical: false,
        shipped: null,
        cardsActivated: null,
      }

      return (
        <UsersView
          allEmployees={allEmployees}
          allWorksites={[...employerInfo.allWorksites, noWorksite]}
          user={{ ...user }}
        />
      )
    }
    case 1:
      return <BillingView />
    case 2:
      return <ProductsView />
    default:
      return null
  }
}

const AccountsView = React.memo(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [activePage, setActivePage] = useState(0)
  const user = useSelector((state: AppState) => state.user.profile)
  const allPrograms = useSelector((state: AppState) => state.programs.programs)
  const allEmployees = useSelector(
    (state: AppState) => state.employee.list,
    shallowEqual
  )
  const employerInfo = useSelector((state: AppState) => {
    return {
      profile: state.employer.profile,
      allWorksites: state.employer.worksites,
    }
  })

  useEffect(() => {
    if (activePage !== 0 && location.pathname === Locations.Accounts.Profile) {
      setActivePage(0)
    } else if (
      activePage !== 1 &&
      location.pathname === Locations.Accounts.Billing
    ) {
      setActivePage(1)
    } else if (
      activePage !== 2 &&
      location.pathname === Locations.Accounts.Products
    ) {
      setActivePage(2)
    }
  }, [location.pathname, activePage])

  return (
    <>
      <Container>
        <SecondarySidebarView hasTitle={false}>
          <SecondarySidebarItem
            label='Account'
            icon={<AccountCircleIcon />}
            isActive={activePage === 0}
            onClick={() => navigate(Locations.Accounts.Profile)}
          />
          <SecondarySidebarItem
            label='Billing'
            icon={<SubscriptionIcon />}
            isActive={activePage === 1}
            onClick={() => navigate(Locations.Accounts.Billing)}
            data-cy='accounts-secondary-sidebar-billing-btn'
          />
          <SecondarySidebarItem
            label='Products'
            icon={<Summary />}
            isActive={activePage === 2}
            onClick={() => navigate(Locations.Accounts.Products)}
          />
          <LogoutBtn onClick={() => dispatch(userActions.logout())}>
            Logout
          </LogoutBtn>
        </SecondarySidebarView>
        <MainView>
          {user && employerInfo && allEmployees && allPrograms ? (
            renderContent(activePage, allEmployees, employerInfo, user)
          ) : (
            <Loading isCard={false} height='100%' />
          )}
        </MainView>
      </Container>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AccountsView.displayName = 'AccountsView'
}

export default AccountsView
