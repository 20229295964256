import React from 'react'
import styled, { css } from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  grid,
  GridProps,
} from 'styled-system'

const Container = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & GridProps & { defaultView: boolean }
>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  ${(props) =>
    props.defaultView &&
    css`
      margin-bottom: 10rem;
      grid-template-columns: 64.5% 34.5%;

      @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
        grid-template-columns: 100%;
        max-width: ${(props) => props.theme.pxToRem(700)};
        grid-gap: unset;
      }
    `}

  ${flexbox}
  ${space}
  ${layout}
  ${grid}
`

interface ColumnViewProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    GridProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  defaultView?: boolean
}

const ColumnView = React.memo((props: ColumnViewProps) => {
  const { children, defaultView } = props
  return (
    <Container defaultView={defaultView} {...props}>
      {children}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ColumnView.displayName = 'ColumnView'
}

export default ColumnView
