import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'core-system/Button'
import AddressAutocomplete from 'core-system/Map/AddressAutocomplete/AddressAutocomplete'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import {
  CarpoolGroup,
  CarpoolGroupCreatePayload,
} from 'redux/employeePlatform/employeePlatformTypes'
import styled from 'styled-components'
import TimeSelector from 'employee-platform/shared/components/TimeSelector'
import TextToggle from 'core-system/TextToggle'
import TextField from 'core-system/TextField'
import { AppState } from 'redux/config/store'
import Dropdown, { SelectItemProps } from 'core-system/Dropdown'
import Checkbox from 'core-system/Checkbox'

const NUM_SEATS_OPTIONS: SelectItemProps[] = Array.from(
  { length: 6 },
  (_, i) => ({
    id: (i + 2).toString(),
    text: (i + 2).toString(),
  })
)

const DEFAULT_CARPOOL_STATE: CarpoolGroupCreatePayload = {
  source: null,
  dest: null,
  workArrivalTime: null,
  workDepartureTime: null,
  driver: true,
  passenger: false,
  vanpoolInterest: false,
  contactInfo: null,
  numSeats: 5,
}

const FormField = styled.div<{ isRow?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isRow ? 'row' : 'column')};
  margin-bottom: 1rem;
  ${(props) => (props.isRow ? 'justify-content: space-between' : '')}
`

interface UpdateCreateCarpoolModalProps {
  open: boolean
  closeModal: () => void
  carpool?: CarpoolGroup
}

const UpdateCreateCarpoolModal = React.memo(
  (props: UpdateCreateCarpoolModalProps) => {
    const { open, closeModal, carpool } = props

    const { email } = useSelector(
      (state: AppState) => state.employeePlatform.profileData
    )

    const dispatch = useDispatch()

    const [carpoolDetails, setCarpoolDetails] =
      useState<CarpoolGroupCreatePayload>(
        carpool
          ? {
              source: carpool.source,
              dest: carpool.dest,
              workArrivalTime: carpool.workArrivalTime,
              workDepartureTime: carpool.workDepartureTime,
              driver: carpool.driver,
              passenger: carpool.passenger,
              vanpoolInterest: carpool.vanpoolInterest,
              contactInfo: carpool.contactInfo,
              numSeats: carpool.numSeats,
            }
          : {
              ...DEFAULT_CARPOOL_STATE,
              contactInfo: email,
            }
      )

    const missingRequiredFields =
      carpoolDetails.source === null ||
      carpoolDetails.dest === null ||
      carpoolDetails.workArrivalTime === null ||
      carpoolDetails.workDepartureTime === null ||
      carpoolDetails.numSeats === null

    const handleCloseModal = () => {
      setCarpoolDetails(DEFAULT_CARPOOL_STATE)
      closeModal()
    }

    const handleSubmit = () => {
      dispatch(employeePlatformActions.createCarpoolGroup(carpoolDetails))
      handleCloseModal()
    }

    return (
      <Modal open={open} onClose={handleCloseModal} width={pxToRem(450)}>
        <ModalHeader
          title={`${carpool ? 'Edit' : 'Create'} Carpool Group`}
          onClose={handleCloseModal}
        />
        <ModalBody>
          <FormField>
            <TextToggle
              label='I am a...'
              options={['Driver', 'Passenger']}
              toggled={carpoolDetails.driver ? 0 : 1}
              onClick={() =>
                setCarpoolDetails({
                  ...carpoolDetails,
                  driver: !carpoolDetails.driver,
                  passenger: !carpoolDetails.passenger,
                })
              }
              fullWidth={true}
              variant='dark'
            />
          </FormField>
          <FormField>
            <AddressAutocomplete
              label='Depart From *'
              onActiveChange={(source) =>
                setCarpoolDetails({
                  ...carpoolDetails,
                  source: { type: 'Point', coordinates: source.coordinates },
                })
              }
              hasError={false}
            />
          </FormField>
          <FormField>
            <AddressAutocomplete
              label='Work Address *'
              onActiveChange={(dest) =>
                setCarpoolDetails({
                  ...carpoolDetails,
                  dest: { type: 'Point', coordinates: dest.coordinates },
                })
              }
              hasError={false}
            />
          </FormField>
          <FormField isRow>
            <TimeSelector
              value={carpoolDetails.workArrivalTime}
              onChange={(time) =>
                setCarpoolDetails({ ...carpoolDetails, workArrivalTime: time })
              }
              label='Work Arrival Time *'
              timeInterval={15}
            />
            <TimeSelector
              value={carpoolDetails.workDepartureTime}
              onChange={(time) =>
                setCarpoolDetails({
                  ...carpoolDetails,
                  workDepartureTime: time,
                })
              }
              label='Work Departure Time *'
              timeInterval={15}
            />
          </FormField>
          <FormField>
            <TextField
              label='Contact Email *'
              type='email'
              value={carpoolDetails.contactInfo}
              onChange={(e) =>
                setCarpoolDetails({
                  ...carpoolDetails,
                  contactInfo: e.currentTarget.value,
                })
              }
              invalid={false}
              helpText='Please enter a valid email.'
              data-cy='carpool-creation-email-input'
            />
          </FormField>
          <FormField>
            <Dropdown
              label='Number of Seats *'
              items={NUM_SEATS_OPTIONS}
              active={carpoolDetails.numSeats.toString()}
              itemCallback={(item) =>
                setCarpoolDetails({
                  ...carpoolDetails,
                  numSeats: parseInt(item.id),
                })
              }
              usePortal={true}
              selectorMaxHeight='5rem'
              data-cy='carpool-creation-num-seats-dropdown'
            />
          </FormField>
          <Checkbox
            checked={carpoolDetails.vanpoolInterest}
            onClick={() =>
              setCarpoolDetails({
                ...carpoolDetails,
                vanpoolInterest: !carpoolDetails.vanpoolInterest,
              })
            }
            marginY='0.75rem'
            label='Add me to the vanpool interest list'
          />
        </ModalBody>
        <ModalFooter>
          <Button variant='tertiary' onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={missingRequiredFields}
            marginLeft='1rem'
          >
            {carpool ? 'Update' : 'Create'}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  UpdateCreateCarpoolModal.displayName = 'UpdateCreateCarpoolModal'
}

export default UpdateCreateCarpoolModal
