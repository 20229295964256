import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M24 5C22.89 4.65 21.67 4.5 20.5 4.5C18.55 4.5 16.45 4.9 15 6C13.55 4.9 11.45 4.5 9.5 4.5C7.55 4.5 5.45 4.9 4 6V20.65C4 20.9 4.25 21.15 4.5 21.15C4.6 21.15 4.65 21.1 4.75 21.1C6.1 20.45 8.05 20 9.5 20C11.45 20 13.55 20.4 15 21.5C16.35 20.65 18.8 20 20.5 20C22.15 20 23.85 20.3 25.25 21.05C25.35 21.1 25.4 21.1 25.5 21.1C25.75 21.1 26 20.85 26 20.6V6C25.4 5.55 24.75 5.25 24 5ZM24 18.5C22.9 18.15 21.7 18 20.5 18C18.8 18 16.35 18.65 15 19.5V8C16.35 7.15 18.8 6.5 20.5 6.5C21.7 6.5 22.9 6.65 24 7V18.5Z' />
    <path d='M20.662 10.4118C21.542 10.4118 22.392 10.5018 23.162 10.6718V9.15183C22.372 9.00183 21.522 8.91183 20.662 8.91183C18.962 8.91183 17.422 9.20183 16.162 9.74183V11.4018C17.292 10.7618 18.862 10.4118 20.662 10.4118Z' />
    <path d='M16 12.49V14.15C17.13 13.51 18.7 13.16 20.5 13.16C21.38 13.16 22.23 13.25 23 13.42V11.9C22.21 11.75 21.36 11.66 20.5 11.66C18.8 11.66 17.26 11.96 16 12.49Z' />
    <path d='M20.5 14.33C18.8 14.33 17.26 14.62 16 15.16V16.82C17.13 16.18 18.7 15.83 20.5 15.83C21.38 15.83 22.23 15.92 23 16.09V14.57C22.21 14.41 21.36 14.33 20.5 14.33Z' />
  </>,
  'Provider Catalog'
)
