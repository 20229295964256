import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import * as LeaderboardIcons from 'core-system/Icons/Leaderboards'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import ProgramChange from 'core-system/Program/ProgramChange'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { LeaderboardManageState } from 'redux/leaderboards/leaderboardsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
`

// const IconContainer = styled.div`
//   margin-bottom: 0.25rem;
//   height: 1.5rem;
// `

const getDescriptionText = (canEdit: boolean, isPending: boolean) => {
  if (isPending) {
    return 'Update this competition to apply these changes.'
  } else {
    return canEdit
      ? 'Update this competition to apply these changes to the following month.'
      : 'All upcoming changes will be applied to the competition the following month.'
  }
}

interface LeaderboardManagePendingProps {
  leaderboardState: LeaderboardManageState
  canEdit?: boolean
  isPending: boolean
  openUpdateModal: () => void
}

const LeaderboardManagePending = React.memo(
  (props: LeaderboardManagePendingProps) => {
    const { leaderboardState, canEdit, isPending, openUpdateModal } = props

    const currentBudget = FormattingUtils.formatDollar(
      leaderboardState.active.bonuses.reduce((sum, bonus) => sum + bonus, 0),
      0
    )
    const nextMonthBudget = FormattingUtils.formatDollar(
      leaderboardState.nextMonth.bonuses.reduce((sum, bonus) => sum + bonus, 0),
      0
    )

    // const activeEndDate =
    //   leaderboardState.active.endDate &&
    //   moment(leaderboardState.active.endDate).format('MMMM Do, YYYY')
    // const nextMonthEndDate =
    //   leaderboardState.nextMonth.endDate &&
    //   moment(leaderboardState.nextMonth.endDate).format('MMMM Do, YYYY')

    const hasChanges =
      currentBudget !== nextMonthBudget ||
      // activeEndDate !== nextMonthEndDate ||
      leaderboardState.nextMonth.status === 'CANCELLING'

    return (
      <>
        {hasChanges ? (
          <Container>
            <Text variant='h4' marginBottom='0.5rem'>
              {canEdit ? 'Pending Changes' : 'Upcoming Changes'}
            </Text>
            <Text variant='body1' marginBottom='1.5rem'>
              {getDescriptionText(canEdit, isPending)}
            </Text>
            {leaderboardState.nextMonth.status === 'CANCELLING' && (
              <>
                <Divider margin='1.5rem 0' />
                <Text
                  variant='caption'
                  captionTitle
                  textColor={palette.text.secondary}
                  marginBottom='0.5rem'
                >
                  Competition Cancellation Request
                </Text>
                <Text variant='body2'>
                  Our team will be in touch with you soon.
                </Text>
                {canEdit && (
                  <Button
                    onClick={openUpdateModal}
                    width='100%'
                    marginTop='1rem'
                  >
                    Reactivate Competition
                  </Button>
                )}
              </>
            )}
            {currentBudget !== nextMonthBudget && (
              <>
                <ProgramChange
                  title='Budget'
                  metric='Total Monthly Rewards'
                  icon={<PlatformSvg folder='metrics' variant='money' />}
                  value={`${currentBudget} ➞ ${nextMonthBudget}`}
                />
                {leaderboardState.active.bonuses[0] !==
                  leaderboardState.nextMonth.bonuses[0] && (
                  <ProgramChange
                    metric='1st Place'
                    icon={<LeaderboardIcons.GoldMedal />}
                    value={`${FormattingUtils.formatDollar(
                      leaderboardState.active.bonuses[0],
                      0
                    )} ➞ ${FormattingUtils.formatDollar(
                      leaderboardState.nextMonth.bonuses[0],
                      0
                    )}`}
                  />
                )}
                {leaderboardState.active.bonuses[1] !==
                  leaderboardState.nextMonth.bonuses[1] && (
                  <ProgramChange
                    metric='2nd Place'
                    icon={<LeaderboardIcons.SilverMedal />}
                    value={`${FormattingUtils.formatDollar(
                      leaderboardState.active.bonuses[1],
                      0
                    )} ➞ ${FormattingUtils.formatDollar(
                      leaderboardState.nextMonth.bonuses[1],
                      0
                    )}`}
                  />
                )}
                {leaderboardState.active.bonuses[2] !==
                  leaderboardState.nextMonth.bonuses[2] && (
                  <ProgramChange
                    metric='3rd Place'
                    icon={<LeaderboardIcons.BronzeMedal />}
                    value={`${FormattingUtils.formatDollar(
                      leaderboardState.active.bonuses[2],
                      0
                    )} ➞ ${FormattingUtils.formatDollar(
                      leaderboardState.nextMonth.bonuses[2],
                      0
                    )}`}
                  />
                )}
              </>
            )}
            {/* {activeEndDate !== nextMonthEndDate && (
              <ProgramChange
                title='Date'
                metric='End Date'
                icon={
                  <IconContainer>
                    <CalendarIcon />
                  </IconContainer>
                }
                value={`${activeEndDate ? activeEndDate : 'None'} ➞ ${
                  nextMonthEndDate ? nextMonthEndDate : 'None'
                }`}
              />
            )} */}
          </Container>
        ) : null}
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardManagePending.displayName = 'LeaderboardManagePending'
}

export default LeaderboardManagePending
