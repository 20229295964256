import Request from 'redux/config/services/Request'
import { AxiosResponse } from 'axios'
import { SegmentParams } from 'redux/employer/employerTypes'
import { OtpProgram, OtpProgramUpdate } from './otpTypes'
import {
  SegmentAndType,
  SegmentAndTypeAndDate,
} from 'redux/programs/programsTypes'

const getOtpRecommendations = (
  payload: SegmentParams
): Promise<AxiosResponse> => {
  return Request.get(`/hr/programs/merchants/?segment=${payload.segmentId}`)
}

const createOtpProgram = (payload: OtpProgram): Promise<AxiosResponse> => {
  return Request.post('/hr/programs/card_programs/', payload)
}

const updateOtpProgram = (
  payload: OtpProgramUpdate
): Promise<AxiosResponse> => {
  const { programId, ...rest } = payload
  return Request.put(`/hr/programs/card_programs/${programId}/`, rest)
}

const cancelOtpProgram = (programId: string): Promise<AxiosResponse> => {
  return Request.del(`/hr/programs/card_programs/${programId}/`)
}

const getOtpDashboardStats = (
  segmentAndType: SegmentAndType
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/programs/card_programs/dashboard/?segment=${segmentAndType.segmentId}&type=${segmentAndType.type}`
  )
}

const getOtpDashboardServices = (
  segmentAndTypeAndDate: SegmentAndTypeAndDate
): Promise<AxiosResponse> => {
  return Request.post('/hr/programs/card_programs/services/', {
    rangeEnd: segmentAndTypeAndDate.rangeEnd,
    rangeStart: segmentAndTypeAndDate.rangeStart,
    segment: segmentAndTypeAndDate.segmentId,
    type: segmentAndTypeAndDate.type,
  })
}

const getOtpUsageHistory = (
  segmentAndType: SegmentAndType
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/programs/card_programs/transaction_requests/?segment=${segmentAndType.segmentId}&type=OTP`
  )
}

export default {
  getOtpRecommendations,
  createOtpProgram,
  updateOtpProgram,
  cancelOtpProgram,
  getOtpDashboardStats,
  getOtpDashboardServices,
  getOtpUsageHistory,
}
