import MicromobilityIcon from 'core-system/Icons/Sidebar/Micromobility'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import {
  MicromobilityActiveOptions,
  MicromobilityType,
} from 'redux/micromobility/micromobilityTypes'
import FormattingUtils from 'shared/FormattingUtils'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import MicromobilityUtils from '../MicromobilityUtils'

const PurpleContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`

const Options = styled.div`
  margin-top: 1.5rem;
`

const Option = styled.div`
  margin-top: 1.25rem;
  padding-top: 1.5rem;
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey4};

  &:first-of-type {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const HyperLink = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.primaryPurple};

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const OptionProviders = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
`

const getRelevantMerchantIds = (
  activeOption: MicromobilityType,
  leasingIds: string[],
  sharedIds: string[],
  oneTimeIds: string[]
) => {
  if (activeOption === 'leasing') return leasingIds
  else if (activeOption === 'shared') return sharedIds
  else if (activeOption === 'oneTime') return oneTimeIds
  else return []
}

interface MicromobilitySummaryOptionsProps {
  activeOptions: MicromobilityActiveOptions
  budget: number
  handleOpenModal: (type: MicromobilityType) => void
  activeLeasingMerchantIds: string[]
  availableLeasingMerchantIds: string[]
  activeSharedMerchantIds: string[]
  availableSharedMerchantIds: string[]
  activeOneTimeMerchantIds: string[]
  availableOneTimeMerchantIds: string[]
}

const MicromobilitySummaryOptions = React.memo(
  (props: MicromobilitySummaryOptionsProps) => {
    const {
      budget,
      activeOptions,
      handleOpenModal,
      activeLeasingMerchantIds,
      availableLeasingMerchantIds,
      activeSharedMerchantIds,
      availableSharedMerchantIds,
      activeOneTimeMerchantIds,
      availableOneTimeMerchantIds,
    } = props

    const noActiveOptions = Object.keys(activeOptions).every(
      (option) => !activeOptions[option]
    )
    const monthsLeft = MicromobilityUtils.monthsLeft

    return (
      <PurpleContainer>
        <HeaderContainer>
          <MicromobilityIcon color={palette.primary.primaryPurple} />
          <Text variant='action3' marginLeft='0.5rem'>
            Program Options
          </Text>
        </HeaderContainer>
        {noActiveOptions ? (
          <Text
            variant='body2'
            marginTop='0.5rem'
            textColor={palette.text.secondary}
          >
            No options are selected for this program
          </Text>
        ) : (
          <Options>
            {Object.keys(activeOptions).flatMap((option) => {
              if (activeOptions[option]) {
                const relevantActiveMerchantIds = getRelevantMerchantIds(
                  option as MicromobilityType,
                  activeLeasingMerchantIds,
                  activeSharedMerchantIds,
                  activeOneTimeMerchantIds
                )
                const relevantAvailableMerchantIds = getRelevantMerchantIds(
                  option as MicromobilityType,
                  availableLeasingMerchantIds,
                  availableSharedMerchantIds,
                  availableOneTimeMerchantIds
                )

                return (
                  <Option key={option}>
                    <Text
                      variant='caption'
                      textColor={palette.text.secondary}
                      captionTitle
                    >
                      {MicromobilityUtils.optionsCopy[option].title}
                    </Text>
                    {option === 'oneTime' && (
                      <>
                        <OptionProviders>
                          <Text variant='action4'>
                            Max Budget ({monthsLeft} Month
                            {RandomUtils.pluralCheck(monthsLeft)})
                          </Text>
                          <Text variant='action4'>
                            {FormattingUtils.formatDollar(
                              monthsLeft * budget,
                              0
                            )}
                          </Text>
                        </OptionProviders>
                      </>
                    )}
                    <OptionProviders>
                      <Text variant='action4'>
                        {relevantActiveMerchantIds.length} Service Provider
                        {RandomUtils.pluralCheck(
                          relevantActiveMerchantIds.length
                        )}
                      </Text>
                      {relevantAvailableMerchantIds.length > 0 && (
                        <HyperLink
                          variant='hyperlink'
                          onClick={() =>
                            handleOpenModal(option as MicromobilityType)
                          }
                        >
                          Edit
                        </HyperLink>
                      )}
                    </OptionProviders>
                  </Option>
                )
              } else {
                return []
              }
            })}
          </Options>
        )}
      </PurpleContainer>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilitySummaryOptions.displayName = 'MicromobilitySummaryOptions'
}

export default MicromobilitySummaryOptions
