import AccordionCard, { AccordionCardRow } from 'core-system/AccordionCard'
import EditIcon from 'core-system/Icons/Actions/Edit'
import Tooltip from 'core-system/Tooltip'
import DOMPurify from 'dompurify'
import React, { SyntheticEvent, useCallback, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Faq } from 'redux/concierge/conciergeTypes'
import styled from 'styled-components'

const QuestionText = styled.div`
  ${(props) => props.theme.typography.action3}
`

const AnswerText = styled.div`
  ${(props) => props.theme.typography.body1}
  padding: 0.5rem 4rem;
`

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 1rem;
`

const EditContainer = styled.div<{ show: boolean }>`
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.2s ease;
  color: ${(props) => props.theme.palette.text.secondary};

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple5};
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const sanitizeMarkup = (html: any) => {
  return {
    __html: DOMPurify.sanitize(html),
  }
}

interface FaqCardProps {
  faq: Faq
  idx: number
  editable?: boolean
}

const FaqCard = React.memo((props: FaqCardProps) => {
  const { faq, idx, editable = true } = props

  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [isEditVisible, setIsEditVisible] = useState(false)
  const [isAccordionCardOpen, setIsAccordionCardOpen] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const ref = useRef(null)

  const handleEditClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      navigate(`/concierge/faq`, {
        state: { from: location.pathname, faq, idx },
      })
    },
    [navigate, location.pathname, faq, idx]
  )

  return (
    <AccordionCard
      setIsOpen={setIsAccordionCardOpen}
      isOpen={isAccordionCardOpen}
    >
      <AccordionCardRow
        minHeight={'4rem'}
        allowHoverEffect={false}
        onMouseEnter={() => setIsEditVisible(editable ? true : false)}
        onMouseLeave={() => setIsEditVisible(false)}
      >
        <AccordionHeader>
          <QuestionText>{faq.question}</QuestionText>
          {editable ? (
            <EditContainer
              ref={ref}
              show={isEditVisible}
              onMouseEnter={() => setIsTooltipVisible(true)}
              onMouseLeave={() => setIsTooltipVisible(false)}
              onClick={handleEditClick}
              id='faq-edit-icon'
            >
              <EditIcon />
              <Tooltip
                target={ref}
                show={isTooltipVisible}
                position={'bottom'}
                marginTop={'0.25rem'}
              >
                Edit
              </Tooltip>
            </EditContainer>
          ) : null}
        </AccordionHeader>
      </AccordionCardRow>
      <AccordionCardRow allowHoverEffect={false}>
        <AnswerText dangerouslySetInnerHTML={sanitizeMarkup(faq.answer)} />
      </AccordionCardRow>
    </AccordionCard>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FaqCard.displayName = 'FaqCard'
}

export default FaqCard
