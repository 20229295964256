import Banner from 'core-system/Banner'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppState } from 'redux/config/store'
import { Locations } from 'shared/Router/Locations'

interface ProgramMissingPaymentMethodProps {
  from: string
}

const ProgramMissingPaymentMethod = React.memo(
  (props: ProgramMissingPaymentMethodProps) => {
    const { from } = props

    const navigate = useNavigate()

    const isAdminUser = useSelector(
      (state: AppState) => state.user.profile.role === 'HRadmins'
    )

    return (
      <>
        {isAdminUser ? (
          <>
            <Banner
              variant='cta'
              background='bannerCreditCard'
              title='Missing Fleet Card Information!'
              description="We're working hard to get your program up and running. But first, we need some information to set up your commuter's Fleet Cards."
              cta='Setup Fleet Cards'
              ctaCallback={() =>
                navigate(Locations.Accounts.Billing, {
                  state: { from: from },
                })
              }
            />
          </>
        ) : (
          <Banner
            variant='cta'
            title='No Billing Information!'
            description="It doesn't seem like your account has the required permissions
                to set up the Fleet Cards. We've let the admins in your
                organization know already so theres no need for you to do
                anything. Once an admin has filled out the required
                information we will continue the process and rollout your
                program."
          />
        )}
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramMissingPaymentMethod.displayName = 'ProgramMissingPaymentMethod'
}

export default ProgramMissingPaymentMethod
