import React from 'react'
import styled from 'styled-components'
import {
  MyCarpoolGroup,
  SearchedCarpoolGroup,
  CarpoolRelationType,
} from 'redux/employeePlatform/employeePlatformTypes'
import Text from 'core-system/Text'
import FlexContainer from 'core-system/FlexContainer'
import palette from 'core-system/Themes/palette'
import {
  convertMetresToMiles,
  convertTimeStringToAMPM,
} from 'employee-platform/shared/utils'
import { Employees } from 'core-system/Icons/Misc'
import Divider from 'core-system/Divider'
import Button from 'core-system/Button'
import {
  CardListCard,
  DistancesContainer,
  DistanceItem,
  InfoContainer,
  InfoItem,
} from 'employee-platform/shared/styles/CardList'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'

const getButtonText = (
  carpoolRelation: CarpoolRelationType,
  seatsLeft: number
): string => {
  if (carpoolRelation === 'MEMBER' || carpoolRelation === 'OWNER') {
    return 'View Details'
  } else if (carpoolRelation === 'REQUESTED') {
    return 'Request Sent'
  } else if (seatsLeft === 0) {
    return 'Carpool Full'
  } else {
    return 'Request to Join'
  }
}

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: space-between;
  align-items: ${(props) => (props.isMobile ? 'flex-start' : 'center')};
`

const CarpoolCapacityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`

const groupField = (label: string, value: string, isMobile: boolean) => {
  return (
    <InfoItem isMobile={isMobile}>
      <Text variant='action3'>{`${label}:`}</Text>
      <Text variant='action3' textColor={palette.text.secondary}>
        {value}
      </Text>
    </InfoItem>
  )
}

const distanceField = (source: 'You' | 'Destination', distance: number) => {
  return (
    <DistanceItem>
      <Text
        variant='action3'
        textAlign='center'
        style={{ marginBottom: '0.25rem' }}
      >{`Distance from ${source}`}</Text>
      <Text
        variant='action1'
        textColor={palette.primary.primaryPurple}
      >{`${distance} mi`}</Text>
    </DistanceItem>
  )
}

const bigNumberField = (label: string, value: number) => {
  return (
    <DistanceItem>
      <Text variant='h2' textColor={palette.primary.primaryPurple}>
        {value}
      </Text>
      <Text variant='action3' textAlign='center'>{`${label}`}</Text>
    </DistanceItem>
  )
}

interface CarpoolGroupCardProps {
  carpoolRelation: CarpoolRelationType
  groupData: MyCarpoolGroup | SearchedCarpoolGroup
  isMobile: boolean
  setSelectedCarpool?: (groupId: string) => void
  onViewDetailsClick?: (groupId: string) => void
  distanceFromHome?: number
  distanceFromWork?: number
  setShowRequestModal?: (show: boolean) => void
  hideButtons?: boolean
}

const CarpoolGroupCard = React.memo((props: CarpoolGroupCardProps) => {
  const {
    carpoolRelation,
    groupData,
    isMobile,
    setSelectedCarpool,
    onViewDetailsClick,
    distanceFromHome,
    distanceFromWork,
    setShowRequestModal,
    hideButtons = false,
  } = props

  const { receivedCarpoolRequests } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const numReceivedRequests =
    receivedCarpoolRequests && receivedCarpoolRequests[groupData.id]
      ? Object.keys(receivedCarpoolRequests[groupData.id]).length
      : 0

  const showDistanceFromYou = distanceFromHome || distanceFromHome === 0
  const showDistanceFromDestination = distanceFromWork || distanceFromWork === 0
  const buttonText = getButtonText(carpoolRelation, groupData.seatsLeft)

  const handleButtonClick = () => {
    if (carpoolRelation === 'MEMBER' || carpoolRelation === 'OWNER') {
      onViewDetailsClick(groupData.id)
    } else {
      setSelectedCarpool(groupData.id)
      setShowRequestModal(true)
    }
  }

  return (
    // IF WE WANT TO SHOW DETAILS REGARDLESS THEN SET onClick={() => onViewDetailsClick(groupData.id)}
    <CardListCard>
      <Container isMobile={isMobile && carpoolRelation !== 'OWNER'}>
        <InfoContainer>
          {groupData.workArrivalTime
            ? groupField(
                'Work Arrival Time',
                convertTimeStringToAMPM(groupData.workArrivalTime),
                isMobile
              )
            : null}
          {groupData.workDepartureTime
            ? groupField(
                'Work Departure Time',
                convertTimeStringToAMPM(groupData.workDepartureTime),
                isMobile
              )
            : null}
          {groupField(
            'Has Driver',
            groupData.hasDriver ? 'Yes' : 'No',
            isMobile
          )}
        </InfoContainer>
        {(showDistanceFromYou || showDistanceFromDestination) && !isMobile ? (
          <DistancesContainer isMobile={isMobile}>
            {showDistanceFromYou
              ? distanceField('You', convertMetresToMiles(distanceFromHome, 1))
              : null}
            {showDistanceFromYou && showDistanceFromDestination && isMobile ? (
              <Divider direction='horizontal' size='70%' margin='0.5rem 0' />
            ) : null}
            {showDistanceFromDestination
              ? distanceField(
                  'Destination',
                  convertMetresToMiles(distanceFromWork, 1)
                )
              : null}
          </DistancesContainer>
        ) : null}
        {carpoolRelation === 'OWNER'
          ? bigNumberField(
              `Request${numReceivedRequests === 1 ? '' : 's'} to Join`,
              numReceivedRequests
            )
          : null}
      </Container>
      <Divider direction='horizontal' />
      <FlexContainer flexDirection='row' justifyContent='space-between'>
        <CarpoolCapacityContainer>
          <Employees width='2rem' height='2rem' />
          <Text variant='action4'>{`${groupData.seatsLeft} / ${groupData.numSeats} Spots Open`}</Text>
        </CarpoolCapacityContainer>
        {!hideButtons ? (
          <Button
            disabled={
              carpoolRelation === 'REQUESTED' || groupData.seatsLeft === 0
            }
            onClick={() => handleButtonClick()}
            size='small'
          >
            {buttonText}
          </Button>
        ) : null}
      </FlexContainer>
    </CardListCard>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolGroupCard.displayName = 'CarpoolGroupCard'
}

export default CarpoolGroupCard
