import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='40' height='40' rx='8' fill='#D2F5F4' />
    <path
      d='M11.052 28.0965C14.8853 31.9298 23.6883 37.2616 33.6666 28.0429C28.0262 26.9578 27.0338 19.7813 23.1997 15.9488C19.3664 12.1147 14.0177 11.2479 10.1837 15.0812C6.35038 18.9145 7.21796 24.2624 11.052 28.0965Z'
      fill='#A6D388'
    />
    <path
      d='M25.052 29.0613C29.7444 30.3125 33.2132 28.4962 33.5333 28.1761C33.5333 28.1761 30.6342 29.5169 25.601 27.0458C26.33 26.1178 28.5522 24.8183 28.5522 24.8183C28.1287 24.5534 25.4295 24.646 23.5864 25.9126C22.6996 25.3513 21.7608 24.6744 20.7738 23.8642L21.417 23.1972C23.9057 20.606 26.7343 21.2186 26.7343 21.2186C26.2458 20.7492 22.4469 18.5837 19.3962 21.7546L18.9268 22.2431C18.6006 21.9376 18.2714 21.6213 17.936 21.286L16.5193 19.8693L17.3341 19.0538C19.8771 16.5116 23.6331 16.4113 23.6331 16.4113C23.1537 15.9311 18.3778 14.3828 15.2681 17.4925L14.7045 18.0553L8.35503 11.7042C7.87568 11.2241 7.0594 11.2639 6.58081 11.7425C6.10146 12.2219 6.0624 13.0374 6.54176 13.5168L12.892 19.867L12.8469 19.9122C9.73719 23.0227 11.0282 28.0551 11.5083 28.5352C11.5083 28.5352 11.8667 24.5212 14.4097 21.9789L14.7061 21.6818L17.3869 24.3635C17.4306 24.4071 17.4735 24.4446 17.5171 24.4883C15.6602 28.1615 18.4582 32.406 19.0164 32.746C19.0164 32.746 17.8946 29.1294 19.384 26.1339C20.5525 27.0489 21.6965 27.7381 22.7969 28.2435C22.8604 30.5353 24.8697 32.6082 25.3575 32.7851C25.3575 32.7851 24.6599 30.4856 24.9486 29.3492L25.052 29.0613Z'
      fill='#77B255'
    />
    <path
      d='M23.0598 13.8919C22.7565 13.8919 22.4694 13.7096 22.3492 13.411C22.1922 13.0189 22.3813 12.5748 22.7726 12.4163C22.8997 12.3642 25.8478 11.1161 26.1541 7.69705C26.1924 7.27666 26.5554 6.96883 26.9849 7.00252C27.4069 7.04004 27.717 7.41219 27.6795 7.83335C27.2874 12.2065 23.5054 13.7717 23.3446 13.836C23.2512 13.8743 23.1547 13.8919 23.0598 13.8919ZM26.8885 16.9548C26.5714 16.9548 26.2751 16.7565 26.1656 16.441C26.027 16.0428 26.236 15.6079 26.6327 15.4678C26.7767 15.4157 30.0977 14.1576 30.7524 9.1979C30.8075 8.77904 31.182 8.48193 31.6115 8.53936C32.0312 8.59449 32.326 8.9789 32.2701 9.39852C31.4883 15.333 27.3173 16.8515 27.1404 16.9127C27.0569 16.9411 26.9719 16.9548 26.8885 16.9548ZM29.1864 21.5493C28.9161 21.5493 28.655 21.4053 28.5149 21.1519C28.3112 20.782 28.4467 20.3164 28.8166 20.1128C28.8373 20.1013 30.9231 18.9366 31.574 17.4189C31.741 17.0299 32.1927 16.8507 32.5787 17.0169C32.9677 17.183 33.1476 17.6333 32.9807 18.0223C32.1208 20.0285 29.6581 21.3977 29.554 21.4551C29.4368 21.5187 29.3113 21.5493 29.1864 21.5493Z'
      fill='#5DADEC'
    />
  </>,
  'Env',
  '0 0 40 40'
)
