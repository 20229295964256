import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.812 19.5V13.188H4.5V10.812H10.812V4.5H13.188V10.812H19.5V13.188H13.188V19.5H10.812Z'
    />
  </>,
  'Add'
)
