import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='52' height='52' rx='26' fill='#F0F0FF' />
    <path
      d='M30.6667 17.3333C32.1333 17.3333 33.3333 16.1333 33.3333 14.6667C33.3333 13.2 32.1333 12 30.6667 12C29.2 12 28 13.2 28 14.6667C28 16.1333 29.2 17.3333 30.6667 17.3333ZM16.6667 26C12.9333 26 10 28.9333 10 32.6667C10 36.4 12.9333 39.3333 16.6667 39.3333C20.4 39.3333 23.3333 36.4 23.3333 32.6667C23.3333 28.9333 20.4 26 16.6667 26ZM16.6667 37.3333C14.1333 37.3333 12 35.2 12 32.6667C12 30.1333 14.1333 28 16.6667 28C19.2 28 21.3333 30.1333 21.3333 32.6667C21.3333 35.2 19.2 37.3333 16.6667 37.3333ZM24.4 24L27.6 20.8L28.6667 21.8667C30.08 23.28 31.84 24.24 33.9467 24.56C34.7467 24.68 35.4667 24.04 35.4667 23.2267C35.4667 22.5733 34.9733 22.0133 34.3333 21.9067C32.8533 21.6667 31.64 20.96 30.6667 20L28.1333 17.4667C27.4667 16.9333 26.8 16.6667 26 16.6667C25.2 16.6667 24.5333 16.9333 24.1333 17.4667L20.4 21.2C19.8667 21.7333 19.6 22.4 19.6 23.0667C19.6 23.8667 19.8667 24.5333 20.4 24.9333L24.6667 28.6667V34C24.6667 34.7333 25.2667 35.3333 26 35.3333C26.7333 35.3333 27.3333 34.7333 27.3333 34V28.1333C27.3333 27.44 27.0667 26.7867 26.6 26.2933L24.4 24ZM35.3333 26C31.6 26 28.6667 28.9333 28.6667 32.6667C28.6667 36.4 31.6 39.3333 35.3333 39.3333C39.0667 39.3333 42 36.4 42 32.6667C42 28.9333 39.0667 26 35.3333 26ZM35.3333 37.3333C32.8 37.3333 30.6667 35.2 30.6667 32.6667C30.6667 30.1333 32.8 28 35.3333 28C37.8667 28 40 30.1333 40 32.6667C40 35.2 37.8667 37.3333 35.3333 37.3333Z'
      fill='#5E5EAA'
    />
  </>,
  'ActiveModes',
  '0 0 52 52'
)
