import React from 'react'
import styled from 'styled-components'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import FormattingUtils from 'shared/FormattingUtils'
import ChipDifference from 'core-system/Chip/ChipDifference'
import palette from 'core-system/Themes/palette'
import RenewIcon from 'core-system/Icons/Actions/Renew'
import pxToRem from 'core-system/utils/pxToRem'
import { GrhDashboardStats } from 'redux/grh/grhTypes'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 1.5rem 0 1.5rem 1.5rem;
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: 1.5rem;
  }
`

const HeaderContainer = styled.div`
  width: 70%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 85%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 100%;
  }
`

const HeaderImg = styled.div`
  width: 30%;
  overflow: hidden;
  margin-left: 1.1875rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    display: none;
  }
`

const StyledDivider = styled(Divider)`
  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin: 0 5%;
  }
`

interface GRHOverviewHeaderProps {
  stats: GrhDashboardStats
  transactionsPerEmployee: number
  transactionLimit: number
  numEmployees: number
  monthsRemaining: number
}

const GRHOverviewHeader = React.memo((props: GRHOverviewHeaderProps) => {
  const {
    stats,
    transactionLimit,
    transactionsPerEmployee,
    numEmployees,
    monthsRemaining,
  } = props
  if (!stats) return <Loading height={pxToRem(287)} />

  return (
    <Container>
      <HeaderContainer>
        <FlexContainer alignItems='center'>
          <Text variant='h5'>Total Spend</Text>
        </FlexContainer>
        <FlexContainer margin='1.5rem 0 0.5rem' alignItems='center'>
          <PlatformSvg
            folder='metrics'
            variant='money'
            width={40}
            height={40}
          />
          <Text variant='h1' marginLeft='1rem'>
            {FormattingUtils.formatDollar(stats.totalSpend, 0)}
          </Text>
          <Text
            variant='body2'
            textColor={palette.text.disabled}
            alignSelf='flex-end'
            margin='0 0 0.3125rem 0.3125rem'
          >
            /{' '}
            {FormattingUtils.formatDollar(
              transactionLimit * transactionsPerEmployee * numEmployees,
              0
            )}{' '}
            month
          </Text>
          <ChipDifference
            percentDifference={stats.totalSpendDifference}
            marginLeft='3.25rem'
          />
        </FlexContainer>
        <FlexContainer alignItems='center'>
          <RenewIcon color={palette.text.secondary} />
          <Text
            variant='caption'
            captionTitle
            textColor={palette.text.secondary}
            marginLeft='0.5rem'
          >
            Program Resets in {monthsRemaining}{' '}
            {monthsRemaining === 1 ? 'Month' : 'Months'}.
          </Text>
        </FlexContainer>
        <Divider margin='1rem 0 1.5rem' />
        <FlexContainer>
          <div>
            <FlexContainer marginBottom='0.5rem' alignItems='center'>
              <PlatformSvg
                folder='metrics'
                variant='employees'
                width={40}
                height={40}
              />
              <Text variant='h3' marginLeft='1rem'>
                {FormattingUtils.formatNumber(stats.numEmployees || 0)}
              </Text>
              <ChipDifference
                percentDifference={stats.numEmployeesDifference}
                marginLeft='4rem'
              />
            </FlexContainer>
            <Text
              variant='caption'
              captionTitle
              textColor={palette.text.secondary}
            >
              Commuter Engagement
            </Text>
          </div>
          <StyledDivider
            direction='vertical'
            margin='0 3.25rem'
            size={pxToRem(49)}
          />
          <div>
            <FlexContainer marginBottom='0.5rem' alignItems='center'>
              <PlatformSvg
                folder='metrics'
                variant='money2'
                width={40}
                height={40}
              />
              <Text variant='h3' marginLeft='1rem'>
                {FormattingUtils.formatNumber(stats.numTransactions || 0)}
              </Text>
            </FlexContainer>
            <Text
              variant='caption'
              captionTitle
              textColor={palette.text.secondary}
            >
              Total Number of Transactions
            </Text>
          </div>
        </FlexContainer>
      </HeaderContainer>
      <HeaderImg>
        <PlatformSvg
          folder='banners'
          variant='banner4'
          width={431}
          height={208}
        />
      </HeaderImg>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHOverviewHeader.displayName = 'GRHOverviewHeader'
}

export default GRHOverviewHeader
