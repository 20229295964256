import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import GridCard from 'employee-platform/shared/components/GridCard'
import CarpoolGroupCard from './components/CarpoolGroupCard'
import Button from 'core-system/Button'
import UpdateCreateCarpoolModal from './components/UpdateCreateCarpoolModal'
import CarpoolSearch from './components/CarpoolSearch'
import { useNavigate } from 'react-router'
import SegmentService from 'redux/config/services/SegmentService'
import { Locations } from 'shared/Router/Locations'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import CarpoolRequestModal from './components/CarpoolRequestModal'
import Loading from 'core-system/Loading'
import { AutocompleteResult } from 'core-system/Map/AddressAutocomplete/AddressAutocompleteField'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'

const CarpoolOverviewView = React.memo(() => {
  const { myCarpoolGroups, carpoolMemberships } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const navigate = useNavigate()

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const [selectedCarpoolId, setSelectedCarpoolId] = useState<string | null>(
    null
  )
  const [source, setSource] = useState<AutocompleteResult | null>(null)
  const [destination, setDestination] = useState<AutocompleteResult | null>(
    null
  )
  const [showCreateCarpoolModal, setShowCreateCarpoolModal] = useState(false)
  const [showCarpoolRequestModal, setShowCarpoolRequestModal] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)
  const [sourceAddress, setSourceAddress] = useState<string>('')
  const [destAddress, setDestAddress] = useState<string>('')

  const showCarpoolDetails = useCallback(
    (groupId: string) => {
      navigate(`${groupId}`, {
        state: { from: Locations.EmployeeCarpool },
      })
      SegmentService.track('carpool-group-click', {
        action: `carpool-group-${groupId}`,
        locationAt: 'employee-platform-carpool-overview',
      })
    },
    [navigate]
  )

  return (
    <div>
      <GridContainer numColumns={2}>
        <GridContainer numColumns={1} fitContentHeight>
          <GridContainer numColumns={1} fitContentHeight>
            <GridCard headerTitle='My Carpool Group'>
              {myCarpoolGroups && Object.keys(myCarpoolGroups).length > 0 ? (
                Object.keys(myCarpoolGroups).map((groupId: string) => {
                  const groupData = myCarpoolGroups[groupId]
                  return (
                    <CarpoolGroupCard
                      key={groupId}
                      carpoolRelation={'OWNER'}
                      groupData={groupData}
                      isMobile={isMobile}
                      setSelectedCarpool={setSelectedCarpoolId}
                      onViewDetailsClick={showCarpoolDetails}
                    />
                  )
                })
              ) : !myCarpoolGroups ? (
                <Loading />
              ) : (
                <Button
                  onClick={() => setShowCreateCarpoolModal(true)}
                  marginTop='0.75rem'
                >
                  Create Carpool Group
                </Button>
              )}
            </GridCard>
          </GridContainer>
          <GridContainer numColumns={1} fitContentHeight>
            <GridCard headerTitle='Joined Carpool Groups'>
              {carpoolMemberships &&
              Object.keys(carpoolMemberships).length > 0 ? (
                Object.keys(carpoolMemberships).map((groupId: string) => {
                  const groupData = carpoolMemberships[groupId].carpoolGroup
                  return (
                    <CarpoolGroupCard
                      key={groupId}
                      carpoolRelation={'MEMBER'}
                      groupData={groupData}
                      isMobile={isMobile}
                      setSelectedCarpool={setSelectedCarpoolId}
                      onViewDetailsClick={showCarpoolDetails}
                      distanceFromHome={
                        carpoolMemberships[groupId].distanceSource
                      }
                      distanceFromWork={
                        carpoolMemberships[groupId].distanceDest
                      }
                    />
                  )
                })
              ) : !carpoolMemberships ? (
                <Loading />
              ) : (
                <NoDataText text='NO JOINED CARPOOL GROUPS' />
              )}
            </GridCard>
          </GridContainer>
        </GridContainer>
        <GridCard headerTitle='Search for Carpools'>
          <CarpoolSearch
            // onViewDetailsClick={showCarpoolDetails}
            // selectedCarpool={selectedCarpoolId}
            isMobile={isMobile}
            setSelectedCarpool={setSelectedCarpoolId}
            setShowRequestModal={setShowCarpoolRequestModal}
            setSource={setSource}
            setDestination={setDestination}
            sourceAddress={sourceAddress}
            setSourceAddress={setSourceAddress}
            destAddress={destAddress}
            setDestAddress={setDestAddress}
            hasSearched={hasSearched}
            setHasSearched={setHasSearched}
          />
        </GridCard>
      </GridContainer>
      {showCreateCarpoolModal ? (
        <UpdateCreateCarpoolModal
          open={showCreateCarpoolModal}
          closeModal={() => setShowCreateCarpoolModal(false)}
        />
      ) : null}
      {showCarpoolRequestModal ? (
        <CarpoolRequestModal
          open={showCarpoolRequestModal}
          closeModal={() => setShowCarpoolRequestModal(false)}
          selectedCarpool={selectedCarpoolId}
          setSelectedCarpool={setSelectedCarpoolId}
          source={source}
          destination={destination}
        />
      ) : null}
    </div>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolOverviewView.displayName = 'CarpoolOverviewView'
}

export default CarpoolOverviewView
