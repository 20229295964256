import Loading from 'core-system/Loading'
import ErrorView from 'features/ErrorView'
import React, { useEffect, useState } from 'react'
import DashboardService from 'redux/dashboard/dashboardService'
import LocalStorage from 'shared/LocalStorage'

enum ReportPageState {
  LOADING,
  ERROR,
  SUCCESS,
}

const ReportView = React.memo(() => {
  const [pageState, setPageState] = useState<ReportPageState>(
    ReportPageState.LOADING
  )
  const startDate = LocalStorage.getItem('reportStartDate') as string
  const endDate = LocalStorage.getItem('reportEndDate') as string
  const segmentId = LocalStorage.getItem('segmentId') as string

  useEffect(() => {
    LocalStorage.removeItem('reportStartDate')
    LocalStorage.removeItem('reportEndDate')
    LocalStorage.removeItem('segmentId')

    if (!startDate || !endDate) setPageState(ReportPageState.ERROR)
    else {
      DashboardService.getDashboardReport({
        startDate,
        endDate,
        segmentId,
      })
        .then((reportData) => {
          setPageState(ReportPageState.SUCCESS)
          window.document.write(reportData.data)
          window.document.close()
          window.onload = () => {
            setTimeout(() => {
              window.print()
            }, 100) // occassionally, text needs a bit more time to render
          }
        })
        .catch(() => {
          setPageState(ReportPageState.ERROR)
        })
    }
  }, [setPageState, startDate, endDate, segmentId])

  return pageState === ReportPageState.ERROR ? (
    <ErrorView />
  ) : pageState === ReportPageState.LOADING ? (
    <Loading fullPage withoutNav />
  ) : null
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ReportView.displayName = 'ReportView'
}
export default ReportView
