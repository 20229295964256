import React from 'react'
import { StaticAssetsUrl } from 'redux/config/services/Constants'

type PNGFolder = 'customer_logos'

export type PNGVariant =
  //customer_logos
  'sfmta-logo' | 'fleet-logo'

interface PlatformPngProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  variant: PNGVariant
  folder: PNGFolder
}

const PlatformPng = (props: PlatformPngProps) => {
  return (
    <img
      alt={props.variant}
      src={`${StaticAssetsUrl}/${props.folder}/${props.variant}.png`}
      {...props}
    />
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PlatformPng.displayName = 'PlatformPng'
}

export default PlatformPng
