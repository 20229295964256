import React from 'react'
import { StaticAssetsUrl } from 'redux/config/services/Constants'

type SVGFolder =
  | 'banners'
  | 'users'
  | 'icons'
  | 'metrics'
  | 'offices'
  | 'dashboard'
  | 'segments'
  | 'archive'

export type SVGVariant =
  //banners
  | 'banner1'
  | 'banner2'
  | 'banner3'
  | 'banner4'
  | 'banner5'
  | 'banner6'
  | 'banner7'

  //users
  | 'userBasic'
  | 'userMale1'
  | 'userMale2'
  | 'userMale3'
  | 'userMale4'
  | 'userMale5'
  | 'userMale6'
  | 'userFemale1'
  | 'userFemale2'
  | 'userFemale3'
  | 'userFemale4'
  | 'userFemale5'
  | 'userFemale6'

  //icons
  | 'calendar'
  | 'incentives'
  | 'lock'

  //metrics
  | 'agony'
  | 'bike'
  | 'co2'
  | 'covidPink'
  | 'employees'
  | 'graphs'
  | 'globe'
  | 'leaf2'
  | 'longDistanceDriving'
  | 'longDistancePurple'
  | 'longDistanceTransit'
  | 'megaCommuterDriving'
  | 'megaCommuterTransit'
  | 'money'
  | 'money2'
  | 'productiveGains'
  | 'productiveTimeGains'
  | 'superCommuterDriving'
  | 'superCommuterTransit'
  | 'employeeCostPurple'

  //offices
  | 'office1'
  | 'office2'
  | 'office3'
  | 'office4'
  | 'office5'

  //dashboard
  | 'dashboardInvite'
  | 'dashboardReport'

  //segments
  | 'noTrip'

  //archive
  | 'archiveLogo'

interface PlatformSvgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  variant: SVGVariant
  folder: SVGFolder
}

const PlatformSvg = (props: PlatformSvgProps) => {
  return (
    <img
      alt={props.variant}
      src={`${StaticAssetsUrl}/${props.folder}/${props.variant}.svg`}
      {...props}
    />
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PlatformSvg.displayName = 'PlatformSvg'
}

export default PlatformSvg
