import React from 'react'
import styled from 'styled-components'
import { color, ColorProps } from '../@styled-system/Color'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  border,
  BorderProps,
} from 'styled-system'

const Container = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps &
    BorderProps &
    ColorProps & { hasChildren: boolean; hasShadow: boolean }
>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${(props) => (!props.hasChildren ? '4rem' : '5rem')};
  background-color: ${(props) => props.theme.palette.grey.grey1};
  border-top: ${(props) =>
    !props.hasChildren
      ? 'unset'
      : `1px solid ${props.theme.palette.grey.grey3}`};
  box-shadow: ${(props) =>
    props.hasShadow ? props.theme.dropShadows.bottom : 'unset'};
  padding: ${(props) =>
    !props.hasChildren
      ? 0
      : `${props.theme.pxToRem(20)} ${props.theme.pxToRem(52)}`};
  
  ${flexbox} 
  ${space} 
  ${layout} 
  ${color}
  ${border}
`

const FullWidthBtn = styled.div`
  ${(props) => props.theme.typography.action1}
  background-color: ${(props) => props.theme.palette.primary.primaryPurple};
  color: ${(props) => props.theme.palette.white};
  padding: ${(props) =>
    `${props.theme.pxToRem(19)} ${props.theme.pxToRem(52)}`};
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple1};
  }
`

interface ModalFooterProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    BorderProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  label?: string
  hasShadow?: boolean
  clickCallback?: () => void
}

const ModalFooter = React.memo((props: ModalFooterProps) => {
  const { label, hasShadow, children, clickCallback } = props

  return (
    <Container hasChildren={children && true} hasShadow={hasShadow} {...props}>
      {children ? (
        children
      ) : (
        <FullWidthBtn onClick={() => clickCallback()}>{label}</FullWidthBtn>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ModalFooter.displayName = 'ModalFooter'
}

export default ModalFooter
