import FlexContainer from 'core-system/FlexContainer'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import Text from 'core-system/Text'
import TextField from 'core-system/TextField'
import React from 'react'
import styled from 'styled-components'

const FormBody = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FormContainer = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FormField = styled(FlexContainer)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-bottom: 2rem;
`

const FormHeader = styled(FlexContainer)`
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 3rem;
  margin-bottom: 2rem;
  width: 70%;
`

const InputContainer = styled(FlexContainer)`
  justify-content: flex-end;
  width: 23rem;
`

interface MyCommuterGiveOTRProps {
  otrAmount: number
  onOTRAmountChange: (otrAmount: number) => void
  onOTRMemoChange: (otrMemo: string) => void
}

const MyCommuterGiveOTR = React.memo((props: MyCommuterGiveOTRProps) => {
  const { otrAmount, onOTRAmountChange, onOTRMemoChange } = props

  return (
    <FormContainer>
      <FormHeader>
        <Text variant='h2'>Give One Time Reward</Text>
        <Text variant='body1'>
          Please enter an amount and description for the One Time Reward
        </Text>
      </FormHeader>
      <FormBody>
        <FormField>
          <Text variant='h3'>Amount:</Text>
          <InputContainer>
            <NumberInput
              padding='1rem'
              onValueChange={onOTRAmountChange}
              value={otrAmount}
            >
              <NumberFormatter decimalScale={0} fontVariant='h3' />
            </NumberInput>
          </InputContainer>
        </FormField>
        <FormField>
          <Text variant='h3'>Description:</Text>
          <InputContainer>
            <TextField
              onChange={(e) => onOTRMemoChange(e.target.value)}
              maxLength={256}
              width='22.5rem'
            />
          </InputContainer>
        </FormField>
      </FormBody>
    </FormContainer>
  )
})

if (process.env.NODE_ENV !== 'production') {
  MyCommuterGiveOTR.displayName = 'MyCommuterGiveOTR'
}

export default MyCommuterGiveOTR
