import Chip from 'core-system/Chip'
import Divider from 'core-system/Divider'
import HorizontalScroller from 'core-system/HorizontalScroller'
import HorizontalScrollerUtils from 'core-system/HorizontalScroller/HorizontalScrollerUtils'
import InfoIcon from 'core-system/Icons/Misc/Info'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import SwitchToggle from 'core-system/SwitchToggle'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import SvgTooltip from 'core-system/Tooltip/SvgTooltip'
import React from 'react'
import { useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HyperLink = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.primaryPurple};

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const PillContainer = styled.div`
  padding: 0.25rem 0.3125rem 0.25rem 0.25rem;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  min-width: 1.5rem;
`

interface ProgramOptionProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  active: boolean
  type: string
  error?: boolean
  handleToggle: (type: string) => void
  handleOpenModal?: (type: string) => void
  canEdit?: boolean
  readOnly?: boolean
  availableMerchantIds?: string[]
  activeMerchantIds?: string[]
}

const ProgramOption = React.memo((props: ProgramOptionProps) => {
  const {
    active,
    type,
    error,
    handleToggle,
    handleOpenModal,
    children,
    canEdit = true,
    readOnly = false,
    availableMerchantIds,
    activeMerchantIds,
  } = props

  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )

  const hasAvailableProviders =
    availableMerchantIds?.length > 0 &&
    (!readOnly || activeMerchantIds?.length > 0)

  return (
    <>
      <Container>
        <HeaderContainer>
          <Text variant='h4'>{ProgramUtils.optionsCopy[type].title}</Text>
          {canEdit ? (
            <SwitchToggle
              active={active}
              onClick={() => {
                handleToggle(type)
                SegmentService.track('program-option-toggle', {
                  [type === 'flex' ? 'flexProviders' : type]: !active,
                })
              }}
              data-cy={`${type}-switch`}
            />
          ) : (
            <Chip variant={active ? 'green' : 'grey'}>
              {active ? 'Active' : 'Disabled'}
            </Chip>
          )}
        </HeaderContainer>
        <Text variant='body1' margin='1.5rem 0'>
          {ProgramUtils.optionsCopy[type].description}
        </Text>
        {active && (
          <>
            <div>{children}</div>
            {availableMerchantIds && (
              <>
                <Divider marginY='1.5rem' />
                <HeaderContainer>
                  {readOnly ? (
                    <FlexContainer>
                      <Text variant='action4' marginRight='0.375rem'>
                        {ProgramUtils.optionsCopy[type].infoTitle ||
                          `Service Provider${RandomUtils.pluralCheck(
                            activeMerchantIds.length
                          )}`}
                      </Text>
                      {ProgramUtils.optionsCopy[type].infoDescription && (
                        <SvgTooltip
                          svg={
                            <InfoIcon
                              width={20}
                              height={20}
                              color={palette.text.secondary}
                            />
                          }
                          height='1.25rem'
                          isMultiLine={true}
                          description={
                            'Commuters will be able to spend their budget on all local public transportation providers beyond only this list.'
                          }
                        />
                      )}
                    </FlexContainer>
                  ) : (
                    <FlexContainer>
                      <Text variant='action4'>
                        Service Provider
                        {RandomUtils.pluralCheck(activeMerchantIds.length)}
                      </Text>
                      <PillContainer>
                        <Text variant='action4'>
                          {activeMerchantIds.length}
                        </Text>
                      </PillContainer>
                    </FlexContainer>
                  )}
                  {hasAvailableProviders && !readOnly && (
                    <HyperLink
                      variant='hyperlink'
                      onClick={() => handleOpenModal(type)}
                      data-cy={`${type}-providers`}
                    >
                      {canEdit ? 'Edit' : 'View All'}
                    </HyperLink>
                  )}
                </HeaderContainer>
                {hasAvailableProviders ? (
                  <HorizontalScroller
                    translationPerClick={160}
                    marginTop='1rem'
                    resetOnChildrenUpdate
                  >
                    {HorizontalScrollerUtils.getProviders(
                      activeMerchantIds,
                      merchantsMap
                    )}
                  </HorizontalScroller>
                ) : (
                  <Text variant='action3' marginTop='1rem'>
                    Sorry, there are currently no{' '}
                    {ProgramUtils.optionsCopy[type].title} providers in your
                    area!
                  </Text>
                )}
                {error && activeMerchantIds.length === 0 && (
                  <Text variant='body1' textColor={palette.secondary.red1}>
                    Please Select Providers
                  </Text>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramOption.displayName = 'ProgramOption'
}

export default ProgramOption
