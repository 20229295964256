import { notificationsActions } from 'redux/notifications/notificationsSlice'
import Button from 'core-system/Button'
import Dropdown from 'core-system/Dropdown'
import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import ToggleWorksiteInviteCodeModal from 'features/Accounts/Users/ToggleWorksiteInviteCodeModal'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppState } from 'redux/config/store'
import { Worksite } from 'redux/employer/employerTypes'
import RandomUtils from 'shared/RandomUtils'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-bottom: 1rem;
  display: flex;
`

const InviteContainer = styled.div`
  flex: 1;
  margin-right: 2.5rem;
`

const DropdownContainer = styled.div`
  flex: 1;
  margin-right: 1rem;
`

const HyperLink = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.primaryPurple};
  margin-left: 0.25rem;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const getSelectableWorksites = (worksites: Worksite[]) => {
  return [...worksites]
    .sort((a, b) => b.employeeCount - a.employeeCount)
    .map((worksite) => {
      return {
        id: worksite.id,
        text: worksite.alias,
      }
    })
}

const DashboardInvite = React.memo(() => {
  const dispatch = useDispatch()

  const worksites = useSelector((state: AppState) => state.employer.worksites)

  const [currentWorksiteId, setCurrentWorksiteId] = useState(
    worksites ? worksites[0]?.id : null
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleCopy = useCallback(
    (currentWorksite: Worksite) => {
      if (currentWorksite?.inviteCode) {
        RandomUtils.copyInviteCode(
          currentWorksite?.address.split(',')[0],
          currentWorksite?.inviteCode
        )
        dispatch(
          notificationsActions.generalPageInfo({
            title: 'Copied!',
            msg: 'Successfully coped to clipboard',
          })
        )
      } else {
        setIsModalOpen(true)
      }
    },
    [dispatch]
  )

  if (!worksites) {
    return <Loading />
  }

  const currentWorksite = worksites.find(
    (worksite) => worksite.id === currentWorksiteId
  )

  return (
    <>
      <Container>
        <InviteContainer>
          <Text variant='h5' marginBottom='1rem'>
            Invite Commuters
          </Text>
          <FlexContainer marginBottom='1rem'>
            <Text variant='body1'>Commuter link:</Text>
            <Link
              to={Locations.EmployeeSignUp}
              target='_blank'
              rel='noopener noreferrer'
            >
              <HyperLink variant='hyperlink'>Sign up page</HyperLink>
            </Link>
          </FlexContainer>
          <Text
            variant='body1'
            marginBottom='0.25rem'
            textColor={palette.text.secondary}
          >
            Select Worksite:
          </Text>
          <FlexContainer width='100%'>
            <DropdownContainer>
              <Dropdown
                items={getSelectableWorksites(worksites)}
                active={currentWorksiteId}
                itemCallback={({ id }) => setCurrentWorksiteId(id)}
                width='100%'
                placeholder={
                  !worksites || worksites.length === 0
                    ? 'No Worksites Available'
                    : ''
                }
              />
            </DropdownContainer>
            <Button
              onClick={() => handleCopy(currentWorksite)}
              disabled={!currentWorksite}
            >
              {currentWorksite && currentWorksite.inviteCode
                ? 'Copy Invite Code'
                : 'Activate Code'}
            </Button>
          </FlexContainer>
        </InviteContainer>
        <PlatformSvg
          folder='dashboard'
          variant='dashboardInvite'
          width={119}
          height={116}
        />
      </Container>
      {currentWorksite && (
        <ToggleWorksiteInviteCodeModal
          worksite={currentWorksite}
          open={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardInvite.displayName = 'DashboardInvite'
}

export default DashboardInvite
