import React from 'react'
import { MapIntelligenceStats } from 'redux/mapIntelligence/mapIntelligenceTypes'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import KeyMetricsAgony from './KeyMetrics/KeyMetricsAgony'
import KeyMetricsModes from './KeyMetrics/KeyMetricsModes'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 2rem;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: unset;
`

const displayKeyMetrics = (
  activeLayer: string,
  statistics: MapIntelligenceStats
) => {
  if (activeLayer === 'currentCommuteModes') {
    const commuteModePercentages = []
    const commuteModes = Object.keys(statistics.modes).map((mode: string) => {
      commuteModePercentages.push(
        Math.round((statistics.modes[mode] / statistics.totalEmployees) * 100)
      )
      return RandomUtils.capitalize(mode)
    })

    return (
      <KeyMetricsModes data={commuteModePercentages} labels={commuteModes} />
    )
  } else if (activeLayer === 'agony') {
    return (
      <KeyMetricsAgony
        agonyStats={statistics.agony}
        totalEmployees={statistics.totalEmployees}
      />
    )
  } else {
    return <div>No Key Metrics</div>
  }
}

interface MapIntelligenceKeyMetricsProps {
  statistics: MapIntelligenceStats
  activeLayer: string
}

const MapIntelligenceKeyMetrics = React.memo(
  (props: MapIntelligenceKeyMetricsProps) => {
    const { statistics, activeLayer } = props
    return <Container>{displayKeyMetrics(activeLayer, statistics)}</Container>
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MapIntelligenceKeyMetrics.displayName = 'MapIntelligenceKeyMetrics'
}

export default MapIntelligenceKeyMetrics
