import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import fileDownload from 'js-file-download'
import moment from 'moment'
import {
  CreateQtfProgramParams,
  EmployeeAllocationUpdateData,
  EmployeeAllocationUpdateParams,
  QtfDashboardStats,
  QtfEmployeeDeduction,
  QtfPerEmployeeAllocation,
  QtfServiceUsage,
  UpdateQtfProgramParams,
} from './qtfTypes'

export interface QtfState {
  qtfPrograms: Dictionary<any>
  qtfProgramFlag: boolean
  qtfProgramUpdated: boolean
  qtfProgramsLoaded: boolean
  qtfProgramCommunicationUsers: Dictionary<{ userIds: string[] }>

  dashboardStats: QtfDashboardStats
  serviceUsage: QtfServiceUsage[]
  upcomingAllocations: Dictionary<any>
  isReportGenerating: boolean
}

export const initialState: QtfState = {
  qtfPrograms: {},
  qtfProgramFlag: false,
  qtfProgramUpdated: false,
  qtfProgramsLoaded: false,
  qtfProgramCommunicationUsers: null,

  dashboardStats: null,
  serviceUsage: null,
  upcomingAllocations: {},
  isReportGenerating: false,
}

const handleUpdate = (state: QtfState) => {
  return {
    ...state,
    qtfProgramUpdated: true,
    qtfProgramsLoaded: false,
  }
}

const handleToggleProgramUpdate = (state: QtfState) => {
  return {
    ...state,
    qtfProgramUpdated: !state.qtfProgramUpdated,
  }
}

const createQtfProgram = createAction<CreateQtfProgramParams>(
  'qtf/createQtfProgram'
)
const updateQtfProgram = createAction<UpdateQtfProgramParams>(
  'qtf/updateQtfProgram'
)
const cancelQtfProgram = createAction<string[]>('qtf/cancelQtfProgram')

const updateEmployeeAllocation = createAction<EmployeeAllocationUpdateParams>(
  'qtf/updateEmployeeAllocation'
)

const createEmployeeAllocation = createAction<EmployeeAllocationUpdateData>(
  'qtf/createEmployeeAllocation'
)

const qtfSlice = createSlice({
  name: 'qtf',
  initialState,
  reducers: {
    getAllQtfProgramSuccess(state, action: any) {
      const activeStatuses = ['ACTIVE', 'UPDATING']
      const groupedPrograms = action.payload.reduce((agg, program) => {
        if (program.status === 'CANCELLED') return agg

        const defaultProgramGroup = {} as any

        if (activeStatuses.includes(program.status)) {
          if (!agg[program.segment]?.active) {
            defaultProgramGroup.active = {
              ...program,
              employerTransitContribution:
                program.type === 'COMMUTER_TRANSIT'
                  ? program.employerContribution
                  : 0,
              employerParkingContribution:
                program.type === 'COMMUTER_PARKING'
                  ? program.employerContribution
                  : 0,
              type: [program.type],
              idMap: { [program.id]: program.type },
              typeMap: {
                [program.type]: {
                  id: program.id,
                  status: program.status,
                },
              },
            }
          } else {
            defaultProgramGroup.active = {
              ...agg[program.segment].active,
              employerTransitContribution:
                program.type === 'COMMUTER_TRANSIT'
                  ? program.employerContribution
                  : agg[program.segment].active.employerTransitContribution,
              employerParkingContribution:
                program.type === 'COMMUTER_PARKING'
                  ? program.employerContribution
                  : agg[program.segment].active.employerParkingContribution,
              type: [...agg[program.segment].active.type, program.type],
              idMap: {
                ...agg[program.segment].active.idMap,
                [program.id]: program.type,
              },
              typeMap: {
                ...agg[program.segment].active.typeMap,
                [program.type]: {
                  id: program.id,
                  status: program.status,
                },
              },
            }
          }
        } else {
          if (!agg[program.segment]?.nextMonth) {
            defaultProgramGroup.nextMonth = {
              ...program,
              contributionDeadlineDay:
                program.status === 'CANCELLING'
                  ? agg[program.segment]?.active
                    ? agg[program.segment].active.contributionDeadlineDay
                    : 5
                  : program.contributionDeadlineDay,
              employerTransitContribution:
                program.type === 'COMMUTER_TRANSIT'
                  ? program.employerContribution
                  : 0,
              employerParkingContribution:
                program.type === 'COMMUTER_PARKING'
                  ? program.employerContribution
                  : 0,
              type: [program.type],
              idMap: { [program.id]: program.type },
              typeMap: {
                [program.type]: { id: program.id, status: program.status },
              },
            }
          } else {
            defaultProgramGroup.nextMonth = {
              ...agg[program.segment].nextMonth,
              contributionDeadlineDay:
                program.status === 'CANCELLING'
                  ? agg[program.segment].nextMonth.contributionDeadlineDay
                  : program.contributionDeadlineDay,
              employerTransitContribution:
                program.type === 'COMMUTER_TRANSIT'
                  ? program.employerContribution
                  : agg[program.segment].nextMonth.employerTransitContribution,
              employerParkingContribution:
                program.type === 'COMMUTER_PARKING'
                  ? program.employerContribution
                  : agg[program.segment].nextMonth.employerParkingContribution,
              type: [...agg[program.segment].nextMonth.type, program.type],
              idMap: {
                ...agg[program.segment].nextMonth.idMap,
                [program.id]: program.type,
              },
              typeMap: {
                ...agg[program.segment].nextMonth.typeMap,
                [program.type]: { id: program.id, status: program.status },
              },
            }
          }
        }

        agg[program.segment] = {
          ...agg[program.segment],
          ...defaultProgramGroup,
        }

        return agg
      }, {})

      state.qtfPrograms = groupedPrograms
      state.qtfProgramsLoaded = true
    },
    updateQtfProgramSuccess(state) {
      return handleUpdate(state)
    },
    cancelQtfProgramSuccess(state) {
      return handleUpdate(state)
    },
    toggleQtfProgramUpdated(state) {
      return handleToggleProgramUpdate(state)
    },
    createQtfProgramSuccess(state) {
      state.qtfProgramFlag = true
    },
    toggleQtfProgramFlag(state) {
      state.qtfProgramFlag = !state.qtfProgramFlag
    },
    getQTFDashboardStats(state, _action: PayloadAction<{ segmentId: string }>) {
      state.dashboardStats = null
    },
    getQTFDashboardStatsSuccess(
      state,
      action: PayloadAction<QtfDashboardStats>
    ) {
      state.dashboardStats = action.payload
    },
    getQTFDashboardServices(
      state,
      _action: PayloadAction<{ segmentId: string }>
    ) {
      state.serviceUsage = null
    },
    getQTFDashboardServicesSuccess(
      state,
      action: PayloadAction<QtfServiceUsage[]>
    ) {
      state.serviceUsage = action.payload
    },
    getQTFDashboardDeductions(
      state,
      action: PayloadAction<{
        segmentId: string
        format: 'csv' | 'json'
        segmentName?: string
      }>
    ) {
      if (action.payload.format === 'json') {
        state.upcomingAllocations = null
      }
    },
    getQTFDashboardDeductionsSuccess(
      state,
      action: PayloadAction<QtfEmployeeDeduction[]> | any
    ) {
      const displayMonthYear = moment().add(1, 'M').format('MMMM_YYYY')
      if (action.meta.format === 'csv') {
        state.upcomingAllocations
        fileDownload(
          action.payload,
          `${action.meta.segmentName}_employee_allocations_${displayMonthYear}.csv`
        )
      }
    },
    getQTFUpcomingAllocations(
      state,
      _action: PayloadAction<{ segmentId: string }>
    ) {
      state.upcomingAllocations = {}
    },
    getQTFUpcomingAllocationsSuccess(
      state,
      action: PayloadAction<QtfPerEmployeeAllocation[]>
    ) {
      action.payload.map((allocation: QtfPerEmployeeAllocation) => {
        state.upcomingAllocations[allocation.employees_id] = allocation
      })
    },
    getQTFDashboardCsvReports(state, _action: any) {
      state.isReportGenerating = true
    },
    getQTFDashboardCsvReportsSuccess(state, action: any) {
      const reportNames = {
        usage: 'fleet_tax_savings_usage_report',
      }
      state.isReportGenerating = false
      fileDownload(
        action.payload,
        `${action.meta.segmentName}_${reportNames[action.meta.reportType]}.csv`
      )
    },
    sendEmployeeReminder(state, _action: PayloadAction<{ segmentId: string }>) {
      state
    },
    sendEmployeeReminderSuccess(state, _action: any) {
      state
    },
    getQtfProgramCommUsers(
      state,
      _action: PayloadAction<{ segmentId: string }>
    ) {
      state.qtfProgramCommunicationUsers = null
    },
    updateQtfProgramCommUsersSuccess(state, action: any) {
      const userIds = action.payload as string[]
      const segmentId = action.meta.segmentId as string

      state.qtfProgramCommunicationUsers = {
        ...state.qtfProgramCommunicationUsers,
        [segmentId]: { userIds },
      }
    },
    updateQtfProgramCommUsers(
      state,
      _action: PayloadAction<{ segmentId: string; userIds: string[] }>
    ) {
      state
    },
    updateEmployeeAllocationSuccess(state, action: any) {
      const updatedAllocation = action.payload
      const employeeId = updatedAllocation.employee
      const newDeductions =
        state.upcomingAllocations[employeeId].deductions.slice()
      const updatedAllocationIndex = newDeductions.findIndex(
        (deduction) => deduction.id === updatedAllocation.id
      )
      newDeductions[updatedAllocationIndex] = {
        ...newDeductions[updatedAllocationIndex],
        employeeDeduction: updatedAllocation.employeeDeduction,
      }
      state.upcomingAllocations[employeeId] = {
        ...state.upcomingAllocations[employeeId],
        deductions: newDeductions,
      }
    },
    createEmployeeAllocationSuccess(state, action: any) {
      const newAllocation = action.payload
      const employeeId = newAllocation.employee
      const newDeductions =
        state.upcomingAllocations[employeeId].deductions.slice()
      const newAllocationIndex = newDeductions.findIndex(
        (deduction) => deduction.type === newAllocation.type
      )

      newDeductions[newAllocationIndex] = {
        ...newDeductions[newAllocationIndex],
        id: newAllocation.id,
        employeeDeduction: newAllocation.employeeDeduction,
      }
      state.upcomingAllocations[employeeId] = {
        ...state.upcomingAllocations[employeeId],
        deductions: newDeductions,
      }
    },
  },
})

export const qtfReducer = qtfSlice.reducer
export const qtfActions = {
  ...qtfSlice.actions,
  createQtfProgram,
  updateQtfProgram,
  cancelQtfProgram,
  updateEmployeeAllocation,
  createEmployeeAllocation,
}
