import React from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import { convertMetresToMiles } from 'employee-platform/shared/utils'
import { CarpoolRequestResponsePayload } from 'redux/employeePlatform/employeePlatformTypes'
import FlexContainer from 'core-system/FlexContainer'
import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import { useDispatch } from 'react-redux'
import {
  CardListCard,
  DistancesContainer,
  DistanceItem,
  InfoContainer,
  InfoItem,
  CardListCardTop,
} from 'employee-platform/shared/styles/CardList'

const AcceptDeclineButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
`

const infoField = (label: string, value: string, isMobile: boolean) => {
  return (
    <InfoItem isMobile={isMobile}>
      <Text variant='action3'>{`${label}:`}</Text>
      <Text variant='action3' textColor={palette.text.secondary} hideOverflow>
        {value}
      </Text>
    </InfoItem>
  )
}

const distanceField = (source: 'You' | 'Destination', distance: number) => {
  return (
    <DistanceItem>
      <Text
        variant='action3'
        textAlign='center'
      >{`Distance from ${source}`}</Text>
      <Text
        variant='action1'
        textColor={palette.primary.primaryPurple}
      >{`${distance} mi`}</Text>
    </DistanceItem>
  )
}

interface CarpoolRequestCardProps {
  isMobile: boolean
  requestData: CarpoolRequestResponsePayload
  onViewDetailsClick?: (requestId: string, groupId: string) => void
}

const CarpoolRequestCard = React.memo((props: CarpoolRequestCardProps) => {
  const { isMobile, requestData, onViewDetailsClick } = props

  const dispatch = useDispatch()

  const handleAccept = () => {
    dispatch(employeePlatformActions.acceptCarpoolRequest(requestData.id))
  }

  const handleDecline = () => {
    dispatch(employeePlatformActions.declineCarpoolRequest(requestData.id))
  }

  return (
    <CardListCard>
      <CardListCardTop>
        <InfoContainer>
          {infoField('Email', requestData?.contactInfo, isMobile)}
          {infoField(
            'Role',
            requestData?.driver ? 'Driver' : 'Passenger',
            isMobile
          )}
        </InfoContainer>
        <DistancesContainer>
          {distanceField(
            'You',
            convertMetresToMiles(requestData.distanceSource, 1)
          )}
          {distanceField(
            'Destination',
            convertMetresToMiles(requestData.distanceDest, 1)
          )}
        </DistancesContainer>
      </CardListCardTop>
      <Divider direction='horizontal' />
      <FlexContainer flexDirection='row' justifyContent='space-between'>
        <AcceptDeclineButtonContainer>
          <Button variant='green' size='small' onClick={() => handleAccept()}>
            Accept
          </Button>
          <Button variant='red' size='small' onClick={() => handleDecline()}>
            Decline
          </Button>
        </AcceptDeclineButtonContainer>
        <Button
          size='small'
          onClick={() =>
            onViewDetailsClick(requestData.id, requestData.carpoolGroupId)
          }
        >
          View Details
        </Button>
      </FlexContainer>
    </CardListCard>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolRequestCard.displayName = 'CarpoolRequestCard'
}

export default CarpoolRequestCard
