import ProgramUtils from 'core-system/Program/ProgramUtils'
import { OtpActiveOptions, OtpManageState } from 'redux/otp/otpTypes'
import FormattingUtils from 'shared/FormattingUtils'

const recTransacLimit = 3000
const recUses = 10

const defaultErrorState = {
  transactionLimit: false,
  micromobility: false,
}
//this is used to see if the upcoming program has changes
const programHasChanges = (
  programState: OtpManageState,
  activeOptions: Dictionary<OtpActiveOptions>
) => {
  const currentBudget = FormattingUtils.formatDollar(
    programState.active.budget,
    0
  )
  const nextMonthBudget = FormattingUtils.formatDollar(
    programState.nextMonth.budget,
    0
  )

  const currentUses = programState.active.numUses
  const nextMonthUses = programState.nextMonth.numUses

  const optionChanges = Object.keys(activeOptions.active).flatMap((option) => {
    if (activeOptions.active[option] !== activeOptions.nextMonth[option]) {
      return option
    } else {
      return []
    }
  })

  const providerChanges: any = ProgramUtils.getProviderDifference(
    programState,
    ['micromobility']
  )

  //only compare provider changes on active options
  const hasProviderChanges =
    Object.keys(providerChanges).length > 0 &&
    Object.keys(providerChanges).some(
      (option) => activeOptions.nextMonth[option]
    )

  return (
    currentBudget !== nextMonthBudget ||
    optionChanges.length > 0 ||
    hasProviderChanges ||
    currentUses !== nextMonthUses
  )
}

export default {
  recTransacLimit,
  recUses,
  defaultErrorState,
  programHasChanges,
}
