import React from 'react'
import Calendar, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// override default styles
import './datepicker.css'
import { buildComponent } from '../utils/buildComponent'

export interface DatePickerProps extends ReactDatePickerProps {}

const DatePicker = (props: DatePickerProps) => {
  return <Calendar {...props} />
}

export default buildComponent(DatePicker, 'DatePicker')
