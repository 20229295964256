import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M19 7.5H18.18L16.48 2.82C16.19 2.03 15.44 1.5 14.6 1.5H12V3.5H14.6L16.06 7.5H11.25L10.89 6.5H12V4.5H7V6.5H8.75L10.57 11.5H9.9C9.46 9.27 7.59 7.62 5.25 7.51C2.45 7.37 0 9.7 0 12.5C0 15.3 2.2 17.5 5 17.5C7.46 17.5 9.45 15.81 9.9 13.5H14.1C14.54 15.73 16.41 17.38 18.75 17.49C21.55 17.62 24 15.3 24 12.49C24 9.7 21.8 7.5 19 7.5ZM7.82 13.5C7.42 14.67 6.33 15.5 5 15.5C3.32 15.5 2 14.18 2 12.5C2 10.82 3.32 9.5 5 9.5C6.33 9.5 7.42 10.33 7.82 11.5H5V13.5H7.82ZM14.1 11.5H12.7L11.97 9.5H15C14.56 10.08 14.24 10.75 14.1 11.5ZM19 15.5C17.32 15.5 16 14.18 16 12.5C16 11.57 16.41 10.77 17.05 10.22L18.01 12.86L19.89 12.18L18.92 9.51C18.94 9.51 18.97 9.5 19 9.5C20.68 9.5 22 10.82 22 12.5C22 14.18 20.68 15.5 19 15.5Z' />
    <path d='M11 20.5H7L13 23.5V21.5H17L11 18.5V20.5Z' />
  </>,
  'Electric Bike'
)
