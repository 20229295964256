import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M20.4618 15.7231C21.2062 13.8733 21.3776 11.826 20.9518 9.86915C20.526 7.91225 19.5244 6.14412 18.088 4.81335C16.6515 3.48257 14.8523 2.65607 12.9435 2.45006C11.0346 2.24404 9.11193 2.66887 7.44586 3.66481L6.30776 1.58071C8.04891 0.540001 10.0206 -0.00501793 12.026 3.48147e-05C14.0315 0.00508756 16.0006 0.560035 17.737 1.60951C22.8883 4.71946 24.8616 11.3785 22.4603 16.9313L24 17.86L19.2216 20.5164L19.0323 14.8604L20.4618 15.7231ZM3.53937 8.27694C2.79495 10.1267 2.62359 12.174 3.04938 14.1309C3.47518 16.0877 4.47671 17.8559 5.91318 19.1867C7.34964 20.5174 9.1488 21.3439 11.0577 21.5499C12.9666 21.756 14.8892 21.3311 16.5553 20.3352L17.6934 22.4193C15.9522 23.46 13.9806 24.005 11.9751 24C9.96964 23.9949 8.0005 23.44 6.26416 22.3905C1.11286 19.2805 -0.860462 12.6215 1.5408 7.06872L0 6.14125L4.77843 3.48484L4.96773 9.14081L3.53822 8.27814L3.53937 8.27694Z' />
    <path d='M11.5886 10.937L16.1224 6.00089L17.3335 7.31947L16.5261 8.19852L17.7372 9.5171L15.7187 11.7154L14.5076 10.3968L12.8003 12.2556C13.1147 12.9141 13.2023 13.6734 13.0475 14.3962C12.8926 15.1189 12.5054 15.7575 11.9559 16.1967C11.4063 16.6358 10.7306 16.8464 10.0509 16.7905C9.37114 16.7346 8.73218 16.4158 8.24945 15.8918C7.76647 15.3668 7.47221 14.6708 7.41999 13.9299C7.36777 13.189 7.56105 12.4523 7.9648 11.8532C8.36854 11.2541 8.95605 10.8323 9.62084 10.6642C10.2856 10.4962 10.9837 10.5929 11.5886 10.937ZM11.0753 14.5726C11.1844 14.4579 11.2714 14.3207 11.3312 14.169C11.3911 14.0173 11.4226 13.8542 11.4239 13.6891C11.4252 13.524 11.3963 13.3603 11.3389 13.2074C11.2815 13.0546 11.1967 12.9158 11.0895 12.7991C10.9823 12.6823 10.8547 12.59 10.7144 12.5275C10.5741 12.465 10.4237 12.4335 10.272 12.435C10.1204 12.4364 9.97055 12.4707 9.83123 12.5358C9.6919 12.601 9.56588 12.6957 9.46054 12.8145C9.25251 13.049 9.13741 13.3631 9.14001 13.6891C9.14261 14.0151 9.26271 14.3269 9.47445 14.5574C9.68619 14.788 9.97261 14.9187 10.272 14.9216C10.5715 14.9244 10.8599 14.7991 11.0753 14.5726Z' />
  </>,
  'Leasing'
)
