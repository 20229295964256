import Button from 'core-system/Button/Button'
import Table from 'core-system/Table/Table'
import {
  TableRow,
  TableLabelRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import Text from 'core-system/Text/Text'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { OneTimeRewardPayload } from 'redux/incentives/incentivesTypes'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { SelectItemProps } from 'core-system/Dropdown'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import TextField from 'core-system/TextField/TextField'
import { Add as AddIcon, Close as CloseIcon } from 'core-system/Icons/Actions'
import FormattingUtils from 'shared/FormattingUtils'
import { useDispatch } from 'react-redux'
import { incentivesActions } from 'redux/incentives/incentivesSlice'
import SegmentService from 'redux/config/services/SegmentService'
import AlertSuccessModal from 'core-system/Modal/AlertSuccessModal'
import SearchableDropdown from 'core-system/Dropdown/SearchableDropdown'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
`

const HoverableAddIcon = styled(AddIcon)`
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.secondary.purple5};
    border-radius: 0.5rem;
  }
`

const HoverableCloseIcon = styled(CloseIcon)`
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.secondary.purple5};
    border-radius: 0.5rem;
  }
`

const ConfirmDeleteCell = styled(TableRowCell)`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  equal-size: false;
`

const getAddOtrTableRows = (
  otrsToAdd: OneTimeRewardPayload[],
  allCommutersMap: any,
  handleOtrDelete: (idx: number) => void
) => {
  return otrsToAdd.map((otr, idx) => {
    return (
      <TableRow key={idx} display='flex'>
        <TableRowContents autoResize={false} height='2rem'>
          <TableRowCell width='23%' equalSize={false}>
            {allCommutersMap[otr.employeeId].name}
          </TableRowCell>
          <TableRowCell width='25%' equalSize={false}>
            {FormattingUtils.formatDollar(otr.amount)}
          </TableRowCell>
          <TableRowCell width='41%' equalSize={false}>
            {otr.memo}
          </TableRowCell>
          <ConfirmDeleteCell>
            <HoverableCloseIcon
              width={24}
              height={24}
              onClick={() => handleOtrDelete(idx)}
            />
          </ConfirmDeleteCell>
        </TableRowContents>
      </TableRow>
    )
  })
}

const getOtrAlertMessage = (currentOtrAdding: OneTimeRewardPayload) => {
  const invalidFields = []
  if (!currentOtrAdding.employeeId) invalidFields.push('Commuter')
  if (!currentOtrAdding.amount) invalidFields.push('Amount')
  if (!currentOtrAdding.memo || currentOtrAdding.memo === '')
    invalidFields.push('Description')
  return `Please fill in the following fields: ${invalidFields.join(', ')}`
}

interface AddOTRViewProps {
  setShowAddOTRView: (flag: boolean) => void
}

const AddOTRView = React.memo((props: AddOTRViewProps) => {
  const { setShowAddOTRView } = props

  const dispatch = useDispatch()

  const { allCommuters, allCommutersMap } = useSelector(
    (state: AppState) => state.employee
  )

  const commuterDropdownItems = allCommuters
    ?.map((commuter) => {
      return {
        id: commuter.id,
        text: commuter.name,
      }
    })
    ?.sort((a, b) => a.text.localeCompare(b.text))

  const [otrsToAdd, setOtrsToAdd] = useState<OneTimeRewardPayload[]>([])
  const [currentOtrAdding, setCurrentOtrAdding] =
    useState<OneTimeRewardPayload>({
      employeeId: null,
      amount: 0,
      memo: '',
    })

  const [showAlertModal, setShowAlertModal] = useState(false)

  const currentOtrAddingInvalid = useMemo(() => {
    return !currentOtrAdding.employeeId ||
      !currentOtrAdding.amount ||
      !currentOtrAdding.memo ||
      currentOtrAdding.memo.length === 0
      ? true
      : false
  }, [currentOtrAdding])

  const setCommuter = (commuter: SelectItemProps) => {
    setCurrentOtrAdding({
      ...currentOtrAdding,
      employeeId: commuter ? commuter.id : null,
    })
  }

  const clearCurrentOtrToAdd = () => {
    setCurrentOtrAdding({
      employeeId: null,
      amount: 0,
      memo: '',
    })
  }

  const handleCancel = () => {
    setShowAddOTRView(false)
    setOtrsToAdd([])
    clearCurrentOtrToAdd()

    SegmentService.track('cancel-bulk-add-otrs')
  }

  const handleCreateAdditionalOtr = () => {
    if (currentOtrAddingInvalid) {
      setShowAlertModal(true)
    } else {
      setOtrsToAdd([
        ...otrsToAdd,
        { ...currentOtrAdding, amount: currentOtrAdding.amount * 100 },
      ])
      clearCurrentOtrToAdd()
    }
  }

  const handleAddAllOtrs = () => {
    const rewardedOtrs = otrsToAdd

    dispatch(incentivesActions.bulkCreateOneTimeRewards(otrsToAdd))
    setShowAddOTRView(false)
    setOtrsToAdd([])
    clearCurrentOtrToAdd()

    SegmentService.track('bulk-add-otrs', {
      rewarded: rewardedOtrs,
    })
  }

  const handleOtrDelete = useCallback(
    (idx: number) => {
      const otrsToAddCopy = [...otrsToAdd]
      if (otrsToAdd.length > 1) {
        otrsToAddCopy.splice(idx, 1)
        setOtrsToAdd(otrsToAddCopy)
      } else {
        setOtrsToAdd([])
      }
    },
    [otrsToAdd]
  )

  const addOtrsTableRows = useMemo(() => {
    return getAddOtrTableRows(otrsToAdd, allCommutersMap, handleOtrDelete)
  }, [otrsToAdd, allCommutersMap, handleOtrDelete])

  return (
    <>
      <AlertSuccessModal
        open={showAlertModal}
        onClose={() => setShowAlertModal(false)}
        type='alert'
        headerText='Cannot Add One Time Reward'
        message={getOtrAlertMessage(currentOtrAdding)}
      />
      <HeaderContainer>
        <Text variant='h2'>Add One Time Rewards</Text>
        <HeaderButtons>
          <Button onClick={() => handleCancel()} variant='secondary'>
            Cancel
          </Button>
          <Button
            onClick={handleAddAllOtrs}
            disabled={otrsToAdd.length === 0}
            marginLeft='1rem'
          >
            Distribute Rewards
          </Button>
        </HeaderButtons>
      </HeaderContainer>
      <Table hasHeader={false} maxRows={10}>
        <TableLabelRow padding='1rem 1.5rem' overflow='hidden'>
          <TableRowCell width='23%' equalSize={false}>
            Commuter
          </TableRowCell>
          <TableRowCell width='25%' equalSize={false}>
            Amount
          </TableRowCell>
          <TableRowCell width='50%' equalSize={false}>
            Description
          </TableRowCell>
        </TableLabelRow>
        {addOtrsTableRows}
        <TableRow>
          <div style={{ width: '25%' }}>
            <SearchableDropdown
              options={commuterDropdownItems}
              itemCallback={setCommuter}
              width='80%'
            />
          </div>
          <TableRowCell width='25%' equalSize={false}>
            <NumberInput
              onValueChange={(value: number) =>
                setCurrentOtrAdding({
                  ...currentOtrAdding,
                  amount: value,
                })
              }
              value={currentOtrAdding.amount}
              height='2.75rem'
            >
              <NumberFormatter fixedDecimalScale />
            </NumberInput>
          </TableRowCell>
          <div style={{ width: '42%' }}>
            <TextField
              value={currentOtrAdding.memo}
              onChange={(e) =>
                setCurrentOtrAdding({
                  ...currentOtrAdding,
                  memo: e.target.value,
                })
              }
              maxLength={256}
              height='2.75rem'
              width='80%'
            />
          </div>
          <ConfirmDeleteCell>
            <HoverableAddIcon
              width={32}
              height={32}
              onClick={handleCreateAdditionalOtr}
            />
          </ConfirmDeleteCell>
        </TableRow>
      </Table>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AddOTRView.displayName = 'AddOTRView'
}

export default AddOTRView
