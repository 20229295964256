import Loading from 'core-system/Loading'
import * as Program from 'core-system/Program/Overview'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { leaderboardsActions } from 'redux/leaderboards/leaderboardsSlice'
import { mapIntelligenceActions } from 'redux/mapIntelligence/mapIntelligenceSlice'
import { micromobilityActions } from 'redux/micromobility/micromobilitySlice'
import { CardProgram, Merchant } from 'redux/programs/programsTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import MerchantUtils from 'shared/MerchantUtils'
import styled from 'styled-components'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1rem;
  margin-top: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
  }
`

const LargeBodyContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  height: ${(props) => props.theme.pxToRem(417)};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`

const SmallBodyContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  height: fit-content;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`

const getActiveOptions = (
  program: CardProgram,
  merchantsMap: Dictionary<Merchant>
) => {
  const sortedIds = ProgramUtils.sortMicroMerchantIds(
    program.merchants,
    merchantsMap
  )

  return {
    leasing: sortedIds.leasing.length > 0,
    shared: sortedIds.shared.length > 0,
    oneTime: sortedIds.oneTime.length > 0,
  }
}

interface MicromobilityOverviewViewProps {
  activeProgram: CardProgram
}

const MicromobilityOverviewView = React.memo(
  (props: MicromobilityOverviewViewProps) => {
    const { activeProgram } = props
    const dispatch = useDispatch()
    const segmentId = useQueryParam('segmentId')

    const availableMerchants = useSelector(
      (state: AppState) => state.micromobility.availableMerchants
    )
    const merchantsMap = useSelector(
      (state: AppState) => state.programs.merchantsMap
    )
    const { dashboardStats, serviceUsage } = useSelector(
      (state: AppState) => state.micromobility
    )
    const { recentTripOrigins, tripDetails } = useSelector(
      (state: AppState) => state.mapIntelligence.micromobility
    )
    const { leaderboards } = useSelector(
      (state: AppState) => state.leaderboards
    )

    useEffect(() => {
      dispatch(
        micromobilityActions.getMicromobilityDashboardStats({
          segmentId,
          type: 'MICROMOBILITY',
        })
      )
      dispatch(
        micromobilityActions.getMicromobilityDashboardServices({
          segmentId,
          type: 'MICROMOBILITY',
          rangeStart: null,
          rangeEnd: null,
        })
      )
      dispatch(
        mapIntelligenceActions.getRecentTripOrigins({
          segment: segmentId,
          modeTypes: MerchantUtils.relevantMicromobilityModeTypes,
          type: 'MICROMOBILITY',
        })
      )
      dispatch(
        micromobilityActions.getMicromobilityRecommendations({
          segmentId,
        })
      )
      dispatch(mapIntelligenceActions.getBaseCommuters({ segmentId }))

      if (leaderboards.length > 0) {
        const activeOverViewLeaderboard = leaderboards.find(
          (leaderboard) =>
            leaderboard.type === 'MICROMOBILITY' &&
            leaderboard.segment === segmentId
        )
        if (activeOverViewLeaderboard) {
          dispatch(
            leaderboardsActions.getLeaderboardRankings({
              familyId: activeOverViewLeaderboard.familyId,
              queryType: 'MICROMOBILITY',
            })
          )
        }
      }
    }, [segmentId, leaderboards, dispatch])

    if (!activeProgram || !availableMerchants || !merchantsMap) {
      return <Loading fullPage />
    }

    return (
      <div>
        <Program.ProgramOverviewHeader
          type='MICROMOBILITY'
          dashboardStats={dashboardStats}
          segmentId={segmentId}
        />
        <GridContainer>
          <div>
            <LargeBodyContainer>
              <Program.ProgramOverviewServices
                type='MICROMOBILITY'
                serviceUsageData={serviceUsage}
              />
              <Program.ProgramOverviewOptions
                activeOptions={getActiveOptions(activeProgram, merchantsMap)}
                budget={activeProgram.budget}
              />
            </LargeBodyContainer>
            <Program.ProgramOverviewMap
              type='MICROMOBILITY'
              recentTrips={recentTripOrigins}
              tripDetails={tripDetails}
            />
          </div>
          <SmallBodyContainer>
            <Program.ProgramOverviewIncentives
              type='MICROMOBILITY'
              segmentId={segmentId}
            />
            <Program.ProgramOverviewLeaderboard
              type='MICROMOBILITY'
              segmentId={segmentId}
            />
          </SmallBodyContainer>
        </GridContainer>
      </div>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityOverviewView.displayName = 'MicromobilityOverviewView'
}

export default MicromobilityOverviewView
