import { employerActions } from 'redux/employer/employerSlice'
import Dropdown, { SelectItemProps } from 'core-system/Dropdown'
import OverflowIcon from 'core-system/Icons/Misc/Overflow'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import Table, {
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { MyCommuter, MyCommuterArchived } from 'redux/employee/employeeTypes'
import { Worksite } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import MyCommuterDetailsModal from './MyCommuterDetailsModal'
import MyCommutersSearch from './MyCommutersSearch'
import { employeeActions } from 'redux/employee/employeeSlice'

const Body = styled.div`
  margin: 1.5rem 0 6rem;
`

const UserContainer = styled.div`
  display: flex;
`

const NameContainer = styled.div`
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`

const EmptyTableRow = styled(TableRow)`
  text-align: center;
`

const getTableRows = (
  filteredCommuters: MyCommuter[],
  sortByFilter: string,
  onRowClick: (id: string) => void,
  menuCallback: (item: SelectItemProps) => void,
  worksites: Worksite[]
) => {
  return filteredCommuters
    .sort((a, b) =>
      sortByFilter === 'desc'
        ? new Date(b.archived).getTime() - new Date(a.archived).getTime()
        : new Date(a.archived).getTime() - new Date(b.archived).getTime()
    )
    .map((commuter, idx) => {
      const matchingWorksite = worksites.find(
        (wrk) => wrk.id === commuter.worksiteId
      )
      const selectItems = [
        {
          id: '0',
          text: 'Re-activate Commuter',
          disabled: matchingWorksite && matchingWorksite.archived,
          employee: commuter,
        },
      ]
      return (
        <TableRow key={idx} display='flex'>
          <TableRowContents
            onClick={() => onRowClick(commuter.id)}
            isClickable={true}
            autoResize={false}
          >
            <TableRowCell
              minWidth={pxToRem(240)}
              equalSize={false}
              overflow='hidden'
            >
              <UserContainer>
                <PlatformSvg
                  folder='users'
                  variant='userBasic'
                  width={40}
                  height={40}
                />
                <NameContainer>
                  <Text variant='action4' hideOverflow>
                    {commuter.name}
                  </Text>
                  <Text variant='body2' textColor={palette.text.placeholder}>
                    {commuter.employeeCorporateId
                      ? `#${commuter.employeeCorporateId}`
                      : ''}
                  </Text>
                </NameContainer>
              </UserContainer>
            </TableRowCell>
            <TableRowCell
              icon='time'
              overflow='hidden'
              equalSize={false}
              flex='1'
            >
              {moment(commuter.archiveDate).format('MMMM Do, YYYY')}
            </TableRowCell>
            <TableRowCell
              icon='location'
              overflow='hidden'
              equalSize={false}
              flex='1'
            >
              {matchingWorksite ? matchingWorksite.alias : 'unknown'}
            </TableRowCell>
            <TableRowCell overflow='hidden' equalSize={false} flex='1.8'>
              {commuter.email}
            </TableRowCell>
            <TableRowCell equalSize={false} marginRight={'0.5rem'}>
              <Dropdown
                variant={'textual'}
                svgDropdown={true}
                icon={<OverflowIcon />}
                active={null}
                items={selectItems}
                itemCallback={menuCallback}
                width={pxToRem(180)}
                top={'2.75rem'}
                right={0}
              />
            </TableRowCell>
          </TableRowContents>
        </TableRow>
      )
    })
}

const formatMyCommuters = (
  employees: MyCommuterArchived[],
  activeSegment: string,
  worksites: Worksite[]
): MyCommuter[] => {
  const relevantWorksite =
    activeSegment !== 'archived' &&
    worksites.find((wrk) => wrk.segmentId === activeSegment)
  if (relevantWorksite) {
    employees = employees.filter(
      (emp) => emp.worksiteId === relevantWorksite.id
    )
  }

  return employees.map((emp) => {
    return {
      id: emp.id,
      name: emp.name,
      email: emp.email,
      employeeCorporateId: emp.employeeCorporateId,
      worksiteId: emp.worksiteId,
      archived: emp.archived,
      archiveDate: emp.archiveDate,
      totalTrips: 0,
      programsEnrolled: [],
      segmentsEnrolled: [],
      competitionsEnrolled: [],
      hasPhysicalCard: false,
      remainingProgramBudget: null,
      currentIncentives: null,
      fleetRemaining: null,
      hasRegistered: false,
      hasInvite: false,
    }
  })
}

interface MyCommutersArchived {
  archivedEmployees: MyCommuterArchived[]
  activeSegment: string
}

const MyCommutersArchived = React.memo((props: MyCommutersArchived) => {
  const { archivedEmployees, activeSegment } = props

  const dispatch = useDispatch()

  const { segmentUpdateFlag, worksites, allEmployeeSegmentId } = useSelector(
    (state: AppState) => state.employer
  )
  const { myCommuters } = useSelector((state: AppState) => state.employee)

  const formattedEmployees = formatMyCommuters(
    archivedEmployees,
    activeSegment,
    worksites
  )

  const [sortByFilter, setSortByFilter] = useState('desc')
  const [filteredCommuters, setFilteredCommuters] = useState(formattedEmployees)
  const [activeEmployeeId, setActiveEmployeeId] = useState(null)

  //check if need to get all employees commuters
  //this will fire after an employee is reactivated and `myCommuters` is set to null
  useEffect(() => {
    if (!myCommuters || Object.keys(myCommuters).length === 0) {
      dispatch(
        employeeActions.getMyCommuters({ segment: allEmployeeSegmentId })
      )
    }
  }, [dispatch, myCommuters, allEmployeeSegmentId])

  useLayoutEffect(() => {
    setFilteredCommuters(
      formatMyCommuters(archivedEmployees, activeSegment, worksites)
    )
  }, [archivedEmployees, activeSegment, worksites])

  useEffect(() => {
    if (segmentUpdateFlag) {
      dispatch(employerActions.getSegments())
      dispatch(employeeActions.getMyCommuters({ segment: segmentUpdateFlag }))
      dispatch(employerActions.toggleSegmentUpdateFlag())
    }
  }, [dispatch, segmentUpdateFlag, formattedEmployees])

  const menuCallback = (item: SelectItemProps) => {
    dispatch(
      employeeActions.reactivateEmployee({ employeeId: item.employee.id })
    )
  }

  const onRowClick = (id: string) => {
    setActiveEmployeeId(id)
  }

  if (!formattedEmployees || !filteredCommuters) {
    return <Loading isCard={false} />
  }

  return (
    <>
      <MyCommutersSearch
        myCommuters={formattedEmployees}
        filteredCommuters={filteredCommuters}
        setFilteredCommuters={setFilteredCommuters}
        sortByFilter={sortByFilter}
        setSortByFilter={setSortByFilter}
        activeSegment={'archived'}
        isAdd={false}
      />
      <Body>
        <Table hasHeader={false} maxRows={10} loadMoreAnimation={false}>
          <TableLabelRow overflow='hidden'>
            <TableRowCell minWidth={pxToRem(240)} equalSize={false}>
              Commuter
            </TableRowCell>
            <TableRowCell equalSize={false} flex='1'>
              Archived Date
            </TableRowCell>
            <TableRowCell equalSize={false} flex='1'>
              Worksite
            </TableRowCell>
            <TableRowCell equalSize={false} flex='1.8'>
              Email
            </TableRowCell>
            <TableRowCell width='5%' equalSize={false} marginRight='0.5rem' />
          </TableLabelRow>
          {filteredCommuters.length > 0 ? (
            getTableRows(
              filteredCommuters,
              sortByFilter,
              onRowClick,
              menuCallback,
              worksites
            )
          ) : (
            <EmptyTableRow>No Results Found</EmptyTableRow>
          )}
        </Table>
      </Body>
      <MyCommuterDetailsModal
        employeeId={activeEmployeeId}
        open={activeEmployeeId && true}
        closeModal={() => setActiveEmployeeId(null)}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommutersArchived.displayName = 'MyCommutersArchived'
}

export default MyCommutersArchived
