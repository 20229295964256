import React from 'react'
import { CarpoolMember } from 'redux/employeePlatform/employeePlatformTypes'
import Text from 'core-system/Text'
import {
  DistanceItem,
  InfoItem,
  CardListCard,
  DistancesContainer,
  InfoContainer,
  CardListCardTop,
} from 'employee-platform/shared/styles/CardList'
import palette from 'core-system/Themes/palette'
import { convertMetresToMiles } from 'employee-platform/shared/utils'

const distanceField = (source: 'You' | 'Destination', distance: number) => {
  return (
    <DistanceItem>
      <Text
        variant='action3'
        textAlign='center'
      >{`Distance from ${source}`}</Text>
      <Text
        variant='action1'
        textColor={palette.primary.primaryPurple}
      >{`${distance} mi`}</Text>
    </DistanceItem>
  )
}

const infoField = (label: string, value: string, isMobile: boolean) => {
  return (
    <InfoItem isMobile={isMobile}>
      <Text variant='action3'>{`${label}:`}</Text>
      <Text variant='action3' textColor={palette.text.secondary} hideOverflow>
        {value}
      </Text>
    </InfoItem>
  )
}

interface CarpoolMemberCardProps {
  isMobile: boolean
  memberData: CarpoolMember
}

const CarpoolMemberCard = React.memo((props: CarpoolMemberCardProps) => {
  const { isMobile, memberData } = props

  return (
    <CardListCard>
      <CardListCardTop>
        <InfoContainer>
          {infoField('Name', memberData.firstName, isMobile)}
          {infoField('Email', memberData.contactInfo, isMobile)}
          {infoField(
            'Role',
            memberData.driver ? 'Driver' : 'Passenger',
            isMobile
          )}
        </InfoContainer>
        <DistancesContainer>
          {distanceField(
            'You',
            convertMetresToMiles(memberData.distanceSource, 1)
          )}
          {distanceField(
            'Destination',
            convertMetresToMiles(memberData.distanceDest, 1)
          )}
        </DistancesContainer>
      </CardListCardTop>
    </CardListCard>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolMemberCard.displayName = 'CarpoolMemberCard'
}

export default CarpoolMemberCard
