import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.252 21.252C21.0272 21.4755 20.723 21.601 20.406 21.601C20.089 21.601 19.7848 21.4755 19.56 21.252L15.78 17.472C14.0795 18.7447 11.96 19.3259 9.84821 19.0987C7.7364 18.8714 5.78908 17.8526 4.39825 16.2473C3.00742 14.642 2.27636 12.5695 2.35223 10.4468C2.4281 8.32418 3.30528 6.30908 4.80717 4.80719C6.30907 3.30529 8.32416 2.42812 10.4468 2.35224C12.5694 2.27637 14.642 3.00743 16.2473 4.39826C17.8526 5.78909 18.8714 7.73642 19.0987 9.84823C19.3259 11.96 18.7447 14.0795 17.472 15.78L21.252 19.56C21.4755 19.7849 21.601 20.089 21.601 20.406C21.601 20.723 21.4755 21.0272 21.252 21.252ZM15.024 6.55202C14.1856 5.71103 13.1166 5.13772 11.9522 4.90469C10.7878 4.67166 9.58042 4.78938 8.48297 5.24295C7.38552 5.69653 6.44734 6.46555 5.78722 7.45265C5.1271 8.43975 4.77474 9.60054 4.77474 10.788C4.77474 11.9755 5.1271 13.1363 5.78722 14.1234C6.44734 15.1105 7.38552 15.8795 8.48297 16.3331C9.58042 16.7867 10.7878 16.9044 11.9522 16.6714C13.1166 16.4383 14.1856 15.865 15.024 15.024C16.1452 13.8994 16.7747 12.3761 16.7747 10.788C16.7747 9.19998 16.1452 7.6767 15.024 6.55202Z'
    />
  </>,
  'Search'
)
