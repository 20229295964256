import React, { useEffect } from 'react'
import Loading from 'core-system/Loading'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AppState } from 'redux/config/store'
import { OtpProgram } from 'redux/otp/otpTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import { otpActions } from 'redux/otp/otpSlice'
import OTPOverviewHeader from './components/OTPOverviewHeader'
import styled from 'styled-components'
import * as Program from 'core-system/Program/Overview'
import moment from 'moment'
import OTPOptions from './components/OTPOptions'
import OTPReasons from './components/OTPReasons'
import OTPReasonsBreakdownCard from './components/OTPReasonsBreakdownCard'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1rem;
  margin-top: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
  }
`

const LargeBodyContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`

const SmallBodyContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  height: fit-content;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`

interface OTPOverviewViewProps {
  activeProgram: OtpProgram
}

const OTPOverviewView = React.memo((props: OTPOverviewViewProps) => {
  const { activeProgram } = props
  const dispatch = useDispatch()
  const segmentId = useQueryParam('segmentId')

  const { dashboardStats, serviceUsage, usageHistory } = useSelector(
    (state: AppState) => state.otp
  )
  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  useEffect(() => {
    dispatch(
      otpActions.getOtpDashboardStats({
        segmentId,
        type: 'OTP',
      })
    )
    dispatch(
      otpActions.getOtpRecommendations({
        segmentId,
      })
    )
    // range = cycle start + today
    dispatch(
      otpActions.getOtpDashboardServices({
        segmentId,
        type: 'OTP',
        rangeStart: activeProgram.cycleStart,
        rangeEnd: moment().utc().format(),
      })
    )
    dispatch(otpActions.getOtpUsageHistory({ segmentId }))
  }, [segmentId, dispatch, activeProgram])

  if (
    !activeProgram ||
    !dashboardStats ||
    !serviceUsage ||
    !usageHistory ||
    !segmentsMap
  ) {
    return <Loading fullPage />
  }

  const totalEmployees = segmentsMap[segmentId].commuters
  const segmentName = segmentsMap[segmentId].name
  const monthsRemaining = moment(activeProgram.cycleEnd).diff(
    moment(),
    'months'
  )
  return (
    <>
      <OTPOverviewHeader
        stats={dashboardStats}
        transactionLimit={activeProgram.budget}
        transactionsPerEmployee={activeProgram.numUses}
        numEmployees={totalEmployees}
        monthsRemaining={monthsRemaining}
      />
      <GridContainer>
        <div>
          <LargeBodyContainer>
            <Program.ProgramOverviewServices
              type='OTP'
              serviceUsageData={serviceUsage}
            />
            <OTPOptions
              transactionLimit={activeProgram.budget}
              totalBudget={activeProgram.budget * totalEmployees}
              micromobilityActive={true}
            />
          </LargeBodyContainer>
          <OTPReasons usageHistory={usageHistory} segmentName={segmentName} />
        </div>
        <SmallBodyContainer>
          <OTPReasonsBreakdownCard usageHistory={usageHistory} />
        </SmallBodyContainer>
      </GridContainer>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OTPOverviewView.displayName = 'OTPOverviewView'
}

export default OTPOverviewView
