import { AxiosResponse } from 'axios'
import {
  DashboardCsvReportParams,
  DashboardReportParams,
} from 'redux/dashboard/dashboardTypes'
import Request from '../config/services/Request'

const getDashboardBulkData = (): Promise<AxiosResponse> => {
  return Request.get('/hr/dashboard/main_metrics/')
}

const getDashboardMapData = (): Promise<AxiosResponse> => {
  return Request.get('/hr/dashboard/recent_trips/')
}

const getDashboardTrackers = (): Promise<AxiosResponse> => {
  return Request.get('/hr/dashboard/employee_trackers/')
}

const getDashboardReport = (
  params: DashboardReportParams
): Promise<AxiosResponse> => {
  let URLQuery = ''

  if (params.startDate) {
    URLQuery = 'start_date=' + params.startDate
  }
  if (params.endDate) {
    URLQuery += '&end_date=' + params.endDate
  }
  if (params.segmentId) {
    URLQuery += '&segment_id=' + params.segmentId
  }

  return Request.get('/hr/dashboard/report/?' + URLQuery, true)
}

const getDashboardCsvReport = (
  params: DashboardCsvReportParams
): Promise<AxiosResponse> => {
  const reportUrls = {
    otr: '/hr/dashboard/reports/one_time_rewards/',
    competitions: '/hr/dashboard/reports/competition_winners/',
    trips: '/hr/dashboard/reports/trips/',
    incentives: '/hr/dashboard/reports/incentives_rewarded/',
    transactions: '/hr/dashboard/reports/transactions/',
    taxSavings: '/hr/dashboard/reports/pretax_eligible_transactions/',
  }

  let URLQuery = ''
  if (params.startDate) {
    URLQuery = 'date__gte=' + params.startDate
  }
  if (params.endDate) {
    URLQuery += '&date__lt=' + params.endDate
  }
  if (params.segmentId) {
    URLQuery += '&segment=' + params.segmentId
  }

  return Request.get(
    `${reportUrls[params.reportType]}?${URLQuery}&format=csv`,
    true
  )
}

export default {
  getDashboardBulkData,
  getDashboardMapData,
  getDashboardTrackers,
  getDashboardReport,
  getDashboardCsvReport,
}
