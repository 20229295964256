import Button from 'core-system/Button'
import { ColumnCard } from 'core-system/ColumnView'
import SummaryOverview from 'core-system/Program/Summary/SummaryOverview'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import GRHSummaryBudget from 'features/GRH/Activation/components/GRHSummaryBudget'
import GRHSummaryOptions from 'features/GRH/Activation/components/GRHSummaryOptions'
import moment from 'moment'
import React from 'react'
import { Segment } from 'redux/employer/employerTypes'
import { GrhActiveOptions, GrhManageOptionState } from 'redux/grh/grhTypes'
import styled from 'styled-components'

const Container = styled(ColumnCard)`
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
`

interface GRHManageSummaryProps {
  toggleEdit: () => void
  canEdit: boolean
  program: GrhManageOptionState
  currentSegment: Segment
  activeOptions: GrhActiveOptions
  handleOpenModal: () => void
}

const GRHManageSummary = React.memo((props: GRHManageSummaryProps) => {
  const {
    toggleEdit,
    canEdit,
    program,
    currentSegment,
    activeOptions,
    handleOpenModal,
  } = props

  return (
    <Container>
      <Text variant='h4' marginBottom='1.5rem'>
        {canEdit ? "Next Month's Program" : 'Current Program'}
      </Text>
      <SummaryOverview
        startDate={moment(program.startDate, 'YYYY-MM-DD').format(
          'MMMM Do, YYYY'
        )}
        currentSegment={currentSegment}
        marginBottom='1rem'
      />
      <GRHSummaryOptions
        activeOptions={activeOptions}
        activeMerchantIds={program.rideshare.activeMerchantIds}
        availableMerchantIds={program.rideshare.availableMerchantIds}
        handleOpenModal={handleOpenModal}
        canEdit={canEdit}
      />
      <GRHSummaryBudget
        totalEmployees={currentSegment.commuters}
        transactionLimit={program.budget}
        totalUses={program.numUses}
      />
      <Text
        variant='body2'
        textColor={palette.text.placeholder}
        marginBottom={!canEdit && '1.5rem'}
      >
        All budget amounts are estimated based on max commuter spend.
      </Text>
      {!canEdit && (
        <Button width='100%' onClick={toggleEdit}>
          Edit Next Year&apos;s Program
        </Button>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHManageSummary.displayName = 'GRHManageSummary'
}

export default GRHManageSummary
