import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { SegmentAndDateParams } from 'redux/employer/employerTypes'
import {
  IncentiveGroup,
  IncentiveProgram,
  IncentiveProgramCreate,
  IncentivesNumTrips,
  IncentivesPauseArgs,
  IncentivesRewarded,
  TripMetrics,
  TripPayload,
  TripStats,
  OneTimeReward,
  OneTimeRewardPayload,
} from 'redux/incentives/incentivesTypes'

export interface IncentivesState {
  allIncentives: Dictionary<IncentiveGroup>
  incentivesTrips: Dictionary<number>
  incentivesRewarded: Dictionary<IncentivesRewarded[]>
  tripMetrics: TripMetrics
  activeMonthSegmentId: string
  month: TripStats
  day: TripStats
  allOneTimeRewards: OneTimeReward[]

  incentivesLoaded: boolean
  incentivesUpdated: boolean
}

const initialState: IncentivesState = {
  allIncentives: null,
  incentivesTrips: null,
  incentivesRewarded: {},
  tripMetrics: null,
  activeMonthSegmentId: null,
  month: null,
  day: null,
  allOneTimeRewards: null,

  incentivesLoaded: false,
  incentivesUpdated: false,
}

const getIncentives = createAction<string>('incentives/getIncentives')
const getIncentivesSuccess = createAction('incentives/getIncentivesSuccess')
const createIncentives = createAction<IncentiveProgramCreate>(
  'incentives/createIncentives'
)
const createIncentivesSuccess = createAction(
  'incentives/createIncentivesSuccess'
)
const updateIncentives = createAction<IncentiveProgram>(
  'incentives/updateIncentives'
)
const pauseIncentives = createAction<IncentivesPauseArgs>(
  'incentives/pauseIncentives'
)
const getIncentivesRewarded = createAction<SegmentAndDateParams>(
  'incentives/getIncentivesRewarded'
)

const handleToggleIncentivesUpdate = (state: IncentivesState) => {
  if (!state.incentivesUpdated) {
    return {
      ...state,
      incentivesUpdated: true,
    }
  }

  return {
    ...state,
    incentivesUpdated: !state.incentivesUpdated,
  }
}

const getAllOneTimeRewards = createAction('incentives/getAllOneTimeRewards')

const createOneTimeReward = createAction<OneTimeRewardPayload>(
  'incentives/createOneTimeReward'
)

const bulkCreateOneTimeRewards = createAction<OneTimeRewardPayload[]>(
  'incentives/bulkCreateOneTimeRewards'
)

const incentivesSlice = createSlice({
  name: 'incentives',
  initialState,
  reducers: {
    getAllIncentivesSuccess(state, action: PayloadAction<IncentiveProgram[]>) {
      const activeStatuses = ['ACTIVE', 'UPDATING']
      const groupedIncentives: Dictionary<IncentiveGroup> =
        action.payload.reduce((state, incentives) => {
          if (incentives.status === 'CANCELLED') return state

          state[incentives.segment] = state[incentives.segment] || {}

          const defaultIncentives = {} as any
          const formattedIncentives = {
            ...incentives,
            maxVal: parseInt(incentives.maxVal as string),
          }
          if (activeStatuses.includes(incentives.status)) {
            defaultIncentives.active = formattedIncentives
          } else {
            defaultIncentives.nextMonth = formattedIncentives
          }

          state[incentives.segment] = {
            ...state[incentives.segment],
            ...defaultIncentives,
          }
          return state
        }, {})

      return {
        ...state,
        allIncentives: groupedIncentives,
        incentivesLoaded: true,
      }
    },
    getAllIncentivesTripsSuccess(
      state,
      action: PayloadAction<IncentivesNumTrips[]>
    ) {
      const incentivesTrips: Dictionary<number> = action.payload.reduce(
        (state, incentives) => {
          state[incentives.segment] = incentives.numTrips
          return state
        },
        {}
      )

      return {
        ...state,
        incentivesTrips: incentivesTrips,
      }
    },
    updateIncentivesSuccess(state) {
      return handleToggleIncentivesUpdate(state)
    },
    toggleIncentivesUpdated(state) {
      return handleToggleIncentivesUpdate(state)
    },
    pauseIncentivesSuccess(state) {
      return handleToggleIncentivesUpdate(state)
    },
    getIncentivesRewardedSuccess(state, action: any) {
      state.incentivesRewarded = {
        ...state.incentivesRewarded,
        [action.meta.segmentId]: action.payload,
      }
    },
    getTrips(state, action: PayloadAction<TripPayload>) {
      const isDayDate =
        moment(action.payload.date, 'YYYY-MM-DD').format('YYYY-MM-DD') ===
        action.payload.date

      if (state.month && !isDayDate) {
        return {
          ...state,
          month: null,
          activeMonthSegmentId: action.payload.segmentId,
        }
      } else if (isDayDate) {
        return {
          ...state,
          day: null,
        }
      } else {
        return {
          ...state,
          activeMonthSegmentId: action.payload.segmentId,
        }
      }
    },
    getTripsSuccess(state, action: PayloadAction<TripStats>) {
      if (action.payload.month) {
        return {
          ...state,
          month: action.payload,
        }
      } else {
        return {
          ...state,
          day: action.payload,
        }
      }
    },
    resetTripsData(state, action: PayloadAction<'month' | 'day'>) {
      return {
        ...state,
        month: action.payload === 'month' ? null : state.month,
        day: action.payload === 'day' ? null : state.day,
      }
    },
    getAllOneTimeRewardsSuccess(state, action: PayloadAction<OneTimeReward[]>) {
      return {
        ...state,
        allOneTimeRewards: action.payload,
      }
    },
    // See if these can be merged into one (similar to the comment I left in incentivesService)
    createOneTimeRewardSuccess(state, action: PayloadAction<OneTimeReward>) {
      return {
        ...state,
        allOneTimeRewards: state.allOneTimeRewards
          ? [...state.allOneTimeRewards, action.payload]
          : [action.payload],
      }
    },
    bulkCreateOneTimeRewardsSuccess(
      state,
      action: PayloadAction<OneTimeReward[]>
    ) {
      return {
        ...state,
        allOneTimeRewards: state.allOneTimeRewards
          ? [...state.allOneTimeRewards, ...action.payload]
          : action.payload,
      }
    },
  },
})

export const incentivesReducer = incentivesSlice.reducer
export const incentivesActions = {
  ...incentivesSlice.actions,
  getIncentives,
  getIncentivesSuccess,
  createIncentives,
  createIncentivesSuccess,
  updateIncentives,
  pauseIncentives,
  getIncentivesRewarded,
  getAllOneTimeRewards,
  createOneTimeReward,
  bulkCreateOneTimeRewards,
}
