import { employerActions } from 'redux/employer/employerSlice'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import Button from 'core-system/Button'
import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Modal, { ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { Worksite } from 'redux/employer/employerTypes'
import styled from 'styled-components'

const Container = styled(FlexContainer)`
  text-align: center;
  padding: 0.5rem 0 1rem;
`
const UnderlinedText = styled.span`
  text-decoration: underline;
  font-weight: 700;
`

interface UnarchiveWorksiteModalProps {
  worksite: Worksite
  open: boolean
  closeModal: () => void
}

const UnarchiveWorksiteModal = React.memo(
  (props: UnarchiveWorksiteModalProps) => {
    const { worksite, open, closeModal } = props
    const dispatch = useDispatch()
    const { worksites } = useSelector((state: AppState) => state.employer)
    const handleUnarchive = () => {
      if (worksites && worksites.filter((wrk) => wrk.archived).length < 1) {
        dispatch(
          notificationsActions.generalPageError(
            'You need at least one archived worksite!'
          )
        )
      } else {
        dispatch(employerActions.unarchiveWorksite({ worksiteId: worksite.id }))
      }
      closeModal()
    }

    return (
      <Modal
        open={open}
        onClose={() => closeModal()}
        width={pxToRem(800)}
        maxHeight='unset'
      >
        <ModalHeader title='Unarchive a Worksite' />
        <Container center flexDirection='column' padding='0.5rem'>
          <PlatformSvg folder='archive' variant='archiveLogo' width='200px' />
          <Text variant='action2' marginTop='1rem'>
            It will also unarchive{' '}
            <UnderlinedText>
              {/* This is a band-aid fix: when archiving a worksite with employees in staging, the archived employee count isn't getting updated
              likely being caused by the get request happening before the bulk_archive_employees task is finished */}
              {worksite.employeeArchivedCount + worksite.employeeCount}{' '}
              commuter(s)
            </UnderlinedText>
          </Text>
        </Container>
        <ModalFooter>
          <Button
            variant={'tertiary'}
            marginRight={pxToRem(29)}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button onClick={handleUnarchive}>Unarchive Worksite</Button>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  UnarchiveWorksiteModal.displayName = 'UnarchiveWorksiteModal'
}

export default UnarchiveWorksiteModal
