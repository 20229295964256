import ProgramUtils from 'core-system/Program/ProgramUtils'
import { GrhActiveOptions, GrhManageState } from 'redux/grh/grhTypes'
import { Merchant } from 'redux/programs/programsTypes'
import FormattingUtils from 'shared/FormattingUtils'

const recTransacLimit = 3000
const recUses = 10

const defaultErrorState = {
  uses: false,
  transactionLimit: false,
  rideshare: false,
  localTaxi: false,
}

const metroAreaTaxiProviders = {
  USSanFranciscoBayArea: ['yellow cab sf'],
  USNewYorkMetropolitanArea: ['nyc yellow cab'],
  CAGreaterTorontoArea: [],
  USSeattleMetropolitanArea: [],
  USAustinMetropolitanArea: [],
  USChicagoMetropolitanArea: [],
  USBostonMetropolitanArea: [],
  USLosAngelesMetropolitanArea: [],
  USMiamiMetropolitanArea: [],
  USDCMetroArea: [],
  USPhiladelphiaMetropolitanArea: [],
}

const getLocalTaxiProviders = (
  localTaxiMerchantIds: string[],
  merchantsWithLocalTaxi: Merchant[],
  metroAreas: string[]
) => {
  const relevantMerchants = metroAreas.reduce((agg, metroArea) => {
    return metroArea ? [...agg, ...metroAreaTaxiProviders[metroArea]] : agg
  }, [])
  const relevantMerchantIds = relevantMerchants.map((name) => {
    return merchantsWithLocalTaxi.find((m) => m.name.toLowerCase() === name)?.id
  })

  return relevantMerchantIds.filter((merchantId: string) =>
    localTaxiMerchantIds.includes(merchantId)
  )
}

//this is used to see if the upcoming program has changes
const programHasChanges = (
  programState: GrhManageState,
  activeOptions: Dictionary<GrhActiveOptions>
) => {
  const currentBudget = FormattingUtils.formatDollar(
    programState.active.budget,
    0
  )
  const nextMonthBudget = FormattingUtils.formatDollar(
    programState.nextMonth.budget,
    0
  )

  const currentUses = programState.active.numUses
  const nextMonthUses = programState.nextMonth.numUses

  const optionChanges = Object.keys(activeOptions.active).flatMap((option) => {
    if (activeOptions.active[option] !== activeOptions.nextMonth[option]) {
      return option
    } else {
      return []
    }
  })

  const providerChanges: any = ProgramUtils.getProviderDifference(
    programState,
    ['rideshare', 'localTaxi']
  )

  //only compare provider changes on active options
  const hasProviderChanges =
    Object.keys(providerChanges).length > 0 &&
    Object.keys(providerChanges).some(
      (option) => activeOptions.nextMonth[option]
    )

  return (
    currentBudget !== nextMonthBudget ||
    optionChanges.length > 0 ||
    hasProviderChanges ||
    currentUses !== nextMonthUses
  )
}

export default {
  recTransacLimit,
  recUses,
  defaultErrorState,
  metroAreaTaxiProviders,
  getLocalTaxiProviders,
  programHasChanges,
}
