import { employerActions } from 'redux/employer/employerSlice'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import Button from 'core-system/Button'
import Map from 'core-system/Map/Map'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import TextField from 'core-system/TextField'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import styled, { css } from 'styled-components'

const defaultErrorState = {
  name: false,
  address: false,
  employees: false,
}

const Container = styled.div`
  margin-bottom: 1rem;
`

const MapContainer = styled.div<{ hasError: boolean }>`
  ${(props) => props.theme.baseCard}
  overflow: hidden;
  position: relative;
  padding: 0;

  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid ${props.theme.palette.secondary.red1};
    `}
`

interface WorksiteModalProps {
  closeModal: () => void
  open: boolean
}

const EditWorksiteModal = React.memo((props: WorksiteModalProps) => {
  const { open, closeModal } = props

  const dispatch = useDispatch()

  const [worksiteName, setWorksiteName] = useState('')
  const [unitNumber, setUnitNumber] = useState('')
  const [worksiteAddress, setWorksiteAddress] = useState('')
  const [expectedEmployees, setExpectedEmployees] = useState('')
  const [errorState, setErrorState] = useState(defaultErrorState)

  const addWorksite = () => {
    const checkErrors = {
      name: worksiteName.trim() === '',
      address: worksiteAddress.trim() === '',
      employees:
        expectedEmployees.trim() === '' || isNaN(parseInt(expectedEmployees)),
    }

    const hasNoErrors = Object.keys(checkErrors).every(
      (key) => !checkErrors[key]
    )

    if (hasNoErrors) {
      dispatch(
        employerActions.createWorksite({
          alias: worksiteName,
          address: worksiteAddress,
          expectedEmployees: parseInt(expectedEmployees),
          unitNumber: unitNumber.trim() === '' ? null : unitNumber,
        })
      )

      SegmentService.track('addWorksite-btn-click', {
        name: worksiteName,
        address: worksiteAddress,
        expectedEmployees: parseInt(expectedEmployees),
        unitNumber: unitNumber.trim() === '' ? null : unitNumber,
      })

      setUnitNumber('')
      closeModal()
    } else {
      setErrorState(checkErrors)
      dispatch(
        notificationsActions.generalPageError(
          'Make sure all fields have a valid input'
        )
      )
    }
  }

  const handleGeocoderResult = useCallback(({ result }) => {
    setWorksiteAddress(result.place_name)
  }, [])

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      width={pxToRem(800)}
      maxHeight='unset'
    >
      <ModalHeader title='Add a Worksite' />
      <ModalBody>
        <Container>
          <TextField
            label='Office Name'
            onChange={(e) => setWorksiteName(e.currentTarget.value)}
            invalid={errorState.name}
            helpText='Please enter a valid name'
            data-cy='accounts-office-name-input'
          />
        </Container>
        <Container>
          <Text
            variant='body1'
            textColor={palette.text.secondary}
            marginBottom='0.25rem'
          >
            Street Address
          </Text>
          <MapContainer hasError={errorState.address}>
            <Map
              style={{
                height: '350px',
                width: '100%',
              }}
              geocoderCallback={handleGeocoderResult}
            />
          </MapContainer>
          {errorState.address && (
            <Text
              variant='body2'
              textColor={palette.secondary.red1}
              marginTop='0.3125rem'
            >
              Please enter a valid address
            </Text>
          )}
        </Container>
        <Container>
          <TextField
            label='Unit Number'
            onChange={(e) => setUnitNumber(e.currentTarget.value)}
            data-cy='accounts-unit-number-input'
          />
        </Container>
        <Container>
          <TextField
            label='Number of Maximum Commuters'
            onChange={(e) => setExpectedEmployees(e.currentTarget.value)}
            invalid={errorState.employees}
            helpText='Please enter a valid number of commuters'
            data-cy='accounts-max-employees-input'
          />
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          variant={'tertiary'}
          marginRight={pxToRem(29)}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button onClick={addWorksite}>Add</Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EditWorksiteModal.displayName = 'EditWorksiteModal'
}

export default EditWorksiteModal
