import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <g clipPath='url(#clip0)'>
      <path d='M19 3H11C10.45 3 10 3.45 10 4V5.61C10 5.61 10 5.61 10.01 5.62L15.01 10.12C15.64 10.68 16 11.5 16 12.35V13H18V15H16V17H18V19H16V21H19C19.55 21 20 20.55 20 20V4C20 3.45 19.55 3 19 3ZM14 7H12V5H14V7ZM18 11H16V9H18V11ZM18 7H16V5H18V7Z' />
      <path d='M14 20.0001V12.3501C14 12.0701 13.88 11.8001 13.67 11.6101L8.67 7.1101C8.48 6.9301 8.24 6.8501 8 6.8501C7.76 6.8501 7.52 6.9401 7.33 7.1101L2.33 11.6101C2.12 11.7901 2 12.0601 2 12.3501V20.0001C2 20.5501 2.45 21.0001 3 21.0001H5C5.55 21.0001 6 20.5501 6 20.0001V16.0001H10V20.0001C10 20.5501 10.45 21.0001 11 21.0001H13C13.55 21.0001 14 20.5501 14 20.0001Z' />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='24' height='24' />
      </clipPath>
    </defs>
  </>,
  'NeighbourhoodAlt'
)
