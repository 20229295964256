export const mapboxConfig = {
  apiKey:
    'pk.eyJ1IjoiZmxlZXRkZXYiLCJhIjoiY2tlajZiNGxnMDQ4aTJ0cGYxbDhqNjlxNiJ9.CIH6Wg04VnOtPIwWPBI31g',
  placesApiKey:
    'pk.eyJ1IjoiZmxlZXRkZXYiLCJhIjoiY2w3NmJjZWUyMjA4dzNvcXBwZ2R0ZHJyZiJ9.AslQaTQJfdOBGnMFu3P81Q',
  baseMapStyle: 'mapbox://styles/fleetdev/ckek49ojw1x4o19odjvqn7k4p',
  baseMapLight: 'mapbox://styles/fleetdev/ckhgilhe00ejp19q797yeof1s',
  baseMapPurple: 'mapbox://styles/fleetdev/ckhkun1ro046m19qaibtym8cw',
  covidUrl: 'https://fleet-geodata.s3.amazonaws.com/countycovid.json',
}
