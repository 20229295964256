import Divider from 'core-system/Divider'
import WalletIcon from 'core-system/Icons/Micromobility/Wallet'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'
import ProgramPriceCoverage from '../../../../core-system/Program/ProgramPriceCoverage'
import MicromobilityUtils from '../MicromobilityUtils'

const PurpleContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  width: 100%;
  display: flex;
`

const IconContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.purple5};
  color: ${(props) => props.theme.palette.primary.primaryPurple};
  border-radius: 50%;
  width: 3.25rem;
  height: 3.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

interface MicromobilityOneTimePurchaseProps {
  budget: number
  percentage: number
}

const MicromobilityOneTimePurchase = React.memo(
  (props: MicromobilityOneTimePurchaseProps) => {
    const { budget, percentage } = props
    return (
      <>
        <PurpleContainer>
          <IconContainer>
            <WalletIcon width={19} height={19} />
          </IconContainer>
          <TextContainer>
            <Text variant='h2' display='flex'>
              {FormattingUtils.formatDollar(
                MicromobilityUtils.monthsLeft * budget,
                0
              )}
              <Text
                variant='action3'
                alignSelf='flex-end'
                margin='0 0 0.1875rem 0.375rem'
              >
                one-time budget
              </Text>
            </Text>
            <Text
              variant='body2'
              display='flex'
              textColor={palette.text.secondary}
            >
              Combine the remaining
              <Text variant='action3' margin='-0.125rem 0.1875rem 0'>
                {MicromobilityUtils.monthsLeft}
              </Text>
              months from the selected start date.
            </Text>
          </TextContainer>
        </PurpleContainer>
        <Divider marginY='1.5rem' />
        <Text variant='action3' marginBottom='0.5rem'>
          Annual Memberships
        </Text>
        <Text variant='body1' marginBottom='1.5rem'>
          Alternatively, commuters can buy annual memberships with their
          combined mobility budget.
        </Text>
        <ProgramPriceCoverage
          percentage={percentage}
          title='Annual Membership Price Covered'
          description={`Your budget will cover ${percentage}% of the average annual
                  membership price (${FormattingUtils.formatDollar(
                    MicromobilityUtils.avgAnnualLeasing,
                    0
                  )}) for your commuters.`}
        />
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityOneTimePurchase.displayName = 'MicromobilityOneTimePurchase'
}

export default MicromobilityOneTimePurchase
