import { IncentivesManageState } from 'redux/incentives/incentivesTypes'

const dropdownModes = [
  'walk',
  'bike',
  'scooter',
  'transit',
  'carpool',
  'rideshare',
  'bikeshare',
  'scootershare',
  'vanpool',
  'moped',
  'mopedshare',
  'shuttle',
  'car',
]

const incentivesHasChanges = (incentivesState: IncentivesManageState) => {
  const allActiveModes = [
    ...Array.from(
      new Set([
        ...incentivesState.active.activeModes.flatMap((mode) => {
          return mode.text ? [mode.text] : []
        }),
        ...incentivesState.nextMonth.activeModes.flatMap((mode) => {
          return mode.text ? [mode.text] : []
        }),
      ])
    ),
  ]

  const modeTypeChanges = allActiveModes.flatMap((mode) => {
    const activeVal = incentivesState.active.activeModes.find(
      (activeMode) => activeMode.text === mode
    )
    const nextVal = incentivesState.nextMonth.activeModes.find(
      (activeMode) => activeMode.text === mode
    )

    if (activeVal && nextVal && activeVal.perTrip !== nextVal.perTrip) {
      return { mode, activeVal: activeVal.perTrip, nextVal: nextVal.perTrip }
    } else if (activeVal && !nextVal) {
      return { mode, activeVal: activeVal.perTrip, nextVal: 0 }
    } else if (!activeVal && nextVal && nextVal.perTrip) {
      return { mode, activeVal: 0, nextVal: nextVal.perTrip }
    } else {
      return []
    }
  })

  const modeMaxChanges = allActiveModes.flatMap((mode) => {
    const activeVal = incentivesState.active.activeModes.find(
      (activeMode) => activeMode.text === mode
    )
    const nextVal = incentivesState.nextMonth.activeModes.find(
      (activeMode) => activeMode.text === mode
    )

    if (activeVal && nextVal && (activeVal.max || 0) !== (nextVal.max || 0)) {
      return { mode, activeVal: activeVal.max, nextVal: nextVal.max }
    } else if (activeVal && !nextVal) {
      return { mode, activeVal: activeVal.max, nextVal: 0 }
    } else if (!activeVal && nextVal && nextVal.max) {
      return { mode, activeVal: 0, nextVal: nextVal.max }
    } else {
      return []
    }
  })

  const hasBudgetChange =
    incentivesState.active.maxVal !== incentivesState.nextMonth.maxVal
  return (
    hasBudgetChange || modeTypeChanges.length > 0 || modeMaxChanges.length > 0
  )
}

export default {
  dropdownModes,
  incentivesHasChanges,
}
