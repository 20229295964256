import React, { useState } from 'react'
import styled from 'styled-components'
import palette from 'core-system/Themes/palette'
import { OptIn } from 'redux/employeePlatform/employeePlatformTypes'
import Text from 'core-system/Text'
import EmployeeOptInChoicesModal from './EmployeeOptInChoicesModal'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import SegmentService from 'redux/config/services/SegmentService'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const OptInQuestion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  background-color: ${(props) => props.theme.palette.white};
  padding: 1rem 2rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple6};
  }
`

interface EmployeeOptInOpenQuestionsProps {
  openQuestions: OptIn[]
}

const EmployeeOptInOpenQuestions = React.memo(
  (props: EmployeeOptInOpenQuestionsProps) => {
    const { openQuestions } = props

    const [selectedQuestion, setSelectedQuestion] = useState(null)
    const [showChoicesModal, setShowChoicesModal] = useState(false)

    const handleQuestionClick = (question: OptIn) => {
      setSelectedQuestion(question)
      setShowChoicesModal(true)

      SegmentService.track('employee-optIn-questionNav-action-click', {
        action: `optInQuestion-${question.id}`,
      })
    }

    const handleCloseModal = () => {
      setSelectedQuestion(null)
      setShowChoicesModal(false)
    }

    return (
      <Container>
        <Text variant='h2'>Requires Response</Text>
        {openQuestions.length > 0 ? (
          openQuestions.map((question) => {
            return (
              <OptInQuestion
                key={question.id}
                onClick={() => handleQuestionClick(question)}
              >
                <Text variant='action3'>{question.question}</Text>
                <ChevronIcon
                  color={palette.primary.primaryDark}
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </OptInQuestion>
            )
          })
        ) : (
          <NoDataText text='NO QUESTIONS REQUIRE A RESPONSE' center={false} />
        )}
        {showChoicesModal && selectedQuestion ? (
          <EmployeeOptInChoicesModal
            open={showChoicesModal}
            closeModal={() => handleCloseModal()}
            optIn={selectedQuestion}
          />
        ) : null}
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeOptInOpenQuestions.displayName = 'EmployeeOptInOpenQuestions'
}

export default EmployeeOptInOpenQuestions
