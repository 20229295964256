import React, { useState } from 'react'
import styled from 'styled-components'
import SelectItem from './SelectItem'
import Text from 'core-system/Text'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
} from 'styled-system'

export const modeTypeMap = {
  walk: {
    text: 'Walk',
    title: 'Walking',
    description: 'Includes all walking trips',
  },
  bike: {
    text: 'Bike',
    title: 'Bike',
    description: 'Includes all self-owned, leased, and electric bike trips',
  },
  scooter: {
    text: 'Scooter',
    title: 'Scooter',
    description: 'Includes all self-owned, leased, and electric scooter trips',
  },
  transit: {
    text: 'Transit',
    title: 'Transit',
    description:
      'Includes bus, subway, streetcar, light rail, heavy rail, ferry, and monorail trips',
  },
  carpool: {
    text: 'Carpool',
    title: 'Carpool',
    description: 'Includes all carpool trips',
  },
  rideshare: {
    text: 'Rideshare',
    title: 'Rideshare',
    description: 'Includes all rideshare (uber, lyft, etc...) trips',
  },
  bikeshare: {
    text: 'Bikeshare',
    title: 'Bikeshare',
    description: 'Includes all bikeshare trips',
  },
  scootershare: {
    text: 'Scootershare',
    title: 'Scootershare',
    description: 'Includes all scootershare trips',
  },
  vanpool: {
    text: 'Vanpool',
    title: 'Vanpool',
    description: 'Includes all scootershare trips',
  },
  moped: {
    text: 'Moped',
    title: 'Moped',
    description: 'Includes moped and electric moped trips',
  },
  mopedshare: {
    text: 'Mopedshare',
    title: 'Mopedshare',
    description: 'Includes all mopedshare trips',
  },
  shuttle: {
    text: 'Shuttle',
    title: 'Shuttle',
    description: 'Includes all private shuttle trips',
  },
  car: {
    text: 'Car',
    title: 'Car',
    description: 'Includes all carshare and self-owned car trips',
  },
}

const Container = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps &
    PositionProps & {
      open: boolean
    }
>`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.dropdowns};
  top: ${(props) => props.theme.pxToRem(41)};
  display: ${(props) => (props.open ? 'flex' : 'none')};
  width: calc(100% + ${(props) => props.theme.pxToRem(220)});

  ${flexbox}
  ${space}
  ${layout}
  ${position}
`

const Selector = styled.div`
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.white};
  width: calc(100% - ${(props) => props.theme.pxToRem(220)});
  max-height: ${(props) => props.theme.pxToRem(350)};
  border: 1px solid ${(props) => props.theme.palette.text.secondary};
  border-radius: 0 0 0.5rem 0.5rem;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Description = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  border: 1px solid ${(props) => props.theme.palette.text.secondary};
  border-left-width: 0;
  border-radius: 0 0.5rem 0.5rem 0;
  height: ${(props) => props.theme.pxToRem(140)};
  width: ${(props) => props.theme.pxToRem(220)};
  padding: 1.5rem;
`

const ModeText = styled(Text)`
  text-transform: capitalize;
`

const handleClick = (
  item: SelectItemProps,
  itemCallback: (item: SelectItemProps) => void,
  setIsOpen: (open: boolean) => void,
  isPlaceholder: boolean,
  setIsPlaceholder: (visible: boolean) => void
) => {
  itemCallback(item)
  setIsOpen(false)
  if (isPlaceholder) {
    setIsPlaceholder(false)
  }
}

export interface SelectItemProps {
  id: string
  text: string
  disabled?: boolean
  [key: string]: any
}

interface ModeSelectProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    PositionProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  items: SelectItemProps[]
  open: boolean
  itemCallback: (item: SelectItemProps) => void
  setIsOpen: (open: boolean) => void
  active?: string
  isPlaceholder?: boolean
  setIsPlaceholder?: (visible: boolean) => void
}

const ModeSelect = React.memo((props: ModeSelectProps) => {
  const {
    items,
    active,
    open,
    isPlaceholder,
    setIsPlaceholder,
    itemCallback,
    setIsOpen,
  } = props
  const [activeMultiMode, setActiveMultiMode] = useState(null)

  return (
    <Container open={open} {...props}>
      <Selector>
        {items.map((item: SelectItemProps, idx: number) => {
          const isActive = active === item.id
          return (
            <SelectItem
              key={idx}
              active={isActive}
              disabled={item.disabled}
              onMouseEnter={() =>
                !isActive &&
                !item.disabled &&
                setActiveMultiMode(modeTypeMap[item.text])
              }
              onMouseLeave={() =>
                !isActive && !item.disabled && setActiveMultiMode(null)
              }
              onClick={() =>
                !item.disabled &&
                handleClick(
                  item,
                  itemCallback,
                  setIsOpen,
                  isPlaceholder,
                  setIsPlaceholder
                )
              }
            >
              {modeTypeMap[item.text].text}
            </SelectItem>
          )
        })}
      </Selector>
      {activeMultiMode && (
        <Description>
          <Text variant='action3' margin='0.75rem 0'>
            {activeMultiMode.title}
          </Text>
          <ModeText variant='body2'>{activeMultiMode.description}</ModeText>
        </Description>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ModeSelect.displayName = 'ModeSelect'
}

export default ModeSelect
