import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='52' height='52' rx='26' fill='#F0F0FF' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.4884 19.692L22.473 23.7055L18.4575 19.691C17.6637 18.8969 17.1233 17.8853 16.9044 16.784C16.6856 15.6828 16.7982 14.5414 17.228 13.5041C17.6578 12.4669 18.3854 11.5804 19.319 10.9566C20.2526 10.3329 21.3502 10 22.473 10C23.5957 10 24.6933 10.3329 25.6269 10.9566C26.5605 11.5804 27.2882 12.4669 27.718 13.5041C28.1477 14.5414 28.2603 15.6828 28.0415 16.784C27.8226 17.8853 27.2822 18.8979 26.4884 19.692ZM22.473 17.7419C23.6132 17.7419 24.5375 16.8176 24.5375 15.6774C24.5375 14.5372 23.6132 13.6129 22.473 13.6129C21.3328 13.6129 20.4084 14.5372 20.4084 15.6774C20.4084 16.8176 21.3328 17.7419 22.473 17.7419Z'
      fill='#5E5EAA'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.1001 26.5265C28.8936 25.9174 28.3155 25.4839 27.6342 25.4839H16.2794C15.5981 25.4839 15.0304 25.9174 14.8136 26.5265L12.6665 32.7097V40.9677C12.6665 41.5355 13.131 42 13.6988 42H14.731C15.2988 42 15.7633 41.5355 15.7633 40.9677V39.9355H28.1504V40.9677C28.1504 41.5355 28.6149 42 29.1826 42H30.2149C30.7826 42 31.2472 41.5355 31.2472 40.9677V32.7097L29.1001 26.5265ZM16.2794 36.8387C15.4226 36.8387 14.731 36.1471 14.731 35.2903C14.731 34.4335 15.4226 33.7419 16.2794 33.7419C17.1362 33.7419 17.8278 34.4335 17.8278 35.2903C17.8278 36.1471 17.1362 36.8387 16.2794 36.8387ZM27.6342 36.8387C26.7775 36.8387 26.0859 36.1471 26.0859 35.2903C26.0859 34.4335 26.7775 33.7419 27.6342 33.7419C28.491 33.7419 29.1826 34.4335 29.1826 35.2903C29.1826 36.1471 28.491 36.8387 27.6342 36.8387ZM14.731 31.6774L16.2794 27.0323H27.6342L29.1826 31.6774H14.731Z'
      fill='#5E5EAA'
    />
    <path
      d='M36.9246 25.4839C38.0704 25.4839 38.9891 24.5652 38.9891 23.4194C38.9891 22.2735 38.0704 21.3548 36.9246 21.3548C35.7788 21.3548 34.8601 22.2735 34.8601 23.4194C34.8601 24.5652 35.7788 25.4839 36.9246 25.4839Z'
      fill='#5E5EAA'
    />
    <path
      d='M38.9891 42V34.7742H40.5375V28.5806C40.5375 27.4452 39.6084 26.5161 38.473 26.5161H35.3762C34.2407 26.5161 33.3117 27.4452 33.3117 28.5806V34.7742H34.8601V42H38.9891Z'
      fill='#5E5EAA'
    />
  </>,
  'Rideshare',
  '0 0 52 52'
)
