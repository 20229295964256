import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import BonusMedal from 'core-system/Icons/Leaderboards/BonusMedal'
import Tooltip from 'core-system/Tooltip'

const ItemContainer = styled.div<{
  isPending: boolean
  itemClass: 'program' | 'competition'
}>`
  position: relative;
  border-radius: 0.375rem;
  display: flex;

  ${(props) =>
    props.isPending &&
    css`
      opacity: 0.65;
    `};

  ${(props) =>
    props.itemClass === 'program' &&
    css`
      margin-right: 0.25rem;
      width: 2rem;
      height: 2rem;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => props.theme.palette.chips.purple2};
      color: ${(props) => props.theme.palette.text.secondary};
    `};

  ${(props) =>
    props.itemClass === 'competition' &&
    css`
      padding: 0.25rem 0.25rem 0.25rem 0;
      width: ${(props) => props.theme.pxToRem(36)};
    `};
`

const ViewContainer = styled.div<{ itemClass: 'program' | 'competition' }>`
  height: ${(props) => props.theme.pxToRem(32)};
  width: ${(props) => props.theme.pxToRem(32)};
  border-radius: 0.375rem;
  position: absolute;

  ${(props) =>
    props.itemClass === 'competition' &&
    css`
      top: ${(props) => props.theme.pxToRem(8)};
    `};
`

const BonusContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${(props) => props.theme.pxToRem(14)};
  height: ${(props) => props.theme.pxToRem(14)};
  pointer-events: none;
`

interface ProgramItemProps {
  itemClass: 'program' | 'competition'
  itemType: string
  itemIcon: JSX.Element
  itemStatus: string
  segmentName: string
  showRewardBadge: boolean
}

const ProgramItem = React.memo((props: ProgramItemProps) => {
  const {
    itemClass,
    itemType,
    itemIcon,
    itemStatus,
    segmentName,
    showRewardBadge,
  } = props
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef(null)

  return (
    <ItemContainer
      key={itemType}
      isPending={itemStatus === 'PENDING'}
      itemClass={itemClass}
    >
      <ViewContainer
        ref={ref}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        itemClass={itemClass}
      />
      <Tooltip
        target={ref}
        show={isVisible}
        position={'bottom'}
        marginTop={'0.25rem'}
      >
        {segmentName}
      </Tooltip>
      {itemIcon}
      {showRewardBadge && (
        <BonusContainer>
          <BonusMedal width={14} height={14} />
        </BonusContainer>
      )}
    </ItemContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramItem.displayName = 'ProgramItem'
}

export default ProgramItem
