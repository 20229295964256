import React, { useEffect, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import IntercomService from 'redux/config/services/IntercomService'
import { AppState } from 'redux/config/store'

interface IntercomWrapperProps {
  children: ReactNode
}

const IntercomWrapper = React.memo(({ children }: IntercomWrapperProps) => {
  const location = useLocation()
  const { profile } = useSelector((state: AppState) => state.employer)

  useEffect(() => {
    if (profile?.id) {
      IntercomService.boot({
        user_id: profile.id,
        name: profile.name,
        email: `${profile.name}@${profile.domain}`,
      })
    }
  }, [location, profile])

  return <>{children}</>
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  IntercomWrapper.displayName = 'IntercomWrapper'
}
export default IntercomWrapper
