import { userActions } from 'redux/user/userSlice'
import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import FleetLogo from 'core-system/Icons/Misc/FleetLogo'
import Text from 'core-system/Text'
import TextField from 'core-system/TextField'
import palette from 'core-system/Themes/palette'
import React, { useCallback, useEffect, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import UserService from 'redux/user/userService'
import { Locations } from 'shared/Router/Locations'
import UrlParser from 'shared/UrlParser'
import Validators from 'shared/Validators'
import styled from 'styled-components'
import PasswordStrengthMeter from './components/PasswordStrengthMeter'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.secondary.purple7};
`

const FormContainer = styled.div`
  ${(props) => props.theme.typography.body1};
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-color: ${(props) => props.theme.palette.secondary.lavender2};
  border-radius: 0.9375rem;
  max-width: 80%;
  width: ${(props) => props.theme.pxToRem(650)};
  padding: 1rem 4.5rem;
`

const Title = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
  ${(props) => props.theme.typography.h2};
  margin: 1rem 0;
  text-align: center;
`

const InfoText = styled.h4`
  color: ${(props) => props.theme.palette.text.primary};
  ${(props) => props.theme.typography.h4};
  margin-bottom: 2rem;
  text-align: center;

  a {
    color: ${(props) => props.theme.palette.primary.primaryPurple};
  }
`

const LogoContainer = styled.div`
  padding: 1rem 0;
  text-align: center;
`

const StatusText = styled.p<{ error?: boolean }>`
  margin-top: 1rem;
  ${(props) => props.theme.typography.caption};
  font-size: ${(props) => (props.error ? '0.75rem' : '1rem')};
  line-height: 1rem;
  color: ${(props) =>
    props.error
      ? props.theme.palette.secondary.red1
      : props.theme.palette.primary.primaryDark};
`

const ReqText = styled.div`
  color: ${(props) => props.theme.palette.secondary.lavender2};
  ${(props) => props.theme.typography.caption};
  font-size: 0.75rem;
`

const ReqContainer = styled.div`
  padding: 0.5rem 0.2rem 0.3rem;
`

const StyledMobileLink = styled.a`
  text-decoration: none;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const SuccessContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const INITIAL_STATE = {
  pwd: '',
  confirmPwd: '',
  mismatch: false,
  invalid: false,
}

const ResetPasswordView = React.memo(() => {
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false)
  const [state, setState] = useState(INITIAL_STATE)
  const [passwordError, setPasswordError] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const qp = UrlParser.getQueryParams(location.search)

  useEffect(() => {
    if (qp.uid && qp.token) {
      dispatch(userActions.logout())
    }
  }, [dispatch, qp.uid, qp.token])

  const onSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      if (!Validators.checkPassword(state.pwd)) {
        return setState({
          ...state,
          invalid: true,
        })
      }

      if (state.pwd !== state.confirmPwd) {
        return setState({
          ...state,
          mismatch: true,
        })
      }

      UserService.resetPasswordConfirm(
        state.pwd,
        state.confirmPwd,
        qp.uid,
        qp.token
      )
        .then(() => {
          setState(INITIAL_STATE)
          setShowSuccessMsg(true)
          SegmentService.track('resetPassword-btn-click')
          setTimeout(() => {
            navigate(Locations.Login)
          }, 3000)
        })
        .catch((err) => {
          const errorMsg = Object.keys(err.response.data).reduce((agg, key) => {
            agg = agg.concat(err.response.data[key])
            return agg
          }, [])
          setPasswordError(errorMsg.join(' '))
        })

      SegmentService.track('resetPassword-click')
    },
    [state, setState, qp, navigate]
  )

  const onChange = useCallback(
    (val: string, isConfirm?: boolean) => {
      const key = isConfirm ? 'confirmPwd' : 'pwd'
      setState({
        ...state,
        [key]: val,
        mismatch: state.mismatch
          ? state.pwd !== val && state.confirmPwd !== val
          : false,
        invalid: state.invalid ? !Validators.checkPassword(state.pwd) : false,
      })
    },
    [state]
  )

  return (
    <>
      <Helmet>
        <title>Fleet-Reset Password</title>
      </Helmet>
      <Container>
        {(!qp.uid || !qp.token) && (
          <FormContainer>
            <Title>Oops, Something went wrong.</Title>
            <InfoText>
              Please try again or contact support:{' '}
              <a href='mailto:support@movewithfleet.com'>
                support@movewithfleet.com
              </a>
            </InfoText>
          </FormContainer>
        )}
        {qp.uid && qp.token && (
          <FormContainer>
            {!showSuccessMsg ? (
              <>
                <Title>Reset Your Password</Title>
                <InfoText>Enter a new password below.</InfoText>
                <Divider mb={3} />
                <form>
                  <TextField
                    label='New Password*'
                    type='password'
                    defaultValue={state.pwd}
                    placeholder='new password'
                    onChange={(e) => onChange(e.currentTarget.value)}
                    invalid={state.invalid}
                    helpText='Must meet minimum requirements.'
                  />
                  <ReqContainer>
                    <ReqText>
                      Minimum 10 characters, 1 special character, and 1 number
                    </ReqText>
                  </ReqContainer>
                  <PasswordStrengthMeter password={state.pwd} />
                  <TextField
                    label='Confirm Password*'
                    type='password'
                    defaultValue={state.confirmPwd}
                    placeholder='confirm password'
                    onChange={(e) => onChange(e.currentTarget.value, true)}
                    invalid={state.mismatch}
                    helpText='Passwords must match.'
                  />
                  {passwordError && (
                    <Text
                      variant='action3'
                      marginTop='0.5rem'
                      textColor={palette.secondary.red1}
                    >
                      {passwordError}
                    </Text>
                  )}
                  <Button marginTop='1rem' onClick={onSubmit}>
                    Reset Password
                  </Button>
                </form>
              </>
            ) : (
              <SuccessContainer>
                <StatusText>
                  Success! Please login with your new password.
                </StatusText>
                <MobileView>
                  <StyledMobileLink href='fleetmobile://login'>
                    <Button>Login</Button>
                  </StyledMobileLink>
                </MobileView>
                <BrowserView>
                  <StyledLink to={Locations.Login}>
                    <Button>Login</Button>
                  </StyledLink>
                </BrowserView>
              </SuccessContainer>
            )}
            <Divider my={4} />
            <LogoContainer>
              <FleetLogo height='40' width='115' />
            </LogoContainer>
          </FormContainer>
        )}
      </Container>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ResetPasswordView.displayName = 'ResetPasswordView'
}

export default ResetPasswordView
