import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'
import Chip from 'core-system/Chip'
import MultiSelect, { MultiSelectItemProps } from './MultiSelect'
import CaretIcon from '../Icons/Actions/Caret'
import CloseIcon from '../Icons/Actions/Close'
import Portal from '../Portal'
import Text from 'core-system/Text'
import ConditionalWrapper from 'core-system/utils/ConditionalWrapper'
import pxToRem from 'core-system/utils/pxToRem'

const Container = styled.div<FlexboxProps & LayoutProps & SpaceProps>`
  position: relative;

  ${flexbox}
  ${layout}
  ${space}
`

const DropdownCaret = styled(CaretIcon)<SpaceProps & { open: boolean }>`
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
  transition: all 0.2s ease-in-out;
  margin-left: auto;
  color: ${(props) => props.theme.palette.text.primary};

  ${space}
`

const DropdownClose = styled(CloseIcon)<SpaceProps>`
  color: ${(props) => props.theme.palette.white};
  width: 1.5rem;
  height: 1.5rem;

  ${space}
`

const CurrentSelectionText = styled(Text)<{ hasOptionsSelected: boolean }>`
  color: ${(props) =>
    props.hasOptionsSelected
      ? props.theme.palette.white
      : props.theme.palette.text.secondary};
`

interface MultiDropdownProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  categoryName: string
  items: MultiSelectItemProps[]
  activeItems: MultiSelectItemProps[]
  usePortal?: boolean
  defaultString?: string
  itemCallback: (item: MultiSelectItemProps) => void
  onClickCloseIcon: () => void
}

const MultiDropdown = (props: MultiDropdownProps) => {
  const {
    categoryName,
    items,
    activeItems,
    usePortal = false,
    defaultString = 'All',
    itemCallback,
    onClickCloseIcon,
    ...rest
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [dropdownCoors, setCoordinates] = useState(null)

  const containerRef = useRef(null)
  const dropdownRef = useRef(null)
  const selectRef = useRef(null)
  const hasItemsSelected = activeItems.length !== 0

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        (containerRef &&
          containerRef.current &&
          containerRef.current.contains(e.target)) ||
        (usePortal &&
          selectRef &&
          selectRef.current &&
          selectRef.current.contains(e.target))
      ) {
        return
      }
      setIsOpen(false)
    },
    [containerRef, selectRef, usePortal]
  )

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()

      if (
        dropdownRef &&
        dropdownRef.current &&
        dropdownRef.current.contains(e.target)
      ) {
        setIsOpen(!isOpen)
      }

      if (usePortal) {
        const coors = dropdownRef.current.getBoundingClientRect()
        setCoordinates({
          left: `${coors.x}px`,
          top: `${coors.y + coors.height + 1}px`,
        })
      }
    },
    [isOpen, usePortal]
  )

  const handleClickCloseIcon = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation() // don't close the multiSelect
    onClickCloseIcon()
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside, false)
    return () => {
      document.removeEventListener('mousedown', clickOutside, false)
    }
  }, [clickOutside])

  return (
    <Container ref={containerRef} {...rest}>
      <div ref={dropdownRef}>
        <Chip
          key={categoryName}
          variant='filter'
          selected={hasItemsSelected}
          onClick={handleClick}
          padding='0.25rem 0.5rem 0.25rem 1rem'
        >
          {categoryName}:&nbsp;
          <CurrentSelectionText
            variant='action3'
            hasOptionsSelected={hasItemsSelected}
            paddingRight='0.5rem'
          >
            {!hasItemsSelected ? defaultString : activeItems[0].text}
            {activeItems.length >= 2 ? ` +${activeItems.length - 1}` : ``}
          </CurrentSelectionText>
          {hasItemsSelected ? (
            <DropdownClose onClick={handleClickCloseIcon} />
          ) : (
            <DropdownCaret open={isOpen} />
          )}
        </Chip>
      </div>
      <ConditionalWrapper
        condition={usePortal}
        wrapper={(children) => (
          <Portal>
            <div ref={selectRef}>{children}</div>
          </Portal>
        )}
      >
        <MultiSelect
          items={items}
          activeItems={activeItems}
          open={isOpen}
          onClick={handleClick}
          setIsOpen={setIsOpen}
          itemCallback={itemCallback}
          coords={usePortal ? dropdownCoors : null}
          minWidth={pxToRem(200)}
        />
      </ConditionalWrapper>
    </Container>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MultiDropdown.displayName = 'Multi Dropdown'
}

export default MultiDropdown
