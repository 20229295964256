import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import Text from 'core-system/Text'
import Button from 'core-system/Button'
import { useDispatch } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import EmployeeTripsCalendar from './components/EmployeeTripsCalendar'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import { useNavigate } from 'react-router-dom'
import { Locations } from 'shared/Router/Locations'
import DayTripsOverview from './components/DayTripsOverview'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const MonthSelector = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`

const MonthSelectChevron = styled.div<{
  isLeft?: boolean
  isDisabled?: boolean
}>`
  transform: rotate(${(props) => (props.isLeft ? '90deg' : '270deg')});
  color: ${(props) =>
    props.isDisabled
      ? props.theme.palette.text.disabled
      : props.theme.palette.text.primary};
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple5};
  }
`

const TripsOverviewView = React.memo(() => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const today = moment()

  const [selectedMonth, setSelectedMonth] = useState(
    moment().format('MMM YYYY')
  )
  const [selectedDay, setSelectedDay] = useState<number | null>(null)

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const changeMonth = (type: string) => {
    const currentMonth = moment(selectedMonth)
    if (type === 'increment') {
      setSelectedMonth(currentMonth.add(1, 'month').format('MMM YYYY'))
    } else {
      setSelectedMonth(currentMonth.subtract(1, 'month').format('MMM YYYY'))
    }
    setSelectedDay(null)

    SegmentService.track('trips-month-toggle', {
      action: type,
    })
  }

  // Fetch the current month trips when the user switches the month
  useEffect(() => {
    if (selectedMonth) {
      dispatch(employeePlatformActions.getCurrentMonthTrips(selectedMonth))
    }
  }, [dispatch, selectedMonth])

  return (
    <>
      <Header>
        <MonthSelector>
          <MonthSelectChevron isLeft onClick={() => changeMonth('decrement')}>
            <ChevronIcon />
          </MonthSelectChevron>
          <Text variant='h3' minWidth='7rem'>
            {selectedMonth}
          </Text>
          <MonthSelectChevron
            isDisabled={moment(selectedMonth).add(1, 'month').isAfter(today)}
            onClick={() => changeMonth('increment')}
          >
            <ChevronIcon />
          </MonthSelectChevron>
        </MonthSelector>
        <Button
          onClick={() => navigate(Locations.EmployeeTrips.AddTrip)}
          size={isMobile ? 'small' : 'medium'}
        >
          Add Trip
        </Button>
      </Header>
      <EmployeeTripsCalendar
        isMobile={isMobile}
        month={selectedMonth}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
      />
      <DayTripsOverview
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        selectedMonth={selectedMonth}
        isMobile={isMobile}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TripsOverviewView.displayName = 'TripsOverviewView'
}

export default TripsOverviewView
