import turfBbox from '@turf/bbox'
import { mapIntelligenceActions } from 'redux/mapIntelligence/mapIntelligenceSlice'
import Loading from 'core-system/Loading'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { MapLayers } from 'redux/mapIntelligence/mapIntelligenceTypes'
import styled from 'styled-components'
import MapIntelligence from './MapIntelligence'
import MapIntelligenceControlPanel from './MapIntelligenceControlPanel'
import MapIntelligenceKeyMetrics from './MapIntelligenceKeyMetrics'
import { metricLayerNames } from './MapIntelligenceStyles'

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100vh - 5rem);
`

const MapIntelligenceView = React.memo(() => {
  const dispatch = useDispatch()

  const { employeesGeojson, worksiteGeojson, agonyGeojson, statistics } =
    useSelector((state: AppState) => state.mapIntelligence)

  const { allEmployeeSegmentId } = useSelector(
    (state: AppState) => state.employer
  )

  const [activeLayer, setActiveLayer] = useState('currentCommuteModes')

  useEffect(() => {
    dispatch(
      mapIntelligenceActions.getBaseCommuters({
        segmentId: allEmployeeSegmentId,
      })
    )
    dispatch(
      mapIntelligenceActions.getAgonyData({
        segmentId: allEmployeeSegmentId,
      })
    )
  }, [dispatch, allEmployeeSegmentId])

  const updateActiveLayer = useCallback(
    (layer: string) => {
      if (activeLayer !== layer) {
        SegmentService.track('mapintel-layer-select', {
          layerName: metricLayerNames[layer],
        })
        setActiveLayer(layer)
      }
    },
    [activeLayer]
  )

  const baseBbox = useMemo(
    () => (employeesGeojson ? turfBbox(employeesGeojson) : null),
    [employeesGeojson]
  )

  if (!employeesGeojson || !baseBbox || !agonyGeojson) {
    return <Loading fullPage={true} />
  }

  const mapLayers: MapLayers = {
    employees: employeesGeojson,
    agony: agonyGeojson,
    worksites: worksiteGeojson,
  }

  return (
    <Container>
      <MapIntelligenceControlPanel
        mapLayers={mapLayers}
        updateActiveLayer={updateActiveLayer}
        activeLayer={activeLayer}
      />
      <MapIntelligence
        mapLayers={mapLayers}
        activeLayer={activeLayer}
        statistics={statistics}
      />
      <MapIntelligenceKeyMetrics
        statistics={statistics}
        activeLayer={activeLayer}
      />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MapIntelligenceView.displayName = 'MapIntelligenceView'
}

export default MapIntelligenceView
