import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='40' height='40' rx='8' fill='#BCDFFF' fillOpacity='0.6' />
    <path
      d='M16.139 11.6361C15.4526 12.3225 16.568 12.0939 17.2544 13.4668C17.9408 14.8396 20.0001 14.1532 19.3137 12.7803C19.1689 12.49 18.5971 12.028 18.1255 11.6793C18.1255 11.6793 18.5277 11.2078 18.3129 10.7074C18.098 10.207 16.139 11.6361 16.139 11.6361Z'
      fill='#F7DECE'
    />
    <path
      d='M15.8666 8.86467C14.8095 8.89213 13.9748 9.77075 14.0016 10.8278C14.0291 11.8849 14.9077 12.7196 15.9648 12.6928C17.0219 12.6654 17.8565 11.7868 17.8298 10.7297C17.803 9.6719 16.9237 8.83722 15.8666 8.86467Z'
      fill='#F7DECE'
    />
    <path
      d='M21.34 9.93403C20.7531 10.0967 20.042 10.3198 18.9643 9.53385C18.1948 8.97236 17.4768 8.78565 15.9653 9.11651C14.3605 9.46796 13.2327 10.65 14.3728 10.976C14.8705 11.1181 15.2288 10.928 15.9798 10.8497C17.0815 10.7351 17.529 11.6659 18.5978 11.6652C20.6886 11.6624 22.2159 9.69104 21.34 9.93403Z'
      fill='#292F33'
    />
    <path
      d='M20.6862 18.2717C20.6862 18.2717 18.627 19.6446 18.627 20.331C18.627 21.0174 21.3726 23.0767 22.0591 23.7631C22.7455 24.4495 24.1183 24.4495 23.4319 23.0767C22.7455 21.7038 20.6862 18.2717 20.6862 18.2717Z'
      fill='#EEC2AD'
    />
    <path
      d='M21.8088 23.091C21.8088 23.091 22.614 23.6326 23.1556 22.8267C23.6972 22.0209 24.0926 24.0424 24.0926 24.0424C24.0926 24.0424 22.336 25.7852 20.9892 26.0495C19.6425 26.3137 20.1841 25.5079 20.725 24.7027L21.8088 23.091Z'
      fill='#A0041E'
    />
    <path
      d='M12.4495 22.3899C9.79577 22.3899 7.64453 24.5411 7.64453 27.1948C7.64453 29.8485 9.79577 31.9998 12.4495 31.9998C15.1032 31.9998 17.2544 29.8485 17.2544 27.1948C17.2544 24.5411 15.1039 22.3899 12.4495 22.3899ZM12.4495 30.6269C10.5543 30.6269 9.01737 29.0907 9.01737 27.1948C9.01737 25.2989 10.5543 23.7627 12.4495 23.7627C14.3447 23.7627 15.8816 25.2989 15.8816 27.1948C15.8816 29.0907 14.3454 30.6269 12.4495 30.6269ZM27.5507 22.3899C24.8977 22.3899 22.7458 24.5411 22.7458 27.1948C22.7458 29.8485 24.8977 31.9998 27.5507 31.9998C30.2051 31.9998 32.3557 29.8485 32.3557 27.1948C32.3557 24.5411 30.2051 22.3899 27.5507 22.3899ZM27.5507 30.6269C25.6555 30.6269 24.1186 29.0907 24.1186 27.1948C24.1186 25.2989 25.6555 23.7627 27.5507 23.7627C29.4466 23.7627 30.9828 25.2989 30.9828 27.1948C30.9828 29.0907 29.4466 30.6269 27.5507 30.6269Z'
      fill='#292F33'
    />
    <path
      d='M22.7457 21.017C22.7457 20.6374 22.4135 20.3306 22.0023 20.3306H14.5657C14.1552 20.3306 13.8223 20.6374 13.8223 21.017C13.8223 21.3966 14.1552 21.7034 14.5657 21.7034H22.0017C22.4135 21.7034 22.7457 21.3966 22.7457 21.017ZM28.2371 27.1948C28.2371 26.8152 27.9303 26.5084 27.5507 26.5084H20.0001C19.6211 26.5084 19.3136 26.8152 19.3136 27.1948C19.3136 27.5744 19.6211 27.8812 20.0001 27.8812H27.5507C27.9303 27.8812 28.2371 27.5744 28.2371 27.1948Z'
      fill='#AAB8C2'
    />
    <path
      d='M22.213 18.9578L19.3534 26.9642C19.2264 27.3204 19.4124 27.7137 19.7694 27.8407C19.8456 27.8682 19.9231 27.8812 20 27.8812C20.2821 27.8812 20.5464 27.7062 20.6466 27.4255L23.671 18.9578H22.213Z'
      fill='#AAB8C2'
    />
    <path
      d='M27.5517 27.8814C27.3252 27.8814 27.1028 27.7689 26.9717 27.5636L22.1667 20.013C21.9628 19.6931 22.0576 19.2689 22.3774 19.065C22.698 18.8612 23.1215 18.9559 23.3254 19.2758L28.1303 26.8264C28.3342 27.1463 28.2395 27.5705 27.9196 27.7743C27.805 27.8464 27.678 27.8814 27.5517 27.8814ZM20.6868 24.9532L14.9298 20.4749C14.6312 20.2416 14.2001 20.2965 13.9667 20.5958C13.734 20.8957 13.7883 21.3261 14.0875 21.5588L20.2653 26.3637C20.3903 26.4619 20.5385 26.5086 20.6861 26.5086H20.6868V24.9532Z'
      fill='#AAB8C2'
    />
    <path
      d='M12.4493 27.8814C12.3779 27.8814 12.3044 27.8697 12.2324 27.8457C11.8727 27.7262 11.6784 27.3377 11.7985 26.9774L13.7013 21.2691L11.3517 19.5064C11.0483 19.2792 10.9872 18.8488 11.2144 18.5454C11.4416 18.2433 11.8713 18.1809 12.1754 18.4081L15.3171 20.7646L13.1014 27.4119C13.0046 27.6995 12.7362 27.8814 12.4493 27.8814Z'
      fill='#AAB8C2'
    />
    <path
      d='M25.7539 20.3305C25.7333 20.3305 25.7134 20.3292 25.6928 20.3264L21.7994 19.8404C21.5441 19.8089 21.3574 19.5837 21.3739 19.327C21.3896 19.0703 21.6024 18.8705 21.8592 18.8705C21.8873 18.8705 24.6508 18.8582 25.9701 17.9789C26.1417 17.8642 26.3696 17.8711 26.5357 17.9967C26.6998 18.1237 26.7671 18.3406 26.7012 18.5376L26.2145 19.9976C26.1486 20.1988 25.9612 20.3305 25.7539 20.3305ZM15.1947 19.6441H11.7626C11.3837 19.6441 11.0762 19.3366 11.0762 18.9577C11.0762 18.5788 11.3837 18.2713 11.7626 18.2713H15.1947C15.5736 18.2713 15.8811 18.5788 15.8811 18.9577C15.8811 19.3366 15.5736 19.6441 15.1947 19.6441Z'
      fill='#292F33'
    />
    <path
      d='M22.745 16.2122L20 18.2714L22.0593 20.3307L22.745 16.2122Z'
      fill='#A0041E'
    />
    <path
      d='M21.372 18.9579C21.372 18.9579 19.9992 21.0172 19.9992 21.7036C19.9992 22.39 20.6856 24.4493 20.6856 26.5085C20.6856 28.5678 22.0584 28.5678 22.0584 27.8814C22.0584 27.1949 23.1437 24.1617 22.0584 23.0764C21.372 22.39 23.4313 20.3307 23.4313 20.3307L21.372 18.9579ZM18.4108 16.4161L16.5231 15.8779C16.2163 17.2206 14.4481 18.9572 13.8221 18.9572C13.1356 18.9572 12.4492 18.9572 12.4492 19.6436C12.4492 20.3301 12.4492 20.3301 13.8221 20.3301C15.1949 20.3301 16.5677 18.9572 17.2542 18.2708C17.7003 17.8246 18.1527 17.0648 18.4108 16.4161Z'
      fill='#F7DECE'
    />
    <path
      d='M22.7448 16.2123L20.6855 18.958L23.4312 21.0173C23.4312 21.0173 26.8633 17.5852 26.8633 16.2123C26.8633 14.8395 24.8041 14.1531 24.8041 14.1531L22.7448 16.2123Z'
      fill='#BE1931'
    />
    <path
      d='M22.0585 12.093C19.9993 11.4066 18.2249 11.8089 17.2536 12.7795C16.5672 13.4659 16.5672 14.1523 16.5672 15.5251C16.5672 15.646 16.5466 15.7736 16.5171 15.9027L18.4006 16.4408C18.5392 16.0914 18.6271 15.7654 18.6271 15.5251C18.6271 14.8387 19.3135 14.8387 19.9999 15.5251C20.6864 16.2116 21.3728 16.898 22.0592 16.898C22.7456 16.898 26.1777 14.1523 26.1777 14.1523C24.1178 13.4659 23.3614 12.5269 22.0585 12.093ZM20.6864 27.195C20.6864 27.195 21.3728 27.8814 22.0592 27.195C22.7456 26.5086 22.7456 28.5678 22.7456 28.5678C22.7456 28.5678 20.6864 29.9407 19.3135 29.9407C17.9407 29.9407 18.6271 29.2542 19.3135 28.5678L20.6864 27.195ZM15.195 8.66162C16.5678 7.9752 19.3135 8.66162 17.9407 10.0345C17.2543 10.7209 13.1357 11.4073 13.1357 11.4073C13.1357 10.7209 13.8222 9.34804 15.195 8.66162Z'
      fill='#DD2E44'
    />
  </>,
  'Bike',
  '0 0 40 40'
)
