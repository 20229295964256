import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Button from 'core-system/Button'
import pxToRem from 'core-system/utils/pxToRem'
import Text from 'core-system/Text'
import Divider from 'core-system/Divider'
import CreditCardIcon from 'core-system/Icons/Accounts/CreditCard'
import BankAccountIcon from 'core-system/Icons/Misc/BankAccount'
import Radio from 'core-system/Radio'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import palette from 'core-system/Themes/palette'
import { StripeCardNumberElementOptions } from '@stripe/stripe-js'
import employeePlatformService from 'redux/employeePlatform/employeePlatformService'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import { useDispatch } from 'react-redux'
import { SpringSpinner } from 'react-epic-spinners'
import Bugsnag from '@bugsnag/js'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import { PaymentMethodCopy } from '../ProfileUtils'
import BankMandateModal from './BankMandateModal'

const PaymentMethodInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const PaymentMethodTypes = styled.div`
  display: flex;
  flex-direction: column;
`

const PaymentMethodTypeContainer = styled.div<{
  isSelected: boolean
  disabled?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.palette.secondary.purple5
      : props.theme.palette.transparent};

  ${(props) =>
    !props.disabled
      ? css`
          &:hover {
            cursor: pointer;
            background-color: ${(props) =>
              props.theme.palette.secondary.purple5};
          }
        `
      : ''}
`

const PaymentMethodTypeText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const PaymentMethodTypeHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

const FooterButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const CardDetailsInputContainer = styled.div<{ focused: boolean }>`
  background-color: ${(props) =>
    props.focused
      ? props.theme.palette.white
      : props.theme.palette.secondary.purple7};
  padding: ${(props) => props.theme.pxToRem(10)} 1rem;
  border: 0.0625rem solid
    ${(props) =>
      props.focused
        ? props.theme.palette.text.secondary
        : props.theme.palette.grey.grey3};
  border-radius: 0.5rem;
  min-width: 2.5rem;
  height: ${(props) => props.theme.pxToRem(41)};
  width: 100%;
  padding: ${(props) => props.theme.pxToRem(10)} 1rem;
  :hover {
    color: ${(props) => props.theme.palette.text.secondary};
    border-color: ${(props) => props.theme.palette.text.secondary};
  }
`

const cardElementOptions = {
  iconStyle: 'solid' as StripeCardNumberElementOptions['iconStyle'],
  hidePostalCode: false,
  style: {
    base: {
      color: palette.text.primary,
      '::placeholder': {
        color: palette.text.placeholder,
      },
    },
    invalid: {
      iconColor: palette.secondary.red1,
      color: palette.secondary.red1,
    },
    complete: {
      iconColor: palette.secondary.green2,
    },
  },
}

const renderPaymentMethodType = (
  type: 'card' | 'bankAccount',
  selectedPaymentMethodType: 'card' | 'bankAccount' | null,
  setSelectedPaymentMethodType: (type: 'card' | 'bankAccount' | null) => void,
  disabled?: boolean
) => {
  return (
    <PaymentMethodTypeContainer
      isSelected={selectedPaymentMethodType === type}
      disabled={disabled}
      onClick={() => (disabled ? null : setSelectedPaymentMethodType(type))}
    >
      <PaymentMethodTypeText>
        <PaymentMethodTypeHeader>
          {type === 'card' ? (
            <CreditCardIcon />
          ) : (
            <BankAccountIcon
              color={disabled ? palette.text.disabled : palette.text.primary}
            />
          )}
          <Text
            variant='action3'
            textColor={disabled ? palette.text.disabled : palette.text.primary}
          >
            {type === 'card' ? 'Credit / Debit Card' : 'Bank Account'}
          </Text>
        </PaymentMethodTypeHeader>
        <Text
          variant='body2'
          textColor={disabled ? palette.text.disabled : palette.text.primary}
        >
          {PaymentMethodCopy[type]}
        </Text>
      </PaymentMethodTypeText>
      <Radio
        active={selectedPaymentMethodType === type}
        disabled={disabled}
        onClick={() => (disabled ? null : setSelectedPaymentMethodType(type))}
      />
    </PaymentMethodTypeContainer>
  )
}

interface AddPaymentMethodModalProps {
  open: boolean
  closeModal: () => void
  isMobile: boolean
}

const AddPaymentMethodModal = React.memo(
  (props: AddPaymentMethodModalProps) => {
    const { open, closeModal } = props

    const { profileData, wallet } = useSelector(
      (state: AppState) => state.employeePlatform
    )

    const stripe = useStripe()
    const elements = useElements()

    const dispatch = useDispatch()

    const [currentStep, setCurrentStep] = useState<'typeSelect' | 'cardInput'>(
      'typeSelect'
    )
    const [selectedPaymentMethodType, setSelectedPaymentMethodType] = useState<
      'card' | 'bankAccount' | null
    >(null)
    const [cardElementFocus, setCardElementFocus] = useState(false)
    const [cardError, setCardError] = useState({
      errorMsg: null,
      complete: null,
      empty: null,
    })
    const [cardSetupError, setCardSetupError] = useState(null)
    const [bankAccountInfo, setBankAccountInfo] = useState(null)
    const [showBankMandateModal, setShowBankMandateModal] = useState(false)
    const [loading, setLoading] = useState(false)

    // Get the stripe client secret
    const [stripeClientSecret, setStripeClientSecret] = useState(null)
    useEffect(() => {
      if (!stripeClientSecret && open) {
        employeePlatformService.getStripeClientSecret().then((res) => {
          setStripeClientSecret(res.data)
        })
      }
    }, [stripeClientSecret, open])

    // Open the Stripe Connect modal
    const handleConnect = async () => {
      const { setupIntent, error } = await stripe.collectBankAccountForSetup({
        clientSecret: stripeClientSecret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {
              name: `${profileData?.firstName} ${profileData?.lastName}`,
              email: profileData?.email,
            },
          },
        },
        expand: ['payment_method'],
      })
      if (error) {
        console.log('stripe bank account setup error:', error.message)
        Bugsnag.notify(error as any)
        dispatch(
          notificationsActions.generalPageError('Unable to setup bank account')
        )
      }
      if (setupIntent.status === 'requires_confirmation') {
        const accountInfo = {
          clientSecret: stripeClientSecret,
          account: setupIntent.payment_method['us_bank_account'],
        }
        setBankAccountInfo(accountInfo)
        setShowBankMandateModal(true)
      }
    }

    const handleCloseModal = (openStripeBankAccountSetup?: boolean) => {
      closeModal()
      setCurrentStep('typeSelect')
      setSelectedPaymentMethodType(null)
      setCardElementFocus(false)
      cardError &&
        setCardError({
          errorMsg: null,
          complete: null,
          empty: null,
        })
      setCardSetupError(null)
      setStripeClientSecret(null)
      setLoading(false)
      if (openStripeBankAccountSetup) {
        handleConnect()
      }
    }

    const handleCardDetailsChange = (e) => {
      setCardError({
        errorMsg: e.error ? e.error.message : null,
        complete: e.complete,
        empty: e.empty,
      })
    }

    const handleNextSubmitClick = async () => {
      if (currentStep === 'typeSelect') {
        selectedPaymentMethodType === 'card'
          ? setCurrentStep('cardInput')
          : handleCloseModal(true)
      } else {
        setLoading(true)
        try {
          const result = await stripe.confirmCardSetup(stripeClientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                name: `${profileData?.firstName} ${profileData?.lastName}`,
                email: profileData?.email,
              },
            },
          })
          if (result.error) {
            setCardSetupError(result.error.message)
            return
          }
          setTimeout(() => {
            dispatch(employeePlatformActions.getAllPaymentMethods())
            handleCloseModal()
          }, 5000)
        } catch (e) {
          setCardSetupError('Oops, something went wrong, please try again.')
        }
      }
    }

    const disableNextSubmitButton =
      currentStep === 'typeSelect'
        ? !selectedPaymentMethodType
        : !cardError?.complete

    const hasBankAccount = wallet?.paymentMethods?.some(
      (paymentMethod) => paymentMethod.type === 'us_bank_account'
    )

    if (showBankMandateModal) {
      return (
        <BankMandateModal
          open={showBankMandateModal}
          closeModal={() => setShowBankMandateModal(false)}
          bankAccountInfo={bankAccountInfo}
        />
      )
    }

    return (
      <Modal
        open={open}
        onClose={() => handleCloseModal()}
        width={pxToRem(500)}
      >
        <ModalHeader title='Add Payment Method' onClose={() => closeModal()} />
        <ModalBody>
          {currentStep === 'typeSelect' ? (
            <PaymentMethodTypes>
              {renderPaymentMethodType(
                'card',
                selectedPaymentMethodType,
                setSelectedPaymentMethodType
              )}
              <Divider direction='horizontal' margin='1rem 0' />
              {renderPaymentMethodType(
                'bankAccount',
                selectedPaymentMethodType,
                setSelectedPaymentMethodType,
                hasBankAccount
              )}
            </PaymentMethodTypes>
          ) : null}
          {currentStep === 'cardInput' ? (
            <PaymentMethodInputContainer>
              <CardDetailsInputContainer
                focused={cardElementFocus}
                data-cy='add-payment-method-card-details-input-container'
              >
                <CardElement
                  options={cardElementOptions}
                  onChange={handleCardDetailsChange}
                  onFocus={() => setCardElementFocus(true)}
                  onBlur={() => setCardElementFocus(false)}
                />
              </CardDetailsInputContainer>
              {cardError.errorMsg ? (
                <Text variant='body2' textColor={palette.secondary.red1}>
                  {cardError.errorMsg}
                </Text>
              ) : null}
              <Text variant='body2' textColor={palette.text.secondary}>
                {PaymentMethodCopy.cardDisclaimer}
              </Text>
              {cardSetupError ? (
                <Text variant='body1' textColor={palette.secondary.red1}>
                  {cardSetupError}
                </Text>
              ) : null}
            </PaymentMethodInputContainer>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <FooterButtonsContainer>
            <Button
              variant='tertiary'
              disabled={currentStep === 'typeSelect'}
              onClick={() => setCurrentStep('typeSelect')}
            >
              Back
            </Button>
            <Button
              onClick={() => handleNextSubmitClick()}
              disabled={!profileData || disableNextSubmitButton || loading}
            >
              {loading ? (
                <SpringSpinner
                  color={palette.primary.primaryPurple}
                  size={25}
                />
              ) : currentStep === 'typeSelect' ? (
                'Next'
              ) : (
                'Add'
              )}
            </Button>
          </FooterButtonsContainer>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AddPaymentMethodModal.displayName = 'AddPaymentMethodModal'
}

export default AddPaymentMethodModal
