import React from 'react'
import styled, { css } from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  grid,
  GridProps,
} from 'styled-system'
import { buildForwardingComponent } from '../utils/buildComponent'

const Container = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps &
    GridProps & {
      isLoading: boolean
      isClickable: boolean
      autoResize: boolean
    }
>`
  display: flex;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: ${(props) => props.theme.pxToRem(100)};
  padding: 0.5rem;
  align-items: center;
  width: 100%;
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  position: relative;
  min-height: ${(props) =>
    props.isClickable ? props.theme.pxToRem(50) : 'unset'};

  ${(props) =>
    props.autoResize &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-auto-flow: row;
        gap: 1rem;
      }
    `}

  &:hover {
    background-color: ${(props) => props.theme.palette.white};
  }

  ${(props) =>
    props.isClickable && {
      '&:hover': {
        backgroundColor: props.theme.palette.secondary.purple6,
      },
    }}

  ${grid}
  ${flexbox}
  ${space}
  ${layout}
`

export interface TableRowContentsProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    GridProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  isLoading?: boolean
  isClickable?: boolean
  autoResize?: boolean
}

const TableRowContents = React.forwardRef<
  HTMLDivElement,
  TableRowContentsProps
>((props: TableRowContentsProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    children,
    isLoading = false,
    isClickable = false,
    autoResize = true,
  } = props

  return (
    <Container
      ref={ref}
      isClickable={isClickable}
      isLoading={isLoading}
      autoResize={autoResize}
      {...props}
    >
      {children}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TableRowContents.displayName = 'TableRowContents'
}

export default buildForwardingComponent(TableRowContents)
