import React from 'react'

const TermsAndConditionsAccept = React.memo(() => {
    return (
        <div>
            <iframe
                src="https://www.movewithfleet.com/mobility-card-terms"
                width='100%'
                height='600rem'
                style={{
                    border: 'none',
                    borderRadius: '0.5rem',
                }}
            />
        </div>
    );    
});

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
    TermsAndConditionsAccept.displayName = 'TermsAndConditionsAccept';
};

export default TermsAndConditionsAccept;