/**
 * Allows a number to be formatted to set number of decimal places.
 * @param num
 * @param decimal
 * @param ignoreBaseRule
 */
function formatNumber(
  num: number | string,
  decimal?: number,
  ignoreBaseRule = false
): string {
  const formattedNum = typeof num === 'string' ? parseFloat(num) : num

  if (!formattedNum) {
    return '0'
  } else if ((formattedNum === 0 || formattedNum === 100) && !ignoreBaseRule) {
    return formattedNum.toFixed(0)
  } else if (typeof decimal === 'number') {
    return formattedNum
      .toFixed(decimal)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  return formattedNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export default {
  formatNumber,
}
