import moment, { Moment, unitOfTime } from 'moment'
import RandomUtils from './RandomUtils'

/**
 * @param startDate The start date
 * @param endDate The end date
 * @param type The range type. eg: 'days', 'hours' etc
 *
 *ex:
 * startDate = moment('01/07/20')
 * endDate = moment('01/12/20')
 * type = 'month'
 * output = ['01/07/20', '01/08/20', '01/09/20', '01/10/20', '01/11/20', '01/12/20']
 */
function getRange(startDate: Moment, endDate: Moment, type: unitOfTime.Diff) {
  const fromDate = moment(startDate)
  const toDate = moment(endDate)
  const diff = toDate.diff(fromDate, type)
  const range = []
  for (let i = 0; i <= diff; i++) {
    range.push(moment(startDate).add(i, type))
  }
  return range
}

/**
 * @param max number of months from present to stop at
 * @param startAt number of months ahead of present to start from
 *
 *ex:
 * max = 4
 * startAt = 1
 * output = [Month, Month, Month, Month]
 */
export type Month = {
  id: string
  text: string
  iso?: string
}

const getNextXMonths = (max: number, startAt: number) => {
  const months: Month[] = []
  for (let i = startAt; i <= max; i++) {
    const d = moment().add(i, 'M').startOf('month')
    months.push({
      id: i.toString(),
      text: d.format('MMMM Do, YYYY'),
      iso: d.format(),
    })
  }
  return months
}

const getLastWeek = () => {
  return moment().subtract(7, 'days').utc().format('YYYY-MM-DD HH:mm:ss')
}

const formatTime = (time: string) => {
  const times = time.split(':').map((time) => parseInt(time))
  const hours =
    times[0] > 0 ? `${times[0]} hr${RandomUtils.pluralCheck(times[0])} ` : ''
  const mins =
    times[1] > 0 ? `${times[1]} min${RandomUtils.pluralCheck(times[1])} ` : ''
  return hours + mins
}

export interface DropdownDate {
  val: string
  text: string
}

const nextMonthDate = (): DropdownDate => {
  return {
    val: 'next-month',
    text: getNextXMonths(1, 1)[0].text,
  }
}

const getDayOfTheMonth = (day: number) => {
  return moment(`2022-12-${day}`, 'YYYY-MM-DD').format('Do')
}

export default {
  getRange,
  getNextXMonths,
  getLastWeek,
  formatTime,
  nextMonthDate,
  getDayOfTheMonth,
}
