import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 1C9.3 1 10.41 1.83 10.83 3H19V5H18V7H16V5H10.83C10.41 6.16 9.3 7 8 7C6.34 7 5 5.65 5 4C5 2.34 6.34 1 8 1ZM7 4C7 4.55 7.45 5 8 5C8.55 5 9 4.55 9 4C9 3.45 8.55 3 8 3C7.45 3 7 3.45 7 4Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.61 9H16.39C16.82 9 17.2 9.28 17.34 9.69L19 14.69V21.5C19 21.78 18.78 22 18.5 22H17.5C17.22 22 17 21.78 17 21.5V20H7V21.5C7 21.78 6.78 22 6.5 22H5.5C5.23 22 5 21.78 5 21.49V14.68L6.66 9.68C6.8 9.28 7.18 9 7.61 9ZM6.56 16.78C6.56 17.46 7.1 18 7.78 18C8.46 18 9 17.45 9 16.78C9 16.11 8.46 15.56 7.78 15.56C7.1 15.56 6.56 16.1 6.56 16.78ZM15 16.78C15 17.46 15.55 18 16.22 18C16.9 18 17.44 17.46 17.44 16.78C17.44 16.1 16.9 15.56 16.22 15.56C15.54 15.56 15 16.1 15 16.78ZM7.62 10L6.29 14H17.73L16.4 10H7.62Z'
    />
  </>,
  'Car Rental'
)
