export const signUpCopy = {
  employee:
    'Please provide the following information to activate your Fleet account.',
  employer:
    'Ready to unlock best-in-class commuter benefits? Sign up in minutes.',
  employerEmailHelpText:
    'This must be your work email. Generic emails (e.g. gmail.com, hotmail.com, etc.) are not allowed.',
  employerPasswordHelpText:
    'Passwords must be at least 10 characters long and contain at least one special character and one number.',
  employerSuccess: 'Success! Please check your email for next steps.',
  employerFail:
    'Error. Please ensure that all fields are filled out correctly.',
}

export const passwordSpecialCharCheck = (password: string) => {
  const specialCharRegex = /[!@#$%^&*]/
  return specialCharRegex.test(password)
}

export const passwordNumberCheck = (password: string) => {
  const numberRegex = /\d/
  return numberRegex.test(password)
}

export const passwordNoUnderscoreCheck = (password: string) => {
  const underscoreRegex = /[_]/
  return !underscoreRegex.test(password)
}

export const invalidEmailProviders = new Set([
  'gmail',
  'hotmail',
  'live',
  'msn',
  'outlook',
  'yahoo',
  'aol',
  'icloud',
  'me',
  'mac',
  'protonmail',
  'zoho',
  'aim',
  'mail',
  'microsoft',
  'github',
])
