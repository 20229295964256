import React, { useState, useCallback } from 'react'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import Divider from 'core-system/Divider'
import IntegrateStripeBankAccount from 'features/Accounts/Payments/components/IntegrateStripeBankAccount'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import SegmentService from 'redux/config/services/SegmentService'
import AddCreditCardModal from 'features/Accounts/Payments/components/AddCreditCardModal'
import PaymentMethod from 'features/Accounts/Payments/components/PaymentMethod'
import { useDispatch } from 'react-redux'
import { employerActions } from 'redux/employer/employerSlice'
import styled from 'styled-components'
import HeaderTextButton from 'core-system/ButtonV2/HeaderTextButton'
import Chevron from 'core-system/Icons/Actions/Chevron'
import { extraOnboardingCopy } from './OnboardingUtils'

const AddPaymentMethodButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`
interface OnboardingAddPaymentProps {
  handleNextStep: () => void
}
const OnboardingAddPayment = React.memo((props: OnboardingAddPaymentProps) => {
  const dispatch = useDispatch()
  const { handleNextStep } = props

  const { email, firstName, lastName } = useSelector(
    (state: AppState) => state.user.profile
  )
  const { platformFeeDetails } = useSelector(
    (state: AppState) => state.employer.profile
  )

  const [addCreditCardModalOpen, setAddCreditCardModalOpen] = useState(false)

  const openAddCreditCardModal = useCallback(() => {
    setAddCreditCardModalOpen(true)
    SegmentService.track('paymentMethod-btn-click', {
      action: 'add',
      type: 'card',
      location: 'employer-onboarding',
    })
  }, [setAddCreditCardModalOpen])

  const handleDelete = useCallback(
    (id: string, type: string) => {
      dispatch(employerActions.removePaymentMethod(id))
      setAddCreditCardModalOpen(false)
      SegmentService.track('paymentMethod-btn-click', {
        action: 'delete',
        type,
      })
    },
    [dispatch]
  )

  const paymentMethodDetails = platformFeeDetails[0]

  return (
    <>
      {platformFeeDetails?.length === 0 ? (
        <FlexContainer flexDirection='column'>
          <AddPaymentMethodButtonsContainer>
            <IntegrateStripeBankAccount
              billingEmail={email}
              billingName={`${firstName} ${lastName}`}
              CustomButton={HeaderTextButton}
              customButtonProps={{
                header: extraOnboardingCopy.addPayment.bankAccount.header,
                text: extraOnboardingCopy.addPayment.bankAccount.text,
                width: '100%',
              }}
              moveToNextStep={handleNextStep}
            />
            <HeaderTextButton
              header={extraOnboardingCopy.addPayment.creditCard.header}
              text={extraOnboardingCopy.addPayment.creditCard.text}
              onClick={openAddCreditCardModal}
              iconRight={<Chevron style={{ transform: 'rotate(-90deg)' }} />}
              data-cy='employer-onboarding-add-credit-card-btn'
            />
          </AddPaymentMethodButtonsContainer>
        </FlexContainer>
      ) : (
        <FlexContainer flexDirection='column'>
          <Divider marginBottom='2rem' />
          <Text variant='h5' marginBottom='1rem'>
            Your Added Payment Method
          </Text>
          <PaymentMethod
            id={paymentMethodDetails.internalId}
            type={paymentMethodDetails.type}
            bankName={paymentMethodDetails.bankName}
            brand={paymentMethodDetails.brand}
            last4={paymentMethodDetails.last4}
            expMonth={
              paymentMethodDetails.expMonth && paymentMethodDetails.expMonth
            }
            expYear={
              paymentMethodDetails.expYear && paymentMethodDetails.expYear
            }
            showActions={true}
            onDeleteAction={handleDelete}
          />
        </FlexContainer>
      )}
      <AddCreditCardModal
        open={addCreditCardModalOpen}
        moveToNextStep={handleNextStep}
        closeModal={() => setAddCreditCardModalOpen(false)}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OnboardingAddPayment.displayName = 'OnboardingAddPayment'
}

export default OnboardingAddPayment
