import React, { useEffect } from 'react'
import Loading from 'core-system/Loading'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AppState } from 'redux/config/store'
import { QtfProgram } from 'redux/qtf/qtfTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import QTFOverviewHeader from './components/QTFOverviewHeader'
import styled from 'styled-components'
import QTFReportingCard from './components/QTFReportingCard'
import { qtfActions } from 'redux/qtf/qtfSlice'
import QTFAllocations from './components/QTFAllocations'
import QTFBreakdownCard from './components/QTFBreakdownCard'
import { useLocation } from 'react-router-dom'
import QTFAllocationDetailsCard from './components/QTFAllocationDetailsCard'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1rem;
  margin-top: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
  }
`

const LargeBodyContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  /* height: ${(props) => props.theme.pxToRem(464)}; */

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`

const SmallBodyContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  height: fit-content;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`

interface QTFOverviewViewProps {
  activeProgram: QtfProgram
}

const QTFOverviewView = React.memo((props: QTFOverviewViewProps) => {
  const { activeProgram } = props
  const dispatch = useDispatch()
  const segmentId = useQueryParam('segmentId')
  const search = useLocation().search
  const reminderModalOpen = new URLSearchParams(search).get('modal_open')

  const {
    dashboardStats,
    serviceUsage,
    upcomingAllocations,
    isReportGenerating,
  } = useSelector((state: AppState) => state.qtf)

  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  useEffect(() => {
    dispatch(qtfActions.getQTFUpcomingAllocations({ segmentId }))
    dispatch(qtfActions.getQTFDashboardServices({ segmentId }))
    dispatch(qtfActions.getQTFDashboardStats({ segmentId }))
  }, [segmentId, dispatch])

  if (!activeProgram || !segmentsMap) {
    return <Loading fullPage />
  }
  const isParkingActive = activeProgram?.typeMap['COMMUTER_PARKING']
    ? true
    : false
  const isTransitActive = activeProgram?.typeMap['COMMUTER_TRANSIT']
    ? true
    : false
  const employerParkingContribution = activeProgram
    ? activeProgram.employerParkingContribution
    : 0
  const employerTransitContribution = activeProgram
    ? activeProgram.employerTransitContribution
    : 0

  return (
    <div>
      <QTFOverviewHeader stats={dashboardStats} />
      <GridContainer>
        <div>
          <LargeBodyContainer>
            <QTFReportingCard
              segmentId={activeProgram.segment}
              isReportGenerating={isReportGenerating}
            />
            <QTFAllocationDetailsCard
              transitActive={isTransitActive}
              parkingActive={isParkingActive}
              employerTransitContribution={employerTransitContribution}
              employerParkingContribution={employerParkingContribution}
            />
          </LargeBodyContainer>
          <QTFAllocations
            allocations={upcomingAllocations}
            totalEmployees={
              segmentsMap[segmentId].commuters -
              segmentsMap[segmentId].contractors.length
            }
            deadlineDay={activeProgram.contributionDeadlineDay}
            openReminderModal={reminderModalOpen ? true : false}
            segmentId={activeProgram.segment}
            segmentName={segmentsMap[segmentId].name}
          />
        </div>
        <SmallBodyContainer>
          <QTFBreakdownCard servicesUsageData={serviceUsage} />
          {/* map overview */}
        </SmallBodyContainer>
      </GridContainer>
    </div>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFOverviewView.displayName = 'QTFOverviewView'
}

export default QTFOverviewView
