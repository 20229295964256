import React from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import { CarpoolMapLegendColors, CarpoolMapLegendIcons } from '../CarpoolUtils'

const MapLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

const MapLegend = styled.div`
  display: grid;
  position: absolute;
  padding: 0.5rem;
  left: 0.5rem;
  top: 0.5rem;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
  }
`

const renderMapLegendItem = (
  label: string,
  color: string,
  type: 'source' | 'dest'
) => {
  const LegendIcon =
    type === 'source'
      ? CarpoolMapLegendIcons.source
      : CarpoolMapLegendIcons.dest
  return (
    <MapLegendItem>
      <LegendIcon height='1.25rem' width='1.25rem' color={color} />
      <Text variant='action3'>{label}</Text>
    </MapLegendItem>
  )
}

interface CarpoolMapLegendProps {
  isMyCarpool?: boolean
  isRequest?: boolean
  hasOtherUserData?: boolean
}

const CarpoolMapLegend = React.memo((props: CarpoolMapLegendProps) => {
  const { isMyCarpool, isRequest, hasOtherUserData } = props

  return (
    <MapLegend>
      {renderMapLegendItem(
        'Your Home',
        CarpoolMapLegendColors.mySource,
        'source'
      )}
      {renderMapLegendItem('Your Work', CarpoolMapLegendColors.myDest, 'dest')}
      {hasOtherUserData
        ? renderMapLegendItem(
            `${
              isRequest ? "Requestor's" : isMyCarpool ? "Member's" : "Owner's"
            } Home`,
            CarpoolMapLegendColors.otherSource,
            'source'
          )
        : null}
      {hasOtherUserData
        ? renderMapLegendItem(
            `${
              isRequest ? "Requestor's" : isMyCarpool ? "Member's" : "Owner's"
            } Work`,
            CarpoolMapLegendColors.otherDest,
            'dest'
          )
        : null}
    </MapLegend>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolMapLegend.displayName = 'CarpoolMapLegend'
}

export default CarpoolMapLegend
