import Button from 'core-system/Button'
import Dropdown from 'core-system/Dropdown'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import SegmentDropdownUtils from 'core-system/SegmentDropdown/SegmentDropdownUtils'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppState } from 'redux/config/store'

interface IncentivesPreviewModalProps {
  open: boolean
  closeModal: () => void
  segmentId?: string
}

const IncentivesPreviewModal = React.memo(
  (props: IncentivesPreviewModalProps) => {
    const { open, closeModal, segmentId } = props

    const { allSegments } = useSelector((state: AppState) => state.employer)
    const { allIncentives } = useSelector((state: AppState) => state.incentives)

    const [currentSegment, setCurrentSegment] = useState(segmentId)
    const [hasError, setHasError] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    const handleNext = () => {
      if (!currentSegment) {
        setHasError(true)
      } else {
        navigate(`/trips/${currentSegment}/incentives/activation`, {
          state: {
            from: location.pathname,
            currentSegment,
          },
        })
        closeModal()
      }
    }

    const handleClose = () => {
      setCurrentSegment(null)
      closeModal()
    }

    return (
      <Modal open={open} onClose={handleClose} width={pxToRem(546)}>
        <ModalHeader title='New Incentives Program' />
        <ModalBody padding='1.5rem 3.25rem 3.25rem'>
          <Dropdown
            items={SegmentDropdownUtils.segmentDropdownItems(
              allSegments || [],
              allIncentives || []
            )}
            active={currentSegment}
            itemCallback={({ id }) => setCurrentSegment(id)}
            width='100%'
            label='Select a Group of Commuters:'
            placeholder={!currentSegment ? '--' : null}
            error={hasError && !currentSegment}
            errorMsg='Select a Valid Group'
            usePortal
          />
        </ModalBody>
        <ModalFooter>
          <Button variant='tertiary' onClick={closeModal}>
            Cancel
          </Button>
          <Button marginLeft='1rem' onClick={handleNext}>
            Next
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  IncentivesPreviewModal.displayName = 'IncentivesPreviewModal'
}

export default IncentivesPreviewModal
