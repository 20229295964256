import React from 'react'
import styled from 'styled-components'
import Modal, { ModalHeader, ModalFooter, ModalBody } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import Button from 'core-system/Button'
import { ButtonVariants } from 'core-system/Button/Button'
import Text from 'core-system/Text'

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

interface ProgramModalProps {
  title: string
  description: string
  buttonVariant?: ButtonVariants
  buttonText: string
  open: boolean
  closeModal: () => void
  buttonCallback: () => void
}

const ProgramModal = React.memo((props: ProgramModalProps) => {
  const {
    title,
    description,
    buttonVariant = 'primary',
    buttonText,
    open,
    closeModal,
    buttonCallback,
  } = props
  return (
    <Modal open={open} onClose={closeModal} width={pxToRem(550)}>
      <ModalHeader title={title} />
      <ModalBody>
        <Text variant='body1' margin='1rem 0'>
          {description}
        </Text>
      </ModalBody>
      <ModalFooter>
        <ButtonContainer>
          <Button variant='tertiary' marginRight='1rem' onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant={buttonVariant}
            onClick={buttonCallback}
            data-cy='program-modal-button'
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramModal.displayName = 'ProgramModal'
}

export default ProgramModal
