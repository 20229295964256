import React from 'react'
import styled from 'styled-components'
import { Feature } from 'geojson'
import PlatformSvg from 'core-system/Icons/PlatformSvg'

const PopupContainer = styled.div`
  display: flex;
  padding: 0.4rem;
  font-family: Hind;
  width: ${(props) => props.theme.pxToRem(235)};
  color: ${(props) => props.theme.palette.text.primary};
`

const PopupStat = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.435rem;
`

const PopupStatLight = styled.p`
  margin: 0;
  font-weight: 100;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.theme.palette.text.secondary};
`

const SVGContainer = styled.div`
  width: ${(props) => props.theme.pxToRem(60)};
`

const WorkplaceStats = styled.div`
  flex: 1;
`

interface PopupProps {
  feature: Feature
}

const PopupWorksites = React.memo((props: PopupProps) => {
  const { feature } = props

  const addressSplit = feature.properties.formattedAddress.split(',')
  const addressPostal = `${addressSplit[0]}, ${addressSplit[2].substring(3)}`
  const addressState = feature.properties.province
    ? feature.properties.province
    : feature.properties.state

  return (
    <PopupContainer>
      <SVGContainer>
        <PlatformSvg
          folder='offices'
          variant='office1'
          height={45}
          width={45}
        />
      </SVGContainer>
      <WorkplaceStats>
        <PopupStat>{addressSplit[0]}</PopupStat>
        <PopupStatLight>{addressState}</PopupStatLight>
        <PopupStatLight>{addressPostal}</PopupStatLight>
        <PopupStatLight>
          {feature.properties.numEmployees} Commuters
        </PopupStatLight>
      </WorkplaceStats>
    </PopupContainer>
  )
})

PopupWorksites.displayName = 'PopupWorksites'

export default PopupWorksites
