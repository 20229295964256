import { PositionType, InteractionMode } from 'chart.js'
import palette from 'core-system/Themes/palette'

const defaultChartOptions = {
  legend: {
    display: true,
    position: 'right' as PositionType,
    align: 'center' as const,
    labels: {
      usePointStyle: true,
      boxWidth: 10,
      fontColor: '#7E7EC2',
      fontFamily: 'Montserrat',
    },
    onClick: (e: MouseEvent) => e.stopPropagation(),
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        return data.labels[tooltipItem.index] as string
      },
      labelTextColor: () => 'fff',
    },
    titleSpacing: 3,
    backgroundColor: palette.primary.primaryPurple,
    displayColors: false,
    caretSize: 0,
    mode: 'single' as InteractionMode,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  mainAspectratio: false,
  cutoutPercentage: 55,
  animation: {
    animateRotate: false,
  },
}

const legendHiddenChartOptions = {
  ...defaultChartOptions,
  legend: {
    ...defaultChartOptions.legend,
    display: false,
  },
}

export default {
  defaultChartOptions,
  legendHiddenChartOptions,
}
