import Button from 'core-system/Button'
import CategoryBreakdown from 'core-system/CategoryBreakdown'
import Divider from 'core-system/Divider'
import Dropdown, { SelectItemProps } from 'core-system/Dropdown'
import FlexContainer from 'core-system/FlexContainer'
import AlertIcon from 'core-system/Icons/Misc/Alert'
import LocationIcon from 'core-system/Icons/Misc/Location'
import OverflowIcon from 'core-system/Icons/Misc/Overflow'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import FormattingUtils from 'shared/FormattingUtils'
import styled, { css } from 'styled-components'
import MyCommuterArchive from './MyCommuterDetails/MyCommuterArchive'
import MyCommuterDetailsOverview from './MyCommuterDetails/MyCommuterDetailsOverview'
import MyCommuterDetailsTransactions from './MyCommuterDetails/MyCommuterDetailsTransactions'
import MyCommuterDetailsTrips from './MyCommuterDetails/MyCommuterDetailsTrips'
import { employeeActions } from 'redux/employee/employeeSlice'
import { Commuter, MyCommuterDetails } from 'redux/employee/employeeTypes'
import MyCommuterChangeWorksite from './MyCommuterDetails/MyCommuterChangeWorksite'
import { employerActions } from 'redux/employer/employerSlice'
import MyCommuterGiveOTR from './MyCommuterDetails/MyCommuterGiveOTR'
import { incentivesActions } from 'redux/incentives/incentivesSlice'
import MyCommuterInvite from './MyCommuterDetails/MyCommuterInvite'
import Chip, { ChipVariants } from 'core-system/Chip'
import { getStatusChipAttributes } from './MyCommutersView'

const Header = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.purple7};
  padding: 1rem ${(props) => props.theme.pxToRem(52)} 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
  width: 100%;
`

const TabButton = styled.div<{ isActive: boolean }>`
  margin-right: 1.5rem;
  padding-bottom: 1rem;
  pointer-events: none;
  border-bottom: 3px solid transparent;

  button {
    pointer-events: auto;
  }

  &:hover {
    border-bottom: 3px solid
      ${(props) => props.theme.palette.primary.primaryPurple};
  }

  ${(props) =>
    props.isActive &&
    css`
      border-bottom: 3px solid
        ${(props) => props.theme.palette.primary.primaryPurple};
    `};
`

const HeaderContainer = styled(FlexContainer)`
  position: relative;
  width: 100%;
`

const getEmpQTFBalanceInfo = (
  myCommuterDetails: MyCommuterDetails,
  basicCommuterInfo: Commuter,
  qtfPrograms: any
) => {
  const qtfProgramsSegments =
    qtfPrograms && new Set<string>(Object.keys(qtfPrograms))
  const empQTFSegments =
    myCommuterDetails && basicCommuterInfo && qtfProgramsSegments
      ? new Set<string>(
          myCommuterDetails[basicCommuterInfo.id]?.segmentsEnrolled.filter(
            (segment) => qtfProgramsSegments.has(segment)
          )
        )
      : null
  const empInActiveQTFProgram = empQTFSegments?.size > 0 ? true : false
  const showQTFBalances =
    myCommuterDetails &&
    basicCommuterInfo &&
    qtfPrograms &&
    // We want to show the balances if either the user has QTF balances or if their programs are active
    (empInActiveQTFProgram ||
      (myCommuterDetails[basicCommuterInfo.id]?.qtfBalanceData &&
        (myCommuterDetails[basicCommuterInfo.id].qtfBalanceData
          .parkingCycleBalance > 0 ||
          myCommuterDetails[basicCommuterInfo.id].qtfBalanceData
            .transitCycleBalance > 0)))
      ? true
      : false

  const qtfBalances =
    myCommuterDetails && basicCommuterInfo
      ? {
          transit:
            myCommuterDetails[basicCommuterInfo.id]?.qtfBalanceData
              ?.transitCycleBalance || 0,
          parking:
            myCommuterDetails[basicCommuterInfo.id]?.qtfBalanceData
              ?.parkingCycleBalance || 0,
        }
      : null

  return {
    showQTFBalances,
    qtfBalances,
  }
}

const getBalanceBreakdownCategories = (
  remainingProgramBudget: number,
  fleetRemaining: number,
  products: Set<string>,
  currentIncentives?: number
) => {
  const universalCategories = [
    {
      title: 'Program Budget',
      value: remainingProgramBudget,
      color: palette.primary.primaryPurple,
    },
    {
      title: 'Personal Top Up',
      value: fleetRemaining,
      color: palette.chips.magenta2,
    },
  ]

  const balanceBreakdownCategories =
    products.has('INCENTIVES') || products.has('OTR')
      ? universalCategories.concat([
          {
            title: 'Incentives',
            value: currentIncentives,
            color: palette.chips.teal2,
          },
        ])
      : universalCategories

  return balanceBreakdownCategories
}

interface MyCommuterDetailsModalProps {
  open: boolean
  closeModal: () => void
  employeeId: string
}

const MyCommuterDetailsModal = React.memo(
  (props: MyCommuterDetailsModalProps) => {
    const { open, closeModal, employeeId } = props

    const dispatch = useDispatch()

    const {
      employeeWorksiteChangeFlag,
      employeeUpdateFlag,
      myCommuterDetails,
      myCommuterTransactions,
      allCommutersMap,
      archivedEmployees,
    } = useSelector((state: AppState) => state.employee)

    const { worksites, allEmployeeSegmentId, profile } = useSelector(
      (state: AppState) => state.employer
    )

    // Fetch the employee's original worksite segment id
    const currEmpWorksiteSegmentId = myCommuterDetails
      ? worksites?.find(
          (worksite) =>
            worksite.id === myCommuterDetails[employeeId]?.worksiteId
        )?.segmentId
      : null

    const { qtfPrograms } = useSelector((state: AppState) => state.qtf)

    const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM'))
    const [activeTab, setActiveTab] = useState(0)
    const [forceChangeWorksite, setForceChangeWorksite] = useState(true)
    const [selectedWorksiteId, setSelectedWorksiteId] = useState(null)
    const [statusChipAttributes, setStatusChipAttributes] = useState<{
      text: string
      variant: string
    }>({
      text: 'NEEDS INVITE',
      variant: 'red',
    })

    const products = new Set(profile.products)

    // OTR details
    const [otrAmount, setOtrAmount] = useState<number>(0)
    const [otrMemo, setOtrMemo] = useState<string>('')

    const onCloseModal = useCallback(() => {
      dispatch(employeeActions.resetMyCommuterDetails({ employeeId }))
      setActiveTab(0)
      setSelectedWorksiteId(null)
      closeModal()
    }, [dispatch, employeeId, closeModal])

    //after employee is successfully archived, close the modal
    useEffect(() => {
      if (employeeUpdateFlag) {
        dispatch(
          employeeActions.getMyCommuters({ segment: allEmployeeSegmentId })
        )
        onCloseModal()
      }
    }, [employeeUpdateFlag, dispatch, onCloseModal, allEmployeeSegmentId])

    // After the employee's worksite is changed, reload the commuter data and close the commuter details modal
    useEffect(() => {
      if (employeeWorksiteChangeFlag === 'success') {
        // Only need to refetch the commuter data if it is a forced worksite change
        if (forceChangeWorksite) {
          const newWorksiteSegmentId = worksites?.find(
            (worksite) => worksite.id === selectedWorksiteId
          )?.segmentId

          // Fetch the allCommuters segment commuters
          dispatch(
            employeeActions.getMyCommuters({ segment: allEmployeeSegmentId })
          )

          // Fetch the old worksite segment's commuters
          if (currEmpWorksiteSegmentId) {
            dispatch(
              employeeActions.getMyCommuters({
                segment: currEmpWorksiteSegmentId,
              })
            )
          }

          // Fetch the new worksite segment's commuters
          if (newWorksiteSegmentId) {
            dispatch(
              employeeActions.getMyCommuters({ segment: newWorksiteSegmentId })
            )
          }

          // Fetch all commuters
          dispatch(employeeActions.getCommuters())
        }

        dispatch(employeeActions.toggleEmployeeWorksiteChangeFlagOff())
        onCloseModal()
      }
    }, [
      onCloseModal,
      employeeWorksiteChangeFlag,
      dispatch,
      allEmployeeSegmentId,
      currEmpWorksiteSegmentId,
      forceChangeWorksite,
      selectedWorksiteId,
      worksites,
    ])

    useEffect(() => {
      if (employeeId) {
        const currentMoment = moment()
        dispatch(
          employeeActions.getMyCommuterDetails({
            id: employeeId,
            startDate: currentMoment.startOf('month').format(),
            endDate: currentMoment.endOf('month').format(),
          })
        )
        dispatch(
          employeeActions.getMyCommuterTransactions({
            id: employeeId,
            startDate: currentMoment.startOf('month').format(),
            endDate: currentMoment.endOf('month').format(),
          })
        )
      }
    }, [dispatch, employeeId])

    useEffect(() => {
      if (myCommuterDetails && myCommuterDetails[employeeId]) {
        setStatusChipAttributes(
          getStatusChipAttributes(myCommuterDetails[employeeId])
        )
      }
    }, [myCommuterDetails, employeeId])

    if (!employeeId) return null

    const menuCallback = (item: SelectItemProps) => {
      // Archive employee
      if (item.id === '0') {
        setActiveTab(-1)
        // Re-Activate employee
      } else if (item.id === '1') {
        dispatch(
          employeeActions.reactivateEmployee({ employeeId: item.employee.id })
        )
        // Change worksite
      } else if (item.id === '2') {
        setActiveTab(-2)
      } else if (item.id === '3') {
        setActiveTab(-3)
        // Give OTR
      } else if (item.id === '4') {
        setOtrAmount(0)
        setOtrMemo('')
        setActiveTab(-4)
      }
    }

    const basicCommuterInfo = allCommutersMap && allCommutersMap[employeeId]
    const commuterWorksite =
      basicCommuterInfo &&
      worksites.find((wrk) => wrk.id === basicCommuterInfo.worksiteId)

    const hasError =
      !basicCommuterInfo ||
      !myCommuterDetails ||
      !myCommuterDetails[employeeId] ||
      !myCommuterTransactions ||
      !myCommuterTransactions[employeeId]

    const {
      email: commuterEmail,
      remainingProgramBudget,
      currentIncentives,
      fleetRemaining,
    } = (myCommuterDetails && myCommuterDetails[employeeId]) || {}

    const currentBalance =
      (remainingProgramBudget || 0) +
      (fleetRemaining || 0) +
      (currentIncentives || 0)

    const handleDateChange = (isIncrease: boolean, date: string) => {
      const currentMoment = moment(date)

      if (isIncrease) {
        const newDate = currentMoment.add(1, 'month')
        setCurrentDate(newDate.format('YYYY-MM'))
        dispatch(
          employeeActions.getMyCommuterDetails({
            id: employeeId,
            startDate: newDate.startOf('month').format(),
            endDate: newDate.endOf('month').format(),
          })
        )
        dispatch(
          employeeActions.getMyCommuterTransactions({
            id: employeeId,
            startDate: currentMoment.startOf('month').format(),
            endDate: currentMoment.endOf('month').format(),
          })
        )
      } else {
        const newDate = currentMoment.subtract(1, 'month')
        setCurrentDate(newDate.format('YYYY-MM'))
        dispatch(
          employeeActions.getMyCommuterDetails({
            id: employeeId,
            startDate: newDate.startOf('month').format(),
            endDate: newDate.endOf('month').format(),
          })
        )
        dispatch(
          employeeActions.getMyCommuterTransactions({
            id: employeeId,
            startDate: currentMoment.startOf('month').format(),
            endDate: currentMoment.endOf('month').format(),
          })
        )
      }
    }

    const handleArchiveClick = (employeeId: string) => {
      dispatch(employeeActions.archiveEmployee({ employeeId }))
    }

    const handleCloseModal = () => {
      setActiveTab(0)
      closeModal()
    }

    const handleWorksiteChange = (
      employeeId: string,
      worksiteId: string,
      forceChangeWorksite: boolean
    ) => {
      dispatch(
        employeeActions.changeEmployeeWorksite({
          employeeId,
          worksiteId,
          forceChange: forceChangeWorksite,
        })
      )
    }

    const handleGiveOTRClick = () => {
      const otrPayload = {
        employeeId: employeeId,
        amount: otrAmount * 100,
        memo: otrMemo,
      }
      dispatch(incentivesActions.createOneTimeReward(otrPayload))
      // TODO: this is a temp fix for the current balance in the modal and the MyCommutersView table not dynamically updating to reflect OTR being added
      window.location.reload()
      handleCloseModal()
    }

    const handleInviteEmployee = () => {
      dispatch(employerActions.rolloutUsers({ employees: [employeeId] }))
      window.location.reload()
      handleCloseModal()
    }

    const archivedEmp =
      archivedEmployees &&
      [...archivedEmployees].find((emp) => emp.id === basicCommuterInfo.id)

    const isFinchEmp =
      myCommuterDetails &&
      myCommuterDetails[basicCommuterInfo?.id]?.finchIndividualId
        ? true
        : false

    const { qtfBalances, showQTFBalances } = getEmpQTFBalanceInfo(
      myCommuterDetails,
      basicCommuterInfo,
      qtfPrograms
    )

    const getSelectItems = () => {
      if (archivedEmp) {
        return [
          {
            id: '1',
            text: 'Re-activate Commuter',
            disabled: commuterWorksite && commuterWorksite.archived,
            employee: basicCommuterInfo,
            productLabel: null,
          },
        ]
      }

      const universalSelectItems = [
        {
          id: '0',
          text: 'Archive Commuter',
          disabled: isFinchEmp || activeTab === -1,
        },
        {
          id: '2',
          text: commuterWorksite ? 'Change Worksite' : 'Add to Worksite',
          disabled: isFinchEmp || activeTab === -2,
        },
        {
          id: '3',
          text: 'Send Invite',
          disabled:
            myCommuterDetails[employeeId].hasRegistered || activeTab === -3,
        },
      ]

      if (products.has('OTR')) {
        universalSelectItems.push({
          id: '4',
          text: 'Give One Time Reward',
          disabled: activeTab === -4,
        })
      }

      return universalSelectItems
    }

    return (
      <Modal
        open={open}
        onClose={onCloseModal}
        width={pxToRem(944)}
        height={pxToRem(797)}
      >
        <ModalHeader title='Commuter Profile' />
        {hasError ? (
          <Loading height={pxToRem(698)} isCard={false} />
        ) : (
          <>
            <Header>
              <FlexContainer
                alignItems='center'
                marginBottom='1.5rem'
                width='100%'
              >
                <PlatformSvg
                  folder='users'
                  variant='userBasic'
                  width={80}
                  height={80}
                />
                <FlexContainer
                  flexDirection='column'
                  marginLeft='1.5rem'
                  marginTop='0.25rem'
                  flexGrow={1}
                  width='90%'
                >
                  <FlexContainer width='100%'>
                    <Text variant='h5' marginTop='0.125rem'>
                      {basicCommuterInfo.name}
                    </Text>
                    <FlexContainer alignItems='center'>
                      <Divider
                        direction='vertical'
                        size='1.5rem'
                        margin='0 1rem'
                      />
                      {archivedEmp ? (
                        <FlexContainer>
                          <AlertIcon color={palette.text.primary} />
                          <Text
                            variant='action3'
                            marginTop='0.125rem'
                            marginLeft='0.25rem'
                          >
                            Archived on{' '}
                            {moment(archivedEmp.archiveDate).format(
                              'MMMM DD, YYYY'
                            )}
                          </Text>
                        </FlexContainer>
                      ) : (
                        <FlexContainer>
                          <Chip
                            variant={
                              statusChipAttributes.variant as ChipVariants
                            }
                          >
                            {statusChipAttributes.text}
                          </Chip>
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </FlexContainer>
                  <HeaderContainer alignItems='center' marginTop='0.5rem'>
                    <FlexContainer width='90%'>
                      <Text variant='action4' marginTop='0.125rem' hideOverflow>
                        ID: #
                        {basicCommuterInfo.employeeCorporateId
                          ? basicCommuterInfo.employeeCorporateId
                          : 'No ID'}
                      </Text>
                      <Divider
                        direction='vertical'
                        size='1.5rem'
                        margin='0 1rem'
                      />
                      <Text variant='action4' marginTop='0.125rem' hideOverflow>
                        {commuterEmail}
                      </Text>
                      <Divider
                        direction='vertical'
                        size='1.5rem'
                        margin='0 1rem'
                      />
                      <FlexContainer alignItems='center'>
                        <LocationIcon
                          width={25}
                          height={25}
                          color={palette.text.secondary}
                        />
                        <Text
                          variant='action4'
                          marginLeft='0.5rem'
                          marginTop='0.125rem'
                          hideOverflow
                        >
                          {commuterWorksite ? commuterWorksite.alias : 'None'}
                        </Text>
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer marginLeft='auto'>
                      <Dropdown
                        variant='textual'
                        svgDropdown={true}
                        icon={<OverflowIcon />}
                        active={null}
                        items={getSelectItems()}
                        itemCallback={menuCallback}
                        width={pxToRem(200)}
                        top='2.75rem'
                        right={0}
                      />
                    </FlexContainer>
                  </HeaderContainer>
                  <FlexContainer
                    marginTop='1rem'
                    marginBottom='0.5rem'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Text variant='action3' minWidth='20rem'>
                      Current Balance:{' '}
                      {FormattingUtils.formatDollar(currentBalance)}
                    </Text>
                    <CategoryBreakdown
                      categories={getBalanceBreakdownCategories(
                        remainingProgramBudget,
                        fleetRemaining,
                        products,
                        currentIncentives
                      )}
                      total={currentBalance}
                      isCurrency
                      isBalanceBreakdown
                    />
                  </FlexContainer>
                  {showQTFBalances && products.has('QTF') ? (
                    <FlexContainer alignItems='center'>
                      <Text variant='action3' minWidth='20rem'>
                        Pre-Tax Commuter Program Balance:{' '}
                        {FormattingUtils.formatDollar(
                          qtfBalances.transit + qtfBalances.parking
                        )}
                      </Text>
                      <CategoryBreakdown
                        categories={[
                          {
                            title: 'Transit',
                            value: qtfBalances.transit,
                            color: palette.primary.primaryPurple,
                          },
                          {
                            title: 'Parking',
                            value: qtfBalances.parking,
                            color: palette.chips.teal2,
                          },
                        ]}
                        total={qtfBalances.transit + qtfBalances.parking}
                        isCurrency
                        isBalanceBreakdown
                      />
                    </FlexContainer>
                  ) : null}
                </FlexContainer>
              </FlexContainer>
              <FlexContainer>
                <TabButton isActive={activeTab === 0}>
                  <Button size='small' onClick={() => setActiveTab(0)}>
                    Overview
                  </Button>
                </TabButton>
                <TabButton isActive={activeTab === 1}>
                  <Button size='small' onClick={() => setActiveTab(1)}>
                    Trips
                  </Button>
                </TabButton>
                <TabButton isActive={activeTab === 2}>
                  <Button size='small' onClick={() => setActiveTab(2)}>
                    Transactions
                  </Button>
                </TabButton>
              </FlexContainer>
            </Header>
            <ModalBody height={pxToRem(545)}>
              {activeTab === -2 && (
                <MyCommuterChangeWorksite
                  commuterDetails={myCommuterDetails[employeeId]}
                  selectedWorksiteId={selectedWorksiteId}
                  forceChangeWorksite={forceChangeWorksite}
                  onWorksiteIdChange={(worksiteId) =>
                    setSelectedWorksiteId(worksiteId)
                  }
                  onForceChangeWorksiteChange={(forceChange) =>
                    setForceChangeWorksite(forceChange)
                  }
                />
              )}
              {activeTab === -3 && <MyCommuterInvite employeeId={employeeId} />}
              {activeTab === -4 && (
                <MyCommuterGiveOTR
                  otrAmount={otrAmount}
                  onOTRAmountChange={(otrAmount) => setOtrAmount(otrAmount)}
                  onOTRMemoChange={(otrMemo) => setOtrMemo(otrMemo)}
                />
              )}
              {activeTab === -1 && (
                <MyCommuterArchive
                  commuterDetails={myCommuterDetails[employeeId]}
                />
              )}
              {activeTab === 0 && (
                <MyCommuterDetailsOverview
                  currentDate={currentDate}
                  commuterDetails={myCommuterDetails[employeeId]}
                  onIncreaseClick={() => handleDateChange(true, currentDate)}
                  onDecreaseClick={() => handleDateChange(false, currentDate)}
                />
              )}
              {activeTab === 1 && (
                <MyCommuterDetailsTrips
                  currentDate={currentDate}
                  commuterDetails={myCommuterDetails[employeeId]}
                  onIncreaseClick={() => handleDateChange(true, currentDate)}
                  onDecreaseClick={() => handleDateChange(false, currentDate)}
                />
              )}
              {activeTab === 2 && (
                <MyCommuterDetailsTransactions
                  currentDate={currentDate}
                  commuterTransactions={myCommuterTransactions[employeeId]}
                  onIncreaseClick={() => handleDateChange(true, currentDate)}
                  onDecreaseClick={() => handleDateChange(false, currentDate)}
                />
              )}
            </ModalBody>
            {activeTab < 0 && (
              <ModalFooter>
                <FlexContainer>
                  <Button variant='tertiary' onClick={() => setActiveTab(0)}>
                    Cancel
                  </Button>
                  {activeTab === -1 && (
                    <Button
                      marginLeft='1rem'
                      onClick={() => handleArchiveClick(employeeId)}
                      disabled={isFinchEmp}
                    >
                      Archive
                    </Button>
                  )}
                  {activeTab === -2 && (
                    <Button
                      marginLeft='1rem'
                      onClick={() =>
                        handleWorksiteChange(
                          employeeId,
                          selectedWorksiteId,
                          forceChangeWorksite
                        )
                      }
                      disabled={isFinchEmp || !selectedWorksiteId}
                    >
                      {commuterWorksite ? 'Change Worksite' : 'Add to Worksite'}
                    </Button>
                  )}
                  {activeTab === -3 && (
                    <Button
                      marginLeft='1rem'
                      onClick={() => handleInviteEmployee()}
                    >
                      Invite Commuter
                    </Button>
                  )}
                  {activeTab === -4 && (
                    <Button
                      marginLeft='1rem'
                      onClick={() => handleGiveOTRClick()}
                      disabled={otrAmount === 0 || otrMemo === ''}
                    >
                      Give One Time Reward
                    </Button>
                  )}
                </FlexContainer>
              </ModalFooter>
            )}
          </>
        )}
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommuterDetailsModal.displayName = 'MyCommuterDetailsModal'
}

export default MyCommuterDetailsModal
