import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import { createRoot } from 'react-dom/client'

import { ThemeProvider } from 'styled-components'
import Themes from './core-system/Themes'
import GlobalStyle from './shared/global.styles'

import { Elements } from '@stripe/react-stripe-js'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import store from 'redux/config/store'
import NotificationsProvider from './features/Notifications/NotificationsProvider'
import NotSupported from './features/NotSupported'
import './shared/index.css'

import 'mapbox-gl/dist/mapbox-gl.css'
import {
  BUGSNAGKEY,
  getReleaseEnviornment,
  getStripePromise,
} from './redux/config/services/Constants'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ErrorView from 'features/ErrorView'
import RootRoutes from './shared/Router/RootRoutes'

// const store = CreateStore()

const stripePromise = getStripePromise()

const stripeElementsOptions = {
  fonts: [
    {
      family: 'Hind, sans-serif',
      src: `url('https://fonts.googleapis.com/css2?family=Hind&display=swap') format("woff2")`,
    },
  ],
}

// Client side crash analytics
Bugsnag.start({
  apiKey: BUGSNAGKEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: getReleaseEnviornment(),
  enabledReleaseStages: ['production', 'staging'],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const App = () => {
  const currentBrowser = navigator.userAgent
  const isIE =
    currentBrowser.indexOf('MSIE') > -1 ||
    currentBrowser.indexOf('Trident') > -1

  return (
    <ThemeProvider theme={Themes.Main}>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <GlobalStyle />
        <Provider store={store}>
          <NotificationsProvider>
            <HelmetProvider>
              {isIE && <NotSupported />}
              {!isIE && (
                <Elements
                  stripe={stripePromise}
                  options={stripeElementsOptions}
                >
                  <RootRoutes />
                </Elements>
              )}
            </HelmetProvider>
          </NotificationsProvider>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

const container = document.getElementById('app')
const root = createRoot(container!)
root.render(<App />)
