import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LeaderboardRankingsParams } from 'redux/employer/employerTypes'
import {
  Leaderboard,
  LeaderboardCreatePayload,
  LeaderboardEmployeeRanking,
  LeaderboardPayload,
  LeaderboardsGroup,
  LeaderboardUpdatePayload,
  LeaderboardWinnersData,
} from 'redux/leaderboards/leaderboardsTypes'

export interface LeaderboardsState {
  leaderboards: Leaderboard[]
  leaderboardsMap: Dictionary<LeaderboardsGroup>
  successTrigger: boolean
  rankings: Dictionary<LeaderboardEmployeeRanking[]>
  winnersData: Dictionary<LeaderboardWinnersData[]>
  summaryStats: Dictionary<Dictionary>
}

const initialState: LeaderboardsState = {
  leaderboards: null,
  leaderboardsMap: null,
  successTrigger: false,
  rankings: null,
  winnersData: null,
  summaryStats: null,
}

const getLeaderboards = createAction('leaderboards/getLeaderboards')
const cancelLeaderboard = createAction<string>('leaderboards/cancelLeaderboard')
const createLeaderboard = createAction<LeaderboardCreatePayload>(
  'leaderboards/createLeaderboard'
)
const updateLeaderboard = createAction<LeaderboardUpdatePayload>(
  'leaderboards/updateLeaderboard'
)

const formatLeaderboardPayload = (
  leaderboard: LeaderboardPayload
): Leaderboard => {
  return {
    id: leaderboard.id,
    familyId: leaderboard.familyId,
    startDate: leaderboard.startDate,
    endDate: leaderboard.endDate,
    status: leaderboard.status,
    type: leaderboard.queryType,
    worksiteId: leaderboard.worksiteId,
    bonuses: [
      parseInt(leaderboard.firstPrize),
      parseInt(leaderboard.secondPrize),
      parseInt(leaderboard.thirdPrize),
    ],
    totalEnvSavings: leaderboard.totalEnvSavings,
    totalDistance: leaderboard.totalDistance,
    numEmployees: leaderboard.numEmployees,
    segment: leaderboard.segment,
  }
}

const handleLeaderboardActionSuccess = (state: LeaderboardsState) => {
  return {
    ...state,
    leaderboards: null,
    successTrigger: true,
  }
}

const leaderboardsSlice = createSlice({
  name: 'leaderboards',
  initialState,
  reducers: {
    getLeaderboardsSuccess(state, action: PayloadAction<LeaderboardPayload[]>) {
      const activeStatuses = ['ACTIVE', 'UPDATING']
      const groupedCompetitions = action.payload.reduce(
        (agg, competition: LeaderboardPayload) => {
          if (competition.status === 'CANCELLED') return agg

          const formattedCompetition = formatLeaderboardPayload(competition)

          agg[competition.segment] = agg[competition.segment] || {
            programs: [],
            programGroups: {},
            active: 0,
          }

          const defaultCompetitionGroup = {} as any

          if (activeStatuses.includes(competition.status)) {
            defaultCompetitionGroup.active = formattedCompetition
            agg[competition.segment].active += 1
          } else {
            defaultCompetitionGroup.nextMonth = formattedCompetition
          }

          agg[competition.segment].programGroups[formattedCompetition.type] = {
            ...agg[competition.segment].programGroups[
              formattedCompetition.type
            ],
            ...defaultCompetitionGroup,
          }

          agg[competition.segment].programs = [
            ...agg[competition.segment].programs,
            formattedCompetition,
          ]

          return agg
        },
        {}
      )

      const leaderboards = action.payload.map(
        (leaderboard: LeaderboardPayload) => {
          return formatLeaderboardPayload(leaderboard)
        }
      )

      return {
        ...state,
        leaderboards,
        leaderboardsMap: groupedCompetitions,
        successTrigger: state.successTrigger && false,
      }
    },
    getLeaderboardRankings(
      state,
      _action: PayloadAction<LeaderboardRankingsParams>
    ) {
      return {
        ...state,
        rankings: null,
        winnersData: null,
        summaryStats: null,
      }
    },
    getLeaderboardRankingsSuccess(state, action: any) {
      const { queryType } = action.meta
      const {
        rankingsData = [],
        winnersData = null,
        totalDistance = null,
        totalEnvSavings = null,
      } = action.payload

      return {
        ...state,
        rankings: { ...state.rankings, [queryType]: rankingsData },
        summaryStats: {
          ...state.summaryStats,
          [queryType]: {
            totalDistance,
            totalEnvSavings,
          },
        },
        winnersData: { ...state.winnersData, [queryType]: winnersData },
      }
    },
    createLeaderboardSuccess(state) {
      return handleLeaderboardActionSuccess(state)
    },
    updateLeaderboardSuccess(state) {
      return handleLeaderboardActionSuccess(state)
    },
    cancelLeaderboardSuccess(state) {
      return handleLeaderboardActionSuccess(state)
    },
  },
})

export const leaderboardsReducer = leaderboardsSlice.reducer
export const leaderboardsActions = {
  ...leaderboardsSlice.actions,
  getLeaderboards,
  cancelLeaderboard,
  createLeaderboard,
  updateLeaderboard,
}
