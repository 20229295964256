import React, { useEffect } from 'react'

import { employerActions } from 'redux/employer/employerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import FleetCardsConfig from './components/FleetCardsConfig'
import Invoices from './components/Invoices'
import SubscriptionCard from './components/SubscriptionCard'

const BillingView = () => {
  const dispatch = useDispatch()
  const { totalEmployees, employerInvoices, profile } = useSelector(
    (state: AppState) => state.employer
  )

  useEffect(() => {
    dispatch(employerActions.getEmployerInvoices())
  }, [dispatch])

  return (
    <>
      <SubscriptionCard
        totalEmployees={totalEmployees}
        planDetails={profile.planDetails}
        promoCode={profile.promoCode}
        referralCredits={profile.referralCredits}
      />
      <FleetCardsConfig />
      <Invoices invoices={employerInvoices} />
    </>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  BillingView.displayName = 'BillingView'
}

export default BillingView
