import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import AddIcon from 'core-system/Icons/Actions/Add'
import Loading from 'core-system/Loading'
import React, { useCallback, useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  OnDragEndResponder,
} from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { conciergeActions } from 'redux/concierge/conciergeSlice'
import { Faq } from 'redux/concierge/conciergeTypes'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import FaqEditCard from './FaqEditCard'
import StrictModeDroppable from './StrictModeDroppable'

const Container = styled.div``

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem ${(props) => props.theme.pxToRem(52)};
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 5rem);
  box-shadow: ${(props) => props.theme.dropShadows.bottom};
  z-index: ${(props) => props.theme.zIndex.max};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
  }
`

const Content = styled.div`
  padding-bottom: 10rem;
`

const ButtonContainer = styled.div`
  display: flex;
`

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  color: ${(props) => props.theme.palette.text.primary};
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const Title = styled.div`
  ${(props) => props.theme.typography.action1}
  margin-right: 0.5rem;
`

const reorder = (list: Faq[], startIndex: number, endIndex: number): Faq[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

type LocationState = {
  location: string
  faq?: Faq
  idx?: number
}

const FaqView = React.memo(() => {
  const allFaqs = useSelector((state: AppState) => state.concierge.faqs)
  const conciergeSuccessAction = useSelector(
    (state: AppState) => state.concierge.conciergeSuccessAction
  )

  const [items, setItems] = useState<Faq[] | null>(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as LocationState

  const isEditing =
    items && items.filter((item: Faq) => !item.question).length > 0

  useEffect(() => {
    if (!allFaqs) {
      dispatch(conciergeActions.getFaqs())
    }
  }, [allFaqs, dispatch])

  useEffect(() => {
    if (allFaqs && allFaqs.faq) {
      setItems(
        allFaqs.faq.map((faq: Faq, idx: number) => {
          return { ...faq, id: `item-${idx}` }
        })
      )
    } else if (allFaqs && !allFaqs.faq) {
      setItems([{ question: null, answer: null, id: 'item-1' }])
    } else {
      return
    }
  }, [allFaqs])

  const onDragEnd = (result: OnDragEndResponder) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const itemsReorder = reorder(
      items,
      result.source.index,
      result.destination.index
    )

    setItems(itemsReorder)
  }

  const handleUpdate = (faq: Faq, idx: number) => {
    const itemsCopy = [...items]
    itemsCopy.splice(idx, 1, faq)
    setItems(itemsCopy)
  }

  const handleDelete = (idx: number) => {
    const itemsCopy = [...items]
    itemsCopy.splice(idx, 1)
    setItems(itemsCopy)
  }

  const createNewFaq = () => {
    const itemsCopy = [...items]
    const idx = itemsCopy.length + 1
    itemsCopy.push({
      question: null,
      answer: null,
      id: `item-${idx}`,
    })
    setItems(itemsCopy)
    SegmentService.track('faq-btn-click', {
      type: 'add',
    })
  }

  const handleSaveChanges = useCallback(() => {
    const faqs = items.map((item: Faq) => {
      return { question: item.question, answer: item.answer }
    })
    dispatch(conciergeActions.updateFaq({ faq: faqs }))
    SegmentService.track('faq-update-click', {
      total: items.length || 0,
    })
  }, [dispatch, items])

  const handleCancel = useCallback(() => {
    navigate(`/concierge`, {
      state: { from: 'faq' },
    })
    SegmentService.track('faq-cancel-click')
  }, [navigate])

  useEffect(() => {
    if (conciergeSuccessAction) {
      dispatch(conciergeActions.cancelConciergeSuccess())
      navigate(`/concierge`, {
        state: { from: 'faq' },
      })
    }
  }, [conciergeSuccessAction, dispatch, navigate])

  if (!items) {
    return <Loading fullPage={true} />
  }

  return (
    <Container>
      <Content>
        <TitleContainer>
          <Title>Edit FAQs</Title>
          <Button iconLeft={<AddIcon />} onClick={createNewFaq}>
            Add FAQ
          </Button>
        </TitleContainer>
        <ColumnView gridTemplateColumns={'1fr'}>
          <Column>
            <DragDropContext onDragEnd={onDragEnd}>
              <StrictModeDroppable droppableId={'droppable'}>
                {(provided: DroppableProvided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map((item, idx) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={idx}
                      >
                        {(
                          provided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => (
                          <FaqEditCard
                            idx={idx}
                            faq={item}
                            ref={provided.innerRef}
                            draggableProps={provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                            isOpen={state && idx === state.idx}
                            isNew={!item.question}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          </Column>
        </ColumnView>
      </Content>
      <Footer>
        <Button variant='tertiary' onClick={handleCancel}>
          Cancel
        </Button>
        <ButtonContainer>
          <Button
            onClick={handleSaveChanges}
            disabled={isEditing || (items && items.length === 0)}
          >
            Save Changes
          </Button>
        </ButtonContainer>
      </Footer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FaqView.displayName = 'FaqView'
}

export default FaqView
