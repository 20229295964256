import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d='M4.63104 11.3379L4.63104 2.02494'
      stroke='#6200E0'
      strokeWidth='1.6'
    />
    <path
      d='M8.63745 5.63086L4.6311 1.62451L0.624745 5.63086'
      stroke='#6200E0'
      strokeWidth='1.6'
    />
  </>,
  'Arrow',
  '0 0 10 12'
)
