import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M0.999954 19C0.999954 16.34 6.32995 15 8.99995 15C11.67 15 17 16.34 17 19V20C17 20.55 16.55 21 16 21H1.99995C1.44995 21 0.999954 20.55 0.999954 20V19Z' />
    <path d='M15.47 10.23C15.79 9.44001 15.79 8.56002 15.47 7.77002C15.28 7.30002 15.36 6.76001 15.7 6.41001L15.71 6.40001C16.3 5.81001 17.3 5.95001 17.68 6.69001C18.46 8.20002 18.46 9.90002 17.7 11.35C17.32 12.08 16.33 12.2 15.75 11.62L15.72 11.59C15.36 11.23 15.28 10.7 15.47 10.23Z' />
    <path d='M19.05 4.37001C18.72 3.91001 18.78 3.29001 19.18 2.89001C19.67 2.40001 20.51 2.44001 20.93 2.99001C23.69 6.64002 23.68 11.47 20.93 15.01C20.51 15.56 19.68 15.6 19.19 15.11L19.16 15.08C18.77 14.69 18.7 14.07 19.02 13.62C21.01 10.78 21.02 7.11001 19.05 4.37001Z' />
    <path d='M12.9999 9C12.9999 11.2091 11.2091 13 8.99994 13C6.7908 13 4.99994 11.2091 4.99994 9C4.99994 6.79086 6.7908 5 8.99994 5C11.2091 5 12.9999 6.79086 12.9999 9Z' />
  </>,
  'Announcements'
)
