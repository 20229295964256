import { createGlobalStyle } from 'styled-components'
import Montserrat, { Name as MontserratName } from './Montserrat'
import Hind, { Name as HindName } from './Hind'
import Cabin, { Name as CabinName } from './Cabin'
import PolySans, { Name as PolySansName } from './PolySans'

export const Fonts = {
  Montserrat: {
    Name: MontserratName,
    Weights: {
      Regular: 400,
      Medium: 500,
      Semibold: 600,
      Bold: 800,
    },
  },
  Hind: {
    Name: HindName,
    Weights: {
      Regular: 400,
      Medium: 500,
      Semibold: 600,
      Bold: 700,
    },
  },
  Cabin: {
    Name: CabinName,
    Weights: {
      Regular: 400,
      Medium: 500,
      Bold: 700,
    },
  },
  PolySans: {
    Name: PolySansName,
    Weights: {
      Regular: 400,
      Medium: 500,
      Semibold: 600,
      Bold: 800,
    },
  },
}

export default createGlobalStyle`
${Montserrat};
${Hind};
${Cabin};
${PolySans};
`
