import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d='M12.2203 6.79136C13.2168 6.79136 14.0702 7.36927 14.4887 8.2028L16.2591 7.31734C15.5239 5.80253 13.975 4.75465 12.1776 4.75465C11.5151 4.75465 10.8873 4.89976 10.3196 5.15537L11.1689 7.02405C11.4902 6.87792 11.8446 6.79136 12.2203 6.79136ZM23.4222 6.79136C24.1692 6.79136 24.8337 7.11876 25.3001 7.63048L26.7752 6.2888C25.9437 5.34988 24.7324 4.75465 23.3795 4.75465C21.5587 4.75465 19.9924 5.82952 19.2689 7.37691L21.1264 8.25372C21.5347 7.39219 22.4054 6.79136 23.4222 6.79136Z'
      fill='#BEB3FF'
    />
    <path
      d='M12.2204 5.77302C10.2519 5.77302 8.65613 7.36878 8.65613 9.33726C8.65613 11.3057 10.2519 12.9015 12.2204 12.9015C14.1889 12.9015 15.7846 11.3057 15.7846 9.33726C15.7846 7.36878 14.1894 5.77302 12.2204 5.77302ZM12.2204 11.8832C10.8145 11.8832 9.67448 10.7436 9.67448 9.33726C9.67448 7.93091 10.8145 6.79137 12.2204 6.79137C13.6262 6.79137 14.7663 7.93091 14.7663 9.33726C14.7663 10.7436 13.6267 11.8832 12.2204 11.8832ZM23.4223 5.77302C21.4543 5.77302 19.858 7.36878 19.858 9.33726C19.858 11.3057 21.4543 12.9015 23.4223 12.9015C25.3913 12.9015 26.9865 11.3057 26.9865 9.33726C26.9865 7.36878 25.3913 5.77302 23.4223 5.77302ZM23.4223 11.8832C22.0164 11.8832 20.8764 10.7436 20.8764 9.33726C20.8764 7.93091 22.0164 6.79137 23.4223 6.79137C24.8286 6.79137 25.9682 7.93091 25.9682 9.33726C25.9682 10.7436 24.8286 11.8832 23.4223 11.8832Z'
      fill='#16014A'
    />
    <path
      d='M23.9233 9.29734C23.9208 9.26322 23.9126 9.23013 23.9029 9.19652C23.8933 9.16343 23.8826 9.13237 23.8668 9.10182C23.8602 9.08959 23.8592 9.07534 23.852 9.06363L20.2878 3.46267C20.1366 3.2254 19.8224 3.15513 19.5846 3.30636C19.4324 3.4031 19.3585 3.56756 19.358 3.73559H14.7663C14.4852 3.73559 14.2571 3.96319 14.2571 4.24477C14.2571 4.52635 14.4852 4.75395 14.7663 4.75395H19.858C19.8718 4.75395 19.8835 4.74682 19.8972 4.7458L22.4946 8.82788H17.8213C17.5403 8.82788 17.3121 9.05548 17.3121 9.33706C17.3121 9.61863 17.5403 9.84623 17.8213 9.84623H23.4228C23.4819 9.84623 23.5399 9.83198 23.5974 9.8111C23.6147 9.80448 23.63 9.79735 23.6468 9.78921C23.6626 9.78055 23.6804 9.77698 23.6962 9.7668C23.7115 9.75662 23.7207 9.74083 23.7344 9.73014C23.7624 9.70774 23.7869 9.68482 23.8093 9.65733C23.8301 9.63238 23.8469 9.60692 23.8622 9.57891C23.8775 9.5504 23.8902 9.5229 23.9004 9.49134C23.9111 9.45773 23.9172 9.42412 23.9208 9.38899C23.9228 9.37066 23.9315 9.35539 23.9315 9.33706C23.9315 9.32331 23.9243 9.31109 23.9233 9.29734V9.29734Z'
      fill='#947BFF'
    />
    <path
      d='M19.6445 2.71793L18.1684 6.85093L15.1495 3.40125C14.9642 3.18994 14.6424 3.16754 14.4311 3.35339C14.2198 3.53873 14.1984 3.86002 14.3832 4.07184L17.7764 7.94923L17.3415 9.16616C17.2473 9.43042 17.3853 9.72218 17.6501 9.81638C17.7066 9.83675 17.7641 9.84642 17.8212 9.84642C18.0304 9.84642 18.2265 9.71658 18.3008 9.50833L20.726 2.71793H19.6445Z'
      fill='#947BFF'
    />
    <path
      d='M12.2204 9.8464C12.144 9.8464 12.0661 9.82909 11.9933 9.79293C11.7418 9.66717 11.6399 9.36115 11.7657 9.10962L13.3248 6.00058C13.3334 5.98632 14.2571 4.57692 14.2571 3.22709C14.2571 1.57633 13.0895 1.16848 13.0778 1.16441C12.811 1.0753 12.6669 0.787108 12.756 0.520299C12.8446 0.25349 13.1323 0.107865 13.4001 0.198499C13.4765 0.223957 15.2754 0.850755 15.2754 3.22709C15.2754 4.78925 14.3406 6.30354 14.1904 6.53522L12.6761 9.56431C12.587 9.74354 12.4072 9.8464 12.2204 9.8464Z'
      fill='#947BFF'
    />
    <path
      d='M16.0961 1.52794C16.0686 1.52794 16.0401 1.5259 16.0121 1.52081L12.9571 1.01163C12.6801 0.965295 12.4922 0.703068 12.5385 0.425566C12.5849 0.148574 12.8445 -0.0393131 13.1246 0.00702204L16.1796 0.5162C16.4566 0.562535 16.6445 0.824761 16.5982 1.10226C16.5564 1.35125 16.3405 1.52794 16.0961 1.52794V1.52794ZM22.0897 3.22706C22.0744 3.22706 22.0596 3.22604 22.0444 3.22401L19.1563 2.86351C18.9669 2.84009 18.8284 2.67308 18.8406 2.48264C18.8523 2.29221 19.0102 2.14404 19.2006 2.14404C19.2215 2.14404 21.2714 2.13488 22.2501 1.48262C22.3774 1.39759 22.5464 1.40268 22.6696 1.49586C22.7913 1.59006 22.8412 1.75096 22.7923 1.89709L22.4313 2.98011C22.3824 3.1293 22.2434 3.22706 22.0897 3.22706V3.22706Z'
      fill='#16014A'
    />
    <path
      d='M18.8396 9.33724C18.8396 9.89937 18.3839 10.3556 17.8212 10.3556C17.2591 10.3556 16.8029 9.89937 16.8029 9.33724C16.8029 8.77511 17.2591 8.31889 17.8212 8.31889C18.3839 8.31889 18.8396 8.77511 18.8396 9.33724Z'
      fill='#5E5EAA'
    />
    <path
      d='M2.2913 20C3.55675 20 4.5826 18.9741 4.5826 17.7087C4.5826 16.4432 3.55675 15.4174 2.2913 15.4174C1.02585 15.4174 0 16.4432 0 17.7087C0 18.9741 1.02585 20 2.2913 20Z'
      fill='#16014A'
    />
    <path
      d='M2.29133 19.2362C3.13496 19.2362 3.81886 18.5523 3.81886 17.7087C3.81886 16.8651 3.13496 16.1812 2.29133 16.1812C1.44769 16.1812 0.763794 16.8651 0.763794 17.7087C0.763794 18.5523 1.44769 19.2362 2.29133 19.2362Z'
      fill='#947BFF'
    />
    <path
      d='M16.0391 20C17.3046 20 18.3304 18.9741 18.3304 17.7087C18.3304 16.4432 17.3046 15.4174 16.0391 15.4174C14.7737 15.4174 13.7478 16.4432 13.7478 17.7087C13.7478 18.9741 14.7737 20 16.0391 20Z'
      fill='#16014A'
    />
    <path
      d='M16.0391 19.2362C16.8828 19.2362 17.5667 18.5523 17.5667 17.7087C17.5667 16.8651 16.8828 16.1812 16.0391 16.1812C15.1955 16.1812 14.5116 16.8651 14.5116 17.7087C14.5116 18.5523 15.1955 19.2362 16.0391 19.2362Z'
      fill='#947BFF'
    />
    <path
      d='M16.0391 18.4725H5.85556C5.43549 18.4725 5.0918 18.1288 5.0918 17.7087C5.0918 17.2887 5.43549 16.945 5.85556 16.945H16.0391C16.4592 16.945 16.8029 17.2887 16.8029 17.7087C16.8029 18.1288 16.4592 18.4725 16.0391 18.4725Z'
      fill='#947BFF'
    />
    <path
      d='M13.2448 17.834C13.2433 17.7922 13.2387 17.751 13.2387 17.7087C13.2387 16.1618 14.4923 14.9082 16.0391 14.9082C16.6553 14.9082 17.2235 15.1099 17.6858 15.4469L18.139 14.8334C17.5494 14.4021 16.8253 14.1445 16.0386 14.1445C14.0702 14.1445 12.4744 15.7402 12.4744 17.7087C12.4744 17.7606 12.48 17.8111 12.482 17.8625L13.2448 17.834V17.834ZM2.86621 14.9678C4.13661 15.2326 5.09081 16.3579 5.09183 17.7062L6.87395 17.7077C6.87344 15.4979 5.30874 13.6536 3.22671 13.2218C3.10858 13.7972 2.98434 14.398 2.86621 14.9678Z'
      fill='#947BFF'
    />
    <path
      d='M2.23794 17.9622C2.10097 17.9332 2.01289 17.7972 2.04191 17.6603L5.09799 3.28209C5.12702 3.14512 5.26297 3.05704 5.39993 3.08606C5.5369 3.11508 5.62499 3.25103 5.59597 3.388L2.53988 17.7662C2.51086 17.9031 2.37491 17.9912 2.23794 17.9622V17.9622Z'
      fill='#5E5EAA'
    />
    <path
      d='M3.11781 15.0412L2.61983 14.9353C2.3459 14.8772 2.16921 14.6053 2.22777 14.3314L2.86271 11.343C2.92076 11.0691 3.19266 10.8924 3.4666 10.951L3.96457 11.0569C4.23851 11.1149 4.41519 11.3868 4.35664 11.6607L3.7222 14.6486C3.66365 14.9225 3.39175 15.0992 3.11781 15.0412V15.0412Z'
      fill='#947BFF'
    />
    <path
      d='M4.73546 3.87127L3.739 3.65945C3.32809 3.57188 3.06332 3.16453 3.1509 2.75363C3.23797 2.34272 3.64582 2.07795 4.05673 2.16502L5.05268 2.37684C5.46359 2.46441 5.72836 2.87176 5.64078 3.28266C5.55371 3.69357 5.14586 3.95834 4.73546 3.87127V3.87127Z'
      fill='#947BFF'
    />
    <path
      d='M5.51959 2.52249C5.10868 2.43491 4.70083 2.69968 4.61376 3.11059L3.92586 6.34794C3.86781 6.62188 4.04399 6.89378 4.31793 6.95183L4.8159 7.05774C5.08984 7.11578 5.36174 6.93961 5.41979 6.66567L6.10769 3.42832C6.19527 3.01741 5.93049 2.61007 5.51959 2.52249V2.52249Z'
      fill='#947BFF'
    />
    <path
      d='M7.1819 4.39107L5.68798 4.07334C5.27707 3.98576 5.0123 3.57842 5.09988 3.16751C5.18745 2.75661 5.5948 2.49183 6.0057 2.57941L7.49963 2.89714C7.91054 2.98472 8.17531 3.39206 8.08773 3.80297C8.00066 4.21387 7.59281 4.47865 7.1819 4.39107V4.39107Z'
      fill='#947BFF'
    />
    <path
      d='M8.09756 3.80519C8.00998 4.21762 7.60417 4.48138 7.19173 4.39329C6.7793 4.30571 6.51554 3.8999 6.60363 3.48746C6.69121 3.07503 7.09703 2.81127 7.50946 2.89936C7.92189 2.98745 8.18565 3.39225 8.09756 3.80519Z'
      fill='#BEB3FF'
    />
    <path
      d='M17.9179 15.5193C18.126 15.5193 18.2947 15.3507 18.2947 15.1426C18.2947 14.9345 18.126 14.7658 17.9179 14.7658C17.7098 14.7658 17.5411 14.9345 17.5411 15.1426C17.5411 15.3507 17.7098 15.5193 17.9179 15.5193Z'
      fill='#947BFF'
    />
  </>,
  'AlternativeCommute',
  '0 0 27 20'
)
