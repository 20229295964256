import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Chip from 'core-system/Chip'
import Tooltip from 'core-system/Tooltip'
import { CombinedModeTypeFilter } from './ActivateIncentiveRewards'

const Container = styled.div`
  margin-right: 1rem;
`

const StyledChip = styled(Chip)`
  white-space: nowrap;
`

interface ActivateIncentiveRewardFilterProps {
  handleFilterClick: (mode: CombinedModeTypeFilter) => void
  mode: CombinedModeTypeFilter
}

const ActivateIncentiveRewardFilter = React.memo(
  (props: ActivateIncentiveRewardFilterProps) => {
    const { mode, handleFilterClick } = props

    const [isTooltipVisible, setIsTooltipVisible] = useState(false)

    const tooltipRef = useRef(null)

    return (
      <Container
        ref={tooltipRef}
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
      >
        <StyledChip variant='filter' onClick={() => handleFilterClick(mode)}>
          <>
            <Tooltip
              target={tooltipRef}
              show={isTooltipVisible}
              position='bottom'
              marginTop='0.25rem'
              isMultiLine={true}
            >
              {mode.description.join(', ')}
            </Tooltip>
            {mode.title}
          </>
        </StyledChip>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ActivateIncentiveRewardFilter.displayName = 'ActivateIncentiveRewardFilter'
}

export default ActivateIncentiveRewardFilter
