import React, { useState, useEffect } from 'react'
import ColumnView, { Column } from 'core-system/ColumnView'
import styled from 'styled-components'
import useQueryParam from 'shared/Hooks/useQueryParam'
import { Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import GRHUtils from '../Shared/GRHUtils'
import {
  GrhActiveOptions,
  GrhManageState,
  GrhProgram,
} from 'redux/grh/grhTypes'
import { grhActions } from 'redux/grh/grhSlice'
import { Merchant, OptionState } from 'redux/programs/programsTypes'
import SegmentService from 'redux/config/services/SegmentService'
import moment from 'moment'
import Loading from 'core-system/Loading'
import { Locations } from 'shared/Router/Locations'
import BudgetUsesSelector from '../Activation/components/BudgetUsesSelector'
import ProgramOption from 'core-system/Program/ProgramOption'
import GRHManageSummary from './components/GRHManageSummary'
import GRHManagePending from './components/GRHManagePending'
import Footer from 'core-system/Program/Footer'
import Button from 'core-system/Button'
import ServiceProviderSelectorModal from 'core-system/ServiceProvider/ServiceProviderSelectorModal'
import ProgramModal from 'core-system/Program/ProgramModal'
import { notificationsActions } from 'redux/notifications/notificationsSlice'

const StyledColumnView = styled(ColumnView)`
  margin-bottom: 10rem;
  grid-template-columns: 64.5% 34.5%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    max-width: ${(props) => props.theme.pxToRem(700)};
    grid-gap: unset;
  }
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`
const getDefaultOptions = (program: GrhProgram, localTaxiIds: string[]) => {
  return program.merchants.reduce(
    (vals, id) => {
      if (localTaxiIds.includes(id)) {
        vals.localTaxi = true
      } else {
        vals.rideshare = true
      }
      return vals
    },
    { rideshare: false, localTaxi: false }
  )
}

const formatMerchantIds = (
  activeMerchantIds: string[],
  availableMerchants: Merchant[],
  availableTransitMerchantIds: string[]
): OptionState => {
  return {
    //need to filter out active local taxi ids so users aren't able to select
    activeMerchantIds: activeMerchantIds.filter(
      (id) => !availableTransitMerchantIds.includes(id)
    ),
    availableMerchantIds: availableMerchants.map((m) => m.id),
  }
}

const GRHManageView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )

  const grhProgramsLoaded = useSelector(
    (state: AppState) => state.grh.grhProgramsLoaded
  )
  const grhProgramUpdated = useSelector(
    (state: AppState) => state.grh.grhProgramUpdated
  )
  const availableMerchants = useSelector(
    (state: AppState) => state.grh.availableMerchants
  )
  const allGrhPrograms = useSelector((state: AppState) => state.grh.grhPrograms)

  const [isEdit, setIsEdit] = useState(false)
  const [errorState, setErrorState] = useState(GRHUtils.defaultErrorState)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [availableLocalTaxiMerchantIds, setAvailableLocalTaxiMerchantIds] =
    useState<string[]>([])
  const [programState, setProgramState] = useState<GrhManageState>({
    active: null,
    nextMonth: null,
  })
  const [pendingProgram, setPendingProgram] = useState({
    program: null,
    activeOptions: null,
  })
  const [activeState, setActiveState] = useState('active')
  const [activeOptions, setActiveOptions] =
    useState<Dictionary<GrhActiveOptions>>(null)

  useEffect(() => {
    //ensure all providers are loaded so proper options groupings can be done
    if (segmentId && merchantsMap && !availableMerchants) {
      dispatch(
        grhActions.getGrhRecommendations({
          segmentId,
        })
      )
    }
  }, [segmentId, merchantsMap, availableMerchants, dispatch])

  useEffect(() => {
    if (merchantsMap && availableMerchants && allGrhPrograms) {
      const activeProgramGroup = allGrhPrograms[segmentId]
      const rideMerchantIds = availableMerchants.rideshare.map((m) => m.id)
      const localTaxiMerchantIds = availableMerchants.localTaxi.map((m) => m.id)

      if (!activeProgramGroup.active && activeProgramGroup.nextMonth) {
        const nextMonthState = {
          ...activeProgramGroup.nextMonth,
          rideshare: formatMerchantIds(
            activeProgramGroup.nextMonth.merchants,
            availableMerchants.rideshare,
            localTaxiMerchantIds
          ),
          localTaxi: formatMerchantIds(
            activeProgramGroup.nextMonth.merchants,
            availableMerchants.localTaxi,
            rideMerchantIds
          ),
        }
        const nextMonthOptions = getDefaultOptions(
          activeProgramGroup.nextMonth,
          localTaxiMerchantIds
        )

        setProgramState((prevState) => {
          return {
            ...prevState,
            nextMonth: nextMonthState,
          }
        })
        setActiveOptions({
          active: null,
          nextMonth: nextMonthOptions,
        })
        setIsEdit(true)
        setActiveState('nextMonth')
        setPendingProgram({
          program: nextMonthState,
          activeOptions: nextMonthOptions,
        })
      } else if (activeProgramGroup.active) {
        const activeProgramState = {
          ...activeProgramGroup.active,
          rideshare: formatMerchantIds(
            activeProgramGroup.active.merchants,
            availableMerchants.rideshare,
            localTaxiMerchantIds
          ),
          localTaxi: formatMerchantIds(
            activeProgramGroup.active.merchants,
            availableMerchants.localTaxi,
            rideMerchantIds
          ),
        }
        setProgramState({
          active: activeProgramState,
          nextMonth: activeProgramGroup.nextMonth
            ? {
                ...activeProgramGroup.nextMonth,
                rideshare: formatMerchantIds(
                  activeProgramGroup.nextMonth.merchants,
                  availableMerchants.rideshare,
                  localTaxiMerchantIds
                ),
                localTaxi: formatMerchantIds(
                  activeProgramGroup.nextMonth.merchants,
                  availableMerchants.localTaxi,
                  rideMerchantIds
                ),
              }
            : {
                ...activeProgramState,
                startDate: moment()
                  .add(1, 'M')
                  .startOf('month')
                  .format('YYYY-MM-DDTHH:mm:ss'),
              },
        })
        const defaultActiveOptions = getDefaultOptions(
          activeProgramGroup.active,
          localTaxiMerchantIds
        )
        setActiveOptions({
          active: defaultActiveOptions,
          nextMonth: activeProgramGroup.nextMonth
            ? getDefaultOptions(
                activeProgramGroup.nextMonth,
                localTaxiMerchantIds
              )
            : defaultActiveOptions,
        })
      }

      setAvailableLocalTaxiMerchantIds(
        GRHUtils.getLocalTaxiProviders(
          localTaxiMerchantIds,
          [...availableMerchants.rideshare, ...availableMerchants.localTaxi],
          segmentsMap[segmentId].metroAreas
        )
      )
    }
  }, [allGrhPrograms, merchantsMap, availableMerchants, segmentId, segmentsMap])

  useEffect(() => {
    if (grhProgramUpdated && grhProgramsLoaded) {
      dispatch(grhActions.toggleGrhProgramUpdated())
      if (!allGrhPrograms[segmentId].active) {
        navigate(`/guaranteed-rides/${segmentId}`)
      } else {
        setIsEdit(false)
        setActiveState('active')
        setIsUpdateModalOpen(false)
      }
    }
  }, [
    navigate,
    grhProgramUpdated,
    dispatch,
    grhProgramsLoaded,
    segmentId,
    allGrhPrograms,
  ])
  const toggleEdit = () => {
    if (isEdit) {
      const activeProgramGroup = allGrhPrograms[segmentId]
      const ridehshareMerchantIds = availableMerchants.rideshare.map(
        (m) => m.id
      )
      const localTaxiMerchantIds = availableMerchants.localTaxi.map((m) => m.id)

      setProgramState((prevState) => {
        return {
          ...prevState,
          nextMonth: activeProgramGroup.nextMonth
            ? {
                ...activeProgramGroup.nextMonth,
                rideshare: formatMerchantIds(
                  activeProgramGroup.nextMonth.merchants,
                  availableMerchants.rideshare,
                  localTaxiMerchantIds
                ),
                localTaxi: formatMerchantIds(
                  activeProgramGroup.nextMonth.merchants,
                  availableMerchants.localTaxi,
                  ridehshareMerchantIds
                ),
              }
            : prevState.active,
        }
      })
    }

    setIsEdit((prevState) => !prevState)
    setActiveState((prevState) => {
      return prevState === 'active' ? 'nextMonth' : 'active'
    })

    SegmentService.track('program-manage-action', {
      action: isEdit ? 'close' : 'edit',
      programType: 'grh',
      segmentName: segmentsMap[segmentId].name,
    })
  }

  const handleOptionToggle = (type: string) => {
    //activate all providers when turning on a blank option
    if (
      // type === 'rideshare' &&
      !activeOptions.nextMonth[type] &&
      programState.nextMonth[type].activeMerchantIds.length === 0
    ) {
      setProgramState((prevState) => {
        return {
          ...prevState,
          nextMonth: {
            ...prevState.nextMonth,
            [type]: {
              ...prevState.nextMonth[type],
              activeMerchantIds: prevState.nextMonth[type].availableMerchantIds,
            },
          },
        }
      })
    }

    setActiveOptions((prevState) => {
      return {
        ...prevState,
        nextMonth: {
          ...prevState.nextMonth,
          [type]: !prevState.nextMonth[type],
        },
      }
    })
  }

  const handleProviderChange = (
    type: string,
    newActiveMerchantIds: string[]
  ) => {
    setProgramState((prevState) => {
      return {
        ...prevState,
        nextMonth: {
          ...prevState.nextMonth,
          [type]: {
            ...prevState.nextMonth[type],
            activeMerchantIds: newActiveMerchantIds,
          },
        },
      }
    })
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
    SegmentService.track('modes-modal-toggle', {
      action: 'open',
      state: isEdit ? 'edit' : 'view-all',
      location: 'grh-providers',
    })
  }

  const handleUpdate = () => {
    const nextMonthProgram = programState.nextMonth
    const checkErrors = {
      transactionLimit: !nextMonthProgram.budget && true,
      uses: !nextMonthProgram.numUses && true,
      rideshare:
        nextMonthProgram.rideshare.activeMerchantIds.length === 0 &&
        activeOptions.nextMonth.rideshare &&
        true,
      localTaxi: false,
    }

    const hasNoErrors = Object.keys(checkErrors).every(
      (key) => !checkErrors[key]
    )

    if (hasNoErrors) {
      dispatch(
        grhActions.updateGrhProgram({
          programId: nextMonthProgram.id,
          budget: nextMonthProgram.budget,
          type: nextMonthProgram.type,
          name: nextMonthProgram.name,
          segment: segmentId,
          startDate: nextMonthProgram.startDate,
          endDate: nextMonthProgram.endDate,
          numUses: nextMonthProgram.numUses,
          requiresRequest: true,
          merchants: activeOptions.nextMonth.rideshare
            ? activeOptions.nextMonth.localTaxi
              ? [
                  ...nextMonthProgram.rideshare.activeMerchantIds,
                  ...availableMerchants.localTaxi.map((m) => m.id),
                ]
              : nextMonthProgram.rideshare.activeMerchantIds
            : [],
        })
      )
      SegmentService.track('programs-action-click', {
        action: 'update',
        programType: 'flex',
        segmentName: segmentsMap[segmentId].name,
        startDate: moment(nextMonthProgram.startDate).format('DD-MM-YYYY'),
        budget: nextMonthProgram.budget,
        rideshare:
          nextMonthProgram.rideshare.activeMerchantIds.length > 0 &&
          activeOptions.nextMonth.rideshare
            ? nextMonthProgram.rideshare.activeMerchantIds.map(
                (id) => merchantsMap[id]?.name
              )
            : false,
        gas: null,
        parking: null,
        transit: null,
        oneTime: null,
        shared: null,
        leasing: null,
        localTaxi:
          (nextMonthProgram.localTaxi.activeMerchantIds.length > 0 &&
            activeOptions.nextMonth.localTaxi) ||
          false,
      })
    } else {
      setErrorState(checkErrors)
      dispatch(
        notificationsActions.generalPageError(
          'Make sure a valid amount of transactions, transaction limit is set and there is at least one service provider'
        )
      )
    }
  }

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false)
  }

  const handleUpdateModalToggle = () => {
    setIsUpdateModalOpen((prevState) => !prevState)
  }

  const handleProgramCancel = (programGroup: 'active' | 'nextMonth') => {
    const nextMonthProgram = programState.nextMonth
    dispatch(grhActions.cancelGrhProgram(programState[programGroup].id))
    SegmentService.track('programs-action-click', {
      action: programGroup === 'active' ? 'cancel' : 'reactivate',
      programType: 'grh',
      segmentName: segmentsMap[segmentId].name,
      startDate: moment(nextMonthProgram.startDate).format('DD-MM-YYYY'),
      budget: nextMonthProgram.budget,
      rideshare:
        nextMonthProgram.rideshare.activeMerchantIds.length > 0 &&
        activeOptions.nextMonth.rideshare
          ? nextMonthProgram.rideshare.activeMerchantIds.map(
              (id) => merchantsMap[id]?.name
            )
          : false,
      localTaxi: false,
      flexProviders: null,
      gas: null,
      parking: null,
      transit: null,
      oneTime: null,
      shared: null,
      leasing: null,
    })
    setIsCancelModalOpen(false)
  }
  const handleReActivate = () => {
    const hasChanges = GRHUtils.programHasChanges(
      programState.active
        ? programState
        : { ...programState, active: pendingProgram.program },
      programState.active
        ? activeOptions
        : { ...activeOptions, active: pendingProgram.activeOptions }
    )

    if (!programState.active || hasChanges) {
      handleUpdate()
    } else {
      handleProgramCancel('nextMonth')
    }
  }

  if (grhProgramsLoaded && !allGrhPrograms[segmentId]) {
    return <Navigate to={Locations.GRH.Programs} replace />
  }

  if (
    !grhProgramsLoaded ||
    (!programState.active && !programState.nextMonth) ||
    !merchantsMap ||
    !availableMerchants
  ) {
    return <Loading fullPage />
  }

  const noActiveOptions = Object.keys(activeOptions.nextMonth).every(
    (option) => !activeOptions.nextMonth[option]
  )
  const transactionLimit = programState[activeState].budget || 0
  const totalUses = programState[activeState].numUses

  return (
    <>
      <StyledColumnView>
        <Column>
          {/* budget and use selector */}
          <BudgetUsesSelector
            canEdit={isEdit}
            budget={transactionLimit}
            onBudgetChange={(newLimit) =>
              setProgramState((prevState) => {
                return {
                  ...prevState,
                  nextMonth: {
                    ...prevState.nextMonth,
                    budget: newLimit ? newLimit * 100 : 0,
                  },
                }
              })
            }
            recBudget={GRHUtils.recTransacLimit}
            uses={totalUses}
            recUses={GRHUtils.recUses}
            onUsesChange={(newUses) =>
              setProgramState((prevState) => {
                return {
                  ...prevState,
                  nextMonth: { ...prevState.nextMonth, numUses: newUses },
                }
              })
            }
            budgetError={errorState.transactionLimit}
            usesError={errorState.uses}
          />
          {/* rideshare option */}
          <ProgramOption
            type='rideshare'
            active={activeOptions[activeState].rideshare}
            activeMerchantIds={
              programState[activeState].rideshare.activeMerchantIds
            }
            availableMerchantIds={
              programState[activeState].rideshare.availableMerchantIds
            }
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.rideshare}
            canEdit={isEdit}
          />
          {/* local taxi options */}
          <ProgramOption
            type='localTaxi'
            active={activeOptions[activeState].localTaxi}
            activeMerchantIds={availableLocalTaxiMerchantIds}
            availableMerchantIds={availableLocalTaxiMerchantIds}
            // activeMerchantIds={
            //   programState[activeState].localTaxi.activeMerchantIds
            // }
            // availableMerchantIds={
            //   programState[activeState].localTaxi.availableMerchantIds
            // }
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.localTaxi}
            canEdit={isEdit}
            readOnly
          />
        </Column>
        <Column>
          {/* manage summary */}
          <GRHManageSummary
            currentSegment={segmentsMap[segmentId]}
            activeOptions={activeOptions[activeState]}
            program={programState[activeState]}
            handleOpenModal={handleOpenModal}
            canEdit={isEdit}
            toggleEdit={toggleEdit}
          />
          {/* manage pending, next year */}
          <GRHManagePending
            isPending={!programState.active && true}
            programState={
              programState.active
                ? programState
                : { ...programState, active: pendingProgram.program }
            }
            activeOptions={
              programState.active
                ? activeOptions
                : { ...activeOptions, active: pendingProgram.activeOptions }
            }
            canEdit={isEdit}
            openUpdateModal={handleUpdateModalToggle}
          />
        </Column>
      </StyledColumnView>
      {/* is edit and footer */}
      {isEdit && (
        <Footer>
          <FooterContainer>
            {programState.nextMonth.status !== 'CANCELLING' && (
              <Button
                variant='cancel'
                onClick={() => {
                  setIsCancelModalOpen(true)
                  SegmentService.track('program-manage-action', {
                    action: 'cancel',
                    programType: 'grh',
                    segmentName: segmentsMap[segmentId].name,
                  })
                }}
              >
                End Program
              </Button>
            )}
            <ButtonContainer>
              <Button
                variant='tertiary'
                marginRight='1rem'
                onClick={() =>
                  programState.active
                    ? toggleEdit()
                    : navigate(`/guaranteed-rides/${segmentId}`)
                }
              >
                Cancel
              </Button>
              <Button
                disabled={noActiveOptions}
                onClick={() =>
                  programState.nextMonth.status === 'CANCELLING'
                    ? handleUpdateModalToggle()
                    : handleUpdate()
                }
              >
                Update Program
              </Button>
            </ButtonContainer>
          </FooterContainer>
        </Footer>
      )}
      {/* services modal */}
      <ServiceProviderSelectorModal
        open={isModalOpen}
        title='Available Service Providers'
        closeModal={() => {
          setIsModalOpen(false)
          SegmentService.track('modes-modal-toggle', {
            action: 'close',
            state: isEdit ? 'edit' : 'view-all',
            location: 'grh-providers',
          })
        }}
        canEdit={isEdit}
        activeProviders={programState[activeState].rideshare.activeMerchantIds}
        availableProviders={
          programState[activeState].rideshare.availableMerchantIds
        }
        onSave={(newActiveMerchantIds: string[]) =>
          handleProviderChange('rideshare', newActiveMerchantIds)
        }
        modeTypeFilters
      />
      {/* program cancel modal */}
      <ProgramModal
        title='Cancel Program'
        description='Are you sure you want to cancel this program? Your commuters will lose
        all access to this programs benefits.'
        buttonText='Cancel Program'
        open={isCancelModalOpen}
        closeModal={handleCancelModalClose}
        buttonCallback={() =>
          handleProgramCancel(programState.active ? 'active' : 'nextMonth')
        }
      />
      {/* program reactivate modal */}
      <ProgramModal
        title='Reactivate Program'
        description='Do you want to reactive this program? All previous benefits will be available again next month.'
        buttonText='Reactivate Program'
        open={isUpdateModalOpen}
        closeModal={handleUpdateModalToggle}
        buttonCallback={handleReActivate}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHManageView.displayName = 'GRHManageView'
}

export default GRHManageView
