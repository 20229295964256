import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import TextField from 'core-system/TextField'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useDispatch } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { Worksite } from 'redux/employer/employerTypes'

interface WorksiteModalProps {
  closeModal: () => void
  worksite: Worksite
  open: boolean
}

const EditWorksiteModal = React.memo((props: WorksiteModalProps) => {
  const dispatch = useDispatch()
  const { worksite, open, closeModal } = props
  const [worksiteName, setWorksiteName] = React.useState<string>(worksite.alias)

  const updateWorksite = React.useCallback(() => {
    const updatedWorksite: Worksite = {
      ...worksite,
      alias: worksiteName,
    }

    dispatch(
      employerActions.updateWorksite({
        id: updatedWorksite.id,
        address: updatedWorksite.address,
        alias: updatedWorksite.alias,
        numEmployees: updatedWorksite.employeeCount,
        numArchivedEmployees: updatedWorksite.employeeArchivedCount,
        numContractors: updatedWorksite.contractorCount,
        formattedAddress: updatedWorksite.formattedAddress,
        cardsActivated: updatedWorksite.cardsActivated,
        shippingDetails: !updatedWorksite.shippingDetails
          ? null
          : {
              shippingType: updatedWorksite.shippingDetails.type,
              shippingAddress: updatedWorksite.shippingDetails.address,
            },
      })
    )
    closeModal()

    SegmentService.track('table-item-click', {
      tableType: 'worksites',
      action: 'update',
      worksiteName: worksiteName,
    })
  }, [dispatch, worksite, worksiteName, closeModal])

  return (
    <Modal open={open} onClose={() => closeModal()} width={pxToRem(450)}>
      <ModalHeader title={'Edit Worksite Information'} />
      <ModalBody>
        <TextField
          label='Office Name'
          defaultValue={worksite.alias}
          onChange={(e) => setWorksiteName(e.currentTarget.value)}
          marginBottom='1rem'
          data-cy='accounts-edit-worksite-office-name-input'
        />
        <TextField
          label='Address'
          defaultValue={worksite.formattedAddress}
          onChange={(e) => setWorksiteName(e.currentTarget.value)}
          marginBottom='1rem'
          disabled={true}
        />
        <TextField
          label='Unit Number'
          defaultValue={worksite.unitNumber || ''}
          marginBottom='1rem'
          disabled={true}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          variant={'tertiary'}
          marginRight={pxToRem(29)}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button onClick={() => updateWorksite()}>Update</Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EditWorksiteModal.displayName = 'EditWorksiteModal'
}

export default EditWorksiteModal
