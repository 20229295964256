import FlexContainer from 'core-system/FlexContainer'
import HorizontalScroller from 'core-system/HorizontalScroller'
import HorizontalScrollerUtils from 'core-system/HorizontalScroller/HorizontalScrollerUtils'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import ServiceProviderSelectorModal from 'core-system/ServiceProvider/ServiceProviderSelectorModal'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { DashboardTopServices } from 'redux/dashboard/dashboardTypes'
import { Employer } from 'redux/employer/employerTypes'
import { CardProgram } from 'redux/programs/programsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-bottom: 1.5rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

const Header = styled.div<{ hasServices: boolean }>`
  padding-bottom: ${(props) => (props.hasServices ? '1.5rem' : 0)};
  align-items: center;
  width: 100%;
  display: flex;
  border-bottom: 1px solid
    ${(props) =>
      props.hasServices ? props.theme.palette.grey.grey3 : 'transparent'};
`

const Value = styled(Text)`
  display: flex;
  margin: 0 0.125rem 0 1rem;
  align-items: flex-end;
  z-index: 5;
`

const HyperLink = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.primaryPurple};

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

interface DashboardEmbProps {
  services: DashboardTopServices
  activatedPrograms: CardProgram[]
  employerProfile: Employer
}

const DashboardEmb = React.memo((props: DashboardEmbProps) => {
  const { services, activatedPrograms, employerProfile } = props

  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )
  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )

  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!merchantsMap || !employerProfile || !services) {
    return <Loading height={pxToRem(253)} marginBottom='1.5rem' />
  }

  const availableProviders = services.map((s) => s.merchantId)
  const customData = services.reduce((agg, s) => {
    agg[s.merchantId] = {
      spend: FormattingUtils.formatDollar(s.spend) || '$0',
      trips: s.numTrips,
    }
    return agg
  }, {})

  const totalBudget = activatedPrograms.reduce(
    (sum, program) => {
      const activeSegment = segmentsMap[program.segment]
      return {
        totalSpend: sum.totalSpend + (parseFloat(program.spend as any) || 0),
        totalBudget:
          sum.totalBudget +
          (!activeSegment || !activeSegment.commuters
            ? program.budget
            : activeSegment.commuters * program.budget),
      }
    },
    { totalSpend: 0, totalBudget: 0 }
  )

  const hasServices = services.length > 0
  return (
    <Container>
      <Content>
        <Text variant='h5' marginBottom='1.5rem'>
          Total Mobility Spend
        </Text>
        <Header hasServices={hasServices}>
          <PlatformSvg
            folder='metrics'
            variant='employeeCostPurple'
            width='40'
            height='40'
          />
          <Value variant='h1'>
            {FormattingUtils.formatDollar(totalBudget.totalSpend, 0)}
          </Value>
        </Header>
        {hasServices && (
          <>
            <FlexContainer
              margin='1rem 0'
              justifyContent='space-between'
              alignItems='center'
            >
              <Text
                variant='caption'
                captionTitle
                textColor={palette.text.secondary}
              >
                Most Used Services
              </Text>
              <HyperLink
                variant='hyperlink'
                onClick={() => {
                  setIsModalOpen(true)
                  SegmentService.track('modes-modal-toggle', {
                    action: 'open',
                    state: 'view-all',
                    location: 'dashboard',
                  })
                }}
              >
                View All
              </HyperLink>
            </FlexContainer>
            <HorizontalScroller translationPerClick={160}>
              {HorizontalScrollerUtils.getProviders(
                availableProviders,
                merchantsMap,
                customData
              )}
            </HorizontalScroller>
          </>
        )}
      </Content>
      <ServiceProviderSelectorModal
        open={isModalOpen}
        title='Most Used Service Providers'
        closeModal={() => {
          setIsModalOpen(false)
          SegmentService.track('modes-modal-toggle', {
            action: 'close',
            state: 'view-all',
            location: 'dashboard',
          })
        }}
        activeProviders={availableProviders}
        availableProviders={availableProviders}
        canEdit={false}
        modeTypeFilters
        customData={customData}
        serviceCardHeight={240}
      />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardEmb.displayName = 'DashboardEmb'
}

export default DashboardEmb
