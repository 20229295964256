import React, { useState } from 'react'
import styled from 'styled-components'
import { OptIn } from 'redux/employeePlatform/employeePlatformTypes'
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'core-system/Modal'
import { useDispatch } from 'react-redux'
import Button from 'core-system/Button'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import Loading from 'core-system/Loading'
import pxToRem from 'core-system/utils/pxToRem'
import Text from 'core-system/Text'
import Radio from 'core-system/Radio'

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`

interface EmployeeOptInChoicesModalProps {
  open: boolean
  closeModal: () => void
  optIn: OptIn | null
}

const EmployeeOptInChoicesModal = React.memo(
  (props: EmployeeOptInChoicesModalProps) => {
    const { open, closeModal, optIn } = props

    const dispatch = useDispatch()

    const [selectedChoice, setSelectedChoice] = useState(null)

    const handleCloseModal = () => {
      setSelectedChoice(null)
      closeModal()
    }

    const handleSubmit = () => {
      dispatch(
        employeePlatformActions.setOptInChoice({
          optInId: optIn.id,
          choice: selectedChoice,
        })
      )
      handleCloseModal()
    }

    if (!optIn) return <Loading />

    return (
      <Modal
        open={open}
        onClose={() => handleCloseModal()}
        width={pxToRem(450)}
      >
        <ModalHeader title='Select a Response' />
        <ModalBody>
          <Text variant='action2'>{optIn.question}</Text>
          <ChoicesContainer>
            {optIn.choices.map((choice, idx) => {
              return (
                <Radio
                  key={idx}
                  label={choice}
                  active={selectedChoice === idx}
                  onClick={() => setSelectedChoice(idx)}
                />
              )
            })}
          </ChoicesContainer>
        </ModalBody>
        <ModalFooter>
          <Button variant='tertiary' onClick={() => handleCloseModal()}>
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()} style={{ marginLeft: '1rem' }}>
            Submit Response
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeOptInChoicesModal.displayName = 'EmployeeOptInChoicesModal'
}

export default EmployeeOptInChoicesModal
