import Button from 'core-system/Button'
import { ColumnCard } from 'core-system/ColumnView'
import SummaryBudget from 'core-system/Program/Summary/SummaryBudget'
import SummaryOverview from 'core-system/Program/Summary/SummaryOverview'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import MicromobilitySummaryOptions from 'features/Micromobility/Shared/components/MicromobilitySummaryOptions'
import MicromobilityUtils from 'features/Micromobility/Shared/MicromobilityUtils'
import moment from 'moment'
import React from 'react'
import { Segment } from 'redux/employer/employerTypes'
import {
  MicromobilityActiveOptions,
  MicromobilityManageOptionState,
  MicromobilityType,
} from 'redux/micromobility/micromobilityTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'

const Container = styled(ColumnCard)`
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.25rem 0;
`

interface MicromobilityManageSummaryProps {
  toggleEdit: () => void
  canEdit: boolean
  program: MicromobilityManageOptionState
  currentSegment: Segment
  activeOptions: MicromobilityActiveOptions
  handleOpenModal: (type: MicromobilityType) => void
}

const MicromobilityManageSummary = React.memo(
  (props: MicromobilityManageSummaryProps) => {
    const {
      toggleEdit,
      canEdit,
      program,
      currentSegment,
      activeOptions,
      handleOpenModal,
    } = props

    return (
      <Container>
        <Text variant='h4' marginBottom='1.5rem'>
          {canEdit ? "Next Month's Program" : 'Current Program'}
        </Text>
        <SummaryOverview
          startDate={moment(program.startDate, 'YYYY-MM-DD').format(
            'MMMM Do, YYYY'
          )}
          currentSegment={currentSegment}
          marginBottom='1rem'
        >
          {program.oneTimeMerchants.activeMerchantIds.length > 0 && !canEdit && (
            <>
              <Text
                variant='caption'
                textColor={palette.text.secondary}
                captionTitle
                marginTop='1rem'
              >
                One-Time Purchase
              </Text>
              <FlexContainer>
                <Text variant='action4'>
                  Max Budget ({MicromobilityUtils.monthsLeft} Months)
                </Text>
                <Text variant='action4'>
                  {FormattingUtils.formatDollar(
                    MicromobilityUtils.monthsLeft * program.budget,
                    0
                  )}
                </Text>
              </FlexContainer>
              <Text variant='action4'>Resets Annually</Text>
            </>
          )}
        </SummaryOverview>
        {canEdit && (
          <MicromobilitySummaryOptions
            activeOptions={activeOptions}
            activeLeasingMerchantIds={
              program.leasingMerchants.activeMerchantIds
            }
            availableLeasingMerchantIds={
              program.leasingMerchants.availableMerchantIds
            }
            activeSharedMerchantIds={program.sharedMerchants.activeMerchantIds}
            availableSharedMerchantIds={
              program.sharedMerchants.availableMerchantIds
            }
            activeOneTimeMerchantIds={
              program.oneTimeMerchants.activeMerchantIds
            }
            availableOneTimeMerchantIds={
              program.oneTimeMerchants.availableMerchantIds
            }
            budget={program.budget}
            handleOpenModal={handleOpenModal}
          />
        )}
        <SummaryBudget
          title='Total Monthly Budget'
          metric='per commuter'
          numEmps={currentSegment.commuters}
          budget={program.budget}
          marginBottom='1.5rem'
        />
        <Text
          variant='body2'
          textColor={palette.text.placeholder}
          marginBottom={!canEdit && '1.5rem'}
        >
          All budget amounts are estimated based on max commuter spend.
        </Text>
        {!canEdit && (
          <Button width='100%' onClick={toggleEdit}>
            Edit Next Month&apos;s Program
          </Button>
        )}
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityManageSummary.displayName = 'MicromobilityManageSummary'
}

export default MicromobilityManageSummary
