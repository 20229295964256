import { AxiosResponse } from 'axios'
import Request from '../config/services/Request'

// ---- Programs ----
const getAllPrograms = () => {
  return Request.get(`/hr/programs/card_programs/`)
}

// ---- Transportation Service Providers ----
const getAllServiceProviders = (): Promise<AxiosResponse> => {
  return Request.get(`/hr/programs/modes/`)
}

const getMerchants = (): Promise<AxiosResponse> => {
  return Request.get(`/hr/programs/merchants/`)
}

export default {
  getAllPrograms,
  getAllServiceProviders,
  getMerchants,
}
