import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import FlexContainer from 'core-system/FlexContainer'
import Loading from 'core-system/Loading'
import * as Program from 'core-system/Program'
import ProgramOption from 'core-system/Program/ProgramOption'
import ProgramPriceCoverage from 'core-system/Program/ProgramPriceCoverage'
import SegmentDropdownUtils from 'core-system/SegmentDropdown/SegmentDropdownUtils'
import ServiceProviderSelectorModal from 'core-system/ServiceProvider/ServiceProviderSelectorModal'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { micromobilityActions } from 'redux/micromobility/micromobilitySlice'
import {
  MicromobilityActiveOptions,
  MicromobilityType,
} from 'redux/micromobility/micromobilityTypes'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import DateUtils from 'shared/DateUtils'
import useQueryParam from 'shared/Hooks/useQueryParam'
import MicromobilityLeasingTrips from '../Shared/components/MicromobilityLeasingTrips'
import MicromobilityOneTimePurchase from '../Shared/components/MicromobilityOneTimePurchase'
import MicromobilityUtils from '../Shared/MicromobilityUtils'
import MicromobilityActivationSummary from './components/MicromobilityActivationSummary'
import FormattingUtils from 'shared/FormattingUtils'

const defaultActiveOptions = {
  shared: true,
  leasing: true,
  oneTime: true,
}

const nextMonth = () => {
  return {
    val: 'next-month',
    text: DateUtils.getNextXMonths(1, 1)[0].text,
  }
}

const getRelevantMerchantIds = (
  activeOption: MicromobilityType,
  leasingIds: string[],
  sharedIds: string[],
  oneTimeIds: string[]
) => {
  if (activeOption === 'leasing') return leasingIds
  else if (activeOption === 'shared') return sharedIds
  else if (activeOption === 'oneTime') return oneTimeIds
  else return []
}

type LocationState = {
  from: string
  activeOptions: MicromobilityActiveOptions
}

const MicromobilityActivationView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const state = location.state as LocationState

  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )
  const allSegments = useSelector(
    (state: AppState) => state.employer.allSegments
  )
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )
  const allPrograms = useSelector(
    (state: AppState) => state.micromobility.micromobilityPrograms
  )
  const availableMerchants = useSelector(
    (state: AppState) => state.micromobility.availableMerchants
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeOption, setActiveOption] = useState<MicromobilityType>('shared')
  const [startDate, setStartDate] = useState(nextMonth)
  const [budget, setBudget] = useState(MicromobilityUtils.recBudget)
  const [errorState, setErrorState] = useState(
    MicromobilityUtils.defaultErrorState
  )
  const [monthsLeft, setMonthsLeft] = useState(6)
  const [activeOptions, setActiveOptions] = useState(
    state && state.activeOptions ? state.activeOptions : defaultActiveOptions
  )

  const [availableLeasingMerchantIds, setAvailableLeasingMerchantIds] =
    useState<string[]>([])
  const [activeLeasingMerchantIds, setActiveLeasingMerchantIds] = useState<
    string[]
  >([])

  const [availableOneTimeMerchantIds, setAvailableOneTimeMerchantIds] =
    useState<string[]>([])
  const [activeOneTimeMerchantIds, setActiveOneTimeMerchantIds] = useState<
    string[]
  >([])

  const [availableSharedMerchantIds, setAvailableSharedMerchantIds] = useState<
    string[]
  >([])
  const [activeSharedMerchantIds, setActiveSharedMerchantIds] = useState<
    string[]
  >([])

  useEffect(() => {
    dispatch(
      micromobilityActions.getMicromobilityRecommendations({
        segmentId,
      })
    )
  }, [dispatch, segmentId])

  useEffect(() => {
    if (availableMerchants && merchantsMap) {
      const leasingMerchantIds = availableMerchants.leasing.map((m) => m.id)
      const oneTimeMerchantIds = availableMerchants.oneTime.map((m) => m.id)
      const sharedMerchantIds = availableMerchants.shared.map((m) => m.id)

      setAvailableLeasingMerchantIds(leasingMerchantIds)
      setActiveLeasingMerchantIds(leasingMerchantIds)

      setAvailableOneTimeMerchantIds(oneTimeMerchantIds)
      setActiveOneTimeMerchantIds(oneTimeMerchantIds)

      setAvailableSharedMerchantIds(sharedMerchantIds)
      setActiveSharedMerchantIds(sharedMerchantIds)
    }
  }, [availableMerchants, merchantsMap])

  const handleOptionToggle = (type: string) => {
    setActiveOptions((prevState) => {
      return {
        ...prevState,
        [type]: !prevState[type],
      }
    })
  }

  const handleActivate = () => {
    const checkErrors = {
      budget: !budget && true,
      leasing:
        availableLeasingMerchantIds.length > 0 &&
        activeLeasingMerchantIds.length === 0 &&
        activeOptions.leasing &&
        true,
      shared:
        availableSharedMerchantIds.length > 0 &&
        activeSharedMerchantIds.length === 0 &&
        activeOptions.shared &&
        true,
      oneTime:
        availableOneTimeMerchantIds.length > 0 &&
        activeOneTimeMerchantIds.length === 0 &&
        activeOptions.oneTime &&
        true,
    }

    const hasNoErrors = Object.keys(checkErrors).every(
      (key) => !checkErrors[key]
    )

    if (hasNoErrors) {
      const startDateFormatted = moment(startDate.text, 'MMMM Do YYYY').format()
      dispatch(
        micromobilityActions.createMicromobilityProgram({
          startDate: startDateFormatted,
          endDate: null,
          segment: segmentId,
          name: 'micromobility',
          type: 'MICROMOBILITY',
          budget: budget,
          merchants: [
            ...Array.from(
              new Set([
                ...activeLeasingMerchantIds,
                ...activeSharedMerchantIds,
                ...activeOneTimeMerchantIds,
              ])
            ),
          ],
          yearlyPool: activeOptions.oneTime,
          leasing: activeOptions.leasing,
          shared: activeOptions.shared,
        })
      )
      SegmentService.track('programs-action-click', {
        action: 'activate',
        programType: 'micromobility',
        segmentName: segmentsMap[segmentId].name,
        startDate: moment(startDateFormatted).format('DD-MM-YYYY'),
        budget: budget,
        oneTime:
          activeOneTimeMerchantIds.length > 0 && activeOptions.oneTime
            ? activeOneTimeMerchantIds.map((m) => merchantsMap[m]?.name)
            : false,
        shared:
          activeSharedMerchantIds.length > 0 && activeOptions.shared
            ? activeSharedMerchantIds.map((m) => merchantsMap[m]?.name)
            : false,
        leasing:
          activeLeasingMerchantIds.length > 0 && activeOptions.leasing
            ? activeLeasingMerchantIds.map((m) => merchantsMap[m]?.name)
            : false,
        yearlyPool: activeOptions.oneTime,
      })
    } else {
      setErrorState(checkErrors)
      dispatch(
        notificationsActions.generalPageError(
          'Make sure a valid budget exists and there is at least one service provider selected for each active option'
        )
      )
    }
  }

  const handleMerchantIdChange = (
    type: MicromobilityType,
    newActiveMerchantIds: string[]
  ) => {
    if (type === 'leasing') {
      setActiveLeasingMerchantIds(newActiveMerchantIds)
    } else if (type === 'shared') {
      setActiveSharedMerchantIds(newActiveMerchantIds)
    } else if (type === 'oneTime') {
      setActiveOneTimeMerchantIds(newActiveMerchantIds)
    }
  }

  const handleDateChange = (val: string, text: string, initial: boolean) => {
    setStartDate(initial ? nextMonth : { val, text })
    setMonthsLeft(
      13 -
        parseInt(
          initial
            ? moment().add(1, 'month').format('M')
            : moment(val).format('M')
        )
    )
  }

  const handleOpenModal = (type: string) => {
    setActiveOption(type as MicromobilityType)
    setIsModalOpen(true)
    SegmentService.track('modes-modal-toggle', {
      action: 'open',
      state: 'edit',
      location: type,
    })
  }

  const handleSegmentChange = (newSegmentId: string) => {
    if (newSegmentId !== segmentId) {
      navigate(`/micromobility/${newSegmentId}/activation`)
      SegmentService.track('baseOptions-toggle-action', {
        actionType: 'segment-name',
      })
    }
  }

  if (!availableMerchants || !merchantsMap || !allPrograms || !segmentsMap) {
    return <Loading fullPage />
  }

  if (allPrograms[segmentId]) {
    return <Navigate to={`/micromobility/${segmentId}`} replace />
  }

  const noActiveOptions = Object.keys(activeOptions).every(
    (option) => !activeOptions[option]
  )

  const leaseCoverage = Math.min(
    Math.round(
      ((budget ? budget : 0) / MicromobilityUtils.avgMonthlyLeasing) * 100
    ),
    100
  )
  const annualCoverage = Math.min(
    Math.round(
      (((budget ? budget : 0) * monthsLeft) /
        MicromobilityUtils.avgAnnualLeasing) *
        100
    ),
    100
  )
  return (
    <>
      <ColumnView defaultView>
        <Column>
          <Program.SegmentAndDateConfig
            currentSegment={segmentsMap[segmentId]}
            dropdownItems={SegmentDropdownUtils.segmentDropdownItems(
              allSegments,
              allPrograms
            )}
            onDateChange={handleDateChange}
            onSegmentChange={handleSegmentChange}
          />
          <Program.BudgetSelector
            budget={budget}
            recBudget={MicromobilityUtils.recBudget}
            onChange={(newBudget) => setBudget(newBudget * 100)}
            error={errorState.budget}
          />
          <ProgramOption
            type='shared'
            active={activeOptions.shared}
            activeMerchantIds={activeSharedMerchantIds}
            availableMerchantIds={availableSharedMerchantIds}
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.shared}
          >
            <MicromobilityLeasingTrips budget={budget} />
          </ProgramOption>
          <ProgramOption
            type='leasing'
            active={activeOptions.leasing}
            activeMerchantIds={activeLeasingMerchantIds}
            availableMerchantIds={availableLeasingMerchantIds}
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.leasing}
          >
            <ProgramPriceCoverage
              percentage={leaseCoverage}
              title='Monthly Membership Price Covered'
              description={`Your budget will cover ${leaseCoverage}% of the average monthly
                  membership price (${FormattingUtils.formatDollar(
                    MicromobilityUtils.avgMonthlyLeasing,
                    0
                  )}) for your commuters.`}
            />
          </ProgramOption>
          <ProgramOption
            type='oneTime'
            active={activeOptions.oneTime}
            activeMerchantIds={activeOneTimeMerchantIds}
            availableMerchantIds={availableOneTimeMerchantIds}
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.oneTime}
          >
            <MicromobilityOneTimePurchase
              percentage={annualCoverage}
              budget={budget}
            />
          </ProgramOption>
        </Column>
        <Column>
          <MicromobilityActivationSummary
            startDate={startDate.text}
            currentSegment={segmentsMap[segmentId]}
            budget={budget}
            handleOpenModal={handleOpenModal}
            activeOptions={activeOptions}
            activeLeasingMerchantIds={activeLeasingMerchantIds}
            availableLeasingMerchantIds={availableLeasingMerchantIds}
            activeSharedMerchantIds={activeSharedMerchantIds}
            availableSharedMerchantIds={availableSharedMerchantIds}
            activeOneTimeMerchantIds={activeOneTimeMerchantIds}
            availableOneTimeMerchantIds={availableOneTimeMerchantIds}
          />
        </Column>
      </ColumnView>
      <Program.Footer>
        <FlexContainer marginLeft='auto'>
          <Button
            variant='tertiary'
            marginRight='1rem'
            onClick={() => navigate(`/micromobility/${segmentId}`)}
          >
            Cancel
          </Button>
          <Button disabled={noActiveOptions} onClick={handleActivate}>
            Activate
          </Button>
        </FlexContainer>
      </Program.Footer>
      <ServiceProviderSelectorModal
        open={isModalOpen}
        title={`${MicromobilityUtils.optionsCopy[activeOption].title} Service Providers`}
        closeModal={() => {
          setIsModalOpen(false)
          SegmentService.track('modes-modal-toggle', {
            action: 'close',
            state: 'edit',
            location: activeOption,
          })
        }}
        activeProviders={getRelevantMerchantIds(
          activeOption,
          activeLeasingMerchantIds,
          activeSharedMerchantIds,
          activeOneTimeMerchantIds
        )}
        availableProviders={getRelevantMerchantIds(
          activeOption,
          availableLeasingMerchantIds,
          availableSharedMerchantIds,
          availableOneTimeMerchantIds
        )}
        onSave={(newActiveMerchantIds: string[]) =>
          handleMerchantIdChange(activeOption, newActiveMerchantIds)
        }
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityActivationView.displayName = 'MicromobilityActivationView'
}

export default MicromobilityActivationView
