import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { Announcement } from 'redux/concierge/conciergeTypes'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import Button from 'core-system/Button'
import FlexContainer from 'core-system/FlexContainer'
import Loading from 'core-system/Loading'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import { getAnnouncementCopy } from '../shared/utils'

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
  width: 100%;
`

interface DetailFieldProps {
  label: string
  text?: string
}
const DetailField = React.memo((props: DetailFieldProps) => {
  const { label, text = 'N/A' } = props
  return (
    <DetailContainer>
      <Text
        variant='caption'
        captionTitle
        style={{
          paddingBottom: '0.375rem',
          color: palette.text.secondary,
        }}
      >
        {label}
      </Text>
      <Text
        variant='action4'
        style={{
          whiteSpace: 'pre-wrap',
        }}
      >
        {text}
      </Text>
    </DetailContainer>
  )
})
DetailField.displayName = 'DetailField'

interface AnnouncementDetailsModalProps {
  isModalOpen: boolean
  setIsModalOpen: any
  announcement: Announcement
}
const AnnouncementDetailsModal = (props: AnnouncementDetailsModalProps) => {
  const { isModalOpen, setIsModalOpen, announcement } = props
  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  const now = moment()

  if (!announcement)
    return (
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        width={pxToRem(600)}
      >
        <ModalHeader title='Announcement Details' />
        <Loading />
      </Modal>
    )

  const { announcementBody, announcementTitle, announcementTime } =
    getAnnouncementCopy(announcement)

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
      }}
      width={pxToRem(600)}
    >
      <ModalHeader title='Announcement Details' />
      {!announcement ? (
        <Loading />
      ) : (
        <>
          <ModalBody padding='1.5rem 3.25rem'>
            <FlexContainer flexDirection='column' style={{ gap: '1.5rem' }}>
              <DetailField label='Title' text={announcementTitle} />
              <DetailField label='Description' text={announcementBody} />
              <FlexContainer>
                <DetailField
                  label='Group'
                  text={segmentsMap[announcement.segment]?.name}
                />
                <DetailField
                  label={`Date ${
                    now.isSameOrAfter(announcementTime) ? 'Sent' : 'Scheduled'
                  }`}
                  text={`${moment(announcementTime).format(
                    'MMMM D, YYYY'
                  )} at ${moment(announcementTime).format('h:mm a')}`}
                />
              </FlexContainer>
              {/* <DetailField
                label='Distribution Platforms'
                text={RandomUtils.arrayToString(
                  announcement.distributionPlatforms
                )}
              /> */}
            </FlexContainer>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setIsModalOpen(false)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}

export default AnnouncementDetailsModal
