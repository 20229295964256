export function convertPxStringToNumber(px: string): number {
  return parseInt(px, 10)
}

/**
 * Convert px value to rem value based on default font size
 * @param px string or number pixel representation
 * @param defaultSize default font size on page
 */
export default function (px: string | number, defaultSize = 16): string {
  const isString = typeof px === 'string'

  const pxSize = isString
    ? convertPxStringToNumber(px as string)
    : (px as number)

  return `${pxSize / defaultSize}rem`
}
