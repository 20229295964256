export default {
  One: '#F4F4F8',
  Two: '#E9E9F1',
  Three: '#E3E3EC',
  Four: '#C7C7D2',
  Five: '#9191AC',
  Six: '#B8B8CC',
  Seven: '#08001A',
  Eight: '#74747B',
  Nine: '#D9D9E3',
  Ten: '#777777',
}
