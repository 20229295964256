import React, { useCallback } from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import Text from 'core-system/Text'
import FlexContainer from 'core-system/FlexContainer'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import QTFAddUsersModal from './QTFAddUsersModal'
import { Employee } from 'redux/employee/employeeTypes'
import palette from 'core-system/Themes/palette'
import InfoIcon from 'core-system/Icons/Misc/Info'
import SvgTooltip from 'core-system/Tooltip/SvgTooltip'

const Container = styled.div<SpaceProps>`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;

  ${space}
`

const UsersHeader = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.placeholder};
  display: flex;
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
`
const Name = styled.div`
  flex 1;
`
const Email = styled.div`
  flex: 2;
  align-self: center;
`
const UserRowsContainer = styled.div`
  margin-bottom: 1rem;
`

const HyperLink = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.primaryPurple};

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

interface QTFProgramCommsProps {
  selectedUserIds: string[]
  canEdit: boolean
  onUserIdsChange: (ids: string[]) => void
}

const QTFProgramComms = React.memo((props: QTFProgramCommsProps) => {
  const { selectedUserIds, canEdit, onUserIdsChange } = props
  const allUsers = useSelector((state: AppState) => state.employee.list)
  const currentUser = useSelector((state: AppState) => state.user.profile)
  const [openAddUsersModal, setAddUserModal] = React.useState(false)

  const combinedAllUsers = allUsers.some((u) => u.id === currentUser.id)
    ? allUsers
    : ([...allUsers, ...[currentUser]] as Employee[])

  const groupedUsers = combinedAllUsers.reduce((agg, curr) => {
    agg[curr.id] = agg[curr.id] || {}
    agg[curr.id] = curr
    return agg
  }, {})

  const handleModalToggle = useCallback(() => {
    setAddUserModal(!openAddUsersModal)
  }, [openAddUsersModal, setAddUserModal])

  const selectedUserIdsSet = selectedUserIds ? new Set(selectedUserIds) : null

  return (
    <>
      <Container>
        <FlexContainer alignItems='center' justifyContent='space-between'>
          <Text variant='h4'>Emails & Reminders</Text>
          <SvgTooltip
            svg={
              <InfoIcon width={20} height={20} color={palette.text.secondary} />
            }
            height='1.25rem'
            isMultiLine={true}
            description={
              'Users can be added/removed at anytime regardless of changes to the program'
            }
          />
        </FlexContainer>
        <Text variant='body1' margin='1.5rem 0'>
          Please select users who should receive program updates, reminders, and
          commuter allocation reports:
        </Text>
        <UsersHeader>
          <Name>Name</Name>
          <Email>Email</Email>
          {canEdit && (
            <HyperLink variant='hyperlink' onClick={handleModalToggle}>
              Edit
            </HyperLink>
          )}
        </UsersHeader>
        <UserRowsContainer>
          {selectedUserIdsSet &&
            Array.from(selectedUserIdsSet) &&
            Array.from(selectedUserIdsSet).map(
              (userId: string, idx: number) => {
                const user = groupedUsers[userId]
                return user ? (
                  <FlexContainer key={`${userId}-${idx}`} paddingY='0.5rem'>
                    <Name>
                      <Text variant='action4'>
                        {user.firstName + ' ' + user.lastName}
                      </Text>
                    </Name>
                    <Email>
                      <Text variant='action4'>{user.email}</Text>
                    </Email>
                  </FlexContainer>
                ) : null
              }
            )}
        </UserRowsContainer>
      </Container>
      <QTFAddUsersModal
        open={openAddUsersModal}
        closeModal={handleModalToggle}
        allUsers={combinedAllUsers}
        selectedUserIds={selectedUserIds}
        buttonCallback={(ids) => onUserIdsChange(ids)}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFProgramComms.displayName = 'QTFProgramComms'
}

export default QTFProgramComms
