import Button from 'core-system/Button'
import Modal, { ModalBody, ModalFooter } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import Text from 'core-system/Text'
import { useDispatch } from 'react-redux'
import { userActions } from 'redux/user/userSlice'

interface QTFOnboardingSkipModalProps {
  open: boolean
  closeModal: () => void
}

const QTFOnboardingSkipModal = React.memo(
  (props: QTFOnboardingSkipModalProps) => {
    const { open, closeModal } = props

    const dispatch = useDispatch()

    const handleNext = () => {
      dispatch(userActions.logout())
    }

    const handleClose = () => {
      closeModal()
    }

    return (
      <Modal open={open} onClose={handleClose} width={pxToRem(600)}>
        <ModalBody marginY='1rem'>
          <Text variant='h2' marginBottom='1.5rem' fontSize='32px'>
            Syncing Your Data
          </Text>
          <Text variant='action2'>
            Because of your HR provider type, we need to sync your data. This
            usually takes less than a week. Once it&apos;s done, we&apos;ll
            email you so you can finalize giving your employees access to
            fantastic commuter benefits.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button marginLeft='1rem' onClick={handleNext}>
            Log Out
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFOnboardingSkipModal.displayName = 'QTFOnboardingSkipModal'
}

export default QTFOnboardingSkipModal
