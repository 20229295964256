import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M19 5.5C19 4.4 18.1 3.5 17 3.5H14V5.5H17V8.15L13.52 12.5H10V7.5H6C3.79 7.5 2 9.29 2 11.5V14.5H4C4 16.16 5.34 17.5 7 17.5C8.66 17.5 10 16.16 10 14.5H14.48L19 8.85V5.5ZM7 15.5C6.45 15.5 6 15.05 6 14.5H8C8 15.05 7.55 15.5 7 15.5Z' />
    <path d='M10 4.5H5V6.5H10V4.5Z' />
    <path d='M19 11.5C17.34 11.5 16 12.84 16 14.5C16 16.16 17.34 17.5 19 17.5C20.66 17.5 22 16.16 22 14.5C22 12.84 20.66 11.5 19 11.5ZM19 15.5C18.45 15.5 18 15.05 18 14.5C18 13.95 18.45 13.5 19 13.5C19.55 13.5 20 13.95 20 14.5C20 15.05 19.55 15.5 19 15.5Z' />
    <path d='M7 20.5H11V18.5L17 21.5H13V23.5L7 20.5Z' />
  </>,
  'Electric Moped'
)
