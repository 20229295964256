import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  height: 100vh;
  width: 100wv;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
`

const NotSupported = () => {
  return (
    <Container>
      Sorry, we don&apos;t support this browser. Please use a newer browser like
      Google Chrome, Mozilla Firefox, Microsoft Edge, Apple Safari
    </Container>
  )
}
// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NotSupported.displayName = 'NotSupported'
}

export default React.memo(NotSupported)
