import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M11.75 5C12.4404 5 13 5.55964 13 6.25C13 6.94036 12.4404 7.5 11.75 7.5C11.0596 7.5 10.5 6.94036 10.5 6.25C10.5 5.55964 11.0596 5 11.75 5Z' />
    <path d='M13 12.25C13 11.5596 12.4404 11 11.75 11C11.0596 11 10.5 11.5596 10.5 12.25C10.5 12.9404 11.0596 13.5 11.75 13.5C12.4404 13.5 13 12.9404 13 12.25Z' />
    <path d='M13 18.25C13 17.5596 12.4404 17 11.75 17C11.0596 17 10.5 17.5596 10.5 18.25C10.5 18.9404 11.0596 19.5 11.75 19.5C12.4404 19.5 13 18.9404 13 18.25Z' />
  </>,
  'Overflow'
)
