import Button from 'core-system/Button'
import ChipDifference from 'core-system/Chip/ChipDifference'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import FormattingUtils from 'shared/FormattingUtils'
import NoPaymentModal from 'shared/components/NoPaymentModal'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  display: flex;
  flex-direction: column;
`

const MetricContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
`

const Value = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

const OverflowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  overflow: hidden;
`

const NoIncentives = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledButton = styled(Button)`
  margin-top: auto;
  width: 100%;
`

interface TripsRewardsProps {
  rewardsGained: number
  rewardsPercentDiff: number
  rewardsSpent: number
  isLoading: boolean
  activeSegmentId: string
}

const TripsRewards = React.memo((props: TripsRewardsProps) => {
  const {
    rewardsGained,
    rewardsPercentDiff,
    rewardsSpent,
    isLoading,
    activeSegmentId,
  } = props
  const navigate = useNavigate()

  const { allIncentives, incentivesLoaded } = useSelector(
    (state: AppState) => state.incentives
  )
  const { segmentsMap, hasValidPaymentMethod, profile } = useSelector(
    (state: AppState) => state.employer
  )

  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)

  const activeIncentives = allIncentives[activeSegmentId]

  const handleIncentivesClick = () => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      navigate(`/trips/${activeSegmentId}/incentives/activation`, {
        state: { from: `trips/${activeSegmentId}` },
      })
      SegmentService.track('incentive-manage-action', {
        action: 'add',
        segmentName: segmentsMap[activeSegmentId].name,
      })
    } else {
      setIsNoPaymentModalOpen(true)
    }
  }

  if (isLoading || !incentivesLoaded) {
    return <Loading height={pxToRem(224)} spinnerSize={45} />
  }

  return (
    <Container>
      <Text variant='h5' marginBottom='1.5rem'>
        Incentives
      </Text>
      {!activeIncentives ? (
        <NoIncentives>
          <Text variant='h3' padding='0.25rem 0'>
            No Incentives Running
          </Text>
          <StyledButton size='small' onClick={() => handleIncentivesClick()}>
            New Incentives
          </StyledButton>
          <NoPaymentModal
            open={isNoPaymentModalOpen}
            closeModal={() => setIsNoPaymentModalOpen(false)}
            product='incentives'
          />
        </NoIncentives>
      ) : (
        <>
          <MetricContainer>
            <Value>
              <OverflowContainer>
                <Text variant='h3'>
                  {FormattingUtils.formatDollar(rewardsGained)}
                </Text>
              </OverflowContainer>
            </Value>
            <ChipDifference percentDifference={rewardsPercentDiff} />
          </MetricContainer>
          <Text
            variant='caption'
            marginBottom='1.75rem'
            captionTitle
            textColor={palette.text.secondary}
          >
            Rewarded This Month
          </Text>
          <MetricContainer>
            <Text variant='h3'>
              {FormattingUtils.formatDollar(rewardsSpent)}
            </Text>
          </MetricContainer>
          <Text
            variant='caption'
            captionTitle
            textColor={palette.text.secondary}
          >
            Rewards Spent This Month
          </Text>
        </>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TripsRewards.displayName = 'TripsRewards'
}

export default TripsRewards
