import React from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'

const CollapsibleListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const CollapsibleListHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`

const ChevronIconContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  transform: rotate(${(props) => (props.isOpen ? '0deg' : '-90deg')});

  &:hover {
    cursor: pointer;
    transform: rotate(${(props) => (props.isOpen ? '-90deg' : '0deg')});
  }
`

const CollapsibleListContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

interface CollapsibleListProps {
  title: string
  isOpen: boolean
  onClick: () => void
  children: React.ReactNode
}

const CollapsibleList = React.memo((props: CollapsibleListProps) => {
  const { title, isOpen, onClick, children } = props

  return (
    <CollapsibleListContainer>
      <CollapsibleListHeaderContainer onClick={() => onClick()}>
        <Text variant='action3' hideOverflow>
          {title}
        </Text>
        <ChevronIconContainer isOpen={isOpen}>
          <ChevronIcon />
        </ChevronIconContainer>
      </CollapsibleListHeaderContainer>
      {isOpen ? (
        <CollapsibleListContentsContainer>
          {children}
        </CollapsibleListContentsContainer>
      ) : null}
    </CollapsibleListContainer>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CollapsibleList.displayName = 'CollapsibleList'
}

export default CollapsibleList
