import { AnyAction } from '@reduxjs/toolkit'
import { all, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { optInActions } from './optInSlice'
import OptInService from './optInService'
import { userActions } from 'redux/user/userSlice'

export function* getAllOptIns(action: AnyAction) {
  yield defaultFlow(
    action,
    OptInService.getAllOptIns,
    optInActions.getAllOptInsSuccess
  )
}

export function* createOptIn(action: AnyAction) {
  yield defaultFlow(
    action,
    OptInService.createOptIn,
    optInActions.createOptInSuccess
  )
}

export function* updateEmployeeOptIns(action: AnyAction) {
  yield defaultFlow(
    action,
    OptInService.updateEmployeeOptIns,
    optInActions.updateEmployeeOptInsSuccess
  )
}

/**************************************
 * Watchers
 **************************************/

export function* watchGetAllOptIns() {
  yield takeEvery(
    [
      optInActions.getAllOptIns.type,
      userActions.getUserSuccess.type,
      optInActions.updateEmployeeOptInsSuccess.type,
    ],
    getAllOptIns
  )
}

export function* watchCreateOptIn() {
  yield takeEvery([optInActions.createOptIn.type], createOptIn)
}

export function* watchUpdateEmployeeOptIns() {
  yield takeEvery(
    [optInActions.updateEmployeeOptIns.type],
    updateEmployeeOptIns
  )
}

export default function* () {
  yield all([
    watchGetAllOptIns(),
    watchCreateOptIn(),
    watchUpdateEmployeeOptIns(),
  ])
}
