import React, { useState } from 'react'
import {
  MyCarpoolGroup,
  CarpoolRequest,
  CarpoolMember,
  CarpoolSearchRequestPayload,
  SearchedCarpoolGroup,
} from 'redux/employeePlatform/employeePlatformTypes'
import Divider from 'core-system/Divider'
import Dropdown from 'core-system/Dropdown'
import styled from 'styled-components'
import { rangeBoundOptions } from 'employee-platform/shared/utils'
import FlexContainer from 'core-system/FlexContainer'
import Button from 'core-system/Button'
import Text from 'core-system/Text'
import { useDispatch } from 'react-redux'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import palette from 'core-system/Themes/palette'
import CarpoolGroupCard from './CarpoolGroupCard'
import TimeSelector from 'employee-platform/shared/components/TimeSelector'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import { getCarpoolRelation } from '../CarpoolUtils'
import { CardListContainer } from 'employee-platform/shared/styles/CardList'
import Loading from 'core-system/Loading'
import AddressAutocompleteField, {
  AutocompleteResult,
} from 'core-system/Map/AddressAutocomplete/AddressAutocompleteField'

const DEFAULT_SEARCH_QUERY = {
  source: null,
  sourceBound: rangeBoundOptions[0].id,
  dest: null,
  destBound: rangeBoundOptions[0].id,
  workArrivalTime: null,
  workDepartureTime: null,
}

const SearchFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const AddressField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

const TimeBoundField = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

interface TimeBound {
  start: string | null
  end: string | null
}

const renderCarpoolCards = (
  isMobile: boolean,
  groups: { [id: string]: SearchedCarpoolGroup },
  myCarpoolGroups: { [id: string]: MyCarpoolGroup },
  carpoolMemberships: { [id: string]: CarpoolMember },
  sentCarpoolRequests: { [id: string]: CarpoolRequest },
  setSelectedCarpool: (groupId: string) => void,
  setShowRequestModal: (show: boolean) => void
) => {
  if (!groups || Object.keys(groups).length === 0) {
    return <NoDataText text='NO MATCHING CARPOOL GROUPS' />
  }

  return Object.values(groups).map((group: SearchedCarpoolGroup) => {
    // Don't want to show carpools that you are already a member of
    if (
      carpoolMemberships &&
      Object.keys(carpoolMemberships).length > 0 &&
      carpoolMemberships[group.id]
    ) {
      return null
    }

    const carpoolRelation = getCarpoolRelation(
      myCarpoolGroups,
      carpoolMemberships,
      sentCarpoolRequests,
      group.id
    )
    return (
      <CarpoolGroupCard
        key={group.id}
        carpoolRelation={carpoolRelation}
        groupData={group}
        isMobile={isMobile}
        setSelectedCarpool={setSelectedCarpool}
        distanceFromHome={group.distanceSource}
        distanceFromWork={group.distanceDest}
        setShowRequestModal={setShowRequestModal}
      />
    )
  })
}

interface CarpoolSearchProps {
  // onViewDetailsClick: (groupId: string) => void
  // selectedCarpool: string | null
  isMobile: boolean
  setSelectedCarpool: (groupId: string) => void
  setShowRequestModal: (show: boolean) => void
  setSource: (source: AutocompleteResult | null) => void
  setDestination: (destination: AutocompleteResult | null) => void
  sourceAddress: string
  setSourceAddress: (address: string) => void
  destAddress: string
  setDestAddress: (address: string) => void
  hasSearched: boolean
  setHasSearched: (hasSearched: boolean) => void
}

const CarpoolSearch = React.memo((props: CarpoolSearchProps) => {
  const {
    // selectedCarpool,
    isMobile,
    setSelectedCarpool,
    setShowRequestModal,
    setSource,
    setDestination,
    sourceAddress,
    setSourceAddress,
    destAddress,
    setDestAddress,
    hasSearched,
    setHasSearched,
  } = props

  const {
    allCarpoolGroups,
    myCarpoolGroups,
    carpoolMemberships,
    sentCarpoolRequests,
    searchedCarpoolGroups,
  } = useSelector((state: AppState) => state.employeePlatform)

  const dispatch = useDispatch()

  const [carpoolSearchQuery, setCarpoolSearchQuery] =
    useState(DEFAULT_SEARCH_QUERY)

  const [arrivalTimeBound, setArrivalTimeBound] = useState<TimeBound>({
    start: null,
    end: null,
  })
  const [departureTimeBound, setDepartureTimeBound] = useState<TimeBound>({
    start: null,
    end: null,
  })

  const searchButtonDisabled =
    carpoolSearchQuery.source === null ||
    carpoolSearchQuery.dest === null ||
    arrivalTimeBound.start === null ||
    arrivalTimeBound.end === null ||
    departureTimeBound.start === null ||
    departureTimeBound.end === null

  const handleSearch = () => {
    const payload: CarpoolSearchRequestPayload = {
      source: carpoolSearchQuery.source,
      sourceBound: parseInt(carpoolSearchQuery.sourceBound),
      dest: carpoolSearchQuery.dest,
      destBound: parseInt(carpoolSearchQuery.destBound),
      workArrivalTime: [arrivalTimeBound.start, arrivalTimeBound.end],
      workDepartureTime: [departureTimeBound.start, departureTimeBound.end],
    }

    dispatch(employeePlatformActions.searchCarpoolGroups(payload))
    setHasSearched(true)
  }

  return (
    <div>
      <SearchFormContainer>
        <AddressField>
          <AddressAutocompleteField
            label='Depart From'
            displayValue={sourceAddress}
            setDisplayValue={setSourceAddress}
            onSuggestionSelect={(location) => {
              setCarpoolSearchQuery({
                ...carpoolSearchQuery,
                source: location.coordinates,
              })
              setSource(location)
            }}
            customPlaceholder='Enter your departure address'
          />
          <Dropdown
            items={rangeBoundOptions}
            active={carpoolSearchQuery.sourceBound}
            itemCallback={(item) =>
              setCarpoolSearchQuery({
                ...carpoolSearchQuery,
                sourceBound: item.id,
              })
            }
            marginLeft='1rem'
            usePortal={true}
            maxWidth='10rem'
          />
        </AddressField>
        <AddressField>
          <AddressAutocompleteField
            label='Destination'
            displayValue={destAddress}
            setDisplayValue={setDestAddress}
            onSuggestionSelect={(location) => {
              setCarpoolSearchQuery({
                ...carpoolSearchQuery,
                dest: location.coordinates,
              })
              setDestination(location)
            }}
            customPlaceholder='Enter your destination address'
          />
          <Dropdown
            items={rangeBoundOptions}
            active={carpoolSearchQuery.destBound}
            itemCallback={(item) =>
              setCarpoolSearchQuery({
                ...carpoolSearchQuery,
                destBound: item.id,
              })
            }
            marginLeft='1rem'
            usePortal={true}
            maxWidth='10rem'
          />
        </AddressField>
        <FlexContainer flexDirection='column'>
          <Text variant='body1' textColor={palette.text.secondary}>
            Arrive at Destination Between
          </Text>
          <TimeBoundField>
            <TimeSelector
              value={arrivalTimeBound.start}
              onChange={(time) =>
                setArrivalTimeBound({ ...arrivalTimeBound, start: time })
              }
              timeInterval={15}
            />
            <TimeSelector
              value={arrivalTimeBound.end}
              onChange={(time) =>
                setArrivalTimeBound({ ...arrivalTimeBound, end: time })
              }
              timeInterval={15}
            />
          </TimeBoundField>
        </FlexContainer>
        <FlexContainer flexDirection='column'>
          <Text variant='body1' textColor={palette.text.secondary}>
            Depart from Destination Between
          </Text>
          <TimeBoundField>
            <TimeSelector
              value={departureTimeBound.start}
              onChange={(time) =>
                setDepartureTimeBound({ ...departureTimeBound, start: time })
              }
              timeInterval={15}
            />
            <TimeSelector
              value={departureTimeBound.end}
              onChange={(time) =>
                setDepartureTimeBound({ ...departureTimeBound, end: time })
              }
              timeInterval={15}
            />
          </TimeBoundField>
        </FlexContainer>
        <FlexContainer justifyContent='flex-start'>
          <Button
            onClick={() => handleSearch()}
            disabled={searchButtonDisabled}
          >
            Search
          </Button>
        </FlexContainer>
      </SearchFormContainer>
      <Divider direction='horizontal' size='100%' marginY='1rem' />
      <CardListContainer maxHeight='20rem'>
        {allCarpoolGroups !== null || searchedCarpoolGroups !== null ? (
          renderCarpoolCards(
            isMobile,
            hasSearched && searchedCarpoolGroups
              ? searchedCarpoolGroups
              : allCarpoolGroups,
            myCarpoolGroups,
            carpoolMemberships,
            sentCarpoolRequests,
            setSelectedCarpool,
            setShowRequestModal
          )
        ) : (
          <Loading />
        )}
      </CardListContainer>
    </div>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolSearch.displayName = 'CarpoolSearch'
}

export default CarpoolSearch
