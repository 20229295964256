import React from 'react'
import styled from 'styled-components'
import { space, SpaceProps, layout, LayoutProps } from 'styled-system'

const Container = styled.div<
  SpaceProps & LayoutProps & { color: string; size: number }
>`
  background-color: ${(props) => props.color};
  width: ${(props) => props.theme.pxToRem(props.size)};
  height: ${(props) => props.theme.pxToRem(props.size)};
  border-radius: 0.5rem;

  ${space}
  ${layout}
`

interface LegendColorProps
  extends SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  color: string
  size?: number
}

const LegendColor = React.memo((props: LegendColorProps) => {
  const { color, size = 8, ...rest } = props
  return <Container color={color} size={size} {...rest} />
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LegendColor.displayName = 'LegendColor'
}

export default LegendColor
