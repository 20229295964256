import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <rect width='52' height='52' rx='26' fill='#F0F0FF' />
    <path
      d='M26.0001 39.3333C32.0801 39.3333 37.1068 34.8 37.8934 28.9333C38.0134 28.08 37.2534 27.32 36.4001 27.44C30.5334 28.2267 26.0001 33.2533 26.0001 39.3333ZM17.4668 23.6667C17.4668 25.5067 18.9601 27 20.8001 27C21.5068 27 22.1468 26.7867 22.6934 26.4133L22.6668 26.6667C22.6668 28.5067 24.1601 30 26.0001 30C27.8401 30 29.3334 28.5067 29.3334 26.6667L29.3068 26.4133C29.8401 26.7867 30.4934 27 31.2001 27C33.0401 27 34.5334 25.5067 34.5334 23.6667C34.5334 22.3333 33.7468 21.2 32.6268 20.6667C33.7468 20.1333 34.5334 19 34.5334 17.6667C34.5334 15.8267 33.0401 14.3333 31.2001 14.3333C30.4934 14.3333 29.8534 14.5467 29.3068 14.92L29.3334 14.6667C29.3334 12.8267 27.8401 11.3333 26.0001 11.3333C24.1601 11.3333 22.6668 12.8267 22.6668 14.6667L22.6934 14.92C22.1601 14.5467 21.5068 14.3333 20.8001 14.3333C18.9601 14.3333 17.4668 15.8267 17.4668 17.6667C17.4668 19 18.2534 20.1333 19.3734 20.6667C18.2534 21.2 17.4668 22.3333 17.4668 23.6667ZM26.0001 17.3333C27.8401 17.3333 29.3334 18.8267 29.3334 20.6667C29.3334 22.5067 27.8401 24 26.0001 24C24.1601 24 22.6668 22.5067 22.6668 20.6667C22.6668 18.8267 24.1601 17.3333 26.0001 17.3333ZM14.1068 28.9333C14.8934 34.8 19.9201 39.3333 26.0001 39.3333C26.0001 33.2533 21.4668 28.2267 15.6001 27.44C14.7468 27.32 13.9868 28.08 14.1068 28.9333Z'
      fill='#5E5EAA'
    />
  </>,
  'GreenCommute',
  '0 0 52 52'
)
