import Button from 'core-system/Button/Button'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import {
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import Table from 'core-system/Table/Table'
import Text from 'core-system/Text/Text'
import moment, { Moment } from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AppState } from 'redux/config/store'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'
import AddOTRView from './AddOTRView'
import SegmentService from 'redux/config/services/SegmentService'
import { dashboardActions } from 'redux/dashboard/dashboardSlice'
import NoPaymentModal from 'shared/components/NoPaymentModal'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
`

const UserContainer = styled.div`
  display: flex;
`

const NameContainer = styled.div`
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`

const EmptyTableRow = styled(TableRowContents)`
  display: flex;
  justify-content: center;
  padding: 2rem;
`

interface formattedOTR {
  amount: number
  createdAt: Moment
  employeeCorpId: string
  employeeId: string
  employeeName: string
  id: string
  memo: string
}

const getTableRows = (formattedOTRData: formattedOTR[]) => {
  return [...formattedOTRData]
    .sort((a, b) => (b.createdAt.isBefore(a.createdAt) ? -1 : 1))
    .map((otr, idx) => {
      return (
        <TableRow key={idx} display='flex'>
          <TableRowContents isClickable={false} autoResize={false}>
            <TableRowCell width='15%' equalSize={false}>
              {otr.createdAt.format('MMMM D, YYYY')}
            </TableRowCell>
            <TableRowCell width='25%' equalSize={false}>
              <UserContainer>
                <PlatformSvg
                  folder='users'
                  variant='userBasic'
                  width={40}
                  height={40}
                />
                <NameContainer>
                  <Text variant='action4' hideOverflow>
                    {otr.employeeName}
                  </Text>
                  <Text variant='body2'>
                    {otr.employeeCorpId ? `#${otr.employeeCorpId}` : ''}
                  </Text>
                </NameContainer>
              </UserContainer>
            </TableRowCell>
            <TableRowCell width='15%' equalSize={false}>
              {FormattingUtils.formatDollar(otr.amount)}
            </TableRowCell>
            <TableRowCell width='45%' equalSize={false}>
              {otr.memo}
            </TableRowCell>
          </TableRowContents>
        </TableRow>
      )
    })
}

const OTRHistoryView = React.memo(() => {
  const dispatch = useDispatch()

  const { allCommutersMap } = useSelector((state: AppState) => state.employee)
  const { profile, hasValidPaymentMethod } = useSelector(
    (state: AppState) => state.employer
  )

  // Only returns OTRs awarded to employees in the current segment and appends the employee name and corporate ID
  const formattedSegmentOTRs = useSelector(
    (state: AppState) => state.incentives.allOneTimeRewards
  )?.map((otr) => {
    return {
      ...otr,
      createdAt: moment(otr.createdAt),
      employeeName: allCommutersMap[otr.employeeId]?.name,
      employeeCorpId: allCommutersMap[otr.employeeId]?.employeeCorporateId,
    }
  })

  const [showAddOTRView, setShowAddOTRView] = useState<boolean>(false)
  const [showNoPaymentModal, setShowNoPaymentModal] = useState<boolean>(false)

  const handleAddNewOTR = () => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      setShowAddOTRView(!showAddOTRView)
    } else {
      setShowNoPaymentModal(true)
    }
  }

  const downloadOtrCsv = () => {
    dispatch(dashboardActions.getDashboardCsvReport({ reportType: 'otr' }))
    SegmentService.track('otr-history-download')
  }

  return (
    <>
      {showAddOTRView ? (
        <AddOTRView setShowAddOTRView={setShowAddOTRView} />
      ) : (
        <>
          <HeaderContainer>
            <Text variant='h2'>One Time Reward History</Text>
            <HeaderButtons>
              <Button onClick={() => handleAddNewOTR()}>
                Add One Time Rewards
              </Button>
              <Button
                disabled={formattedSegmentOTRs.length === 0}
                onClick={() => downloadOtrCsv()}
                marginLeft='1rem'
              >
                CSV
              </Button>
            </HeaderButtons>
          </HeaderContainer>
          <Table hasHeader={false} maxRows={10}>
            <TableLabelRow padding='1rem 1.5rem' overflow='hidden'>
              <TableRowCell width='15%' equalSize={false}>
                Award Date
              </TableRowCell>
              <TableRowCell width='25%' equalSize={false}>
                Commuter
              </TableRowCell>
              <TableRowCell width='15%' equalSize={false}>
                Amount
              </TableRowCell>
              <TableRowCell width='45%' equalSize={false}>
                Description
              </TableRowCell>
            </TableLabelRow>
            {formattedSegmentOTRs && formattedSegmentOTRs.length > 0 ? (
              getTableRows(formattedSegmentOTRs)
            ) : (
              <EmptyTableRow>NO ONE TIME REWARDS AWARDED</EmptyTableRow>
            )}
          </Table>
          <NoPaymentModal
            open={showNoPaymentModal}
            closeModal={() => setShowNoPaymentModal(false)}
            product='otr'
          />
        </>
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OTRHistoryView.displayName = 'OTRHistoryView'
}

export default OTRHistoryView
