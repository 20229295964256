import React, { useCallback, useState } from 'react'
import Banner from 'core-system/Banner'
import Chip, { statusMap } from 'core-system/Chip/Chip'
import FlexContainer from 'core-system/FlexContainer'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { Locations } from 'shared/Router/Locations'
import SegmentService from 'redux/config/services/SegmentService'
import StatusUtils from 'shared/StatusUtils'
import GRHPreviewModal from './Overview/GRHPreviewModal'
import { Segment } from 'redux/employer/employerTypes'
import { Car as CarIcon } from 'core-system/Icons/modeTypes'
import styled from 'styled-components'
import FormattingUtils from 'shared/FormattingUtils'
import { GrhGroup } from 'redux/grh/grhTypes'
import NoPaymentModal from 'shared/components/NoPaymentModal'

const ProgramRow = styled.div`
  ${(props) => props.theme.baseCard}
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple7};
  }
`

const PurpleContainer = styled(FlexContainer)`
  padding: 0.5rem;
  border-radius: 0.3125rem;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  height: 2.5rem;
  width: 2.5rem;
`

const StatContainer = styled.div`
  margin-left: 2.4375rem;
`

const SpendContainer = styled.div`
  min-width: ${(props) => props.theme.pxToRem(60)};
  margin-left: 2.75rem;
`

const getActiveRow = (programGroup: GrhGroup) => {
  //check if active program is cancelling
  if (programGroup.active && programGroup?.nextMonth?.status === 'CANCELLING') {
    return programGroup.nextMonth
  } else if (programGroup.active) {
    return programGroup.active
  } else if (programGroup.nextMonth) {
    return programGroup.nextMonth
  } else {
    return null
  }
}

const renderProgramRows = (
  allSegments: Segment[],
  grhPrograms: Dictionary<GrhGroup>,
  handleOnClick: (segmentId: string) => void
) => {
  return allSegments.map((segment) => {
    const isActive = grhPrograms[segment.id]
    const activeRow = isActive && getActiveRow(grhPrograms[segment.id])

    return (
      <ProgramRow key={segment.id} onClick={() => handleOnClick(segment.id)}>
        <FlexContainer alignItems='center'>
          <PurpleContainer center>
            <CarIcon color={palette.text.secondary} />
          </PurpleContainer>
          <Text variant='action2' marginLeft='1rem'>
            {segment.name === 'All Employees' ? 'All Commuters' : segment.name}
          </Text>
          <FlexContainer alignItems='center' marginLeft='auto'>
            <Chip
              variant={
                isActive ? statusMap[activeRow.status].variant : 'disabled'
              }
            >
              {isActive ? statusMap[activeRow.status].text : 'off'}
            </Chip>
            <SpendContainer>
              {isActive && (
                <div>
                  <Text variant='body2' textColor={palette.text.placeholder}>
                    Spend
                  </Text>
                  <Text variant='action2'>
                    {FormattingUtils.formatDollar(activeRow.spend, 0)}
                  </Text>
                </div>
              )}
            </SpendContainer>
            <StatContainer>
              <Text variant='body2' textColor={palette.text.placeholder}>
                Uses
              </Text>
              <Text
                variant='action2'
                textColor={
                  isActive ? palette.text.primary : palette.text.disabled
                }
              >
                {isActive ? activeRow.numUses || '-' : '-'}
              </Text>
            </StatContainer>
            <StatContainer>
              <Text variant='body2' textColor={palette.text.placeholder}>
                Commuters
              </Text>
              <Text variant='action2'>{segment.commuters}</Text>
            </StatContainer>
          </FlexContainer>
        </FlexContainer>
      </ProgramRow>
    )
  })
}

const GRHPrograms = React.memo(() => {
  const navigate = useNavigate()
  const grhPrograms = useSelector((state: AppState) => state.grh.grhPrograms)
  const { allSegments, segmentsMap, hasValidPaymentMethod, profile } =
    useSelector((state: AppState) => state.employer)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)

  const handleOnClick = useCallback(
    (segmentId: string) => {
      navigate(`${segmentId}`, {
        state: { from: Locations.GRH.Programs },
      })
      SegmentService.track('segmentNav-action-click', {
        action: `segment-${segmentsMap[segmentId].name}`,
        locationAt: 'grh',
      })
    },
    [navigate, segmentsMap]
  )

  const handleNewProgramClick = () => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      SegmentService.track('programs-cta-click', {
        location: 'grh',
      })
      setIsModalOpen(true)
    } else {
      setIsNoPaymentModalOpen(true)
    }
  }

  if (!grhPrograms || !allSegments) {
    return <Loading fullPage />
  }

  const orderedSegments = [...allSegments].sort((a, b) => {
    const firstSegmentVal = grhPrograms[a.id]
      ? StatusUtils.statusOrder[getActiveRow(grhPrograms[a.id]).status]
      : 6
    const secondSegmentVal = grhPrograms[b.id]
      ? StatusUtils.statusOrder[getActiveRow(grhPrograms[b.id]).status]
      : 6
    return firstSegmentVal - secondSegmentVal
  })
  return (
    <>
      {Object.keys(grhPrograms).length === 0 ? (
        <>
          <GRHPreviewModal
            open={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
          />
          <Banner
            variant='cta'
            title='Start a GRH program today!'
            description='Give commuters a sure way to get to and from work, no matter what.'
            cta='New Program'
            ctaCallback={() => handleNewProgramClick()}
            background='bannerGeneral3'
          />
        </>
      ) : (
        <Banner
          variant='info'
          title='Your GRH Programs'
          description='Sure way to get to and from work, no matter what.'
          background='bannerGeneral3'
        />
      )}
      <div>
        {renderProgramRows(orderedSegments, grhPrograms, handleOnClick)}
      </div>
      <Outlet />
      <NoPaymentModal
        open={isNoPaymentModalOpen}
        closeModal={() => setIsNoPaymentModalOpen(false)}
        product='grh'
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHPrograms.displayName = 'GRHPrograms'
}

export default GRHPrograms
