import Button from 'core-system/Button'
import Dropdown from 'core-system/Dropdown'
import AddIcon from 'core-system/Icons/Actions/Add'
import React, { useCallback, useState } from 'react'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import styled from 'styled-components'
import TemplateSurveyModal from './TemplateSurveyModal'

const Container = styled.div``

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
`

const Title = styled.div`
  ${(props) => props.theme.typography.h4}
`

const Subtitle = styled.div`
  ${(props) => props.theme.typography.body1}
  max-width: ${(props) => props.theme.pxToRem(550)};
  margin-bottom: 1.5rem;
`

const EmptyContainer = styled.div`
  padding: ${(props) => props.theme.pxToRem(40)};
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 0.9375rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const IconContainer = styled.div`
  margin-bottom: 1.5rem;
`

const EmptyBottom = styled.div`
  width: ${(props) => props.theme.pxToRem(250)};
`

const templateItems = [
  { id: '0', text: 'Select a Template' },
  { id: '1', text: 'Daily COVID-19 Screening Survey' },
  { id: '2', text: 'Return to Work Survey' },
  { id: '3', text: 'Commuting Pulse Survey' },
]

interface SurveysViewProps {
  allSurveys: any
  userInfo: {
    email: string
    employerName: string
  }
}

const SurveysView = React.memo((props: SurveysViewProps) => {
  const { allSurveys, userInfo } = props
  const [selectedTemplate, setSelectedTemplate] = useState('0')
  const [showDemoSurvey, setShowDemoSurvey] = useState(false)

  const isEmpty = allSurveys.length === 0
  const handleTemplateChange = useCallback(({ id }) => {
    setSelectedTemplate(id)
    setShowDemoSurvey(true)
  }, [])

  // move into segment
  const surveyLinksMap = {
    1: `https://movewithfleet.surveysparrow.com/s/daily-health-survey---open/tt-e7f73e?org=${userInfo.employerName}&user_email=${userInfo.email}`,
    2: `https://movewithfleet.surveysparrow.com/s/Template-Return-to-Work/tt-0576ad?org=${userInfo.employerName}&user_email=${userInfo.email}`,
    3: `https://movewithfleet.surveysparrow.com/s/template-commute-pulse-survey/tt-e1732b=${userInfo.employerName}&user_email=${userInfo.email}`,
  }
  return (
    <>
      <Container>
        <Header>
          <Title>Surveys</Title>
          {!isEmpty && <Button iconLeft={<AddIcon />}>Survey</Button>}
        </Header>
        <Subtitle>
          Set up custom or premade surveys to gather commuter information to
          improve mobility programs.
        </Subtitle>
        {isEmpty ? (
          <EmptyContainer>
            <IconContainer>
              <img
                alt={'surveys'}
                src={`${StaticAssetsUrl}/concierge/surveys.png`}
              />
            </IconContainer>
            <EmptyBottom>
              <Dropdown
                active={selectedTemplate}
                itemCallback={handleTemplateChange}
                items={templateItems}
                label={'Survey Template'}
                marginBottom={'1.5rem'}
                width={'100%'}
              />
              <Button width={'100%'}>Start A New Survey</Button>
            </EmptyBottom>
          </EmptyContainer>
        ) : null}
      </Container>
      <TemplateSurveyModal
        open={showDemoSurvey}
        closeModal={() => setShowDemoSurvey(false)}
        surveyLink={surveyLinksMap[selectedTemplate]}
        title={templateItems[selectedTemplate].text}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SurveysView.displayName = 'SurveysView'
}

export default SurveysView
