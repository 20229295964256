import React from 'react'
import FlexContainer from 'core-system/FlexContainer'
import styled from 'styled-components'
import Text from 'core-system/Text'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import FormattingUtils from 'shared/FormattingUtils'
import Divider from 'core-system/Divider'
import palette from 'core-system/Themes/palette'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Chip from 'core-system/Chip'

const Container = styled.div`
  ${(props) => props.theme.baseCard};
`
const IconContainer = styled(FlexContainer)`
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${(props) => props.theme.palette.chips.purple2};
  border-radius: 50%;
  margin-right: 1rem;
`

interface GRHOptionsProps {
  numUses: number
  transactionLimit: number
  rideshareActive?: boolean
  taxiActive?: boolean
}

const GRHOptions = React.memo((props: GRHOptionsProps) => {
  const { numUses, transactionLimit, rideshareActive } = props
  return (
    <Container>
      <Text variant='h5'>Annual Budget Per Commuter</Text>
      <FlexContainer alignItems='center'>
        <PlatformSvg
          folder='metrics'
          variant='employeeCostPurple'
          width={40}
          height={40}
        />
        <Text variant='h2' marginLeft='0.5rem'>
          {FormattingUtils.formatDollar(numUses * transactionLimit, 0)}
        </Text>
      </FlexContainer>
      <Divider marginY='1rem' />
      <FlexContainer justifyContent={'space-between'} alignItems='center'>
        <div>
          <Text
            variant='caption'
            captionTitle
            textColor={palette.text.secondary}
            marginBottom='0.5rem'
          >
            Transaction Limit
          </Text>
          <Text variant='action3' capitalize={true}>
            {FormattingUtils.formatDollar(transactionLimit, 0)}
          </Text>
        </div>
        <div>
          <Text
            variant='caption'
            captionTitle
            textColor={palette.text.secondary}
            marginBottom='0.5rem'
          >
            Number of Transactions
          </Text>
          <Text variant='action3'>{numUses || '-'}</Text>
        </div>
      </FlexContainer>
      <Divider marginY='1rem' />
      <Text
        variant='caption'
        captionTitle
        textColor={palette.text.secondary}
        marginBottom='0.5rem'
      >
        Program Options
      </Text>
      <FlexContainer
        alignItems='center'
        marginBottom='0.25rem'
        padding='0.5rem'
      >
        <IconContainer center>
          {ProgramUtils.optionsCopy.rideshare.icon}
        </IconContainer>
        <Text variant='action3'>Rideshare</Text>
        <Chip marginLeft='auto' variant={rideshareActive ? 'green' : 'grey'}>
          {rideshareActive ? 'Active' : 'Disabled'}
        </Chip>
      </FlexContainer>
      <FlexContainer
        alignItems='center'
        marginBottom='0.25rem'
        padding='0.5rem'
      >
        <IconContainer center>
          {ProgramUtils.optionsCopy.localTaxi.icon}
        </IconContainer>
        <Text variant='action3'>Local Taxi</Text>
        <Chip marginLeft='auto' variant={rideshareActive ? 'green' : 'grey'}>
          {rideshareActive ? 'Active' : 'Disabled'}
        </Chip>
      </FlexContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHOptions.displayName = 'GRHOptions'
}

export default GRHOptions
