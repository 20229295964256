import Tooltip from 'core-system/Tooltip'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div<{ width: number; color: string }>`
  width: ${(props) => `${props.width}%`};
  background-color: ${(props) => props.color};

  &:last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
  }
`

interface CategoryProps {
  width: number
  color: string
  value: string
  disableToolTip?: boolean
}

const Category = React.memo((props: CategoryProps) => {
  const { width, color, value, disableToolTip = false } = props
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const ref = useRef(null)

  return (
    <Container
      width={width}
      color={color}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
      ref={ref}
    >
      {!disableToolTip && (
        <Tooltip
          target={ref}
          show={isTooltipVisible}
          position='bottom'
          marginTop='0.25rem'
        >
          {value}
        </Tooltip>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  Category.displayName = 'Category'
}

export default Category
