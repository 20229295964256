import React, { useState } from 'react'
import { CustomGridContainer } from 'employee-platform/shared/styles/Grid'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import LeaderboardListSelect from './components/LeaderboardListSelect'
import LeaderboardDetails from './components/LeaderboardDetails'

const LeaderboardsOverviewView = React.memo(() => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const [selectedLeaderboardId, setSelectedLeaderboardId] = useState<
    string | null
  >(null)

  return (
    <CustomGridContainer columnLayout='1fr 2fr'>
      <LeaderboardListSelect
        isMobile={isMobile}
        selectedLeaderboardId={selectedLeaderboardId}
        setSelectedLeaderboardId={setSelectedLeaderboardId}
      />
      <LeaderboardDetails
        isMobile={isMobile}
        selectedLeaderboardId={selectedLeaderboardId}
      />
    </CustomGridContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardsOverviewView.displayName = 'LeaderboardsOverviewView'
}

export default LeaderboardsOverviewView
