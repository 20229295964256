import * as React from 'react'
import debounce, { Debounce, VoidDebounce } from '../DebounceUtil'

/**
 * Attach a callback to window resize event. Callback will be binded
 * and unbinded on mount and on unmount by default
 * @param cb: Callback function
 * @param dependencies: useEffect dependencies
 * @param wait: Wait time for debouncing
 */
export default function (
  callback: () => void,
  dependencies: any[] = [],
  wait?: number
) {
  const resizeRef = React.useRef<Debounce>(VoidDebounce())

  React.useEffect(() => {
    resizeRef.current = debounce(() => callback(), wait)
    window.addEventListener('resize', resizeRef.current)
    return () => {
      resizeRef.current.clear()
      window.removeEventListener('resize', resizeRef.current)
    }
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps
}
