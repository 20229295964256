import React from 'react'

export default function (value: any) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })

  return ref.current
}
