import React from 'react'
import styled from 'styled-components'
import { Feature } from 'geojson'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import PlatformSvg from 'core-system/Icons/PlatformSvg'

const PopupContainer = styled.div`
  display: flex;
  padding: 0.375rem;
  width: ${(props) => props.theme.pxToRem(235)};
`

const SVGContainer = styled.div`
  width: ${(props) => props.theme.pxToRem(60)};
`

const WorkplaceStats = styled.div`
  flex: 1;
`

interface MapPopupWorksiteProps {
  feature: Feature
}

const MapPopupWorksite = React.memo((props: MapPopupWorksiteProps) => {
  const { feature } = props
  return (
    <PopupContainer>
      <SVGContainer>
        <PlatformSvg
          folder='offices'
          variant='office1'
          height={45}
          width={45}
        />
      </SVGContainer>
      <WorkplaceStats>
        <Text variant='h5'>{feature.properties.alias || 'Worksite'}</Text>
        <Text variant='body2' textColor={palette.text.secondary}>
          {feature.properties.formattedAddress.split(',')[0]}
        </Text>
        <Text variant='body2' textColor={palette.text.secondary}>
          {feature.properties.numEmployees} Commuters
        </Text>
      </WorkplaceStats>
    </PopupContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MapPopupWorksite.displayName = 'MapPopupWorksite'
}

export default MapPopupWorksite
