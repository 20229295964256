import Button from 'core-system/Button'
import FlexContainer from 'core-system/FlexContainer'
import AddWorksiteModal from 'features/Accounts/Users/AddWorksiteModal'
import UploadDataModal from 'features/Segments/components/UploadDataModal'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import styled from 'styled-components'
import { AppState } from 'redux/config/store'
import ConnectHris from 'features/Segments/components/ConnectHris'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ImageContainer = styled.div`
  padding-top: 1.25rem;
  img {
    max-width: 250px;
    width: 100%;
  }
`

const OnboardingUpload = React.memo(() => {
  // const {} = props
  // const hrisProvider = useSelector(
  //   (state: AppState) => state.employer.profile?.payrollProviderId
  // )

  // const isFileLoading = useSelector(
  //   (state: AppState) => state.employer.isUploading
  // )

  const hrisStatus = useSelector((state: AppState) => ({
    providerName: state.employer.profile?.payrollProviderId,
    reauth: state.employer.profile?.finchReauth,
  }))

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isWorksiteModalOpen, setIsWorksiteModalOpen] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  // disabling for now
  // useEffect(() => {
  //   if (hrisProvider || isFileLoading) {
  //     handleNextStep()
  //   }
  // }, [hrisProvider, isFileLoading, handleNextStep])

  return (
    <Container>
      <ImageContainer>
        <img
          alt='upload'
          src={`${StaticAssetsUrl}/onboarding/onboarding-employee-data.png`}
        />
      </ImageContainer>
      <FlexContainer
        flexWrap='wrap'
        justifyContent='center'
        alignItems='center'
        margin='2rem 0'
      >
        <ConnectHris
          providerName={hrisStatus.providerName}
          reAuth={hrisStatus.reauth}
          btnSize='medium'
          margin='1rem 0.25rem'
        />
        <Button
          size='medium'
          variant='secondary'
          onClick={() => setIsModalOpen(true)}
          margin='1rem 0.25rem'
        >
          Upload Commuter Data
        </Button>
        <Button
          size='medium'
          variant='secondary'
          margin='1rem 0.25rem'
          onClick={() => setIsWorksiteModalOpen(true)}
        >
          Add a Worksite
        </Button>
      </FlexContainer>
      <UploadDataModal
        open={isModalOpen}
        closeModal={closeModal}
        isOnboarding
      />
      <AddWorksiteModal
        open={isWorksiteModalOpen}
        closeModal={() => setIsWorksiteModalOpen(false)}
      />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OnboardingUpload.displayName = 'OnboardingUpload'
}

export default OnboardingUpload
