import React from 'react'
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'core-system/Modal'
import Text from 'core-system/Text'
import Button from 'core-system/Button'
import { useNavigate } from 'react-router-dom'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import AlertIcon from 'core-system/Icons/Misc/Alert'
import palette from 'core-system/Themes/palette'

const ModalText = {
  qtf: 'Pre-Tax Commuter',
  flex: 'Flex',
  micromobility: 'Micromobility',
  grh: 'Guaranteed Ride Home',
  otp: 'One Time Purchase',
  incentives: 'Incentives',
  competition: 'Competition',
  otr: 'One Time Reward',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

interface NoPaymentModalProps {
  open: boolean
  closeModal: () => void
  product: string
}

const NoPaymentModal = React.memo((props: NoPaymentModalProps) => {
  const { open, closeModal, product } = props

  const navigate = useNavigate()

  const goToAddPaymentMethod = () => {
    closeModal()
    navigate(Locations.Accounts.Billing)
  }

  return (
    <Modal open={open} onClose={closeModal} width='40%'>
      <ModalHeader title='No Payment Method' />
      <ModalBody>
        <Container>
          <AlertIcon color={palette.secondary.red1} height={50} width={50} />
          <Text variant='action1' textAlign='center'>
            {`You cannot ${product === 'otr' ? 'distribute' : 'start'} a${
              product === 'incentives' ? 'n' : ''
            } ${ModalText[product]} ${
              product === 'competition' || product === 'otr' ? '' : 'program '
            }without first adding a payment method.`}
          </Text>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button variant='tertiary' onClick={closeModal}>
          Close
        </Button>
        <Button marginLeft='1rem' onClick={() => goToAddPaymentMethod()}>
          Add Payment Method
        </Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NoPaymentModal.displayName = 'NoPaymentModal'
}

export default NoPaymentModal
