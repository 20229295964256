import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import * as MicromobilityIcons from 'core-system/Icons/Micromobility'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import ProgramChange from 'core-system/Program/ProgramChange'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import MicromobilityUtils from 'features/Micromobility/Shared/MicromobilityUtils'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import React from 'react'
import {
  MicromobilityActiveOptions,
  MicromobilityManageState,
} from 'redux/micromobility/micromobilityTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
`

const getDescriptionText = (canEdit: boolean, isPending: boolean) => {
  if (isPending) {
    return 'Update this program to apply these changes.'
  } else {
    return canEdit
      ? 'Update this program to apply these changes to the following month.'
      : 'All upcoming changes will be applied to the program the following months.'
  }
}

interface MicromobilityManagePendingProps {
  programState: MicromobilityManageState
  activeOptions: Dictionary<MicromobilityActiveOptions>
  canEdit?: boolean
  isPending: boolean
  openUpdateModal: () => void
}

const MicromobilityManagePending = React.memo(
  (props: MicromobilityManagePendingProps) => {
    const {
      programState,
      activeOptions,
      canEdit = true,
      isPending,
      openUpdateModal,
    } = props

    const monthsLeft = MicromobilityUtils.monthsLeft

    const currentBudget = FormattingUtils.formatDollar(
      programState.active.budget,
      0
    )
    const nextMonthBudget = FormattingUtils.formatDollar(
      programState.nextMonth.budget,
      0
    )

    const currentCombinedBudget = FormattingUtils.formatDollar(
      programState.active.budget * monthsLeft,
      0
    )
    const nextMonthCombinedBudget = FormattingUtils.formatDollar(
      programState.nextMonth.budget * monthsLeft,
      0
    )

    const optionChanges = Object.keys(activeOptions.active).flatMap(
      (option) => {
        if (activeOptions.active[option] !== activeOptions.nextMonth[option]) {
          return option
        } else {
          return []
        }
      }
    )

    const providerChanges = ProgramUtils.getProviderDifference(
      programState,
      MicromobilityUtils.providerOptions
    )

    //only compare provider changes on active options
    const hasProviderChanges =
      Object.keys(providerChanges).length > 0 &&
      Object.keys(providerChanges).some(
        (option) => activeOptions.nextMonth[option]
      )

    const hasChanges =
      currentBudget !== nextMonthBudget ||
      optionChanges.length > 0 ||
      hasProviderChanges ||
      programState.nextMonth.status === 'CANCELLING'

    return (
      <>
        {hasChanges ? (
          <Container>
            <Text variant='h4' marginBottom='0.5rem'>
              {canEdit ? 'Pending Changes' : 'Upcoming Changes'}
            </Text>
            <Text variant='body1' marginBottom='1.5rem'>
              {getDescriptionText(canEdit, isPending)}
            </Text>
            {programState.nextMonth.status === 'CANCELLING' && (
              <>
                <Divider margin='1.5rem 0' />
                <Text
                  variant='caption'
                  captionTitle
                  textColor={palette.text.secondary}
                  marginBottom='0.5rem'
                >
                  Program Cancellation Request
                </Text>
                <Text variant='body2'>
                  Our team will be in touch with you soon.
                </Text>
                {canEdit && (
                  <Button
                    onClick={openUpdateModal}
                    width='100%'
                    marginTop='1rem'
                  >
                    Reactivate Program
                  </Button>
                )}
              </>
            )}
            {currentBudget !== nextMonthBudget && (
              <ProgramChange
                title='Total Monthly Budget'
                metric='Per Commuter'
                icon={<PlatformSvg folder='metrics' variant='money' />}
                value={`${currentBudget} ➞ ${nextMonthBudget}`}
              />
            )}
            {activeOptions.nextMonth.oneTime && (
              <>
                {(currentBudget !== nextMonthBudget ||
                  providerChanges.oneTime) && (
                  <>
                    <Divider margin='1.5rem 0' />
                    <Text
                      variant='caption'
                      captionTitle
                      textColor={palette.text.secondary}
                    >
                      One-Time Purchase
                    </Text>
                  </>
                )}
                {currentBudget !== nextMonthBudget && (
                  <ProgramChange
                    metric='Max Combined Budget'
                    icon={
                      <MicromobilityIcons.OneTimePurchase
                        color={palette.text.secondary}
                      />
                    }
                    value={`${currentCombinedBudget} ➞ ${nextMonthCombinedBudget}`}
                  />
                )}
                {providerChanges.oneTime &&
                  ProgramUtils.renderProviderChanges(
                    'oneTime',
                    providerChanges.oneTime,
                    false
                  )}
              </>
            )}
            {providerChanges.leasing &&
              activeOptions.nextMonth.leasing &&
              ProgramUtils.renderProviderChanges(
                'leasing',
                providerChanges.leasing
              )}
            {providerChanges.shared &&
              activeOptions.nextMonth.shared &&
              ProgramUtils.renderProviderChanges(
                'shared',
                providerChanges.shared
              )}
            {optionChanges.length > 0 && (
              <>
                <Divider margin='1.5rem 0' />
                <Text
                  variant='caption'
                  captionTitle
                  textColor={palette.text.secondary}
                >
                  Program Options
                </Text>
                {optionChanges.map((option) => (
                  <ProgramChange
                    key={option}
                    metric={MicromobilityUtils.optionsCopy[option].title}
                    icon={MicromobilityUtils.optionsCopy[option].icon}
                    value={
                      activeOptions.nextMonth[option] ? 'Enabled' : 'Disabled'
                    }
                  />
                ))}
              </>
            )}
          </Container>
        ) : null}
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityManagePending.displayName = 'MicromobilityManagePending'
}

export default MicromobilityManagePending
