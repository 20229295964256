import Button from 'core-system/Button'
import Alert from 'core-system/Icons/Misc/Alert'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Radio from 'core-system/Radio/Radio'
import Text from 'core-system/Text/Text'
import Colors from 'core-system/Themes/Colors'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { employeeActions } from 'redux/employee/employeeSlice'
import { MyCommuter } from 'redux/employee/employeeTypes'
import employerService from 'redux/employer/employerService'
import styled from 'styled-components'

const AlertIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

interface InviteUsersModalProps {
  segmentId: string
  closeModal: () => void
  open: boolean
}

const InviteUsersModal = (props: InviteUsersModalProps) => {
  const { segmentId, closeModal, open } = props

  const unregisteredEmployees = useSelector(
    (state: AppState) => state.employee.myCommuters[segmentId]
  )
    ?.filter((employee) => employee.hasRegistered === false)
    ?.sort((a, b) => a.name.localeCompare(b.name))

  const allUnselectedEmployees = unregisteredEmployees?.reduce(
    (employees, employee) => {
      employees[employee.id] = false
      return employees
    },
    {}
  )

  const dispatch = useDispatch()

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [selectedEmployees, setSelectedEmployees] = useState<any>(
    allUnselectedEmployees
  )

  const renderEmployeeRadioSelect = () => {
    if (!unregisteredEmployees || unregisteredEmployees.length === 0) {
      return (
        <AlertIconContainer>
          <Text variant='h4' textColor={Colors.grey}>
            NO COMMUTERS TO INVITE
          </Text>
        </AlertIconContainer>
      )
    }
    return unregisteredEmployees?.map((employee: MyCommuter) => (
      <Radio
        active={
          selectedEmployees && selectedEmployees[employee.id]
            ? selectedEmployees[employee.id]
            : false
        }
        label={employee.name}
        onClick={() =>
          setSelectedEmployees({
            ...selectedEmployees,
            [employee.id]: !selectedEmployees[employee.id],
          })
        }
        marginBottom='1rem'
        key={employee.id}
      />
    ))
  }

  const handleCloseModal = () => {
    setShowConfirmation(false)
    setSelectedEmployees(allUnselectedEmployees)
    setSelectAll(false)
    closeModal()
    SegmentService.track('close-invite-employees-modal')
  }

  const handleEditExitModal = () => {
    if (showConfirmation) {
      setShowConfirmation(false)
      SegmentService.track('edit-selected-employees')
    } else {
      handleCloseModal()
    }
  }

  const handleInviteSubmit = useCallback(async () => {
    const selectedEmployeeIds = Object.keys(selectedEmployees).filter(
      (employeeId: string) => selectedEmployees[employeeId]
    )
    if (showConfirmation) {
      await employerService.rolloutUsers({
        employees: selectedEmployeeIds,
      })

      SegmentService.track('user-rollout-submit', {
        employees: selectedEmployeeIds,
      })
      setSelectedEmployees(allUnselectedEmployees)
      setShowConfirmation(false)
      closeModal()

      dispatch(employeeActions.getMyCommuters({ segment: segmentId }))
    } else {
      setShowConfirmation(true)
      SegmentService.track('user-rollout-confirmation', {
        employees: selectedEmployeeIds,
      })
    }
  }, [
    showConfirmation,
    allUnselectedEmployees,
    closeModal,
    dispatch,
    selectedEmployees,
    segmentId,
  ])

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedEmployees(allUnselectedEmployees)
    } else {
      setSelectedEmployees(
        unregisteredEmployees?.reduce((employees, employee) => {
          employees[employee.id] = true
          return employees
        }, {})
      )
    }
    setSelectAll(!selectAll)
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      width={pxToRem(514)}
      maxHeight={pxToRem(514)}
    >
      <ModalHeader
        title={`Invite Commuters`}
        style={{ textTransform: 'capitalize' }}
      />
      <ModalBody marginY='1rem'>
        <AlertIconContainer>
          {showConfirmation ? (
            <Alert
              color={Colors.primaryDark}
              height={50}
              width={50}
              style={{ marginBottom: '1.5rem' }}
            />
          ) : null}
        </AlertIconContainer>
        {unregisteredEmployees && unregisteredEmployees.length > 0 ? (
          <Text variant='h5' marginBottom='1rem'>
            {showConfirmation
              ? 'Are you sure you would like to invite the following commuters:'
              : 'Select commuters that you wish to invite:'}
          </Text>
        ) : null}
        {showConfirmation
          ? unregisteredEmployees
              ?.filter((employee: MyCommuter) => selectedEmployees[employee.id])
              ?.map((employee: MyCommuter) => (
                <Text variant='body1' marginBottom='1rem' key={employee.id}>
                  {`• ${employee.name}`}
                </Text>
              ))
          : renderEmployeeRadioSelect()}
      </ModalBody>
      <ModalFooter>
        <ButtonContainer>
          <Button variant='tertiary' onClick={handleEditExitModal}>
            {showConfirmation ? 'Edit' : 'Exit'}
          </Button>
          {!showConfirmation && (
            <Button onClick={handleSelectAll} variant='tertiary'>
              {selectAll ? 'Deselect All' : 'Select All'}
            </Button>
          )}
          <Button
            onClick={handleInviteSubmit}
            disabled={
              !selectedEmployees ||
              !Object.keys(selectedEmployees).some(
                (employeeId: string) => selectedEmployees[employeeId]
              )
            }
          >
            Invite Commuters
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  InviteUsersModal.displayName = 'InviteUsersModal'
}

export default InviteUsersModal
