import { all, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { CurrentWorksitePayload } from 'redux/employer/employerTypes'
import { micromobilityActions } from 'redux/micromobility/micromobilitySlice'
import MicromobilityService from 'redux/micromobility/micromobilityService'
import {
  MicromobilityIsochroneParams,
  MicromobilityProgram,
  MicromobilityProgramUpdate,
} from 'redux/micromobility/micromobilityTypes'
import {
  SegmentAndType,
  SegmentAndTypeAndDate,
} from 'redux/programs/programsTypes'
import { Action } from 'shared/Types/Actions'

export function* getMicromobilityRecommendations(
  action: Action<CurrentWorksitePayload>
) {
  yield defaultFlow(
    action,
    MicromobilityService.getMicromobilityRecommendations,
    micromobilityActions.getMicromobilityRecommendationsSuccess
  )
}

export function* createMicromobilityProgram(
  action: Action<MicromobilityProgram>
) {
  yield defaultFlow(
    action,
    MicromobilityService.createMicromobilityProgram,
    micromobilityActions.createMicromobilityProgramSuccess
  )
}

export function* updateMicromobilityProgram(
  action: Action<MicromobilityProgramUpdate>
) {
  yield defaultFlow(
    action,
    MicromobilityService.updateMicromobilityProgram,
    micromobilityActions.updateMicromobilityProgramSuccess
  )
}

export function* cancelMicromobilityProgram(action: Action<string>) {
  yield defaultFlow(
    action,
    MicromobilityService.cancelMicromobilityProgram,
    micromobilityActions.cancelMicromobilityProgramSuccess
  )
}

export function* getMicromobilityIsochrones(
  action: Action<MicromobilityIsochroneParams>
) {
  yield defaultFlow(
    action,
    MicromobilityService.getMicromobilityIsochrones,
    micromobilityActions.getMicromobilityIsochronesSuccess
  )
}

export function* getMicromobilityDashboardStats(
  action: Action<SegmentAndType>
) {
  yield defaultFlow(
    action,
    MicromobilityService.getMicromobilityDashboardStats,
    micromobilityActions.getMicromobilityDashboardStatsSuccess
  )
}

export function* getMicromobilityDashboardServices(
  action: Action<SegmentAndTypeAndDate>
) {
  yield defaultFlow(
    action,
    MicromobilityService.getMicromobilityDashboardServices,
    micromobilityActions.getMicromobilityDashboardServicesSuccess
  )
}

/**
 * Watchers
 */
export function* watchGetMicromobilityRecommendations() {
  yield takeEvery(
    [micromobilityActions.getMicromobilityRecommendations.type],
    getMicromobilityRecommendations
  )
}

export function* watchCreateMicromobilityProgram() {
  yield takeEvery(
    [micromobilityActions.createMicromobilityProgram.type],
    createMicromobilityProgram
  )
}

export function* watchUpdateMicromobilityProgram() {
  yield takeEvery(
    [micromobilityActions.updateMicromobilityProgram.type],
    updateMicromobilityProgram
  )
}

export function* watchCancelMicromobilityProgram() {
  yield takeEvery(
    [micromobilityActions.cancelMicromobilityProgram.type],
    cancelMicromobilityProgram
  )
}

export function* watchGetMicromobilityIsochrones() {
  yield takeEvery(
    [micromobilityActions.getMicromobilityIsochrones.type],
    getMicromobilityIsochrones
  )
}

export function* watchGetMicromobilityDashboardStats() {
  yield takeEvery(
    [micromobilityActions.getMicromobilityDashboardStats.type],
    getMicromobilityDashboardStats
  )
}

export function* watchGetMicromobilityDashboardServices() {
  yield takeEvery(
    [micromobilityActions.getMicromobilityDashboardServices.type],
    getMicromobilityDashboardServices
  )
}

export default function* () {
  yield all([
    watchGetMicromobilityRecommendations(),
    watchCreateMicromobilityProgram(),
    watchUpdateMicromobilityProgram(),
    watchCancelMicromobilityProgram(),
    watchGetMicromobilityIsochrones(),
    watchGetMicromobilityDashboardStats(),
    watchGetMicromobilityDashboardServices(),
  ])
}
