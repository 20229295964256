import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M8.32 16.5H15.5V15.5C15.5 13.29 17.29 11.5 19.5 11.5H20.24L18.34 3.06C18.13 2.15 17.32 1.5 16.39 1.5H12.5V3.5H16.39L17.79 9.75H17.78C15.62 10.4 13.97 12.23 13.59 14.5H8.32C7.84 13.16 6.46 12.26 4.9 12.56C3.72 12.79 2.77 13.76 2.55 14.94C2.2 16.84 3.66 18.5 5.5 18.5C6.8 18.5 7.9 17.66 8.32 16.5ZM5.5 16.5C4.95 16.5 4.5 16.05 4.5 15.5C4.5 14.95 4.95 14.5 5.5 14.5C6.05 14.5 6.5 14.95 6.5 15.5C6.5 16.05 6.05 16.5 5.5 16.5Z' />
    <path d='M19.5 12.5C17.84 12.5 16.5 13.84 16.5 15.5C16.5 17.16 17.84 18.5 19.5 18.5C21.16 18.5 22.5 17.16 22.5 15.5C22.5 13.84 21.16 12.5 19.5 12.5ZM19.5 16.5C18.95 16.5 18.5 16.05 18.5 15.5C18.5 14.95 18.95 14.5 19.5 14.5C20.05 14.5 20.5 14.95 20.5 15.5C20.5 16.05 20.05 16.5 19.5 16.5Z' />
    <path d='M11.5 20.5H7.5L13.5 23.5V21.5H17.5L11.5 18.5V20.5Z' />
  </>,
  'Electric Scooter'
)
