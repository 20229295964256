import React, { useState, useEffect } from 'react'
import ColumnView, { Column } from 'core-system/ColumnView'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { OtpActiveOptions } from 'redux/otp/otpTypes'
import { AppState } from 'redux/config/store'
import { otpActions } from 'redux/otp/otpSlice'
import SegmentService from 'redux/config/services/SegmentService'
import * as Program from 'core-system/Program'
import SegmentDropdownUtils from 'core-system/SegmentDropdown/SegmentDropdownUtils'
import Loading from 'core-system/Loading'
import ProgramOption from 'core-system/Program/ProgramOption'
import DateUtils from 'shared/DateUtils'
import useQueryParam from 'shared/Hooks/useQueryParam'
import OTPActivationSummary from './components/OTPActivationSummary'
import FlexContainer from 'core-system/FlexContainer'
import Button from 'core-system/Button'
import ServiceProviderSelectorModal from 'core-system/ServiceProvider/ServiceProviderSelectorModal'
import moment from 'moment'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import OTPUtils from '../Shared/OTPUtils'

const defaultActiveOptions = {
  micromobility: true,
}

const nextMonth = () => {
  return {
    val: 'next-month',
    text: DateUtils.getNextXMonths(1, 1)[0].text,
  }
}

type LocationState = {
  from: string
  activeOptions: OtpActiveOptions
}

const OTPActivationView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const state = location.state as LocationState
  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )
  const allSegments = useSelector(
    (state: AppState) => state.employer.allSegments
  )
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )

  const { otpPrograms, availableMerchants } = useSelector(
    (state: AppState) => state.otp
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [startDate, setStartDate] = useState(nextMonth)
  const [transactionLimit, setTransactionLimit] = useState(
    OTPUtils.recTransacLimit
  )
  const [errorState, setErrorState] = useState(OTPUtils.defaultErrorState)
  const [availableMerchantIds, setAvailableMerchantIds] = useState<string[]>([])
  const [activeMicromobilityIds, setActiveMicromobilityIds] = useState<
    string[]
  >([])

  const [activeOptions, setActiveOptions] = useState(
    state && state.activeOptions ? state.activeOptions : defaultActiveOptions
  )

  useEffect(() => {
    dispatch(
      otpActions.getOtpRecommendations({
        segmentId,
      })
    )
  }, [dispatch, segmentId])

  useEffect(() => {
    if (availableMerchants && merchantsMap) {
      const micromobilityMerchantIds = availableMerchants.micromobility.map(
        (m) => m.id
      )

      setAvailableMerchantIds(micromobilityMerchantIds)
      setActiveMicromobilityIds(micromobilityMerchantIds)
    }
  }, [availableMerchants, merchantsMap, segmentsMap, segmentId])

  const handleOptionToggle = (type: string) => {
    setActiveOptions((prevState) => {
      return {
        ...prevState,
        [type]: !prevState[type],
      }
    })
  }
  const handleProviderChange = (newActiveMerchants: string[]) => {
    setActiveMicromobilityIds(newActiveMerchants)
  }

  const handleDateChange = (val: string, text: string, initial: boolean) => {
    setStartDate(initial ? nextMonth : { val, text })
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
    SegmentService.track('modes-modal-toggle', {
      action: 'open',
      state: 'edit',
      location: 'otp-providers',
    })
  }

  const handleSegmentChange = (newSegmentId: string) => {
    if (newSegmentId !== segmentId) {
      navigate(`/one-time-purchase/${newSegmentId}/activation`)
      SegmentService.track('baseOptions-toggle-action', {
        actionType: 'segment-name',
      })
    }
  }
  const handleActivate = () => {
    const checkErrors = {
      transactionLimit: !transactionLimit && true,
      micromobility:
        availableMerchants.micromobility.length > 0 &&
        activeMicromobilityIds.length === 0 &&
        activeOptions.micromobility &&
        true,
    }

    const hasNoErrors = Object.keys(checkErrors).every(
      (key) => !checkErrors[key]
    )
    if (hasNoErrors) {
      const startDateFormatted = moment(startDate.text, 'MMMM Do YYYY').format()

      const merchants = []
      if (activeOptions.micromobility) merchants.push(...activeMicromobilityIds)

      dispatch(
        otpActions.createOtpProgram({
          startDate: startDateFormatted,
          endDate: null,
          segment: segmentId,
          name: 'otp',
          type: 'OTP',
          budget: transactionLimit,
          numUses: 1,
          requiresRequest: true,
          merchants: merchants,
          yearlyPool: true,
        })
      )

      SegmentService.track('programs-action-click', {
        action: 'activate',
        programType: 'otp',
        segmentName: segmentsMap[segmentId].name,
        startDate: moment(startDateFormatted).format('DD-MM-YYYY'),
        budget: transactionLimit,
        flexProviders: false,
        gas: null,
        parking: null,
        transit: null,
        oneTime:
          activeMicromobilityIds.length > 0 && activeOptions.micromobility
            ? activeMicromobilityIds.map((m) => merchantsMap[m]?.name)
            : false,
        shared: null,
        leasing: null,
        numUses: 1,
        localTaxi: false,
        yearlyPool: true,
      })
    } else {
      setErrorState(checkErrors)
      dispatch(
        notificationsActions.generalPageError(
          'Make sure a valid amount of transactions, transaction limit is set and there is at least one service provider'
        )
      )
    }
  }

  if (!availableMerchants || !merchantsMap || !otpPrograms || !segmentsMap) {
    return <Loading fullPage />
  }

  if (otpPrograms[segmentId]) {
    return <Navigate to={`/one-time-purchase/${segmentId}`} replace />
  }

  const noActiveOptions = Object.keys(activeOptions).every(
    (option) => !activeOptions[option]
  )

  return (
    <>
      <ColumnView defaultView>
        <Column>
          <Program.SegmentAndDateConfig
            currentSegment={segmentsMap[segmentId]}
            dropdownItems={SegmentDropdownUtils.segmentDropdownItems(
              allSegments,
              otpPrograms
            )}
            onDateChange={handleDateChange}
            onSegmentChange={handleSegmentChange}
          />
          <Program.BudgetSelector
            budget={transactionLimit}
            onChange={(newLimit) => setTransactionLimit(newLimit * 100)}
            recBudget={OTPUtils.recTransacLimit}
            customHeader='Per Commuter Purchase Limit'
          />
          <ProgramOption
            type='micromobility'
            active={activeOptions.micromobility}
            activeMerchantIds={activeMicromobilityIds}
            availableMerchantIds={availableMerchantIds}
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.micromobility}
          />
        </Column>
        {/* Right */}
        <Column>
          <OTPActivationSummary
            startDate={startDate.text}
            currentSegment={segmentsMap[segmentId]}
            transactionLimit={transactionLimit}
            activeOptions={activeOptions}
            activeMerchantIds={activeMicromobilityIds}
            availableMerchantIds={availableMerchantIds}
            handleOpenModal={handleOpenModal}
          />
        </Column>
      </ColumnView>
      <Program.Footer>
        <FlexContainer marginLeft='auto'>
          <Button
            variant='tertiary'
            marginRight='1rem'
            onClick={() => navigate(`/one-time-purchase/${segmentId}`)}
          >
            Cancel
          </Button>
          <Button disabled={noActiveOptions} onClick={handleActivate}>
            Activate
          </Button>
        </FlexContainer>
      </Program.Footer>
      <ServiceProviderSelectorModal
        open={isModalOpen}
        title='Available Service Providers'
        closeModal={() => {
          setIsModalOpen(false)
          SegmentService.track('modes-modal-toggle', {
            action: 'close',
            state: 'edit',
            location: 'otp-providers',
          })
        }}
        activeProviders={activeMicromobilityIds}
        availableProviders={availableMerchantIds}
        onSave={(newActiveProviders: string[]) =>
          handleProviderChange(newActiveProviders)
        }
        modeTypeFilters
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OTPActivationView.displayName = 'OTPActivationView'
}

export default OTPActivationView
