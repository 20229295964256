import Chip from 'core-system/Chip'
import MultiDropdown, { MultiSelectItemProps } from 'core-system/MultiDropdown'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import React from 'react'
import { Merchant, ServiceModeType } from 'redux/programs/programsTypes'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

const Container = styled.div<SpaceProps>`
  display: flex;
  gap: 1.5rem;
  flex-wrap: nowrap;
  align-items: start;

  ${space}
`

const CityFilterContainer = styled.div`
  display: flex;
  gap: 0.5rem 1rem;
  flex-shrink: 0;
  padding-right: 1.5rem;
  border-right: ${(props) => `1px solid ${props.theme.palette.grey.grey4}`};

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: column;
  }
`

const ModeTypeFilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0.5rem;
`

export enum Group {
  CITY,
  MODE_TYPE,
  SEARCH_TEXT,
  PARTNER,
}

export interface spFilter {
  name: string | number
  group: Group
  func: (...args) => boolean
}

interface ProviderFilterProps extends SpaceProps {
  currentFilters: spFilter[]
  setCurrentFilters: any
  availableCities: string[]
}

const ProviderFilter = React.memo((props: ProviderFilterProps) => {
  const { currentFilters, setCurrentFilters, availableCities, ...rest } = props
  const modeTypeFilters: ServiceModeType[] = [
    'CARSHARE',
    'CARPOOL',
    'BIKESHARE',
    'ESCOOTERSHARE',
    'EMOPED',
    'EBIKE',
    'ESCOOTER',
    'RIDESHARE',
    'EMOPEDSHARE',
    'SHUTTLE',
  ]

  // filter logic
  const filterExists = (name: string | number, group: Group) =>
    currentFilters.findIndex((f) => f.name === name && f.group === group) !== -1

  const toggleFilter = (name: string | number, group: Group, func) => {
    if (filterExists(name, group)) {
      setCurrentFilters(
        currentFilters.filter((f) => !(f.name === name && f.group === group))
      )
    } else {
      setCurrentFilters([...currentFilters, { name, group, func }])
    }
  }

  const activeMetroAreaItems: MultiSelectItemProps[] = currentFilters
    .filter((f) => f.group === Group.CITY)
    .map((f) => {
      const metroArea = f.name.toString()
      return {
        id: metroArea,
        text: ProgramUtils.metroAreaStrings[metroArea],
      }
    })

  return (
    <Container {...rest}>
      <CityFilterContainer>
        <MultiDropdown
          categoryName='Cities'
          items={availableCities
            .filter((c) => c)
            .map(
              (city) =>
                ({
                  id: city,
                  text: ProgramUtils.metroAreaStrings[city],
                } as MultiSelectItemProps)
            )}
          activeItems={activeMetroAreaItems}
          itemCallback={(cityItem: MultiSelectItemProps) =>
            toggleFilter(
              cityItem.id,
              Group.CITY,
              (m: Merchant) =>
                m.metroAreas.length === 0 || m.metroAreas.includes(cityItem.id)
            )
          }
          onClickCloseIcon={() =>
            setCurrentFilters(
              currentFilters.filter((f) => f.group !== Group.CITY)
            )
          }
          data-cy='merchants-catalog-cities-dropdown'
        />
        <Chip
          variant='filter'
          key='fleet partners'
          onClick={() =>
            toggleFilter(
              'fleet partner',
              Group.PARTNER,
              (m: Merchant) => m.fleetPartner
            )
          }
          selected={filterExists('fleet partner', Group.PARTNER)}
          data-cy='merchants-catalog-partners-btn'
        >
          Fleet Partners
        </Chip>
      </CityFilterContainer>
      <ModeTypeFilterContainer data-cy='merchants-catalog-mode-type-filters-container'>
        {modeTypeFilters.map((modeType) => (
          <Chip
            variant='filter'
            key={modeType}
            onClick={() =>
              toggleFilter(modeType, Group.MODE_TYPE, (m: Merchant) =>
                m.modeTypes.includes(modeType)
              )
            }
            selected={filterExists(modeType, Group.MODE_TYPE)}
          >
            {ProgramUtils.formatElectricModeTypes(RandomUtils.title(modeType))}
          </Chip>
        ))}
      </ModeTypeFilterContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProviderFilter.displayName = 'Provider Catalog Filter'
}

export default ProviderFilter
