import React from 'react'
import styled, { css } from 'styled-components'
import GridCard from 'employee-platform/shared/components/GridCard'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import { monthlyStatsUtils } from '../EmployeeDashboardUtils'
import palette from 'core-system/Themes/palette'
import FormattingUtils from 'shared/FormattingUtils'
import InsightsIcon from 'core-system/Icons/Sidebar/Insights'
import { FinchEmploymentTypes } from 'redux/employeePlatform/employeePlatformTypes'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StatContainer = styled.div<{ isLeft?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;

  ${(props) =>
    props.isLeft
      ? css`
          border-right: 1px solid ${props.theme.palette.grey.grey2};
        `
      : ''}
`

const StatValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

const SavingsStatsCard = React.memo(() => {
  const { dashboardStats, profileData } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  if (!dashboardStats) {
    return <Loading />
  }

  return (
    <GridCard
      headerTitle={`This Month's Stats`}
      headerIcon={<InsightsIcon color={palette.primary.primaryPurple} />}
    >
      {/* NOTE: if we end up adding more stats than just env and tax savings, we will need to make this a 2 column grid */}
      <Container>
        {/* TODO: make this more extensible (i.e. allow more stats) */}
        {Object.keys(dashboardStats).map((stat, idx) => {
          // Contractors will not be able to receive pretax benefits, so we should not be displaying this stat in their dashboard
          if (
            stat === 'taxSavings' &&
            profileData?.finchEmploymentType === FinchEmploymentTypes.CONTRACTOR
          ) {
            return null
          }

          return (
            <StatContainer key={stat} isLeft={!(idx % 2)}>
              <StatValueContainer>
                {monthlyStatsUtils[stat]?.icon}
                <Text variant='h2' textColor={palette.primary.primaryPurple}>
                  {FormattingUtils.formatMetric(
                    dashboardStats[stat],
                    monthlyStatsUtils[stat]?.type,
                    0
                  )}
                </Text>
              </StatValueContainer>
              <Text variant='action3' textColor={palette.text.secondary}>
                {monthlyStatsUtils[stat]?.title}
              </Text>
            </StatContainer>
          )
        })}
      </Container>
    </GridCard>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SavingsStatsCard.displayName = 'SavingsStatsCard'
}

export default SavingsStatsCard
