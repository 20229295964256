function hexToDec(hex: string): number {
  return parseInt(hex, 16)
}

/**
 * Fade a hex color. Converts color into rgba
 * @param color Hex color
 * @param alpha Opacity of new color
 */
export default function (color: string, alpha = 0) {
  const hex = color.substring(1)

  if (hex.length !== 6) return ''

  const r = hexToDec(hex.substring(0, 2))
  const g = hexToDec(hex.substring(2, 4))
  const b = hexToDec(hex.substring(4, 6))

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
