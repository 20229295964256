import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'
import {
  ApproveRequestResponsePayload,
  SearchedCarpoolGroup,
  CarpoolGroupCreatePayload,
  CarpoolMember,
  CarpoolRequest,
  CarpoolRequestToJoinRequestData,
  CarpoolSearchRequestPayload,
  DeclineRequestResponsePayload,
  EmployeeProfile,
  Charge,
  Payout,
  Card,
  Wallet,
  Payment,
  MyCarpoolGroup,
  PreTaxBalanceResponsePayload,
  PreTaxBenefitHistoryEntry,
  PreTaxEmployeeAllocationUpdatePayload,
  PreTaxEmployeeDeduction,
  PreTaxProgram,
  PreTaxProgramResponsePayload,
  MyCarpoolGroupResponsePayload,
  CarpoolRequestResponsePayload,
  CarpoolSearchResults,
  Competition,
  FAQ,
  FAQResponsePayload,
  TripMonth,
  AddTripPayload,
  // Merchant,
  Mode,
  Trip,
  BaseProgram,
  ProgramRequest,
  CreateProgramRequestPayload,
  OptIn,
  SetOptInChoiceData,
  TopUpPayload,
} from './employeePlatformTypes'
import moment from 'moment'

export interface EmployeePlatformState {
  // PROFILE
  employeePlatformLoading: boolean
  profileData: EmployeeProfile | null
  wallet: Wallet
  hasDOB: boolean
  dashboardStats: { [stat: string]: number | null } | null

  // PROGRAMS
  programs: {
    allPrograms: {
      [programType: string]: { [programId: string]: BaseProgram }
    } | null
    allProgramRequests: {
      [programId: string]: { [requestId: string]: ProgramRequest }
    } | null
  }

  // PRETAX
  preTaxPrograms: {
    [key: string]: PreTaxProgram | null
  }
  preTaxBenefitHistory: PreTaxBenefitHistoryEntry[]

  // CARPOOL
  allCarpoolGroups: { [id: string]: SearchedCarpoolGroup } | null
  myCarpoolGroups: { [id: string]: MyCarpoolGroup } | null
  carpoolMemberships: { [groupId: string]: CarpoolMember } | null
  receivedCarpoolRequests: {
    [groupId: string]: {
      [requestId: string]: CarpoolRequestResponsePayload
    }
  } | null
  sentCarpoolRequests: { [groupId: string]: CarpoolRequest } | null
  searchedCarpoolGroups: { [id: string]: SearchedCarpoolGroup } | null

  // TRIPS
  currentMonthTrips: { [month: number]: TripMonth } | null
  // allMerchants: Merchant[] | null
  allModes: { [modeId: string]: Mode } | null

  // LEADERBOARDS
  myLeaderboards: { [competitionId: string]: Competition } | null

  // OPT-IN
  optIn: {
    allOptIns: OptIn[]
  }

  // CONCIERGE
  faqs: FAQ[]
}

const initialState: EmployeePlatformState = {
  // PROFILE
  employeePlatformLoading: false,
  profileData: null,
  wallet: {
    currentBalance: 0,
    autoReload: {
      topAmount: null,
      topTrigger: null,
      paymentId: null,
      paymentType: null,
    },
    allCharges: null,
    allPayouts: null,
    pendingBalance: 0,
    paymentMethods: [],
    cards: [],
    rewards: {
      balance: 0,
      realBalance: 0,
    },
  },
  hasDOB: false,
  dashboardStats: null,

  // PROGRAMS
  programs: {
    allPrograms: null,
    allProgramRequests: null,
  },

  // PRETAX
  preTaxPrograms: {
    COMMUTER_PARKING: null,
    COMMUTER_TRANSIT: null,
  },
  preTaxBenefitHistory: [],

  // CARPOOL
  allCarpoolGroups: null, // This represents all carpool groups that the user neither owns nor is a member of
  myCarpoolGroups: null,
  carpoolMemberships: null,
  receivedCarpoolRequests: null,
  sentCarpoolRequests: null,
  searchedCarpoolGroups: null,

  // TRIPS
  currentMonthTrips: null,
  // allMerchants: null,
  allModes: null,

  // LEADERBOARDS
  myLeaderboards: null,

  // OPT-IN
  optIn: {
    allOptIns: null,
  },

  // CONCIERGE
  faqs: [],
}

// PROFILE ACTIONS
const getProfileData = createAction('employeePlatform/getProfileData')
const updateProfileData = createAction<EmployeeProfile>(
  'employeePlatform/updateProfileData'
)
const getWalletBalance = createAction('employeePlatform/getWalletBalance')
const getAllPayouts = createAction('employeePlatform/getAllPayouts')
const getAllRewards = createAction('employeePlatform/getAllRewards')
const getAllCharges = createAction('employeePlatform/getAllCharges')
const getDOB = createAction('employeePlatform/getDOB')
const getDashboardStats = createAction('employeePlatform/getDashboardStats')

// PAYMENT METHODS ACTIONS
const getAllPaymentMethods = createAction(
  'employeePlatform/getAllPaymentMethods'
)
const deletePaymentMethod = createAction<string>(
  'employeePlatform/deletePaymentMethod'
)

// CARDS ACTIONS
const getCards = createAction('employeePlatform/getCards')
const lockCard = createAction<string>('employeePlatform/lockCard')
const unlockCard = createAction<string>('employeePlatform/unlockCard')
const topUpCard = createAction<TopUpPayload>('employeePlatform/topUpCard')

// PROGRAMS ACTIONS
const getAllPrograms = createAction('employeePlatform/getAllPrograms')
const getAllProgramRequests = createAction(
  'employeePlatform/getAllProgramRequests'
)
const createProgramRequest = createAction<CreateProgramRequestPayload>(
  'employeePlatform/createProgramRequest'
)
const cancelProgramRequest = createAction<string>(
  'employeePlatform/cancelProgramRequest'
)

// PRETAX ACTIONS
const getPreTaxEmployeeDeduction = createAction(
  'employeePlatform/getPreTaxEmployeeDeduction'
)
const getPreTaxPrograms = createAction('employeePlatform/getPreTaxPrograms')
const getPreTaxBalance = createAction('employeePlatform/getPreTaxBalance')
const getPreTaxBenefitHistory = createAction(
  'employeePlatform/getPreTaxBenefitHistory'
)
const createPreTaxEmployeeAllocation =
  createAction<PreTaxEmployeeAllocationUpdatePayload>(
    'employeePlatform/createPreTaxEmployeeAllocation'
  )
const updatePreTaxEmployeeAllocation = createAction<PreTaxEmployeeDeduction>(
  'employeePlatform/updatePreTaxEmployeeAllocation'
)

// CARPOOL ACTIONS
const getAllCarpoolGroups = createAction('employeePlatform/getAllCarpoolGroups')
const getMyCarpoolGroups = createAction('employeePlatform/getMyCarpoolGroups')
const searchCarpoolGroups = createAction<CarpoolSearchRequestPayload>(
  'employeePlatform/searchCarpoolGroups'
)
const createCarpoolGroup = createAction<CarpoolGroupCreatePayload>(
  'employeePlatform/createCarpoolGroup'
)
const updateCarpoolGroup = createAction<CarpoolGroupCreatePayload>(
  'employeePlatform/updateCarpoolGroup'
)
const deleteCarpoolGroup = createAction<string>(
  'employeePlatform/deleteCarpoolGroup'
)
const getCarpoolGroupMemberships = createAction(
  'employeePlatform/getCarpoolGroupMemberships'
)
const getReceivedCarpoolRequests = createAction(
  'employeePlatform/getCarpoolGroupRequests'
)
const getSentCarpoolRequests = createAction(
  'employeePlatform/getSentCarpoolRequests'
)
const requestToJoinCarpool = createAction<CarpoolRequestToJoinRequestData>(
  'employeePlatform/requestToJoinCarpool'
)
const acceptCarpoolRequest = createAction<string>(
  'employeePlatform/approveCarpoolRequest'
)
const declineCarpoolRequest = createAction<string>(
  'employeePlatform/declineCarpoolRequest'
)

// TRIPS ACTIONS
const getCurrentMonthTrips = createAction<string>(
  'employeePlatform/getCurrentMonthTrips'
)
const addTrip = createAction<AddTripPayload>('employeePlatform/addTrip')
// const getAllMerchants = createAction('employeePlatform/getAllMerchants')
const getAllModes = createAction('employeePlatform/getAllModes')

// LEADERBOARDS ACTIONS
const getMyLeaderboards = createAction('employeePlatform/getMyLeaderboards')
const getLeaderboardRankings = createAction<string>(
  'employeePlatform/getLeaderboardRankings'
)

// OPT-IN ACTIONS
const getAllOptIns = createAction('employeePlatform/getAllOptIns')
const setOptInChoice = createAction<SetOptInChoiceData>(
  'employeePlatform/setOptInChoice'
)

// CONCIERGE ACTIONS
const getFAQs = createAction('employeePlatform/getFAQs')

// REDUCERS
const employeePlatformSlice = createSlice({
  name: 'employeePlatform',
  initialState,
  reducers: {
    // Overall Actions
    toggleEmployeePlatformLoading(state) {
      state.employeePlatformLoading = !state.employeePlatformLoading
    },

    // PROFILE REDUCERS
    getProfileDataSuccess(state, action: PayloadAction<EmployeeProfile>) {
      state.profileData = action.payload
    },
    updateProfileDataSuccess(state, action: PayloadAction<EmployeeProfile>) {
      state.profileData = action.payload
    },
    getWalletBalanceSuccess(state, action: PayloadAction<number>) {
      state.wallet.currentBalance = action.payload
    },
    getAllPayoutsSuccess(state, action: PayloadAction<Payout[]>) {
      state.wallet.allPayouts = action.payload
    },
    getAllRewardsSuccess(state, action: PayloadAction<any>) {
      state.wallet.rewards = action.payload
    },
    getAllChargesSuccess(state, action: PayloadAction<Charge[]>) {
      state.wallet.allCharges = action.payload
    },
    getDOBSuccess(state, action: PayloadAction<string | null>) {
      state.hasDOB = action.payload && action.payload !== '' ? true : false
    },
    getDashboardStatsSuccess(
      state,
      action: PayloadAction<{ [stat: string]: number | null }>
    ) {
      state.dashboardStats = action.payload
    },

    // PAYMENT METHODS REDUCERS
    getAllPaymentMethodsSuccess(state, action: PayloadAction<Payment[]>) {
      state.wallet.paymentMethods = action.payload
    },
    deletePaymentMethodSuccess(state, action: any) {
      state.wallet.paymentMethods = state.wallet.paymentMethods.filter(
        (payment) => payment.internalId !== action.meta
      )
    },

    // CARDS REDUCERS
    getCardsSuccess(state, action: PayloadAction<Card[]>) {
      state.wallet.cards = action.payload
    },
    lockCardSuccess(state, action: any) {
      state.wallet.cards = state.wallet.cards.map((card) => {
        if (card.internalId === action.meta) {
          return {
            ...card,
            status: 'inactive',
          }
        } else {
          return card
        }
      })
    },
    unlockCardSuccess(state, action: any) {
      state.wallet.cards = state.wallet.cards.map((card) => {
        if (card.internalId === action.meta) {
          return {
            ...card,
            status: 'active',
          }
        } else {
          return card
        }
      })
    },
    topUpCardSuccess() {
      // DO NOTHING, JUST REFETCH THE WALLET IN THE SAGA
    },

    // PROGRAMS REDUCERS
    getAllProgramsSuccess(state, action: PayloadAction<BaseProgram[]>) {
      if (!action.payload || action.payload.length === 0) {
        state.programs.allPrograms = {}
      } else {
        action.payload.forEach((program) => {
          if (!state.programs.allPrograms) {
            state.programs.allPrograms = {
              [program.type]: { [program.id]: program },
            }
          } else if (
            Object.keys(state.programs.allPrograms).includes(program.type)
          ) {
            state.programs.allPrograms[program.type] = {
              ...state.programs.allPrograms[program.type],
              [program.id]: program,
            }
          } else {
            state.programs.allPrograms[program.type] = { [program.id]: program }
          }
        })
      }
    },
    getAllProgramRequestsSuccess(
      state,
      action: PayloadAction<ProgramRequest[]>
    ) {
      if (!action.payload || action.payload.length === 0) {
        state.programs.allProgramRequests = {}
      } else {
        action.payload.forEach((request) => {
          if (!state.programs.allProgramRequests) {
            state.programs.allProgramRequests = {
              [request.programId]: { [request.id]: request },
            }
          } else if (
            Object.keys(state.programs.allProgramRequests).includes(
              request.programId
            )
          ) {
            state.programs.allProgramRequests[request.programId] = {
              ...state.programs.allProgramRequests[request.programId],
              [request.id]: request,
            }
          } else {
            state.programs.allProgramRequests[request.programId] = {
              [request.id]: request,
            }
          }
        })
      }
    },
    createProgramRequestSuccess(state, action: PayloadAction<ProgramRequest>) {
      const newRequest = action.payload
      if (!state.programs.allProgramRequests) {
        state.programs.allProgramRequests = {
          [newRequest.programId]: { [newRequest.id]: newRequest },
        }
      } else {
        // If there are existing request(s) for the new request's program
        if (
          Object.keys(state.programs.allProgramRequests).includes(
            newRequest.programId
          )
        ) {
          state.programs.allProgramRequests[newRequest.programId] = {
            ...state.programs.allProgramRequests[newRequest.programId],
            [newRequest.id]: newRequest,
          }
          // If there are no existing requests for the new request's program
        } else {
          state.programs.allProgramRequests[newRequest.programId] = {
            [newRequest.id]: newRequest,
          }
        }
      }
    },
    cancelProgramRequestSuccess() {
      // DO NOTHING, JUST REFETCH THE PROGRAM REQUESTS IN THE SAGA
    },

    // PRETAX REDUCERS
    getPreTaxEmployeeDeductionSuccess(
      state,
      action: PayloadAction<PreTaxEmployeeDeduction[]>
    ) {
      action.payload.forEach((deduction) => {
        if (state.preTaxPrograms) {
          const programToUpdate = Object.keys(state.preTaxPrograms).find(
            (type) => state.preTaxPrograms[type].id === deduction.benefit
          )
          state.preTaxPrograms[programToUpdate].employeeDeduction = deduction
        }
      })
    },
    getPreTaxProgramsSuccess(
      state,
      action: PayloadAction<PreTaxProgramResponsePayload[]>
    ) {
      action.payload.forEach((program) => {
        state.preTaxPrograms[program.type] = {
          ...program,
          balance: null,
          cycleBalance: null,
          employeeDeduction: null,
        }
      })
    },
    getPreTaxBalanceSuccess(
      state,
      action: PayloadAction<PreTaxBalanceResponsePayload>
    ) {
      if (state.preTaxPrograms['COMMUTER_PARKING']) {
        state.preTaxPrograms['COMMUTER_PARKING'].balance =
          action.payload[0]?.parkingBalance
        state.preTaxPrograms['COMMUTER_PARKING'].cycleBalance =
          action.payload[0]?.parkingCycleBalance
      }
      if (state.preTaxPrograms['COMMUTER_TRANSIT']) {
        state.preTaxPrograms['COMMUTER_TRANSIT'].balance =
          action.payload[0]?.transitBalance
        state.preTaxPrograms['COMMUTER_TRANSIT'].cycleBalance =
          action.payload[0]?.transitCycleBalance
      }
    },
    getPreTaxBenefitHistorySuccess(
      state,
      action: PayloadAction<PreTaxBenefitHistoryEntry[]>
    ) {
      state.preTaxBenefitHistory = action.payload
    },
    createPreTaxEmployeeAllocationSuccess(
      state,
      action: PayloadAction<PreTaxEmployeeDeduction>
    ) {
      const newAllocation = action.payload
      const programTypeToUpdate = Object.keys(state.preTaxPrograms).find(
        (type) => state.preTaxPrograms[type]?.id === newAllocation.benefit
      )
      state.preTaxPrograms[programTypeToUpdate].employeeDeduction =
        newAllocation
    },
    updatePreTaxEmployeeAllocationSuccess(
      state,
      action: PayloadAction<PreTaxEmployeeDeduction>
    ) {
      const updatedAllocation = action.payload
      const programTypeToUpdate = Object.keys(state.preTaxPrograms).find(
        (type) => state.preTaxPrograms[type]?.id === updatedAllocation.benefit
      )
      state.preTaxPrograms[
        programTypeToUpdate
      ].employeeDeduction.employeeDeduction =
        updatedAllocation.employeeDeduction
    },

    // CARPOOL REDUCERS
    getAllCarpoolGroupsSuccess(
      state,
      action: PayloadAction<SearchedCarpoolGroup[]>
    ) {
      if (action.payload && action.payload.length === 0) {
        state.allCarpoolGroups = {}
      } else {
        action.payload.forEach((group) => {
          if (state.allCarpoolGroups) {
            state.allCarpoolGroups[group.id] = group
          } else {
            state.allCarpoolGroups = { [group.id]: group }
          }
        })
      }
    },
    getMyCarpoolGroupsSuccess(
      state,
      action: PayloadAction<MyCarpoolGroupResponsePayload[]>
    ) {
      if (action.payload.length === 0) {
        state.myCarpoolGroups = {}
      } else {
        action.payload.forEach((group) => {
          if (state.myCarpoolGroups) {
            state.myCarpoolGroups[group.id] = {
              ...group,
              members: group.members.reduce((members, member) => {
                return {
                  ...members,
                  [member.id]: member,
                }
              }, {}),
            }
          } else {
            state.myCarpoolGroups = {
              [group.id]: {
                ...group,
                members: group.members.reduce((members, member) => {
                  return {
                    ...members,
                    [member.id]: member,
                  }
                }, {}),
              },
            }
          }
        })
      }
    },
    searchCarpoolGroupsSuccess(
      state,
      action: PayloadAction<CarpoolSearchResults>
    ) {
      if (!action.payload.groups || action.payload.groups.length === 0) {
        state.searchedCarpoolGroups = {}
      } else {
        state.searchedCarpoolGroups = action.payload.groups.reduce(
          (groups, group) => {
            groups[group.id] = group
            return groups
          },
          {}
        )
      }
    },
    createCarpoolGroupSuccess(
      state,
      action: PayloadAction<MyCarpoolGroupResponsePayload>
    ) {
      if (state.myCarpoolGroups) {
        state.myCarpoolGroups[action.payload.id] = {
          ...action.payload,
          members: {},
        }
      } else {
        state.myCarpoolGroups = {
          [action.payload.id]: {
            ...action.payload,
            members: {},
          },
        }
      }
    },
    updateCarpoolGroupSuccess(
      state,
      action: PayloadAction<MyCarpoolGroupResponsePayload>
    ) {
      if (state.myCarpoolGroups) {
        state.myCarpoolGroups[action.payload.id] = {
          ...action.payload,
          members: action.payload.members.reduce((members, member) => {
            return {
              ...members,
              [member.id]: member,
            }
          }, {}),
        }
      } else {
        state.myCarpoolGroups = {
          [action.payload.id]: {
            ...action.payload,
            members: action.payload.members.reduce((members, member) => {
              return {
                ...members,
                [member.id]: member,
              }
            }, {}),
          },
        }
      }
    },
    deleteCarpoolGroupSuccess(state, action: PayloadAction<string>) {
      if (state.myCarpoolGroups) {
        delete state.myCarpoolGroups[action.payload]
      }
    },
    getCarpoolGroupMembershipsSuccess(
      state,
      action: PayloadAction<CarpoolMember[]>
    ) {
      if (action.payload.length === 0) {
        state.carpoolMemberships = {}
      } else {
        action.payload.forEach((membership) => {
          if (state.carpoolMemberships) {
            state.carpoolMemberships[membership.carpoolGroup.id] = membership
          } else {
            state.carpoolMemberships = {
              [membership.carpoolGroup.id]: membership,
            }
          }
        })
      }
    },
    getReceivedCarpoolRequestsSuccess(
      state,
      action: PayloadAction<CarpoolRequestResponsePayload[]>
    ) {
      if (action.payload.length === 0) {
        state.receivedCarpoolRequests = {}
      } else {
        action.payload.forEach((request) => {
          if (state.receivedCarpoolRequests) {
            if (state.receivedCarpoolRequests[request.carpoolGroupId]) {
              state.receivedCarpoolRequests[request.carpoolGroupId][
                request.id
              ] = request
            } else {
              state.receivedCarpoolRequests[request.carpoolGroupId] = {
                [request.id]: request,
              }
            }
          } else {
            state.receivedCarpoolRequests = {
              [request.carpoolGroupId]: { [request.id]: request },
            }
          }
        })
      }
    },
    getSentCarpoolRequestsSuccess(
      state,
      action: PayloadAction<CarpoolRequest[]>
    ) {
      if (action.payload.length === 0) {
        state.sentCarpoolRequests = {}
      } else {
        action.payload.forEach((request) => {
          if (state.sentCarpoolRequests) {
            state.sentCarpoolRequests[request.carpoolGroup.id] = request
          } else {
            state.sentCarpoolRequests = { [request.carpoolGroup.id]: request }
          }
        })
      }
    },
    requestToJoinCarpoolSuccess(state, action: PayloadAction<CarpoolRequest>) {
      if (state.sentCarpoolRequests) {
        state.sentCarpoolRequests[action.payload.carpoolGroup.id] =
          action.payload
      } else {
        state.sentCarpoolRequests = {
          [action.payload.carpoolGroup.id]: action.payload,
        }
      }
    },
    acceptCarpoolRequestSuccess(
      state,
      action: PayloadAction<ApproveRequestResponsePayload>
    ) {
      if (
        state.myCarpoolGroups &&
        state.myCarpoolGroups[action.payload.carpoolGroupId]
      ) {
        state.myCarpoolGroups[action.payload.carpoolGroupId].seatsLeft =
          state.myCarpoolGroups[action.payload.carpoolGroupId].seatsLeft - 1
      }
      if (
        state.receivedCarpoolRequests &&
        state.receivedCarpoolRequests[action.payload.carpoolGroupId]
      ) {
        delete state.receivedCarpoolRequests[action.payload.carpoolGroupId][
          action.payload.carpoolRequestId
        ]
      }
    },
    declineCarpoolRequestSuccess(
      state,
      action: PayloadAction<DeclineRequestResponsePayload>
    ) {
      if (
        state.receivedCarpoolRequests &&
        state.receivedCarpoolRequests[action.payload.carpoolGroupId]
      ) {
        delete state.receivedCarpoolRequests[action.payload.carpoolGroupId][
          action.payload.carpoolRequestId
        ]
      }
    },

    // TRIPS REDUCERS
    getCurrentMonthTripsSuccess(
      state,
      action: PayloadAction<{ [month: string]: TripMonth }>
    ) {
      state.currentMonthTrips = action.payload
    },
    addTripSuccess(state, action: PayloadAction<Trip>) {
      const tripYearMonth = Number(moment(action.payload.date).format('YYYYMM'))
      const tripDate = Number(moment(action.payload.date).format('YYYYMMDD'))
      if (state.currentMonthTrips && state.currentMonthTrips[tripYearMonth]) {
        if (state.currentMonthTrips[tripYearMonth].trips[tripDate]) {
          state.currentMonthTrips[tripYearMonth].trips[tripDate].push(
            action.payload
          )
        } else {
          state.currentMonthTrips[tripYearMonth].trips[tripDate] = [
            action.payload,
          ]
        }
      }
    },
    // getAllMerchantsSuccess(state, action: PayloadAction<Merchant[]>) {
    //   state.allMerchants = action.payload
    // },
    getAllModesSuccess(state, action: PayloadAction<Mode[]>) {
      action.payload.forEach((mode) => {
        if (state.allModes) {
          state.allModes[mode.id] = mode
        } else {
          state.allModes = { [mode.id]: mode }
        }
      })
    },

    // LEADERBOARDS REDUCERS
    getMyLeaderboardsSuccess(state, action: PayloadAction<Competition[]>) {
      if (action.payload.length === 0) {
        state.myLeaderboards = {}
      } else {
        state.myLeaderboards = action.payload.reduce(
          (competitions, competition) => {
            return {
              ...competitions,
              [competition.id]: {
                ...competition,
                rankings: null,
                myRank: null,
              },
            }
          },
          {}
        )
      }
    },
    getLeaderboardRankingsSuccess(state, action: any) {
      if (state.myLeaderboards && state.myLeaderboards[action.meta]) {
        state.myLeaderboards[action.meta] = {
          ...state.myLeaderboards[action.meta],
          rankings: action.payload.rankings,
          myRank:
            action.payload.myRank && action.payload.myRank.length > 0
              ? action.payload.myRank[0]
              : null,
        }
      }
    },

    // OPT-IN REDUCERS
    getAllOptInsSuccess(state, action: PayloadAction<OptIn[]>) {
      if (!action.payload || action.payload.length === 0) {
        state.optIn.allOptIns = []
      } else {
        state.optIn.allOptIns = action.payload
      }
    },
    setOptInChoiceSuccess() {
      // DO NOTHING, JUST REFETCH THE OPT-INS IN THE SAGA
    },

    // CONCIERGE REDUCERS
    getFAQsSuccess(state, action: PayloadAction<FAQResponsePayload>) {
      state.faqs = action.payload.faq
    },
  },
})

export const employeePlatformReducer = employeePlatformSlice.reducer
export const employeePlatformActions = {
  ...employeePlatformSlice.actions,

  // PROFILE
  getProfileData,
  updateProfileData,
  getWalletBalance,
  getAllPayouts,
  getAllRewards,
  getAllCharges,
  getDOB,
  getDashboardStats,

  // PAYMENT METHODS
  getAllPaymentMethods,
  deletePaymentMethod,

  // CARDS
  getCards,
  lockCard,
  unlockCard,
  topUpCard,

  // PROGRAMS
  getAllPrograms,
  getAllProgramRequests,
  createProgramRequest,
  cancelProgramRequest,

  // PRETAX
  getPreTaxEmployeeDeduction,
  getPreTaxPrograms,
  getPreTaxBalance,
  getPreTaxBenefitHistory,
  createPreTaxEmployeeAllocation,
  updatePreTaxEmployeeAllocation,

  // CARPOOL
  getAllCarpoolGroups,
  getMyCarpoolGroups,
  searchCarpoolGroups,
  createCarpoolGroup,
  updateCarpoolGroup,
  deleteCarpoolGroup,
  getCarpoolGroupMemberships,
  getReceivedCarpoolRequests,
  getSentCarpoolRequests,
  requestToJoinCarpool,
  acceptCarpoolRequest,
  declineCarpoolRequest,

  // TRIPS
  getCurrentMonthTrips,
  addTrip,
  // getAllMerchants,
  getAllModes,

  // LEADERBOARDS
  getMyLeaderboards,
  getLeaderboardRankings,

  // OPT-IN
  getAllOptIns,
  setOptInChoice,

  // CONCIERGE
  getFAQs,
}
