import { MapStyles } from 'core-system/Map'
import { Expression } from 'mapbox-gl'
import palette from 'core-system/Themes/palette'
import { LayerProps } from 'react-map-gl'

const micromobilityStyle: LayerProps = {
  id: 'employees',
  type: 'circle',
  beforeId: 'settlement-major-label',
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      [
        'case',
        [
          'all',
          ['==', ['get', 'bike900'], false],
          ['==', ['get', 'withinTransit'], false],
        ],
        MapStyles.pointSize.smallHidden,
        MapStyles.pointSize.smallNormal,
      ],
      15,
      [
        'case',
        [
          'all',
          ['==', ['get', 'bike900'], false],
          ['==', ['get', 'withinTransit'], false],
        ],
        MapStyles.pointSize.largeHidden,
        MapStyles.pointSize.largeNormal,
      ],
    ] as Expression,
    'circle-color': [
      'case',
      ['==', ['get', 'bike900'], true],
      palette.primary.primaryPurple,
      ['==', ['get', 'withinTransit'], true],
      '#C30078',
      palette.secondary.lavender2,
    ] as Expression,
    'circle-stroke-width': MapStyles.pointStyle.pointStrokeWidth,
    'circle-stroke-color': MapStyles.pointStyle.pointStrokeColor,
    'circle-opacity': MapStyles.pointStyle.pointOpacity,
    'circle-stroke-opacity': MapStyles.pointStyle.pointStrokeOpacity,
  },
}

const shapeOpacity = [
  'interpolate',
  ['linear'],
  ['zoom'],
  3,
  0.8,
  10,
  0.6,
  15,
  0.4,
] as Expression

const isochroneStyle: LayerProps = {
  id: 'isochroneFill',
  type: 'fill',
  beforeId: 'settlement-minor-label',
  paint: {
    'fill-color': palette.secondary.purple4,
    'fill-opacity': shapeOpacity,
  },
}

const shapeOutlineSize = [
  'interpolate',
  ['linear'],
  ['zoom'],
  3,
  0.5,
  10,
  1.5,
  15,
  3,
] as Expression

const isochroneOutlineStyle: LayerProps = {
  id: 'isochroneOutline',
  type: 'line',
  beforeId: 'settlement-minor-label',
  paint: {
    'line-color': palette.secondary.purple3,
    'line-opacity': 0.7,
    'line-width': shapeOutlineSize,
  },
}

export default {
  micromobilityStyle,
  isochroneStyle,
  isochroneOutlineStyle,
}
