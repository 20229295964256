import Button from 'core-system/Button'
import RadialChart from 'core-system/charts/Radial'
import RadialChartUtils from 'core-system/charts/Radial/RadialChartUtils'
import Chip, { statusMap } from 'core-system/Chip/Chip'
import FleetError from 'core-system/Error/FleetError'
import ClearIcon from 'core-system/Icons/Actions/Clear'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React, { useState } from 'react'
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import {
  IncentiveGroup,
  TripMode,
  TripStats,
} from 'redux/incentives/incentivesTypes'
import NoPaymentModal from 'shared/components/NoPaymentModal'
import NumberUtils from 'shared/NumberUtils'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'

export const activeStatuses = ['UPDATING', 'ACTIVE']

const transitModeTypes = [
  'bus',
  'ferry',
  'commrail',
  'heavyrail',
  'literail',
  'monorail',
  'aerial',
  'funicular',
]

export const graphColors = [
  '#C30078',
  '#4EC7C4',
  palette.primary.primaryPurple,
  palette.primary.primaryDark,
  '#5FCD6A',
  '#3A78FF',
  '#C19CFF',
  '#FF7076',
  '#7ADBF8',
  palette.text.secondary,
  palette.chips.yellow2,
  palette.secondary.green1,
  palette.chips.grey1,
  palette.chips.yellow1,
  palette.secondary.green2,
  palette.chips.teal1,
  palette.chips.blue1,
  palette.chips.blue2,
  palette.chips.magenta1,
  palette.chips.magenta2,
  palette.chips.purple1,
  palette.chips.purple2,
  palette.secondary.lavender1,
  palette.secondary.lavender2,
]

const chartOptions = {
  ...RadialChartUtils.defaultChartOptions,
  legend: { display: false },
  cutoutPercentage: 69,
}

const getModeProgressStyles = () => {
  return buildStyles({
    strokeLinecap: 'round',
    pathColor: palette.chips.blue1,
    trailColor: palette.chips.blue2,
  })
}

const Container = styled.div`
  position: absolute;
  right: 0;
  top: 5rem;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.text.primary};
  width: ${(props) => props.theme.pxToRem(272)};
  height: calc(100% - 5rem);
  border-left: 1px solid ${(props) => props.theme.palette.grey.grey3};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  padding: 2.5rem 1.5rem;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    visibility: hidden;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Content = styled.div`
  flex: 1;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey3};
  height: 100%;
  display: flex;
  flex-direction: column;
`

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const ChartContainer = styled.div`
  position: relative;
  width: ${(props) => props.theme.pxToRem(175)};
  height: ${(props) => props.theme.pxToRem(175)};
`

const TotalTrips = styled.div`
  ${(props) => props.theme.typography.h2}
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
`

const ChartDescription = styled(Text)`
  color: ${(props) => props.theme.palette.text.placeholder};
  text-transform: uppercase;
  letter-spacing: 0.7px;
`

const ChartLegend = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
`

const Mode = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`

const CustomLegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0;
  margin: 0.125rem 0 0;
  border-radius: 0.25rem;
`

const CustomLegendColor = styled.div<{ background: string }>`
  background-color: ${(props) => props.background};
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`

const MaskSelfies = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey3};
`

const StatText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CircularProgressContainer = styled.div`
  height: ${(props) => props.theme.pxToRem(52)};
  width: ${(props) => props.theme.pxToRem(52)};
  color: ${(props) => props.theme.palette.chips.blue1};
  margin-right: 1rem;
`

const Commuters = styled.div`
  margin: 1rem 0 1.5rem;
`

const CommuterRow = styled.div`
  display: flex;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple6};
    cursor: pointer;
  }
`

const CommuterText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.875rem;
`

const StyledButton = styled(Button)`
  margin-top: 1.5rem;
  width: 100%;
`

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
`

const IconContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  color: ${(props) => props.theme.palette.text.placeholder};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const StyledFleetError = styled(FleetError)`
  flex: 1;
  margin: 0 0 4.5rem 1.5rem;
  img {
    width: ${(props) => props.theme.pxToRem(139)};
  }
`

const getChartData = (modetypes: TripMode[]) => {
  const labels = []

  const modetypeCount = modetypes.reduce((obj, modetype) => {
    if (transitModeTypes.includes(modetype.modetype.toLowerCase())) {
      obj['TRANSIT'] = obj['TRANSIT'] + modetype.numTrips || modetype.numTrips
    } else {
      const updatedMode = ProgramUtils.formatElectricModeTypes(
        modetype.modetype
      )
      obj[updatedMode] =
        obj[updatedMode] + modetype.numTrips || modetype.numTrips
    }
    return obj
  }, {})

  const data = Object.keys(modetypeCount)
    .map((modetype) => {
      return { modeType: modetype, numTrips: modetypeCount[modetype] }
    })
    .sort((a, b) => b.numTrips - a.numTrips)
    .map((group) => {
      const viewLabel = `${RandomUtils.capitalize(group.modeType)}, ${
        modetypeCount[group.modeType]
      } ${modetypeCount[group.modeType] === 1 ? 'Trip leg' : 'Trip legs'}`
      labels.push(viewLabel)
      return group.numTrips
    })

  return {
    datasets: [
      {
        data: data,
        backgroundColor: graphColors,
        hoverBackgroundColor: graphColors,
        borderWidth: 0,
      },
    ],
    labels: labels,
  }
}

const displayBarChartLegend = (
  colors: string[],
  labels: string[],
  data: number[],
  totalTrips: number
) => {
  return labels.map((label: string, idx: number) => (
    <CustomLegendItem key={idx}>
      <Mode>
        <CustomLegendColor background={colors[idx]} />
        <Text variant='body2'>{label.split(',')[0]}</Text>
      </Mode>
      <Text variant='body2' textColor={palette.text.secondary}>
        {Math.round((100 * data[idx]) / totalTrips)}% Trip legs
      </Text>
    </CustomLegendItem>
  ))
}

const getIncentiveStatus = (incentives: IncentiveGroup) => {
  //commenting out until deal with FC-1374
  // if (!incentives) {
  //   return 'DISABLED'
  // } else if (
  //   incentives.active &&
  //   incentives.nextMonth &&
  //   incentives.nextMonth.status === 'CANCELLING'
  // ) {
  //   return incentives.nextMonth.status
  // } else if (incentives.active) {
  //   return incentives.active.status
  // } else {
  //   return incentives.nextMonth.status
  // }
  if (!incentives) {
    return 'DISABLED'
  } else if (incentives.active) {
    return incentives.active.status
  } else {
    return incentives.nextMonth.status
  }
}

interface TripsOverviewProps {
  date: string
  activeTripStats: TripStats
  setDaySelected: (dayNumber: number) => void
  activeSegmentId: string
  onEmployeeClick: (id: string) => void
}

const TripsOverview = React.memo((props: TripsOverviewProps) => {
  const {
    date,
    activeTripStats,
    activeSegmentId,
    setDaySelected,
    onEmployeeClick,
  } = props

  const navigate = useNavigate()
  const { allIncentives } = useSelector((state: AppState) => state.incentives)
  const { segmentsMap, hasValidPaymentMethod, profile } = useSelector(
    (state: AppState) => state.employer
  )

  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)

  if (!activeTripStats) {
    return (
      <Container>
        <Loading isCard={false} height='100%' spinnerSize={45} />
      </Container>
    )
  }

  const { commuters, multiMode, modetypes, employeeEngaged } = activeTripStats

  const multiModePerc = Math.round(
    (multiMode / activeTripStats.totalTrips) * 100
  )

  const chartData = getChartData(modetypes)
  const incentiveStatus =
    allIncentives && getIncentiveStatus(allIncentives[activeSegmentId])
  const allModes = modetypes.reduce((sum, { numTrips }) => sum + numTrips, 0)

  const handleIncentivesClick = (
    incentiveStatus: string,
    activeSegmentId: string
  ) => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      navigate(
        incentiveStatus === 'DISABLED'
          ? `/trips/${activeSegmentId}/incentives/activation`
          : `/trips/${activeSegmentId}/incentives/manage`,
        {
          state: { from: `trips/${activeSegmentId}` },
        }
      )
      SegmentService.track('incentive-manage-action', {
        action: ['DISABLED', 'CANCELLED'].includes(incentiveStatus)
          ? 'sidebar-add'
          : 'manage',
        segmentName: segmentsMap[activeSegmentId].name,
      })
    } else {
      setIsNoPaymentModalOpen(true)
    }
  }

  return (
    <Container>
      {!allIncentives ? (
        <Loading isCard={false} height={pxToRem(73)} spinnerSize={35} />
      ) : (
        <>
          <Header>
            <Text variant='h5'>Incentives</Text>
            <Chip variant={statusMap[incentiveStatus].variant}>
              {statusMap[incentiveStatus].text}
            </Chip>
          </Header>
          <StyledButton
            size='small'
            data-cy='trips-overview-incentives-btn'
            onClick={() =>
              handleIncentivesClick(incentiveStatus, activeSegmentId)
            }
          >
            {statusMap[incentiveStatus].btn}
          </StyledButton>
          <NoPaymentModal
            open={isNoPaymentModalOpen}
            closeModal={() => setIsNoPaymentModalOpen(false)}
            product='incentives'
          />
        </>
      )}
      <Content>
        <DateContainer>
          <Text variant='h5'>
            {activeTripStats.day
              ? moment(activeTripStats.day).format('MMMM Do, YYYY')
              : `${moment(date).format('MMMM')} Overview`}
          </Text>
          {activeTripStats.day && (
            <IconContainer onClick={() => setDaySelected(null)}>
              <ClearIcon />
            </IconContainer>
          )}
        </DateContainer>
        {allModes > 0 ? (
          <>
            <Text variant='action3' marginBottom='1.5rem'>
              Commute Modes
            </Text>
            <CenterContainer>
              <ChartContainer>
                <TotalTrips>
                  {NumberUtils.formatNumber(activeTripStats.totalTrips)}
                  <ChartDescription variant='caption'>
                    total trips
                  </ChartDescription>
                </TotalTrips>
                <RadialChart
                  data={chartData}
                  options={chartOptions}
                  width={pxToRem(175)}
                  height={pxToRem(175)}
                />
              </ChartContainer>
            </CenterContainer>
            <ChartLegend>
              {displayBarChartLegend(
                chartData.datasets[0].backgroundColor,
                chartData.labels,
                chartData.datasets[0].data,
                allModes
              )}
            </ChartLegend>
            <MaskSelfies>
              <CircularProgressContainer>
                <CircularProgressbarWithChildren
                  styles={getModeProgressStyles()}
                  strokeWidth={10}
                  counterClockwise={true}
                  value={multiModePerc}
                >
                  <Text variant='action3'>{multiModePerc}%</Text>
                </CircularProgressbarWithChildren>
              </CircularProgressContainer>
              <StatText>
                <Text variant='action3'>Multi-Modal Trips</Text>
                <Text variant='body2' textColor={palette.text.secondary}>
                  {multiMode} Trip{RandomUtils.pluralCheck(multiMode)}
                </Text>
              </StatText>
            </MaskSelfies>
            <div>
              <Text variant='action3'>Top Commuters ({employeeEngaged})</Text>
              <Commuters>
                {commuters.slice(0, 5).map((commuter, idx) => {
                  return (
                    <CommuterRow
                      key={idx}
                      onClick={() => onEmployeeClick(commuter.id)}
                    >
                      <PlatformSvg
                        folder='users'
                        variant='userBasic'
                        width={40}
                        height={40}
                      />
                      <CommuterText>
                        <Text variant='action4'>{commuter.name}</Text>
                        <Text
                          variant='body2'
                          textColor={palette.text.placeholder}
                        >
                          {commuter.numTrips} Trip
                          {RandomUtils.pluralCheck(commuter.numTrips)} Logged
                        </Text>
                      </CommuterText>
                    </CommuterRow>
                  )
                })}
              </Commuters>
            </div>
          </>
        ) : (
          <StyledFleetError description='No Trips Logged' />
        )}
      </Content>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TripsOverview.displayName = 'TripsOverview'
}

export default TripsOverview
