import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  grid,
  GridProps,
} from 'styled-system'
import { color, ColorProps } from '../@styled-system/Color'
import { buildForwardingComponent } from '../utils/buildComponent'
import PortraitIcon from '../Icons/Misc/Employees'
import CalendarIcon from '../Icons/Misc/Calendar'
import DollarIcon from '../Icons/Misc/Money'
import LocationIcon from '../Icons/Misc/Location'
import LoadingBar from '../LoadingBar'
import LinkIcon from 'core-system/Icons/Actions/Link'

const icons = {
  employee: PortraitIcon,
  time: CalendarIcon,
  location: LocationIcon,
  dollar: DollarIcon,
  link: LinkIcon,
}

const Container = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps &
    GridProps &
    ColorProps & {
      equalSize: boolean
      fontType: fontTypeVariants
    }
>`
  ${(props) => props.theme.typography[props.fontType]}
  display: flex;
  flex: ${(props) => (props.equalSize ? '1' : 'none')};
  margin-right: 1.5rem;
  position: relative;
  
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    ${grid}
  }

  ${flexbox}
  ${space}
  ${layout}
  ${color}
`

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Text = styled.div`
  display: flex;
`

const IconContainer = styled.div`
  display: flex;
  margin-right: 0.375rem;
`

const Content = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: ${(props) => props.theme.pxToRem(26)};
`

const RowLoading = styled(LoadingBar)`
  height: 1.4375rem;
  width: ${(props) => props.theme.pxToRem(200)};
  margin: 0;
`

const Label = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.placeholder};
`

type fontTypeVariants = 'body2' | 'action4'

export interface TableRowCellProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    GridProps,
    ColorProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  icon?: 'employee' | 'time' | 'location' | 'dollar' | 'link'
  equalSize?: boolean
  isLoading?: boolean
  fontType?: fontTypeVariants
  label?: string
}

const TableRowCell = React.forwardRef<HTMLDivElement, TableRowCellProps>(
  (props: TableRowCellProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      children,
      icon = null,
      equalSize = true,
      isLoading = false,
      fontType = 'action4',
      label,
      ...rest
    } = props
    const Icon = icon && icons[icon]
    return (
      <Container ref={ref} equalSize={equalSize} fontType={fontType} {...rest}>
        {isLoading ? (
          <RowLoading />
        ) : (
          <Cell>
            {label && <Label>{label}</Label>}
            <Text>
              {icon && (
                <IconContainer>
                  <Icon />
                </IconContainer>
              )}
              <Content>{children}</Content>
            </Text>
          </Cell>
        )}
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TableRowCell.displayName = 'TableRowCell'
}

export default buildForwardingComponent(TableRowCell)
