import React, { useState } from 'react'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import Loading from 'core-system/Loading'
import GRHSummaryCard from './components/GRHSummaryCard'
import GRHInfoCard from './components/GRHInfoCard'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'

const EmployeeGRHOverviewView = React.memo(() => {
  const { allPrograms } = useSelector(
    (state: AppState) => state.employeePlatform.programs
  )

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  // This is assuming that an employee can only be a part of at most one GRH program
  const grhProgram = allPrograms?.GRH ? Object.values(allPrograms.GRH)[0] : null

  if (!allPrograms) {
    return <Loading />
  }

  return (
    <GridContainer numColumns={2}>
      <GridContainer numColumns={1} fitContentHeight>
        <GRHSummaryCard grhProgram={grhProgram} isMobile={isMobile} />
      </GridContainer>
      <GridContainer numColumns={1} fitContentHeight>
        <GRHInfoCard isMobile={isMobile} />
      </GridContainer>
    </GridContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeGRHOverviewView.displayName = 'EmployeeGRHOverviewView'
}

export default EmployeeGRHOverviewView
