import React from 'react'
import * as SidebarIcons from 'core-system/Icons/Sidebar'
import * as MicromobilityIcons from 'core-system/Icons/Micromobility'
import { Car as CarIcon } from 'core-system/Icons/modeTypes'

export interface EmployerProduct {
  productLabel: string
  productName: string
  icon: React.ReactElement
}

const allProducts = new Map([
  [
    'COMPETITIONS',
    {
      productLabel: 'COMPETITIONS',
      productName: 'Competitions',
      icon: <SidebarIcons.Leaderboards />,
    },
  ],
  [
    'FLEX',
    {
      productLabel: 'FLEX',
      productName: 'Flex',
      icon: <SidebarIcons.Flex />,
    },
  ],
  [
    'INCENTIVES',
    {
      productLabel: 'INCENTIVES',
      productName: 'Incentives',
      icon: <SidebarIcons.MapIntelligence />,
    },
  ],
  [
    'GRH',
    {
      productLabel: 'GRH',
      productName: 'Guaranteed Ride Home',
      icon: <CarIcon />,
    },
  ],
  [
    'MICROMOBILITY',
    {
      productLabel: 'MICROMOBILITY',
      productName: 'Micromobility',
      icon: <SidebarIcons.Micromobility />,
    },
  ],
  [
    'OTP',
    {
      productLabel: 'OTP',
      productName: 'One Time Purchases',
      icon: <MicromobilityIcons.OneTimePurchase />,
    },
  ],
  [
    'OTR',
    {
      productLabel: 'OTR',
      productName: 'One Time Rewards',
      icon: <SidebarIcons.Incentives />,
    },
  ],
  [
    'QTF',
    {
      productLabel: 'QTF',
      productName: 'Pre-Tax Commuter Benefits',
      icon: <SidebarIcons.QTF />,
    },
  ],
])

export default {
  allProducts,
}
