import Banner from 'core-system/Banner'
import Loading from 'core-system/Loading'
import { ProgramMissingPaymentMethod } from 'core-system/Program'
import ProgramHeader from 'core-system/Program/ProgramHeader'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getClientEnvironment } from 'redux/config/services/Constants'
import { AppState } from 'redux/config/store'
import { FlexProgram } from 'redux/flex/flexTypes'
import { ProgramGroup } from 'redux/programs/programsTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import FlexPreviewModal from './components/FlexPreviewModal'
import FlexOverviewView from './FlexOverviewView'
import NoPaymentModal from 'shared/components/NoPaymentModal'

const FlexView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const navigate = useNavigate()

  const flexProgramsLoaded = useSelector(
    (state: AppState) => state.flex.flexProgramsLoaded
  )
  const flexPrograms = useSelector((state: AppState) => state.flex.flexPrograms)
  const {
    segmentsMap,
    profile,
    hasValidPaymentMethod,
    hasValidDistributionType,
  } = useSelector((state: AppState) => state.employer)

  const [programGroup, setProgramGroup] = useState<ProgramGroup>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [noPaymentModalOpen, setNoPaymentModalOpen] = useState(false)

  useEffect(() => {
    setProgramGroup({
      active: flexPrograms[segmentId] && flexPrograms[segmentId].active,
      nextMonth: flexPrograms[segmentId] && flexPrograms[segmentId].nextMonth,
    })
  }, [flexPrograms, segmentId])

  const handleGetStartedPress = () => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      navigate(`/flex/${segmentId}/activation`)
    } else {
      setNoPaymentModalOpen(true)
    }
  }

  if (!flexProgramsLoaded || !profile || !programGroup) {
    return <Loading fullPage />
  }

  if (
    flexPrograms &&
    (!programGroup.active || programGroup.active.segment !== segmentId) &&
    (!programGroup.nextMonth || programGroup.nextMonth.segment !== segmentId)
  ) {
    return (
      <>
        <ProgramHeader segmentId={segmentId} />
        <Banner
          variant='cta'
          title='Start your Flex Program today!'
          description={`Give ${
            segmentsMap[segmentId].name === 'All Employees'
              ? 'All Commuters'
              : segmentsMap[segmentId].name
          } flexible and fun commuting options.`}
          cta='Get Started'
          ctaCallback={() => handleGetStartedPress()}
          background='bannerGeneral3'
        />
        <FlexPreviewModal
          open={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          segmentId={segmentId}
        />
        <NoPaymentModal
          open={noPaymentModalOpen}
          closeModal={() => setNoPaymentModalOpen(false)}
          product={'flex'}
        />
      </>
    )
  }

  const isValid = hasValidDistributionType && hasValidPaymentMethod
  const isNextMonth = !programGroup.active && programGroup.nextMonth
  const isCancelling =
    !programGroup.active &&
    programGroup.nextMonth &&
    programGroup.nextMonth.status === 'CANCELLING'
  const isPending = isNextMonth && !isCancelling
  const isDevCheck =
    getClientEnvironment() === 'development' && !isPending && !isCancelling
  const isOverviewCheck = programGroup.active && isValid

  return (
    <>
      <ProgramHeader
        segmentId={segmentId}
        manageRoute={
          isDevCheck || isOverviewCheck ? `/flex/${segmentId}/manage` : null
        }
      />
      {isPending && (
        <Banner
          variant='cta'
          background='bannerGeneral3'
          title='Starting Soon!'
          description={`Your Flex Program is scheduled to start on ${moment(
            programGroup.nextMonth.startDate
          ).format('MMMM Do, YYYY')}.`}
          cta='Manage Program'
          ctaCallback={() => navigate(`/flex/${segmentId}/manage`)}
        />
      )}
      {isCancelling && (
        <Banner
          variant='cta'
          background='bannerGeneral3'
          title='Program Cancelling'
          description='Your program cancellation request has been submitted. Our team will reach out soon.'
          cta='Manage Program'
          ctaCallback={() => navigate(`/flex/${segmentId}/manage`)}
        />
      )}
      {isDevCheck ? (
        <FlexOverviewView activeProgram={programGroup.active as FlexProgram} />
      ) : (
        <>
          {!isValid && (
            <ProgramMissingPaymentMethod from={`/flex/${segmentId}`} />
          )}
          {isOverviewCheck && (
            <FlexOverviewView
              activeProgram={programGroup.active as FlexProgram}
            />
          )}
        </>
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FlexView.displayName = 'FlexView'
}

export default FlexView
