import React from 'react'
import Table, {
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableRowContents,
  TableRowCell,
} from './index'

const getHeaderItems = (headerItems: number) => {
  return [...Array(headerItems)].map((_element: number, idx: number) => (
    <TableHeaderItem key={idx} isLoading={true} />
  ))
}

const getRowItems = (rowItems: number, rowCells: number) => {
  return [...Array(rowItems)].map((_element: number, idx: number) => (
    <TableRow key={idx}>
      <TableRowContents isLoading={true}>
        {[...Array(rowCells)].map((_element: number, idx: number) => (
          <TableRowCell key={idx} isLoading={true} />
        ))}
      </TableRowContents>
    </TableRow>
  ))
}

export interface TableLoadingProps {
  headerItems?: number
  rowItems?: number
  rowCells?: number
}

const TableLoading = React.memo((props: TableLoadingProps) => {
  const { headerItems = 3, rowItems = 3, rowCells = 5 } = props
  return (
    <Table>
      <TableHeader>{getHeaderItems(headerItems)}</TableHeader>
      {getRowItems(rowItems, rowCells)}
    </Table>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TableLoading.displayName = 'TableLoading'
}

export default TableLoading
