import React from 'react'
import styled from 'styled-components'
import { variants } from './Dropdown'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  variant,
} from 'styled-system'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import SelectItemV2 from './SelectItemV2'

const Container = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps &
    PositionProps & {
      open: boolean
      coords: any
      selectorMaxHeight: string
    }
>`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.dropdowns};
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.white};
  width: 100%;
  display: ${(props) => (props.open ? 'block' : 'none')};
  max-height: ${(props) => props.selectorMaxHeight};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 0.75rem;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: ${palette.grey.grey3};
    background-clip: content-box;
    border: 3px solid transparent;
  }

  ${(props) =>
    variant({
      variants: {
        textual: {
          border: `1px solid ${props.theme.palette.grey.grey3}`,
          borderRadius: pxToRem(4),
          top: props.theme.pxToRem(36),
        },
        header: {
          border: `1px solid ${props.theme.palette.grey.grey3}`,
          borderRadius: pxToRem(4),
          top: props.theme.pxToRem(46),
        },
        contained: {
          border: `1px solid ${props.theme.palette.grey.grey10}`,
          borderRadius: `0 0 ${pxToRem(4)} ${pxToRem(4)}`,
          top: props.theme.pxToRem(41),
        },
      },
    })}

  // if portal is used
  ${(props) => props.coords}

  ${flexbox}
  ${space}
  ${layout}
  ${position}
`

const handleClick = (
  e: React.SyntheticEvent,
  item: SelectItemProps,
  itemCallback: (item: SelectItemProps) => void,
  setIsOpen: (open: boolean) => void,
  isPlaceholder: boolean,
  setIsPlaceholder: (visible: boolean) => void
) => {
  e.stopPropagation()
  if (!item.disabled) {
    itemCallback(item)
    setIsOpen(false)
    if (isPlaceholder) {
      setIsPlaceholder(false)
    }
  }
}

interface CoordsProps {
  top?: string
  left?: string
  right?: string
  bottom?: string
  width?: string
  height?: string
}

export interface SelectItemProps {
  id: string
  text: string
  disabled?: boolean
  [key: string]: any
}

interface SelectProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    PositionProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  items: SelectItemProps[]
  open: boolean
  itemCallback: (item: SelectItemProps) => void
  setIsOpen: (open: boolean) => void
  active?: string
  isPlaceholder?: boolean
  setIsPlaceholder?: (visible: boolean) => void
  variant?: variants
  coords?: CoordsProps
  ref?: React.MutableRefObject<any>
  selectorMaxHeight?: string
}

const SelectV2 = React.memo((props: SelectProps) => {
  const {
    items,
    active,
    open,
    variant = 'contained',
    isPlaceholder,
    coords,
    setIsPlaceholder,
    itemCallback,
    setIsOpen,
    selectorMaxHeight,
  } = props

  return (
    <Container
      open={open}
      variant={variant}
      coords={coords}
      selectorMaxHeight={selectorMaxHeight}
      {...props}
    >
      {items.map((item: SelectItemProps, idx: number) => (
        <SelectItemV2
          key={idx}
          active={active === item.id}
          disabled={item.disabled}
          onClick={(e) =>
            handleClick(
              e,
              item,
              itemCallback,
              setIsOpen,
              isPlaceholder,
              setIsPlaceholder
            )
          }
        >
          {item.text}
        </SelectItemV2>
      ))}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SelectV2.displayName = 'Select'
}

export default SelectV2
