import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { generateTaxReminderReducerAnnouncements } from 'features/Concierge/shared/utils'
import moment from 'moment'
import {
  Announcement,
  AnnouncementCreate,
  Faqs,
} from 'redux/concierge/conciergeTypes'
import DateUtils from 'shared/DateUtils'

export interface ConciergeState {
  announcements: Announcement[] | null
  faqs: Faqs | null
  conciergeSuccessAction: boolean
}

const initialState: ConciergeState = {
  announcements: null,
  faqs: null,
  conciergeSuccessAction: null,
}

const processTaxReminderAnnouncements = (rawAnnouncement: any) => {
  // first time, only upcoming
  if (rawAnnouncement.sentAt === null) {
    //  return future announcements 1 set
    const firstTimeAnnouncements = generateTaxReminderReducerAnnouncements(
      rawAnnouncement,
      rawAnnouncement.scheduledFor,
      true
    )
    return firstTimeAnnouncements
  }
  // return from initial to current month
  const today = moment()
  const scheduled = moment(rawAnnouncement.scheduledFor, 'YYYY-MM-DD hh:mm:ss')
  const announcementsRange = DateUtils.getRange(scheduled, today, 'months')

  const generatedAnnouncements = announcementsRange.map(
    (date: any, idx: number) =>
      generateTaxReminderReducerAnnouncements(
        rawAnnouncement,
        date,
        idx === announcementsRange.length - 1
      )
  )

  return generatedAnnouncements.flat(1)
}

const getAnnouncements = createAction('concierge/getAnnouncements')

const createAnnouncement = createAction<AnnouncementCreate>(
  'concierge/createAnnouncement'
)
const updateAnnouncement = createAction<Announcement>(
  'concierge/updateAnnouncement'
)
const deleteAnnouncement = createAction<string>('concierge/deleteAnnouncement')
const getFaqs = createAction('concierge/getFaqs')
const updateFaq = createAction<Faqs>('concierge/updateFaq')

const conciergeSlice = createSlice({
  name: 'concierge',
  initialState,
  reducers: {
    getAnnouncementsSuccess(state, action: PayloadAction<Announcement[]>) {
      state.announcements = !state.announcements
        ? action.payload
        : [...state.announcements, ...action.payload]
    },
    getAutomatedAnnouncementsSuccess(
      state,
      action: PayloadAction<Announcement[]>
    ) {
      const rawData = action.payload
      const formatted =
        rawData &&
        rawData
          .map((rawAnnouncement: any) => {
            if (rawAnnouncement.name === 'employee_tax_reminder') {
              return processTaxReminderAnnouncements(rawAnnouncement)
            }

            return {
              automated: true,
              id: rawAnnouncement.id,
              name: rawAnnouncement.name,
              segment: rawAnnouncement.segmentId,
              createdAt: rawAnnouncement.createdAt,
              status: rawAnnouncement.status,
              title: rawAnnouncement.title,
              htmlBody: '',
              distributionPlatforms: rawAnnouncement.platforms,
              body: rawAnnouncement.body,
              scheduledFor: rawAnnouncement.scheduledFor,
              sentAt: rawAnnouncement.sentAt,
              userType: rawAnnouncement.userType,
              productType: rawAnnouncement.productType,
              extraData: {
                reminderDay: rawAnnouncement?.reminderDay || null,
                extraTemplateInfo: rawAnnouncement?.extraTemplateData || null,
              },
            } as Announcement
          })
          .flat(1)

      state.announcements = !state.announcements
        ? formatted
        : [...state.announcements, ...formatted]
    },
    createAnnouncementSuccess(state, action: PayloadAction<Announcement>) {
      state.conciergeSuccessAction = true
      state.announcements = [...state.announcements, action.payload]
    },
    updateAnnouncementSuccess(state, action: PayloadAction<Announcement>) {
      state.announcements = state.announcements.map((ann: Announcement) =>
        ann.id !== action.payload.id ? ann : action.payload
      )
      state.conciergeSuccessAction = true
    },
    deleteAnnouncementSuccess(state, action: any) {
      state.announcements = state.announcements.map((ann: Announcement) => {
        return ann.id === action.meta
          ? {
              ...ann,
              status: 'Cancelled',
            }
          : ann
      })
    },
    getFaqsSuccess(state, action: PayloadAction<Faqs>) {
      state.faqs = action.payload
    },
    updateFaqSuccess(state, action: PayloadAction<Faqs>) {
      state.conciergeSuccessAction = true
      state.faqs = action.payload
    },
    cancelConciergeSuccess(state) {
      state.conciergeSuccessAction = false
    },
  },
})

export const conciergeReducer = conciergeSlice.reducer
export const conciergeActions = {
  ...conciergeSlice.actions,
  getAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  getFaqs,
  updateFaq,
}
