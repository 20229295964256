import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import Select, { SelectItemProps } from './Select'
import ModeSelect from './ModeSelect'
import CaretIcon from '../Icons/Actions/Caret'
import palette from '../Themes/palette'
import Portal from '../Portal'
import { modeTypeMap } from './ModeSelect'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  variant,
} from 'styled-system'
import pxToRem from 'core-system/utils/pxToRem'
import MultiSelectList from 'core-system/MultiSelect/MultiSelectList'

const Container = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & { variant: variants }
>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: ${(props) => (props.variant === 'textual' ? '0.25rem' : 0)};
`

const Content = styled.div<{
  open: boolean
  isPlaceholder: boolean
  containedBorderColor: string
  containedBackgroundColor: string
  error: boolean
}>`
  color: ${(props) => props.theme.palette.text.primary};
  width: 100%;
  display: flex;
  cursor: pointer;
  width: ${(props) => props.theme.pxToRem(200)};

  ${(props) =>
    variant({
      variants: {
        textual: {
          borderRadius: '0.5rem',
          padding: '0.25rem 0.375rem',
          backgroundColor: props.open
            ? props.theme.palette.secondary.purple5
            : props.theme.palette.white,
          '&:hover': {
            backgroundColor: props.open
              ? props.theme.palette.secondary.purple5
              : props.theme.palette.secondary.purple6,
          },
        },
        header: {
          borderRadius: '0.5rem',
          padding: '0.375rem 0.625rem',
          alignItems: 'center',
          backgroundColor: props.open
            ? props.theme.palette.secondary.purple5
            : 'transparent',
          width: props.theme.pxToRem(232),
          '&:hover': {
            backgroundColor: props.theme.palette.secondary.purple5,
          },
        },
        contained: {
          border: `1px solid ${props.containedBorderColor}`,
          borderRadius: props.open ? '0.5rem 0.5rem 0 0' : '0.5rem',
          padding: '0.5rem 0.25rem 0.5rem 1rem',
          backgroundColor: props.containedBackgroundColor,
          color:
            props.isPlaceholder && !props.open
              ? props.theme.palette.text.placeholder
              : props.theme.palette.text.primary,
          '&:hover': {
            border: `1px solid ${
              props.error && !props.open
                ? props.theme.palette.secondary.red1
                : props.theme.palette.text.secondary
            }`,
            color:
              props.isPlaceholder && !props.open
                ? props.theme.palette.text.secondary
                : props.theme.palette.text.primary,
          },
        },
      },
    })}

  ${flexbox}
  ${space}
  ${layout}
`

const SvgContainer = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & PositionProps & { open: boolean }
>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background-color: ${(props) =>
    props.open ? props.theme.palette.secondary.purple5 : 'unset'};

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple5};
  }

  ${flexbox}
  ${space}
  ${layout}
  ${position}
`

const Placeholder = styled.div<{
  variant: string
  icon: boolean
  multiSelect?: boolean
}>`
  ${(props) =>
    props.variant === 'header'
      ? props.theme.typography.h3
      : props.theme.typography.action4};
  display: flex;
  padding-top: 0.0625rem;
  align-items: center;
  overflow: ${(props) => (props.multiSelect ? '' : 'hidden')};
  margin-left: ${(props) =>
    props.variant === 'textual' && !props.icon ? '0.25rem' : 0};
`

const Text = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const BubbleText = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  text-overflow: ellipsis;
  width: 100%;
`

const Bubble = styled.div`
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.palette.secondary.purple6};
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
`

const IconContainer = styled.div`
  width: ${(props) => props.theme.pxToRem(24)};
  height: ${(props) => props.theme.pxToRem(24)};
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CaretWrapper = styled.div<{ open: boolean }>`
  width: ${(props) => props.theme.pxToRem(24)};
  height: ${(props) => props.theme.pxToRem(24)};
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
  transition: all 0.2s ease-in-out;
  margin-left: auto;
`

const Label = styled.div`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: 0.25rem;
`

const Error = styled.div`
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.palette.secondary.red1};
  margin-top: 0.25rem;
`

const getContainedBorderColor = (isOpen: boolean, error: boolean) => {
  if (isOpen) {
    return palette.text.secondary
  } else if (error) {
    return palette.secondary.red1
  } else {
    return palette.grey.grey3
  }
}

const getContainedBackgroundColor = (isOpen: boolean, error: boolean) => {
  if (error && !isOpen) {
    return palette.secondary.red2
  } else if (isOpen) {
    return palette.white
  } else {
    return palette.secondary.purple7
  }
}

const getActiveElement = (
  items: SelectItemProps[],
  active: string,
  isModeSelector: boolean
) => {
  const match = items.find((item: SelectItemProps) => item.id === active)?.text

  return isModeSelector ? modeTypeMap[match].text : match
}

export type variants = 'textual' | 'contained' | 'header'

interface DropdownProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    PositionProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  items: SelectItemProps[]
  active: string
  itemCallback?: (item: SelectItemProps) => void
  svgDropdown?: boolean
  variant?: variants
  icon?: React.ReactElement
  placeholder?: string
  error?: boolean
  errorMsg?: string
  label?: string
  usePortal?: boolean
  isModeSelector?: boolean
  selectorMaxHeight?: string
  activeItems?: string[]
  onItemCallback?: (id: string) => void
  multiDropdown?: boolean
  disabledItemIds?: Set<string>
}

const Dropdown = React.memo((props: DropdownProps) => {
  const {
    items,
    active,
    icon,
    placeholder,
    variant = 'contained',
    error = false,
    usePortal = false,
    errorMsg,
    label,
    itemCallback,
    svgDropdown,
    isModeSelector,
    selectorMaxHeight = pxToRem(350),
    activeItems,
    onItemCallback,
    multiDropdown = false,
    disabledItemIds,
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [isPlaceholder, setIsPlaceholder] = useState(placeholder && true)
  const [dropdownCoors, setCoordinates] = useState(null)

  const containerRef = useRef(null)
  const dropdownRef = useRef(null)
  const selectRef = useRef(null)

  useEffect(() => {
    if ((placeholder && true) !== isPlaceholder) {
      setIsPlaceholder(active === null ? placeholder && true : false)
    }
  }, [active, placeholder, isPlaceholder])

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        (containerRef &&
          containerRef.current &&
          containerRef.current.contains(e.target)) ||
        (usePortal &&
          selectRef &&
          selectRef.current &&
          selectRef.current.contains(e.target))
      ) {
        return
      }
      setIsOpen(false)
    },
    [containerRef, selectRef, usePortal]
  )

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside, false)
    return () => {
      document.removeEventListener('mousedown', clickOutside, false)
    }
  }, [clickOutside])

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      setIsOpen(!isOpen)

      if (usePortal) {
        const coors = dropdownRef.current.getBoundingClientRect()
        const variantBuffer = variant === 'textual' ? 4 : -1
        setCoordinates({
          left: `${coors.x}px`,
          top: `${coors.y + coors.height + variantBuffer}px`,
          width: `${coors.width}px`,
        })
      }
    },
    [isOpen, usePortal, variant]
  )

  if (svgDropdown) {
    return (
      <div ref={containerRef}>
        <SvgContainer open={isOpen} onClick={handleClick} id='dropdown-icon'>
          {icon && icon}
        </SvgContainer>
        <Select
          variant={variant}
          items={items}
          active={active}
          open={isOpen}
          setIsOpen={setIsOpen}
          itemCallback={itemCallback}
          {...props}
        />
      </div>
    )
  }

  return (
    <>
      {label && <Label>{label}</Label>}
      <Container variant={variant} ref={containerRef}>
        <Content
          variant={variant}
          isPlaceholder={isPlaceholder}
          containedBorderColor={getContainedBorderColor(isOpen, error)}
          containedBackgroundColor={getContainedBackgroundColor(isOpen, error)}
          onClick={handleClick}
          open={isOpen}
          error={error}
          ref={dropdownRef}
          {...props}
        >
          {icon && <IconContainer>{icon}</IconContainer>}
          <Placeholder
            variant={variant}
            icon={icon && true}
            multiSelect={multiDropdown}
          >
            {multiDropdown ? (
              <BubbleText>
                {!placeholder.includes('Select all that apply')
                  ? placeholder
                      ?.split('\u0000')
                      .map((part, index) => <Bubble key={index}>{part}</Bubble>)
                  : placeholder}
              </BubbleText>
            ) : (
              <Text>
                {(isPlaceholder || (!isPlaceholder && placeholder)) &&
                active === null
                  ? placeholder
                  : getActiveElement(items, active, isModeSelector)}
              </Text>
            )}
          </Placeholder>
          <CaretWrapper open={isOpen}>
            <CaretIcon color={palette.text.primary} height={24} width={24} />
          </CaretWrapper>
        </Content>
        {usePortal ? (
          <Portal>
            <div ref={selectRef}>
              <Select
                variant={variant}
                items={items}
                active={active}
                open={isOpen}
                isPlaceholder={isPlaceholder}
                setIsPlaceholder={setIsPlaceholder}
                setIsOpen={setIsOpen}
                itemCallback={itemCallback}
                coords={dropdownCoors}
                selectorMaxHeight={selectorMaxHeight}
                data-cy='dropdown-portal-options-container'
              />
            </div>
          </Portal>
        ) : (
          <>
            {isModeSelector ? (
              <ModeSelect
                items={items}
                active={active}
                open={isOpen}
                isPlaceholder={isPlaceholder}
                setIsPlaceholder={setIsPlaceholder}
                setIsOpen={setIsOpen}
                itemCallback={itemCallback}
              />
            ) : multiDropdown ? (
              <MultiSelectList
                items={items}
                activeItems={activeItems}
                onItemCallback={onItemCallback}
                variant={variant}
                open={isOpen}
                selectorMaxHeight={selectorMaxHeight}
                disabledItemIds={disabledItemIds}
              />
            ) : (
              <Select
                variant={variant}
                items={items}
                active={active}
                open={isOpen}
                isPlaceholder={isPlaceholder}
                setIsPlaceholder={setIsPlaceholder}
                setIsOpen={setIsOpen}
                itemCallback={itemCallback}
                selectorMaxHeight={selectorMaxHeight}
                data-cy='dropdown-options-container'
              />
            )}
          </>
        )}
      </Container>
      {error && errorMsg && <Error>{errorMsg}</Error>}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  Dropdown.displayName = 'Dropdown'
}

export default Dropdown
