import { ColumnCard } from 'core-system/ColumnView'
import SummaryBudget from 'core-system/Program/Summary/SummaryBudget'
import SummaryOverview from 'core-system/Program/Summary/SummaryOverview'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import FlexSummaryOptions from 'features/Flex/Shared/components/FlexSummaryOptions'
import React from 'react'
import { Segment } from 'redux/employer/employerTypes'
import { FlexActiveOptions } from 'redux/flex/flexTypes'
import styled from 'styled-components'

const Container = styled(ColumnCard)`
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
`

interface FlexActivationSummaryProps {
  startDate: string
  currentSegment: Segment
  budget: number
  activeOptions: FlexActiveOptions
  activeMerchantIds: string[]
  availableMerchantIds: string[]
  handleOpenModal: () => void
}

const FlexActivationSummary = React.memo(
  (props: FlexActivationSummaryProps) => {
    const {
      startDate,
      currentSegment,
      budget,
      activeOptions,
      activeMerchantIds,
      availableMerchantIds,
      handleOpenModal,
    } = props

    return (
      <Container>
        <Text variant='h4' marginBottom='1.5rem'>
          My Flex Program
        </Text>
        <SummaryOverview
          startDate={startDate}
          currentSegment={currentSegment}
          marginBottom='1rem'
        />
        <FlexSummaryOptions
          activeOptions={activeOptions}
          activeMerchantIds={activeMerchantIds}
          availableMerchantIds={availableMerchantIds}
          handleOpenModal={handleOpenModal}
          canEdit
        />
        <SummaryBudget
          title='Total Monthly Budget'
          metric='per commuter'
          numEmps={currentSegment.commuters}
          budget={budget}
          marginBottom='1.5rem'
        />
        <Text variant='body2' textColor={palette.text.placeholder}>
          All budget amounts are estimated based on max commuter spend.
        </Text>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FlexActivationSummary.displayName = 'FlexActivationSummary'
}

export default FlexActivationSummary
