import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import LockIcon from 'core-system/Icons/Misc/Lock'
import * as SegmentIcons from 'core-system/Icons/Segments'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import ConnectHris from 'features/Segments/components/ConnectHris'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import SegmentsCreateModal from './components/SegmentsCreateModal'
import SegmentsSidebarItem from './components/SegmentsSidebarItem'
import Tooltip from 'core-system/Tooltip'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: 1rem 0;
  }
`

const HideOnPhoneContainer = styled(FlexContainer)`
  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    display: none;
  }
`

const ArchivedHideOnPhoneContainer = styled(FlexContainer)`
  cursor: pointer;
  user-select: none;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    display: none;
  }

  &:hover {
    #archivedIcon {
      background-color: ${(props) => props.theme.palette.secondary.purple5};
    }
  }
`

const ShowOnPhoneContainer = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    display: block;
    padding-right: 1rem;
    border-right: 2px solid ${(props) => props.theme.palette.grey.grey1};
    margin-right: 1rem;
  }
`

const Segments = styled.div`
  flex: 1;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div:nth-child(2) {
    margin-top: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    display: flex;
  }
`

const IconContainer = styled.div<{ isOpen: boolean }>`
  padding: 0.25rem;
  border-radius: 0.5rem;
  height: 2rem;
  transform: rotate(${(props) => (props.isOpen ? '0deg' : '-90deg')});
`

const ArchivedContainer = styled.div<{ isOpen: boolean }>`
  max-height: ${(props) => (props.isOpen ? '400px' : 0)};
  overflow: hidden;
`

const CSVUploadButtonContainer = styled.div`
  cursor: pointer;
`

const Bottom = styled.div`
  margin-top: auto;
  height: ${(props) => props.theme.pxToRem(169)};

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    display: none;
  }
`

interface SegmentsSidebarProps {
  segmentsType: Dictionary<Segment[]>
  activeSegment: string
  handleSegmentChange: (params: {
    segmentId: string
    creationType?: number
  }) => void
  handleOpenModal: () => void
}

const SegmentsSidebar = React.memo((props: SegmentsSidebarProps) => {
  const { segmentsType, activeSegment, handleSegmentChange, handleOpenModal } =
    props

  const hrisStatus = useSelector((state: AppState) => ({
    providerName: state.employer.profile?.payrollProviderId,
    reauth: state.employer.profile?.finchReauth,
  }))

  const { archivedEmployees, declinedEmployees } = useSelector(
    (state: AppState) => state.employee
  )

  const ref = useRef(null)

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isArchivedOpen, setIsArchivedOpen] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)

  const hasDeclinedEmployees = declinedEmployees && declinedEmployees.length > 0
  const hasArchivedEmployees = archivedEmployees && archivedEmployees.length > 0
  const hasArchivedSegments =
    segmentsType?.archived && segmentsType.archived.length > 0
  const hasArchived =
    hasArchivedEmployees || hasArchivedSegments || hasDeclinedEmployees

  return (
    <>
      <Container>
        <Segments>
          <ShowOnPhoneContainer>
            <Button
              size='small'
              width={pxToRem(100)}
              height='100%'
              onClick={() => {
                setIsCreateModalOpen(true)
                SegmentService.track('commuters-modal-action', {
                  action: 'open',
                })
              }}
            >
              New Group
            </Button>
          </ShowOnPhoneContainer>
          <SegmentsSidebarItem
            name='All Commuters'
            icon={<SegmentIcons.AllCommuters />}
            isActive={segmentsType?.all[0].id === activeSegment}
            numEmployees={segmentsType?.all[0].commuters || 0}
            onClick={() =>
              handleSegmentChange({ segmentId: segmentsType?.all[0].id })
            }
            data-cy={`segments-sidebar-${segmentsType?.all[0].id}`}
          />
          <HideOnPhoneContainer marginTop='1rem'>
            <Text
              variant='body2'
              textColor={palette.text.secondary}
              marginRight='0.25rem'
            >
              Worksites
            </Text>
            <Text variant='body2' textColor={palette.text.disabled}>
              (Autogenerated)
            </Text>
          </HideOnPhoneContainer>
          {segmentsType?.worksite &&
            segmentsType.worksite.map((segment) => (
              <SegmentsSidebarItem
                key={segment.id}
                name={segment.name}
                icon={<SegmentIcons.Worksite />}
                numEmployees={segment.commuters}
                isActive={segment.id === activeSegment}
                onClick={() => {
                  handleSegmentChange({ segmentId: segment.id })
                  SegmentService.track('commuters-group-select', {
                    groupName: segment.name,
                    employeeCount: segment.commuters,
                  })
                }}
                data-cy={`segments-sidebar-${segment.id}`}
              />
            ))}
          {segmentsType?.custom && (
            <>
              <HideOnPhoneContainer>
                <Text
                  variant='body2'
                  textColor={palette.text.secondary}
                  marginTop='1rem'
                >
                  Custom Groups
                </Text>
              </HideOnPhoneContainer>
              {segmentsType.custom.map((segment) => (
                <SegmentsSidebarItem
                  key={segment.id}
                  name={segment.name}
                  icon={<SegmentIcons.CustomGroup />}
                  numEmployees={segment.commuters}
                  isActive={segment.id === activeSegment}
                  onClick={() => {
                    handleSegmentChange({
                      segmentId: segment.id,
                    })
                    SegmentService.track('commuters-group-select', {
                      groupName: segment.name,
                      employeeCount: segment.commuters,
                    })
                  }}
                  data-cy={`segments-sidebar-${segment.id}`}
                />
              ))}
            </>
          )}
          {hasArchived && (
            <>
              <ArchivedHideOnPhoneContainer
                alignItems='center'
                justifyContent='space-between'
                marginTop='1rem'
                onClick={() => setIsArchivedOpen((prev) => !prev)}
              >
                <Text
                  variant='body2'
                  textColor={palette.text.secondary}
                  marginTop='0.125rem'
                >
                  Archived
                </Text>
                <IconContainer id='archivedIcon' isOpen={isArchivedOpen}>
                  <ChevronIcon color={palette.primary.primaryDark} />
                </IconContainer>
              </ArchivedHideOnPhoneContainer>
              <ArchivedContainer isOpen={isArchivedOpen}>
                {hasArchivedEmployees && (
                  <SegmentsSidebarItem
                    name='Archived Commuters'
                    icon={<SegmentIcons.AllCommuters />}
                    isActive={'archived' === activeSegment}
                    numEmployees={archivedEmployees.length}
                    onClick={() =>
                      handleSegmentChange({ segmentId: 'archived' })
                    }
                  />
                )}
                {hasDeclinedEmployees && (
                  <SegmentsSidebarItem
                    name='Declined Commuters'
                    icon={<LockIcon />}
                    isActive={'declined' === activeSegment}
                    numEmployees={declinedEmployees.length}
                    onClick={() =>
                      handleSegmentChange({ segmentId: 'declined' })
                    }
                  />
                )}
                {hasArchivedSegments &&
                  segmentsType?.archived.map((segment) => (
                    <SegmentsSidebarItem
                      key={segment.id}
                      name={segment.name}
                      icon={
                        segment.type === 'WORKSITE' ? (
                          <SegmentIcons.Worksite />
                        ) : (
                          <SegmentIcons.CustomGroup />
                        )
                      }
                      numEmployees={segment.commuters}
                      isActive={segment.id === activeSegment}
                      disabled={segment.type === 'CUSTOM' && true}
                      onClick={() => {
                        handleSegmentChange({ segmentId: segment.id })
                        SegmentService.track('commuters-group-select', {
                          groupName: segment.name,
                          employeeCount: segment.commuters,
                        })
                      }}
                    />
                  ))}
              </ArchivedContainer>
            </>
          )}
        </Segments>
        <Bottom>
          <Button
            width='100%'
            size='small'
            onClick={() => {
              setIsCreateModalOpen(true)
              SegmentService.track('commuters-modal-action', {
                action: 'open',
              })
            }}
            marginTop='1rem'
            dataCy='create-new-group'
          >
            Create New Group
          </Button>
          <Divider margin='1rem 0' />
          <ConnectHris
            providerName={hrisStatus.providerName}
            reAuth={hrisStatus.reauth}
            width='100%'
          />
          <CSVUploadButtonContainer
            ref={ref}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <Button
              width='100%'
              size='small'
              variant='secondary'
              onClick={handleOpenModal}
              marginTop='1rem'
              disabled={hrisStatus?.providerName ? true : false}
            >
              Import Commuter CSV
            </Button>
          </CSVUploadButtonContainer>
          {hrisStatus?.providerName ? (
            <Tooltip
              target={ref}
              show={showTooltip}
              position='right'
              marginBottom='0.25rem'
              marginLeft='0.25rem'
            >
              You cannot import commuters via CSV while connected to a payroll
              provider through HRIS
            </Tooltip>
          ) : null}
        </Bottom>
      </Container>
      <SegmentsCreateModal
        open={isCreateModalOpen}
        closeModal={() => {
          setIsCreateModalOpen(false)
          SegmentService.track('commuters-modal-action', {
            action: 'close',
          })
        }}
        handleSegmentChange={handleSegmentChange}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SegmentsSidebar.displayName = 'SegmentsSidebar'
}

export default SegmentsSidebar
