import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'

const Container = styled(FlexContainer)`
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ContentWrapper = styled(FlexContainer)`
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
`

interface MyCommuterInviteProps {
  employeeId: string
}

const MyCommuterInvite = React.memo((props: MyCommuterInviteProps) => {
  const { employeeId } = props

  const hasInvite = useSelector(
    (state: AppState) => state.employee.myCommuterDetails[employeeId].hasInvite
  )

  return (
    <Container>
      <ContentWrapper maxWidth={pxToRem(550)}>
        <PlatformSvg
          folder='dashboard'
          variant='dashboardInvite'
          width='200px'
        />
        <Text variant='h3' marginTop='1rem'>
          {`${
            hasInvite ? 'This commuter has already been invited. ' : ''
          } Are you sure you want to ${
            hasInvite ? 'send another invite' : 'invite this commuter'
          }?`}
        </Text>
        <Text variant='action2' marginTop='1rem'>
          {`They will receive ${
            hasInvite ? 'another' : 'an'
          } email inviting them to register with Fleet.`}
        </Text>
      </ContentWrapper>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommuterInvite.displayName = 'MyCommuterInvite'
}

export default MyCommuterInvite
