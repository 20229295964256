import { all, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { SegmentParams } from 'redux/employer/employerTypes'
import {
  SegmentAndType,
  WorksiteAndTypeAndDate,
} from 'redux/programs/programsTypes'

import { Action } from 'shared/Types/Actions'
import OtpService from './otpService'
import { otpActions } from './otpSlice'
import { OtpProgram, OtpProgramUpdate } from './otpTypes'

export function* getOtpRecommendations(action: Action<SegmentParams>) {
  yield defaultFlow(
    action,
    OtpService.getOtpRecommendations,
    otpActions.getOtpRecommendationsSuccess
  )
}

export function* createOtpProgram(action: Action<OtpProgram>) {
  yield defaultFlow(
    action,
    OtpService.createOtpProgram,
    otpActions.createOtpProgramSuccess
  )
}

export function* updateOtpProgram(action: Action<OtpProgramUpdate>) {
  yield defaultFlow(
    action,
    OtpService.updateOtpProgram,
    otpActions.updateOtpProgramSuccess
  )
}

export function* cancelOtpProgram(action: Action<string>) {
  yield defaultFlow(
    action,
    OtpService.cancelOtpProgram,
    otpActions.cancelOtpProgramSuccess
  )
}

export function* getOtpUsageHistory(action: Action<SegmentAndType>) {
  yield defaultFlow(
    action,
    OtpService.getOtpUsageHistory,
    otpActions.getOtpUsageHistorySuccess
  )
}

export function* getOtpDashboardServices(
  action: Action<WorksiteAndTypeAndDate>
) {
  yield defaultFlow(
    action,
    OtpService.getOtpDashboardServices,
    otpActions.getOtpDashboardServicesSuccess
  )
}

export function* getOtpDashboardStats(action: Action<SegmentAndType>) {
  yield defaultFlow(
    action,
    OtpService.getOtpDashboardStats,
    otpActions.getOtpDashboardStatsSuccess
  )
}

/**
 * Watchers
 */
export function* watchGetOtpRecommendations() {
  yield takeEvery(
    [otpActions.getOtpRecommendations.type],
    getOtpRecommendations
  )
}
export function* watchCreateOtpProgram() {
  yield takeEvery([otpActions.createOtpProgram.type], createOtpProgram)
}

export function* watchUpdateOtpProgram() {
  yield takeEvery([otpActions.updateOtpProgram.type], updateOtpProgram)
}

export function* watchCancelOtpProgram() {
  yield takeEvery([otpActions.cancelOtpProgram.type], cancelOtpProgram)
}

export function* watchGetOtpUsageHistory() {
  yield takeEvery([otpActions.getOtpUsageHistory.type], getOtpUsageHistory)
}

export function* watchGetOtpDashboardServices() {
  yield takeEvery(
    [otpActions.getOtpDashboardServices.type],
    getOtpDashboardServices
  )
}
export function* watchGetOtpDashboardStats() {
  yield takeEvery([otpActions.getOtpDashboardStats.type], getOtpDashboardStats)
}

export default function* () {
  yield all([
    watchGetOtpRecommendations(),
    watchCreateOtpProgram(),
    watchUpdateOtpProgram(),
    watchCancelOtpProgram(),
    watchGetOtpUsageHistory(),
    watchGetOtpDashboardServices(),
    watchGetOtpDashboardStats(),
  ])
}
