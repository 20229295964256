import {
  system,
  get,
  HeightProps,
  WidthProps,
  MinWidthProps,
  MaxWidthProps,
  MinHeightProps,
  MaxHeightProps,
  SizeProps as CoreSizeProps,
} from 'styled-system'

const isNumber = (n) => typeof n === 'number' && !isNaN(n)

const getWidth = (n, scale) =>
  get(scale, n, !isNumber(n) || n > 1 ? n : n * 100 + '%')

export interface SizeProps
  extends HeightProps,
    WidthProps,
    MinWidthProps,
    MaxWidthProps,
    MinHeightProps,
    MaxHeightProps,
    CoreSizeProps {}

export const size = system({
  width: {
    property: 'width',
    scale: 'sizes',
    transform: getWidth,
  },
  height: {
    property: 'height',
    scale: 'sizes',
  },
  minWidth: {
    property: 'minWidth',
    scale: 'sizes',
  },
  minHeight: {
    property: 'minHeight',
    scale: 'sizes',
  },
  maxWidth: {
    property: 'maxWidth',
    scale: 'sizes',
  },
  maxHeight: {
    property: 'maxHeight',
    scale: 'sizes',
  },
  size: {
    properties: ['width', 'height'],
    scale: 'sizes',
  },
})
