import React from 'react'
import styled from 'styled-components'
import Button from 'core-system/Button'
import Text from 'core-system/Text'
import Modal, { ModalBody, ModalFooter } from 'core-system/Modal'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  max-width: 25rem;
`

interface ActionStatusModalProps {
  open: boolean
  closeModal: () => void
  text: string
  icon?: React.ReactNode
  buttonText?: string
}

const ActionStatusModal = React.memo((props: ActionStatusModalProps) => {
  const { open, closeModal, text, icon, buttonText = 'Done' } = props

  return (
    <Modal open={open} onClose={closeModal}>
      <ModalBody>
        <Container>
          {icon}
          <Text variant='action1'>{text}</Text>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => closeModal()}>{buttonText}</Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ActionStatusModal.displayName = 'ActionStatusModal'
}

export default ActionStatusModal
