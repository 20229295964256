import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import jwt_decode from 'jwt-decode'
import IntercomService from 'redux/config/services/IntercomService'
import SegmentService from 'redux/config/services/SegmentService'
import {
  LoginPayload,
  SocialLoginPayload,
  SocialSignupPayload,
  User,
  UserApiType as UserSuccessPayload,
} from 'redux/user/userTypes'
import LocalStorage from 'shared/LocalStorage'

export interface UserState {
  profile: User
  error: {
    status: boolean
    info: {
      code: number
      statusText: string
      message: string
    } | null
  } | null
  stayLoggedIn: boolean
  authUserFail: boolean | null
  loggedOut: boolean
}

const initialState: UserState = {
  profile: {
    id: '0',
    email: '',
    firstName: '',
    lastName: '',
    worksiteId: null,
    role: null,
    avatarUrl: '',
    hasRegistered: false,
    groups: [],
  },
  error: null,
  stayLoggedIn: null,
  authUserFail: null,
  loggedOut: false,
}

const login = createAction<LoginPayload>('user/login')
const socialLogin = createAction<SocialLoginPayload>('user/socialLogin')
const socialSignup = createAction<SocialSignupPayload>('user/socialSignup')
const getUser = createAction('user/getUser')
const updateUser = createAction<UserSuccessPayload>('user/updateUser')
const resetPasswordSuccess = createAction<AxiosResponse>(
  'user/resetPasswordSuccess'
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess(state, action: any) {
      const { accessToken } = action.payload
      const decodedToken = jwt_decode(accessToken)

      // stayloggedin true, store in localstorage
      if (action.meta.stayLoggedIn) {
        LocalStorage.setItem('tokenExp', decodedToken['exp'])
      }
      // store exp in session storage
      if (!action.meta.stayLoggedIn) {
        window.sessionStorage.setItem('tokenExp', decodedToken['exp'])
      }

      // store the type of platform the user logged into in local storage
      LocalStorage.setItem('platformType', action.meta.platformType)

      LocalStorage.setItem('isSocialLogin', false)
      return {
        ...state,
        error: {
          status: false,
          info: null,
        },
        stayLoggedIn: action.meta.stayLoggedIn,
      }
    },
    socialLoginSuccess(state, action: any) {
      const { accessToken } = action.payload
      const decodedToken = jwt_decode(accessToken)
      LocalStorage.setItem('tokenExp', decodedToken['exp'])
      LocalStorage.setItem('isSocialLogin', true)
      // store the type of platform the user logged into in local storage
      LocalStorage.setItem('platformType', action.meta.platformType)
      return {
        ...state,
        error: {
          status: false,
          info: null,
        },
        stayLoggedIn: true,
        loggedOut: false,
      }
    },
    socialSignupSuccess(state, action: any) {
      const { accessToken } = action.payload
      const decodedToken = jwt_decode(accessToken)
      LocalStorage.setItem('tokenExp', decodedToken['exp'])
      LocalStorage.setItem('isSocialLogin', true)
      // store the type of platform the user logged into in local storage
      LocalStorage.setItem('platformType', action.meta.platformType)
      return {
        ...state,
        error: {
          status: false,
          info: null,
        },
        stayLoggedIn: true,
        loggedOut: false,
      }
    },
    logout() {
      IntercomService.shutdown()
      LocalStorage.removeItem('stayLoggedIn')
      LocalStorage.removeItem('tokenExp')
      LocalStorage.removeItem('isSocialLogin')
      LocalStorage.removeItem('socialLoginType')
      return {
        ...initialState,
        loggedOut: true,
      }
    },
    getUserSuccess(state, action: PayloadAction<UserSuccessPayload>) {
      const user = action.payload
      SegmentService.track('user-login-success', { email: user.email })

      state.profile = {
        ...user,
        worksiteId: user.worksiteId || null,
        role: user.groups.length === 0 ? null : user.groups[0],
        avatarUrl: user.avatar,
        hasRegistered: false,
      }
      state.authUserFail = false
    },
    updateUserSuccess(state, action: PayloadAction<UserSuccessPayload>) {
      const user = action.payload
      SegmentService.track('user-login-success', { email: user.email })

      state.profile = {
        ...user,
        worksiteId: user.worksiteId || null,
        role: user.groups.length === 0 ? null : user.groups[0],
        avatarUrl: user.avatar,
        hasRegistered: false,
      }
      state.authUserFail = false
    },
    loginFailed(state, action: any) {
      state.error = {
        status: true,
        info: {
          code: action.error.status,
          message: action.error.message,
          statusText: action.error.statusText,
        },
      }
    },
    socialLoginFailed(state, action: any) {
      state.error = {
        status: true,
        info: {
          code: action.error.status,
          message: action.error.message,
          statusText: action.error.statusText,
        },
      }
    },
    getUserFailed(state) {
      state.authUserFail = true
    },
  },
})

export const userReducer = userSlice.reducer
export const userActions = {
  ...userSlice.actions,
  login,
  socialLogin,
  socialSignup,
  getUser,
  updateUser,
  resetPasswordSuccess,
}
