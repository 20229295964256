import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import useQueryParam from 'shared/Hooks/useQueryParam'
import Loading from 'core-system/Loading'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import OptInOverviewHeader from './components/OptInOverviewHeader'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import OptInQuestion from './components/OptInQuestion'
import moment from 'moment'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const OptInsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const NoOptInsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  background-color: ${(props) => props.theme.palette.white};
  padding: 1rem;
`

const OptInOverviewView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const today = moment()

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const { optIns } = useSelector((state: AppState) => state.optIn)

  if (!optIns) {
    return <Loading fullPage />
  }

  const activeOptIns =
    optIns && optIns[segmentId]
      ? optIns[segmentId].filter(
          (optIn) =>
            (!optIn.endDate || !moment(optIn.endDate).isBefore(today)) &&
            (!optIn.startDate || !moment(optIn.startDate).isAfter(today))
        )
      : []

  return (
    <Container>
      <OptInOverviewHeader segmentId={segmentId} isMobile={isMobile} />
      {optIns[segmentId] && activeOptIns.length > 0 ? (
        <OptInsContainer>
          <Text variant='h3'>Questions</Text>
          {activeOptIns.map((optIn) => {
            return <OptInQuestion key={optIn.id} optIn={optIn} />
          })}
        </OptInsContainer>
      ) : (
        <NoOptInsContainer>
          <Text
            variant='h3'
            textAlign='center'
            textColor={palette.text.secondary}
          >
            You have no active Opt-In questions for this commuter group.
          </Text>
        </NoOptInsContainer>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OptInOverviewView.displayName = 'OptInOverviewView'
}

export default OptInOverviewView
