import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <mask
      id='mask0'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='18'
      height='14'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 0H17.9995V13.904H0V0Z'
        fill='white'
      />
    </mask>
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9765 12.467C12.5505 12.467 11.3905 11.307 11.3905 9.881C11.3905 8.946 11.8935 8.132 12.6395 7.678L13.2825 10.068C13.3065 10.156 13.3485 10.235 13.3995 10.304C13.4175 10.329 13.4405 10.347 13.4615 10.369C13.4995 10.408 13.5385 10.446 13.5845 10.476C13.6145 10.496 13.6455 10.51 13.6785 10.526C13.7235 10.546 13.7685 10.562 13.8165 10.573C13.8525 10.581 13.8875 10.588 13.9255 10.591C13.9425 10.592 13.9585 10.6 13.9765 10.6C14.0375 10.6 14.1005 10.592 14.1635 10.575C14.5465 10.471 14.7735 10.077 14.6705 9.694L14.0265 7.299C15.4295 7.326 16.5625 8.471 16.5625 9.881C16.5625 11.307 15.4025 12.467 13.9765 12.467M6.60951 9.881C6.60951 11.307 5.44951 12.467 4.02351 12.467C2.59751 12.467 1.43751 11.307 1.43751 9.881C1.43751 8.454 2.59751 7.294 4.02351 7.294C4.02951 7.294 4.03551 7.296 4.04151 7.296L3.33451 9.676C3.22151 10.057 3.43751 10.456 3.81851 10.57C3.88751 10.59 3.95551 10.6 4.02351 10.6C4.33351 10.6 4.61951 10.398 4.71251 10.085L5.41851 7.709C6.13251 8.17 6.60951 8.969 6.60951 9.881M13.9765 5.857C13.8635 5.857 13.7575 5.881 13.6465 5.89L13.0815 3.79L12.0625 0H9.12051C8.72351 0 8.40151 0.321 8.40151 0.719C8.40151 1.115 8.72351 1.438 9.12051 1.438H10.9605L11.5935 3.79H5.08351L4.45651 5.901C4.31251 5.885 4.17151 5.857 4.02351 5.857C1.80551 5.857 -0.000488281 7.662 -0.000488281 9.881C-0.000488281 12.099 1.80551 13.904 4.02351 13.904C6.24251 13.904 8.04751 12.099 8.04751 9.881C8.04751 8.318 7.14151 6.974 5.83551 6.308L6.15651 5.228H11.9805L12.2575 6.257C10.8995 6.905 9.95351 8.28 9.95351 9.881C9.95351 12.099 11.7585 13.904 13.9765 13.904C16.1945 13.904 17.9995 12.099 17.9995 9.881C17.9995 7.662 16.1945 5.857 13.9765 5.857'
        fill='#6200E0'
      />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.63181 3.00713H6.42181C6.81881 3.00713 7.14081 2.68613 7.14081 2.28813C7.14081 1.89213 6.81881 1.57013 6.42181 1.57013H3.63181C3.23481 1.57013 2.91281 1.89213 2.91281 2.28813C2.91281 2.68613 3.23481 3.00713 3.63181 3.00713'
      fill='#6200E0'
    />
  </>,
  'Programs Bike',
  '0 0 18 14'
)
