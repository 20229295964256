import React, { useState } from 'react'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import AddTripForm from './components/AddTripForm'
import { Footer } from 'core-system/Program'
import styled from 'styled-components'
import Button from 'core-system/Button'
import moment, { Moment } from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { Locations } from 'shared/Router/Locations'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import { AutocompleteResult } from 'core-system/Map/AddressAutocomplete/AddressAutocompleteField'
import Divider from 'core-system/Divider'
import { useDispatch } from 'react-redux'

const FooterContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-end')};
  gap: 1rem;
`

export const isFutureDate = (date: Moment) => {
  return date.isAfter(moment())
}

const formatTripLocation = (location: AutocompleteResult) => {
  return {
    address: location.address,
    lat: location.coordinates[1],
    lng: location.coordinates[0],
    home: location.type === 'HOME',
    worksite: location.type === 'WORK',
  }
}

const AddTripView = React.memo(() => {
  const today = moment()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const locationState = useLocation().state as any

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const [date, setDate] = useState({
    day: String(locationState?.day ? locationState.day : today.date()),
    month: String(
      locationState?.month ? locationState.month + 1 : today.month() + 1
    ), // moment months are 0-indexed
    year: String(locationState?.year ? locationState.year : today.year()),
  })
  const [timeRange, setTimeRange] = useState(null)
  const [source, setSource] = useState(null)
  const [sourceAddr, setSourceAddr] = useState(null)
  const [dest, setDest] = useState(null)
  const [destAddr, setDestAddr] = useState(null)
  const [modeIds, setModeIds] = useState<string[]>([])
  // const [noTripReason, setNoTripReason] = useState<string | null>(null)

  const handleAddTrip = () => {
    if (!addTripButtonDisabled) {
      const modeIdsList = Object.values(modeIds).reduce((ids, modeTypeIds) => {
        return [...ids, modeTypeIds]
      }, [])
      const addTripPayload = {
        date: moment(date.year + '-' + date.month + '-' + date.day).format(
          'YYYY-MM-DDThh:mm'
        ),
        timeRange: timeRange,
        source: formatTripLocation(source),
        dest: formatTripLocation(dest),
        modeIds: modeIdsList,
        noTripReason: null,
      }
      dispatch(employeePlatformActions.addTrip(addTripPayload))
      navigate(Locations.EmployeeTrips.Overview)
    }
  }

  const addTripButtonDisabled =
    // !noTripReason &&
    isFutureDate(moment(date.year + '-' + date.month + '-' + date.day)) ||
    !timeRange ||
    !source ||
    !dest ||
    modeIds.length === 0

  return (
    <GridContainer numColumns={1}>
      <AddTripForm
        isMobile={isMobile}
        date={date}
        setDate={setDate}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        source={source}
        setSource={setSource}
        sourceAddr={sourceAddr}
        setSourceAddr={setSourceAddr}
        dest={dest}
        setDest={setDest}
        destAddr={destAddr}
        setDestAddr={setDestAddr}
        modeIds={modeIds}
        setModeIds={setModeIds}
      />
      <Divider size='0%' marginY='2rem' />
      <Footer>
        <FooterContainer isMobile={isMobile}>
          {/* TODO: FUTURE - add selected month to trips overview URL and navigate back on cancel */}
          <Button
            variant='secondary'
            onClick={() => navigate(Locations.EmployeeTrips.Overview)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleAddTrip()}
            disabled={addTripButtonDisabled}
          >
            Add Trip
          </Button>
        </FooterContainer>
      </Footer>
    </GridContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AddTripView.displayName = 'AddTripView'
}

export default AddTripView
