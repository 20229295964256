import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import FleetCard from './FleetCard'
import palette from 'core-system/Themes/palette'
import FleetCardDetailsModal from './FleetCardDetailsModal'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
`

interface FleetCardsProps {
  isMobile: boolean
}

const FleetCards = React.memo((props: FleetCardsProps) => {
  const { isMobile } = props

  const { cards } = useSelector(
    (state: AppState) => state.employeePlatform.wallet
  )

  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)

  const handleCloseDetailsModal = useCallback(() => {
    setShowDetailsModal(false)
    setSelectedCard(null)
  }, [])

  const handleClickCard = useCallback((card) => {
    setSelectedCard(card)
    setShowDetailsModal(true)
  }, [])

  return (
    <Container>
      <Text variant='action1'>Fleet Cards</Text>
      <Text variant='body1' textColor={palette.text.secondary}>
        Click on a card to view details
      </Text>
      <CardsContainer>
        {cards && cards.length > 0 ? (
          cards.map((card) => {
            return (
              <FleetCard
                key={card.internalId}
                card={card}
                size={isMobile ? 'small' : 'large'}
                onPress={handleClickCard}
              />
            )
          })
        ) : (
          <NoDataText text='YOU DO NOT HAVE ANY FLEET CARDS' />
        )}
      </CardsContainer>
      <FleetCardDetailsModal
        open={showDetailsModal}
        closeModal={handleCloseDetailsModal}
        card={selectedCard}
        isMobile={isMobile}
      />
    </Container>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FleetCards.displayName = 'FleetCards'
}

export default FleetCards
