import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import DayMonthYearInput from 'employee-platform/shared/components/DayMonthYearInput'
import palette from 'core-system/Themes/palette'
import Text from 'core-system/Text'
import Dropdown from 'core-system/Dropdown'
import {
  convertMapToDropdownItems,
  timeRanges,
} from 'employee-platform/shared/utils'
import AddressAutocompleteField, {
  AutocompleteResult,
} from 'core-system/Map/AddressAutocomplete/AddressAutocompleteField'
import GridCard from 'employee-platform/shared/components/GridCard'
import { isFutureDate } from '../AddTripView'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { EmployeeProfile } from 'redux/employeePlatform/employeePlatformTypes'
import Divider from 'core-system/Divider'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import { tripModeTypes } from '../TripsUtils'
import ModeTypeOptionsList from './ModeTypeOptionsList'
import SelectServiceProviderModal from './SelectProviderModal'
import { Mode } from 'redux/employeePlatform/employeePlatformTypes'
import SelectedModesList from './SelectedModesList'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const ModesContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

const ModesContainer = styled.div<{ borderLocation?: 'bottom' | 'right' }>`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: ${(props) =>
      props.borderLocation === 'bottom' ? '1rem' : '0'};
    border-bottom: ${(props) =>
      props.borderLocation === 'bottom'
        ? '1px solid ' + palette.grey.grey3
        : 'none'};
    padding-right ${(props) =>
      props.borderLocation === 'right' ? '1rem' : '0'};
    border-right: ${(props) =>
      props.borderLocation === 'right'
        ? '1px solid ' + palette.grey.grey3
        : 'none'};
`

const renderFormField = (fieldLabel: string, field: React.ReactNode) => {
  return (
    <FormField>
      <Text variant='body1' textColor={palette.text.secondary}>
        {fieldLabel}
      </Text>
      {field}
    </FormField>
  )
}

const getHomeAndWorksiteAddressSuggestions = (
  profileData: EmployeeProfile
): { type: 'HOME' | 'WORK'; address: string }[] => {
  if (profileData.homeAddress && profileData.worksiteAddress) {
    return [
      { type: 'HOME', address: profileData.homeAddress },
      { type: 'WORK', address: profileData.worksiteAddress },
    ]
  } else if (profileData.homeAddress) {
    return [{ type: 'HOME', address: profileData.homeAddress }]
  } else if (profileData.worksiteAddress) {
    return [{ type: 'WORK', address: profileData.worksiteAddress }]
  } else {
    return []
  }
}

const getBaseModeIds = (allModes: { [modeId: string]: Mode }) => {
  return Object.values(allModes).reduce((baseModes, mode) => {
    if (mode.modeType === mode.name) {
      return {
        ...baseModes,
        [mode.modeType]: mode.id,
      }
    }
    return baseModes
  }, {})
}

interface TripDate {
  day: string
  month: string
  year: string
}

interface AddTripFormProps {
  isMobile: boolean
  date: TripDate
  setDate: (date: TripDate) => void
  timeRange: string | null
  setTimeRange: (timeRange: string) => void
  source: AutocompleteResult | null
  setSource: (source: AutocompleteResult) => void
  sourceAddr: string | null
  setSourceAddr: (sourceAddr: string) => void
  dest: AutocompleteResult | null
  setDest: (dest: AutocompleteResult) => void
  destAddr: string | null
  setDestAddr: (destAddr: string) => void
  modeIds: string[]
  setModeIds: (modeIds: string[]) => void
}

const AddTripForm = React.memo((props: AddTripFormProps) => {
  const {
    isMobile,
    date,
    setDate,
    timeRange,
    setTimeRange,
    setSource,
    sourceAddr,
    setSourceAddr,
    setDest,
    destAddr,
    setDestAddr,
    modeIds,
    setModeIds,
  } = props

  const { profileData, allModes } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const today = moment()

  const defaultAddressSuggestions = profileData
    ? getHomeAndWorksiteAddressSuggestions(profileData)
    : []

  const [tripDateErrorMessage, setTripDateErrorMessage] = useState(null)
  const [showServiceProviderModal, setShowServiceProviderModal] =
    useState(false)
  const [selectedModeType, setSelectedModeType] = useState(null)

  const clearModes = () => {
    setModeIds(null)
  }

  const baseModeIds = useMemo(() => {
    return allModes ? getBaseModeIds(allModes) : {}
  }, [allModes])

  const quickAddModeType = (modeType: string) => {
    const baseModeId = baseModeIds ? baseModeIds[modeType] : null
    if (baseModeId && !modeIds.includes(baseModeId)) {
      setModeIds([...modeIds, baseModeId])
    }
  }

  const onSelectServiceProvidersClick = (modeType: string) => {
    // Certain mode types don't have providers (e.g. WALK, BIKE, CAR)
    if (!tripModeTypes[modeType].hasProviders) {
      quickAddModeType(modeType)
    } else {
      setSelectedModeType(modeType)
    }
  }

  // Set the trip date error message if the user enters a future date and unset it if they select a past date or today's date
  useEffect(() => {
    if (isFutureDate(moment(date.year + '-' + date.month + '-' + date.day))) {
      setTripDateErrorMessage('Please select the current date or a past date.')
    } else if (
      tripDateErrorMessage &&
      !isFutureDate(moment(date.year + '-' + date.month + '-' + date.day))
    ) {
      setTripDateErrorMessage(null)
    }
  }, [date, today, tripDateErrorMessage, setTripDateErrorMessage])

  useEffect(() => {
    if (selectedModeType) {
      setShowServiceProviderModal(true)
    }
  }, [selectedModeType, setShowServiceProviderModal])

  return (
    <GridCard>
      <FormContainer>
        {/* TRIP DATE */}
        {renderFormField(
          'Trip Date*',
          <DayMonthYearInput
            date={date}
            setDate={setDate}
            errorMessage={tripDateErrorMessage}
            isMobile={isMobile}
          />
        )}
        {/* TIME RANGE */}
        {renderFormField(
          'Time Range*',
          <Dropdown
            items={convertMapToDropdownItems(timeRanges)}
            active={timeRange}
            itemCallback={(item) => setTimeRange(item.id)}
            placeholder='Select a time range'
            width='100%'
            selectorMaxHeight='150px'
            usePortal
          />
        )}
        {/* SOURCE */}
        <AddressAutocompleteField
          label='Source*'
          displayValue={sourceAddr}
          setDisplayValue={setSourceAddr}
          onSuggestionSelect={setSource}
          customPlaceholder='Source address'
          defaultSuggestions={defaultAddressSuggestions}
        />
        {/* DEST */}
        <AddressAutocompleteField
          label='Destination*'
          displayValue={destAddr}
          setDisplayValue={setDestAddr}
          onSuggestionSelect={setDest}
          customPlaceholder='Destination address'
          defaultSuggestions={defaultAddressSuggestions}
        />
        <Divider marginTop='0.5rem' />
        <ModesContainerHeader>
          <Text variant='h4'>Modes</Text>
          <Text
            variant='hyperlink'
            textColor={palette.primary.primaryPurple}
            onClick={() => clearModes()}
          >
            Clear Modes
          </Text>
        </ModesContainerHeader>
        <GridContainer numColumns={2}>
          <ModesContainer borderLocation={isMobile ? 'bottom' : 'right'}>
            <Text variant='body1' textColor={palette.text.secondary}>
              Select the modes of transportation used
            </Text>
            <ModeTypeOptionsList
              isMobile={isMobile}
              quickAddModeType={quickAddModeType}
              onSelectServiceProvidersClick={onSelectServiceProvidersClick}
            />
          </ModesContainer>
          <ModesContainer>
            <Text variant='body1' textColor={palette.text.secondary}>
              Trip Overview
            </Text>
            <SelectedModesList
              selectedModes={modeIds}
              setSelectedModes={setModeIds}
            />
          </ModesContainer>
        </GridContainer>
      </FormContainer>
      <SelectServiceProviderModal
        open={showServiceProviderModal}
        closeModal={() => setShowServiceProviderModal(false)}
        selectedModeType={selectedModeType}
        setSelectedModeType={setSelectedModeType}
        selectedModes={modeIds}
        setSelectedModes={setModeIds}
      />
    </GridCard>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AddTripForm.displayName = 'AddTripForm'
}

export default AddTripForm
