import React from 'react'
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import palette from 'core-system/Themes/palette'
import { styled } from '@mui/styles'
import typography, { Font } from 'core-system/Themes/typography'

const StyledTooltip = styled(
  ({ className, ...props }: CustomMuiTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ backgroundColor, textVariant }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '0.5rem 0.75rem',
    backgroundColor: backgroundColor,
    ...textVariant,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: palette.text.secondary,
  },
}))

interface CustomMuiTooltipProps extends TooltipProps {
  backgroundColor?: string
  textVariant?: Font
}

const CustomMuiTooltip = React.memo((props: CustomMuiTooltipProps) => {
  const {
    backgroundColor = palette.text.secondary,
    textVariant = typography.action4,
    title,
    placement,
    enterDelay,
    leaveDelay,
    arrow,
    children,
  } = props

  return (
    <StyledTooltip
      backgroundColor={backgroundColor}
      textVariant={textVariant}
      title={title}
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      arrow={arrow}
    >
      {children}
    </StyledTooltip>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CustomMuiTooltip.displayName = 'CustomMuiTooltip'
}

export default CustomMuiTooltip
