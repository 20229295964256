import React, { useRef, useState } from 'react'
import styled, { ThemedStyledProps } from 'styled-components'
import useResizeListener from '../../../shared/Hooks/useResizeListener'
import Tooltip from '../../../core-system/Tooltip'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const SidebarItem = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
  width: 100%;
  border: none;
  text-decoration: none;
  pointer-events: ${(props) => props.isDisabled && 'none'};

  background-color: ${(props) =>
    props.isActive
      ? props.theme.palette.secondary.purple2
      : props.theme.palette.white};
  color: ${(props) => getItemColor(props)};
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.isActive
        ? props.theme.palette.secondary.purple2
        : props.theme.palette.secondary.purple5};
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: unset;
    margin: 0 1.5rem 0 0;
    height: 100%;
    padding: 1.25rem 0;
    background-color: unset;
    cursor: default;

    &:hover {
        background-color: unset;
    }
  }

  ${flexbox}
  ${space}
  ${layout}
`

const SidebarIconContainer = styled.div<{ isActive: boolean }>`
  margin-right: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-right: unset;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;

    background-color: ${(props) =>
      props.isActive
        ? props.theme.palette.secondary.purple2
        : props.theme.palette.white};
    &:hover {
      background-color: ${(props) =>
        props.isActive
          ? props.theme.palette.secondary.purple2
          : props.theme.palette.secondary.purple5};
    }
  }
`

const Text = styled.div`
  ${(props) => props.theme.typography.body1}
  line-height: unset;
  font-weight: 500;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    display: none;
  }
`

const SecondaryIconContainer = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    display: none;
  }
`

const getItemColor = (props: ThemedStyledProps<any, any>) => {
  if (props.isDisabled) {
    return props.theme.palette.chips.grey1
  }

  if (props.isActive) {
    return props.theme.palette.white
  }

  return props.theme.palette.primary.primaryDark
}

interface SecondarySidebarItemProps
  extends React.HtmlHTMLAttributes<HTMLDivElement>,
    FlexboxProps,
    SpaceProps,
    LayoutProps {
  isActive: boolean
  isDisabled?: boolean
  icon: React.ReactElement
  secondaryIcon?: React.ReactElement
  label: string
}

const SecondarySidebarItem = React.memo((props: SecondarySidebarItemProps) => {
  const { isActive, isDisabled = false, icon, label, secondaryIcon } = props
  const [isVisible, setIsVisible] = useState(false)
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1000)

  useResizeListener(() => setIsTablet(window.innerWidth <= 1000), [])
  const ref = useRef(null)

  return (
    <SidebarItem
      isActive={isActive}
      isDisabled={isDisabled}
      ref={ref}
      {...props}
    >
      <SidebarIconContainer
        onMouseEnter={() => isTablet && setIsVisible(true)}
        onMouseLeave={() => isTablet && setIsVisible(false)}
        onClick={() => isTablet && setIsVisible(false)}
        isActive={isActive}
      >
        {icon}
      </SidebarIconContainer>
      <Text>{label}</Text>
      {secondaryIcon && (
        <SecondaryIconContainer>{secondaryIcon}</SecondaryIconContainer>
      )}
      <Tooltip target={ref} show={isVisible} position={'top'}>
        {label}
      </Tooltip>
    </SidebarItem>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SecondarySidebarItem.displayName = 'SecondarySidebarItem'
}

export default SecondarySidebarItem
