import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path d='M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z' />
    <path d='M12.7692 7H9V17H11.1538V13.6667H12.7692C14.5515 13.6667 16 12.1722 16 10.3333C16 8.49444 14.5515 7 12.7692 7ZM12.8769 11.4444H11.1538V9.22222H12.8769C13.4692 9.22222 13.9538 9.72222 13.9538 10.3333C13.9538 10.9444 13.4692 11.4444 12.8769 11.4444Z' />
  </>,
  'Parking'
)
