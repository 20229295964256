import { ColumnCard } from 'core-system/ColumnView'
import SummaryOverview from 'core-system/Program/Summary/SummaryOverview'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { Segment } from 'redux/employer/employerTypes'
import { GrhActiveOptions } from 'redux/grh/grhTypes'
import styled from 'styled-components'
import GRHSummaryOptions from './GRHSummaryOptions'
import GRHSummaryBudget from './GRHSummaryBudget'

const Container = styled(ColumnCard)`
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
`
interface GRHActivationSummaryProps {
  startDate: string
  currentSegment: Segment
  transactionLimit: number
  totalUses: number
  activeOptions: GrhActiveOptions
  activeMerchantIds: string[]
  availableMerchantIds: string[]
  handleOpenModal: () => void
}

const GRHActivationSummary = React.memo((props: GRHActivationSummaryProps) => {
  const {
    startDate,
    currentSegment,
    transactionLimit,
    totalUses,
    activeOptions,
    activeMerchantIds,
    availableMerchantIds,
    handleOpenModal,
  } = props
  const numEmps = currentSegment.commuters

  return (
    <Container>
      <Text variant='h4' marginBottom='1.5rem'>
        My GRH Program
      </Text>
      <SummaryOverview
        startDate={startDate}
        currentSegment={currentSegment}
        marginBottom='1rem'
      />
      <GRHSummaryOptions
        activeOptions={activeOptions}
        activeMerchantIds={activeMerchantIds}
        availableMerchantIds={availableMerchantIds}
        handleOpenModal={handleOpenModal}
        canEdit
      />
      <GRHSummaryBudget
        totalEmployees={numEmps}
        transactionLimit={transactionLimit}
        totalUses={totalUses}
      />

      <Text variant='body2' textColor={palette.text.placeholder}>
        All amounts are estimated based on max commuter spend.
      </Text>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHActivationSummary.displayName = 'GRHActivationSummary'
}

export default GRHActivationSummary
