import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      d='M4.26651 18.5551C7.63802 21.9266 15.3805 26.6161 24.1568 18.5079C19.1959 17.5536 18.323 11.2416 14.9508 7.87074C11.5793 4.49855 6.87495 3.73615 3.50276 7.10767C0.131246 10.4792 0.894316 15.1829 4.26651 18.5551V18.5551Z'
      fill='#A6D388'
    />
    <path
      d='M16.58 19.4036C20.7071 20.5041 23.7581 18.9065 24.0396 18.625C24.0396 18.625 21.4897 19.8043 17.0628 17.6309C17.704 16.8147 19.6585 15.6717 19.6585 15.6717C19.2861 15.4387 16.912 15.5202 15.2909 16.6342C14.511 16.1405 13.6853 15.5451 12.8171 14.8326L13.3829 14.246C15.5717 11.9668 18.0596 12.5056 18.0596 12.5056C17.6299 12.0928 14.2887 10.1881 11.6055 12.9771L11.1927 13.4068C10.9058 13.1381 10.6162 12.8599 10.3212 12.5649L9.0752 11.3189L9.7918 10.6017C12.0285 8.36567 15.332 8.27744 15.332 8.27744C14.9104 7.85516 10.7098 6.49335 7.97471 9.22842L7.47901 9.72344L1.89439 4.13747C1.47278 3.71519 0.754839 3.75021 0.333904 4.17114C-0.0877038 4.59275 -0.122052 5.31002 0.299556 5.73163L5.88485 11.3169L5.84511 11.3567C3.11005 14.0924 4.24556 18.5186 4.66785 18.9409C4.66785 18.9409 4.98304 15.4104 7.21972 13.1744L7.48036 12.9131L9.83827 15.2717C9.87666 15.3101 9.91437 15.3431 9.95276 15.3815C8.31954 18.6122 10.7805 22.3454 11.2715 22.6444C11.2715 22.6444 10.2848 19.4635 11.5947 16.8288C12.6225 17.6336 13.6287 18.2398 14.5965 18.6843C14.6524 20.7001 16.4197 22.5232 16.8487 22.6788C16.8487 22.6788 16.2351 20.6563 16.489 19.6568L16.58 19.4036V19.4036Z'
      fill='#77B255'
    />
    <path
      d='M14.8282 6.06165C14.5615 6.06165 14.309 5.90136 14.2032 5.6387C14.0652 5.29387 14.2315 4.90324 14.5757 4.76383C14.6875 4.71803 17.2804 3.62023 17.5498 0.613078C17.5835 0.243329 17.9027 -0.0274164 18.2806 0.00221742C18.6517 0.0352187 18.9244 0.362537 18.8914 0.73296C18.5466 4.57929 15.2202 5.95592 15.0788 6.01249C14.9966 6.04616 14.9117 6.06165 14.8282 6.06165ZM18.1957 8.75563C17.9169 8.75563 17.6562 8.5812 17.5599 8.30372C17.438 7.9535 17.6219 7.57096 17.9708 7.44771C18.0974 7.40191 21.0183 6.29536 21.5942 1.93313C21.6426 1.56473 21.972 1.30341 22.3498 1.35392C22.7189 1.40241 22.9782 1.74051 22.929 2.10958C22.2414 7.32917 18.5729 8.66471 18.4173 8.71859C18.3439 8.74351 18.2691 8.75563 18.1957 8.75563ZM20.2169 12.7966C19.9791 12.7966 19.7495 12.67 19.6262 12.4471C19.4471 12.1218 19.5663 11.7123 19.8916 11.5331C19.9097 11.523 21.7443 10.4986 22.3168 9.16377C22.4636 8.82164 22.861 8.66404 23.2004 8.81019C23.5426 8.95634 23.7008 9.35235 23.554 9.69449C22.7977 11.459 20.6317 12.6633 20.5401 12.7138C20.4371 12.7697 20.3266 12.7966 20.2169 12.7966V12.7966Z'
      fill='#5DADEC'
    />
  </>,
  'GreenCommute',
  '0 0 25 23'
)
