import { mapIntelligenceActions } from 'redux/mapIntelligence/mapIntelligenceSlice'
import Loading from 'core-system/Loading'
import Map from 'core-system/Map/Map'
import MapNavigationControl from 'core-system/Map/MapNavigationControl'
import ProgramMapStyles from 'core-system/Map/MapStyles/ProgramMapStyles'
import MapUtils, { MetroArea } from 'core-system/Map/MapUtils'
import MapPopupWorksite from 'core-system/Map/Popups/MapPopupWorksite'
import pxToRem from 'core-system/utils/pxToRem'
import MicromobilityPopupEmployee from 'features/Micromobility/Overview/components/map/MicromobilityPopupEmployee'
import { Feature, FeatureCollection } from 'geojson'
import React, { useCallback, useMemo, useState } from 'react'
import { Layer, Source } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import MapIntelligenceService from 'redux/mapIntelligence/mapIntelligenceService'
import { RecentTripDetails } from 'redux/mapIntelligence/mapIntelligenceTypes'
import { ProgramType } from 'redux/programs/programsTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import styled from 'styled-components'
import ProgramOverviewMapStats from './components/ProgramOverviewMapStats'

const popupMap = {
  worksites: MapPopupWorksite,
  employees: MicromobilityPopupEmployee,
  trips: null,
}

const MapContainer = styled.div`
  ${(props) => props.theme.baseCard}
  overflow: hidden;
  position: relative;
  padding: 0;
  margin-top: 1rem;
`

const StyledLoading = styled(Loading)`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${(props) => props.theme.palette.white};
  opacity: 0.7;
  z-index: 1;
`

export interface ModeTypeGroupings {
  groupings: {
    modeType: string
    numTrips: number
  }[]
  total: number
}

const getModeTypeGroupings = (
  recentTrips: FeatureCollection
): ModeTypeGroupings => {
  const recentTripGroupings = recentTrips.features.reduce(
    (agg, trip) => {
      const numTrips = trip.properties.trips.length
      agg.groupings[trip.properties.tripsTag] =
        agg.groupings[trip.properties.tripsTag] + numTrips || numTrips
      agg.total += numTrips
      return agg
    },
    { groupings: {}, total: 0 }
  )
  const top6Modes = Object.keys(recentTripGroupings.groupings)
    .map((key) => {
      return { modeType: key, numTrips: recentTripGroupings.groupings[key] }
    })
    .sort((a, b) => b.numTrips - a.numTrips)
    .slice(0, 5)

  const totalTop6 = top6Modes.reduce((total, modeType) => {
    return (total += modeType.numTrips)
  }, 0)

  if (recentTripGroupings.total - totalTop6 !== 0) {
    top6Modes.push({
      modeType: 'OTHER',
      numTrips: recentTripGroupings.total - totalTop6,
    })
  }

  return { groupings: top6Modes, total: recentTripGroupings.total }
}

const getMapLoadLayer = (
  tripDetails: FeatureCollection,
  recentTrips: FeatureCollection,
  segment: Segment
) => {
  if (tripDetails) {
    return tripDetails
  } else if (recentTrips.features.length > 0) {
    return recentTrips
  } else if (segment.metroAreas && segment.metroAreas.length === 1) {
    return MapUtils.makeDefaultFeatureCollection(
      MapUtils.getBbox({ metroArea: segment.metroAreas[0] as MetroArea })
    )
  } else {
    return MapUtils.makeDefaultFeatureCollection(MapUtils.getBbox({}))
  }
}

interface MicromobilityOverviewMapProps {
  type: ProgramType
  recentTrips: FeatureCollection
  tripDetails: FeatureCollection
}

const MicromobilityOverviewMap = React.memo(
  (props: MicromobilityOverviewMapProps) => {
    const { type, recentTrips, tripDetails } = props

    const dispatch = useDispatch()
    const segmentId = useQueryParam('segmentId')

    const { worksiteGeojson } = useSelector(
      (state: AppState) => state.mapIntelligence
    )
    const { segmentsMap } = useSelector((state: AppState) => state.employer)

    const [selectedTrip, setSelectedTrip] = useState<RecentTripDetails>(
      (tripDetails &&
        tripDetails.features.length === 1 &&
        (tripDetails.features[0].properties as RecentTripDetails)) ||
        null
    )

    const onMapClick = useCallback(
      (
        feature: Feature,
        updateBbox: (featureCollection: FeatureCollection) => void
      ) => {
        if (feature.properties.featureId === 'detailedTrip') {
          //update trip details if same trip isn't already selected
          if (
            feature.properties.linkedJourneyId !==
            (selectedTrip && selectedTrip.linkedJourneyId)
          ) {
            const parsedProperties = {
              ...feature.properties,
              employees: JSON.parse(feature.properties.employees),
              modes: JSON.parse(feature.properties.modes),
              modeTypes: JSON.parse(feature.properties.modeTypes),
            }
            setSelectedTrip(parsedProperties as RecentTripDetails)
          }
        } else {
          const tripIds = JSON.parse(feature.properties.trips).map(
            (trip: { id: number; modeTypes: string[] }) => trip.id
          )
          MapIntelligenceService.getRecentTripDetails({ type, tripIds })
            .then((res) => {
              if (res.data.features.length === 1) {
                setSelectedTrip(res.data.features[0].properties)
              } else {
                setSelectedTrip(null)
              }
              updateBbox(res.data)
              dispatch({
                type: mapIntelligenceActions.getRecentTripDetailsSuccess.type,
                payload: res.data,
                meta: { type, tripIds },
              })
            })
            .catch((err) => {
              dispatch({
                type: 'mapIntelligence/getRecentTripsDetailsFailed',
                error: err,
              })
            })
        }
      },
      [dispatch, type, selectedTrip]
    )

    const isTripDetailsLoading =
      tripDetails &&
      Object.keys(tripDetails).length === 0 &&
      tripDetails.constructor === Object

    const modeTypeGroupings = recentTrips && getModeTypeGroupings(recentTrips)

    const recentTripStyles = useMemo(
      () =>
        modeTypeGroupings &&
        ProgramMapStyles.getRecentTripStyle(modeTypeGroupings.groupings),
      [modeTypeGroupings]
    )

    if (!worksiteGeojson || !recentTrips || !recentTripStyles) {
      return <Loading height={pxToRem(650)} marginTop='1rem' />
    }

    return (
      <>
        <MapContainer>
          {isTripDetailsLoading && <StyledLoading />}
          <Map
            style={{
              height: '500px',
              width: '100%',
            }}
            validInteractiveGeoJSONLayers={
              tripDetails ? ['trips', 'detailedTrip'] : ['trips']
            }
            popupMap={popupMap}
            worksites={worksiteGeojson}
            loadLayer={getMapLoadLayer(
              tripDetails,
              recentTrips,
              segmentsMap[segmentId]
            )}
            onMapClickCallback={onMapClick}
          >
            {(mapProps) => (
              <>
                {recentTrips.features.length > 0 && (
                  <Source type='geojson' id='trips' data={recentTrips}>
                    <Layer {...recentTripStyles} />
                  </Source>
                )}
                {tripDetails && (
                  <Source type='geojson' id='detailedTrip' data={tripDetails}>
                    <Layer {...ProgramMapStyles.isochroneOutlineStyle} />
                  </Source>
                )}
                <MapNavigationControl
                  setViewport={mapProps.setViewport}
                  featureCollection={recentTrips}
                  updateBbox={mapProps.updateBbox}
                />
              </>
            )}
          </Map>
          <ProgramOverviewMapStats
            selectedTrip={selectedTrip}
            modeTypeGroupings={modeTypeGroupings}
          />
        </MapContainer>
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityOverviewMap.displayName = 'MicromobilityOverviewMap'
}

export default MicromobilityOverviewMap
