import { AnyAction } from 'redux'
import { all, takeEvery } from 'redux-saga/effects'
import { conciergeActions } from 'redux/concierge/conciergeSlice'
import { Action } from '../../shared/Types/Actions'
import { defaultFlow } from '../config/saga/Common'
import conciergeService from './conciergeService'
import { Announcement, AnnouncementCreate, Faqs } from './conciergeTypes'
import { userActions } from 'redux/user/userSlice'

// ---- Announcements ----
export function* getAnnouncements(action: AnyAction) {
  yield defaultFlow(
    action,
    conciergeService.getAnnouncements,
    conciergeActions.getAnnouncementsSuccess
  )
}

export function* getAutomatedAnnouncements(action: AnyAction) {
  yield defaultFlow(
    action,
    conciergeService.getAutomatedAnnouncements,
    conciergeActions.getAutomatedAnnouncementsSuccess
  )
}

export function* createAnnouncement(action: Action<AnnouncementCreate>) {
  yield defaultFlow(
    action,
    conciergeService.createAnnouncement,
    conciergeActions.createAnnouncementSuccess
  )
}

export function* updateAnnouncement(action: Action<Announcement>) {
  yield defaultFlow(
    action,
    conciergeService.updateAnnouncement,
    conciergeActions.updateAnnouncementSuccess
  )
}

export function* deleteAnnouncement(action: Action<string>) {
  yield defaultFlow(
    action,
    conciergeService.deleteAnnouncement,
    conciergeActions.deleteAnnouncementSuccess
  )
}

// ---- FAQs ----
export function* getFaqs(action: AnyAction) {
  yield defaultFlow(
    action,
    conciergeService.getFaqs,
    conciergeActions.getFaqsSuccess
  )
}

export function* updateFaq(action: Action<Faqs>) {
  yield defaultFlow(
    action,
    conciergeService.updateFaq,
    conciergeActions.updateFaqSuccess
  )
}

/**
 * Watchers
 */
export function* watchGetAnnouncements() {
  yield takeEvery(
    [conciergeActions.getAnnouncements.type, userActions.getUserSuccess.type],
    getAnnouncements
  )
}
export function* watchGetAutomatedAnnouncements() {
  yield takeEvery(
    [conciergeActions.getAnnouncements.type, userActions.getUserSuccess.type],
    getAutomatedAnnouncements
  )
}

export function* watchCreateAnnouncement() {
  yield takeEvery(
    [conciergeActions.createAnnouncement.type],
    createAnnouncement
  )
}

export function* watchUpdateAnnouncement() {
  yield takeEvery(
    [conciergeActions.updateAnnouncement.type],
    updateAnnouncement
  )
}

export function* watchDeleteAnnouncement() {
  yield takeEvery(
    [conciergeActions.deleteAnnouncement.type],
    deleteAnnouncement
  )
}

export function* watchGetFaqs() {
  yield takeEvery([conciergeActions.getFaqs.type], getFaqs)
}

export function* watchUpdateFaq() {
  yield takeEvery([conciergeActions.updateFaq.type], updateFaq)
}

export default function* () {
  yield all([
    watchGetAnnouncements(),
    watchCreateAnnouncement(),
    watchUpdateAnnouncement(),
    watchDeleteAnnouncement(),
    watchGetFaqs(),
    watchUpdateFaq(),
    watchGetAutomatedAnnouncements(),
  ])
}
