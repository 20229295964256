import { useParams } from 'react-router'

/**
 * get requested query param
 * @param param: name of desired query param
 */
export default function (param: string): string {
  const { [param]: requestedParam } = useParams<Dictionary>()
  return requestedParam
}
