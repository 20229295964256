import Text from 'core-system/Text'
import React from 'react'
import styled, { css } from 'styled-components'
import pxToRem from 'core-system/utils/pxToRem'

const Container = styled.div<{ isActive: boolean; isDisabled: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  cursor: pointer;
  color: ${(props) =>
    props.isActive
      ? props.theme.palette.white
      : props.theme.palette.text.primary};
  background-color: ${(props) =>
    props.isActive
      ? props.theme.palette.secondary.purple2
      : props.theme.palette.white};

  div {
    &:nth-of-type(3) {
      color: ${(props) =>
        props.isActive
          ? props.theme.palette.white
          : props.theme.palette.text.placeholder};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.purple2};
    color: ${(props) => props.theme.palette.white};

    div {
      &:nth-of-type(3) {
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
      color: ${(props) => props.theme.palette.text.disabled};
    `}

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: ${(props) => props.theme.pxToRem(180)};
    margin-top: 0;
    margin-right: 0.5rem;
  }
`

const IconContainer = styled.div`
  min-width: 1.5rem;
  height: 1.5rem;
`

interface SegmentsSidebarItemProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  icon: React.ReactElement
  name: string
  numEmployees?: number
  isActive: boolean
  disabled?: boolean
}

const SegmentsSidebarItem = React.memo((props: SegmentsSidebarItemProps) => {
  const {
    icon,
    name,
    numEmployees,
    isActive,
    disabled = false,
    ...rest
  } = props
  return (
    <Container isActive={isActive} isDisabled={disabled} {...rest}>
      <IconContainer>{icon}</IconContainer>
      <Text
        variant='action4'
        textColor={'inherit'}
        hideOverflow
        marginLeft='1rem'
        marginTop='0.125rem'
        maxWidth={pxToRem(125)}
      >
        {name}
      </Text>
      {numEmployees >= 0 && (
        <Text variant='body1' marginLeft='auto'>
          {numEmployees}
        </Text>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SegmentsSidebarItem.displayName = 'SegmentsSidebarItem'
}

export default SegmentsSidebarItem
