import React from 'react'
import { OptIn } from 'redux/employeePlatform/employeePlatformTypes'
import styled from 'styled-components'
import palette from 'core-system/Themes/palette'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import Text from 'core-system/Text'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const OptInResponse = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: ${(props) => (props.isMobile ? 'flex-start' : 'center')};
  justify-content: ${(props) =>
    props.isMobile ? 'flex-start' : 'space-between'};
  gap: ${(props) => (props.isMobile ? '1rem' : '0')};
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey3};
  background-color: ${(props) => props.theme.palette.white};
  padding: 1rem 2rem;
`

const TextSection = styled.div<{
  type: 'question' | 'response'
  isMobile: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: ${(props) => (props.isMobile ? '100%' : '50%')};
`

interface EmployeeOptInResponsesProps {
  isMobile: boolean
  questions: OptIn[]
}

const EmployeeOptInResponses = React.memo(
  (props: EmployeeOptInResponsesProps) => {
    const { isMobile, questions } = props

    return (
      <Container>
        <Text variant='h2'>Your Responses</Text>
        {questions.length > 0 ? (
          questions.map((question) => {
            return (
              <OptInResponse key={question.id} isMobile={isMobile}>
                <TextSection type='question' isMobile={isMobile}>
                  <Text variant='body2' textColor={palette.text.secondary}>
                    Question
                  </Text>
                  <Text variant='body1'>{question.question}</Text>
                </TextSection>
                <TextSection type='response' isMobile={isMobile}>
                  <Text variant='body2' textColor={palette.text.secondary}>
                    Response
                  </Text>
                  <Text variant='body1'>
                    {question.choices[question.choice]}
                  </Text>
                </TextSection>
              </OptInResponse>
            )
          })
        ) : (
          <NoDataText text='NO RESPONSES' center={false} />
        )}
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeOptInResponses.displayName = 'EmployeeOptInResponses'
}

export default EmployeeOptInResponses
