import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { FinchWorksite } from 'redux/employer/employerTypes'
import Radio from 'core-system/Radio'
import Text from 'core-system/Text'
import styled from 'styled-components'
import palette from 'core-system/Themes/palette'
import Loading from 'core-system/Loading'
import Divider from 'core-system/Divider'
import { Alert } from 'core-system/Icons/Misc'
import RandomUtils from 'shared/RandomUtils'

const FinchWorksiteRadioContainers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
`

const FinchWorksiteRadioItemLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const NoSyncableWorksitesMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`

const renderSyncableFinchWorksiteRadios = (
  syncableFinchWorksites: FinchWorksite[],
  selectedFinchWorksites: string[],
  setSelectedFinchWorksites: (worksites: string[]) => void
) => {
  return syncableFinchWorksites
    .filter((finchWorksite) => finchWorksite.canSync)
    .map((finchWorksite) => {
      return (
        <Radio
          active={selectedFinchWorksites.includes(finchWorksite.id)}
          label={
            <FinchWorksiteRadioItemLabel>
              <Text variant='action3'>{finchWorksite.address}</Text>
              <Text variant='body1'>
                {`(${
                  finchWorksite.unsyncedEmployees || 0
                } Unsynced Employee${RandomUtils.pluralCheck(
                  finchWorksite.unsyncedEmployees
                )}, ${
                  finchWorksite.unsyncedContractors || 0
                } Unsynced Contractor${RandomUtils.pluralCheck(
                  finchWorksite.unsyncedContractors
                )})`}
              </Text>
            </FinchWorksiteRadioItemLabel>
          }
          onClick={() => {
            if (selectedFinchWorksites.includes(finchWorksite.id)) {
              setSelectedFinchWorksites(
                selectedFinchWorksites.filter(
                  (worksiteId) => worksiteId !== finchWorksite.id
                )
              )
            } else {
              setSelectedFinchWorksites([
                ...selectedFinchWorksites,
                finchWorksite.id,
              ])
            }
          }}
          marginRight='1rem'
          key={finchWorksite.id}
        />
      )
    })
}

interface FinchWorksiteSelectMenuProps {
  loading: boolean
  selectedFinchWorksites: string[]
  setSelectedFinchWorksites: (worksites: string[]) => void
  providerName: string
}

const FinchWorksiteSelectMenu = React.memo(
  (props: FinchWorksiteSelectMenuProps) => {
    const {
      loading,
      selectedFinchWorksites,
      setSelectedFinchWorksites,
      providerName,
    } = props

    const { allFinchWorksites } = useSelector(
      (state: AppState) => state.employer
    )

    // We define syncable Finch worksites as worksites that have contractors/employees that have yet to be synced
    const syncableFinchWorksites = allFinchWorksites.filter(
      (finchWorksite) =>
        finchWorksite.unsyncedEmployees + finchWorksite.unsyncedContractors >
          0 && finchWorksite.canSync
    )

    const handleSelectAllPress = useCallback(() => {
      if (selectedFinchWorksites.length === syncableFinchWorksites.length) {
        setSelectedFinchWorksites([])
      } else {
        setSelectedFinchWorksites(
          syncableFinchWorksites.map((finchWorksite) => finchWorksite.id)
        )
      }
    }, [
      selectedFinchWorksites,
      setSelectedFinchWorksites,
      syncableFinchWorksites,
    ])

    if (syncableFinchWorksites.length === 0) {
      return (
        <NoSyncableWorksitesMessageContainer>
          <Alert color={palette.chips.yellow2} height={50} width={50} />
          <Text variant='h5' textColor={palette.grey.grey10} textAlign='center'>
            NO WORKSITES AVAILABLE TO SYNC
          </Text>
          <Text
            variant='action3'
            textColor={palette.grey.grey10}
            textAlign='center'
          >
            All worksites from{' '}
            <span style={{ textTransform: 'capitalize' }}>{providerName}</span>{' '}
            with unsynced employees/contractors have been synced.
          </Text>
        </NoSyncableWorksitesMessageContainer>
      )
    }

    if (loading) {
      return <Loading isCard={false} height={200} />
    }

    return (
      <>
        <Text variant='action2' marginBottom='1rem'>
          Select worksites to sync:
        </Text>
        <Radio
          active={
            selectedFinchWorksites.length === syncableFinchWorksites.length
          }
          label='Select All'
          onClick={handleSelectAllPress}
        />
        <Divider marginTop='1rem' />
        <FinchWorksiteRadioContainers>
          {syncableFinchWorksites
            ? renderSyncableFinchWorksiteRadios(
                syncableFinchWorksites,
                selectedFinchWorksites,
                setSelectedFinchWorksites
              )
            : null}
        </FinchWorksiteRadioContainers>
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FinchWorksiteSelectMenu.displayName = 'FinchWorksiteSelectMenu'
}

export default FinchWorksiteSelectMenu
