import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useQueryParam from 'shared/Hooks/useQueryParam'
import { AppState } from 'redux/config/store'
import Banner from 'core-system/Banner'
import Loading from 'core-system/Loading'
import { GrhGroup, GrhProgram } from 'redux/grh/grhTypes'
import ProgramHeader from 'core-system/Program/ProgramHeader'
import GRHPreviewModal from './GRHPreviewModal'
import { getClientEnvironment } from 'redux/config/services/Constants'
import moment from 'moment'
import GRHOverviewView from './GRHOverviewView'
import { ProgramMissingPaymentMethod } from 'core-system/Program'
import NoPaymentModal from 'shared/components/NoPaymentModal'

const GRHView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const navigate = useNavigate()

  const grhProgramsLoaded = useSelector(
    (state: AppState) => state.grh.grhProgramsLoaded
  )
  const grhPrograms = useSelector((state: AppState) => state.grh.grhPrograms)
  const {
    segmentsMap,
    profile,
    hasValidPaymentMethod,
    hasValidDistributionType,
  } = useSelector((state: AppState) => state.employer)

  const [grhGroup, setGrhGroup] = useState<GrhGroup>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)

  const handleGetStartedPress = () => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      navigate(`/guaranteed-rides/${segmentId}/activation`)
    } else {
      setIsNoPaymentModalOpen(true)
    }
  }

  useEffect(() => {
    setGrhGroup({
      active: grhPrograms[segmentId] && grhPrograms[segmentId].active,
      nextMonth: grhPrograms[segmentId] && grhPrograms[segmentId].nextMonth,
    })
  }, [grhPrograms, segmentId])

  if (!grhProgramsLoaded || !profile || !grhGroup) {
    return <Loading fullPage />
  }

  if (
    grhPrograms &&
    (!grhGroup.active || grhGroup.active.segment !== segmentId) &&
    (!grhGroup.nextMonth || grhGroup.nextMonth.segment !== segmentId)
  ) {
    return (
      <>
        <ProgramHeader segmentId={segmentId} />
        <Banner
          variant='cta'
          title='Start your GRH Program today!'
          description={`Give ${
            segmentsMap[segmentId].name === 'All Employees'
              ? 'All Commuters'
              : segmentsMap[segmentId].name
          } ${
            segmentsMap[segmentId].name === 'All Employees' ? '' : 'commuters'
          } a sure way to get to and from work.`}
          cta='Get Started'
          ctaCallback={() => handleGetStartedPress()}
          background='bannerGeneral3'
        />
        <GRHPreviewModal
          open={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          segmentId={segmentId}
        />
        <NoPaymentModal
          open={isNoPaymentModalOpen}
          closeModal={() => setIsNoPaymentModalOpen(false)}
          product='grh'
        />
      </>
    )
  }

  const isValid = hasValidDistributionType && hasValidPaymentMethod
  const isNextMonth = !grhGroup.active && grhGroup.nextMonth
  const isCancelling =
    !grhGroup.active &&
    grhGroup.nextMonth &&
    grhGroup.nextMonth.status === 'CANCELLING'
  const isPending = isNextMonth && !isCancelling
  const isDevCheck =
    getClientEnvironment() === 'development' && !isPending && !isCancelling
  const isOverviewCheck = grhGroup.active && isValid

  return (
    <>
      <ProgramHeader
        segmentId={segmentId}
        manageRoute={
          isDevCheck || isOverviewCheck
            ? `/guaranteed-rides/${segmentId}/manage`
            : null
        }
      />
      {isPending && (
        <Banner
          variant='cta'
          background='bannerGeneral3'
          title='Starting Soon!'
          description={`Your GRH is scheduled to start on ${moment(
            grhGroup.nextMonth.startDate
          ).format('MMMM Do, YYYY')}.`}
          cta='Manage Program'
          ctaCallback={() => navigate(`/guaranteed-rides/${segmentId}/manage`)}
        />
      )}
      {isCancelling && (
        <Banner
          variant='cta'
          background='bannerGeneral3'
          title='Program Cancelling'
          description='Your program cancellation request has been submitted. Our team will reach out soon.'
          cta='Manage Program'
          ctaCallback={() => navigate(`/guaranteed-rides/${segmentId}/manage`)}
        />
      )}
      {isDevCheck ? (
        <GRHOverviewView activeProgram={grhGroup.active as GrhProgram} />
      ) : (
        <>
          {!isValid && (
            <ProgramMissingPaymentMethod
              from={`/guaranteed-rides/${segmentId}`}
            />
          )}
          {isOverviewCheck && (
            <GRHOverviewView activeProgram={grhGroup.active as GrhProgram} />
          )}
        </>
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHView.displayName = 'GRHView'
}

export default GRHView
