import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5314 10.7804C15.2294 9.9884 15.5764 8.8804 15.3264 7.6964C15.0534 6.4004 14.0134 5.3364 12.7214 5.0414C10.4204 4.5154 8.37941 6.2504 8.37941 8.4604C8.37941 9.3454 8.70841 10.1534 9.24941 10.7704C9.48241 11.0364 9.34441 11.4554 9.00541 11.5564C7.67841 11.9514 5.34741 12.9914 5.50841 15.4004C5.60841 16.9164 7.06841 18.1444 8.58741 18.1444H15.1984C16.7184 18.1444 18.1784 16.9154 18.2794 15.3984C18.4384 12.9944 16.1054 11.9644 14.7714 11.5724C14.4274 11.4714 14.2944 11.0494 14.5314 10.7804Z'
    />
  </>,
  'Employees'
)
