import { AnyAction } from 'redux'
import { all, takeEvery } from 'redux-saga/effects'
import { defaultFlow } from 'redux/config/saga/Common'
import { employerActions } from 'redux/employer/employerSlice'
import { SegmentParams } from 'redux/employer/employerTypes'
import { leaderboardsActions } from 'redux/leaderboards/leaderboardsSlice'
import LeaderboardsService from 'redux/leaderboards/leaderboardsService'
import {
  LeaderboardCreatePayload,
  LeaderboardUpdatePayload,
} from 'redux/leaderboards/leaderboardsTypes'
import { userActions } from 'redux/user/userSlice'
import { Action } from 'shared/Types/Actions'

export function* getLeaderboards(action: AnyAction) {
  yield defaultFlow(
    action,
    LeaderboardsService.getLeaderboards,
    leaderboardsActions.getLeaderboardsSuccess
  )
}

export function* cancelLeaderboard(action: Action<string>) {
  yield defaultFlow(
    action,
    LeaderboardsService.cancelLeaderboard,
    leaderboardsActions.cancelLeaderboardSuccess
  )
}

export function* getLeaderboardRankings(action: Action<SegmentParams>) {
  yield defaultFlow(
    action,
    LeaderboardsService.getLeaderboardRankings,
    leaderboardsActions.getLeaderboardRankingsSuccess
  )
}

export function* createLeaderboard(action: Action<LeaderboardCreatePayload>) {
  yield defaultFlow(
    action,
    LeaderboardsService.createLeaderboard,
    leaderboardsActions.createLeaderboardSuccess
  )
}

export function* updateLeaderboard(action: Action<LeaderboardUpdatePayload>) {
  yield defaultFlow(
    action,
    LeaderboardsService.updateLeaderboard,
    leaderboardsActions.updateLeaderboardSuccess
  )
}

/**
 * Watchers
 */
export function* watchGetLeaderboards() {
  yield takeEvery(
    [
      userActions.getUserSuccess.type,
      leaderboardsActions.getLeaderboards.type,
      leaderboardsActions.cancelLeaderboardSuccess.type,
      employerActions.archiveWorksiteSuccess.type,
    ],
    getLeaderboards
  )
}

export function* watchCancelLeaderboard() {
  yield takeEvery(
    [leaderboardsActions.cancelLeaderboard.type],
    cancelLeaderboard
  )
}

export function* watchGetLeaderboardRankings() {
  yield takeEvery(
    [leaderboardsActions.getLeaderboardRankings.type],
    getLeaderboardRankings
  )
}

export function* watchCreateLeaderboard() {
  yield takeEvery(
    [leaderboardsActions.createLeaderboard.type],
    createLeaderboard
  )
}

export function* watchUpdateLeaderboard() {
  yield takeEvery(
    [leaderboardsActions.updateLeaderboard.type],
    updateLeaderboard
  )
}

export default function* () {
  yield all([
    watchGetLeaderboards(),
    watchCancelLeaderboard(),
    watchGetLeaderboardRankings(),
    watchCreateLeaderboard(),
    watchUpdateLeaderboard(),
  ])
}
